import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
	Tooltip,
	twozoStyles,
	SummaryFieldName,
} from "../../../../../../styles/twozo";
import { default as DropDownIcon } from "../../../../../../assets/icons/dropDown";
import FieldEdit from "../../../../FieldEdit";
import ContactFieldEditForm from "../../../../FieldEditFormComponents/ContactFieldEditForm";
import { useSummaryContext } from "../../../../../Summary/SummaryContext";

export default function ContactComponent(params) {
	const { summaryField } = params;

	const theme = useTheme();
	const classes = twozoStyles();

	// summary context:-
	const { updateSummaryField, entityId } = useSummaryContext();

	const [openToolTip, setOpenToolTip] = useState(false);
	const [isContactHovered, setIsContactHovered] = useState(false);

	const handleOpenTooltip = (event) => {
		setOpenToolTip(event.target.scrollWidth > event.target.clientWidth);
	};

	const getContactRequestData = (contactFieldValue) => {
		let contactValue = contactFieldValue[summaryField?.fieldId];
		return {
			[summaryField?.fieldId]: contactValue ? contactValue : null,
			id: entityId,
		};
	};
	const hasValueOrIsChanged = (contactFieldValue) => {
		return (
			(summaryField?.value &&
				summaryField?.value?.id !==
					contactFieldValue[summaryField?.fieldId]) ||
			(!summaryField?.value && contactFieldValue[summaryField?.fieldId])
		);
	};

	const handleSave = (contactFieldValue, onClose) => {
		if (hasValueOrIsChanged(contactFieldValue)) {
			let requestData = getContactRequestData(contactFieldValue);
			updateSummaryField(requestData, onClose);
		} else {
			onClose();
		}
	};

	const getContactFieldLabel = (onFieldClicked) => {
		return (
			<Box onClick={onFieldClicked}>
				{summaryField?.value ? (
					<Tooltip
						title={summaryField.value?.fullName}
						open={openToolTip}
						placement="top"
					>
						<SummaryFieldName
							className={classes.summaryData}
							onMouseOver={handleOpenTooltip}
							onMouseLeave={() => setOpenToolTip(false)}
						>
							{summaryField.value?.fullName}
						</SummaryFieldName>
					</Tooltip>
				) : summaryField.config.readOnly ? (
					<SummaryFieldName style={{ opacity: 0.6 }}>
						-
					</SummaryFieldName>
				) : (
					<Stack
						onMouseOver={() => setIsContactHovered(true)}
						onMouseLeave={() => setIsContactHovered(false)}
						padding={0}
						sx={{
							borderBottom: isContactHovered
								? "1px solid rgba(0, 0, 0, 0.2)"
								: "none",
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							spacing={0.5}
							width="150px"
						>
							<Typography
								fontSize="15px"
								fontWeight={500}
								style={{
									color: theme.palette.secondary.contrastText,
									opacity: "0.4",
								}}
							>
								Select
							</Typography>
							{isContactHovered ? (
								<Box display="flex">
									{DropDownIcon(20, 20, "rgba(0, 0, 0, 0.2)")}
								</Box>
							) : null}
						</Stack>
					</Stack>
				)}
			</Box>
		);
	};

	return (
		<>
			<FieldEdit field={summaryField} fieldLabel={getContactFieldLabel}>
				<ContactFieldEditForm
					onSave={handleSave}
					value={summaryField?.value}
					field={summaryField}
				/>
			</FieldEdit>
		</>
	);
}

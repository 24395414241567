import React, { useState } from "react";
import {
	Autocomplete,
	Chip,
	TextField,
	Typography,
	Popper,
	Box,
	MenuItem,
	ListSubheader,
	Stack,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../../../../assets/icons/close";
import { default as DropDownIcon } from "../../../../../../../../assets/icons/dropDown";
import { fieldType } from "../../../../../../../../utils/FilterUtills";
import { useValueDropDown } from "../../../../../../../../hooks/services/common/table/filter";
import Menu from "../../../../../../Menu";
import { getDropdownDataByName } from "../../../../../../../../utils/dropdownData";
import { useFilterDataContext } from "../../../../Context/FilterDataContext";
import { MenuItemText } from "../../../../../../../../styles/twozo";

const CustomPopper = function (props) {
	return (
		<Popper
			{...props}
			style={{ ...props.style, padding: "7px 0px", width: "202px" }}
		/>
	);
};

export default function ValueField(props) {
	const { filterCondition, onChange } = props;

	const theme = useTheme();

	const styles = {
		header: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			paddingLeft: "10px",
		},
		data: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			height: "38px",
			padding: "0px 10px",
			backgroundColor: "rgba(51, 188, 126, 0.12)",
		},
		focusTextField: {
			whiteSpace: "normal",
			overflow: "visible",
			textOverflow: "clip",
		},
		defaultTextField: {
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	};

	const { enableApplyButton } = useFilterDataContext();

	const selectedField = filterCondition?.fieldData || {};

	const selectedDropDownId = selectedField.fieldId
		? selectedField.fieldId
		: selectedField.optionId;
	const selectedDropDownName = selectedField.valueDropdown?.valueDropdownName
		? selectedField.valueDropdown?.valueDropdownName
		: null;

	const getDropdownRequest = () => {
		if (selectedField?.valueDropdown) {
			if (
				!!selectedField?.valueDropdown?.requiredFields &&
				selectedField?.valueDropdown?.requiredFields[0] === "id"
			) {
				return { id: selectedDropDownId };
			} else if (
				!!selectedField?.valueDropdown?.requiredFields &&
				selectedField?.valueDropdown?.requiredFields[0] === "sourceId"
			) {
				return {
					name: selectedDropDownName,
					sourceId: filterCondition?.sourceId?.value?.id,
				};
			} else {
				return { name: selectedDropDownName };
			}
		}
	};

	const { data: dropDownData } = useValueDropDown(
		getDropdownRequest(),
		selectedDropDownId,
		!!selectedField?.valueDropdown
	);

	const [dropDownMenuElement, setDropDownMenuElement] = useState(null);
	const isDropDownMenuElement = Boolean(dropDownMenuElement);

	const openDropDownMenu = (event) => {
		setDropDownMenuElement(event.currentTarget);
	};

	const closeDropDownMenu = () => {
		setDropDownMenuElement(null);
	};

	const handleOpenDropdownMenu = (data, event) => {
		if (
			data.fieldData.fieldType.name === fieldType.time ||
			data.fieldData.fieldType.name === fieldType.timeRange
		) {
			opentimeMenu(event);
		} else openDropDownMenu(event);
	};

	const handleSelectDropDownData = (selectedValue) => {
		const updatedFilterCondition = {
			...filterCondition,
			valueEntityId: {
				...filterCondition.valueEntityId,
				value: selectedValue.value
					? selectedValue.value
					: selectedValue.id,
			},
			value: {
				...filterCondition.value,
				value: selectedValue.name,
			},
			singleSelectValue: selectedValue,
		};
		onChange(updatedFilterCondition);
		enableApplyButton();
	};

	const handleSelectMultipleData = (selectedValue) => {
		let updatedValue = {
			...filterCondition,
			multiSelectValues: selectedValue,
		};
		onChange(updatedValue);
		enableApplyButton();
	};

	//Text Field
	const [focusTextField, setFocusTextField] = useState(false);

	const onFocusTextField = () => {
		setFocusTextField(true);
	};

	const onBlurTextField = () => {
		setFocusTextField(false);
	};

	const handleInputChange = (event) => {
		const { value } = event.target;

		const updatedFilterCondition = {
			...filterCondition,
			value: {
				...filterCondition.value,
				value: value,
			},
		};
		onChange(updatedFilterCondition);
		enableApplyButton();
	};

	const handleNumberInputChange = (event) => {
		const { value } = event.target;
		const numericValue = value.replace(/[^0-9]/g, "");
		let parsed = parseInt(numericValue);

		const updatedFilterCondition = {
			...filterCondition,
			value: {
				...filterCondition.value,
				value: isNaN(parsed) ? "" : parsed,
			},
		};
		onChange(updatedFilterCondition);
		enableApplyButton();
	};

	//Time and TimeRange DropDown
	const time = getDropdownDataByName("TIME");
	const [timeMenuElement, setTimeMenuElement] = useState(null);
	const isOpenTimeMenu = Boolean(timeMenuElement);

	const opentimeMenu = (event) => {
		setTimeMenuElement(event.currentTarget);
	};

	const closeTimeMenu = () => {
		setTimeMenuElement(null);
	};

	const handleSelectValue = (selectedValue) => {
		handleSelectDropDownData(selectedValue);
		setDropDownMenuElement(null);
		setTimeMenuElement(null);
	};

	const getField = (filterCondition) => {
		switch (filterCondition.fieldData.fieldType.name) {
			case fieldType.multiSelect:
			case fieldType.tags:
			case fieldType.contactsOnboard:
				return (
					<Autocomplete
						size="small"
						multiple
						disableClearable
						forcePopupIcon={false}
						PopperComponent={CustomPopper}
						options={dropDownData || []}
						getOptionLabel={(option) => option.name}
						noOptionsText="No Results Found"
						onChange={(_, data) => handleSelectMultipleData(data)}
						value={filterCondition.multiSelectValues || []}
						renderTags={(dropDownData, getTagProps) =>
							dropDownData.map((option, index) => (
								<Chip
									key={index}
									size="small"
									label={
										<Typography fontSize={14} color="#000">
											{option.name ? option.name : option}
										</Typography>
									}
									deleteIcon={CloseIcon(16, 16, "#000")}
									sx={{
										borderRadius: "8px",
										backgroundColor: "#FFF",
									}}
									{...getTagProps({
										index,
									})}
								/>
							))
						}
						renderOption={(props, option) => (
							<Box {...props}>
								<Typography fontSize={14}>
									{option.name ? option.name : option}
								</Typography>
							</Box>
						)}
						renderInput={(params) => (
							<TextField
								sx={{
									backgroundColor: "rgba(51, 188, 126, 0.12)",
									padding: "5px 10px 0px 10px",
									width: "218px",
									minHeight: "38px",
									borderRadius: "0px 0px 8px 8px",
									borderBottom: focusTextField
										? `1px solid ${theme.palette.primary.main}`
										: null,
								}}
								{...params}
								variant="standard"
								InputProps={{
									...params.InputProps,
									disableUnderline: true,
									style: {
										fontSize: "14px",
									},
								}}
								placeholder={
									filterCondition.fieldData.fieldType.name ===
									fieldType.tags
										? "Enter"
										: "Select"
								}
								onFocus={() => onFocusTextField()}
								onBlur={() => onBlurTextField()}
							/>
						)}
					/>
				);
			//Single Select
			case fieldType.contact:
			case fieldType.contactOnboard:
			case fieldType.company:
			case fieldType.companyOnboard:
			case fieldType.deal:
				return (
					<Autocomplete
						size="small"
						disableClearable
						forcePopupIcon={false}
						PopperComponent={CustomPopper}
						options={dropDownData || []}
						getOptionLabel={(option) => option?.name ?? ""}
						onChange={(_, data) => handleSelectValue(data)}
						noOptionsText="No Results Found"
						value={filterCondition.singleSelectValue}
						renderOption={(props, option) => (
							<Box {...props} sx={{ height: "40px" }}>
								<MenuItemText>
									{option.name ? option.name : option}
								</MenuItemText>
							</Box>
						)}
						renderInput={(params) => (
							<TextField
								sx={{
									backgroundColor: "rgba(51, 188, 126, 0.12)",
									padding: "5px 10px 0px 10px",
									width: "218px",
									minHeight: "38px",
									borderRadius: "0px 0px 8px 8px",
									borderBottom: focusTextField
										? `1px solid ${theme.palette.primary.main}`
										: null,
									"& .MuiInputBase-input": focusTextField
										? styles.focusTextField
										: styles.defaultTextField,
								}}
								{...params}
								variant="standard"
								InputProps={{
									...params.InputProps,
									disableUnderline: true,
									style: {
										fontSize: "14px",
									},
								}}
								placeholder="Search"
								onFocus={() => onFocusTextField()}
								onBlur={() => onBlurTextField()}
							/>
						)}
					/>
				);
			case fieldType.time:
			case fieldType.timeRange:
			case fieldType.premiumProduct:
			case fieldType.owner:
			case fieldType.date:
			case fieldType.dropdown:
			case fieldType.territory:
				return (
					<Stack
						direction="row"
						style={{
							...styles.data,
							cursor: "pointer",
							borderRadius: "0px 0px 8px 8px",
							borderBottom:
								dropDownMenuElement || timeMenuElement
									? `1px solid ${theme.palette.primary.main}`
									: null,
						}}
						onClick={(event) =>
							handleOpenDropdownMenu(filterCondition, event)
						}
					>
						{filterCondition.value.value ? (
							<Typography fontSize={14} noWrap>
								{filterCondition.value.value}
							</Typography>
						) : (
							<Typography
								sx={{
									fontSize: "14px",
									color: "rgba(0,0,0,0.4)",
								}}
							>
								Select
							</Typography>
						)}
						<Box sx={{ pl: 1 }}>{DropDownIcon(12, 12)}</Box>
					</Stack>
				);
			case fieldType.singleNumber:
			case fieldType.monetary:
				return (
					<Stack
						direction="row"
						style={{
							...styles.data,
							borderRadius: "0px 0px 8px 8px",
							borderBottom: focusTextField
								? `1px solid ${theme.palette.primary.main}`
								: null,
						}}
					>
						<TextField
							name={filterCondition.value.fieldName}
							value={filterCondition.value.value}
							onChange={(event) => handleNumberInputChange(event)}
							variant="standard"
							InputProps={{
								disableUnderline: true,
							}}
							sx={{
								"& .MuiInputBase-input": focusTextField
									? styles.focusTextField
									: styles.defaultTextField,
							}}
							placeholder="Enter Here"
							onFocus={() => onFocusTextField()}
							onBlur={() => onBlurTextField()}
						/>
					</Stack>
				);
			default:
				return (
					<Stack
						direction="row"
						style={{
							...styles.data,
							borderRadius: "0px 0px 8px 8px",
							borderBottom: focusTextField
								? `1px solid ${theme.palette.primary.main}`
								: null,
						}}
					>
						<TextField
							name={filterCondition.value.fieldName}
							value={filterCondition.value.value}
							onChange={(event) => handleInputChange(event)}
							variant="standard"
							InputProps={{
								disableUnderline: true,
							}}
							sx={{
								"& .MuiInputBase-input": focusTextField
									? styles.focusTextField
									: styles.defaultTextField,
							}}
							placeholder="Enter Here"
							onFocus={() => onFocusTextField()}
							onBlur={() => onBlurTextField()}
						/>
					</Stack>
				);
		}
	};

	return (
		<React.Fragment>
			{/* time */}
			<Menu
				minWidth="202px"
				anchorEl={timeMenuElement}
				open={isOpenTimeMenu}
				onClose={closeTimeMenu}
				style={{ transform: "translateY(3px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				{time.map((timeData, timeIndex) => (
					<MenuItem
						style={{ height: "40px" }}
						key={timeIndex}
						onClick={() => handleSelectValue(timeData)}
					>
						<MenuItemText>{timeData.name}</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			{/* ValuedropDown */}
			<Menu
				minWidth="202px"
				anchorEl={dropDownMenuElement}
				open={isDropDownMenuElement}
				onClose={closeDropDownMenu}
				style={{ transform: "translateX(8px) translateY(-1px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				{selectedDropDownName === "dateIntervalList"
					? dropDownData?.map((option, optionIndex) => (
							<Stack key={optionIndex}>
								<ListSubheader>
									<MenuItemText color="#000" fontWeight={600}>
										{option.category}
									</MenuItemText>
								</ListSubheader>

								{option?.days?.map((day) => (
									<MenuItem
										key={day.id}
										value={day.id}
										onClick={() => handleSelectValue(day)}
									>
										<MenuItemText>{day.name}</MenuItemText>
									</MenuItem>
								))}
							</Stack>
						))
					: dropDownData?.map((option, optionIndex) => (
							<MenuItem
								style={{ height: "40px" }}
								key={optionIndex}
								onClick={() => handleSelectValue(option)}
							>
								<MenuItemText>{option.name}</MenuItemText>
							</MenuItem>
						))}
			</Menu>

			{getField(filterCondition)}
		</React.Fragment>
	);
}

import { Menu, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
	useWorkflowHistoryFilter,
	useWorkflowNamesList,
} from "../../../../../../../hooks/services/workflow";
import { MenuItemText } from "../../../../../../../styles/twozo";

export default function WorkflowNameFilter(params) {
	const { updateFilter } = params;

	const filterRef = useRef(false);

	// query call:-
	const { data: workflowNamesList } = useWorkflowNamesList();
	const { data: historyData } = useWorkflowHistoryFilter();

	useEffect(() => {
		if (historyData && !filterRef.current) {
			setSelectedWorkflowName(historyData);
			filterRef.current = true;
			updateFilter({ value: historyData.id });
		}
	}, [historyData, updateFilter]);

	const theme = useTheme();
	const [workflowNameMenuElement, setWorkflowNameMenuElement] =
		useState(null);
	const [selectedWorkflowName, setSelectedWorkflowName] = useState([]);
	const isWorkflowNameFilterMenuOpened = Boolean(workflowNameMenuElement);

	const closeWorkflowNameMenu = () => setWorkflowNameMenuElement(null);
	const openWorkflowNameMenu = (event) =>
		setWorkflowNameMenuElement(event.currentTarget);

	const handleSelectWorkflowName = (workflowName) => {
		updateFilter({ value: workflowName.id });
		setSelectedWorkflowName(workflowName);
		closeWorkflowNameMenu();
	};

	const styles = {
		boxStyle: {
			padding: "8px 16px",
			borderTop: "1px solid rgba(0, 0, 0, 0.1)",
			borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
		},
		headerStyle: {
			fontSize: 13,
		},
		contentStyle: {
			fontSize: 13,
			fontWeight: 500,
			color: theme.palette.primary.main,
			cursor: "pointer",
		},
	};

	return (
		<>
			<Menu
				minWidth="200px"
				anchorEl={workflowNameMenuElement}
				open={isWorkflowNameFilterMenuOpened}
				onClose={closeWorkflowNameMenu}
				style={{ marginTop: "4px", maxHeight: "290px" }}
			>
				{workflowNamesList?.map((WorkflowName) => (
					<MenuItem
						key={WorkflowName.id}
						style={{ height: "40px", padding: 0 }}
						onClick={() => handleSelectWorkflowName(WorkflowName)}
					>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							p={1}
						>
							<MenuItemText>{WorkflowName.name}</MenuItemText>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<Stack style={styles.boxStyle} onClick={openWorkflowNameMenu}>
				<Typography style={styles.headerStyle}>Workflows</Typography>

				<Typography style={styles.contentStyle}>
					{selectedWorkflowName?.name || "select"}
				</Typography>
			</Stack>
		</>
	);
}

export const getFormattedFileSize = (bytes) => {
	var kiloBytes = 1024; // One Kilobyte is 1024 bytes
	var megaBytes = 1024 * 1024; // One MB is 1024 KB

	if (bytes < megaBytes) {
		return (bytes / kiloBytes).toFixed() + "KB"; // 880KB
	} else {
		return (bytes / megaBytes).toFixed() + "MB"; // 2.3MB
	}
};

export const imageExtension = ["png", "jpg", "jpeg", "gif"];

export const fileUploadCategory = {
	contact: 1,
	company: 2,
	deal: 3,
	import: 4,
	sourceProfile: 5,
	userProfile: 6,
	email: 7,
};

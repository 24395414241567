import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import SingleFieldComponent from "./SingleField";
import { checkFieldVisibility } from "./FieldVisibility";
import { multipleValuesFieldTypes } from "../../../WorkflowConfig/config";

export default function DefaultField(params) {
	const { setActionFields, actionFields, metadata, fieldError, clearError } =
		params;
	const theme = useTheme();

	const toggleEnableField = (fieldEnabled, actionFieldIndex, fieldId) => {
		setActionFields((actionFields) =>
			actionFields.map((actionField, index) => {
				if (index === actionFieldIndex) {
					return actionField.map((field) => {
						if (field.fieldId === fieldId) {
							return {
								...field,
								isEnabled: !!fieldEnabled,
								values: fieldEnabled ? field?.values : [],
							};
						}

						return field;
					});
				}
				return actionField;
			})
		);
	};

	const handleUpdateActionFields = (index, value, fieldId, isActivity) => {
		setActionFields((actionFields) =>
			actionFields.map((actionField, actionFieldIndex) => {
				if (isActivity ? index + 1 : index === actionFieldIndex) {
					return actionField.map((field) => {
						if (field.fieldId === fieldId) {
							return { ...field, values: value };
						}

						return field;
					});
				}
				return actionField;
			})
		);
	};

	const formValues = useMemo(() => {
		if (!Array.isArray(actionFields)) return {};

		const formFieldValue = {};

		actionFields?.forEach((actionField) => {
			actionField?.forEach((field) => {
				if (field?.values) {
					const { fieldType: type, fieldId, values } = field;

					formFieldValue[fieldId] = multipleValuesFieldTypes.includes(
						type
					)
						? values
						: values[0];
				}
			});
		});

		return formFieldValue;
	}, [actionFields]);

	const getGroupFieldNameStyle = (groupFieldHead, groupFieldFooter) => {
		return {
			borderRadius: groupFieldHead
				? "8px 8px 0 0"
				: groupFieldFooter
					? "0 0 8px 8px"
					: 0,
		};
	};

	const getGroupFieldValueStyle = (groupFieldHead, groupFieldFooter) => {
		return {
			borderRadius: groupFieldHead
				? "8px 8px 0 0"
				: groupFieldFooter
					? "0 0 8px 8px"
					: 0,
			"& .MuiOutlinedInput-notchedOutline": {
				border: "1px solid rgba(0, 0, 0, 0.1)",
				borderRadius: groupFieldHead
					? "8px 8px 0 0"
					: groupFieldFooter
						? "0 0 8px 8px"
						: 0,
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: groupFieldHead
						? "8px 8px 0 0"
						: groupFieldFooter
							? "0 0 8px 8px"
							: 0,
				},
			},
		};
	};

	return (
		<>
			<Box
				style={{
					height: "100%",
					overflowY: "auto",
					backgroundColor: theme.palette.secondary.main,
					borderRadius: "8px",
					border: "1px solid rgba(0, 0, 0, 0.1)",
				}}
				p={1}
			>
				<Grid container spacing={2} rowSpacing={1}>
					<Grid item xs={12} sm={6}>
						<Typography fontSize={14} fontWeight={600}>
							Fields
						</Typography>
					</Grid>

					<Grid item xs={12} sm={6}>
						<Typography fontSize={14} fontWeight={600}>
							Values
						</Typography>
					</Grid>

					{actionFields?.map((actionField, actionFieldIndex) => (
						<React.Fragment
							key={
								metadata.actionSource +
								"-" +
								metadata.actionEvent +
								"-" +
								actionFieldIndex
							}
						>
							{checkFieldVisibility(
								actionField?.[0],
								formValues
							) && actionField?.length === 1 ? (
								<SingleFieldComponent
									formValues={formValues}
									metadata={metadata}
									values={
										formValues?.[actionField?.[0]?.fieldId]
									}
									actionField={actionField[0]}
									error={fieldError?.[actionFieldIndex]}
									toggleEnableField={(data) =>
										toggleEnableField(
											data,
											actionFieldIndex,
											actionField?.[0]?.fieldId
										)
									}
									updateFieldValue={(
										data,
										fieldId,
										isActivity
									) =>
										handleUpdateActionFields(
											actionFieldIndex,
											data,
											fieldId,
											isActivity
										)
									}
									clearError={(fieldId) =>
										clearError(fieldId)
									}
								/>
							) : (
								<Grid direction="row" item xs={24} sm={12}>
									{actionField?.map(
										(field, index) =>
											checkFieldVisibility(
												field,
												formValues
											) && (
												<Stack
													direction="row"
													spacing={2}
													key={field.fieldId}
												>
													<SingleFieldComponent
														metadata={metadata}
														actionField={field}
														formValues={formValues}
														values={
															formValues?.[
																field?.fieldId
															]
														}
														fieldNameStyle={getGroupFieldNameStyle(
															index === 0,
															index ===
																actionField?.length -
																	1
														)}
														fieldValueStyle={getGroupFieldValueStyle(
															index === 0,
															index ===
																actionField?.length -
																	1
														)}
														error={
															fieldError?.[
																actionFieldIndex
															]
														}
														toggleEnableField={(
															data
														) =>
															toggleEnableField(
																data,
																actionFieldIndex,
																field?.fieldId
															)
														}
														updateFieldValue={(
															data,
															fieldId,
															isActivity
														) =>
															handleUpdateActionFields(
																actionFieldIndex,
																data,
																fieldId,
																isActivity
															)
														}
														clearError={(fieldId) =>
															clearError(fieldId)
														}
													/>
												</Stack>
											)
									)}
								</Grid>
							)}
						</React.Fragment>
					))}
				</Grid>
			</Box>
		</>
	);
}

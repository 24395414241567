import React, { useEffect, useState } from "react";
import {
	Box,
	// Button,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Pipeline from "./Pipeline";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as CloseIcon } from "../../../assets/icons/close";
import { twozoStyles } from "../../../styles/twozo";
import AddPipeline from "./AddPipeline";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import {
	usePipelineList,
	useUpdatePipelineOrder,
} from "../../../hooks/services/deal/pipeline";
import DragDropContextWithNonce from "../../Elements/DragDropContextWithNonce";

export default function ManagePipelines(props) {
	const { onClose } = props;

	const updatePipelineOrderMutation = useUpdatePipelineOrder();
	const { data: pipelines, status: pipelinesStatus } = usePipelineList();

	const [pipelinesList, setPipelinesList] = useState([]);

	const pipelineLimitCount = 15;
	const isPipelineLimitNotExceeded =
		pipelinesList.length < pipelineLimitCount;

	useEffect(() => {
		if (pipelinesStatus === "success") {
			if (pipelines) {
				setPipelinesList(pipelines);
			}
		}
	}, [pipelines, pipelinesStatus]);

	const theme = useTheme();
	const classes = twozoStyles();
	const [isAddNewPipelineDialogOpen, setIsAddNewPipelineDialogOpen] =
		useState(false);
	const [editedPipelineData, setEditedPipelineData] = useState({});
	const [isPipelineInEditMode, setIsPipelineInEditMode] = useState(false);

	const openEditPipelineDialog = (pipeline) => {
		setEditedPipelineData(pipeline);
		openAddNewPipelineDialog();
	};

	const openAddNewPipelineDialog = () => {
		setIsAddNewPipelineDialogOpen(true);
	};

	const closeAddNewPipelineDialog = () => {
		setEditedPipelineData({});
		setIsPipelineInEditMode(false);
		setIsAddNewPipelineDialogOpen(false);
	};

	const handlePipelineChange = (pipelineData) => {
		let newPipelines = pipelinesList;

		for (let i = 0; i < newPipelines.length; i++) {
			if (newPipelines[i].id === pipelineData.id) {
				newPipelines[i].stages = pipelineData.stages;
				break;
			}
		}

		setPipelinesList(newPipelines);
	};

	const getPipelineOrderRequest = (pipelines) => {
		let pipelineIds = pipelines.map((pipeline) => pipeline.id);

		return pipelineIds;
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onPipelineDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		// default pipeline should not be draggable
		let defaultPipelineIndex = 0;
		if (result.destination.index === defaultPipelineIndex) {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.pipelineOrderUpdateError,
			});
			return;
		}

		const updatedData = reorder(
			pipelinesList,
			result.source.index,
			result.destination.index
		);

		// update the pipeline order
		let requestData = getPipelineOrderRequest(updatedData);
		updatePipelineOrderMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.pipelineUpdated,
				});
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});

		setPipelinesList(updatedData);
	};

	const onAddStageClicked = (pipeline) => {
		let clonedPipeline = { ...pipeline, stages: [...pipeline.stages] };
		let numberOfWonAndLostStages = 2;
		let emptyPipelineStage = {
			name: "",
			isNewStage: true,
			id: `id ${clonedPipeline.stages.length - numberOfWonAndLostStages}`,
			probability: 100,
			isEditable: true,
			isDefault: false,
		};

		clonedPipeline.stages.splice(
			clonedPipeline.stages.length - numberOfWonAndLostStages,
			0,
			emptyPipelineStage
		);
		setEditedPipelineData({ ...clonedPipeline });
		openAddNewPipelineDialog();
		setIsPipelineInEditMode(true);
	};

	const updateIsPipelineInEditMode = (isPipelineInEditMode) => {
		setIsPipelineInEditMode(isPipelineInEditMode);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddNewPipelineDialogOpen}
				onOpen={openAddNewPipelineDialog}
				onClose={closeAddNewPipelineDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					{isAddNewPipelineDialogOpen && (
						<AddPipeline
							isPipelineInEditMode={isPipelineInEditMode}
							editedPipelineData={editedPipelineData}
							onClose={closeAddNewPipelineDialog}
							isShowSaveAndNewButton={
								pipelineLimitCount > pipelinesList.length + 1
							}
						/>
					)}
				</Box>
			</CustomSwipeableDrawer>

			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Typography fontWeight={600}>
							Pipelines & Stages
						</Typography>

						<Box display="flex">
							<IconButton
								size="small"
								style={{ padding: 0 }}
								onClick={onClose}
							>
								{CloseIcon(24, 24, theme.palette.primary.main)}
							</IconButton>
						</Box>
					</Stack>
				</Box>

				<Divider />

				<Box
					p={3}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
					}}
				>
					<DragDropContextWithNonce onDragEnd={onPipelineDragEnd}>
						<Droppable
							droppableId="pipelines"
							direction="horizontal"
						>
							{(provided) => (
								<div
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									<Stack direction="row" height="100%">
										{pipelinesList.map(
											(pipeline, index) => (
												<Draggable
													draggableId={pipeline.id.toString()}
													index={index}
													key={pipeline.id}
												>
													{(provided) => (
														<Box
															ref={
																provided.innerRef
															}
															{...provided.draggableProps}
															pr={2}
														>
															<Box width="320px">
																<Pipeline
																	pipeline={
																		pipeline
																	}
																	openEditPipelineDialog={() =>
																		openEditPipelineDialog(
																			pipeline
																		)
																	}
																	handlePipelineChange={
																		handlePipelineChange
																	}
																	dragHandleProps={
																		provided.dragHandleProps
																	}
																	onAddStageClicked={() =>
																		onAddStageClicked(
																			pipeline
																		)
																	}
																	updateIsPipelineInEditMode={
																		updateIsPipelineInEditMode
																	}
																/>
															</Box>
														</Box>
													)}
												</Draggable>
											)
										)}

										{provided.placeholder}

										{isPipelineLimitNotExceeded && (
											<Stack
												spacing={1}
												p={3}
												alignItems="center"
												justifyContent="center"
											>
												<Box>
													<IconButton
														color="primary"
														style={{
															backgroundColor:
																theme.palette
																	.secondary
																	.main,
														}}
														onClick={
															openAddNewPipelineDialog
														}
													>
														{AddIcon(
															24,
															24,
															theme.palette
																.secondary
																.contrastText
														)}
													</IconButton>
												</Box>

												<Typography
													width="88px"
													fontSize={13}
													fontWeight={500}
													color={
														theme.palette.secondary
															.contrastText
													}
												>
													Add Pipeline
												</Typography>
											</Stack>
										)}
									</Stack>
								</div>
							)}
						</Droppable>
					</DragDropContextWithNonce>
				</Box>
			</Box>
		</React.Fragment>
	);
}

import React, { useCallback, useEffect, useState } from "react";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { twozoStyles } from "../../../../styles/twozo";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { getActionFieldsKey } from "../../../../utils/queryKeys/workflow";
import { useQuery } from "@tanstack/react-query";
import { getActionFieldsData } from "../../../../api/workflow/workflowApi";
import DefaultField from "./DefaultField";
import { handleFieldValidation } from "../../WorkflowConfig/handleFieldValidation";

export default function DefaultFields(props) {
	const {
		toggleAddActivityDialog,
		triggerSourceId,
		actionSource,
		actionEvent,
		formName,
		resetAction,
		fieldMapping,
		setFieldMappings,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();

	const [actionFields, setActionFields] = useState(null);
	const [fieldError, setFieldError] = useState([]);

	const actionFieldsKey = getActionFieldsKey(
		triggerSourceId,
		actionSource?.id,
		actionEvent
	);

	const hasFieldMapping = useCallback(() => {
		if (Array.isArray(fieldMapping) && fieldMapping.length > 0) {
			return false;
		}
		return true;
	}, [fieldMapping]);

	const isActionFieldEnabled = () => {
		return !!(
			hasFieldMapping() &&
			triggerSourceId &&
			actionSource?.id &&
			actionEvent
		);
	};

	const { data: actionFieldsData } = useQuery(
		actionFieldsKey,
		() =>
			getActionFieldsData(triggerSourceId, actionSource?.id, actionEvent),
		{
			enabled: isActionFieldEnabled(),
		}
	);

	useEffect(() => {
		if (actionFieldsData && actionFieldsData.fields) {
			let fieldError = [];
			actionFieldsData.fields?.forEach(() => {
				fieldError.push([false]);
			});
			setFieldError(fieldError);
			const deepCopyActionFields = JSON.parse(
				JSON.stringify(actionFieldsData.fields)
			);
			setActionFields(deepCopyActionFields);
		}
	}, [actionFieldsData]);

	useEffect(() => {
		if (Array.isArray(fieldMapping) && !hasFieldMapping()) {
			let fieldError = [];
			fieldMapping.forEach(() => {
				fieldError.push([false]);
			});
			setFieldError(fieldError);
			setActionFields(fieldMapping);
		}
	}, [fieldMapping, hasFieldMapping]);

	const handleSave = () => {
		let fieldError = [];
		actionFields?.forEach((field) => {
			if (
				(field[0]?.config?.isRequired || field[0]?.isEnabled) &&
				!(
					field[0].values &&
					field[0].values.length > 0 &&
					field[0].values[0].value
				)
			) {
				let validatedField = handleFieldValidation(field, true);
				fieldError.push({
					...validatedField[0].error,
					fieldId: field[0]?.fieldId,
				});
			} else {
				let validatedField = handleFieldValidation(
					field,
					field[0]?.isEnabled
				);
				fieldError.push({
					...validatedField[0].error,
					fieldId: field[0]?.fieldId,
				});
			}
		});

		setFieldError(fieldError);
		if (fieldError.some((error) => error?.error)) {
			return;
		}
		setFieldMappings([...actionFields]);
	};

	const metadata = {
		triggerSourceId,
		actionSource: actionSource?.id,
		actionEvent,
	};

	const onCancelClick = () => {
		resetAction();
		setActionFields([]);
		toggleAddActivityDialog();
	};

	const handleClearActionFieldError = (fieldId) => {
		setFieldError((fieldError) => {
			return fieldError.map((error) => {
				if (error.fieldId == fieldId) {
					return { ...error, error: false, message: "" };
				}
				return error;
			});
		});
	};

	return (
		<React.Fragment>
			<Box className={classes.addDialogContainer}>
				<Box
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box p={2}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<Typography fontWeight={600}>{formName}</Typography>

							<IconButton
								onClick={onCancelClick}
								size="small"
								style={{ padding: 0 }}
							>
								{CloseIcon(25, 25, theme.palette.primary.main)}
							</IconButton>
						</Stack>
					</Box>

					<Divider />

					<Box
						p={2}
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<DefaultField
							metadata={metadata}
							actionFields={actionFields}
							fieldError={fieldError}
							setActionFields={setActionFields}
							clearError={handleClearActionFieldError}
						/>
					</Box>

					<Divider />

					<Box p={2}>
						<Button
							variant="contained"
							disableElevation
							onClick={handleSave}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}

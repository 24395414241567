import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	IconButton,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as FavouriteOffIcon } from "../../../../assets/icons/favouriteOff";
import { default as FavouriteOnIcon } from "../../../../assets/icons/favouriteOn";
import { default as CopyIcon } from "../../../../assets/icons/copy";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import { default as ReloadIcon } from "../../../../assets/icons/reload";
import {
	FormFieldName,
	TableCellText,
	TableHeaderLabel,
	Tooltip,
} from "../../../../styles/twozo";
import { useNavigate, useParams } from "react-router-dom";
import DeleteDialog from "../../../Elements/DeleteDialog";
import Dialog from "../../../Elements/Dialog";
import { useGetDashboardList } from "../../../../hooks/services/analytics";
import { getDateMonthAndYearFormat } from "../../../../utils/DateUtils";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";

export default function DashboardList() {
	const theme = useTheme();
	const navigate = useNavigate();
	let { reportName } = useParams();
	const inboxRef = useRef(null);
	const [topBarHeight, setTopBarHeight] = useState(0);
	const [reportsData, setReportsData] = useState([]);
	const [tableRowHovered, setTableRowHovered] = useState(null);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [deleteReportName, setDeleteReportName] = useState("");
	const [isCloneDialogOpened, setIsCloneDialogOpened] = useState(false);
	const [cloneReportName, setCloneReportName] = useState("");
	const [sortRequest, setSortRequest] = useState([]);

	const sortTypes = {
		ascending: 1,
		decending: 0,
	};

	const [reportHeaderList, setReportHeaderList] = useState([
		{
			id: 1,
			displayName: "Report Name",
			name: "displayName",
			isSortable: true,
			config: {
				width: "25%",
			},
		},
		{
			id: 2,
			displayName: "Created By",
			name: "createdBy",
			isSortable: false,
		},
		{
			id: 3,
			displayName: "Created Date",
			name: "createdTime",
			isSortable: true,
		},
		{
			id: 4,
			displayName: "Modified By",
			name: "modifiedBy",
			isSortable: false,
		},
		{
			id: 5,
			displayName: "Modified Date",
			name: "modifiedTime",
			isSortable: true,
		},
	]);

	const reportsHeader = {
		favourites: {
			id: 1,
			displayName: "Favourites",
		},
		all: {
			id: 2,
			displayName: "All Dashboards",
		},
		curated: {
			id: 3,
			displayName: "Curated Dashboards",
		},
		private: {
			id: 5,
			displayName: "Private Dashboards",
		},
		shared: {
			id: 6,
			displayName: "Shared Dashboards",
		},
		owned: {
			id: 7,
			displayName: "Owned Dashboards",
		},
		trash: {
			id: 8,
			displayName: "Trash",
		},
	};

	const {
		data: dashboardListData,
		isLoading: isDashboardListDataLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useGetDashboardList(reportName, true, [], sortRequest);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	useEffect(() => {
		setReportsData(dashboardListData || []);
	}, [dashboardListData, isDashboardListDataLoading]);

	useEffect(() => {
		if (inboxRef) {
			setTopBarHeight(inboxRef.current.getBoundingClientRect().top);
		}
	}, [inboxRef]);

	const toggleFavourite = (index) => {
		setReportsData((reportsData) => {
			let updatedReportsData = [...reportsData];
			updatedReportsData[index]["favourite"] =
				!updatedReportsData[index]["favourite"];
			return updatedReportsData;
		});
	};

	const onMouseOverTableRow = (id) => setTableRowHovered(id);

	const onMouseOutTableRow = () => setTableRowHovered(null);

	const openDeleteReportDialog = (reportName) => {
		setIsDeleteDialogOpened(true);
		setDeleteReportName(reportName);
	};

	const closeDeleteReportDialog = () => {
		setIsDeleteDialogOpened(false);
		setDeleteReportName("");
	};

	const openCloneReportDialog = (reportName) => {
		setIsCloneDialogOpened(true);
		setCloneReportName("Copy of " + reportName);
	};

	const closeCloneReportDialog = () => {
		setIsCloneDialogOpened(false);
		setCloneReportName("");
	};

	const getReportListHeader = (reportName) => {
		const report = reportsHeader[reportName];
		return report ? report.displayName : null;
	};

	const reportListHeader = getReportListHeader(reportName);

	const toggleSort = async (columnIndex) => {
		const sortRequest = [];

		setReportHeaderList((reportHeaderList) => {
			if (reportHeaderList[columnIndex].sort === sortTypes.ascending) {
				reportHeaderList[columnIndex].sort = sortTypes.decending;
				sortRequest.push({
					field: reportHeaderList[columnIndex].name,
					type: "desc",
				});
			} else {
				reportHeaderList[columnIndex].sort = sortTypes.ascending;
				sortRequest.push({
					field: reportHeaderList[columnIndex].name,
					type: "asc",
				});
			}

			reportHeaderList.forEach((column, index) => {
				if (
					column.sort === sortTypes.ascending &&
					index !== columnIndex
				) {
					reportHeaderList[index].sort = sortTypes.decending;
				}
			});

			return reportHeaderList;
		});

		setSortRequest(sortRequest);
	};

	const CuratedTag = () => (
		<span
			style={{
				fontSize: "12px",
				fontWeight: 500,
				color: theme.palette.secondary.contrastText,
				backgroundColor: theme.palette.secondary.main,
				padding: "4px",
				borderRadius: "6px",
			}}
		>
			Curated
		</span>
	);

	return (
		<React.Fragment>
			<Dialog
				open={isCloneDialogOpened}
				onClose={closeCloneReportDialog}
				title="Clone Report"
			>
				<Stack spacing={2} p={2}>
					<Stack spacing={0.5}>
						<FormFieldName>Report Name</FormFieldName>
						<TextField value={cloneReportName} />
					</Stack>

					<Stack spacing={0.5}>
						<FormFieldName>Description</FormFieldName>
						<TextField minRows={2} maxRows={6} multiline />
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						justifyContent="flex-end"
						spacing={2}
						px={1}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={closeCloneReportDialog}
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							onClick={closeCloneReportDialog}
						>
							Clone
						</Button>
					</Stack>
				</Stack>
			</Dialog>

			<DeleteDialog
				title={`Are you sure you want to move this report “${deleteReportName}”to trash?`}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteReportDialog}
				onDelete={closeDeleteReportDialog}
			/>

			<Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					py={1.25}
					px={2}
					sx={{ height: "56px" }}
				>
					<Box>
						<Typography fontWeight={500} fontSize={15}>
							{reportListHeader}
						</Typography>
					</Box>

					<Box width="300px">
						<MenuSearchBar />
					</Box>
				</Stack>

				<Box
					sx={{
						height: `calc(100vh - ${topBarHeight + 8}px)`,
						overflowY: "auto",
					}}
					ref={inboxRef}
				>
					{!isDashboardListDataLoading && reportsData.length === 0 ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="80%"
							spacing={1}
						>
							<Typography
								fontSize={17}
								fontWeight={500}
								color="rgba(0, 0, 0, 0.6)"
							>
								&quot;You currently have no reports here.&quot;
							</Typography>

							<Button
								variant="contained"
								color="secondary"
								onClick={() =>
									navigate("/analytics/dashboard/list/all")
								}
							>
								See All Reports
							</Button>
						</Stack>
					) : (
						<Table
							size="small"
							style={{
								width: "100%",
								tableLayout: "fixed",
							}}
						>
							<TableHead>
								<TableRow sx={{ height: "36px" }}>
									<TableCell width="5%" />

									{reportHeaderList.map((header, index) => (
										<TableCell
											key={header.id}
											width={
												header.config?.width || "auto"
											}
											onClick={
												header.isSortable
													? () => toggleSort(index)
													: null
											}
										>
											<Stack
												direction="row"
												spacing={0.5}
												alignItems="center"
											>
												<TableHeaderLabel>
													{header.displayName}
												</TableHeaderLabel>

												{header.isSortable ? (
													<Box
														sx={{
															display: "flex",
														}}
													>
														{header.sort ===
														sortTypes.ascending ? (
															<Box
																display={"flex"}
																style={{
																	transform:
																		"rotate(180deg)",
																}}
															>
																{DropDownIcon(
																	12,
																	12,
																	"rgba(0, 0, 0, 0.6)"
																)}
															</Box>
														) : (
															<Box
																display={"flex"}
															>
																{DropDownIcon(
																	12,
																	12,
																	"rgba(0, 0, 0, 0.6)"
																)}
															</Box>
														)}
													</Box>
												) : null}
											</Stack>
										</TableCell>
									))}
									<TableCell width="10%" />
								</TableRow>
							</TableHead>
							<TableBody>
								{isDashboardListDataLoading
									? new Array(15).fill(0).map((_, index) => (
											<TableRow
												key={index}
												style={{ height: "42px" }}
											>
												{new Array(7)
													.fill(0)
													.map((_, index) => (
														<TableCell key={index}>
															<Skeleton
																variant="text"
																sx={{
																	fontSize:
																		"13px",
																}}
															/>
														</TableCell>
													))}
											</TableRow>
										))
									: reportsData.map((reportData, index) => (
											<TableRow
												key={index}
												style={{ height: "42px" }}
												onClick={() =>
													navigate(
														`/analytics/dashboard/view/${reportData.id}?ref=${reportName}`
													)
												}
												onMouseOver={() =>
													onMouseOverTableRow(
														reportData.id
													)
												}
												onMouseOut={() =>
													onMouseOutTableRow()
												}
											>
												<TableCell>
													<Tooltip
														title={
															reportData.isFavView
																? "Remove from favourites"
																: "Add to favourites"
														}
														placement="top"
													>
														<IconButton
															size="small"
															onClick={() =>
																toggleFavourite(
																	index
																)
															}
														>
															{reportData.isFavView
																? FavouriteOnIcon(
																		20,
																		20,
																		theme
																			.palette
																			.primary
																			.main
																	)
																: FavouriteOffIcon(
																		20,
																		20,
																		"rgba(0, 0, 0, 0.2)"
																	)}
														</IconButton>
													</Tooltip>
												</TableCell>

												<TableCell>
													<Stack
														direction="row"
														alignItems="center"
														spacing={"6px"}
													>
														<Tooltip
															title={
																reportData.displayName
															}
															placement="top"
														>
															<TableCellText
																noWrap
															>
																{
																	reportData.displayName
																}
															</TableCellText>
														</Tooltip>

														{reportData.isCurated && (
															<CuratedTag />
														)}
													</Stack>
												</TableCell>

												<TableCell>
													<TableCellText>
														{
															reportData.createdBy
																.name
														}
													</TableCellText>
												</TableCell>

												<TableCell>
													<TableCellText>
														{getDateMonthAndYearFormat(
															reportData.createdTime
														)}
													</TableCellText>
												</TableCell>

												<TableCell>
													<TableCellText>
														{reportData.modifiedBy
															? reportData
																	.modifiedBy
																	.name
															: "-"}
													</TableCellText>
												</TableCell>

												<TableCell>
													<TableCellText>
														{getDateMonthAndYearFormat(
															reportData.modifiedTime
														)}
													</TableCellText>
												</TableCell>

												<TableCell
													style={{
														padding: "0px 16px",
													}}
												>
													{tableRowHovered ===
														reportData.id && (
														<Box
															style={{
																display: "flex",
																justifyContent:
																	"flex-end",
															}}
														>
															<Box
																style={{
																	height: "37px",
																	border: `1px solid ${theme.palette.primary.main}`,
																	borderRadius:
																		"8px",
																	width: "fit-content",
																}}
																p={1}
															>
																<Stack
																	direction="row"
																	justifyContent="center"
																	alignItems="center"
																	style={{
																		height: "100%",
																	}}
																	spacing={
																		0.5
																	}
																>
																	<IconButton
																		onClick={() =>
																			openCloneReportDialog(
																				reportData.reportName
																			)
																		}
																	>
																		{CopyIcon(
																			20,
																			20,
																			theme
																				.palette
																				.primary
																				.main
																		)}
																	</IconButton>

																	{reportData.isDeletable && (
																		<>
																			<Divider orientation="vertical" />

																			<IconButton
																				onClick={() =>
																					openDeleteReportDialog(
																						reportData.reportName
																					)
																				}
																			>
																				{DeleteIcon(
																					20,
																					20,
																					theme
																						.palette
																						.error
																						.main
																				)}
																			</IconButton>
																		</>
																	)}

																	{reportData.isRecoverable && (
																		<>
																			<Divider orientation="vertical" />

																			<IconButton
																				onClick={() =>
																					alert(
																						"recover"
																					)
																				}
																			>
																				{ReloadIcon(
																					20,
																					20,
																					theme
																						.palette
																						.primary
																						.main
																				)}
																			</IconButton>
																		</>
																	)}
																</Stack>
															</Box>
														</Box>
													)}
												</TableCell>
											</TableRow>
										))}
								<TableRow>
									<TableCell
										colSpan={7}
										sx={{ borderBottom: "none" }}
									>
										<Box ref={lastRowRef}>
											{!isDashboardListDataLoading &&
												isFetching && (
													<Stack
														direction="row"
														alignItems="center"
														justifyContent="center"
														spacing={1}
														py={2}
													>
														<CircularProgress
															size={18}
														/>

														<Typography
															fontSize={12}
														>
															Loading More Data
														</Typography>
													</Stack>
												)}
										</Box>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}

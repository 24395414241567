import { useTheme } from "@emotion/react";
import {
	Box,
	Chip,
	InputAdornment,
	Paper,
	Popper,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import AddIcon from "../../../../../assets/icons/add";
import CloseIcon from "../../../../../assets/icons/close";
import DropdownIcon from "../../../../../assets/icons/dropDownCentered";
import { default as MuiAutocomplete } from "@mui/material/Autocomplete";
import { useRef, useState } from "react";
import TooltipComponent from "../../../../Elements/TooltipComponent";
import { phoneRegexPattern } from "../../../WorkflowConfig/config";
import { MenuItemText } from "../../../../../styles/twozo";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
			}}
		/>
	);
};

export default function AutocompleteBaseComponent(params) {
	const {
		values,
		style = {},
		error,
		onChange,
		isValidOption,
		options,
		type,
		isNewOption,
		placeholder = "Select",
		isConditionField,
	} = params;

	const theme = useTheme();
	const inputRef = useRef(null);

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isMenuExpanded, setIsMenuExpanded] = useState(false);
	const [searchValue, setSearchValue] = useState("");

	const filterOptions = (options, { inputValue }) => {
		const filteredOptions = options.filter((option) =>
			option?.value?.name
				?.toLowerCase()
				?.includes(inputValue?.toLowerCase())
		);

		const isExisting = options.some(
			(option) =>
				option?.value?.name?.toLowerCase() === inputValue?.toLowerCase()
		);

		if (inputValue !== "" && !isExisting && isNewOption) {
			filteredOptions.push({
				value: { name: inputValue },
				isNewOption: true,
			});
		}

		return filteredOptions;
	};

	const onMenuClose = () => {
		setIsMenuOpen(false);
		setIsMenuExpanded(false);
		setSearchValue("");
	};

	const onMenuOpen = (event) => {
		if (!event.target.textContent.startsWith("+")) {
			setIsMenuOpen(true);
		} else {
			event.preventDefault();
		}
	};

	const isMenuOpened = () => {
		return (
			isMenuOpen &&
			(isConditionField
				? searchValue.trim().length > 0
					? true
					: false
				: true)
		);
	};

	const handleChangeValue = (event) => {
		let { value } = event.target;
		if (type === "tel") {
			value = value.trimStart().replace(phoneRegexPattern, "");
		}
		setSearchValue(value);
	};

	const handleChangeOptionsValue = (value) => {
		onChange(value);
		setIsMenuExpanded(true);
		setSearchValue("");
	};

	return (
		<MuiAutocomplete
			size="small"
			options={Array.isArray(options) ? options : []}
			value={values}
			open={isMenuOpened()}
			PopperComponent={CustomPopper}
			PaperComponent={CustomPaper}
			isOptionEqualToValue={(option, value) => {
				return option?.value?.name === value?.value?.name;
			}}
			inputValue={searchValue}
			onBlur={onMenuClose}
			onOpen={onMenuOpen}
			forcePopupIcon={false}
			multiple
			style={{ width: "100%" }}
			onChange={(_, value) => handleChangeOptionsValue(value)}
			disableClearable
			filterSelectedOptions
			getOptionLabel={(option) => option?.value?.name || ""}
			renderTags={(selected, getTagProps) => {
				const maxVisibleOptions = 1;
				const visibleOptions = isMenuExpanded
					? selected
					: selected.slice(0, maxVisibleOptions);

				return (
					Array.isArray(visibleOptions) && (
						<Stack
							direction="row"
							alignItems="center"
							flexWrap={isConditionField ? "nowrap" : "wrap"}
							gap={0.5}
						>
							{visibleOptions.map((option, index) => (
								<Chip
									key={index}
									size="small"
									style={{
										height: "26px",
										display: "flex",
										alignItems: "center",
										backgroundColor:
											isValidOption &&
											!isValidOption(option)
												? theme.palette.warning.main
												: isConditionField
													? theme.palette.primary
															.contrastText
													: theme.palette.secondary
															.main,
									}}
									label={
										<Box display="flex">
											<TooltipComponent
												title={
													option?.value?.name || ""
												}
												placement="top"
											>
												<Typography
													fontSize={14}
													color="#000"
													maxWidth="120px"
													noWrap
												>
													{option?.value?.name || ""}
												</Typography>
											</TooltipComponent>
										</Box>
									}
									sx={{
										borderRadius: "8px",
										marginRight: "4px",
										height: "100%",
									}}
									deleteIcon={CloseIcon(16, 16, "#000")}
									color="secondary"
									{...getTagProps({ index })}
								/>
							))}

							{isMenuExpanded
								? null
								: values?.length > 1 && (
										<Typography
											fontSize={14}
											style={{ cursor: "pointer" }}
											color={theme.palette.primary.main}
											onClick={(event) => {
												event.stopPropagation();
												setIsMenuExpanded(true);
												inputRef.current.focus();
											}}
										>
											+{values?.length - 1}
										</Typography>
									)}
						</Stack>
					)
				);
			}}
			renderOption={(props, option) => (
				<Box
					key={option?.id}
					{...props}
					style={{
						minHeight: "40px",
					}}
				>
					{option.isNewOption ? (
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							width="100%"
						>
							<MenuItemText
								fontWeight={500}
								width="100%"
								overflow="hidden"
								color={theme.palette.primary.main}
							>
								{option?.value?.name || ""}
							</MenuItemText>

							<Box display="flex">
								{AddIcon(20, 20, theme.palette.primary.main)}
							</Box>
						</Stack>
					) : (
						<MenuItemText>{option?.value?.name || ""}</MenuItemText>
					)}
				</Box>
			)}
			filterOptions={filterOptions}
			renderInput={(params) => (
				<TextField
					{...params}
					inputRef={inputRef}
					value={searchValue}
					onChange={handleChangeValue}
					placeholder={
						isMenuExpanded || values?.length <= 0 ? placeholder : ""
					}
					type={type ? type : "text"}
					sx={{
						backgroundColor: "#fff",
						borderRadius: "8px",
						"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
							{
								"-webkit-appearance": "none",
							},
						"& .MuiAutocomplete-inputRoot": {
							flexWrap: "nowrap",
						},
						...style,
					}}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<InputAdornment position="start">
								<Box
									style={{
										transform:
											isMenuOpened() && "rotate(180deg)",
									}}
									display="flex"
								>
									{DropdownIcon(14, 14, "rgb(0 0 0 / 54%)")}
								</Box>
							</InputAdornment>
						),
					}}
					error={error?.error}
					inputProps={{
						...params.inputProps,
						style: {
							fontSize: "14px",
							width: "40px",
						},
					}}
				/>
			)}
		/>
	);
}

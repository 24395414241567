import React, { useEffect } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { filterConnectorsList } from "../../../../../../utils/FilterUtills";
import { useFilterDataContext } from "../../Context/FilterDataContext";
import SingleCriteria from "./SingleCriteria";
import { default as closeIcon } from "../../../../../../assets/icons/close";

export default function CriteriaList(props) {
	const { connector } = props;

	const styles = {
		header: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			paddingLeft: "10px",
		},
	};

	const {
		filterState,
		updateFilterUICriteriaList,
		enableApplyButton,
		disableApplyButton,
	} = useFilterDataContext();

	// Remove Filter
	const removeSingleFilterCondition = (index) => {
		const modifiedFilterCriteria = [...filterState.filterUICriteriaList];
		modifiedFilterCriteria.splice(index, 1);
		updateFilterUICriteriaList([...modifiedFilterCriteria]);
		enableApplyButton();
	};

	const onChange = (condition) => {
		let modifiedFilterCriteria = [...filterState.filterUICriteriaList];
		modifiedFilterCriteria = modifiedFilterCriteria.map((filter) =>
			filter.id === condition.id ? condition : filter
		);

		updateFilterUICriteriaList(modifiedFilterCriteria);
	};

	//To Enable and disable apply button based on applied filter conditions
	useEffect(() => {
		if (
			filterState?.activeFilterDetails?.criteriaList?.length > 0 &&
			filterState.filterUICriteriaList.length > 0
		) {
			var isFilterConditionsAreSame = false;
			if (
				filterState?.activeFilterDetails?.criteriaList?.length !=
				filterState.filterUICriteriaList.length
			) {
				isFilterConditionsAreSame = false;
			} else {
				isFilterConditionsAreSame =
					filterState.filterUICriteriaList.every((filter, index) => {
						const activeFilter =
							filterState?.activeFilterDetails?.criteriaList[
								index
							];

						const fieldMatch =
							(filter?.fieldId?.value.name ||
								filter?.optionId?.value.name) ===
							activeFilter?.field?.name;

						const comparatorMatch =
							filter?.comparatorId?.value?.name ===
							activeFilter?.comparator?.name;

						const valueMatch = activeFilter?.values
							? filter?.value?.value ===
								activeFilter?.values?.[0]?.value
							: true;

						const additionalOptionMatch = activeFilter?.field
							?.additionalOption
							? filter?.additionalOption?.displayName ===
								activeFilter?.field?.additionalOption
									?.displayName
							: true;

						return (
							fieldMatch &&
							comparatorMatch &&
							valueMatch &&
							additionalOptionMatch
						);
					});
			}

			if (isFilterConditionsAreSame) {
				disableApplyButton();
			} else {
				enableApplyButton();
			}
		}
	}, [
		filterState.filterUICriteriaList,
		enableApplyButton,
		disableApplyButton,
		filterState?.activeFilterDetails?.criteriaList,
	]);

	return (
		<React.Fragment>
			{filterState.filterUICriteriaList?.map((data, index) => (
				<Box key={index}>
					{(connector === filterConnectorsList.AND &&
						data.connectorId.value.name ===
							filterConnectorsList.AND) ||
					(connector === filterConnectorsList.OR &&
						data.connectorId.value.name ===
							filterConnectorsList.OR) ? (
						<Box p={1}>
							<Stack>
								<Stack
									direction="row"
									style={styles.header}
									key={index}
								>
									<Typography fontSize={13} fontWeight={500}>
										{data.sourceId.value.name}
									</Typography>
									<IconButton
										style={{ cursor: "pointer" }}
										onClick={() =>
											removeSingleFilterCondition(index)
										}
									>
										{closeIcon(20, 20, "#000", "0.6")}
									</IconButton>
								</Stack>

								<SingleCriteria
									filterCondition={data}
									onChange={onChange}
								/>
							</Stack>
						</Box>
					) : null}
				</Box>
			))}
		</React.Fragment>
	);
}

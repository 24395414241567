import { phoneNumberPattern } from "../../config";

export const validateMultiplePhonesField = (field, config, isActionField) => {
	const values = field?.values || [];
	const min = 5;
	const max = 17;

	// Check if values are empty
	if (values.length === 0) {
		return {
			error: true,
			message: isActionField ? "" : "Can’t be empty",
		};
	}

	// Check for exceeding maxLimit
	if (values.length > config?.maxLimit) {
		return {
			error: true,
			message: `Max. of ${config.maxLimit} numbers are allowed`,
		};
	}

	// Validate length of each value
	const isInvalidLength = values.some((value) => {
		const nameLength =
			value?.value?.name?.length || value?.value?.length || 0;
		if (!value?.dependsOn) {
			return nameLength < min || nameLength > max;
		}
		return false;
	});

	if (isInvalidLength) {
		return {
			error: true,
			message:
				"Min. of 5 numbers are required / Max. of 17 numbers are allowed",
		};
	}

	const hasInvalidPattern = values.some((value) => {
		let phoneNumber = value?.value?.name || value?.value;
		if (!value?.dependsOn) {
			return !phoneNumberPattern.test(phoneNumber);
		}
		return false;
	});

	if (hasInvalidPattern) {
		return {
			error: true,
			message:
				"Please enter a valid number in the format '+1 (202) 555-0143'.",
		};
	}

	return { error: false, message: "" };
};

import React, { useEffect, useState } from "react";
import DropdownBaseComponent from "../DropdownBaseComponent";
import { useQuery } from "@tanstack/react-query";
import {
	getDependentDropdownKeyByFieldId,
	getDropdownKeyByFieldId,
	getDropdownKeyByFieldName,
} from "../../../../../utils/queryKeys";
import { getFieldDropdownData } from "../../../../../api/fields/config/configApi";
import { getActionStepOptionKey } from "../../../../../utils/queryKeys/workflow";
import { getActionStepOptionData } from "../../../../../api/workflow/workflowApi";

export default function DropdownComponent(props) {
	const {
		field,
		metadata,
		style,
		error,
		updateFieldValue,
		formValues,
		value,
		clearError,
	} = props;
	const [selected, setSelected] = useState("");

	const dependentDropdownValue = formValues?.[field?.config?.dependsOn];

	useEffect(() => {
		if (value) {
			setSelected(value);
		} else {
			setSelected("");
		}
	}, [value]);

	const isDependsOnKeyAvailable = () => {
		return !!field.config?.dependsOn;
	};

	const getDropdownKey = () => {
		if (isDependsOnKeyAvailable()) {
			return getDependentDropdownKeyByFieldId(
				field?.fieldId,
				dependentDropdownValue?.value?.id
			);
		} else if (
			Array.isArray(field?.dropdown?.requiredKeys) &&
			field?.dropdown?.requiredKeys?.includes("id")
		) {
			return getDropdownKeyByFieldId(field?.fieldId);
		} else if (field?.dropDown?.id) {
			return getDropdownKeyByFieldId(field?.dropDown?.id);
		} else {
			return getDropdownKeyByFieldName(
				field?.dropdown?.name || field?.dropDown?.name
			);
		}
	};

	const getDropdownRequestData = () => {
		if (isDependsOnKeyAvailable()) {
			if (formValues[field.config.dependsOn]) {
				return {
					id: field.fieldId,
					dependentId: dependentDropdownValue?.value?.id,
				};
			}
		} else if (
			Array.isArray(field?.dropdown?.requiredKeys) &&
			field?.dropdown?.requiredKeys?.includes("id")
		) {
			return { id: field.fieldId };
		} else if (field?.dropDown?.id) {
			return { id: field?.dropDown?.id };
		} else {
			return { name: field?.dropdown?.name || field?.dropDown?.name };
		}
	};

	const { data: options } = useQuery(
		getDropdownKey(),
		() => getFieldDropdownData(getDropdownRequestData() || field.id),
		{
			enabled: !!field?.fieldId,
			select: (data) => {
				//TODO - Get consistent value from dropdown API
				return (
					data?.options?.map((option) => {
						if (option.title) {
							option["name"] = option["title"];
						}
						if (option.id) {
							option["value"] = option["id"];
						} else if (option.value) {
							option["id"] = option["value"];
						}
						return option;
					}) || []
				);
			},
		}
	);

	const { data: stepOptions } = useQuery(
		getActionStepOptionKey(metadata.triggerSourceId, field.fieldId, [
			{ source: metadata.actionSource, event: metadata.actionEvent },
		]),
		() =>
			getActionStepOptionData(metadata.triggerSourceId, field.fieldId, [
				{ source: metadata.actionSource, event: metadata.actionEvent },
			]),
		{
			enabled: !!(
				!metadata ||
				(field?.fieldId && field?.config?.hasStepOptions)
			),
			select: (data) => data?.stepOptions,
		}
	);

	const onFieldChange = (value) => {
		setSelected(value);
		updateFieldValue([value], field.fieldId);
		clearError(field.fieldId);
	};

	return (
		<React.Fragment>
			<DropdownBaseComponent
				hasStepOptions={field?.config?.hasStepOptions}
				selected={selected}
				options={options || []}
				onFieldChange={onFieldChange}
				multiDropdown={field.fieldType === "MULTI_DROPDOWN"}
				stepOptions={stepOptions || []}
				style={style}
				error={error}
			/>
		</React.Fragment>
	);
}

import React, { useEffect, useMemo } from "react";
import { Box, Divider, Stack } from "@mui/material";
import { FormFieldName } from "../../../../../../../styles/twozo";
import { useWatch } from "react-hook-form";
import { useDuration } from "../../../../../../../hooks/services/analytics";
import Field from "../../Field";

const hideBorderSx = {
	".MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"& .MuiSelect-select": {
		paddingLeft: 2,
	},
	height: "42px",
	width: "100%",
};

export default function CompareTo(props) {
	const { control, unregister, index } = props;
	const [moduleId, compareUnitId, from] = useWatch({
		control,
		name: [
			`viewProperties.metrics.${index}.moduleId`,
			"viewProperties.compareTo.compareUnitId",
			"viewProperties.compareTo.from",
		],
	});

	const { data: durationData } = useDuration(moduleId, {
		enabled: !!moduleId,
	});

	const compareToOptions = useMemo(() => {
		if (durationData) {
			return (
				durationData.compareTo?.map((option) => ({
					id: option.compareUnitId,
					label: option.compareUnitName,
					isCustom: !!option?.isCustom,
				})) || []
			);
		}
		return [];
	}, [durationData]);

	const isCustomField = useMemo(() => {
		return compareToOptions?.find((option) => option?.id === compareUnitId)
			?.isCustom;
	}, [compareToOptions, compareUnitId]);

	useEffect(() => {
		if (!isCustomField) {
			unregister("viewProperties.compareTo.from");
			unregister("viewProperties.compareTo.to");
		}
	}, [isCustomField, unregister, compareUnitId]);

	return (
		<React.Fragment>
			<Stack spacing={1.5}>
				<Stack spacing={0.5}>
					<FormFieldName>Compare To</FormFieldName>
					<Box
						style={{
							border: "1px solid rgba(0, 0, 0, 0.1)",
							borderRadius: "8px",
						}}
					>
						<Field
							fieldType="DROPDOWN"
							name="viewProperties.compareTo.compareUnitId"
							control={control}
							options={compareToOptions}
							disabled={!moduleId}
							sx={hideBorderSx}
							rules={{
								required: {
									value: true,
									message: "Compare To is required",
								},
							}}
						/>

						{isCustomField && (
							<>
								<Divider />

								<Field
									fieldType="FROM"
									label="From"
									name="viewProperties.compareTo.from"
									control={control}
									rules={{
										required: "From is required",
									}}
								/>

								<Divider />

								<Field
									fieldType="TO"
									label="To"
									fromValue={from}
									name="viewProperties.compareTo.to"
									control={control}
									rules={{
										required: "To is required",
									}}
								/>
							</>
						)}
					</Box>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}

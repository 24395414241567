import React, { useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	InputAdornment,
	Radio,
	Stack,
	TextField,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";
import { default as DealIcon } from "../../../../assets/icons/deals";
import { Tooltip } from "../../../../styles/twozo";
import {
	useDealFieldList,
	useMergeDealDetails,
	useDealMerge,
} from "../../../../hooks/services/deal/dealMerge/dealMerge";
import Preview from "./Preview";
import DealMergeTableCell from "./DealMergeTableCell";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { useNavigate } from "react-router-dom";
import DealAutocomplete from "../../../Elements/DealAutoCompleteComponent";

export default function MergeDeal(props) {
	const { onClose, dealId } = props;
	const theme = useTheme();
	const navigate = useNavigate();
	const [selected, setSelected] = useState("source");

	const [selectedDealValue, setSelectedDealValue] = useState(null);
	const [isPreviewOpened, setIsPreviewOpened] = useState(false);

	const primaryDealId =
		selected === "source" ? dealId : selectedDealValue?.value;
	const secondaryDealId =
		selected === "source" ? selectedDealValue?.value : dealId;

	const { data: dealFields, isLoading: isLoadingDealFields } =
		useDealFieldList();

	const { data: sourceDealData } = useMergeDealDetails(dealId);
	const { data: destinationDealData } = useMergeDealDetails(
		selectedDealValue?.value
	);
	const dealMergeMutation = useDealMerge(dealId);

	const handleSelectDeal = (value) => {
		setSelectedDealValue(value);
	};

	const handleMerge = () => {
		let requestDataForDealMerge = {
			primaryDealId: primaryDealId,
			secondaryDealId: secondaryDealId,
		};
		dealMergeMutation.mutate(requestDataForDealMerge, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealMerge,
				});
				onClose();
				navigate(`/deals/${primaryDealId?.toString()}`);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<Stack
				style={{
					height: "100%",
				}}
			>
				<Box p={2}>
					<Typography fontWeight={600} fontSize={17}>
						Deal Merge
					</Typography>
				</Box>

				<Divider />

				{isLoadingDealFields ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="100vh"
					>
						<CircularProgress />
					</Stack>
				) : (
					<Box
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
							paddingRight: "16px",
						}}
					>
						{isPreviewOpened ? (
							<Preview
								dealFields={dealFields}
								primaryId={primaryDealId}
								secondaryId={secondaryDealId}
							/>
						) : (
							<table
								style={{
									width: "100%",
									borderSpacing: "16px 0px",
									borderCollapse: "separate",
									tableLayout: "fixed",
								}}
								cellPadding={0}
							>
								<tbody>
									<tr style={{ paddingRight: "16px" }}>
										<td
											style={{
												borderRight:
													"1px solid #E0E0E0",
												width: "30%",
											}}
										/>

										<td
											style={{
												padding: "16px 0px",
												width: "40%",
											}}
										>
											<Stack direction="row">
												<Tooltip
													title="Preserve these values"
													placement="top"
												>
													<Radio
														onClick={() =>
															setSelected(
																"source"
															)
														}
														checked={
															selected ===
															"source"
														}
													/>
												</Tooltip>

												<TextField
													value={
														sourceDealData?.title ||
														""
													}
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																{DealIcon(
																	20,
																	20,
																	"#666"
																)}
															</InputAdornment>
														),
														readOnly: true,
													}}
													fullWidth
												/>
											</Stack>
										</td>

										<td
											style={{
												padding: "16px 0px",
												width: "40%",
											}}
										>
											<Stack direction="row" width="100%">
												<Tooltip
													title="Preserve these values"
													placement="top"
												>
													<Radio
														onClick={() =>
															setSelected(
																"destination"
															)
														}
														checked={
															selected ===
															"destination"
														}
													/>
												</Tooltip>

												<Box width="100%">
													<DealAutocomplete
														value={
															selectedDealValue
														}
														onChange={
															handleSelectDeal
														}
														onCancel={() =>
															setSelectedDealValue(
																""
															)
														}
														filterCriteria={{
															excludeDealId:
																dealId,
														}}
													/>
												</Box>
											</Stack>
										</td>
									</tr>

									{dealFields?.map((field, index) => (
										<tr
											key={index}
											style={{ padding: "10px" }}
										>
											<td>
												<Box
													style={{
														height: "42px",
														display: "flex",
														alignItems: "center",
														justifyContent:
															"flex-end",
														borderRight:
															"1px solid #E0E0E0",
													}}
													pr={3}
												>
													<Typography
														fontSize={14}
														fontWeight={500}
													>
														{field?.name}
													</Typography>
												</Box>
											</td>
											<td>
												<Box
													style={{
														height: "42px",
														display: "flex",
														alignItems: "center",
														borderBottom:
															index ===
															dealFields?.length -
																1
																? "none"
																: "1px solid #E0E0E0",
														borderRadius:
															index === 0
																? "8px 8px 0px 0px"
																: index ===
																			dealFields?.length -
																				1 &&
																	  selected ===
																			"source"
																	? "0px 0px 8px 8px"
																	: "0px",
														backgroundColor:
															selected ===
															"source"
																? theme.palette
																		.secondary
																		.main
																: "transparent",
													}}
													px={2}
												>
													<DealMergeTableCell
														fieldType={
															field?.storeType
														}
														fieldValue={
															sourceDealData?.[
																field?.key
															]
														}
													/>
												</Box>
											</td>
											<td>
												<Box
													style={{
														height: "42px",
														display: "flex",
														alignItems: "center",
														borderBottom:
															index ===
															dealFields?.length -
																1
																? "none"
																: "1px solid #E0E0E0",
														borderRadius:
															index === 0
																? "8px 8px 0px 0px"
																: index ===
																			dealFields?.length -
																				1 &&
																	  selected ===
																			"destination"
																	? "0px 0px 8px 8px"
																	: "0px",
														backgroundColor:
															selected ===
															"destination"
																? theme.palette
																		.secondary
																		.main
																: "transparent",
													}}
													px={2}
												>
													<DealMergeTableCell
														fieldType={
															field?.storeType
														}
														fieldValue={
															destinationDealData?.[
																field?.key
															]
														}
													/>
												</Box>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						)}
					</Box>
				)}

				<Divider />

				<Box px={3} py={2.5}>
					<Stack
						direction="row"
						justifyContent={
							isPreviewOpened ? "space-between" : "flex-end"
						}
					>
						<Box hidden={!isPreviewOpened}>
							<Button
								variant="contained"
								color="secondary"
								disableElevation
								onClick={() => setIsPreviewOpened(false)}
							>
								Back
							</Button>
						</Box>

						<Stack direction="row" spacing={2}>
							<Button
								variant="contained"
								color="secondary"
								disableElevation
								onClick={onClose}
							>
								Cancel
							</Button>

							{isPreviewOpened ? (
								<Button
									variant="contained"
									disableElevation
									onClick={handleMerge}
									disabled={dealMergeMutation.isLoading}
								>
									Merge
								</Button>
							) : (
								<Button
									variant="contained"
									disableElevation
									onClick={() => setIsPreviewOpened(true)}
									disabled={!selectedDealValue}
									sx={{
										"&.Mui-disabled": {
											backgroundColor:
												"rgba(51, 188, 126)",
											color: alpha("#FFFFFF", 0.6),
										},
									}}
								>
									Preview
								</Button>
							)}
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</React.Fragment>
	);
}

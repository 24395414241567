import {
	Box,
	Button,
	Divider,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import DropDown from "../../../../../assets/icons/dropDown";
import { useEffect, useRef, useState } from "react";
import Menu from "../../../../Elements/Menu";
import ClosedLost from "../ClosedLost";
import Dialog from "../../../../Elements/Dialog";
import {
	useLifecycleStageProgress,
	useUpdateContactLifecycleStatus,
} from "../../../../../hooks/services/contact";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { MenuItemText } from "../../../../../styles/twozo";

const status = {
	COMPLETED: "completed",
	PENDING: "pending",
	LOST: "lost",
};

const state = {
	LOST: 1,
	WON: 2,
};

export default function LifecycleStageStatusView({ contactId }) {
	const theme = useTheme();
	const statusCardContainerRef = useRef(null);

	// query call:-
	const { data: stageProgressList } = useLifecycleStageProgress(contactId);

	// mutation call:-
	const updateMutation = useUpdateContactLifecycleStatus(contactId);

	const [
		hideStatusCardContainerScrollButton,
		setHideStatusCardContainerScrollButton,
	] = useState(false);

	const [statuses, setStatuses] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState("");

	const [statusMenuElement, setStatusMenuElement] = useState(null);
	const isStatusMenuOpened = Boolean(statusMenuElement);
	const [isLostDialogOpened, setIsLostDialogOpened] = useState(false);

	const handleUpdateStatus = (lifeCycleStageId, lifeCycleStatusId) => {
		let requestData = {
			id: contactId,
			lifeCycleStageId: lifeCycleStageId,
			lifeCycleStatusId: lifeCycleStatusId,
		};

		updateMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.contactLifecycleUpdated,
				});
				closeStatusMenu();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const onStatusClick = (event, status) => {
		if (status?.dropdown) {
			setStatusMenuElement(event.currentTarget);
			setStatuses(status?.dropdown || []);
		} else {
			handleUpdateStatus(status?.lifeCycleStageId, status?.id);
		}
	};

	const openLostDialog = () => {
		setIsLostDialogOpened(true);
	};

	const closeLostDialog = () => {
		setIsLostDialogOpened(false);
	};

	const closeStatusMenu = () => {
		setStatusMenuElement(null);
	};

	const getStatusCardBgColor = (value) => {
		switch (value) {
			case status.COMPLETED:
				return theme.palette.primary.main;
			case status.LOST:
				return theme.palette.error.main;
			case status.PENDING:
				return "#fff";
		}
	};

	const getStageNameColor = (value) => {
		switch (value) {
			case status.COMPLETED:
			case status.LOST:
				return "#fff";
			case status.PENDING:
				return "#00000099";
		}
	};

	const getStatusNameColor = (label) => {
		switch (label) {
			case state.WON:
				return theme.palette.primary.main;
			case state.LOST:
				return theme.palette.error.main;
			default:
				"#000";
		}
	};

	useEffect(() => {
		const scrollableContainer = statusCardContainerRef.current;
		const { scrollWidth, clientWidth } = scrollableContainer;

		if (clientWidth < scrollWidth) {
			setHideStatusCardContainerScrollButton(true);
		} else {
			setHideStatusCardContainerScrollButton(false);
		}
	}, [stageProgressList]);

	const scroll = (scrollOffset) => {
		const scrollableContainer = statusCardContainerRef.current;
		if (scrollableContainer) {
			const { scrollLeft } = scrollableContainer;

			const start = scrollLeft;
			const end = start + scrollOffset;
			const duration = 300;
			let startTime;

			const animateScroll = (timestamp) => {
				if (!startTime) startTime = timestamp;
				const progress = (timestamp - startTime) / duration;
				const scrollPosition = start + scrollOffset * progress;

				scrollableContainer.scrollLeft = scrollPosition;

				if (progress < 1) {
					requestAnimationFrame(animateScroll);
				} else {
					scrollableContainer.scrollLeft = end;
				}
			};
			requestAnimationFrame(animateScroll);
		}
	};

	const handleOpenLostMenuOrUpdateStatus = (status) => {
		if (status?.label === state.LOST) {
			openLostDialog();
			setSelectedStatus(status);
		} else {
			handleUpdateStatus(status?.lifeCycleStageId, status?.id);
		}
	};

	const onUpdateStatusSuccess = () => {
		closeLostDialog();
		closeStatusMenu();
	};

	return (
		<>
			<Dialog open={isLostDialogOpened} title="Closed Lost">
				<ClosedLost
					statusData={{
						name: selectedStatus?.name,
						value: selectedStatus?.id,
					}}
					contactId={contactId}
					lifeCycleStageId={selectedStatus?.lifeCycleStageId}
					lifeCycleStatusId={selectedStatus?.id}
					closeLostDialog={closeLostDialog}
					onUpdateStatusSuccess={onUpdateStatusSuccess}
				/>
			</Dialog>

			<Menu
				minWidth="250px"
				anchorEl={statusMenuElement}
				open={isStatusMenuOpened}
				onClose={closeStatusMenu}
				style={{ marginTop: "8px" }}
			>
				{statuses?.length > 0 &&
					statuses?.map((status) => (
						<MenuItem
							key={status.id}
							style={{
								minHeight: "40px",
							}}
							onClick={() =>
								handleOpenLostMenuOrUpdateStatus(status)
							}
						>
							<Stack
								minWidth="202px"
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<MenuItemText
									color={getStatusNameColor(status?.label)}
								>
									{status.name}
								</MenuItemText>
							</Stack>
						</MenuItem>
					))}
			</Menu>

			<Box width="100%">
				<Typography fontSize={14} fontWeight={400}>
					Status
				</Typography>

				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
					}}
				>
					<Stack
						direction="row"
						spacing={2}
						justifyContent="space-between"
						width="100%"
					>
						<Box
							display="flex"
							alignItems="center"
							py={0.5}
							sx={{
								backgroundColor: "#E8F5E9",
								borderRadius: "8px",
							}}
							style={{
								flex: "1 1 auto",
								minWidth: 0,
								overflowX: "hidden",
								maxWidth: "1120px",
							}}
							ref={statusCardContainerRef}
						>
							{stageProgressList?.map((status, index) => (
								<Box
									key={index}
									sx={{
										display: "flex",
										alignItems: "center",
										flexShrink: 0,
									}}
								>
									{/* Chevron Shape */}
									<Box
										onClick={(event) =>
											onStatusClick(event, status)
										}
										sx={{
											backgroundColor:
												getStatusCardBgColor(
													status.status
												),
											color: status.completed
												? "#FFFFFF"
												: "#9E9E9E",
											padding: "7px 35px",
											maxWidth: "160px",
											borderRadius:
												index === 0
													? "10px 0 0 10px"
													: index ===
														  stageProgressList?.length -
																1
														? "0 10px 10px 0"
														: "0",
											marginLeft:
												index === 0 ? 0 : "-20px",
											zIndex:
												stageProgressList?.length -
												index,
											cursor: "pointer",
											clipPath:
												index === 0
													? "polygon(0 0, calc(100% - 20px) 0, 100% 50%, calc(100% - 20px) 100%, 0 100%)" // Left side shape
													: index ===
														  stageProgressList?.length -
																1
														? "polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 20px 50%)"
														: "polygon(0 0, calc(100% - 20px) 0, 100% 50%, calc(100% - 20px) 100%, 0 100%, 20px 50%)", // Middle shape
										}}
									>
										<Stack direction="row" spacing={1}>
											<Typography
												noWrap
												sx={{
													fontWeight: 400,
													fontSize: "15px",
													color: getStageNameColor(
														status.status
													),
												}}
											>
												{status.name}
											</Typography>

											{status.dropdown ? (
												<Box display="flex">
													{DropDown(
														18,
														18,
														getStageNameColor(
															status.status
														)
													)}
												</Box>
											) : null}
										</Stack>
									</Box>

									{/* Add spacing between chevrons */}
									{index < stageProgressList?.length - 1 && (
										<Box
											sx={{
												width: "10px",
												height: "100%",
												backgroundColor: status.active
													? "#66BB6A"
													: "#E0E0E0",
											}}
										></Box>
									)}
								</Box>
							))}
						</Box>

						{hideStatusCardContainerScrollButton && (
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
							>
								<Button
									sx={{
										transform: "rotate(90deg)",
										minWidth: "0px",
										minHeight: "0px",
										padding: "10px 10px",
										"&:hover": {
											backgroundColor:
												"rgba(51, 188, 126, 0.24)",
										},
									}}
									disableRipple
									onClick={() => scroll(-280)}
								>
									{DropDown(
										19,
										19,
										theme.palette.secondary.contrastText
									)}
								</Button>

								<Divider
									orientation="vertical"
									sx={{
										height: "20px",
										backgroundColor:
											theme.palette.primary.main,
									}}
								/>

								<Button
									sx={{
										transform: "rotate(-90deg)",
										minWidth: "0px",
										minHeight: "0px",
										"&:hover": {
											backgroundColor:
												"rgba(51, 188, 126, 0.24)",
										},
									}}
									onClick={() => scroll(280)}
									color="secondary"
									disableRipple
								>
									{DropDown(
										19,
										19,
										theme.palette.secondary.contrastText
									)}
								</Button>
							</Stack>
						)}
					</Stack>
				</Box>
			</Box>
		</>
	);
}

import React, { useState } from "react";
import {
	Box,
	Checkbox,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CopyIcon } from "../../../../../assets/icons/copy";
import { default as EditIcon } from "../../../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../../../assets/icons/delete";
import {
	useCloneEmailTemplate,
	useDeleteEmailTemplate,
} from "../../../../../hooks/services/mail/emailTemplate";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import DeleteDialog from "../../../../Elements/DeleteDialog";
import CustomSwipeableDrawer from "../../../../Elements/CustomSwipeableDrawer";
import EditTemplate from "../../EditTemplate";
import { MenuItemText, twozoStyles } from "../../../../../styles/twozo";
import TemplateViewer from "../../TemplateViewer";
import Menu from "../../../../Elements/Menu";

const styles = {
	headerText: {
		fontSize: "13px",
		fontWeight: 500,
		color: "rgba(0, 0, 0, 0.6)",
	},
	text: {
		fontSize: "14px",
	},
};

export default function EmailTemplate(props) {
	const {
		emailTemplateData,
		templateFolderId,
		handleSelectTemplate,
		selectedTemplateIds,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();

	const [hoveredTemplateId, setHoveredTemplateId] = useState(false);
	const [showTemplateDeleteDialog, setShowTemplateDeleteDialog] =
		useState(false);
	const [isTemplateEditDrawerOpened, setIsTemplateEditDrawerOpened] =
		useState(false);
	const [isTemplateViewerOpened, setIsTemplateViewerOpened] = useState(false);
	const [templateTagsMenuElement, setTemplateTagsMenuElement] =
		useState(null);
	const isTemplateTagsMenuOpened = Boolean(templateTagsMenuElement);

	const successNotificationTitle = "Success!";
	const supplementaryTags = emailTemplateData?.tags?.slice(1);

	const cloneEmailTemplateMutation = useCloneEmailTemplate(templateFolderId);
	const deleteEmailTemplateMutation =
		useDeleteEmailTemplate(templateFolderId);

	const onMouseOverTemplate = () => {
		setHoveredTemplateId(emailTemplateData?.id);
	};

	const onMouseOutTemplate = () => {
		setHoveredTemplateId("");
	};

	const handleCloneEmailTemplate = (event) => {
		event.stopPropagation();
		cloneEmailTemplateMutation.mutate(emailTemplateData.id, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.emailTemplateCloned,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const openTemplateDeleteDialog = (event) => {
		event.stopPropagation();
		setShowTemplateDeleteDialog(true);
	};

	const closeTemplateDeleteDialog = () => {
		setShowTemplateDeleteDialog(false);
	};

	const handleDeleteEmailTemplate = () => {
		deleteEmailTemplateMutation.mutate(emailTemplateData.id, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.emailTemplateDeleted,
					title: successNotificationTitle,
				});
				closeTemplateDeleteDialog();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const openTemplateEditDrawer = (event) => {
		event.stopPropagation();
		setIsTemplateEditDrawerOpened(true);
	};

	const closeTemplateEditDrawer = () => {
		setIsTemplateEditDrawerOpened(false);
	};

	const openTemplateViewer = () => {
		setIsTemplateViewerOpened(true);
	};

	const closeTemplateViewDrawer = () => {
		setIsTemplateViewerOpened(false);
	};

	const openTagsMenu = (event) => {
		event.stopPropagation();
		setTemplateTagsMenuElement(event.currentTarget);
	};

	const closeTemplateTagsMenu = () => {
		setTemplateTagsMenuElement(null);
	};

	const getBackgroundColor = () => {
		if (selectedTemplateIds.includes(emailTemplateData?.id)) {
			return theme.palette.secondary.main;
		} else if (hoveredTemplateId === emailTemplateData.id) {
			return "rgba(0, 0, 0, 0.02)";
		}
		return "#fff";
	};

	return (
		<React.Fragment>
			{/* Delete template dialog */}
			<DeleteDialog
				title={`Are you sure you want to delete template - ${emailTemplateData.name}?`}
				subtitle="This will delete the template with all the metrics associated with it"
				open={showTemplateDeleteDialog}
				onCancel={closeTemplateDeleteDialog}
				onDelete={handleDeleteEmailTemplate}
				disableDeleteButton={deleteEmailTemplateMutation.isLoading}
			></DeleteDialog>

			{/* Edit template drawer */}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isTemplateEditDrawerOpened}
				onOpen={openTemplateEditDrawer}
				onClose={closeTemplateEditDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditTemplate
						onCloseEditTemplateDrawer={closeTemplateEditDrawer}
						templateId={emailTemplateData.id}
						templateFolderId={templateFolderId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{/*Template viewer */}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isTemplateViewerOpened}
				onOpen={openTemplateViewer}
				onClose={closeTemplateViewDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<TemplateViewer
						templateId={emailTemplateData.id}
						templateFolderId={templateFolderId}
						onCloseTemplateViewer={closeTemplateViewDrawer}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{/* Tags Menu */}
			<Menu
				minWidth="200px"
				anchorEl={templateTagsMenuElement}
				open={isTemplateTagsMenuOpened}
				onClose={closeTemplateTagsMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				style={{
					marginTop: "4px",
				}}
				menuListProps={{
					padding: "4px 0px",
				}}
			>
				{supplementaryTags?.map((tagData) => (
					<MenuItem
						key={tagData.id}
						sx={{
							height: "40px",
						}}
					>
						<MenuItemText>{tagData.name}</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				style={{
					borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					cursor: "pointer",
					height: "42px",
					width: "100%",
					backgroundColor: getBackgroundColor(),
				}}
				px={1}
				onMouseOver={onMouseOverTemplate}
				onMouseOut={onMouseOutTemplate}
				onClick={openTemplateViewer}
			>
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={1.5}
					width="40%"
				>
					<Box>
						<Checkbox
							onClick={(event) => {
								event.stopPropagation();
								handleSelectTemplate(emailTemplateData.id);
							}}
							checked={selectedTemplateIds?.includes(
								emailTemplateData.id
							)}
						/>
					</Box>

					<Typography style={styles.text}>
						{emailTemplateData.name}
					</Typography>

					<Box>
						{emailTemplateData?.tags?.length > 0 ? (
							<Stack
								direction="row"
								spacing={1}
								alignItems="center"
								justifyContent="center"
							>
								<Box
									textAlign="center"
									borderRadius="6px"
									bgcolor="rgba(51, 188, 126, 0.12)"
									px={0.5}
									py={0.4}
								>
									<Typography
										fontSize={13}
										fontWeight={500}
										color={theme.palette.primary.main}
									>
										{emailTemplateData.tags[0]?.name}
									</Typography>
								</Box>

								{emailTemplateData.tags?.length > 1 && (
									<Box
										display="flex"
										color={theme.palette.primary.main}
										fontSize={14}
										fontWeight={500}
										sx={{ cursor: "pointer" }}
										onClick={openTagsMenu}
									>
										+{emailTemplateData.tags?.length - 1}
									</Box>
								)}
							</Stack>
						) : null}
					</Box>
				</Stack>

				<Stack direction="row" width="60%" alignItems="center">
					<Box width="15%">
						<Typography style={styles.text}>
							{emailTemplateData.statistics?.sentCount}
						</Typography>
					</Box>

					<Box width="15%">
						<Typography style={styles.text}>
							{emailTemplateData.statistics?.openedPercentage}
						</Typography>
					</Box>

					<Box width="15%">
						<Typography style={styles.text}>
							{emailTemplateData.statistics?.clickedPercentage}
						</Typography>
					</Box>

					<Box width="15%">
						<Typography style={styles.text}>
							{emailTemplateData.statistics?.repliedPercentage}
						</Typography>
					</Box>

					<Box width="15%">
						<Typography style={styles.text}>
							{
								emailTemplateData.statistics
									?.unsubscribedPercentage
							}
						</Typography>
					</Box>

					<Stack
						width="25%"
						direction="row"
						justifyContent="flex-end"
					>
						<Box
							hidden={hoveredTemplateId !== emailTemplateData.id}
							style={{
								border: `1px solid ${theme.palette.primary.main}`,
								borderRadius: "8px",
								width: "150px",
							}}
							py={1}
							px={1.5}
						>
							<Stack
								direction="row"
								justifyContent="center"
								alignItems="center"
								spacing={1.5}
							>
								<IconButton
									sx={{ padding: "0px" }}
									onClick={handleCloneEmailTemplate}
								>
									{CopyIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>

								<Divider
									flexItem={true}
									orientation="vertical"
								/>

								<IconButton
									sx={{ padding: "0px" }}
									onClick={openTemplateEditDrawer}
								>
									{EditIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>

								<Divider
									flexItem={true}
									orientation="vertical"
								/>

								<IconButton
									sx={{ padding: "0px" }}
									onClick={openTemplateDeleteDialog}
								>
									{DeleteIcon(
										20,
										20,
										theme.palette.error.main
									)}
								</IconButton>
							</Stack>
						</Box>
					</Stack>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}

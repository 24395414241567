import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { default as DateIcon } from "../../../assets/icons/date";
import { useTheme } from "@mui/material";

export default function SingleDateTimePicker(props) {
	const {
		slots,
		slotProps,
		popperProps,
		textFieldProps,
		inputRef,
		error,
		...other
	} = props;
	const theme = useTheme();

	return (
		<React.Fragment>
			<DateTimePicker
				ampm
				format={"DD/MM/YYYY hh:mm A"}
				closeOnSelect={false}
				inputRef={inputRef}
				slots={{
					openPickerIcon: () =>
						DateIcon(
							20,
							20,
							error ? theme.palette.error.main : "#666666"
						),
					...slots,
				}}
				slotProps={{
					inputAdornment: {
						position: "start",
					},
					popper: {
						sx: {
							"& .MuiPickersDay-root": {
								"&.Mui-selected": {
									backgroundColor: theme.palette.primary.main,
									borderRadius: "8px",
									"&:hover": {
										border: `1px solid ${theme.palette.secondary.main}`,
										color: "#fff",
									},
								},
							},
							"& .MuiPickersDay-dayWithMargin": {
								borderRadius: "8px",
								fontSize: "14px",
								fontWeight: 500,
								"&:hover": {
									border: `1px solid ${theme.palette.primary.main}`,
									color: theme.palette.primary.main,
								},
							},
						},
						...popperProps,
					},
					textField: {
						inputProps: {
							style: {
								fontSize: "15px",
							},
						},
						placeholder: "DD/MM/YYYY hh:mm aa",
						error: !!error,
						...textFieldProps,
					},
					...slotProps,
				}}
				{...other}
			/>
		</React.Fragment>
	);
}

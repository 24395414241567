import React from "react";
import { Line } from "react-chartjs-2";
import { useTheme } from "@mui/material";

export default function LineChart({ chartData }) {
	const theme = useTheme();

	const colors = [theme.palette.primary.main, "#F4BE37"];

	const data = {
		labels: ["", ...chartData["xAxis"]["data"]],
		datasets: chartData.yAxis.map((data, index) => ({
			label: data["label"],
			data: [null, ...data["data"]],
			segment: {
				borderColor: colors[index % 2],
			},
			backgroundColor: colors[index % 2],
			pointBorderWidth: 0,
			pointRadius: 8,
			pointHoverRadius: 6,
			borderWidth: 3,
		})),
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "bottom",
				display: false,
			},
			title: {
				display: false,
			},
		},
		scales: {
			x: {
				title: {
					display: true,
					text: chartData["xAxis"]["label"],
					padding: {
						top: 12,
					},
				},
				grid: {
					display: false,
				},
			},
			y: {
				title: {
					display: true,
					text: chartData["yAxis"][0]["label"],
					padding: {
						bottom: 12,
					},
				},
				grid: {
					display: false,
				},
				ticks: {
					// stepSize: 20,
				},
				beginAtZero: true,
			},
		},
	};

	return (
		<React.Fragment>
			<Line options={options} data={data} />
		</React.Fragment>
	);
}

import { useEffect, useMemo, useState } from "react";
import { useStepOptions } from "../../../../../hooks/services/workflow";
import AutocompleteBaseComponent from "../AutocompleteBaseComponent";
import { phoneNumberPattern } from "../../../WorkflowConfig/config";

export default function MultiplePhonesComponent(params) {
	const {
		field,
		updateFieldValue,
		error,
		metadata,
		value,
		style = {},
		placeholder,
		clearError,
		isConditionField,
	} = params;

	const { data: stepOptions } = useStepOptions(metadata, field);
	const [selectedPhones, setSelectedPhones] = useState([]);

	useEffect(() => {
		if (value && Array.isArray(value)) {
			let fieldValue = value.map((field) => {
				if (field.value?.name) {
					return field;
				}
				return {
					...field,
					value: {
						name: field.value,
					},
				};
			});

			setSelectedPhones(fieldValue);
		}
	}, [value]);

	const isValidPhoneNumber = (option) => {
		const minLimit = 5;
		const maxLimit = 17;

		if (!option.dependsOn) {
			return (
				option.value.name.length >= minLimit &&
				option.value.name.length <= maxLimit &&
				phoneNumberPattern.test(option.value.name)
			);
		} else {
			return true;
		}
	};

	const handleChangePhones = (phones) => {
		setSelectedPhones(phones);
		updateFieldValue(phones, field.fieldId);
		clearError(field.fieldId);
	};

	const stepOptionFields = useMemo(() => {
		if (Array.isArray(stepOptions)) {
			return stepOptions.flatMap((stepOption) =>
				stepOption.fields.map((field) => ({
					value: { id: field.id, name: field.name },
					dependsOn: stepOption.dependsOn,
				}))
			);
		}
		return [];
	}, [stepOptions]);

	return (
		<AutocompleteBaseComponent
			style={style}
			type="tel"
			placeholder={placeholder || "Enter"}
			isNewOption={true}
			error={error}
			isConditionField={isConditionField}
			values={selectedPhones}
			options={stepOptionFields}
			isValidOption={isValidPhoneNumber}
			onChange={handleChangePhones}
		/>
	);
}

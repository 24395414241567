import React, { useState } from "react";
import Menu from "../../../Elements/Menu";
import {
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { getDropdownDataByName } from "../../../../utils/dropdownData";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { activityFilterKeys } from "../../../../utils/ActivitiesUtils";
import { MenuItemText } from "../../../../styles/twozo/twozo";

export default function ActivityStatus(props) {
	const { handleSelectOption, handleUnselectOption } = props;
	const theme = useTheme();

	const style = {
		filterButton: {
			cursor: "pointer",
			alignItems: "center",
			borderRadius: "8px",
		},
	};

	const [statusMenuElement, setStatusMenuElement] = useState(null);
	const isStatusMenuOpened = Boolean(statusMenuElement);
	const [currentStatus, setCurrentStatus] = useState({});

	const statusList = getDropdownDataByName("ACTIVITY_STATUS");

	const openStatusMenu = (event) => {
		setStatusMenuElement(event.currentTarget);
	};

	const closeStatusMenu = () => {
		setStatusMenuElement(null);
	};

	const handleRemoveStatus = (event) => {
		event.stopPropagation();
		setCurrentStatus({});
		handleUnselectOption(activityFilterKeys.status);
	};

	const handleStatus = (status) => {
		setCurrentStatus(status);
		setStatusMenuElement(null);
		const criteria = getStatusFilterRequestData(status);
		handleSelectOption(activityFilterKeys.status, criteria);
	};

	const getStatusFilterRequestData = (status) => {
		const statusFilterRequestData = {};
		const selectedStatus = {};
		statusFilterRequestData.field = "status";
		statusFilterRequestData.comparator = "equal";
		selectedStatus.valueEntityId = status.value;
		statusFilterRequestData.values = [selectedStatus];
		return statusFilterRequestData;
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="202px"
				anchorEl={statusMenuElement}
				open={isStatusMenuOpened}
				onClose={closeStatusMenu}
				style={{ marginTop: "8px" }}
			>
				{statusList.map((status) => (
					<MenuItem
						key={status.value}
						style={{
							minHeight: "40px",
							backgroundColor:
								currentStatus.value === status.value
									? theme.palette.secondary.main
									: "transparent",
						}}
						onClick={() => handleStatus(status)}
					>
						<Stack
							minWidth="202px"
							direction="row"
							alignItems="center"
							justifyContent="space-between"
						>
							<MenuItemText>{status.name}</MenuItemText>

							{currentStatus.value === status.value && (
								<IconButton
									disableRipple
									style={{ padding: 0 }}
									onClick={(event) =>
										handleRemoveStatus(event)
									}
								>
									{CloseIcon(20, 20, "rgba(0,0,0,0.6)")}
								</IconButton>
							)}
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<Stack
				style={{
					...style.filterButton,
					backgroundColor: isStatusMenuOpened
						? theme.palette.secondary.main
						: "transparent",
				}}
				direction="row"
				spacing={1}
				py={0.5}
				px={1}
				onClick={openStatusMenu}
			>
				<Typography
					color={theme.palette.secondary.contrastText}
					fontSize={14}
					fontWeight={500}
				>
					{currentStatus.value ? currentStatus.name : "Status"}
				</Typography>

				{DropDownIcon(16, 16, theme.palette.primary.main)}
			</Stack>
		</React.Fragment>
	);
}

import React, { useEffect, useState } from "react";
import Menu from "../../../Elements/Menu";
import {
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { getDateMonthAndYearFormat } from "../../../../utils/DateUtils";
import { useActivityDueDateList } from "../../../../hooks/services/activities";
import { useSourceList } from "../../../../hooks/services/common/table/filter";
import { default as DropDownRight } from "../../../../assets/icons/dropDownRight";
import { activityFilterKeys } from "../../../../utils/ActivitiesUtils";
import DateRange from "./DateRange";
import { modules } from "../../../../utils/common/ModulesName";
import { MenuItemText } from "../../../../styles/twozo/twozo";

export default function ActivityDueDate(props) {
	const { handleSelectOption, handleUnselectOption } = props;
	const theme = useTheme();

	const style = {
		filterButton: {
			cursor: "pointer",
			alignItems: "center",
			borderRadius: "8px",
		},
	};

	const [dueDateMenuElement, setDueDateMenuElement] = useState(null);
	const isDueDateMenuOpened = Boolean(dueDateMenuElement);
	const [selectedDueDate, setSelectedDueDate] = useState({});
	const [dateRange, setDateRange] = useState("");
	const [dueDateList, setDueDateList] = useState([]);
	const [selectedDate, setSelectedDate] = useState({});
	const disableSaveButton = !selectedDate?.from || !selectedDate?.to;

	const { data: filterSourceList } = useSourceList();

	const sourceId =
		filterSourceList?.length >= 1
			? filterSourceList?.find(
					(module) => module.name === modules.ACTIVITY
				)?.id
			: null;

	const { data: activityDueDate, status: activityDueDateStatus } =
		useActivityDueDateList(sourceId);

	useEffect(() => {
		if (activityDueDateStatus === "success") {
			if (activityDueDate?.options) {
				setDueDateList(activityDueDate?.options[0]?.days);
			}
		}
	}, [activityDueDate, activityDueDateStatus]);

	const openDueDateMenu = (event) => {
		setDueDateMenuElement(event.currentTarget);
	};

	const closeDueDateMenu = () => {
		setDueDateMenuElement(null);
	};

	const resetDueDateList = (dateRange) => {
		if (dateRange) {
			//Remove the selected date and replace it with between
			const modifiedDueDateList = dueDateList.map((dueDate) => {
				if (dueDate.isRange) {
					return { ...dueDate, name: "Between" };
				} else {
					return dueDate;
				}
			});

			setDueDateList(modifiedDueDateList);
			setDateRange("");
			setSelectedDate({});
		}
	};

	const handleSelectDueDate = (dueDate) => {
		setSelectedDueDate(dueDate);
		resetDueDateList(dateRange);

		if (!dueDate.isRange) {
			setDueDateMenuElement(null);
			const criteria = getDueDateFilterRequestData(dueDate);
			handleSelectOption(activityFilterKeys.dueDate, criteria);
		}
	};

	const handleRemoveDueDate = (event) => {
		event.stopPropagation();
		resetDueDateList(dateRange);
		setSelectedDueDate({});
		handleUnselectOption(activityFilterKeys.dueDate);
	};

	const handleMoveBackToPrevious = () => {
		setSelectedDueDate({});
		setDateRange("");
	};

	const handleDateRangeValueChange = (newDateRangeValue) => {
		setSelectedDate(newDateRangeValue);
	};

	const handleSave = () => {
		const startDate = getDateMonthAndYearFormat(selectedDate.from);
		const endDate = getDateMonthAndYearFormat(selectedDate.to);
		setDateRange(`${startDate} - ${endDate}`);
		setDueDateMenuElement(null);
		setSelectedDueDate({});
		const criteria = getDueDateFilterRequestData(
			selectedDueDate,
			selectedDate
		);
		handleSelectOption(activityFilterKeys.dueDate, criteria);

		//Update the selected date instead of between in the menu
		const modifiedDueDateList = dueDateList.map((dueDate) => {
			if (dueDate.isRange) {
				return { ...dueDate, name: `${startDate} - ${endDate}` };
			} else {
				return dueDate;
			}
		});
		setDueDateList(modifiedDueDateList);
	};

	const getDueDateFilterRequestData = (selectedDueDate, selectedDate) => {
		const dueDateFilterRequestData = {};
		const dateRange = {};
		dueDateFilterRequestData.field = "dueDate";
		dueDateFilterRequestData.comparator = "equal";
		dateRange.valueEntityId = selectedDueDate.id;

		if (selectedDate) {
			dateRange.value = {};
			dateRange.value.start = selectedDate.from || "";
			dateRange.value.end = selectedDate.to || "";
		}

		dueDateFilterRequestData.values = [dateRange];
		return dueDateFilterRequestData;
	};

	const onDueDateSelect = (selectedDueDate, dueDate, dateRange) => {
		if (
			!(
				selectedDueDate.id === dueDate.id ||
				(dueDate.isRange && dateRange)
			)
		) {
			return () => handleSelectDueDate(dueDate);
		}
	};

	return (
		<React.Fragment>
			{/* Due Date Menu */}
			<Menu
				minWidth="200px"
				anchorEl={dueDateMenuElement}
				open={isDueDateMenuOpened}
				onClose={closeDueDateMenu}
				style={{ marginTop: "8px" }}
			>
				{selectedDueDate?.isRange ? (
					<DateRange
						handleDateRangeValueChange={handleDateRangeValueChange}
						handleSave={handleSave}
						handleMoveBackToPrevious={handleMoveBackToPrevious}
						disableSaveButton={disableSaveButton}
					/>
				) : (
					dueDateList.map((dueDate) => (
						<MenuItem
							key={dueDate.id}
							style={{
								minHeight: "40px",
							}}
							selected={
								selectedDueDate.id === dueDate.id ||
								!!(dueDate.isRange && dateRange)
							}
							onClick={onDueDateSelect(
								selectedDueDate,
								dueDate,
								dateRange
							)}
						>
							<Stack
								minWidth="202px"
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<MenuItemText>{dueDate.name}</MenuItemText>

								{(selectedDueDate.id === dueDate.id ||
									(dueDate.isRange && dateRange)) && (
									<IconButton
										disableRipple
										style={{ padding: 0 }}
										onClick={(event) =>
											handleRemoveDueDate(event)
										}
									>
										{CloseIcon(20, 20, "rgba(0,0,0,0.6)")}
									</IconButton>
								)}

								{dueDate.isRange && !dateRange && (
									<IconButton
										disableRipple
										style={{ padding: 0 }}
									>
										{DropDownRight(
											16,
											16,
											"rgba(0,0,0,0.6)"
										)}
									</IconButton>
								)}
							</Stack>
						</MenuItem>
					))
				)}
			</Menu>

			<Stack
				style={{
					...style.filterButton,
					backgroundColor: isDueDateMenuOpened
						? theme.palette.secondary.main
						: "transparent",
				}}
				direction="row"
				spacing={1}
				py={0.5}
				px={1}
				onClick={openDueDateMenu}
			>
				<Typography
					color={theme.palette.secondary.contrastText}
					fontSize={14}
					fontWeight={500}
				>
					{selectedDueDate.id && !selectedDueDate.isRange
						? selectedDueDate.name
						: dateRange
							? dateRange
							: "Due Date"}
				</Typography>

				{DropDownIcon(16, 16, theme.palette.primary.main)}
			</Stack>
		</React.Fragment>
	);
}

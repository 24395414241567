import React, { useState } from "react";
import { MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { default as ContactIcon } from "../../../../../../assets/icons/contact";
import { default as CompanyIcon } from "../../../../../../assets/icons/company";
import { default as DealIcon } from "../../../../../../assets/icons/deals";
import { useNavigate } from "react-router-dom";
import Menu from "../../../../Menu";
import { MenuItemText } from "../../../../../../styles/twozo";

export default function NewActivityTitle(props) {
	const { notificationData, onClose, isNotificationActionEnabled } = props;

	const theme = useTheme();
	const navigate = useNavigate();
	const [activityMenuElement, setActivityMenuElement] = useState(null);
	const isActivityMenuOpened = Boolean(activityMenuElement);

	const getEntityData = () => {
		if (notificationData?.data?.contacts?.length > 0) {
			return notificationData.data.contacts?.[0]?.name;
		} else if (notificationData?.data?.company) {
			return notificationData.data.company?.name;
		} else if (notificationData?.data?.deal) {
			return notificationData.data.deal?.name;
		}
		return "";
	};

	const navigateToContact = (contactId) => {
		if (contactId) {
			onClose();
			navigate(`/contacts/${contactId?.toString()}`);
		}
	};

	const navigateToCompany = (companyId) => {
		if (companyId) {
			onClose();
			navigate(`/companies/${companyId?.toString()}`);
		}
	};

	const navigateTodeal = (dealId) => {
		if (dealId) {
			onClose();
			navigate(`/deals/${dealId?.toString()}`);
		}
	};

	const navigateToEntity = (event) => {
		event.stopPropagation();
		if (notificationData?.data?.contacts?.length > 0) {
			navigateToContact(notificationData.data.contacts[0]?.id);
		} else if (notificationData?.data?.company) {
			navigateToCompany(notificationData.data.company?.id);
		} else if (notificationData?.data?.deal) {
			navigateTodeal(notificationData.data.deal?.id);
		}
	};

	const openActivityMenu = (event) => {
		if (isNotificationActionEnabled) {
			event.stopPropagation();
			setActivityMenuElement(event.currentTarget);
		}
	};

	const closeActivityMenu = (event) => {
		event.stopPropagation();
		setActivityMenuElement(null);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="250px"
				anchorEl={activityMenuElement}
				open={isActivityMenuOpened}
				onClose={closeActivityMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{notificationData?.data?.contacts?.map((contact) => (
					<MenuItem
						key={contact?.id}
						style={{ height: "40px" }}
						onClick={() => navigateToContact(contact?.id)}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							width="100%"
						>
							<MenuItemText
								color={theme.palette.secondary.contrastText}
							>
								{contact?.name}
							</MenuItemText>

							{ContactIcon(16, 16, "rgba(0, 0, 0, 0.6)")}
						</Stack>
					</MenuItem>
				))}

				{notificationData?.data?.company ? (
					<MenuItem
						style={{ height: "40px" }}
						onClick={() =>
							navigateToCompany(
								notificationData?.data?.company?.id
							)
						}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							width="100%"
						>
							<MenuItemText
								color={theme.palette.secondary.contrastText}
							>
								{notificationData?.data?.company?.name}
							</MenuItemText>

							{CompanyIcon(16, 16, "rgba(0, 0, 0, 0.6)")}
						</Stack>
					</MenuItem>
				) : null}

				{notificationData?.data?.deal ? (
					<MenuItem
						style={{ height: "40px" }}
						onClick={(event) =>
							navigateTodeal(
								notificationData?.data?.deal?.id,
								event
							)
						}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							width="100%"
						>
							<MenuItemText
								color={theme.palette.secondary.contrastText}
							>
								{notificationData?.data?.deal?.name}
							</MenuItemText>

							{DealIcon(16, 16, "rgba(0, 0, 0, 0.6)")}
						</Stack>
					</MenuItem>
				) : null}
			</Menu>

			<Typography
				component="span"
				fontSize={15}
				fontWeight={400}
				width="100px"
				noWrap
			>
				{notificationData?.data?.activityTitle}{" "}
				{getEntityData() ? "for" : ""}{" "}
				<Typography
					component="span"
					style={{
						fontSize: "14px",
						color: theme.palette.secondary.contrastText,
					}}
				>
					<Typography
						component="span"
						fontSize={15}
						onClick={navigateToEntity}
					>
						{getEntityData()}{" "}
					</Typography>

					{notificationData?.data?.contacts?.length > 1 ? (
						<Typography
							component="span"
							fontSize={15}
							style={{
								color: theme.palette.secondary.contrastText,
							}}
							onClick={openActivityMenu}
						>
							{`+${notificationData.data.contacts.length - 1}`}
						</Typography>
					) : null}
				</Typography>
			</Typography>
		</React.Fragment>
	);
}

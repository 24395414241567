import { getRootKey } from "../../rootKey";

const rolesKey = "roles";

export const getRolesKey = () => {
	return [...getRootKey(), rolesKey];
};

export const getRolesTableDataKey = () => {
	return [...getRolesKey(), "roleTableData"];
};

export const getRoleTableDataWithSortKey = (fieldToSort = {}) => {
	return [...getRolesTableDataKey(), fieldToSort];
};

export const getRolesListKey = () => {
	return [...getRolesKey(), "rolesList"];
};

export const getRolesUserListKey = (id) => {
	return [...getRolesKey(), id];
};

export const getAssignedUserKey = (id) => {
	return [...getRolesKey(), id, "assignedUser"];
};

import React from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";
import { useFileList } from "../../../../hooks/services/summary/files";
import FileListItem from "../ShortFileItem";

export default function ShortFileList(props) {
	const { sourceName, entityId } = props;

	const {
		data: fileList,
		isLoading: isLoadingFileList,
		hasNextPage,
		isFetching,
		fetchNextPage,
	} = useFileList(sourceName, entityId);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	return (
		<React.Fragment>
			<Box>
				{isLoadingFileList ? (
					<Stack alignItems="center" p={5}>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					<Box
						sx={{
							maxBlockSize: "250px",
							overflowY: "auto",
							mx: -1,
							cursor: "pointer",
						}}
					>
						{fileList?.length > 0 ? (
							fileList?.map((file) => (
								<FileListItem
									key={file?.id}
									fileData={file}
									entityId={entityId}
									sourceName={sourceName}
								/>
							))
						) : (
							<Stack
								alignItems="center"
								justifyContent="center"
								pb={1}
							>
								<Typography
									fontSize={14}
									color="rgba(0, 0, 0, 0.6)"
								>
									No files found.
								</Typography>
							</Stack>
						)}

						<Box ref={lastRowRef}>
							{!isLoadingFileList &&
								isFetching &&
								fileList?.length > 0 && (
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="center"
										spacing={1}
										py={2}
									>
										<CircularProgress size={18} />

										<Typography fontSize={12}>
											Loading More Data
										</Typography>
									</Stack>
								)}
						</Box>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}

import React, { useMemo, useState } from "react";
import { Box, IconButton, MenuItem, useTheme } from "@mui/material";
import { default as PlusIcon } from "../../../assets/icons/add";
import Menu from "../../Elements/Menu";
import { MenuItemText } from "../../../styles/twozo";

export default function Connector(props) {
	const {
		readOnly,
		AddAction,
		AddCondition,
		isAddActionVisible,
		isAddConditionVisible,
		isAddIconVisible,
	} = props;
	const theme = useTheme();
	const [connectorHovered, setConnectorHovered] = useState(false);
	const [moduleMenuElement, setModuleMenuElement] = useState(null);
	const isModuleMenuOpened = Boolean(moduleMenuElement);

	const OpenModuleMenu = (event) => {
		setModuleMenuElement(event.currentTarget);
	};

	const CloseModuleMenu = () => {
		setModuleMenuElement(null);
	};

	const shouldShowAddIcon = useMemo(() => {
		return (
			(isAddActionVisible || isAddConditionVisible) && !isAddIconVisible
		);
	}, [isAddActionVisible, isAddConditionVisible, isAddIconVisible]);

	return (
		<React.Fragment>
			<Menu
				anchorEl={moduleMenuElement}
				open={isModuleMenuOpened}
				onClose={CloseModuleMenu}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				minWidth="250px"
			>
				{isAddConditionVisible && (
					<MenuItem
						style={{ minHeight: "40px" }}
						onClick={() => {
							AddCondition();
							CloseModuleMenu();
						}}
					>
						<MenuItemText>Add Condition</MenuItemText>
					</MenuItem>
				)}

				{isAddActionVisible && (
					<MenuItem
						style={{ minHeight: "40px" }}
						onClick={() => {
							AddAction();
							CloseModuleMenu();
						}}
					>
						<MenuItemText>Add Action</MenuItemText>
					</MenuItem>
				)}
			</Menu>

			<Box
				onMouseEnter={() => {
					setConnectorHovered(true);
				}}
				onMouseLeave={() => {
					setConnectorHovered(false);
				}}
				sx={{ pointerEvents: readOnly ? "none" : "auto" }}
			>
				<Box
					style={{
						height: "30px",
						border:
							(connectorHovered || moduleMenuElement) &&
							shouldShowAddIcon
								? "none"
								: "1px solid rgba(0, 0, 0, 0.12)",
					}}
				>
					{(connectorHovered || !!moduleMenuElement) &&
						shouldShowAddIcon && (
							<IconButton
								style={{
									backgroundColor:
										theme.palette.secondary.main,
									border: "1px solid rgba(0, 0, 0, 0.12)",
									marginTop: "-4px",
								}}
								onClick={OpenModuleMenu}
							>
								{PlusIcon(20, 20, theme.palette.primary.main)}
							</IconButton>
						)}
				</Box>
			</Box>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import {
	Box,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { DwarfButton, MenuItemText } from "../../../styles/twozo";
import DeleteDialog from "../../Elements/DeleteDialog";
import { deleteMultipleCompany } from "../../../api/company/companyApi";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import CompanyAssignOwner from "./assignOwner";
import UpdateField from "../../Elements/UpdateField";
import { getCompanyTableKey } from "../../../utils/queryKeys";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { default as MoreIcon } from "../../../assets/icons/more";
import { default as CloseIcon } from "../../../assets/icons/close";
import Menu from "../../Elements/Menu";
import ExportDialog from "../../Elements/Export/ExportDialog";
import CancelExportDialog from "../../Elements/Export/CancelDialog";
import AddTags from "../../Tags/AddTags";
import RemoveTags from "../../Tags/RemoveTags";
import { modules } from "../../../utils/common/ModulesName";
import { PERMISSIONS } from "../../../utils/Auth";
import { useAuth } from "../../../hooks/auth";
import { usePartialBulkUpdateCompany } from "../../../hooks/services/company";
import AssignFollowersDialog from "../../Elements/AssignFollowersDialog";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import { useCompanyFieldPermissions } from "../../../hooks/modules/auth/company";

export default function SelectedMenu(props) {
	const { selected, data, deSelectAll, setSelected } = props;
	const theme = useTheme();
	const queryClient = useQueryClient();
	// query call:-
	const { isUserAllowedFor } = useAuth();
	const updateMutation = usePartialBulkUpdateCompany();

	const [
		isDeleteConfirmationDialogOpened,
		setIsDeleteConfirmationDialogOpened,
	] = useState(false);
	const [isAssignOwnerDialogOpened, setIsAssignOwnerDialogOpened] =
		useState(false);
	const [isUpdateFieldDialogOpened, setIsUpdateFieldDialogOpened] =
		useState(false);
	const [selectedCompanyMenuElement, setSelectedCompanyMenuElement] =
		useState(null);
	const [isExportDialogOpened, setIsExportDialogOpened] = useState(false);
	const [isCancelExportingDialogOpened, setIsCancelExportingDialogOpened] =
		useState(false);
	const isSelectedCompanyMenuOpened = Boolean(selectedCompanyMenuElement);

	const [isAddTagsDialogOpened, setIsAddTagsDialogOpened] = useState(false);
	const [isRemoveTagsDialogOpened, setIsRemoveTagsDialogOpened] =
		useState(false);
	const [isFollowersDialogOpened, setIsFollowersDialogOpened] =
		useState(false);

	const moduleName = modules.COMPANY;
	const companyTableKey = getCompanyTableKey();
	const companyFieldPermissions = useCompanyFieldPermissions();

	const deleteCompanyMutation = useMutation(
		async (companyIds) => deleteMultipleCompany(companyIds),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(companyTableKey);
				if (selected.length > 1) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: `${selected.length} companies deleted.`,
					});
				} else {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.companyDeleted,
					});
				}
				deSelectAll();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		}
	);

	const openAddTagsDialog = () => {
		setIsAddTagsDialogOpened(true);
		setSelectedCompanyMenuElement(null);
	};

	const closeAddTagsDialog = () => {
		setIsAddTagsDialogOpened(false);
	};

	const openRemoveTagsDialog = () => {
		setIsRemoveTagsDialogOpened(true);
		setSelectedCompanyMenuElement(null);
	};

	const closeRemoveTagsDialog = () => {
		setIsRemoveTagsDialogOpened(false);
	};

	const handleOpenDeleteConfirmationDialog = () => {
		setIsDeleteConfirmationDialogOpened(true);
	};

	const handleCloseDeleteConfirmationDialog = () => {
		setIsDeleteConfirmationDialogOpened(false);
	};

	const handleDeleteCompanies = () => {
		deleteCompanyMutation.mutate(selected);
		handleCloseDeleteConfirmationDialog();
	};

	const openAssignOwnerDialog = () => {
		setIsAssignOwnerDialogOpened(true);
	};

	const closeAssignOwnerDialog = () => {
		setIsAssignOwnerDialogOpened(false);
	};

	const handleOpenUpdateFieldDialog = () => {
		setIsUpdateFieldDialogOpened(true);
	};

	const handleCloseUpdateFieldDialog = () => {
		setIsUpdateFieldDialogOpened(false);
	};

	const openSelectedCompanyMenu = (event) => {
		setSelectedCompanyMenuElement(event.currentTarget);
	};

	const closeSelectedCompanyMenu = () => {
		setSelectedCompanyMenuElement(null);
	};

	const handleSaveUpdateField = (fieldData) => {
		updateMutation.mutate(fieldData, {
			onSuccess: () => {
				handleCloseUpdateFieldDialog();
				if (selected.length > 1) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: `${selected.length} companies updated.`,
					});
				} else {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.companyUpdated,
					});
				}

				setSelected([]);
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const openExportDialog = () => {
		setIsExportDialogOpened(true);
		closeSelectedCompanyMenu();
	};

	const closeExportDialog = () => {
		setIsExportDialogOpened(false);
	};

	const openCancelDialog = () => {
		setIsCancelExportingDialogOpened(true);
		closeExportDialog();
	};

	const cancelExporting = () => {
		setIsExportDialogOpened(false);
		setIsCancelExportingDialogOpened(false);
	};

	const exportFile = () => {
		openCancelDialog();
	};

	const onTagsAdded = () => {
		setSelected([]);
		closeAddTagsDialog();
	};

	const onTagsRemoved = () => {
		setSelected([]);
		closeRemoveTagsDialog();
	};

	const onOwnerUpdated = () => {
		deSelectAll();
	};

	const openFollowersDialog = () => {
		setIsFollowersDialogOpened(true);
	};

	const closeFollowersDialog = () => {
		setIsFollowersDialogOpened(false);
	};

	const onFollowersAssigned = () => {
		deSelectAll();
	};

	const menuOptions = [
		{
			name: "Export",
			action: openExportDialog,
			permission: isUserAllowedFor(PERMISSIONS.company.exportRecords),
		},
		{
			name: "Add Tags",
			action: openAddTagsDialog,
			permission: isUserAllowedFor(PERMISSIONS.company.edit),
		},
		{
			name: "Remove Tags",
			action: openRemoveTagsDialog,
			permission: isUserAllowedFor(PERMISSIONS.company.edit),
		},
	];

	const selectedMenuOptions = [
		{
			name: "Assign Owner",
			action: openAssignOwnerDialog,
			permission: isUserAllowedFor(PERMISSIONS.company.bulkAssignRecords),
		},
		{
			name: "Assign Followers",
			action: openFollowersDialog,
			permission: true,
		},
		{
			name: "Update Field",
			action: handleOpenUpdateFieldDialog,
			permission: isUserAllowedFor(PERMISSIONS.company.bulkUpdateRecords),
		},
		{
			name: "Delete",
			action: handleOpenDeleteConfirmationDialog,
			permission: isUserAllowedFor(PERMISSIONS.company.delete),
		},
	];

	const getMenuOptions = () => {
		let filteredMenuOptions = menuOptions.filter(
			(option) => option.permission
		);
		return filteredMenuOptions;
	};

	const getSelectedMenuOptions = () => {
		let filteredSelectedMenuOptions = selectedMenuOptions.filter(
			(option) => option.permission
		);
		return filteredSelectedMenuOptions;
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="200px"
				anchorEl={selectedCompanyMenuElement}
				open={isSelectedCompanyMenuOpened}
				onClose={closeSelectedCompanyMenu}
				style={{
					marginTop: "8px",
				}}
			>
				{getMenuOptions()?.map((option, index) => (
					<MenuItem
						style={{ height: "40px" }}
						onClick={option.action}
						key={index}
					>
						<MenuItemText
							color={theme.palette.secondary.contrastText}
						>
							{option.name}
						</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			<ExportDialog
				open={isExportDialogOpened}
				onClose={closeExportDialog}
				exportFile={exportFile}
			/>

			<CancelExportDialog
				open={isCancelExportingDialogOpened}
				onCancel={cancelExporting}
			/>

			<Box>
				<DeleteDialog
					open={isDeleteConfirmationDialogOpened}
					onCancel={handleCloseDeleteConfirmationDialog}
					onDelete={handleDeleteCompanies}
					title="Are you sure you want to delete the selected companies?"
					subtitle="It will delete all the selected companies and the data associated with it."
				/>

				<CompanyAssignOwner
					open={isAssignOwnerDialogOpened}
					onClose={closeAssignOwnerDialog}
					companyIds={selected}
					onOwnerUpdated={onOwnerUpdated}
				/>

				<FieldPermissionContextProvider value={companyFieldPermissions}>
					<UpdateField
						selected={selected}
						moduleName={moduleName}
						onSave={handleSaveUpdateField}
						open={isUpdateFieldDialogOpened}
						onClose={handleCloseUpdateFieldDialog}
						isSaving={updateMutation.isLoading}
					/>
				</FieldPermissionContextProvider>

				<AssignFollowersDialog
					open={isFollowersDialogOpened}
					onClose={closeFollowersDialog}
					entityIds={selected}
					moduleName={moduleName}
					onFollowersAssigned={onFollowersAssigned}
				/>

				<AddTags
					sourceName={moduleName}
					isAddTagsDialogOpened={isAddTagsDialogOpened}
					closeAddTagsDialog={closeAddTagsDialog}
					selectedIds={selected}
					onTagsAdded={onTagsAdded}
				/>

				<RemoveTags
					sourceName={moduleName}
					isRemoveTagsDialogOpened={isRemoveTagsDialogOpened}
					closeRemoveTagsDialog={closeRemoveTagsDialog}
					selectedIds={selected}
					onTagsRemoved={onTagsRemoved}
				/>

				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={2}
					height="100%"
				>
					<Stack direction="row" spacing={0.5}>
						<Typography fontSize={14} fontWeight={500}>
							{selected.length} Selected
						</Typography>

						<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
							of {data.length}
						</Typography>

						<IconButton
							disableRipple
							style={{ cursor: "pointer", padding: 0 }}
							onClick={() => setSelected([])}
						>
							{CloseIcon(18, 18, "rgba(0,0,0,0.6)")}
						</IconButton>
					</Stack>

					{getSelectedMenuOptions()?.length > 0 ? (
						<Divider
							orientation="vertical"
							style={{ height: "20px" }}
						/>
					) : null}

					{getSelectedMenuOptions()?.map((option, index) => (
						<DwarfButton
							key={index}
							variant="contained"
							color="secondary"
							disableElevation
							onClick={option.action}
						>
							{option.name}
						</DwarfButton>
					))}

					{getMenuOptions()?.length > 0 && (
						<IconButton
							onClick={openSelectedCompanyMenu}
							size="small"
						>
							{MoreIcon(20, 20, theme.palette.primary.main)}
						</IconButton>
					)}
				</Stack>
			</Box>
		</React.Fragment>
	);
}

import React, { useMemo, useState } from "react";
import teamsImage from "../../../../../../assets/images/contact/multipleContact.png";
import {
	Box,
	Checkbox,
	Chip,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import { useTeamsList } from "../../../../../../hooks/services/userManagement/teams";
import MenuSearchBar from "../../../../../Elements/MenuSearchBar";
import { default as CloseIcon } from "../../../../../../assets/icons/close";
import { MenuItemText } from "../../../../../../styles/twozo";

export default function TeamsDropdown(props) {
	const { value, onChange } = props;
	const { data: teamsList } = useTeamsList();
	const [searchValue, setSearchValue] = useState("");

	const handleFilteredField = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const selectTeam = (event) => {
		const { value } = event.target;
		onChange(value);
	};

	const unSelectTeam = (value, teamIds) => {
		const updatedteamIds = teamIds.filter((id) => id !== value);
		onChange(updatedteamIds);
	};

	const isTeamSelected = (value) => {
		return value?.length > 0;
	};

	const filteredTeamsList = useMemo(() => {
		if (Array.isArray(teamsList)) {
			if (searchValue.trim()) {
				return teamsList.filter((team) =>
					team.name
						.toLowerCase()
						.startsWith(searchValue.toLowerCase())
				);
			}
			return teamsList;
		}
		return [];
	}, [teamsList, searchValue]);

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<Select
			displayEmpty
			value={value || []}
			onChange={selectTeam}
			multiple
			onClose={() => setSearchValue("")}
			renderValue={
				isTeamSelected(value)
					? (selectedTeam) => (
							<Box>
								{selectedTeam.map((team) => (
									<Chip
										size="small"
										key={team}
										label={
											<Typography
												fontSize={14}
												color="#000"
											>
												{
													teamsList?.find(
														(option) =>
															option.value ===
															team
													).name
												}
											</Typography>
										}
										color="secondary"
										deleteIcon={
											<Stack
												direction="row"
												alignItems="center"
												onMouseDown={(event) =>
													event.stopPropagation()
												}
											>
												{CloseIcon(16, 16, "grey")}
											</Stack>
										}
										onDelete={() =>
											unSelectTeam(team, value)
										}
										sx={{
											borderRadius: "8px",
											marginRight: "4px",
										}}
									/>
								))}
							</Box>
						)
					: () => (
							<Typography
								fontSize={14}
								color="rgba(0, 0, 0, 0.6)"
							>
								Select
							</Typography>
						)
			}
			onOpen={() => setSearchValue("")}
		>
			<Box p={0.5}>
				<MenuSearchBar
					value={searchValue}
					onChange={handleFilteredField}
				/>
			</Box>
			{filteredTeamsList?.length > 0
				? filteredTeamsList.map((team) => (
						<MenuItem
							key={team.value}
							value={team.value}
							style={{
								height: "40px",
							}}
						>
							<Stack
								style={{
									width: "100%",
								}}
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<Stack direction="row" spacing={2}>
									<img
										src={teamsImage}
										alt="img"
										width="20px"
										height="20px"
									/>
									<MenuItemText>{team.name}</MenuItemText>
								</Stack>

								<Stack>
									<Checkbox
										checked={
											value
												? value.includes(team.value)
												: false
										}
									/>
								</Stack>
							</Stack>
						</MenuItem>
					))
				: renderEmptyView()}
		</Select>
	);
}

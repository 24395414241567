import React, { useState } from "react";
import Menu from "../../../../../Elements/Menu";
import { Box, MenuItem, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Can from "../../../../../Auth/Can";
import CanNot from "../../../../../Auth/CanNot";
import { PERMISSIONS } from "../../../../../../utils/Auth";
import { MenuItemText } from "../../../../../../styles/twozo";

export default function RenderContactsOnboardFieldValue(props) {
	const { fieldValue } = props;
	const theme = useTheme();
	const navigate = useNavigate();

	const [contactsOnboardMenuElement, setContactsOnboardMenuElement] =
		useState(null);
	const isContactsOnboardMenuOpened = Boolean(contactsOnboardMenuElement);

	const openContactsOnboardMenu = (event) => {
		if (fieldValue?.length > 1) {
			setContactsOnboardMenuElement(event.currentTarget);
		} else {
			navigate(`/contacts/${fieldValue?.[0]?.id?.toString()}`);
		}
	};

	const closeContactsOnboardMenu = () => {
		setContactsOnboardMenuElement(null);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="240px"
				anchorEl={contactsOnboardMenuElement}
				open={isContactsOnboardMenuOpened}
				onClose={closeContactsOnboardMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				style={{
					marginTop: "4px",
				}}
				menuListProps={{
					padding: "4px 0px",
				}}
			>
				{fieldValue?.map((contact) => (
					<MenuItem
						key={contact.id}
						onClick={() =>
							navigate(`/contacts/${contact?.id?.toString()}`)
						}
						sx={{
							height: "40px",
						}}
					>
						<MenuItemText
							fontWeight={500}
							color={theme.palette.primary.main}
						>
							{contact.fullName}
						</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			<Can permission={PERMISSIONS.contact.view}>
				<Box
					onClick={openContactsOnboardMenu}
					sx={{ cursor: "pointer" }}
				>
					<Typography
						fontSize={14}
						fontWeight={500}
						color={theme.palette.primary.main}
					>
						<span>{fieldValue?.[0]?.fullName}</span>
						&nbsp;&nbsp;
						{fieldValue?.length > 1 && (
							<span
								style={{
									color: theme.palette.primary.main,
								}}
							>
								+{fieldValue?.length - 1}
							</span>
						)}
					</Typography>
				</Box>
			</Can>

			<CanNot permission={PERMISSIONS.contact.view}>
				<Typography fontSize={14} fontWeight={500}>
					-
				</Typography>
			</CanNot>
		</React.Fragment>
	);
}

import React, { useMemo, useState, useCallback } from "react";
import { Box } from "@mui/material";
import Table from "../../Elements/Table";
import Menu from "../Menu";
import SelectedMenu from "../SelectedMenu";
import { sourceType } from "../../../utils/queryKeys";
import { twozoStyles } from "../../../styles/twozo";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import SwipeableSummary from "../SwipeableSummary";
import { modules } from "../../../utils/common/ModulesName";
import { dealViews } from "../../../utils/dealUtils";
import { default as MailIcon } from "../../../assets/icons/mail";
import { default as CallIcon } from "../../../assets/icons/call";
import { default as TaskIcon } from "../../../assets/icons/task";
import { default as EditIcon } from "../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../assets/icons/delete";
import EditDeal from "../EditDeal";
import AddActivity from "../../Summary/Activities/AddActivity";
import { useDeleteMutation } from "../../../hooks/services/deal";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import DeleteDialog from "../../Elements/DeleteDialog";
import { PERMISSIONS } from "../../../utils/Auth";
import { useAuth } from "../../../hooks/auth";
import EmailComposerDrawer from "../../Mail/EmailComposer/EmailComposerDrawer";
import { useModuleName } from "../../../hooks/modules";
import { tableActions } from "../../../utils/tableUtills";

const noResultsMessage = {
	title: "No Deals Found",
	message: "Try resetting your filters or create new deal",
};

export default function ListView(props) {
	const { handleViewToggleButtonGroup, onImportClicked } = props;

	const classes = twozoStyles();

	const [selectedRowData, setSelectedRowData] = useState(null);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [isEditFormDialogOpen, setIsEditFormDialogOpen] = useState(false);
	const [isEmailComposerOpened, setIsEmailComposerOpened] = useState(false);
	const [openAddActivityDialog, setOpenAddActivityDialog] = useState(false);
	const [
		isSwipeableDealSummaryDialogOpened,
		setIsSwipeableDealSummaryDialogOpened,
	] = useState(false);

	const { getModuleName } = useModuleName();

	const dealId = selectedRowData?.id ?? "";
	const primaryContactId = selectedRowData?.primaryContact?.id ?? "";
	const companyData = selectedRowData?.company
		? {
				name: selectedRowData.company?.name,
				value: selectedRowData.company?.id,
			}
		: null;

	// query call
	const { isUserAllowedFor } = useAuth();
	const hasShareViewPermissions = isUserAllowedFor(
		PERMISSIONS.deal.shareView
	);

	// delete mutation call
	const deleteMutation = useDeleteMutation();

	const openSwipeableDealSummary = useCallback((row) => {
		toggleSwipeableDealSummaryDialog();
		setSelectedRowData(row);
	}, []);

	const handleTableRowAction = useCallback(
		(action, actionData) => {
			switch (action) {
				case tableActions.rowClickAction:
					openSwipeableDealSummary(actionData);
					break;
				default:
					break;
			}
		},
		[openSwipeableDealSummary]
	);

	const toggleSwipeableDealSummaryDialog = () => {
		setIsSwipeableDealSummaryDialogOpened(
			(isSwipeableDealSummaryDialogOpened) =>
				!isSwipeableDealSummaryDialogOpened
		);
	};

	const toggleEditFormDialog = () => {
		setIsEditFormDialogOpen((openEditFormDialog) => !openEditFormDialog);
	};

	const closeEmailComposer = () => {
		setIsEmailComposerOpened(false);
		setSelectedRowData(null);
	};

	const toggleAddActivityDialog = () => {
		setOpenAddActivityDialog(
			(openAddCompanyDialog) => !openAddCompanyDialog
		);
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const handleDeleteDeal = () => {
		deleteMutation.mutate(dealId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealDeleted,
				});
				closeDeleteDialog();
			},
		});
	};

	const filteredTableMenuOptions = useMemo(() => {
		const onDeleteClicked = (row) => {
			setSelectedRowData(row);
			openDeleteDialog();
		};

		const onEditClicked = (row) => {
			setSelectedRowData(row);
			toggleEditFormDialog();
		};

		const onMailClicked = (row) => {
			setIsEmailComposerOpened(true);
			setSelectedRowData(row);
		};

		const onAddActivityClicked = (row) => {
			setSelectedRowData(row);
			toggleAddActivityDialog();
		};

		const dealMenuOptions = [
			{
				id: 1,
				name: "Edit",
				icon: EditIcon,
				action: onEditClicked,
				permission: isUserAllowedFor(PERMISSIONS.deal.edit),
			},
			{
				id: 2,
				name: "Email",
				icon: MailIcon,
				action: onMailClicked,
				permission: isUserAllowedFor(PERMISSIONS.contact.view),
			},
			{
				id: 3,
				name: "Call",
				icon: CallIcon,
				action: () => {},
				permission: isUserAllowedFor(PERMISSIONS.contact.view),
			},
			{
				id: 4,
				name: "Add Activity",
				icon: TaskIcon,
				action: onAddActivityClicked,
				permission: true,
			},
			{
				id: 5,
				name: "Delete",
				icon: DeleteIcon,
				action: onDeleteClicked,
				permission: isUserAllowedFor(PERMISSIONS.deal.delete),
			},
		];
		let filteredTableMenuOptions = dealMenuOptions.filter(
			(option) => option.permission
		);

		return filteredTableMenuOptions;
	}, [isUserAllowedFor]);

	let moduleName = getModuleName(modules.DEAL).toLowerCase();

	return (
		<React.Fragment>
			<DeleteDialog
				title={`Are you sure you want to delete the ${moduleName}?`}
				subtitle={`It will delete the ${moduleName} and the data associated with it. You can retrieve it from the Recycle Bin. It remains there for 90 days.`}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteDeal}
				disableDeleteButton={deleteMutation.isLoading}
			></DeleteDialog>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditFormDialogOpen}
				onOpen={toggleEditFormDialog}
				onClose={toggleEditFormDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditDeal
						dealId={selectedRowData?.id}
						onClose={toggleEditFormDialog}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{isEmailComposerOpened ? (
				<Box>
					<EmailComposerDrawer
						onClose={closeEmailComposer}
						composerValues={{
							toAddress: [selectedRowData?.primaryContact?.id],
						}}
						sourceName={modules.DEAL}
						entityId={selectedRowData?.id}
					/>
				</Box>
			) : null}

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddActivityDialog}
				onOpen={toggleAddActivityDialog}
				onClose={toggleAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={toggleAddActivityDialog}
						dealData={{
							name: selectedRowData?.title,
							value: selectedRowData?.id,
						}}
						companyValue={companyData}
						contactIds={[primaryContactId]}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: {
						boxShadow: "-3px 0px 14px rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
					},
				}}
				BackdropProps={{ invisible: true }}
				open={isSwipeableDealSummaryDialogOpened}
				onOpen={toggleSwipeableDealSummaryDialog}
				onClose={toggleSwipeableDealSummaryDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<SwipeableSummary
						onClose={toggleSwipeableDealSummaryDialog}
						dealId={dealId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Table
				table={sourceType.DEAL}
				moduleName={modules.DEAL}
				menu={
					<Menu
						view={dealViews.listView}
						handleViewToggleButtonGroup={
							handleViewToggleButtonGroup
						}
						importClicked={onImportClicked}
					/>
				}
				selectedMenu={<SelectedMenu />}
				handleTableRowAction={handleTableRowAction}
				isActionsVisible={true}
				noResultsMessage={noResultsMessage}
				tableMenuOptions={filteredTableMenuOptions}
				hasShareViewPermissions={hasShareViewPermissions}
			/>
		</React.Fragment>
	);
}

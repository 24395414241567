import { getFieldsUrl, getFilterUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import { apiClient } from "..";

export const getSourceListData = async () => {
	let requestData = getSourceRequest();
	return await postFieldManagementApi(requestData);
};

export const getFilterFieldListData = async (id) => {
	let requestData = getFilterFieldListRequest(id);
	return await postFieldManagementApi(requestData);
};

export const getComparatorData = async (id) => {
	let requestData = getComparatorListRequest(id);
	return await postFieldManagementApi(requestData);
};

export const getComparatorListForAdditionalOptions = async (id) => {
	let requestData = getComparatorListForAdditionalOptionsRequest(id);
	return await postFieldManagementApi(requestData);
};

export const getAdditionalOptionData = async (id) => {
	let requestData = getAdditionalOptionRequest(id);
	return await postFieldManagementApi(requestData);
};

export const saveViewFilter = async (data) => {
	let requestData = getCreateFilterRequest(data);
	return await postFilterManagementApi(requestData);
};

export const getFilterList = async (id) => {
	let requestData = getFilterListRequest(id);
	return await postFieldManagementApi(requestData);
};

export const getSalesOwnerFilterList = async (id) => {
	let requestData = getSalesOwnerFilterRequest(id);
	return await postFieldManagementApi(requestData);
};

export const updateFavouriteFilter = async (data) => {
	let requestData = getUpdateFavouiteFilterRequest(data);
	return await postFilterManagementApi(requestData);
};

export const deleteFilter = async (id) => {
	let requestData = getDeleteRequest(id);
	return await postFilterManagementApi(requestData);
};

export const getFilterData = async (id) => {
	let requestData = getFilterRequest(id);
	return await postFilterManagementApi(requestData);
};

export const updateFilter = async (data) => {
	let requestData = getUpdateFilterRequest(data);
	return await postFilterManagementApi(requestData);
};

export const getUserTeamTerritoryList = async () => {
	let requestData = getUserTeamTerritoryListRequest();
	return await postFieldManagementApi(requestData);
};

const getFilterManagementUrl = () => {
	return getFilterUrl() + "/mgmt";
};

const postFilterManagementApi = async (requestData) => {
	let filterManagementUrl = getFilterManagementUrl();
	let response = await apiClient.post(filterManagementUrl, requestData);
	assertError(response);
	return response.data.data;
};

const postFieldManagementApi = async (requestData) => {
	let fieldManagementUrl = getFieldsUrl();
	let response = await apiClient.post(fieldManagementUrl, requestData);
	assertError(response);
	return response.data.data;
};

const getSourceRequest = () => {
	return {
		type: "dropdownData",
		data: {
			name: "filterSourceList",
		},
	};
};

const getFilterFieldListRequest = (id) => {
	return {
		type: "dropdownData",
		data: {
			name: "filterFieldList",
			sourceId: id,
		},
	};
};

const getComparatorListRequest = (id) => {
	return {
		type: "dropdownData",
		data: {
			name: "comparatorList",
			fieldTypeId: id,
		},
	};
};

const getComparatorListForAdditionalOptionsRequest = (id) => {
	return {
		type: "dropdownData",
		data: {
			name: "comparatorList",
			fieldSubTypeId: id,
		},
	};
};

const getAdditionalOptionRequest = (id) => {
	return {
		type: "dropdownData",
		data: {
			name: "additionalFields",
			fieldTypeId: id,
		},
	};
};

const getCreateFilterRequest = (data) => {
	return {
		type: "create",
		data: data,
	};
};

const getFilterListRequest = (id) => {
	return {
		type: "dropdownData",
		data: {
			name: "filterList",
			sourceId: id,
		},
	};
};

const getSalesOwnerFilterRequest = (id) => {
	return {
		type: "dropdownData",
		data: {
			name: "salesOwnerFilterList",
			sourceId: id,
		},
	};
};

const getUpdateFavouiteFilterRequest = (data) => {
	return {
		type: "updateFavouriteStatus",
		data: data,
	};
};

const getDeleteRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getFilterRequest = (id) => {
	return {
		type: "getFilter",
		userId: 5,
		data: {
			id: id,
		},
	};
};

const getUpdateFilterRequest = (data) => {
	return {
		type: "update",
		data: data,
	};
};

const getUserTeamTerritoryListRequest = () => {
	return {
		type: "dropdownData",
		data: {
			name: "userTeamTerritoryList",
		},
	};
};

import React, { useState } from "react";
import {
	Box,
	IconButton,
	InputAdornment,
	Typography,
	useTheme,
} from "@mui/material";
import { default as SendIcon } from "../../../assets/icons/send";
import { ColoredOutlinedInput } from "../../../styles/twozo";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import {
	useCreateNote,
	useUpdateNote,
} from "../../../hooks/services/summary/notes/notes";
import ShortNoteList from "./ShortNoteList";

export default function ShortNotes(props) {
	const { source, entityId, isSwipeableSummary } = props;
	const theme = useTheme();

	const [editedNoteData, setEditedNoteData] = useState({});
	const [noteInput, setNoteInput] = useState("");
	// true indicates it is add note, false indicates it is edit note
	const [isEditMode, setIsEditMode] = useState(false);

	const createMutation = useCreateNote(source, entityId);
	const updateMutation = useUpdateNote(source, entityId);

	const createOrUpdateNote = () => {
		if (isEditMode) {
			let updateNoteRequest = {
				note: noteInput,
				source: source,
				entityId: entityId,
				id: editedNoteData?.id,
			};
			updateMutation.mutate(updateNoteRequest, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.notesUpdated,
					});
					setNoteInput("");
					setIsEditMode(false);
					setEditedNoteData({});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			});
		} else {
			let createNoteRequest = {
				note: noteInput,
				source: source,
				entityId: entityId,
			};
			createMutation.mutate(createNoteRequest, {
				onSuccess: () => {
					setNoteInput("");
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.notesAdded,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			});
		}
	};

	const handleChange = (event) => {
		const { value } = event.target;
		setNoteInput(value?.trimStart());
	};

	const handleEditNote = (noteData) => {
		setNoteInput(noteData.note);
		setEditedNoteData(noteData);
		setIsEditMode(true);
	};

	return (
		<React.Fragment>
			<Box>
				<Typography
					fontSize={15}
					fontWeight={600}
					p={1}
					hidden={isSwipeableSummary}
				>
					Notes
				</Typography>

				<ColoredOutlinedInput
					inputProps={{
						style: {
							fontSize: "15px",
						},
					}}
					placeholder="Add Notes Here..."
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								size="small"
								onClick={createOrUpdateNote}
								disabled={
									createMutation.isLoading ||
									updateMutation.isLoading ||
									!noteInput
								}
							>
								{SendIcon(
									20,
									20,
									theme.palette.primary.main,
									"2"
								)}
							</IconButton>
						</InputAdornment>
					}
					value={noteInput}
					onChange={handleChange}
					maxRows={4}
					multiline
					fullWidth
				/>

				<Box>
					<ShortNoteList
						source={source}
						entityId={entityId}
						handleEditNote={handleEditNote}
					/>
				</Box>
			</Box>
		</React.Fragment>
	);
}

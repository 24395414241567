import {
	Box,
	IconButton,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import { default as CloseIcon } from "../../../../../../../../../assets/icons/close";
import Field from "./Field";

const selectMenuProps = {
	autoFocus: false,
	PaperProps: {
		style: {
			width: "300px",
			borderRadius: "8px",
			maxHeight: "300px",
		},
	},
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left",
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left",
	},
};

const Dropdown = ({
	options = [],
	value,
	onChange,
	placeholder = "Select",
	width = "100%",
	borderRadius,
	showBorderLeft = false,
	showBorderRight = false,
	menuProps = selectMenuProps,
}) => {
	const theme = useTheme();
	return (
		<Select
			displayEmpty
			value={value || ""}
			onChange={onChange}
			sx={{
				width,
				backgroundColor: theme.palette.secondary.main,
				borderRadius,
				"& .MuiOutlinedInput-notchedOutline": {
					border: "none",
					borderLeft: showBorderLeft
						? "1px solid rgba(0, 0, 0, 0.10)"
						: "none",
					borderRight: showBorderRight
						? "1px solid rgba(0, 0, 0, 0.10)"
						: "none",
					borderRadius,
				},
			}}
			MenuProps={menuProps}
			renderValue={() =>
				value?.name || (
					<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
						{placeholder}
					</Typography>
				)
			}
		>
			{options.map((option) => (
				<MenuItem
					key={option.id || option.fieldId}
					value={option}
					style={{ minHeight: "40px" }}
				>
					<Typography fontSize={13}>{option.name}</Typography>
				</MenuItem>
			))}
		</Select>
	);
};

export default function PeriodicFilter({
	periodicFilter,
	setPeriodicFilter,
	periodicFilterFields,
	initialComparatorList,
	initialFunctionList,
	handleClear,
}) {
	const comparators = useMemo(() => {
		return (
			periodicFilter.field?.comparators?.map((comparator) => ({
				...initialComparatorList?.[comparator],
				id: comparator,
			})) || []
		);
	}, [initialComparatorList, periodicFilter.field?.comparators]);

	const handleChangeField = (event) => {
		const { value } = event.target;
		setPeriodicFilter({
			field: value,
			comparator: {},
			values: [],
			function: null,
			isCustomDropdown: false,
		});
	};

	const handleChangeComparator = (event) => {
		const { value } = event.target;
		setPeriodicFilter((previousFilter) => ({
			...previousFilter,
			comparator: value,
			values: [],
			function: null,
			isCustomDropdown: false,
		}));
	};

	const handleFieldValueChange = (fieldValue) => {
		setPeriodicFilter((previousFilter) => ({
			...previousFilter,
			values: [{ value: fieldValue }],
		}));
	};

	const handleFunctionChange = (functionId) => {
		setPeriodicFilter((previousFilter) => ({
			...previousFilter,
			function: parseInt(functionId) || null,
		}));
	};

	const handleChangeCustomDropdown = (isCustomDropdown) => {
		setPeriodicFilter((previousFilter) => ({
			...previousFilter,
			isCustomDropdown,
		}));
	};

	return (
		<Stack spacing={1}>
			<Typography style={{ fontSize: 14, fontWeight: 400, opacity: 0.6 }}>
				Periodic Filter
			</Typography>

			<Stack
				direction="row"
				alignItems="center"
				width="100%"
				height="auto"
			>
				<Box width="30%">
					<Dropdown
						options={periodicFilterFields}
						value={periodicFilter.field}
						onChange={handleChangeField}
						borderRadius="8px 0px 0px 8px"
					/>
				</Box>

				{periodicFilter.field && (
					<Box width="30%">
						<Dropdown
							options={comparators}
							value={periodicFilter.comparator}
							onChange={handleChangeComparator}
							borderRadius="0px"
							showBorderLeft
						/>
					</Box>
				)}

				{periodicFilter.comparator &&
					periodicFilter.comparator?.hasValue && (
						<Field
							value={periodicFilter?.values?.[0]?.value}
							field={periodicFilter.comparator}
							functions={initialFunctionList}
							onFieldValueChange={handleFieldValueChange}
							functionId={periodicFilter.function}
							updateCustomDropdown={handleChangeCustomDropdown}
							isCustomDropdown={periodicFilter.isCustomDropdown}
							onFunctionChange={handleFunctionChange}
						/>
					)}

				<Box pl={1}>
					<IconButton size="small" onClick={handleClear}>
						{CloseIcon(20, 20, "#000")}
					</IconButton>
				</Box>
			</Stack>
		</Stack>
	);
}

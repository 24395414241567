import {
	Box,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { getDropdownDataByName } from "../../../../utils/dropdownData";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { forwardRef, useCallback, useImperativeHandle } from "react";
import { MenuItemText } from "../../../../styles/twozo";

const CustomSelect = styled(Select)(() => ({
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
}));

const EmailFieldEditForm = forwardRef(
	({ value, field, subFields, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const {
			handleSubmit,
			formState: { errors },
			register,
			setValue,
			clearErrors,
		} = useForm({ values: value, reValidateMode: false });

		const getSubFieldObject = (subFieldName) => {
			let subFieldObj;
			if (!!subFields && Array.isArray(subFields)) {
				subFields.forEach((field) => {
					if (field.name === subFieldName) {
						subFieldObj = field;
					}
				});
			}

			return subFieldObj;
		};

		const emailTypeOptions = getDropdownDataByName("EMAIL_TYPE");
		const emailTypeField = getSubFieldObject("EMAIL_TYPE");
		const emailValueField = getSubFieldObject("EMAIL_VALUE");

		const [selectedEmailFieldType, setSelectedEmailFieldType] = useState(
			emailTypeOptions?.[0]?.value
		);

		const handleEmailFieldChange = (event) => {
			const { value } = event.target;
			setSelectedEmailFieldType(value);
			setValue(emailTypeField?.id + "", value);
		};

		useEffect(() => {
			if (value?.[emailTypeField?.id]) {
				setSelectedEmailFieldType(value[emailTypeField.id]);
			}
		}, [value]);

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[emailValueField?.id]) {
						onError(errors[emailValueField?.id]);
					}
				}
			)();
		}, [handleSubmit, emailValueField, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		return (
			<>
				<Stack
					direction="row"
					width="100%"
					height="100%"
					overflow="hidden"
					justifyContent="space-between"
				>
					<Stack flexDirection="column" width="65%" height="100%">
						<TextField
							placeholder={
								emailValueField?.placeHolder || "9876543210"
							}
							{...register(
								emailValueField?.id + "",
								handleFieldValidation(field)
							)}
							onChange={(event) => {
								setValue(
									emailValueField?.id + "",
									event.target.value
								);
								onError(null);
								clearErrors(emailValueField?.id + "");
							}}
							sx={{
								width: "100%",
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										border: "none",
									},
									"&:hover fieldset": {
										border: "none",
									},
									"&.Mui-focused fieldset": {
										border: "none",
									},
								},
							}}
							name="email"
						/>

						{errors[emailValueField?.id] ? (
							<Typography
								pl={1}
								pb={1.2}
								style={{
									fontSize: 13,
									width: "95%",
									marginLeft: "6px",
									color: theme.palette.error.main,
								}}
							>
								{errors[emailValueField?.id]?.message}
							</Typography>
						) : null}
					</Stack>

					<Box
						width="35%"
						borderLeft={`1px solid ${
							errors[emailValueField?.id]
								? theme.palette.error.main
								: theme.palette.primary.main
						}`}
						height="100%"
					>
						<CustomSelect
							{...register(emailTypeField?.id + "")}
							sx={{
								width: "100%",
								"& .MuiOutlinedInput-notchedOutline": {
									borderRadius: "0px 8px 8px 0px",
									marginLeft: "-1px",
								},
							}}
							name="emailType"
							onChange={handleEmailFieldChange}
							value={selectedEmailFieldType}
						>
							{emailTypeOptions.map((emailType) => (
								<MenuItem
									key={emailType.value}
									value={emailType.value}
								>
									<MenuItemText>
										{emailType.name}
									</MenuItemText>
								</MenuItem>
							))}
						</CustomSelect>
					</Box>
				</Stack>
			</>
		);
	}
);

EmailFieldEditForm.displayName = "EmailFieldEditForm";

export default EmailFieldEditForm;

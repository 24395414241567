import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateDashboardMutation } from "../../../../hooks/services/analytics";
import UpsertDashboardDetails from "../../UpsertDashboardDetails";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";

export default function AddNewDashboard(props) {
	const { closeDialog } = props;

	const navigate = useNavigate();
	const createDashboardMutation = useCreateDashboardMutation();
	const [creatingDashboard, setCreatingDashboard] = useState(false);

	const createDashboard = (data) => {
		setCreatingDashboard(true);
		createDashboardMutation.mutate(data, {
			onSuccess: (data) => {
				setCreatingDashboard(false);
				navigate(`dashboard/builder/${data.dashboardId}`);
			},
			onError: (data) => {
				setCreatingDashboard(false);
				console.error(data);
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<UpsertDashboardDetails
				submitButtonLabel={creatingDashboard ? "Creating..." : "Create"}
				onSubmit={createDashboard}
				onCancel={closeDialog}
				disableSubmit={creatingDashboard}
			/>
		</React.Fragment>
	);
}

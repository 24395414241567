import { useQuery } from "@tanstack/react-query";
import { getDropdownKeyByFieldId } from "../../../../../utils/queryKeys";
import { getFieldDropdownData } from "../../../../../api/fields/config/configApi";
import { useEffect, useState } from "react";
import MultiSelectBaseComponent from "../MultiSelectComponent/MultiSelectBaseComponent";

const getSelectTagsList = (data) => {
	return (
		data?.options?.map((data) => {
			return { value: { id: data.id || data.value, name: data.name } };
		}) || []
	);
};

export default function TagsComponent(params) {
	const {
		field,
		error,
		style,
		updateFieldValue,
		value,
		clearError,
		isConditionField,
	} = params;

	const [selectedTags, setSelectedTags] = useState([]);

	const { data: tagsList } = useQuery(
		getDropdownKeyByFieldId(field.fieldId),
		() => getFieldDropdownData({ name: field.dropdown.name }),
		{
			enabled: !!field?.fieldId,
			select: getSelectTagsList,
		}
	);

	const handleChangeTags = (tags) => {
		setSelectedTags(tags);
		updateFieldValue(tags, field.fieldId);
		clearError(field.fieldId);
	};

	useEffect(() => {
		if (Array.isArray(value)) {
			setSelectedTags(value);
		}
	}, [value]);

	return (
		<>
			<MultiSelectBaseComponent
				style={style}
				error={error}
				isConditionField={isConditionField}
				selectedValues={selectedTags}
				options={tagsList || []}
				onFieldChange={handleChangeTags}
			/>
		</>
	);
}

import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import { default as SearchIcon } from "../../../assets/icons/search";

export default function MenuSearchBar(props) {
	const {
		backgroundColor,
		placeholder,
		minHeight = "42px",
		...other
	} = props;

	return (
		<React.Fragment>
			<TextField
				{...other}
				placeholder={placeholder || "Search"}
				onKeyDown={(e) => e.stopPropagation()}
				InputProps={{
					startAdornment: (
						<InputAdornment
							position="start"
							style={{
								paddingLeft: "4px",
								paddingRight: "8px",
							}}
						>
							{SearchIcon(14, 14, "rgba(0, 0, 0, 0.6)", "2")}
						</InputAdornment>
					),
				}}
				sx={{
					"& .MuiInputBase-root": {
						backgroundColor: backgroundColor
							? backgroundColor
							: "#F4F5F5",
						borderRadius: "8px",
					},
					input: {
						"&::placeholder": {
							color: "black",
							opacity: 0.6,
						},
					},
					"& .MuiOutlinedInput-notchedOutline": {
						borderRadius: "8px",
					},
					"& .MuiOutlinedInput-root": {
						minHeight: minHeight,
						"& fieldset": {
							border: "none",
						},
						"&:hover fieldset": {
							border: "none",
						},
						"&.Mui-focused fieldset": {
							border: "none",
						},
					},
				}}
				inputProps={{
					style: {
						fontSize: "14px",
						fontWeight: 500,
					},
				}}
				autoComplete="off"
				fullWidth
			/>
		</React.Fragment>
	);
}

import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { default as FilesIcon } from "../../../../../assets/icons/files";

export default function File(props) {
	const { matchedFiles, highlightText, displayLinkedModule } = props;

	return (
		<React.Fragment>
			<Stack
				direction="row"
				alignItems="flex-start"
				justifyContent="space-between"
				width="100%"
			>
				<Stack
					direction="row"
					alignItems="center"
					spacing={3}
					width="80%"
				>
					<Box alignItems="start">
						<IconButton
							style={{
								padding: 0,
							}}
						>
							{FilesIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
						</IconButton>
					</Box>

					<Box width="85%">
						<Typography fontSize={15} noWrap>
							{highlightText(matchedFiles.name)}
						</Typography>
					</Box>
				</Stack>

				<Box>{displayLinkedModule()}</Box>
			</Stack>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import { Box, Divider, Stack, useTheme } from "@mui/material";
import { MenuItemText, TableCellText } from "../../../../styles/twozo";
import Menu from "../../Menu";

export default function Emails(props) {
	const { data } = props;
	const theme = useTheme();
	const [emailMenuElement, setEmailMenuElement] = useState(null);
	const openEmailMenu = Boolean(emailMenuElement);

	const OpenEmailMenu = (event) => {
		event.stopPropagation();
		setEmailMenuElement(event.currentTarget);
	};

	const CloseEmailMenu = (event) => {
		event.stopPropagation();
		setEmailMenuElement(null);
	};

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<Menu
				minWidth="400px"
				anchorEl={emailMenuElement}
				open={openEmailMenu}
				onClose={(e) => CloseEmailMenu(e)}
				style={{
					marginTop: "8px",
				}}
				menuListProps={{
					padding: "0px",
				}}
			>
				{data?.map((email, index) => (
					<Box key={email.id}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							spacing={3}
							pl={3}
							pr={2}
							py={1.5}
						>
							<MenuItemText
								style={{
									color: theme.palette.primary.main,
								}}
							>
								{email?.emailValue}
							</MenuItemText>

							<MenuItemText color="rgba(0, 0, 0, 0.6)">
								{email?.emailType}
							</MenuItemText>
						</Stack>

						{index !== data.length - 1 && <Divider />}
					</Box>
				))}
			</Menu>

			<Box onClick={(e) => OpenEmailMenu(e)}>
				<TableCellText
					style={{
						fontWeight: "500",
						color: theme.palette.primary.main,
						whiteSpace: "nowrap",
					}}
				>
					{data?.[0]?.emailValue} &nbsp;
					{data.length > 1 && `+${data.length - 1}`}
				</TableCellText>
			</Box>
		</React.Fragment>
	);
}

import React, { useMemo } from "react";
import {
	Timeline,
	TimelineConnector,
	timelineConnectorClasses,
	TimelineContent,
	TimelineDot,
	timelineDotClasses,
	TimelineItem,
	TimelineOppositeContent,
	timelineOppositeContentClasses,
	TimelineSeparator,
} from "@mui/lab";
import {
	Box,
	CircularProgress,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { getIconByName } from "../../../utils";
import { Tooltip } from "../../../styles/twozo/twozo";
import {
	getDateMonthAndYearFormat,
	getHourAndMinuteFormat,
} from "../../../utils/DateUtils";
import { useNavigate } from "react-router-dom";

export default function TimeLine(props) {
	const {
		timelineList,
		isLoadingTimelineDetails,
		lastRowRef,
		isFetchingMoreData,
	} = props;
	const theme = useTheme();
	const navigate = useNavigate();

	const timelineData = useMemo(() => {
		if (!timelineList || !Array.isArray(timelineList.pages)) {
			return { date: "", data: [] };
		}

		let timelineData = [];

		timelineList.pages
			.flatMap((page) => page.timeline)
			.forEach((timeline) => {
				let lastTimelineData = timelineData[timelineData.length - 1];

				if (
					!lastTimelineData ||
					lastTimelineData.date !== timeline.date
				) {
					timelineData.push({
						date: timeline.date,
						data: [...timeline.data],
					});
				} else {
					lastTimelineData.data.push(...timeline.data);
				}
			});

		return timelineData;
	}, [timelineList]);

	const styles = {
		text: {
			fontSize: "15px",
		},
		errorText: {
			fontSize: "15px",
			color: theme.palette.error.main,
		},
		greyedText: {
			fontSize: "15px",
			color: "rgba(0, 0, 0, 0.6)",
		},
		textSecondary: {
			fontSize: "14px",
			color: "rgba(0, 0, 0, 0.6)",
		},
		highlightedText: {
			fontSize: "15px",
			fontWeight: 500,
		},
		errorHighlightedText: {
			fontSize: "15px",
			fontWeight: 500,
			color: theme.palette.error.main,
		},
		coloredHighlightedText: {
			fontSize: "15px",
			fontWeight: 500,
			color: theme.palette.secondary.contrastText,
		},
		callPlaybackContainer: {
			width: "50%",
			border: "1px solid rgba(0, 0, 0, 0.1)",
			borderRadius: "8px",
			padding: "14px 18px",
		},
		callPlaybackProgressBar: {
			"& .MuiSlider-rail": {
				opacity: 0.5,
				backgroundColor: "#bfbfbf",
			},
		},
	};
	const maximumMergedContactToDisplay = 1;
	const maximumMergedCompanyToDisplay = 1;

	const handleDownloadFile = (fileId) => {
		window.location.href = `/api/file/download?fileId=${fileId}`;
	};

	const navigateToDeal = (dealId) => {
		navigate(`/deals/${dealId.toString()}`);
	};

	const getMergedContactsName = (contacts) => {
		const supplementaryContacts = contacts?.splice(
			maximumMergedContactToDisplay
		);
		return (
			<React.Fragment>
				{supplementaryContacts.map((contact, index) => (
					<div key={index}>{contact}</div>
				))}
			</React.Fragment>
		);
	};

	const getMergedCompaniesName = (companies) => {
		const supplementaryCompanies = companies?.splice(
			maximumMergedContactToDisplay
		);
		return (
			<React.Fragment>
				{supplementaryCompanies.map((company, index) => (
					<div key={index}>{company}</div>
				))}
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			{isLoadingTimelineDetails ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="50vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : timelineData?.length > 0 ? (
				<Timeline
					sx={{
						[`& .${timelineOppositeContentClasses.root}`]: {
							flex: 0,
							py: "18px",
							px: 2,
						},
						[`& .${timelineConnectorClasses.root}`]: {
							backgroundColor: theme.palette.secondary.main,
							margin: "-11.5px 0px",
						},
						[`& .${timelineDotClasses.root}`]: {
							boxShadow: "none",
						},
						padding: "0px 12px",
						wordBreak: "break-all",
					}}
				>
					{timelineData?.map((eventsForDay, eventsForDayIndex) => (
						<React.Fragment key={eventsForDayIndex}>
							<TimelineItem
								style={{
									minHeight:
										eventsForDay.data.length === 0
											? "58px"
											: "40px",
								}}
							>
								{/*Empty timeline opposite content to align the timeline*/}
								<TimelineOppositeContent
									style={{
										paddingLeft: "0px",
									}}
								>
									<Box width="110px"></Box>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot color="primary" />
									<TimelineConnector />
								</TimelineSeparator>
								<TimelineContent sx={{ py: 1, px: 3 }}>
									<Typography
										fontSize={15}
										fontWeight={500}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{getDateMonthAndYearFormat(
											eventsForDay.date
										)}
									</Typography>
								</TimelineContent>
							</TimelineItem>

							{eventsForDay.data.map((event, eventIndex) => (
								<TimelineItem
									key={event.id}
									style={{
										minHeight:
											eventsForDay.data.length - 1 ===
											eventsForDayIndex
												? "75px"
												: "24px",
									}}
								>
									<TimelineOppositeContent
										style={{
											paddingLeft: "0px",
										}}
									>
										<Box width="100px">
											<Typography
												fontSize={14}
												fontWeight={500}
											>
												{getHourAndMinuteFormat(
													event.auditedTime
												)}
											</Typography>
										</Box>
									</TimelineOppositeContent>

									<TimelineSeparator>
										<TimelineDot color="secondary">
											{getIconByName(event.icon?.name)(
												20,
												20,
												"#000"
											)}
										</TimelineDot>
										{eventsForDayIndex !==
											timelineData.length - 1 ||
										eventIndex !==
											eventsForDay.data.length - 1 ? (
											<TimelineConnector />
										) : null}
									</TimelineSeparator>
									<TimelineContent>
										{(function () {
											switch (event.type) {
												case "DEAL_CREATED":
													return (
														<Stack pb={1}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>{" "}
																was{" "}
																{event?.action}
															</Typography>
															<Typography
																style={
																	styles.textSecondary
																}
															>
																{
																	event
																		?.salesOwner
																		?.name
																}
															</Typography>
														</Stack>
													);
												case "DEAL_TITLE_UPDATED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>{" "}
																updated the deal
																title from{" "}
																<span
																	style={{
																		...styles.highlightedText,
																		color: event?.dealId
																			? theme
																					.palette
																					.secondary
																					.contrastText
																			: "#000",
																		cursor: event?.dealId
																			? "pointer"
																			: "auto",
																	}}
																	onClick={() =>
																		navigateToDeal(
																			event?.dealId
																		)
																	}
																>
																	{
																		event?.oldValue
																	}
																</span>{" "}
																to{" "}
																<span
																	style={{
																		...styles.highlightedText,
																		color: event?.dealId
																			? theme
																					.palette
																					.secondary
																					.contrastText
																			: "#000",
																		cursor: event?.dealId
																			? "pointer"
																			: "auto",
																	}}
																	onClick={() =>
																		navigateToDeal(
																			event?.dealId
																		)
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Stack>
													);
												case "DEAL_ASSOCIATED_WITH_CONTACT":
													return (
														<Stack pb={1}>
															<Typography
																style={
																	styles.text
																}
															>
																Deal{" "}
																<span
																	style={{
																		...styles.highlightedText,
																		color: theme
																			.palette
																			.secondary
																			.contrastText,
																		cursor: "pointer",
																	}}
																	onClick={() =>
																		navigateToDeal(
																			event?.dealId
																		)
																	}
																>
																	{
																		event?.newValue
																	}
																</span>{" "}
																associated with
																this contact{" "}
															</Typography>

															<Typography
																style={
																	styles.textSecondary
																}
															>
																{
																	event
																		?.salesOwner
																		?.name
																}
															</Typography>
														</Stack>
													);
												case "DEAL_ASSOCIATED_WITH_COMPANY":
													return (
														<Stack pb={1}>
															<Typography
																style={
																	styles.text
																}
															>
																Deal{" "}
																<span
																	style={{
																		...styles.highlightedText,
																		color: theme
																			.palette
																			.secondary
																			.contrastText,
																		cursor: "pointer",
																	}}
																	onClick={() =>
																		navigateToDeal(
																			event?.dealId
																		)
																	}
																>
																	{
																		event?.newValue
																	}
																</span>{" "}
																associated with
																this company{" "}
															</Typography>

															<Typography
																style={
																	styles.textSecondary
																}
															>
																{
																	event
																		?.salesOwner
																		?.name
																}
															</Typography>
														</Stack>
													);
												case "DEAL_VALUE_ASSIGNED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>{" "}
																assigned a value
																of{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Stack>
													);
												case "DEAL_VALUE_UPDATED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>{" "}
																updated a value
																of{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Stack>
													);
												case "DEAL_EXPECTED_CLOSE_DATE_ASSIGNED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>{" "}
																assigned the
																expected closing
																date{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{getDateMonthAndYearFormat(
																		parseInt(
																			event?.newValue
																		)
																	)}
																</span>
															</Typography>
														</Stack>
													);
												case "DEAL_EXPECTED_CLOSE_DATE_CHANGED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>{" "}
																changed the
																expected closing
																date from{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{getDateMonthAndYearFormat(
																		parseInt(
																			event?.oldValue
																		)
																	)}
																</span>{" "}
																to{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{getDateMonthAndYearFormat(
																		parseInt(
																			event?.newValue
																		)
																	)}
																</span>
															</Typography>
														</Stack>
													);
												case "DEAL_PIPELINE_STAGE_MOVED":
													return (
														<Box pt={1.25}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={{
																		...styles.highlightedText,
																		color: "rgba(0, 0, 0, 0.6)",
																	}}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>{" "}
																{event.action}{" "}
																this{" "}
																{event.category}{" "}
																from{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.oldValue
																	}
																</span>{" "}
																to{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Box>
													);
												case "NOW_POINT_OF_CONTACT_FOR_THIS_DEAL":
													return (
														<Stack pb={1}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>{" "}
																is now the point
																of contact for
																this deal{" "}
															</Typography>
															<Typography
																style={
																	styles.textSecondary
																}
															>
																{
																	event
																		?.salesOwner
																		?.name
																}
															</Typography>
														</Stack>
													);
												case "DEAL_LOST_REASON_SET":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>{" "}
																set the closed
																lost reason to{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Stack>
													);
												case "DEAL_LOST_REASON_UPDATED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>{" "}
																updated the
																closed lost
																reason as{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Stack>
													);
												case "DEAL_MERGED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.oldValue
																	}
																</span>{" "}
																merged with{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>{" "}
																to avoid
																duplicates
															</Typography>
														</Stack>
													);
												case "CONTACT_CREATED":
													return (
														<Stack pb={1}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>{" "}
																contact was{" "}
																{event.action}.
															</Typography>
															<Typography
																style={
																	styles.textSecondary
																}
															>
																{
																	event
																		?.salesOwner
																		?.name
																}
															</Typography>
														</Stack>
													);
												case "CONTACT_NAME_UPDATED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>{" "}
																updated the
																contact name
																from{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.oldValue
																	}
																</span>{" "}
																to{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Stack>
													);
												case "SUBSCRIPTION_STATUS_CHANGED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.contactName
																	}
																</span>{" "}
																has changed the
																subscription
																status from{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.oldValue
																	}
																</span>{" "}
																to{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Stack>
													);
												case "CONTACT_MERGED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event
																			?.oldValues?.[0]
																	}
																</span>{" "}
																{event
																	?.oldValues
																	?.length >
																maximumMergedContactToDisplay ? (
																	<span
																		style={
																			styles.highlightedText
																		}
																	>
																		<Tooltip
																			title={getMergedContactsName(
																				event?.oldValues
																			)}
																			placement="top"
																		>
																			<span
																				style={
																					styles.highlightedText
																				}
																			>
																				+
																				{
																					event
																						?.oldValues
																						?.length
																				}{" "}
																			</span>
																		</Tooltip>
																		Contacts{" "}
																	</span>
																) : null}
																was merged with{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>{" "}
																to avoid
																duplicates
															</Typography>
														</Stack>
													);
												case "CONTACT_LIFE_CYCLE_STAGE_UPDATED":
													return (
														<Box pt={1.25}>
															<Typography
																style={
																	styles.text
																}
															>
																Life cycle stage
																for this contact
																was{" "}
																{event.action}{" "}
																from{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.oldValue
																	}
																</span>{" "}
																to{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Box>
													);
												case "CONTACT_LIFE_CYCLE_STATUS_UPDATED":
													return (
														<Box pt={1.25}>
															<Typography
																style={
																	styles.text
																}
															>
																Life cycle
																status for this
																contact was{" "}
																{event.action}{" "}
																from{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.oldValue
																	}
																</span>{" "}
																to{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Box>
													);
												case "CONTACT_CLOSED_LOST_REASON_UPDATED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>{" "}
																updated the
																closed lost
																reason from{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.oldValue
																	}
																</span>{" "}
																to{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Stack>
													);
												case "CONTACT_CLOSED_LOST_REASON_SET":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>{" "}
																set the closed
																lost reason to{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Stack>
													);
												case "COMPANY_CREATED":
													return (
														<Stack pb={1}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>{" "}
																company was{" "}
																{event.action}
															</Typography>
															<Typography
																style={
																	styles.textSecondary
																}
															>
																{
																	event
																		?.salesOwner
																		?.name
																}
															</Typography>
														</Stack>
													);
												case "COMPANY_NAME_UPDATED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>{" "}
																updated the
																company name
																from{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.oldValue
																	}
																</span>{" "}
																to{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Stack>
													);
												case "COMPANY_MERGED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event
																			?.oldValues?.[0]
																	}
																</span>{" "}
																{event
																	?.oldValues
																	?.length >
																maximumMergedCompanyToDisplay ? (
																	<span
																		style={
																			styles.highlightedText
																		}
																	>
																		<Tooltip
																			title={getMergedCompaniesName(
																				event?.oldValues
																			)}
																			placement="top"
																		>
																			<span
																				style={
																					styles.highlightedText
																				}
																			>
																				+
																				{
																					event
																						?.oldValues
																						?.length
																				}{" "}
																			</span>
																		</Tooltip>
																		Contacts{" "}
																	</span>
																) : null}
																was merged with{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>{" "}
																to avoid
																duplicates
															</Typography>
														</Stack>
													);
												case "NOTES_ADDED":
													return (
														<Stack pb={1}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	Note{" "}
																	{
																		event?.action
																	}{" "}
																	by
																</span>{" "}
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>
															</Typography>
															<Typography
																fontSize={14}
																sx={{
																	wordBreak:
																		"break-word",
																}}
															>
																{event.note}
															</Typography>
														</Stack>
													);
												case "NOTES_EDITED":
													return (
														<Stack pb={1}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	Note{" "}
																	{
																		event?.action
																	}{" "}
																	by
																</span>{" "}
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>
															</Typography>
															<Typography
																fontSize={14}
																sx={{
																	wordBreak:
																		"break-word",
																}}
															>
																{event?.note}
															</Typography>
														</Stack>
													);
												case "FILE_UPLOADED":
													return (
														<Stack pb={1}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	File{" "}
																	{
																		event?.action
																	}{" "}
																	by
																</span>{" "}
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>
															</Typography>
															<Box
																onClick={() =>
																	handleDownloadFile(
																		event?.attachmentId
																	)
																}
																style={{
																	cursor: "pointer",
																	width: "fit-content",
																}}
															>
																<Typography
																	fontSize={
																		14
																	}
																	fontWeight={
																		500
																	}
																	color={
																		theme
																			.palette
																			.secondary
																			.contrastText
																	}
																	style={{
																		wordBreak:
																			"break-word",
																	}}
																>
																	{
																		event?.file
																	}
																</Typography>
															</Box>
														</Stack>
													);
												case "ACTIVITY_ADDED":
													return (
														<Stack
															pt={
																!event?.activityNote
																	? 1.25
																	: 0
															}
														>
															<Typography
																style={
																	styles.text
																}
															>
																{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}{" "}
																	-
																</span>{" "}
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>
															</Typography>

															{event?.activityNote ? (
																<Typography
																	sx={{
																		wordBreak:
																			"break-word",
																	}}
																	style={
																		styles.textSecondary
																	}
																>
																	{
																		event?.activityNote
																	}
																</Typography>
															) : null}
														</Stack>
													);
												case "ACTIVITY_TITLE_UPDATED":
													return (
														<Stack pt={1.3}>
															<Typography
																style={
																	styles.text
																}
															>
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>{" "}
																updated the
																activity title
																from{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.oldValue
																	}
																</span>{" "}
																to{" "}
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	{
																		event?.newValue
																	}
																</span>
															</Typography>
														</Stack>
													);
												case "ACTIVITY_NOTE_ADDED":
													return (
														<Stack pb={1}>
															<Typography>
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	Activity
																	note added
																	by
																</span>{" "}
																-{" "}
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>
															</Typography>

															<Typography
																fontSize={14}
																fontWeight={500}
																style={{
																	wordBreak:
																		"break-word",
																}}
															>
																{
																	event?.activityNote
																}
															</Typography>
														</Stack>
													);
												case "ACTIVITY_NOTE_EDITED":
													return (
														<Stack pb={1}>
															<Typography>
																<span
																	style={
																		styles.highlightedText
																	}
																>
																	Activity
																	note edited
																	by
																</span>{" "}
																-{" "}
																<span
																	style={
																		styles.greyedText
																	}
																>
																	{
																		event
																			?.salesOwner
																			?.name
																	}
																</span>
															</Typography>

															<Typography
																fontSize={14}
																fontWeight={500}
																style={{
																	wordBreak:
																		"break-word",
																}}
															>
																{
																	event?.activityNote
																}
															</Typography>
														</Stack>
													);
												case "EMAIL_SENT":
													return (
														<Stack pb={1.5} mt={-1}>
															<Typography
																fontSize={14}
															>
																To :{" "}
																{
																	event?.data
																		?.to?.[0]
																}{" "}
																{event?.data?.to
																	?.length >
																1 ? (
																	<span
																		style={
																			styles.textSecondary
																		}
																	>
																		+
																		{event
																			?.data
																			?.to
																			?.length -
																			1}
																	</span>
																) : null}
																<span
																	style={{
																		padding:
																			"0px 12px",
																		...styles.textSecondary,
																	}}
																>
																	&#8226;
																</span>
																<span
																	style={
																		styles.textSecondary
																	}
																>
																	From :{" "}
																	{
																		event
																			?.data
																			?.from
																	}
																</span>
															</Typography>

															<Typography
																style={
																	styles.highlightedText
																}
															>
																{event.data
																	?.subject ? (
																	event?.data
																		?.subject
																) : (
																	<span
																		style={{
																			fontWeight: 500,
																			...styles.greyedText,
																		}}
																	>
																		{
																			"(No Subject)"
																		}
																	</span>
																)}
															</Typography>

															<Typography
																fontSize={14}
															>
																{event.data
																	?.content
																	? event
																			?.data
																			?.content
																	: null}
															</Typography>
														</Stack>
													);
												case "EMAIL_RECEIVED":
													return (
														<Stack pb={1.5} mt={-1}>
															<Typography
																fontSize={14}
															>
																From :{" "}
																{
																	event?.data
																		?.from
																}
																<span
																	style={{
																		padding:
																			"0px 12px",
																		...styles.textSecondary,
																	}}
																>
																	&#8226;
																</span>
																<span
																	style={
																		styles.textSecondary
																	}
																>
																	To :{" "}
																	{
																		event
																			?.data
																			?.to?.[0]
																	}{" "}
																	{event?.data
																		?.to
																		?.length >
																	1 ? (
																		<span
																			style={
																				styles.textSecondary
																			}
																		>
																			+
																			{event
																				?.data
																				?.to
																				?.length -
																				1}
																		</span>
																	) : null}
																</span>
															</Typography>

															<Typography
																style={
																	styles.highlightedText
																}
															>
																{
																	event?.data
																		?.subject
																}
															</Typography>

															<Typography
																fontSize={14}
															>
																{event?.data
																	?.content
																	? event.data
																			.content
																	: null}
															</Typography>
														</Stack>
													);
												case "EMAIL_REPLIED":
													return (
														<Stack pb={1.5} mt={-1}>
															<Typography
																fontSize={14}
															>
																From :{" "}
																{
																	event?.data
																		?.from
																}
																<span
																	style={{
																		padding:
																			"0px 12px",
																		...styles.textSecondary,
																	}}
																>
																	&#8226;
																</span>
																<span
																	style={
																		styles.textSecondary
																	}
																>
																	To :{" "}
																	{
																		event
																			?.data
																			?.to?.[0]
																	}{" "}
																	{event?.data
																		?.to
																		?.length >
																	1 ? (
																		<span
																			style={
																				styles.textSecondary
																			}
																		>
																			+
																			{event
																				?.data
																				?.to
																				?.length -
																				1}
																		</span>
																	) : null}
																</span>
															</Typography>

															<Typography
																style={
																	styles.highlightedText
																}
															>
																{
																	event?.data
																		?.subject
																}
															</Typography>

															<Typography
																fontSize={14}
															>
																{event?.data
																	?.content
																	? event.data
																			.content
																	: null}
															</Typography>
														</Stack>
													);
												default:
													return null;
											}
										})()}
									</TimelineContent>
								</TimelineItem>
							))}
						</React.Fragment>
					))}
				</Timeline>
			) : (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="30vh"
				>
					<Typography fontSize={14} style={{ opacity: 0.6 }}>
						No records found
					</Typography>
				</Stack>
			)}

			<Box ref={lastRowRef}>
				{isFetchingMoreData && (
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="center"
						spacing={1}
						py={2}
					>
						<CircularProgress size={18} />

						<Typography fontSize={12}>Loading More Data</Typography>
					</Stack>
				)}
			</Box>
		</React.Fragment>
	);
}

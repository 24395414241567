import { useCallback, useReducer } from "react";

export const filterActions = {
	toggleSwipeableFilter: "TOGGLE_SWIPEABLE_FILTER",
	updateFilterUICriteriaList: "UPDATE_FILTER_UI_CRITERIA_LIST",
	applyFilter: "APPLY_FILTER",
	removeAppliedFilter: "REMOVE_APPLIED_FILTER",
	disableApplyButton: "DISABLE_APPLY_BUTTON",
	enableApplyButton: "ENABLE_APPLY_BUTTON",
	updateFilterCount: "UPDATE_FILTER_COUNT",
	updateApplyFilterId: "UPDATE_APPLY_FILTER_ID",
	openSwipeableFilter: "OPEN_SWIPEABLE_FILTER",
	closeSwipeableFilter: "CLOSE_SWIPEABLE_FILTER",
	updateActiveFilterName: "UPDATE_ACTIVE_FILTER_NAME",
	updateActiveFilterDetails: "UPDATE_ACTIVE_FILTER_DETAILS",
};

export const initialState = {
	isSwipeableFilterOpened: false,
	filterConditions: {},
	filterUICriteriaList: [],
	filterCount: null,
	isEdited: true,
	appliedFilterId: null,
	activeFiltername: "",
	activeFilterDetails: {},
};

export const reducer = (state, action) => {
	switch (action.type) {
		case filterActions.toggleSwipeableFilter:
			return {
				...state,
				isSwipeableFilterOpened: !state.isSwipeableFilterOpened,
			};
		case filterActions.updateFilterUICriteriaList:
			return {
				...state,
				filterUICriteriaList: action.payload,
			};
		case filterActions.applyFilter:
			return {
				...state,
				filterConditions: action.payload,
			};
		case filterActions.removeAppliedFilter:
			return {
				...state,
				filterConditions: {},
				filterUICriteriaList: [],
				filterCount: null,
			};
		case filterActions.disableApplyButton:
			return {
				...state,
				isEdited: false,
			};
		case filterActions.enableApplyButton:
			return {
				...state,
				isEdited: true,
			};
		case filterActions.updateFilterCount:
			return {
				...state,
				filterCount: action.payload,
			};
		case filterActions.updateApplyFilterId:
			return {
				...state,
				appliedFilterId: action.payload,
			};
		case filterActions.openSwipeableFilter:
			return {
				...state,
				isSwipeableFilterOpened: true,
			};
		case filterActions.closeSwipeableFilter:
			return {
				...state,
				isSwipeableFilterOpened: false,
			};
		case filterActions.updateActiveFilterName:
			return {
				...state,
				activeFiltername: action.payload,
			};
		case filterActions.updateActiveFilterDetails:
			return {
				...state,
				activeFilterDetails: action.payload,
			};
		default:
			return state;
	}
};

export const useFilterDataReducer = () => {
	const [filterState, filterStateDispatcher] = useReducer(
		reducer,
		initialState
	);

	const toggleSwipeableFilter = () => {
		filterStateDispatcher({ type: filterActions.toggleSwipeableFilter });
	};

	const filterListByCondition = useCallback((filterCriteria) => {
		filterStateDispatcher({
			type: filterActions.applyFilter,
			payload: filterCriteria,
		});
	}, []);

	const removeAppliedFilter = () => {
		filterStateDispatcher({ type: filterActions.removeAppliedFilter });
	};

	const disableApplyButton = useCallback(() => {
		filterStateDispatcher({ type: filterActions.disableApplyButton });
	}, []);

	const updateFilterCount = useCallback((filterConditionCount) => {
		filterStateDispatcher({
			type: filterActions.updateFilterCount,
			payload: filterConditionCount,
		});
	}, []);

	const updateAppliedFilterId = useCallback((id) => {
		filterStateDispatcher({
			type: filterActions.updateApplyFilterId,
			payload: id,
		});
	}, []);

	const updateFilterUICriteriaList = useCallback((conditions) => {
		filterStateDispatcher({
			type: filterActions.updateFilterUICriteriaList,
			payload: conditions,
		});
	}, []);

	const enableApplyButton = useCallback(() => {
		filterStateDispatcher({ type: filterActions.enableApplyButton });
	}, []);

	const openSwipeableFilter = () => {
		filterStateDispatcher({ type: filterActions.openSwipeableFilter });
	};

	const closeSwipeableFilter = () => {
		filterStateDispatcher({ type: filterActions.closeSwipeableFilter });
	};

	const updateActiveFilterName = useCallback((filterName) => {
		filterStateDispatcher({
			type: filterActions.updateActiveFilterName,
			payload: filterName,
		});
	}, []);

	const updateActiveFilterDetails = useCallback((filterDetails) => {
		filterStateDispatcher({
			type: filterActions.updateActiveFilterDetails,
			payload: filterDetails,
		});
	}, []);

	return {
		filterState,
		toggleSwipeableFilter,
		filterListByCondition,
		removeAppliedFilter,
		disableApplyButton,
		updateFilterCount,
		updateAppliedFilterId,
		updateFilterUICriteriaList,
		enableApplyButton,
		openSwipeableFilter,
		closeSwipeableFilter,
		updateActiveFilterName,
		updateActiveFilterDetails,
	};
};

import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../styles";
import Note from "../Fields/Note";
import CustomField from "../Fields/CustomField";
import { default as DealsIcon } from "../../../../../assets/icons/deals";
import DealState from "../Fields/dealState";

export default function Deal(props) {
	const {
		highlightText,
		matchedDeals,
		getFormattedDescription,
		onMouseOverDealMenu,
		handleCloseDealMenu,
	} = props;

	return (
		<React.Fragment>
			<Stack
				direction="row"
				alignItems="flex-start"
				justifyContent="space-between"
				width="100%"
			>
				<Stack direction="row" spacing={3} width="85%">
					<Box>
						<IconButton
							style={{
								padding: 0,
							}}
						>
							{DealsIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
						</IconButton>
					</Box>

					<Stack width="100%" spacing={"5px"}>
						<Typography
							width="fit-content"
							onMouseEnter={onMouseOverDealMenu}
							onMouseLeave={handleCloseDealMenu}
							fontSize={15}
						>
							{highlightText(matchedDeals.name)}
						</Typography>
						<Typography style={styles.subText}>
							{getFormattedDescription([
								!!matchedDeals.contact &&
									matchedDeals.contact.name,
								!!matchedDeals.company &&
									matchedDeals.company.name,
							])}
						</Typography>

						{matchedDeals.notes && (
							<Note
								highlightText={highlightText}
								notes={matchedDeals.notes}
							/>
						)}

						{matchedDeals.customFields && (
							<CustomField
								highlightText={highlightText}
								customFields={matchedDeals.customFields}
							/>
						)}
					</Stack>
				</Stack>

				<Box>{matchedDeals.state && DealState(matchedDeals)}</Box>
			</Stack>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { twozoStyles } from "../../../styles/twozo";
import { default as NotificationPendingIcon } from "../../../assets/icons/notificationPending";
import { default as NotificationIcon } from "../../../assets/icons/notification";
import {
	usePendingNotification,
	useUpdatePendingNotification,
} from "../../../hooks/services/notification/appNotification";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import Notification from "../../Elements/AppNotification";

export default function NotificationBar() {
	const classes = twozoStyles();
	const [isNotificationDrawerOpened, setIsNotificationDrawerOpened] =
		useState(false);

	const { data: pendingNotification } = usePendingNotification();
	const updatePendingNotificationMutation = useUpdatePendingNotification();

	const closeNotificationDrawer = () => {
		setIsNotificationDrawerOpened(false);
	};

	const openNotificationDrawer = () => {
		setIsNotificationDrawerOpened(true);
		updatePendingNotificationMutation.mutate();
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isNotificationDrawerOpened}
				onOpen={openNotificationDrawer}
				onClose={closeNotificationDrawer}
				disableBackdropClick={true}
			>
				<Box className={classes.addDialogContainer}>
					<Notification onClose={closeNotificationDrawer} />
				</Box>
			</CustomSwipeableDrawer>

			<Box
				display="flex"
				style={{
					backgroundColor: "#F4F5F5",
					borderRadius: "8px",
					cursor: "pointer",
					padding: 14,
				}}
				onClick={openNotificationDrawer}
			>
				<IconButton style={{ padding: 0 }}>
					{pendingNotification
						? NotificationPendingIcon(16, 16, "#626262", 2)
						: NotificationIcon(16, 16, "#626262", 2)}
				</IconButton>
			</Box>
		</React.Fragment>
	);
}

import React from "react";
import { Button, Stack, TextField } from "@mui/material";
import { FormFieldName } from "../../../styles/twozo";
import { useForm } from "react-hook-form";

export default function UpsertDashboardDetails(props) {
	const {
		onSubmit,
		onCancel,
		submitButtonLabel,
		defaultValues = {},
		disableSubmit = false,
	} = props;
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: { ...defaultValues },
	});

	return (
		<React.Fragment>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Stack p={2} spacing={2}>
					<Stack spacing={0.5}>
						<FormFieldName>Dashboard Name</FormFieldName>

						<TextField
							placeholder="Eg: Financial performance dashboard"
							{...register("dashboardName", {
								required: "Dashboard Name is required",
								maxLength: {
									value: 100,
									message:
										"Max. of 100 characters are allowed",
								},
							})}
							error={!!errors.dashboardName}
							helperText={
								errors.dashboardName
									? errors.dashboardName.message
									: ""
							}
						/>
					</Stack>

					<Stack spacing={0.5}>
						<FormFieldName>Description</FormFieldName>

						<TextField
							{...register("description", {
								maxLength: {
									value: 100,
									message:
										"Max. of 100 characters are allowed",
								},
							})}
							error={!!errors.description}
							helperText={
								errors.description
									? errors.description.message
									: ""
							}
							multiline
							rows={2}
						/>
					</Stack>

					<Stack
						direction="row"
						spacing={2}
						justifyContent="end"
						pt={1}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={onCancel}
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							type="submit"
							disabled={disableSubmit}
							disableElevation
						>
							{submitButtonLabel}
						</Button>
					</Stack>
				</Stack>
			</form>
		</React.Fragment>
	);
}

import React, { useEffect, useRef, useState } from "react";
import {
	Typography,
	Box,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	Stack,
	Button,
	CircularProgress,
} from "@mui/material";
import { TableHeaderLabel, twozoStyles } from "../../../../styles/twozo";
import companyProfile from "../../../../assets/images/company/companyProfile.png";
import ReviewDuplicates from "./ReviewDuplicates";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import {
	useCompanyDuplicateList,
	useDismissDuplicateCompany,
} from "../../../../hooks/services/company/companyDuplicates";
import ConfirmDialog from "../../ConfirmDialog";
import { enqueueSnackbar } from "notistack";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import Can from "../../../Auth/Can";
import CanNot from "../../../Auth/CanNot";
import UnAuthorized from "../../../Auth/UnAuthorized";
import { PERMISSIONS } from "../../../../utils/Auth";
import TooltipComponent from "../../TooltipComponent";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";

export default function DuplicateCompany() {
	const classes = twozoStyles();
	const companyDuplicateListRef = useRef();
	const [isReviewDialogOpened, setIsReviewDialogOpened] = useState(false);
	const [primaryCompanyId, setPrimaryCompanyId] = useState(null);
	const [isDismissDialogOpened, setIsDismissDialogOpened] = useState(false);
	const [
		companyDuplicateListStartingPosition,
		setCompanyDuplicateListStartingPosition,
	] = useState(0);

	const notificationTitle = "Success";

	const {
		data: companyDuplicateList,
		isLoading: isCompanyDuplicateListLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useCompanyDuplicateList();

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const hasDuplicateRecords =
		companyDuplicateList &&
		companyDuplicateList?.pages?.length > 0 &&
		companyDuplicateList?.pages[0]?.companies &&
		companyDuplicateList?.pages[0]?.companies?.length > 0;

	useEffect(() => {
		if (companyDuplicateListRef) {
			setCompanyDuplicateListStartingPosition(
				companyDuplicateListRef.current.getBoundingClientRect().top
			);
		}
	}, []);

	const dismissDuplicateCompanyMutation = useDismissDuplicateCompany();

	const toggleReviewDialogBox = (id) => {
		setIsReviewDialogOpened(
			(isReviewDialogOpened) => !isReviewDialogOpened
		);
		setPrimaryCompanyId(id);
	};

	const openDismissDialog = (id) => {
		setPrimaryCompanyId(id);
		setIsDismissDialogOpened(true);
	};

	const closeDismissDialog = () => {
		setIsDismissDialogOpened(false);
	};

	const dismissDuplicateCompany = () => {
		dismissDuplicateCompanyMutation.mutate(primaryCompanyId, {
			onSuccess: () => {
				closeDismissDialog();
				enqueueSnackbar({
					title: notificationTitle,
					variant: notificationVariants.error,
					message: notificationMessage.companyDismissMessage,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: {
						boxShadow: "-3px 0px 14px rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
					},
				}}
				open={isReviewDialogOpened}
				onOpen={toggleReviewDialogBox}
				onClose={toggleReviewDialogBox}
				BackdropProps={{ style: { backgroundColor: "transparent" } }}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					className={classes.addDialogContainer}
					style={{
						overflowY: "scroll",
						boxShadow: "-3px 0px 14px rgba(0, 0, 0, 0.1)",
					}}
				>
					<ReviewDuplicates
						primaryCompanyId={primaryCompanyId}
						onClose={toggleReviewDialogBox}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<ConfirmDialog
				open={isDismissDialogOpened}
				onCancel={closeDismissDialog}
				onConfirm={dismissDuplicateCompany}
				title="Are you sure you want to dismiss these companies?"
				subtitle="The duplicates found will no longer be suggested for merge."
				confirmButtonText="Dismiss"
			/>

			<Can permission={PERMISSIONS.company.mergeRecords}>
				<Box px={3}>
					<Typography fontSize={15} fontWeight={400}>
						Companies with the same name are considered duplicates.
					</Typography>
				</Box>

				<Box
					ref={companyDuplicateListRef}
					pt={1}
					sx={{
						height: `calc(100vh - ${companyDuplicateListStartingPosition}px)`,
						overflowY: "auto",
					}}
				>
					<TableContainer sx={{ maxHeight: "100%" }}>
						<Table stickyHeader sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow>
									<TableCell>
										<TableHeaderLabel ml={1}>
											Primary Company
										</TableHeaderLabel>
									</TableCell>
									<TableCell>
										<TableHeaderLabel ml={1}>
											Secondary Company
										</TableHeaderLabel>
									</TableCell>
									<TableCell>
										<TableHeaderLabel ml={1}>
											Actions
										</TableHeaderLabel>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{isCompanyDuplicateListLoading ? (
									<TableRow
										style={{
											height: "60vh",
											border: "2px solid red",
										}}
									>
										<TableCell
											colSpan={3}
											sx={{ borderBottom: 0 }}
										>
											<Stack
												height="100%"
												justifyContent="center"
												alignItems="center"
												width="100%"
											>
												<CircularProgress size={30} />
											</Stack>
										</TableCell>
									</TableRow>
								) : hasDuplicateRecords ? (
									companyDuplicateList.pages.map((page) =>
										page.companies?.map(
											(duplicateCompany, index) => (
												<TableRow key={index}>
													<TableCell>
														<Box p={1}>
															<Stack
																alignItems="center"
																direction="row"
																spacing={2}
															>
																<img
																	style={{
																		borderRadius:
																			"100%",
																	}}
																	alt="companyImage"
																	src={
																		companyProfile
																	}
																	width="48px"
																	height="48px"
																/>
																<Stack>
																	<TooltipComponent
																		title={
																			duplicateCompany
																				.primary
																				.name
																		}
																		placement="top"
																		width="450px"
																	>
																		<Typography
																			fontSize={
																				15
																			}
																			noWrap
																			maxWidth="411px"
																		>
																			{
																				duplicateCompany
																					.primary
																					.name
																			}
																		</Typography>
																	</TooltipComponent>
																</Stack>
															</Stack>
														</Box>
													</TableCell>
													<TableCell>
														<Box p={1}>
															<Stack
																alignItems="center"
																direction="row"
																spacing={2}
															>
																<img
																	style={{
																		borderRadius:
																			"100%",
																	}}
																	alt="companyImage"
																	src={
																		companyProfile
																	}
																	width="48px"
																	height="48px"
																/>
																<Stack>
																	<TooltipComponent
																		title={
																			duplicateCompany
																				.secondary
																				.name
																		}
																		placement="top"
																		width="450px"
																	>
																		<Typography
																			fontSize={
																				15
																			}
																			noWrap
																			maxWidth="411px"
																		>
																			{
																				duplicateCompany
																					.secondary
																					.name
																			}
																		</Typography>
																	</TooltipComponent>
																</Stack>
															</Stack>
														</Box>
													</TableCell>
													<TableCell>
														<Stack
															p={1}
															direction="row"
															spacing={2}
															alignItems="center"
														>
															<Button
																variant="contained"
																onClick={() =>
																	toggleReviewDialogBox(
																		duplicateCompany
																			?.primary
																			?.id
																	)
																}
																disableElevation
															>
																Review
															</Button>
															<Button
																variant="contained"
																color="secondary"
																disableElevation
																onClick={() =>
																	openDismissDialog(
																		duplicateCompany
																			.primary
																			.id
																	)
																}
															>
																Dismiss
															</Button>
														</Stack>
													</TableCell>
												</TableRow>
											)
										)
									)
								) : (
									<TableRow>
										<TableCell
											colSpan={7}
											sx={{
												border: "none",
											}}
										>
											<Stack
												alignItems="center"
												justifyContent="center"
												style={{
													height: "400px",
													width: "100%",
												}}
											>
												<Typography
													fontSize={14}
													fontWeight={400}
													style={{
														opacity: 0.6,
													}}
												>
													No Duplicates Found
												</Typography>
											</Stack>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>

						<Box ref={lastRowRef}>
							{!isCompanyDuplicateListLoading &&
								isFetching &&
								hasDuplicateRecords && (
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="center"
										spacing={1}
										py={2}
									>
										<CircularProgress size={18} />

										<Typography fontSize={12}>
											Loading More Data
										</Typography>
									</Stack>
								)}
						</Box>
					</TableContainer>
				</Box>
			</Can>

			<CanNot permission={PERMISSIONS.company.mergeRecords}>
				<Box height="50vh">
					<UnAuthorized />
				</Box>
			</CanNot>
		</React.Fragment>
	);
}

import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	Divider,
	Grid,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { twozoStyles } from "../../../styles/twozo";
import { default as DropDownRight } from "../../../assets/icons/dropDownRight";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as CopyIcon } from "../../../assets/icons/copy";
import AddEmailOrManageUserDialog from "./AddEmailOrManageUserDialog";
import TeamInboxEmailList from "./TeamInboxEmailList";

export default function AdminSettingsTeamInbox() {
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();
	const emailListRef = useRef(null);
	const [emailListStartingPosition, setEmailListStartingPostion] =
		useState(0);
	const [isAddTeamInboxDialogOpened, setIsAddTeamInboxDialogOpened] =
		useState(false);

	useEffect(() => {
		if (emailListRef?.current) {
			setEmailListStartingPostion(
				emailListRef.current.getBoundingClientRect().top
			);
		}
	}, [emailListRef]);

	const openAddTeamInboxDialog = () => {
		setIsAddTeamInboxDialogOpened(true);
	};

	const closeAddTeamInboxDialog = () => {
		setIsAddTeamInboxDialogOpened(false);
	};

	return (
		<React.Fragment>
			<AddEmailOrManageUserDialog
				title="Add Team Inbox"
				open={isAddTeamInboxDialogOpened}
				onClose={closeAddTeamInboxDialog}
			/>

			<Box className={classes.menuBar}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					height="100%"
				>
					<Stack direction="row" alignItems="center" spacing={1}>
						<Box
							style={{ cursor: "pointer" }}
							onClick={() => navigate("/settings")}
						>
							<Typography
								fontWeight={500}
								fontSize={15}
								color={theme.palette.secondary.contrastText}
							>
								Admin Settings
							</Typography>
						</Box>

						{DropDownRight(16, 16)}

						<Typography fontWeight={500} fontSize={15}>
							Team Inbox
						</Typography>
					</Stack>

					<Button
						variant="contained"
						startIcon={AddIcon(20, 20, "#fff")}
						color="primary"
						disableElevation
						onClick={openAddTeamInboxDialog}
					>
						Add Team Inbox
					</Button>
				</Stack>
			</Box>

			<Stack py={2} pl={3} spacing={"5px"}>
				<Typography fontWeight={500} fontSize={17}>
					Team Inbox
				</Typography>
				<Typography color="rgba(0, 0, 0, 0.6)" fontSize={14}>
					Admins can create a common inbox, like
					contact-us@yourcompany.com, where everyone in the team will
					have access to all emails and the team can step in and
					respond to open conversations.
				</Typography>
			</Stack>

			<Divider />

			<Box p={1}>
				<Grid container spacing={1} direction="row">
					<Grid item xs={12} md={9}>
						<Box
							ref={emailListRef}
							sx={{
								minHeight: `calc(100vh - ${
									emailListStartingPosition + 8
								}px)`,
								border: "1px solid rgba(0, 0, 0, 0.1)",
								borderRadius: "8px",
							}}
						>
							<TeamInboxEmailList />
						</Box>
					</Grid>

					<Grid item xs={12} md={3}>
						<Box
							sx={{
								border: "1px solid rgba(0, 0, 0, 0.1)",
								borderRadius: "8px",
								padding: "16px",
							}}
						>
							<Stack spacing={2}>
								<Typography fontSize={15}>
									To setup auto-forwarding from your email
									provider, copy this address and paste it in
									your provider’s settings.{" "}
									<span
										style={{
											color: theme.palette.secondary
												.contrastText,
										}}
									>
										Learn More
									</span>
								</Typography>

								<Stack direction="row">
									<TextField
										value="thiscrm@crm.com"
										fullWidth
										sx={{
											"& .MuiOutlinedInput-notchedOutline":
												{
													borderRadius:
														"8px 0px 0px 8px",
												},
										}}
									/>
									<Button
										style={{
											border: "1px solid rgba(0, 0, 0, 0.1)",
											borderRadius: "0px 8px 8px 0px",
											height: "42px",
											marginLeft: "-1px",
										}}
										variant="contained"
										color="secondary"
										disableElevation
									>
										{CopyIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</Button>
								</Stack>
							</Stack>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</React.Fragment>
	);
}

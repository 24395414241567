import { useTheme } from "@emotion/react";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "../../styles";
import ConfirmDialog from "../../../../../Elements/ConfirmDialog";
import {
	useInvalidateSyncSettingsData,
	useStartContactSync,
	useStopContactSync,
} from "../../../../../../hooks/services/contactSync";
import { notificationVariants } from "../../../../../../utils/notification/notificationConfig";
import {
	contactSyncState,
	contactSyncStatus,
	groupOfContactsList,
	syncDirections,
} from "../../../../../../utils/contactSyncUtils";
import { enqueueSnackbar } from "notistack";
import { useContactSyncStatus } from "../../../../../../hooks/services/contactSync";
import { notificationMessage } from "../../../../../../utils/notification/notificationMessages";
import { MenuItemText } from "../../../../../../styles/twozo";

export default function ContactSyncSettings(props) {
	const { contactSyncSettingsData, updateSyncState } = props;

	const theme = useTheme();
	const [syncStatus, setSyncStatus] = useState(
		contactSyncSettingsData?.status || ""
	);
	const isSyncStateSyncing = syncStatus === contactSyncStatus.SYNCING;

	// mutation
	const contactSyncMutation = useStartContactSync();
	const stopSyncMutation = useStopContactSync();
	let invalidateSyncSettingsData = useInvalidateSyncSettingsData();

	// query call
	const { data: contactSyncStatusData, status: contactSyncStatusApiStatus } =
		useContactSyncStatus(contactSyncSettingsData?.id, isSyncStateSyncing);

	const [syncWay, setSyncWay] = useState("");
	const [groupOfContact, setGroupOfContact] = useState("");

	const [isConfirmContactSyncDialogOpen, setIsConfirmContactSyncDialogOpen] =
		useState(false);

	const handleCloseConfirmContactSyncDialog = () => {
		setIsConfirmContactSyncDialogOpen(false);
	};

	useEffect(() => {
		if (contactSyncSettingsData) {
			setSyncStatus(contactSyncSettingsData?.status);
			setSyncWay(contactSyncSettingsData?.syncConfig?.syncWay);
			setGroupOfContact(
				contactSyncSettingsData?.syncConfig?.groupOfContacts
			);
		}
	}, [contactSyncSettingsData]);

	useEffect(() => {
		// This useEffect handles showing a notification when the sync state is active.
		// It checks if the contactSyncStatusApiStatus is "success" and then updates the sync state accordingly.
		// If the contactSyncStatusData is ACTIVE, a success notification is displayed to the user.

		if (contactSyncStatusApiStatus === "success") {
			if (
				contactSyncStatusData &&
				syncStatus !== contactSyncStatus.ACTIVE
			) {
				setSyncStatus(contactSyncStatusData);
				if (contactSyncStatusData === contactSyncStatus.ACTIVE) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.contactSyncMessage,
					});
					invalidateSyncSettingsData();
				}
			}
		}
	}, [contactSyncStatusData, contactSyncStatusApiStatus, syncStatus]);

	const getContactSyncRequestData = () => {
		let requestData = { ...contactSyncSettingsData };
		requestData.syncConfig.groupOfContacts = groupOfContact;
		requestData.syncConfig.syncWay = syncWay;

		return requestData;
	};

	const handleContactSync = () => {
		let requestData = getContactSyncRequestData();
		contactSyncMutation.mutate(requestData, {
			onSuccess: () => {
				setSyncStatus(contactSyncStatus.SYNCING);
				handleCloseConfirmContactSyncDialog();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleChangeGroupOfContact = (event) => {
		const { value } = event.target;
		setGroupOfContact(value);
	};

	const handleChangeSyncWay = (event) => {
		const { value } = event.target;
		setSyncWay(value);
	};

	const onCancelClick = () => {
		if (syncStatus === contactSyncStatus.ACTIVE) {
			updateSyncState(contactSyncState.syncEnabled);
		} else {
			stopSyncMutation.mutate(
				{},
				{
					onSuccess: () => {
						handleCloseConfirmContactSyncDialog();
						enqueueSnackbar({
							variant: notificationVariants.success,
							message:
								notificationMessage.contactDisconnectMessage,
						});
					},
					onError: (error) => {
						let errorMessage = error?.message;
						enqueueSnackbar({
							variant: notificationVariants.error,
							message:
								errorMessage ??
								notificationMessage.errorMessage,
						});
					},
				}
			);
		}
	};

	const onChangeAccountClick = () => {
		if (isSyncStateSyncing) {
			return;
		}

		stopSyncMutation.mutate(
			{},
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.contactDisconnectMessage,
					});
				},
				onError: (error) => {
					let errorMessage = error?.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						message:
							errorMessage ?? notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	return (
		<React.Fragment>
			<ConfirmDialog
				open={isConfirmContactSyncDialogOpen}
				title="Are you sure you want to enable contact sync for your account?"
				subtitle="Please note that the provider has a limit of 25000 maximum contacts. When you reach this limit, we'll stop syncing your contacts with the provider."
				onCancel={handleCloseConfirmContactSyncDialog}
				onConfirm={handleContactSync}
				confirmButtonColor="primary"
				confirmButtonText="Enable Contact Sync"
			/>

			<Box
				style={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
				}}
				m={1}
			>
				<Stack direction="row" justifyContent="space-between" p={2}>
					<Stack direction="row" alignItems="center" spacing={1.5}>
						<Typography style={styles.heading}>
							{contactSyncSettingsData?.emailAddress}
						</Typography>

						<Stack
							direction="row"
							alignItems="center"
							style={{
								backgroundColor: theme.palette.secondary.main,
								borderRadius: "6px",
							}}
							py={0.5}
							px={1}
							spacing={1}
						>
							{isSyncStateSyncing && (
								<CircularProgress size="16px" thickness={6} />
							)}
							<Typography
								style={styles.subText}
								color={theme.palette.primary.main}
							>
								{syncStatus}
							</Typography>
						</Stack>
					</Stack>

					<Box>
						<Button
							variant="contained"
							disableElevation
							color="secondary"
							style={{
								opacity: isSyncStateSyncing && 0.6,
							}}
							onClick={onChangeAccountClick}
						>
							Change Account
						</Button>
					</Box>
				</Stack>

				<Divider />

				<Box
					px={2}
					py={2}
					style={{
						opacity: isSyncStateSyncing && 0.6,
						pointerEvents: isSyncStateSyncing ? "none" : "auto",
					}}
				>
					<Box>
						<Typography style={styles.heading}>
							Group of Contacts
						</Typography>

						<Stack pt={1} pb={2} spacing={0.5}>
							<Typography style={styles.subHeading} pl={1}>
								Select from the group folders given by the
								provider
							</Typography>

							<Select
								sx={{ width: "528px" }}
								value={groupOfContact || ""}
								onChange={handleChangeGroupOfContact}
							>
								{groupOfContactsList.map((contact) => (
									<MenuItem
										key={contact.value}
										value={contact.value}
									>
										<MenuItemText>
											{contact.name}
										</MenuItemText>
									</MenuItem>
								))}
							</Select>
						</Stack>
					</Box>

					<Divider />

					<Box pt={1}>
						<Typography style={styles.heading}>
							Sync Direction
						</Typography>

						<Box pt={1}>
							<FormControl sx={{ mt: 1, pb: 2 }}>
								<RadioGroup
									value={syncWay}
									onChange={handleChangeSyncWay}
								>
									<FormControlLabel
										value={syncDirections.oneWay}
										control={
											<Radio
												style={styles.customRadioButton}
											/>
										}
										label={
											<Stack pb={1}>
												<Typography
													style={{ fontSize: "15px" }}
												>
													One-Way
												</Typography>

												<Typography style={styles.text}>
													Receive new contacts,
													contact updates and deletion
													into Twozo
												</Typography>
											</Stack>
										}
									/>

									<FormControlLabel
										value={syncDirections.twoWay}
										control={
											<Radio
												style={styles.customRadioButton}
											/>
										}
										label={
											<Stack>
												<Typography
													style={{ fontSize: "15px" }}
												>
													Two-Way
												</Typography>

												<Typography style={styles.text}>
													Mirror all contacts
													including new, contact
													updates and deletions
												</Typography>
											</Stack>
										}
									/>
								</RadioGroup>
							</FormControl>
						</Box>
					</Box>

					<Divider />

					<Stack direction="row" spacing={2} px={2} pt={2}>
						<Button
							variant="contained"
							disableElevation
							onClick={() =>
								setIsConfirmContactSyncDialogOpen(true)
							}
						>
							Start Syncing
						</Button>

						<Button
							variant="contained"
							disableElevation
							color="secondary"
							onClick={onCancelClick}
						>
							Cancel
						</Button>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}

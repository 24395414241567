export const validateStringField = (field, config, isActionField) => {
	const value = field?.values?.[0]?.value?.trim();
	const minLength = config?.minLength || 0;
	const maxLength = config?.maxLength;

	if (!value) {
		return { error: true, message: isActionField ? "" : "Can’t be empty" };
	}
	if (value.length < minLength) {
		return {
			error: true,
			message: `Min. of ${minLength} characters are required`,
		};
	}
	if (maxLength && value.length > maxLength) {
		return {
			error: true,
			message: `Max. of ${maxLength} characters are allowed`,
		};
	}

	if (config?.validation?.regex) {
		const regex = new RegExp(config?.validation?.regex);
		if (!regex.test(value)) {
			return {
				error: true,
				message: config?.validation?.violationMessage ?? "Invalid URL",
			};
		}
	}
	return { error: false, message: "" };
};

import {
	Box,
	Collapse,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import { useStepOptions } from "../../../../../hooks/services/workflow";
import { useEffect, useMemo, useState } from "react";
import { default as DropdownIcon } from "../../../../../assets/icons/dropDown";
import { MenuItemText } from "../../../../../styles/twozo";

export default function StepPickerComponent(params) {
	const {
		field = {},
		metadata,
		updateFieldValue,
		error,
		value,
		clearError,
	} = params;
	const [selectedValue, setSelectedValue] = useState("");
	const [listOpened, setListOpened] = useState(!!selectedValue);

	const { data: stepOptions = [] } = useStepOptions(metadata, field);

	useEffect(() => {
		if (value) {
			setSelectedValue(value?.value);
		}
	}, [value]);

	const renderEmptyView = (noOptionsFound = false) => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{stepOptions?.length === 0 || noOptionsFound
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	const onFieldChange = (value) => {
		updateFieldValue([value], field.fieldId);
		clearError(field.fieldId);
	};

	const handleValueChange = (value, option) => {
		let fieldValue = {
			value: value,
			dependsOn: option?.dependsOn,
		};

		setSelectedValue(value);
		onFieldChange(fieldValue);
	};

	const stepOptionFields = useMemo(() => {
		if (Array.isArray(stepOptions)) {
			return stepOptions;
		}
		return [];
	}, [stepOptions]);

	return (
		<>
			<Select
				value={selectedValue ? selectedValue?.name || "" : ""}
				displayEmpty
				MenuProps={{
					PaperProps: {
						style: {
							maxHeight: 350,
						},
					},
				}}
				renderValue={() => (
					<Typography
						fontSize={15}
						color={selectedValue ? "#000" : "rgba(0, 0, 0, 0.4)"}
					>
						{selectedValue ? selectedValue.name : "-Select-"}
					</Typography>
				)}
				fullWidth
				sx={{
					backgroundColor: "#fff",
				}}
				error={error?.error}
			>
				{stepOptionFields.length > 0
					? stepOptionFields?.map((option, index) => (
							<Box key={index}>
								<MenuItem
									style={{ minHeight: "40px" }}
									onClick={() =>
										setListOpened(
											(listOpened) => !listOpened
										)
									}
								>
									<Stack
										width="100%"
										direction="row"
										alignItems="center"
										justifyContent="space-between"
										spacing={0.5}
									>
										<MenuItemText>
											{option.source}
										</MenuItemText>
										<Box pt={0.5}>
											{DropdownIcon(14, 14, "#000")}
										</Box>
									</Stack>
								</MenuItem>

								<Collapse
									in={!!listOpened}
									timeout="auto"
									unmountOnExit
								>
									{option.fields.map((field, index) => (
										<MenuItem
											value={field}
											key={index}
											selected={
												field?.id === selectedValue?.id
											}
											style={{ minHeight: "40px" }}
											onClick={() => {
												handleValueChange(
													field,
													option
												);
											}}
										>
											<MenuItemText pl={2}>
												{field.name}
											</MenuItemText>
										</MenuItem>
									))}
								</Collapse>
							</Box>
						))
					: renderEmptyView()}
			</Select>
		</>
	);
}

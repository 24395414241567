import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { default as DropdownRightIcon } from "../../../../../../assets/icons/dropDownRight";
import { TextButton } from "../../../../../../styles/twozo";
import { useNavigate } from "react-router-dom";

export const Breadcrumbs = () => {
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<React.Fragment>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="center"
				spacing={1}
				p={1}
				sx={{ minHeight: "65px" }}
			>
				<TextButton
					onClick={() => navigate("/analytics", { replace: false })}
				>
					<Typography
						fontWeight={500}
						fontSize={15}
						color={theme.palette.secondary.contrastText}
					>
						Analytics
					</Typography>
				</TextButton>

				{DropdownRightIcon(16, 16, "#000")}

				<Typography fontWeight={500} fontSize={15}>
					Create Dashboard
				</Typography>
			</Stack>
		</React.Fragment>
	);
};

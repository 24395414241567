import React, { useCallback, useState } from "react";
import Table from "../../Elements/Table";
import { sourceType } from "../../../utils/queryKeys";
import ActivityMenu from "../Menu";
import SelectedMenu from "../SelectedMenu";
import ActivityFilters from "../ActivityFilters";
import { activityView } from "../../../utils/ActivitiesUtils";
import { modules } from "../../../utils/common/ModulesName";
import { FilterSaveViewProvider } from "../../Elements/Table/Filter/Context/FilterSaveViewContext";
import { FilterDataProvider } from "../../Elements/Table/Filter/Context/FilterDataContext";
import { tableActions } from "../../../utils/tableUtills";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { Box } from "@mui/material";
import AddActivity from "../../Summary/Activities/AddActivity";
import { twozoStyles } from "../../../styles/twozo";

const noResultsMessage = {
	title: "No Activities Found",
	message: "Try resetting your filters or create new activity",
};

export default function ListView(props) {
	const { handleViewToggleButtonGroup, openActivitySummaryDialog } = props;
	const tableName = sourceType.ACTIVITY;
	const classes = twozoStyles();
	const [isAddActivityDialogOpened, setIsAddActivityDialogOpened] =
		useState(false);
	const [activityFormData, setActivityFormData] = useState({});

	const openAddActivityDialog = () => {
		setIsAddActivityDialogOpened(true);
	};

	const closeAddActivityDialog = () => {
		setIsAddActivityDialogOpened(false);
	};

	const openActivitySummary = useCallback(
		(row) => {
			openActivitySummaryDialog(row.id);
		},
		[openActivitySummaryDialog]
	);

	const handleAddActivity = useCallback((activityData) => {
		openAddActivityDialog();
		setActivityFormData(activityData);
	}, []);

	const handleTableRowAction = useCallback(
		(action, actionData) => {
			switch (action) {
				case tableActions.rowClickAction:
					openActivitySummary(actionData);
					break;
				case tableActions.activityStatusCellClickAction:
					handleAddActivity(actionData);
					break;
				default:
					break;
			}
		},
		[openActivitySummary, handleAddActivity]
	);

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddActivityDialogOpened}
				onOpen={openAddActivityDialog}
				onClose={closeAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={closeAddActivityDialog}
						companyValue={
							activityFormData?.company
								? {
										name: activityFormData?.company?.name,
										value: activityFormData?.company?.id,
									}
								: null
						}
						dealData={
							activityFormData?.deal
								? {
										name: activityFormData.deal?.title,
										value: activityFormData.deal?.id,
									}
								: null
						}
						contactIds={
							activityFormData?.contact
								? [activityFormData?.contact?.id]
								: null
						}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<FilterDataProvider>
				<FilterSaveViewProvider>
					<Table
						table={tableName}
						moduleName={modules.ACTIVITY}
						viewName="All Activities"
						menu={
							<ActivityMenu
								view={activityView.list}
								handleViewToggleButtonGroup={
									handleViewToggleButtonGroup
								}
							/>
						}
						selectedMenu={<SelectedMenu />}
						enhancedMenu={<ActivityFilters />}
						handleTableRowAction={handleTableRowAction}
						isActionsVisible={false}
						noResultsMessage={noResultsMessage}
						hasShareViewPermissions={true}
					/>
				</FilterSaveViewProvider>
			</FilterDataProvider>
		</React.Fragment>
	);
}

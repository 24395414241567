import React, { useState } from "react";
import Dialog from "../../../Elements/Dialog";
import {
	Box,
	Button,
	Stack,
	TextField,
	Typography,
	ToggleButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	useTheme,
} from "@mui/material";
import ToggleButtonGroup from "../../../Elements/ToggleButtonGroup";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import { TableHeaderLabel } from "../../../../styles/twozo";
import { default as closeIcon } from "../../../../assets/icons/close";

const AccessList = () => {
	const theme = useTheme();
	const styles = {
		tableData: {
			backgroundColor: "#FFF",
			borderRadius: "8px",
			justifyContent: "space-between",
			alignItems: "center",
			padding: "4px",
		},
		tableValueText: {
			fontSize: "14px",
			fontWeight: 500,
		},
	};

	return (
		<React.Fragment>
			<Stack
				padding={0.5}
				borderRadius={2}
				bgcolor={theme.palette.secondary.main}
			>
				<MenuSearchBar
					backgroundColor="#FFF"
					placeholder="Search Users, Teams, Territories"
				/>

				<TableContainer sx={{ minHeight: "160px" }}>
					<Table sx={{ width: "100%" }} size="small">
						<TableHead>
							<TableRow sx={{ height: "36px" }}>
								<TableCell style={{ width: "35%" }}>
									<TableHeaderLabel>Users</TableHeaderLabel>
								</TableCell>

								<TableCell style={{ width: "30%" }}>
									<TableHeaderLabel> Teams</TableHeaderLabel>
								</TableCell>

								<TableCell style={{ width: "35%" }}>
									<TableHeaderLabel>
										Territories
									</TableHeaderLabel>
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody
							sx={{
								"&:last-child td": {
									border: 0,
								},
							}}
						>
							<TableRow>
								<TableCell style={{ width: "35%" }}>
									<Stack
										direction="row"
										style={styles.tableData}
										width="135px"
									>
										<Typography
											style={styles.tableValueText}
										>
											Mattison James
										</Typography>

										{closeIcon(
											16,
											16,
											theme.palette.primary.main
										)}
									</Stack>
								</TableCell>

								<TableCell style={{ width: "30%" }} />

								<TableCell style={{ width: "35%" }} />
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
		</React.Fragment>
	);
};

export default function AddEmailOrManageUserDialog(props) {
	const { onClose, open, isManageUser, emailAddress, title } = props;

	const visibilityList = [
		{
			id: 1,
			name: "Just me",
		},
		{
			id: 2,
			name: "Selected",
		},
	];

	const [selectedVisibilityTypeName, setSelectedVisibilityTypeName] =
		useState(visibilityList[0].name);

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setSelectedVisibilityTypeName(type);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} title={title}>
			<Box p={2}>
				<Stack spacing={1.5}>
					<Stack>
						<Typography
							fontSize={14}
							fontWeight={500}
							padding="5px"
							sx={{ opacity: isManageUser ? 0.5 : null }}
						>
							Team Inbox Email Address
						</Typography>

						<TextField
							placeholder="Enter email address here"
							value={isManageUser ? emailAddress : ""}
							disabled={isManageUser}
						/>
					</Stack>

					<Box>
						<Typography
							fontSize={14}
							fontWeight={500}
							padding="5px"
						>
							Who can access this Team Inbox
						</Typography>

						<Stack direction="row" spacing={3}>
							<ToggleButtonGroup
								value={selectedVisibilityTypeName}
								onChange={handleToggleButtonGroup}
							>
								{visibilityList.map((visibility) => (
									<ToggleButton
										key={visibility.id}
										value={visibility.name}
									>
										{visibility.name}
									</ToggleButton>
								))}
							</ToggleButtonGroup>
						</Stack>
					</Box>

					{(function () {
						switch (selectedVisibilityTypeName) {
							case "Just me":
								return null;
							case "Selected":
								return <AccessList />;
							default:
								return null;
						}
					})()}

					<Box textAlign="right" p={1}>
						<Button
							variant="contained"
							disableElevation
							sx={{
								width: "112px",
							}}
							onClick={onClose}
						>
							Save
						</Button>
					</Box>
				</Stack>
			</Box>
		</Dialog>
	);
}

import React from "react";
import { Typography } from "@mui/material";

export default function ProductEnabled(props) {
	const { notificationContentStyle } = props;
	return (
		<React.Fragment>
			<Typography style={{ ...notificationContentStyle, opacity: "0.6" }}>
				Products are now accessible, check your left nav bar.
			</Typography>
		</React.Fragment>
	);
}

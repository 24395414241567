import React from "react";
import { Typography } from "@mui/material";

export default function AddedToTeam(props) {
	const { notificationData, notificationContentStyle } = props;

	if (!notificationData) {
		return null;
	}

	return (
		<React.Fragment>
			<Typography style={{ ...notificationContentStyle, opacity: "0.6" }}>
				{`You are added to the ${notificationData?.data?.team}.`}
			</Typography>
		</React.Fragment>
	);
}

import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	createDashboard,
	getDashboardListData,
	getChartApplyData,
	getChartTypeList,
	getChartViewData,
	getDashboard,
	getDuration,
	getMetrics,
	getXAxisMetrics,
	updateDashboard,
	getMetricsFilterField,
} from "../../../api/analytics/analyticsApi";
import {
	getAllDashboardListKey,
	getAnalyticsDashboardDataKey,
	getAnalyticsDurationKey,
	getAnalyticsKey,
	getAnalyticsMetricsKey,
	getAnalyticsXAxisMetricsKey,
	getChartViewDataKey,
	getDashboardListKey,
	getMetricsFilterFieldKey,
} from "../../../utils/queryKeys/analytics";
import { analyticsListSize } from "../../../utils/queryConstants/analytics";

const analyticsKey = getAnalyticsKey();

const useCreateDashboardMutation = () => {
	let invalidateDashboardList = useInvalidateDashboardList();
	return useMutation(createDashboard, {
		onSuccess: () => {
			invalidateDashboardList();
		},
	});
};

const useUpdateDashboardMutation = (dashboardData) => {
	let invalidateDashboardList = useInvalidateDashboardList();
	let invalidateDashboardData = useInvalidateDashboardData(
		dashboardData?.dashboardId
	);
	let invalidateChartViewData = useInvalidateChartViewData(
		dashboardData?.dashboardId
	);
	return useMutation(updateDashboard, {
		onSuccess: () => {
			invalidateDashboardList();
			invalidateDashboardData();
			invalidateChartViewData();
		},
	});
};

const selectReports = (reports) => {
	return reports.pages.flatMap((page) => page?.table?.rows || []);
};

const useGetDashboardList = (
	reportName,
	fetchHits = false,
	criteria = [],
	sort = []
) => {
	return useInfiniteQuery({
		queryKey: getDashboardListKey(reportName, fetchHits, criteria, sort),
		queryFn: ({ pageParam }) => {
			return getDashboardListData(reportName, fetchHits, criteria, sort, {
				start: pageParam ? (pageParam - 1) * analyticsListSize + 1 : 1,
				count: analyticsListSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.table.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: selectReports,
	});
};

const useGetDashboard = (
	dashboardId,
	fetchViewProperties = false,
	options = {}
) => {
	return useQuery(
		getAnalyticsDashboardDataKey(dashboardId, fetchViewProperties),
		() => getDashboard(dashboardId, fetchViewProperties),
		options
	);
};

const useGetChartType = () => {
	return useQuery(analyticsKey, () => getChartTypeList());
};

const useGetMetrics = (viewTypeId) => {
	return useQuery(getAnalyticsMetricsKey(viewTypeId), () =>
		getMetrics(viewTypeId)
	);
};

const useGetXAxisMetrics = (moduleIds, options = {}) => {
	return useQuery(
		getAnalyticsXAxisMetricsKey(moduleIds),
		() => getXAxisMetrics(moduleIds),
		options
	);
};

const useDuration = (moduleId, options = {}) => {
	return useQuery(
		getAnalyticsDurationKey(moduleId),
		() => getDuration(moduleId),
		options
	);
};

const useChartApplyData = () => {
	return useMutation(getChartApplyData);
};

const useChartViewData = (dashboardId, viewId) => {
	return useQuery(
		getChartViewDataKey(dashboardId, viewId),
		() => getChartViewData(dashboardId, viewId),
		{
			cacheTime: 2000,
		}
	);
};

export const useMetricsFilterField = (moduleId) => {
	return useQuery(
		getMetricsFilterFieldKey(moduleId),
		() => getMetricsFilterField(moduleId),
		{
			enabled: !!moduleId,
		}
	);
};

export const useMetricsFilter = () => {
	return useMutation(getMetricsFilterField);
};

// Query Invalidation call:-

const useInvalidateDashboardList = () => {
	let queryClient = useQueryClient();
	let dashBoardListKey = getAllDashboardListKey();

	return () => {
		queryClient.invalidateQueries(dashBoardListKey);
	};
};

const useInvalidateDashboardData = (dashboardId) => {
	let queryClient = useQueryClient();
	let analyticsDashboardDataKey = getAnalyticsDashboardDataKey(dashboardId);

	return () => {
		queryClient.invalidateQueries(analyticsDashboardDataKey);
	};
};

const useInvalidateChartViewData = (dashboardId) => {
	let queryClient = useQueryClient();
	let chartViewDataKey = getChartViewDataKey(dashboardId);

	return () => {
		queryClient.invalidateQueries(chartViewDataKey);
	};
};

export {
	useCreateDashboardMutation,
	useUpdateDashboardMutation,
	useGetDashboard,
	useGetChartType,
	useGetMetrics,
	useGetXAxisMetrics,
	useDuration,
	useChartApplyData,
	useGetDashboardList,
	useChartViewData,
};

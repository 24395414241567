import { useEffect, useState } from "react";
import { useStepOptions } from "../../../../../hooks/services/workflow";
import MultiSelectBaseComponent from "../MultiSelectComponent/MultiSelectBaseComponent";
import { useUsers } from "../../../../../hooks/account/user";

export default function OwnersComponent(params) {
	const {
		field = {},
		error,
		metadata,
		updateFieldValue,
		style = {},
		value,
		clearError,
		isConditionField,
	} = params;
	const [selectedValues, setSelectedValues] = useState([]);

	const { data: stepOptions } = useStepOptions(metadata, field);

	useEffect(() => {
		if (value && Array.isArray(value) && value.length > 0) {
			setSelectedValues(value);
		}
	}, [value]);

	const { activeUserList } = useUsers();

	const formattedOptions = activeUserList?.map((option) => ({
		value: {
			name: option.name,
			id: option.id || option.value,
		},
	}));

	const handleFieldChange = (values) => {
		setSelectedValues(values);
		updateFieldValue(values, field.fieldId);
		clearError(field.fieldId);
	};

	return (
		<MultiSelectBaseComponent
			style={style}
			error={error}
			isConditionField={isConditionField}
			options={formattedOptions || []}
			stepOptions={stepOptions || []}
			selectedValues={selectedValues}
			onFieldChange={handleFieldChange}
			hasStepOptions={field?.config?.hasStepOptions}
		/>
	);
}

import React, { useState } from "react";
import { MenuItem, Typography, useTheme } from "@mui/material";
import Menu from "../../Menu";
import { useNavigate } from "react-router-dom";

export default function ProductAssigned(props) {
	const {
		notificationData,
		onClose,
		isNotificationActionEnabled = true,
	} = props;

	const theme = useTheme();
	const navigate = useNavigate();
	const [productMenuElement, setProductMenuElement] = useState(null);
	const isProductMenuOpened = Boolean(productMenuElement);

	const openProductMenu = (event) => {
		event?.stopPropagation();
		if (isNotificationActionEnabled) {
			setProductMenuElement(event.currentTarget);
		}
	};

	const closeProductMenu = (event) => {
		event?.stopPropagation();
		setProductMenuElement(null);
	};

	const navigateToProduct = (event, productId) => {
		event?.stopPropagation();
		if (productId) {
			navigate(`/products/${productId?.toString()}`);
			setProductMenuElement(null);
			onClose();
		}
	};

	if (!notificationData) {
		return null;
	}

	return (
		<React.Fragment>
			<Menu
				minWidth="250px"
				anchorEl={productMenuElement}
				open={isProductMenuOpened}
				onClose={closeProductMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{notificationData?.data?.products?.map((product) => (
					<MenuItem
						key={product?.id}
						style={{ height: "40px" }}
						onClick={(event) =>
							navigateToProduct(event, product?.id)
						}
					>
						<Typography
							fontSize={14}
							color={theme.palette.secondary.contrastText}
						>
							{product?.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Typography
				component="span"
				fontSize={14}
				color={theme.palette.secondary.contrastText}
				onClick={(event) =>
					navigateToProduct(
						event,
						notificationData?.data?.products?.[0]?.id
					)
				}
			>
				{notificationData?.data?.products?.[0]?.name}
			</Typography>

			{notificationData?.data?.products?.length > 1 ? (
				<Typography
					component="span"
					fontSize={14}
					onClick={openProductMenu}
					color={theme.palette.secondary.contrastText}
				>
					&nbsp;
					{`+${notificationData.data.products.length - 1}`}
				</Typography>
			) : null}
		</React.Fragment>
	);
}

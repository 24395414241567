import React, { useEffect } from "react";
import PermissionList from "../PermisionList";
import { usePermissionContext } from "../../../PermissionContext";
import { permissionTypes } from "../../../../../../../utils/PermissionUtils";

export default function Followers(props) {
	const { permissions, isEditable } = props;

	const {
		permissionState,
		updateFollowersList,
		initializeFollowersListForPermission,
	} = usePermissionContext();

	useEffect(() => {
		if (permissions) {
			initializeFollowersListForPermission(permissions);
		}
	}, [permissions, initializeFollowersListForPermission]);

	const updateFollowersPermission = (
		accessPredicate,
		valuePredicate,
		disabledPredicate
	) => {
		return permissionState.followersList?.map((followerPermission) => {
			return {
				...followerPermission,
				access: accessPredicate(followerPermission),
				permissions: {
					...followerPermission.permissions,
					permission: followerPermission.permissions.permission.map(
						(permission) => {
							return {
								...permission,
								value: valuePredicate(
									followerPermission,
									permission
								),
								disabled: disabledPredicate(
									followerPermission,
									permission
								),
							};
						}
					),
				},
			};
		});
	};

	const handleAccessChange = (event, id) => {
		const accessPredicate = (followers) => {
			if (followers.id === id) {
				return event.target.checked;
			}
			return followers.access;
		};

		const valuePredicate = (followers, permission) => {
			if (followers.id === id) {
				return event.target.checked;
			}
			return permission.value;
		};

		const disablePredicate = (followers, permission) => {
			if (followers.id === id) {
				return !event.target.checked;
			}
			return permission.disabled;
		};

		const updatedFollowersList = updateFollowersPermission(
			accessPredicate,
			valuePredicate,
			disablePredicate
		);
		updateFollowersList(updatedFollowersList);
	};

	const onChange = (event, selectedPermission, id) => {
		const accessPredicate = (followers) => {
			if (followers.id === id) {
				if (selectedPermission.id === permissionTypes.view) {
					return event.target.checked;
				}
				return followers.access;
			}
			return followers.access;
		};

		const valuePredicate = (followers, permission) => {
			if (followers.id === id) {
				if (selectedPermission.id === permissionTypes.view) {
					return event.target.checked;
				} else if (permission.id === selectedPermission.id) {
					return event.target.checked;
				}
				return permission.value;
			}
			return permission.value;
		};

		const disablePredicate = (followers, permission) => {
			if (followers.id === id) {
				if (selectedPermission.id === permissionTypes.view) {
					return !event.target.checked;
				}
				return permission.disabled;
			}
			return permission.disabled;
		};

		const updatedFollowersList = updateFollowersPermission(
			accessPredicate,
			valuePredicate,
			disablePredicate
		);
		updateFollowersList(updatedFollowersList);
	};

	return (
		<React.Fragment>
			<PermissionList
				isViewName="Followers"
				lists={permissionState.followersList}
				isEditable={isEditable}
				onChange={onChange}
				handleAccessChange={handleAccessChange}
			/>
		</React.Fragment>
	);
}

import {
	Box,
	Divider,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Tooltip, twozoStyles } from "../../../../../styles/twozo";
import { useNavigate, useParams } from "react-router-dom";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDown";
import ProfileImage from "../../../../../assets/images/contact/unknownContact.png";
import ToggleButtonGroup from "../../../../Elements/ToggleButtonGroup";
import Activities from "../../../../Summary/Activities";
import {
	getHourAndMinuteFormat,
	getMonthAndDateFormat,
} from "../../../../../utils/DateUtils";
import AuditLog from "./AuditLog";
import {
	useUserCompletedActivties,
	useUserSummaryData,
	useUserToDoActivities,
} from "../../../../../hooks/services/userManagement/user";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

const styles = {
	summaryFieldText: {
		fontSize: 15,
	},
};

const DetailsCard = (props) => {
	const {
		field1,
		field2,
		value1,
		value2,
		defaultValue1,
		defaultValue2,
		countColor,
		fontColor,
		backgroundColour,
	} = props;

	const toolTipList = (values) =>
		values
			.slice(1)
			.map((value) => value.name)
			.join(", ");

	return (
		<Box minWidth="333px">
			<table>
				<tbody>
					<tr>
						<td>
							<Typography style={styles.summaryFieldText} pr={2}>
								{field1}:
							</Typography>
						</td>
						<td>
							<Typography
								fontSize={15}
								fontWeight={500}
								color={value1 ? "#000" : fontColor}
								style={{
									opacity: value1 ? 1 : 0.4,
								}}
							>
								{value1 ?? defaultValue1}
							</Typography>
						</td>
					</tr>
					<tr>
						<td>
							<Typography style={styles.summaryFieldText} pr={2}>
								{field2}:
							</Typography>
						</td>
						<td>
							{(
								Array.isArray(value2)
									? value2.length > 0
									: value2
							) ? (
								Array.isArray(value2) && value2.length > 0 ? (
									<Stack direction="row">
										<Typography
											fontSize={14}
											fontWeight={500}
											px={0.4}
											borderRadius="6px"
											style={{
												backgroundColor:
													backgroundColour,
											}}
										>
											{value2[0].name}
										</Typography>

										{value2.length > 1 && (
											<Tooltip
												title={toolTipList(value2)}
												placement="top"
											>
												<Typography
													fontSize={15}
													fontWeight={500}
													color={countColor}
													style={{
														cursor: "pointer",
													}}
												>
													&nbsp; +{value2.length - 1}
												</Typography>
											</Tooltip>
										)}
									</Stack>
								) : (
									<Typography
										style={styles.summaryFieldText}
										fontWeight={500}
									>
										{value2}
									</Typography>
								)
							) : (
								<Typography
									fontSize={15}
									fontWeight={500}
									color={fontColor}
									style={{ opacity: 0.4 }}
								>
									{defaultValue2}
								</Typography>
							)}
						</td>
					</tr>
				</tbody>
			</table>
		</Box>
	);
};

export default function UserSummary() {
	let { userId } = useParams();
	const parsedUserId = parseInt(userId);
	const classes = twozoStyles();
	const navigate = useNavigate();
	const theme = useTheme();
	const [view, setView] = useState("timeline");
	const { data: userData } = useUserSummaryData(parsedUserId);
	const summaryToggleButtonRef = useRef(null);
	const [summaryToggleButtonPosition, setSummaryToggleButtonPosition] =
		useState(0);

	const handleViewToggleButtonGroup = (_, type) => {
		if (type) {
			setView(type);
		}
	};

	const {
		data: todoActivities,
		isLoading: isLoadingTodoActivities,
		isFetching: isTodoActivitiesFetching,
		hasNextPage: hasNextTodoPage,
		fetchNextPage: fetchNextTodoPage,
	} = useUserToDoActivities(parsedUserId);
	const {
		data: completedActivities,
		isLoading: isLoadingCompletedActivities,
		isFetching: isCompletedActivitiesFetching,
		hasNextPage: hasNextCompletedActivitiesPage,
		fetchNextPage: fetchNextCompletedActivitiesPage,
	} = useUserCompletedActivties(parsedUserId);

	useEffect(() => {
		if (summaryToggleButtonRef) {
			setSummaryToggleButtonPosition(
				summaryToggleButtonRef.current.getBoundingClientRect().top
			);
		}
	}, [summaryToggleButtonRef]);

	const todoActivitiesLastRowRef = useLastRowRef(
		fetchNextTodoPage,
		hasNextTodoPage,
		isTodoActivitiesFetching
	);
	const completedActivitiesLastRowRef = useLastRowRef(
		fetchNextCompletedActivitiesPage,
		hasNextCompletedActivitiesPage,
		isCompletedActivitiesFetching
	);

	return (
		<React.Fragment>
			<Box>
				<Box className={classes.menuBar}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						height="100%"
					>
						<Stack
							direction="row"
							spacing={1.5}
							alignItems="center"
						>
							<Box
								style={{ cursor: "pointer" }}
								onClick={() =>
									navigate(
										"/settings/user-management?type=users"
									)
								}
							>
								<Typography
									fontWeight={600}
									fontSize={15}
									color={theme.palette.secondary.contrastText}
								>
									All Users
								</Typography>
							</Box>

							<Box
								display="flex"
								style={{
									transform: "rotate(-90deg)",
								}}
							>
								{DropDownIcon(16, 16)}
							</Box>

							<Typography fontWeight={500} fontSize={15}>
								{userData?.profile?.name}
							</Typography>
						</Stack>
					</Stack>
				</Box>

				<Box px={2}>
					<Stack direction="row" alignItems="center" spacing={3}>
						<img
							src={ProfileImage}
							alt="profile"
							width="64px"
							height="64px"
						/>

						<Stack>
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
								style={{ cursor: "pointer" }}
							>
								<Typography fontSize={17} fontWeight={600}>
									{userData?.profile.name}
								</Typography>
							</Stack>

							<Stack
								direction="row"
								spacing={1}
								style={{ cursor: "pointer" }}
							>
								{userData?.profile?.jobTitle ? (
									<Typography
										fontSize={14}
										fontWeight={400}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{userData?.profile.jobTitle}
									</Typography>
								) : (
									<Typography
										fontSize={14}
										fontWeight={500}
										color={
											theme.palette.secondary.contrastText
										}
										style={{ opacity: "0.8" }}
									>
										Add Job Title
									</Typography>
								)}
							</Stack>
						</Stack>
					</Stack>
				</Box>

				<Stack direction="row" spacing={2} px={3} py={2}>
					<DetailsCard
						field1="Email"
						value1={userData?.summary?.email}
						field2="Phone"
						value2={userData?.summary?.phone}
						defaultValue2="Add Contact No"
						fontColor={theme.palette.secondary.contrastText}
						countColor={theme.palette.primary.main}
						backgroundColour={theme.palette.secondary.main}
					/>
					<Divider orientation="vertical" flexItem />

					<DetailsCard
						field1="Pipeline"
						value1={userData?.summary?.pipeline.name}
						field2="Territories"
						value2={userData?.summary?.territories}
						defaultValue2="Select"
						fontColor={theme.palette.secondary.contrastText}
						countColor={theme.palette.primary.main}
						backgroundColour={theme.palette.secondary.main}
					/>
					<Divider orientation="vertical" flexItem />

					<DetailsCard
						field1="Reporting Manager"
						value1={userData?.summary?.reportingManager?.name}
						defaultValue1="Select"
						field2="Teams"
						value2={userData?.summary?.teams}
						defaultValue2="Select"
						fontColor={theme.palette.secondary.contrastText}
						countColor={theme.palette.primary.main}
						backgroundColour={theme.palette.secondary.main}
					/>
					<Divider orientation="vertical" flexItem />

					<DetailsCard
						field1="Last login"
						value1={
							userData?.summary?.lastLoginTime
								? getMonthAndDateFormat(
										userData?.summary?.lastLoginTime
									) +
									" " +
									getHourAndMinuteFormat(
										userData?.summary?.lastLoginTime
									)
								: "-"
						}
						field2="Role"
						value2={userData?.summary?.role.name}
						fontColor={theme.palette.secondary.contrastText}
						countColor={theme.palette.primary.main}
						backgroundColour={theme.palette.secondary.main}
					/>
				</Stack>

				<Box px={2}>
					<Divider />
				</Box>

				<Box p={1}>
					<Box className={classes.detailsContainer}>
						<Box>
							<ToggleButtonGroup
								value={view}
								onChange={handleViewToggleButtonGroup}
							>
								<ToggleButton value="timeline">
									Audit log
								</ToggleButton>

								<ToggleButton value="activities">
									Activities
								</ToggleButton>
							</ToggleButtonGroup>
						</Box>

						<Box
							minHeight={`calc(100vh - ${
								summaryToggleButtonPosition + 20
							}px)`}
							ref={summaryToggleButtonRef}
						>
							{(function () {
								switch (view) {
									case "timeline":
										return (
											<AuditLog userId={parsedUserId} />
										);
									case "activities":
										return (
											<Activities
												isLoadingTodoActivities={
													isLoadingTodoActivities
												}
												isLoadingCompletedActivities={
													isLoadingCompletedActivities
												}
												todoActivities={todoActivities}
												completedActivities={
													completedActivities
												}
												todoActivitiesLastRowRef={
													todoActivitiesLastRowRef
												}
												completedActivitiesLastRowRef={
													completedActivitiesLastRowRef
												}
												isFetchingMoreTodoActivitiesData={
													!isLoadingTodoActivities &&
													isTodoActivitiesFetching
												}
												isFetchingMoreCompletedActivitiesData={
													!isLoadingCompletedActivities &&
													isCompletedActivitiesFetching
												}
											/>
										);
									default:
										return null;
								}
							})()}
						</Box>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}

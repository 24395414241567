import React from "react";
import { AppBar, Box, Stack, Toolbar } from "@mui/material";
import Search from "../Search";
import GlobalAdd from "./GlobalAdd";
import NotificationBar from "./NotificationBar";

export default function TopBar(props) {
	const { drawerWidth } = props;

	return (
		<React.Fragment>
			<AppBar
				position="fixed"
				style={{
					backgroundColor: "#FFFFFF",
					boxShadow: "None",
					color: "#000",
					width: `calc(100% - ${drawerWidth}px)`,
				}}
			>
				<Toolbar
					style={{
						padding: "5px 8px",
						minHeight: "54px",
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						width="100%"
					>
						<Stack direction="row" spacing={2}>
							<Search />

							<Box>
								<GlobalAdd />
							</Box>
						</Stack>

						<Stack direction="row">
							<NotificationBar />
						</Stack>
					</Stack>
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
}

import React from "react";
import { Stack } from "@mui/material";
import Field from "./Field";
import SubField from "./SubField";
import Comparator from "./Comparator";
import SubfieldValueInput from "./SubfieldValueInput";
import ValueField from "./ValueField";

export default function SingleCriteria(props) {
	const { filterCondition, onChange } = props;

	return (
		<React.Fragment>
			<Field filterCondition={filterCondition} onChange={onChange} />

			{(filterCondition.fieldId.value?.name ||
				filterCondition.optionId.value?.name) && (
				<Stack>
					{filterCondition.fieldData.additionalOptionsDropdown && (
						<SubField
							filterCondition={filterCondition}
							onChange={onChange}
						/>
					)}

					<Comparator
						filterCondition={filterCondition}
						onChange={onChange}
					/>

					{filterCondition.comparatorId.value.name === "is empty" ||
					filterCondition.comparatorId.value.name ===
						"is not empty" ? null : (
						<>
							{filterCondition?.additionalOption.displayName ? (
								<SubfieldValueInput
									filterCondition={filterCondition}
									onChange={onChange}
								/>
							) : (
								<ValueField
									filterCondition={filterCondition}
									onChange={onChange}
								/>
							)}
						</>
					)}
				</Stack>
			)}
		</React.Fragment>
	);
}

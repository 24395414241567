import { Controller, useForm } from "react-hook-form";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { Typography, useTheme } from "@mui/material";
import {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useEffect,
	useState,
} from "react";
import DateTimeRangePicker from "../../DateTimeRangePicker";

const toTextFieldSx = {
	dateSx: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				border: "none",
			},
			"&:hover fieldset": {
				border: "none",
			},
			"&.Mui-focused fieldset": {
				border: "none",
			},
			padding: "0px",
		},
		width: "130px",
	},
	timeSx: {
		border: "none",
		width: "auto",
		padding: "0px 8px 0px 0px",
	},
};

const fromTextFieldSx = {
	dateSx: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				border: "none",
			},
			"&:hover fieldset": {
				border: "none",
			},
			"&.Mui-focused fieldset": {
				border: "none",
			},
			padding: "0px 0px 0px 12px",
		},
		width: "130px",
	},
	timeSx: {
		border: "none",
		width: "auto",
		padding: "0px",
	},
};

const DateTimeRangeFieldEditForm = forwardRef(
	({ value, field, onError, onSave, onClose, subFields }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		const {
			formState: { errors },
			control,
			handleSubmit,
			clearErrors,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });

		const getSubFieldObject = (subFieldName) => {
			let subFieldObj;
			if (!!subFields && Array.isArray(subFields)) {
				subFields.forEach((field) => {
					if (field.name === subFieldName) {
						subFieldObj = field;
					}
				});
			}

			return subFieldObj;
		};

		const fromField = getSubFieldObject("FROM");
		const toField = getSubFieldObject("TO");

		const [dateTimeRangeValue, setDateTimeRangeValue] = useState({});

		useEffect(() => {
			if (value) {
				let dateTimeRangeValueObj = {};
				dateTimeRangeValueObj.from = value[fromField.id];
				dateTimeRangeValueObj.to = value[toField.id];
				setDateTimeRangeValue(dateTimeRangeValueObj);
			}
		}, [value, fromField, toField]);

		const handleDateTimeRangeValueChange = (
			newDateTimeRangeValue,
			onChange
		) => {
			setDateTimeRangeValue({ ...newDateTimeRangeValue });
			let dateTimeRangeValueObjectWithId = {};
			dateTimeRangeValueObjectWithId[fromField.id] =
				newDateTimeRangeValue.from;
			dateTimeRangeValueObjectWithId[toField.id] =
				newDateTimeRangeValue.to;

			let getDateTimeRangeValue = () => {
				if (
					dateTimeRangeValueObjectWithId[fromField.id] &&
					dateTimeRangeValueObjectWithId[toField.id]
				) {
					return dateTimeRangeValueObjectWithId;
				}

				return "";
			};

			onChange(getDateTimeRangeValue());
			clearErrors(fieldId + "");
			onError(null);
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		return (
			<>
				<Controller
					name={fieldId + ""}
					control={control}
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, ...fieldData } }) => (
						<DateTimeRangePicker
							{...fieldData}
							value={dateTimeRangeValue}
							fromTextFieldSx={fromTextFieldSx}
							toTextFieldSx={toTextFieldSx}
							onChange={(data) =>
								handleDateTimeRangeValueChange(data, onChange)
							}
						/>
					)}
					defaultValue=""
				></Controller>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

DateTimeRangeFieldEditForm.displayName = "DateTimeRangeFieldEditForm";

export default DateTimeRangeFieldEditForm;

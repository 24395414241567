import React, { useState } from "react";
import { MenuItem, Select, Typography, useTheme } from "@mui/material";
import { useWebFormDropdownList } from "../../../../../../../../../hooks/services/webform";
import { MenuItemText } from "../../../../../../../../../styles/twozo";

export default function DropdownComponent(props) {
	const { formProperty, formStyle } = props;
	const theme = useTheme();
	const [selectedValue, setSelectedValue] = useState("");

	const { data: options } = useWebFormDropdownList(formProperty.fieldId);

	const handleChangeOptions = (event) => {
		const { value } = event.target;
		setSelectedValue(value);
	};

	return (
		<React.Fragment>
			<Typography
				pl={1}
				fontSize={14}
				fontWeight={500}
				style={formStyle.labelColor}
			>
				{formProperty.label}
				{formProperty.config.isRequired && (
					<span style={{ color: theme.palette.error.main }}> *</span>
				)}
			</Typography>

			<Select
				size="small"
				sx={formStyle.lableInput}
				onChange={handleChangeOptions}
				value={selectedValue}
			>
				{options?.map((option) => (
					<MenuItem
						key={option.id}
						value={option.option}
						sx={{ justifyContent: formStyle.lableInput.textAlign }}
					>
						<MenuItemText>{option.option}</MenuItemText>
					</MenuItem>
				))}
			</Select>
		</React.Fragment>
	);
}

import React, { useEffect, useMemo, useRef, useState } from "react";
import {
	Box,
	Checkbox,
	CircularProgress,
	IconButton,
	MenuItem,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import {
	MenuItemText,
	TableCellText,
	TableHeaderLabel,
	twozoStyles,
} from "../../../../styles/twozo";
import { default as UnKnownImage } from "../../../../assets/images/contact/unknownContact.png";
import { default as DropDownIcon } from "../../../../assets/icons/dropDown";
import { default as MinusIcon } from "../../../../assets/icons/minus";
import { default as RightArrowIcon } from "../../../../assets/icons/rightArrow";
import { default as MoreIcon } from "../../../../assets/icons/more";
import { default as EditIcon } from "../../../../assets/icons/edit";
import { default as EmailIcon } from "../../../../assets/icons/mail";
import { default as ReinviteIcon } from "../../../../assets/icons/reload";
import { useNavigate } from "react-router-dom";
import Menu from "../../../Elements/Menu";
import {
	getHourAndMinuteFormat,
	getMonthAndDateFormat,
} from "../../../../utils/DateUtils";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import EditUser from "./EditUser";
import { useUserManagementContext } from "../UserManagementContext";
import EmailComposerDrawer from "../../../Mail/EmailComposer/EmailComposerDrawer";
import DeactivateDialog from "./DeactivateDialog";
import { useUserReinviteMutation } from "../../../../hooks/services/userManagement/user";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import TransferRecordDialog from "./TransferRecordDialog";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";

export default function Users(props) {
	const { selected, setSelected, updateUserTableData, updateUsersCount } =
		props;

	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();

	const [userMenuElement, setUserMenuElement] = useState(null);
	const isUserMenuOpened = Boolean(userMenuElement);
	const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
	const [isTransferDialogOpen, setIsTransferDialogOpen] = useState(false);
	const [tableRowHovered, setTableRowHovered] = useState(null);
	const [tableRowElementId, setTableRowElementId] = useState(null);
	const [isEditUserDialogOpened, setIsEditUserDialogOpened] = useState(false);
	const [selectedUser, setSelectedUser] = useState({});
	const [userMenuConfig, setUserMenuConfig] = useState({});
	const [isEmailComposerDrawerOpened, setIsEmailComposerDrawerOpened] =
		useState(false);
	const userReinviteMutation = useUserReinviteMutation();
	const tableRef = useRef();
	const [tableStartingPosition, setTableStartingPosition] = useState(0);

	useEffect(() => {
		if (tableRef) {
			setTableStartingPosition(
				tableRef.current.getBoundingClientRect().top
			);
		}
	}, []);

	const {
		listData: users,
		isLoadingListData: isLoadingUsers,
		listDataStatus: userStatus,
		handleSort: handleSort,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useUserManagementContext();

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const hasUserData =
		users && users?.pages?.length > 0 && users?.pages[0]?.totalRecords > 0;

	useEffect(() => {
		if (userStatus === "success") {
			if (users && users?.pages?.length > 0) {
				updateUsersCount(
					users?.pages[0]?.totalRecords,
					users?.pages[0]?.totalAccountAdmin
				);
			}
		}
	}, [users, userStatus, updateUsersCount]);

	const userList = useMemo(() => {
		let userList = [];
		if (users && users.pages) {
			for (
				let pageCount = 0;
				pageCount < users.pages.length;
				pageCount++
			) {
				if (users.pages[pageCount].users) {
					userList.push(...users.pages[pageCount].users);
				}
			}
		}
		return userList;
	}, [users]);

	// selected menu titles
	const transferDialogTitle = `Are you sure you want to transfer the all the records from the user ${selectedUser.name} to?`;
	const deactivateTitle = `Are you sure you want to deactivate the user ${selectedUser.name} and transfer record to?`;

	const styles = {
		menuItem: {
			fontWeight: 500,
			color: theme.palette.primary.main,
		},
	};

	const sortTypes = {
		ascending: "asc",
		decending: "desc",
	};

	const [tableHeader, setTableHeader] = useState([
		{
			id: 1,
			displayName: "User",
			name: "name",
		},
		{
			id: 2,
			displayName: "Email",
			name: "email",
		},
		{
			id: 3,
			displayName: "Status",
			name: "state",
		},
		{
			id: 4,
			displayName: "Role",
			name: "role",
		},
		{
			id: 5,
			displayName: "Reporting Manager",
			name: "reportingManager",
		},
		{
			id: 6,
			displayName: "Last login",
			name: "lastLogin",
		},
		{
			id: 7,
			displayName: "",
		},
	]);

	const isActionColumn = (index, tableHeader) => {
		return index === tableHeader.length - 1;
	};

	const toggleSort = (sortIndex) => {
		let sortRequest = {};

		if (tableHeader[sortIndex].sort === sortTypes.ascending) {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.decending,
			};
		} else {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.ascending,
			};
		}

		handleSort(sortRequest);

		setTableHeader((headers) => {
			const updatedHeaders = [...headers];

			updatedHeaders.forEach((column, index) => {
				if (index === sortIndex) {
					column.sort =
						column.sort === sortTypes.ascending
							? sortTypes.decending
							: sortTypes.ascending;
				} else {
					column.sort = sortTypes.decending;
				}
			});

			return updatedHeaders;
		});
	};

	const onMouseOverTableRow = (id) => setTableRowHovered(id);

	const onMouseOutTableRow = () => setTableRowHovered(null);

	const openUserMenu = (event, user) => {
		event.stopPropagation();
		setUserMenuConfig(user.config);
		setUserMenuElement(event.currentTarget);
		setTableRowElementId(user.id);
		setSelectedUser(user);
	};

	const isSelected = (user) =>
		selected.some((selectedUser) => selectedUser.id === user.id);

	const handleCheckboxClick = (event, user) => {
		event.stopPropagation();
		const selectedIndex = selected.findIndex(
			(selectedUser) => selectedUser.id === user.id
		);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = [...selected, user];
		} else {
			newSelected = [
				...selected.slice(0, selectedIndex),
				...selected.slice(selectedIndex + 1),
			];
		}
		setSelected(newSelected);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = [...userList];
			setSelected(newSelected);
		} else {
			setSelected([]);
		}
	};

	useEffect(() => {
		updateUserTableData(userList);
	}, [userList, updateUserTableData]);

	const closeUserMenu = () => {
		setUserMenuElement(null);
		setTableRowElementId(null);
	};

	const openDeactivateMenu = () => {
		setIsDeactivateDialogOpen(true);
		setUserMenuElement(null);
		setTableRowElementId(null);
	};

	const closeDeactivateMenu = () => {
		setIsDeactivateDialogOpen(false);
	};

	const openTransferMenu = () => {
		setIsTransferDialogOpen(true);
		setUserMenuElement(null);
		setTableRowElementId(null);
	};

	const closeTransferMenu = () => {
		setIsTransferDialogOpen(false);
	};

	const openEditUserDialog = () => {
		setIsEditUserDialogOpened(true);
		setTableRowElementId(null);
		setUserMenuElement(null);
	};

	const closeEditUserDialog = () => {
		setIsEditUserDialogOpened(false);
		setTableRowElementId(null);
	};

	const openEmailComposerDrawer = () => {
		setIsEmailComposerDrawerOpened(true);
		closeUserMenu();
	};

	const closeEmailComposerDrawer = () => {
		setIsEmailComposerDrawerOpened(false);
	};

	const reinviteUser = (selectedUser) => {
		userReinviteMutation.mutate(
			{
				userId: selectedUser.id,
				email: selectedUser.email,
			},
			{
				onSuccess: (success) => {
					const successMessage = success.message;
					enqueueSnackbar({
						variant: notificationVariants.success,
						message:
							successMessage ?? notificationMessage.userReinvited,
					});
					closeUserMenu();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	const userMenuOptions = [
		{
			id: 1,
			name: "Email",
			icon: EmailIcon,
			action: openEmailComposerDrawer,
			isVisible: true,
		},
		{
			id: 2,
			name: "Edit",
			icon: EditIcon,
			action: openEditUserDialog,
			isVisible: true,
		},
		{
			id: 3,
			name: "Deactivate",
			icon: MinusIcon,
			action: openDeactivateMenu,
			isVisible: userMenuConfig.isDeactivatable,
		},
		{
			id: 4,
			name: "Transfer",
			icon: RightArrowIcon,
			action: openTransferMenu,
			isVisible: userMenuConfig.isTransferable,
		},
		{
			id: 5,
			name: "Re-Invite",
			icon: ReinviteIcon,
			action: () => reinviteUser(selectedUser),
			isVisible: userMenuConfig.canReInvite,
		},
	];

	return (
		<React.Fragment>
			{isEmailComposerDrawerOpened ? (
				<EmailComposerDrawer
					onClose={closeEmailComposerDrawer}
					composerValues={{
						toAddress: [{ email: selectedUser?.email }],
					}}
				/>
			) : null}

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditUserDialogOpened}
				onOpen={openEditUserDialog}
				onClose={closeEditUserDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditUser
						onClose={closeEditUserDialog}
						userId={selectedUser.id}
						isEditable={userMenuConfig.isEditable}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				minWidth="200px"
				anchorEl={userMenuElement}
				open={isUserMenuOpened}
				onClose={closeUserMenu}
			>
				{userMenuOptions.map(
					(menu) =>
						menu.isVisible && (
							<MenuItem
								key={menu.id}
								style={{ height: "40px" }}
								onClick={menu.action}
							>
								<Stack direction="row" spacing={2}>
									<Box display="flex">
										{menu.icon(
											20,
											20,
											theme.palette.primary.main
										)}
									</Box>

									<MenuItemText style={styles.menuItem}>
										{menu.name}
									</MenuItemText>
								</Stack>
							</MenuItem>
						)
				)}
			</Menu>

			<DeactivateDialog
				open={isDeactivateDialogOpen}
				onClose={closeDeactivateMenu}
				title={deactivateTitle}
				userData={selectedUser}
			/>

			<TransferRecordDialog
				open={isTransferDialogOpen}
				onClose={closeTransferMenu}
				title={transferDialogTitle}
				sourceUserDataForTransfer={selectedUser}
			/>

			<Box pb={1.5} pl={2}>
				<Typography color="rgba(0, 0, 0, 0.6)" fontSize={14}>
					Add a manage users from here. You can assign roles, specify
					their permissions and assign teams.
				</Typography>
			</Box>

			<Box
				ref={tableRef}
				sx={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
					height: `calc(100vh - ${tableStartingPosition + 8}px)`,
					overflowY: "auto",
				}}
			>
				<TableContainer sx={{ maxHeight: "100%" }}>
					<Table stickyHeader sx={{ minWidth: 650 }} size="small">
						<TableHead>
							<TableRow sx={{ height: "36px" }}>
								<TableCell padding="checkbox" width="5%">
									<Checkbox
										indeterminate={
											selected.length > 0 &&
											selected.length < userList?.length
										}
										checked={
											userList?.length > 0 &&
											selected.length === userList?.length
										}
										onChange={handleSelectAllClick}
									/>
								</TableCell>

								{tableHeader.map((header, index) => (
									<TableCell
										sx={{ width: "16%" }}
										key={index}
										onClick={() => toggleSort(index)}
									>
										<Stack
											direction="row"
											alignItems="center"
											spacing={0.5}
										>
											<TableHeaderLabel>
												{header.displayName}
											</TableHeaderLabel>

											<Box display="flex">
												{!isActionColumn(
													index,
													tableHeader
												) ? (
													header.sort ===
													sortTypes.ascending ? (
														<Box
															display={"flex"}
															style={{
																transform:
																	"rotate(180deg)",
															}}
														>
															{DropDownIcon(
																12,
																12,
																"rgba(0, 0, 0, 0.6)"
															)}
														</Box>
													) : (
														<Box display={"flex"}>
															{DropDownIcon(
																12,
																12,
																"rgba(0, 0, 0, 0.6)"
															)}
														</Box>
													)
												) : (
													""
												)}
											</Box>
										</Stack>
									</TableCell>
								))}
							</TableRow>
						</TableHead>

						<TableBody>
							{isLoadingUsers ? (
								<TableRow
									style={{
										height: "60vh",
									}}
								>
									<TableCell
										colSpan={7}
										sx={{ borderBottom: 0 }}
									>
										<Stack
											height="100%"
											justifyContent="center"
											alignItems="center"
											width="100%"
										>
											<CircularProgress />
										</Stack>
									</TableCell>
								</TableRow>
							) : hasUserData ? (
								users?.pages?.map((page) =>
									page?.users?.map((user, index) => (
										<TableRow
											key={index}
											selected={isSelected(user)}
											onClick={() =>
												navigate(user.id.toString())
											}
											onMouseOver={() =>
												onMouseOverTableRow(user.id)
											}
											onMouseOut={() =>
												onMouseOutTableRow()
											}
										>
											<TableCell padding="checkbox">
												<Checkbox
													checked={isSelected(user)}
													onClick={(event) =>
														handleCheckboxClick(
															event,
															user
														)
													}
												/>
											</TableCell>

											<TableCell>
												<TableCellText>
													{user.name}
												</TableCellText>
											</TableCell>

											<TableCell>
												<TableCellText>
													{user.email}
												</TableCellText>
											</TableCell>

											<TableCell>
												<TableCellText>
													{user.state}
												</TableCellText>
											</TableCell>

											<TableCell>
												<TableCellText
													fontWeight={500}
													color={
														theme.palette.secondary
															.contrastText
													}
												>
													{user.role.name}
												</TableCellText>
											</TableCell>

											<TableCell>
												{user.reportingManager ? (
													<Stack
														direction="row"
														alignItems="center"
														spacing={1}
													>
														<img
															src={UnKnownImage}
															alt="managerProfile"
															width={26}
															height={26}
														/>

														<TableCellText>
															{
																user
																	.reportingManager
																	.name
															}
														</TableCellText>
													</Stack>
												) : (
													<TableCellText>
														-
													</TableCellText>
												)}
											</TableCell>

											<TableCell>
												<TableCellText>
													{user.lastLoginTime
														? getMonthAndDateFormat(
																user.lastLoginTime
															) +
															", " +
															getHourAndMinuteFormat(
																user.lastLoginTime
															)
														: "-"}
												</TableCellText>
											</TableCell>

											<TableCell
												style={{
													minWidth: "50px",
													paddingLeft: 0,
													paddingTop: 0,
													paddingBottom: 0,
													textAlign: "right",
												}}
											>
												<Box
													hidden={
														tableRowElementId !==
															user.id &&
														tableRowHovered !==
															user.id
													}
													style={{
														border: `1px solid ${theme.palette.primary.main}`,
														borderRadius: "8px",
														paddingTop: "2px",
														paddingBottom: "2px",
													}}
												>
													<Stack alignItems="center">
														<IconButton
															size="small"
															onClick={(event) =>
																openUserMenu(
																	event,
																	user
																)
															}
														>
															{MoreIcon(
																16,
																16,
																theme.palette
																	.primary
																	.main
															)}
														</IconButton>
													</Stack>
												</Box>
											</TableCell>
										</TableRow>
									))
								)
							) : (
								<TableRow>
									<TableCell
										colSpan={8}
										style={{ borderBottom: "none" }}
									>
										<Stack
											alignItems="center"
											justifyContent="center"
											style={{
												height: "60vh",
											}}
										>
											<Typography
												fontSize={14}
												fontWeight={400}
												color="rgba(0, 0, 0, 0.6)"
											>
												No results found
											</Typography>
										</Stack>
									</TableCell>
								</TableRow>
							)}
							<TableRow>
								<TableCell
									colSpan={8}
									sx={{ borderBottom: "none" }}
								>
									<Box ref={lastRowRef}>
										{!isLoadingUsers && isFetching && (
											<Stack
												direction="row"
												alignItems="center"
												justifyContent="center"
												spacing={1}
												py={2}
											>
												<CircularProgress size={18} />

												<Typography fontSize={12}>
													Loading More Data
												</Typography>
											</Stack>
										)}
									</Box>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</React.Fragment>
	);
}

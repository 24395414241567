import React, { useMemo, useState } from "react";
import {
	Box,
	CircularProgress,
	MenuItem,
	Stack,
	ToggleButton,
	useTheme,
} from "@mui/material";
import { default as AddIcon } from "../../../../../../.././assets/icons/add";
import { default as DropDownIcon } from "../../../../../../.././assets/icons/dropDownCentered";
import ImportFieldList from "../../../ImportCustomField/ImportFieldList";
import { useModuleList } from "../../../../../../.././hooks/services/common";
import {
	useFieldTypeList,
	useImportFieldsList,
	useImportModuleList,
} from "../../../../../../.././hooks/services/import";
import { getIconByName } from "../../../../../../.././utils/icon";
import ToggleButtonGroup from "../../../../../.././Elements/ToggleButtonGroup";
import MenuSearchBar from "../../../../../.././Elements/MenuSearchBar";
import { MenuItemText } from "../../../../../../../styles/twozo";

export default function FieldOption(props) {
	const { mappedFields, onClickMappedField, closeImportFieldsMenu } = props;
	const [sourceType, setSourceType] = useState("Contact");

	// query call
	const { data: fieldTypeData } = useFieldTypeList();
	const { data: moduleListData } = useModuleList();
	const { data: modulesList } = useImportModuleList();
	const { data: fieldsData, isLoading } = useImportFieldsList(sourceType);

	const theme = useTheme();
	const [isSubFieldOpen, setIsSubFieldOpen] = useState(false);
	const [selectedSubFieldIndex, setSelectedSubFieldIndex] = useState(null);
	const [newFieldsCreated, setNewFieldsCreated] = useState([]);
	const [searchInput, setSearchInput] = useState("");

	const getSourceId = () => {
		let module = moduleListData?.find(
			(module) =>
				module.sourceName.toLowerCase() === sourceType.toLowerCase()
		);
		if (module) {
			return module?.sourceId;
		}
	};

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setSourceType(type);
		}
		setSearchInput("");
		setNewFieldsCreated([]);
		setIsSubFieldOpen(false);
	};

	const handleSearchField = (event) => {
		const { value } = event.target;
		setSearchInput(value);
	};

	const isCustomFieldCreationEnabled = useMemo(() => {
		if (fieldsData?.isRegisteredSource) {
			return fieldsData.isRegisteredSource;
		}

		return false;
	}, [fieldsData]);

	const handleMappingFieldData = (field, isSubField, subField) => {
		let selectedFieldObject = {
			field: field,
			source: sourceType,
			subField: subField,
			isSubField: isSubField,
		};

		onClickMappedField(selectedFieldObject);
		closeImportFieldsMenu();
	};

	const isFieldCreationEnabled = () => {
		return newFieldsCreated.length > 0;
	};

	const handleCustomField = () => {
		if (isFieldCreationEnabled()) {
			return;
		}

		setNewFieldsCreated((newFieldsCreated) => [
			...newFieldsCreated,
			{
				sourceId: getSourceId(),
				fieldName: "",
				id: "new_field" + (newFieldsCreated.length + 1),
				type: `${fieldTypeData?.[0]?.name}`,
				isFieldNotSaved: true,
				config: {},
			},
		]);
	};

	const handleOpenSubField = () => {
		setIsSubFieldOpen((isSubFieldOpen) => !isSubFieldOpen);
	};

	const handleClickFieldOrSubField = (field, index) => {
		if (hasSubField(field)) {
			handleOpenSubField();
			setSelectedSubFieldIndex(index);
		} else {
			handleMappingFieldData(field);
		}
	};

	const updateNewFieldsCreated = (modifiedNewFieldsCreated) => {
		setNewFieldsCreated(modifiedNewFieldsCreated);
	};

	const isSelectedSubFieldOpened = (index) => {
		return isSubFieldOpen && selectedSubFieldIndex === index;
	};

	const hasSubField = (field) => {
		return !!fieldsData?.subFields?.[field?.type];
	};

	const selectedFieldAsMap = useMemo(() => {
		const selectedFieldsAsMap = {};

		mappedFields?.forEach((selectedField) => {
			if (!selectedFieldsAsMap[selectedField.source]) {
				selectedFieldsAsMap[selectedField.source] = {};
			}

			if (
				!selectedFieldsAsMap[selectedField.source][selectedField.field]
			) {
				selectedFieldsAsMap[selectedField.source][selectedField.field] =
					{
						selectedSubFields: {},
					};
			}

			if (selectedField.subType) {
				selectedFieldsAsMap[selectedField.source][
					selectedField.field
				].selectedSubFields[selectedField.subType] = true;
			}
		});

		return selectedFieldsAsMap;
	}, [mappedFields]);

	const isFieldSelected = (field, sourceType) => {
		const { id } = field;
		return !!selectedFieldAsMap?.[sourceType]?.[id];
	};

	const isSubFieldSelected = (field, subField, sourceType) => {
		const { id: fieldId } = field;
		const { id: subFieldId } = subField;
		return !!selectedFieldAsMap?.[sourceType]?.[fieldId]
			?.selectedSubFields?.[subFieldId];
	};

	const filteredFieldsData = useMemo(() => {
		if (!Array.isArray(fieldsData?.fields)) {
			return;
		}

		const filteredFields = [];

		fieldsData.fields.forEach((field) => {
			let filteredSubFields = [];

			if (hasSubField(field)) {
				if (!Array.isArray(fieldsData?.subFields[field.type])) {
					return;
				}

				fieldsData.subFields[field.type].forEach((subField) => {
					if (!isSubFieldSelected(field, subField, sourceType)) {
						filteredSubFields.push(subField);
					}
				});

				if (filteredSubFields.length === 0) {
					return;
				}
			} else if (isFieldSelected(field, sourceType)) {
				return;
			}

			let processedField = {
				...field,
				hasSubField: hasSubField(field),
				subFields: filteredSubFields,
			};

			filteredFields.push(processedField);
		});

		return filteredFields;
	}, [fieldsData, mappedFields, sourceType]);

	const filteredFieldsBySearch = useMemo(() => {
		if (!searchInput?.trim()) {
			return filteredFieldsData;
		}

		return filteredFieldsData?.filter((field) =>
			field?.name?.toLowerCase()?.startsWith(searchInput?.toLowerCase())
		);
	}, [filteredFieldsData, searchInput]);

	const isFilteredFieldsNotEmpty =
		Array.isArray(filteredFieldsBySearch) &&
		filteredFieldsBySearch.length > 0;

	return (
		<React.Fragment>
			<Box pt={1}>
				<Box style={{ textAlign: "center" }}>
					<ToggleButtonGroup
						value={sourceType}
						onChange={handleToggleButtonGroup}
					>
						{modulesList?.map(
							(module) =>
								module.isEnabled && (
									<ToggleButton
										value={module.sourceName}
										key={module.sourceId}
									>
										{getIconByName(
											module.sourceName.toLowerCase()
										)(
											20,
											20,
											sourceType === module.sourceName
												? "#fff"
												: "#666666"
										)}
									</ToggleButton>
								)
						)}
					</ToggleButtonGroup>
				</Box>

				<Box p={0.5} pt={1}>
					<MenuSearchBar
						value={searchInput}
						onChange={handleSearchField}
					/>
				</Box>
			</Box>

			{isCustomFieldCreationEnabled && isFilteredFieldsNotEmpty && (
				<MenuItem
					style={{
						minHeight: "40px",
						paddingLeft: "24px",
						opacity: isFieldCreationEnabled() > 0 && 0.6,
					}}
					onClick={handleCustomField}
				>
					{AddIcon(20, 20, theme.palette.secondary.contrastText)}

					<MenuItemText
						pl={1}
						color={theme.palette.secondary.contrastText}
					>
						Custom Field
					</MenuItemText>
				</MenuItem>
			)}

			<Box sx={{ maxHeight: "190px", overflowY: "auto" }}>
				{!!newFieldsCreated && newFieldsCreated.length > 0 && (
					<ImportFieldList
						sourceType={sourceType}
						fieldTypeData={fieldTypeData}
						newFieldsCreated={newFieldsCreated}
						updateNewFieldsCreated={updateNewFieldsCreated}
					/>
				)}

				{isLoading ? (
					<Stack
						justifyContent="center"
						alignItems="center"
						height="150px"
						width="100%"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : isFilteredFieldsNotEmpty ? (
					filteredFieldsBySearch.map((field, index) => (
						<Box key={field.id}>
							<MenuItem
								style={{ minHeight: "40px" }}
								onClick={() =>
									handleClickFieldOrSubField(field, index)
								}
							>
								<Stack
									key={index}
									direction="row"
									alignItems="center"
									width="350px"
								>
									<MenuItemText pl={2} pr={0.7} noWrap>
										{field?.name}
									</MenuItemText>

									{field.hasSubField ? (
										<Box
											display="flex"
											style={{
												transform:
													isSelectedSubFieldOpened(
														index
													) && "rotate(180deg)",
											}}
										>
											{DropDownIcon(16, 16, "#666666")}
										</Box>
									) : null}

									<MenuItemText
										hidden={!field.isCustomField}
										pl={1}
										style={{
											color: "rgb(46, 168, 113)",
										}}
									>
										( Custom Field )
									</MenuItemText>
								</Stack>
							</MenuItem>

							{isSelectedSubFieldOpened(index)
								? field.hasSubField &&
									field.subFields.map((subField) => (
										<MenuItem
											key={subField.id}
											sx={{ height: "40px" }}
											onClick={() =>
												handleMappingFieldData(
													field,
													true,
													subField
												)
											}
										>
											<MenuItemText pl={5} pr={1}>
												{subField?.name}
											</MenuItemText>
										</MenuItem>
									))
								: null}
						</Box>
					))
				) : (
					<Stack
						justifyContent="center"
						alignItems="center"
						height="150px"
						width="100%"
						fontSize={14}
					>
						No results found
					</Stack>
				)}
			</Box>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import { MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { default as ContactIcon } from "../../../../assets/icons/contact";
import { default as CompanyIcon } from "../../../../assets/icons/company";
import { default as DealIcon } from "../../../../assets/icons/deals";
import Menu from "../../Menu";
import { getDropdownDataByName } from "../../../../utils/dropdownData";

export default function ActivityReminder(props) {
	const {
		notificationData,
		notificationContentStyle,
		onClose,
		isNotificationActionEnabled = true,
	} = props;

	const theme = useTheme();
	const navigate = useNavigate();

	const [activityReminderMenuElement, setActivityReminderMenuElement] =
		useState(null);
	const isActivityReminderMenuOpened = Boolean(activityReminderMenuElement);

	const reminderList = getDropdownDataByName("REMINDER");

	const getReminderTime = (reminderId) => {
		return reminderList.find(
			(reminderData) => reminderData?.value === reminderId
		)?.name;
	};

	const navigateToContact = (event, contactId) => {
		event.stopPropagation();
		if (contactId) {
			onClose();
			navigate(`/contacts/${contactId?.toString()}`);
		}
	};

	const navigateToCompany = (event, companyId) => {
		event.stopPropagation();
		if (companyId) {
			onClose();
			navigate(`/companies/${companyId?.toString()}`);
		}
	};

	const navigateTodeal = (event, dealId) => {
		event?.stopPropagation();
		if (dealId) {
			onClose();
			navigate(`/deals/${dealId?.toString()}`);
		}
	};

	const openActivityReminderMenu = (event) => {
		if (isNotificationActionEnabled) {
			event.stopPropagation();
			setActivityReminderMenuElement(event.currentTarget);
		}
	};

	const closeActivityReminderMenu = () => {
		setActivityReminderMenuElement(null);
	};

	if (!notificationData) {
		return null;
	}

	return (
		<React.Fragment>
			<Menu
				minWidth="250px"
				anchorEl={activityReminderMenuElement}
				open={isActivityReminderMenuOpened}
				onClose={closeActivityReminderMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{notificationData?.data?.contacts?.map((contact) => (
					<MenuItem
						key={contact?.id}
						style={{ height: "40px" }}
						onClick={(event) =>
							navigateToContact(event, contact?.id)
						}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							width="100%"
						>
							<Typography
								fontSize={14}
								color={theme.palette.secondary.contrastText}
							>
								{contact?.name}
							</Typography>

							{ContactIcon(16, 16, "rgba(0, 0, 0, 0.6)")}
						</Stack>
					</MenuItem>
				))}

				{notificationData?.data?.company ? (
					<MenuItem
						style={{ height: "40px" }}
						onClick={(event) =>
							navigateToCompany(
								event,
								notificationData?.data?.company?.id
							)
						}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							width="100%"
						>
							<Typography
								fontSize={14}
								color={theme.palette.secondary.contrastText}
							>
								{notificationData?.data?.company?.name}
							</Typography>

							{CompanyIcon(16, 16, "rgba(0, 0, 0, 0.6)")}
						</Stack>
					</MenuItem>
				) : null}

				{notificationData?.data?.deal ? (
					<MenuItem
						style={{ height: "40px" }}
						onClick={(event) =>
							navigateTodeal(
								event,
								notificationData?.data?.deal?.id
							)
						}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							width="100%"
						>
							<Typography
								fontSize={14}
								color={theme.palette.secondary.contrastText}
							>
								{notificationData?.data?.deal?.name}
							</Typography>

							{DealIcon(16, 16, "rgba(0, 0, 0, 0.6)")}
						</Stack>
					</MenuItem>
				) : null}
			</Menu>

			<Typography style={{ ...notificationContentStyle }}>
				{notificationData.data.activityTitle}{" "}
				<Typography
					component="span"
					style={{
						...notificationContentStyle,
						opacity: "0.6",
					}}
				>
					{`due in ${getReminderTime(notificationData.data?.reminder)}
							${notificationData.data?.contacts?.length > 0 ? " for" : ""}`}
				</Typography>{" "}
				{notificationData.data?.contacts?.length > 0 ? (
					<Typography
						component="span"
						style={{
							fontSize: "14px",
							color: theme.palette.secondary.contrastText,
						}}
						onClick={(event) =>
							navigateToContact(
								event,
								notificationData.data.contacts[0]?.id
							)
						}
					>
						{notificationData.data.contacts?.[0]?.name}{" "}
					</Typography>
				) : null}
				{notificationData?.data?.contacts?.length > 1 ? (
					<Typography
						component="span"
						onClick={openActivityReminderMenu}
						style={{
							fontSize: "14px",
							color: theme.palette.secondary.contrastText,
						}}
					>
						{`+${notificationData.data.contacts.length - 1}`}
					</Typography>
				) : null}
			</Typography>
		</React.Fragment>
	);
}

import React, { useCallback, useEffect, useState } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { default as AddIcon } from "../../../assets/icons/add";
import { TextButton, Tooltip } from "../../../styles/twozo";
import ProductTableRow from "./ProductTableRow";
import { useProductList } from "../../../hooks/services/product";

export default function AddProductTable(props) {
	const theme = useTheme();
	const {
		currencyValue,
		isTaxInclusive,
		productsData,
		updateProductsData,
		productIds,
	} = props;

	const [selectedTableCellId, setSelectedTableCellId] = useState("");
	const [isLimitExceed, setIsLimitExceed] = useState(false);
	const [individualProductsAmount, setIndividualProductsAmount] = useState(
		[]
	);
	const [taxTotalAmount, setTaxTotalAmount] = useState(0);
	const [selectedProductIds, setSelectedProductIds] = useState([
		...productIds,
	]);

	const { data: productList } = useProductList();
	const isRemoveActionEnabled = productsData?.length !== 1;

	useEffect(() => {
		let productAmount = [];
		let taxTotal = 0;
		for (let index = 0; index < productsData.length; index++) {
			let price = productsData[index]?.price;
			let discount = productsData[index]?.discount;
			let quantity = productsData[index]?.quantity;
			let tax = productsData[index]?.tax;

			let amount = (price - (price * discount) / 100) * quantity;
			productAmount.push(amount);
			setIndividualProductsAmount([...productAmount]);
			taxTotal += (amount * tax) / 100;
			setTaxTotalAmount(taxTotal);
		}
	}, [productsData]);

	const getPriceTagForNewProduct = () => {
		return {
			product: "",
			price: "",
			quantity: "",
			tax: "",
			discount: "",
		};
	};

	const handleAddProduct = () => {
		if (productList?.length !== productsData.length) {
			let priceTagForNewProduct = getPriceTagForNewProduct();
			let updatedProductsData = [...productsData, priceTagForNewProduct];
			updateProductsData(updatedProductsData);

			setSelectedTableCellId(`item_${productsData.length}`);
		} else {
			setIsLimitExceed(true);
		}
	};

	const handleRemoveProduct = (index) => {
		setSelectedProductIds((productIds) =>
			productIds.filter((_, productIndex) => productIndex !== index)
		);

		let updatedProductsData = productsData.filter(
			(_, productIndex) => productIndex !== index
		);
		updateProductsData(updatedProductsData);

		setIsLimitExceed(false);
	};

	const styles = {
		tableContainer: {
			backgroundColor: theme.palette.primary.main + "20",
			borderRadius: "8px",
			p: 1,
		},
		tableHead: {
			fontSize: 14,
			fontWeight: 500,
			color: "#000",
			opacity: "0.6",
		},
		tableCell: {
			fontSize: 14,
			fontWeight: 500,
		},
		tableFieldLabel: {
			fontSize: 14,
			fontWeight: 500,
		},
	};

	const subTotalOfProducts = () => {
		let sum = 0;
		for (const amount of individualProductsAmount) {
			sum += amount;
		}
		return sum;
	};

	const onProductDetailsChange = (event, index) => {
		const { value, name } = event.target;

		let updatedProductsData = productsData.map(
			(productData, productIndex) => {
				if (productIndex === index) {
					return {
						...productData,
						[name]: value,
					};
				}
				return productData;
			}
		);
		updateProductsData(updatedProductsData);
	};

	const onProductMenuInputChange = (value, index) => {
		let productIds = [...selectedProductIds];
		productIds[index] = value?.value;
		setSelectedProductIds(productIds);

		let updatedProductsData = productsData.map(
			(productData, productIndex) => {
				if (productIndex === index) {
					return {
						price: 0,
						quantity: 1,
						tax: 0,
						discount: 0,
						product: value,
					};
				}
				return productData;
			}
		);
		updateProductsData(updatedProductsData);
	};

	const handleProductData = useCallback(
		(updatedProductData, selectedIndex) => {
			updateProductsData((productsData) => {
				return productsData?.map((productData, productIndex) => {
					if (productIndex === selectedIndex) {
						return {
							product: productData?.product,
							...updatedProductData,
						};
					}
					return productData;
				});
			});
		},
		[updateProductsData]
	);

	const handleProductTableCell = (event, index) => {
		setSelectedTableCellId(`${event.currentTarget.id}_${index}`);
	};

	const isTableCellSelected = (id, index) => {
		return selectedTableCellId === `${id}_${index}`;
	};

	return (
		<React.Fragment>
			<Box sx={styles.tableContainer}>
				<TableContainer>
					<Table sx={{ width: "100%" }} size="small">
						<TableHead>
							<TableRow>
								<TableCell
									style={{
										width: isTaxInclusive ? "28%" : "38%",
									}}
								>
									<Typography sx={styles.tableHead}>
										Item
									</Typography>
								</TableCell>

								<TableCell
									align="right"
									style={{ width: "15%" }}
								>
									<Typography sx={styles.tableHead}>
										Price
									</Typography>
								</TableCell>

								<TableCell
									align="right"
									style={{ width: "14%" }}
								>
									<Typography sx={styles.tableHead}>
										Qty
									</Typography>
								</TableCell>

								{isTaxInclusive && (
									<TableCell
										align="right"
										style={{ width: "13%" }}
									>
										<Typography sx={styles.tableHead}>
											Tax %
										</Typography>
									</TableCell>
								)}

								<TableCell
									align="right"
									style={{ width: "18%" }}
								>
									<Typography sx={styles.tableHead}>
										Discount %
									</Typography>
								</TableCell>

								<TableCell
									align="right"
									style={{ width: "16%" }}
								>
									<Typography sx={styles.tableHead}>
										Amount
									</Typography>
								</TableCell>

								<TableCell
									style={{
										width: isTaxInclusive ? "5%" : "6%",
									}}
								></TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{productsData?.map((productData, index) => (
								<ProductTableRow
									key={index}
									productData={productData}
									currencyValue={currencyValue}
									isTaxInclusive={isTaxInclusive}
									onProductDetailsChange={(event) =>
										onProductDetailsChange(event, index)
									}
									onProductMenuInputChange={(value) =>
										onProductMenuInputChange(value, index)
									}
									isRemoveActionEnabled={
										isRemoveActionEnabled
									}
									handleRemoveProduct={() =>
										handleRemoveProduct(index)
									}
									handleProductData={(updatedProductData) =>
										handleProductData(
											updatedProductData,
											index
										)
									}
									selectedProductIds={selectedProductIds}
									handleProductTableCell={(event) =>
										handleProductTableCell(event, index)
									}
									isTableCellSelected={(id) =>
										isTableCellSelected(id, index)
									}
									isNewProductsAllowed={false}
								/>
							))}

							<TableRow style={{ height: "auto" }}>
								<TableCell style={{ borderBottom: "none" }}>
									<Tooltip
										title={
											isLimitExceed
												? "No Products To Add"
												: ""
										}
									>
										<TextButton
											sx={{
												opacity: isLimitExceed
													? "0.6"
													: "1",
											}}
											size="small"
											startIcon={AddIcon(
												13,
												13,
												theme.palette.secondary
													.contrastText
											)}
											onClick={handleAddProduct}
										>
											Add More
										</TextButton>
									</Tooltip>
								</TableCell>
							</TableRow>

							<TableRow style={{ height: "auto" }}>
								<TableCell
									rowSpan={4}
									style={{ borderBottom: "none" }}
								/>
								<TableCell
									rowSpan={4}
									style={{ borderBottom: "none" }}
								/>
								<TableCell
									colSpan={isTaxInclusive ? 3 : 2}
									style={{
										borderBottom: isTaxInclusive && "none",
									}}
								>
									<Typography sx={styles.tableFieldLabel}>
										Subtotal
									</Typography>
								</TableCell>
								<TableCell
									align="right"
									style={{
										borderBottom: isTaxInclusive && "none",
									}}
								>
									<Typography
										sx={styles.tableFieldLabel}
										style={{ opacity: "0.6" }}
									>
										${subTotalOfProducts().toFixed(2)}
									</Typography>
								</TableCell>
							</TableRow>

							{isTaxInclusive && (
								<TableRow style={{ height: "auto" }}>
									<TableCell colSpan={isTaxInclusive ? 3 : 2}>
										<Typography sx={styles.tableFieldLabel}>
											Tax
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography
											sx={styles.tableFieldLabel}
											style={{ opacity: "0.6" }}
										>
											${taxTotalAmount.toFixed(2)}
										</Typography>
									</TableCell>
								</TableRow>
							)}

							<TableRow>
								<TableCell
									colSpan={isTaxInclusive ? 3 : 2}
									style={{ borderBottom: "none" }}
								>
									<Typography sx={styles.tableCell}>
										Total
									</Typography>
								</TableCell>
								<TableCell
									align="right"
									style={{ borderBottom: "none" }}
								>
									<Typography sx={styles.tableCell}>
										$
										{isTaxInclusive
											? (
													subTotalOfProducts() +
													taxTotalAmount
												).toFixed(2)
											: subTotalOfProducts().toFixed(2)}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</React.Fragment>
	);
}

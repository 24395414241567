import React, { useState } from "react";
import {
	Box,
	Button,
	Divider,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as EditIcon } from "../../../assets/icons/edit";
import Dialog from "../../Elements/Dialog";
import { hexToRgba } from "../../../styles/twozo";
import { enqueueSnackbar } from "notistack";
import {
	useModuleList,
	useRenameModules,
} from "../../../hooks/services/common";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";

export default function RenameModuleDialog() {
	const theme = useTheme();
	const [openRenameModulesDialog, setOpenRenameModulesDialog] =
		useState(false);
	const [updatedTerm, setUpdatedTerm] = useState({});

	const handleOpenRenameModulesDialog = () => {
		setOpenRenameModulesDialog(true);
	};

	const handleCloseRenameModulesDialog = () => {
		setOpenRenameModulesDialog(false);
		setUpdatedTerm({});
	};

	const { data: moduleList } = useModuleList();

	const handleUpdateClick = () => {
		if (moduleList && moduleList.length > 0) {
			let moduleListForRequest = [];
			let hasAnyChange = false;

			for (let index = 0; index < moduleList.length; index++) {
				let sourceName = moduleList[index].sourceName;
				moduleListForRequest[index] = { ...moduleList[index] };
				if (updatedTerm[sourceName]) {
					hasAnyChange = true;
					moduleListForRequest[index].alternateName =
						updatedTerm[sourceName];
				}
			}

			if (hasAnyChange) {
				renameModuleUpdateMutation.mutate(moduleListForRequest, {
					onSuccess: () => {
						enqueueSnackbar({
							variant: notificationVariants.success,
							message: notificationMessage.moduleRenameSuccess,
						});
						handleCloseRenameModulesDialog();
					},
					onError: () =>
						enqueueSnackbar({
							variant: notificationVariants.error,
							message: notificationMessage.moduleRenameFailed,
						}),
				});
			}
		} else {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.somethingWentWrong,
			});
		}
	};

	const handleChangeRename = (event, index) => {
		const { value, name } = event.target;

		if (value?.trim()) {
			if (moduleList[index]?.alternateName === value) {
				let updatedData = { ...updatedTerm };
				delete updatedData[name];
				setUpdatedTerm(updatedData);
			} else {
				setUpdatedTerm({
					...updatedTerm,
					[name]: value,
				});
			}
		} else {
			let updatedData = { ...updatedTerm };
			delete updatedData[name];
			setUpdatedTerm(updatedData);
		}
	};

	const renameModuleUpdateMutation = useRenameModules();

	/* Add System Fields Controller End */

	return (
		<React.Fragment>
			{/*Rename Modules Dialog*/}
			<Dialog
				open={openRenameModulesDialog}
				title="Rename Modules"
				subtitle="Rename modules so that they suit your company's vocabulary"
			>
				<Box p={2}>
					<Stack spacing={1}>
						<table>
							<thead>
								<tr>
									<th
										style={{
											textAlign: "left",
											width: "47%",
										}}
									>
										<Typography
											fontSize={14}
											fontWeight={500}
										>
											Current Terms
										</Typography>
									</th>

									<th style={{ width: "6%" }}></th>

									<th
										style={{
											textAlign: "left",
											width: "47%",
										}}
									>
										<Typography
											fontSize={14}
											fontWeight={500}
										>
											Updated Terms
										</Typography>
									</th>
								</tr>
							</thead>

							<tbody>
								{moduleList?.map((module, index) => (
									<tr key={module.sourceId}>
										<td>
											<TextField
												value={
													module.alternateName ??
													module.sourceName
												}
												InputProps={{
													readOnly: true,
												}}
												fullWidth
											/>
										</td>

										<td>
											<Box px={0.5}>
												<Divider />
											</Box>
										</td>

										<td>
											<TextField
												name={module.sourceName}
												placeholder={
													module.alternateName ??
													module.sourceName
												}
												onChange={(event) =>
													handleChangeRename(
														event,
														index
													)
												}
												fullWidth
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<Box p={1} pt={2}>
							<Stack
								direction="row"
								justifyContent="flex-end"
								spacing={2}
							>
								<Button
									onClick={handleCloseRenameModulesDialog}
									variant="contained"
									color="secondary"
									disableElevation
								>
									Cancel
								</Button>

								<Button
									variant="contained"
									disableElevation
									onClick={handleUpdateClick}
									sx={{
										color:
											Object.keys(updatedTerm).length > 0
												? "#FFFFFF"
												: hexToRgba("#FFFFFF", 0.6),
									}}
								>
									Save
								</Button>
							</Stack>
						</Box>
					</Stack>
				</Box>
			</Dialog>

			<Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					height="100%"
				>
					<Button
						variant="contained"
						color="secondary"
						startIcon={EditIcon(20, 20, theme.palette.primary.main)}
						onClick={handleOpenRenameModulesDialog}
						disableElevation
					>
						Rename Modules
					</Button>
				</Stack>
			</Box>
		</React.Fragment>
	);
}

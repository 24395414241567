import React from "react";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import Autocomplete from "../../../../../../../../Elements/Autocomplete";
import { default as CloseIcon } from "../../../../../../../../../assets/icons/close";
import { useWebFormDropdownList } from "../../../../../../../../../hooks/services/webform";

export default function MultiSelectComponent(props) {
	const { formProperty, formStyle } = props;
	const theme = useTheme();

	const { data: options } = useWebFormDropdownList(formProperty.fieldId);

	return (
		<React.Fragment>
			<Typography
				pl={1}
				fontSize={14}
				fontWeight={500}
				style={formStyle.labelColor}
			>
				{formProperty.label}
				{formProperty.config.isRequired && (
					<span style={{ color: theme.palette.error.main }}> *</span>
				)}
			</Typography>

			<Autocomplete
				options={options || []}
				freeSolo={false}
				forcePopupIcon={false}
				getOptionLabel={(option) => option.option}
				sx={{
					"& .MuiOutlinedInput-root": formStyle.lableInput,
					"& .MuiInputBase-input": {
						textAlign: formStyle.lableInput.textAlign,
					},
					"& .MuiInputBase-root": {
						display: "flex",
						flexDirection:
							formStyle.lableInput.textAlign === "right"
								? "row-reverse"
								: "row",
					},
				}}
				renderOption={(props, option) => (
					<Box
						component="li"
						key={option.id}
						{...props}
						style={{
							minHeight: "40px",
							display: "flex",
							flexDirection:
								formStyle.lableInput.textAlign === "right"
									? "row-reverse"
									: "row",
						}}
					>
						<Typography fontSize={14}>{option.option}</Typography>
					</Box>
				)}
				renderTags={(option, getTagProps) => (
					<Box>
						{option.map((option, index) => (
							<Chip
								size="small"
								key={option}
								label={
									<Typography fontSize={14} color="#000">
										{option.option}
									</Typography>
								}
								color="secondary"
								deleteIcon={CloseIcon(16, 16, "#000")}
								{...getTagProps({ index })}
							></Chip>
						))}
					</Box>
				)}
			/>
		</React.Fragment>
	);
}

import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SingleDatePicker from "../SingleDatePicker";
import TimePicker from "../TimePicker";
import dayjs from "dayjs";

export default function DateTimeRangePicker({
	onChange,
	value,
	error,
	inputRef,
	fromTextFieldSx,
	toTextFieldSx,
	maxDateValue,
	dateRangeSx,
}) {
	const [dateTimeRangeValue, setDateTimeRangeValue] = useState({});

	useEffect(() => {
		if (value) {
			setDateTimeRangeValue(value);
		}
	}, [value]);

	const handleDateTimeRangeValueChange = (dateTimeRangeValue) => {
		if (onChange) {
			onChange(dateTimeRangeValue);
		}
	};

	const onStartDateTimeChange = (value) => {
		//here value is a dayjs object, valueOf gives unixtimestamp
		let newValue = { ...dateTimeRangeValue, from: value.valueOf() };
		setDateTimeRangeValue(newValue);
		handleDateTimeRangeValueChange(newValue);
	};

	const onStartTimeChange = (hour, minute) => {
		let selectedDateTime = new Date(value?.from);
		selectedDateTime.setHours(hour);
		selectedDateTime.setMinutes(minute);
		onStartDateTimeChange(selectedDateTime);
	};

	const onEndDateTimeChange = (value) => {
		//here value is a dayjs object, valueOf gives unixtimestamp
		let newValue = { ...dateTimeRangeValue, to: value.valueOf() };
		setDateTimeRangeValue(newValue);
		handleDateTimeRangeValueChange(newValue);
	};

	const onEndTimeChange = (hour, minute) => {
		let selectedDateTime = new Date(value?.to);
		selectedDateTime.setHours(hour);
		selectedDateTime.setMinutes(minute);
		onEndDateTimeChange(selectedDateTime);
	};

	const getParsedValue = (value) => {
		return value ? dayjs(value) : null;
	};

	const getTimeValue = (value) => {
		if (value) {
			let selectedDateTime = new Date(value);

			return {
				hour: selectedDateTime.getHours(),
				minute: selectedDateTime.getMinutes(),
			};
		}
	};

	return (
		<React.Fragment>
			<Stack
				direction="row"
				alignItems="center"
				width="500px"
				sx={{ ...dateRangeSx }}
			>
				<Stack direction="row" spacing={1} width="47%">
					<SingleDatePicker
						textFieldProps={{
							sx: {
								width: "100%",
								...fromTextFieldSx?.dateSx,
							},
						}}
						inputRef={inputRef}
						onChange={(value) => {
							onStartDateTimeChange(value);
						}}
						error={error}
						value={getParsedValue(dateTimeRangeValue.from)}
					/>

					<TimePicker
						value={getTimeValue(dateTimeRangeValue.from)}
						onChange={onStartTimeChange}
						timeSx={fromTextFieldSx?.timeSx}
						error={error}
					/>
				</Stack>

				<Box width="6%" display="flex" justifyContent="center">
					<Typography fontSize={14}>-</Typography>
				</Box>

				<Stack direction="row" spacing={1} width="47%">
					<SingleDatePicker
						textFieldProps={{
							sx: {
								width: "100%",
								...toTextFieldSx?.dateSx,
							},
							inputProps: {
								style: { fontSize: "14px" },
							},
						}}
						inputRef={inputRef}
						onChange={(value) => {
							onEndDateTimeChange(value);
						}}
						error={error}
						value={getParsedValue(dateTimeRangeValue.to)}
						minDate={getParsedValue(dateTimeRangeValue.from)} //Prevents the selection of all values before minDate
						maxDate={maxDateValue && maxDateValue}
					/>

					<TimePicker
						value={getTimeValue(dateTimeRangeValue.to)}
						onChange={onEndTimeChange}
						timeSx={toTextFieldSx?.timeSx}
						error={error}
					/>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}

import {
	Box,
	Checkbox,
	Chip,
	Collapse,
	MenuItem,
	Select,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";
import { default as CloseIcon } from "../../../../../../assets/icons/close";
import { default as DropdownIcon } from "../../../../../../assets/icons/dropDown";
import MenuSearchBar from "../../../../../Elements/MenuSearchBar";
import ToggleButtonGroup from "../../../../../Elements/ToggleButtonGroup";
import TooltipComponent from "../../../../../Elements/TooltipComponent";
import { MenuItemText } from "../../../../../../styles/twozo";

const StepOptionList = ({
	option,
	selected,
	onFieldChange,
	hasSelectedValue,
}) => {
	const [listOpened, setListOpened] = useState(hasSelectedValue);

	const handleValueChange = (field) => {
		onFieldChange([...selected, field]);
	};

	return (
		<>
			<MenuItem
				style={{ minHeight: "40px" }}
				onClick={() => setListOpened((listOpened) => !listOpened)}
			>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<MenuItemText>{option.source}</MenuItemText>
					<Box pt={0.5}>{DropdownIcon(14, 14, "#000")}</Box>
				</Stack>
			</MenuItem>

			<Collapse in={listOpened} timeout="auto" unmountOnExit>
				{option.fields.map((field, index) => {
					const updatedField = {
						...field,
						value: field.name,
					};

					return (
						<MenuItem
							key={index}
							selected={selected.includes(updatedField.id)}
							style={{ minHeight: "40px" }}
							onClick={() => {
								handleValueChange(updatedField);
							}}
						>
							<MenuItemText pl={4}>
								{updatedField.value}
							</MenuItemText>
						</MenuItem>
					);
				})}
			</Collapse>
		</>
	);
};

export default function MultiSelectBaseComponent(params) {
	const {
		options,
		selectedValues,
		onFieldChange,
		hasStepOptions,
		stepOptions,
		style = {},
		error,
		isConditionField,
	} = params;

	const theme = useTheme();
	const [searchValue, setSearchValue] = useState("");
	const [optionType, setOptionType] = useState("field_options");
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleChangeSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const isSelectedValue = (value) => {
		return value?.length > 0;
	};

	const filteredList = useMemo(() => {
		if (Array.isArray(options)) {
			if (searchValue.trim()) {
				return options.filter((value) =>
					value?.value?.name
						.toLowerCase()
						.startsWith(searchValue.toLowerCase())
				);
			}
			return options;
		}
		return [];
	}, [options, searchValue]);

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	const unSelectValue = (value, valueIds) => {
		const updatedValues = valueIds.filter(
			(values) => values.value.id !== value.value.id
		);
		onFieldChange(updatedValues);
	};

	const onChangeFieldValues = (_, child) => {
		const selectedId = child.props.value;
		const isSelected = selectedValues.some(
			(selected) => selected.value.id === selectedId
		);

		if (isSelected) {
			// Remove the selected item
			const updatedValues = selectedValues.filter(
				(selected) => selected.value.id !== selectedId
			);
			onFieldChange(updatedValues);
		} else {
			// Add the selected item
			const newValue = options.find(
				(option) => option.value.id === selectedId
			);
			onFieldChange([...selectedValues, newValue]);
		}
	};

	const handleOptionsType = (_, type) => {
		if (type) {
			setOptionType(type);
			setSearchValue("");
		}
	};

	const getCheckedValue = (value) => {
		if (Array.isArray(selectedValues)) {
			let selectedValueIds = selectedValues.map(
				(value) => value.value.id
			);

			return selectedValueIds.some(
				(selectedId) => selectedId === value.value.id
			);
		}
		return false;
	};

	const renderValue = () => {
		return isSelectedValue(selectedValues)
			? () => {
					const maxVisibleOptions = 2;
					const selectedCount = selectedValues?.length;
					const visibleOptions = selectedValues.slice(
						0,
						maxVisibleOptions
					);

					return (
						<Stack
							direction="row"
							alignItems="center"
							spacing={0.5}
							width="100%"
						>
							<Box
								display="flex"
								flexWrap={isConditionField ? "nowrap" : "wrap"}
								gap={0.5}
							>
								{visibleOptions.map((value, index) => (
									<Chip
										size="small"
										key={index}
										style={{
											height: "26px",
											display: "flex",
											alignItems: "center",
											borderRadius: "8px",
											marginRight: "4px",
											backgroundColor: isConditionField
												? theme.palette.primary
														.contrastText
												: theme.palette.secondary.main,
										}}
										label={
											<Box display="flex">
												<TooltipComponent
													title={
														value?.value?.name || ""
													}
													placement="top"
												>
													<Typography
														fontSize={14}
														color="#000"
														maxWidth="62px"
														noWrap
													>
														{value?.value?.name ||
															""}
													</Typography>
												</TooltipComponent>
											</Box>
										}
										deleteIcon={
											<Stack
												direction="row"
												alignItems="center"
												onMouseDown={(event) =>
													event.stopPropagation()
												}
											>
												{CloseIcon(16, 16, "grey")}
											</Stack>
										}
										onDelete={(event) => {
											event.stopPropagation();
											unSelectValue(
												value,
												selectedValues
											);
										}}
									/>
								))}
							</Box>

							{selectedCount > maxVisibleOptions && (
								<Typography
									fontSize={14}
									color={theme.palette.primary.main}
									onClick={(event) => {
										event.stopPropagation();
										setIsMenuOpen(true);
									}}
								>
									+{selectedCount - maxVisibleOptions}
								</Typography>
							)}
						</Stack>
					);
				}
			: () => (
					<Typography fontSize={15} color="rgba(0, 0, 0, 0.4)">
						-Select-
					</Typography>
				);
	};

	const onMenuClose = () => {
		setIsMenuOpen(false);
	};

	const onMenuOpen = (event) => {
		if (!event.target.textContent.startsWith("+")) {
			setIsMenuOpen(true);
		} else {
			event.preventDefault();
		}
	};

	return (
		<>
			<Select
				displayEmpty
				value={selectedValues?.map((value) => value?.value?.id) || []}
				multiple
				fullWidth
				open={isMenuOpen}
				onOpen={onMenuOpen}
				onClose={onMenuClose}
				onBlur={onMenuClose}
				MenuProps={{
					style: {
						maxHeight: "340px",
						width: "100%",
					},
				}}
				onChange={onChangeFieldValues}
				sx={{
					backgroundColor: "#fff",
					height: "42px",
					...style,
				}}
				renderValue={renderValue()}
				error={error?.error}
			>
				{hasStepOptions && (
					<Stack direction="row" justifyContent="center" pb={0.5}>
						<ToggleButtonGroup
							value={optionType}
							onChange={handleOptionsType}
						>
							<ToggleButton value="field_options">
								Field Options
							</ToggleButton>

							<ToggleButton value="step_options">
								Step Options
							</ToggleButton>
						</ToggleButtonGroup>
					</Stack>
				)}

				{optionType === "field_options" && (
					<Box p={0.5}>
						<MenuSearchBar
							value={searchValue}
							onChange={handleChangeSearchValue}
						/>
					</Box>
				)}

				{optionType === "field_options" &&
					(filteredList?.length > 0
						? filteredList.map((value, index) => (
								<MenuItem
									key={index}
									value={value?.value?.id}
									name={value}
									style={{
										height: "40px",
										width: "100%",
									}}
								>
									<Stack
										style={{
											width: "inherit",
										}}
										direction="row"
										alignItems="center"
										justifyContent="space-between"
									>
										<MenuItemText noWrap width="90%">
											{value.value?.name}
										</MenuItemText>

										<Box width="10%">
											<Checkbox
												checked={getCheckedValue(value)}
											/>
										</Box>
									</Stack>
								</MenuItem>
							))
						: renderEmptyView())}

				{optionType === "step_options" &&
					(stepOptions.length > 0
						? stepOptions.map((option, index) => {
								const hasSelectedValue =
									selectedValues &&
									Array.isArray(option.fields) &&
									option.fields.some((field) =>
										selectedValues.includes(field.value.id)
									);
								return (
									<StepOptionList
										key={index}
										option={option}
										hasSelectedValue={hasSelectedValue}
										selected={selectedValues}
										onFieldChange={onFieldChange}
									/>
								);
							})
						: renderEmptyView(true))}
			</Select>
		</>
	);
}

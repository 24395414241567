import React, { useCallback, useEffect, useState } from "react";
import { ToggleButton, Typography, Box, Stack } from "@mui/material";
import Menu from "../../../Menu";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDownCentered";
import ToggleButtonGroup from "../../../ToggleButtonGroup";
import FilterList from "./FilterList";
import SalesOwnerList from "./SalesOwnerList";
import {
	useFilterList,
	useSalesOwnerFilterList,
	useSourceList,
} from "../../../../../hooks/services/common/table/filter";
import { useFilterDataContext } from "../Context/FilterDataContext";

const filterTypes = {
	filter: "filter",
	salesOwner: "salesOwner",
};

export default function FilterDropdown(props) {
	const { moduleName, hasShareViewPermissions } = props;
	const [filterMenuElement, setFilterMenuElement] = useState(null);
	const isFilterMenuOpened = Boolean(filterMenuElement);
	const [view, setView] = useState(filterTypes.filter);
	const [appliedFilterId, setAppliedFilterId] = useState(null);

	const {
		filterState,
		filterListByCondition,
		updateAppliedFilterId,
		disableApplyButton,
		updateActiveFilterConfig,
		updateFilterUICriteriaList,
		updateActiveFilterDetails,
		updateFilterCount,
	} = useFilterDataContext();

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setView(type);
		}
	};

	const openFilterListMenu = (event) => {
		setFilterMenuElement(event.currentTarget);
	};

	const closeFilterListMenu = () => {
		setFilterMenuElement(null);
	};

	const updateCurrentlyAppliedFilter = useCallback(
		(filter) => {
			closeFilterListMenu();
			if (filter.id != appliedFilterId) {
				updateActiveFilterConfig(filter);
				updateActiveFilterDetails({});
				updateFilterUICriteriaList([]);
				if (!filter?.isDefault) {
					updateAppliedFilterId(filter.id);
				} else {
					//This is a temporary fix to handle improper value sent to getFilter API for default filters
					//Proper fix is to change the API response to be proper
					updateAppliedFilterId(null);
				}
				disableApplyButton();
				updateFilterCount(0);
				filterListByCondition({ filterId: filter.id });
				setAppliedFilterId(filter.id);
			}
		},
		[
			appliedFilterId,
			disableApplyButton,
			filterListByCondition,
			updateActiveFilterDetails,
			updateActiveFilterConfig,
			updateAppliedFilterId,
			updateFilterCount,
			updateFilterUICriteriaList,
		]
	);

	//Filter Source List
	const { data: filterSourceList } = useSourceList();
	const sourceId =
		filterSourceList?.length >= 1
			? filterSourceList?.find((module) => module.name === moduleName)?.id
			: null;

	const { data: filterList, status: filterListStatus } =
		useFilterList(sourceId);

	//SalesOwner Filter
	const { data: salesOwnerFilterList, status: salesOwnerFilterListStatus } =
		useSalesOwnerFilterList(sourceId);

	useEffect(() => {
		if (
			filterListStatus === "success" &&
			filterList.length >= 1 &&
			salesOwnerFilterListStatus === "success"
		) {
			const activeFilterGroup = filterList?.find((list) =>
				list.options.find((option) => option.isActive === true)
			);

			let activeFilter = activeFilterGroup?.options.find(
				(list) => list.isActive === true
			);

			if (!activeFilter) {
				activeFilter = salesOwnerFilterList?.find(
					(list) => list.isActive === true
				);
			}

			if (activeFilter && !appliedFilterId) {
				updateActiveFilterConfig(activeFilter);
				filterListByCondition({ filterId: activeFilter.id });
				updateCurrentlyAppliedFilter(activeFilter);
				disableApplyButton();
			}
		}
	}, [
		filterList,
		filterListStatus,
		salesOwnerFilterList,
		salesOwnerFilterListStatus,
		disableApplyButton,
		updateActiveFilterConfig,
		filterListByCondition,
		updateAppliedFilterId,
		updateCurrentlyAppliedFilter,
		appliedFilterId,
	]);

	return (
		<React.Fragment>
			<Menu
				minWidth="253px"
				anchorEl={filterMenuElement}
				open={isFilterMenuOpened}
				onClose={closeFilterListMenu}
				style={{
					marginTop: "8px",
				}}
				PaperProps={{
					style: {
						maxHeight: "500px",
					},
				}}
			>
				<Stack
					direction="row"
					alignItems="center"
					paddingBottom={1}
					justifyContent="center"
				>
					<ToggleButtonGroup
						value={view}
						onChange={handleToggleButtonGroup}
					>
						<ToggleButton value={filterTypes.filter}>
							Filters
						</ToggleButton>
						<ToggleButton value={filterTypes.salesOwner}>
							Sales Owners
						</ToggleButton>
					</ToggleButtonGroup>
				</Stack>

				{(function () {
					switch (view) {
						case filterTypes.filter:
							return (
								<FilterList
									moduleName={moduleName}
									closeFilterListMenu={closeFilterListMenu}
									filterList={filterList}
									hasShareViewPermissions={
										hasShareViewPermissions
									}
									updateCurrentlyAppliedFilter={
										updateCurrentlyAppliedFilter
									}
									appliedFilterId={appliedFilterId}
								/>
							);
						case filterTypes.salesOwner:
							return (
								<SalesOwnerList
									salesOwnerList={salesOwnerFilterList}
									closeFilterListMenu={closeFilterListMenu}
									updateCurrentlyAppliedFilter={
										updateCurrentlyAppliedFilter
									}
									appliedFilterId={appliedFilterId}
								/>
							);
						default:
							return null;
					}
				})()}
			</Menu>

			<Box style={{ cursor: "pointer" }} onClick={openFilterListMenu}>
				<Stack direction="row" spacing={1} alignItems="center">
					<Typography fontWeight={600} fontSize={17}>
						{filterState.activeFilterConfig.name}
					</Typography>

					{DropDownIcon(16, 16)}
				</Stack>
			</Box>
		</React.Fragment>
	);
}

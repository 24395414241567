import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Divider,
	MenuItem,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
	IconButton,
} from "@mui/material";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import AddNewDeals from "../AddNewDeals";
import { Tooltip, twozoStyles } from "../../../styles/twozo";
import { default as MenuDialog } from "../../Elements/Menu";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as KanbanIcon } from "../../../assets/icons/kanban";
import { default as listIcon } from "../../../assets/icons/list";
import { default as FavouriteOn } from "../../../assets/icons/favouriteOn";
import { default as FavouriteOff } from "../../../assets/icons/favouriteOff";
import { default as ForecastIcon } from "../../../assets/icons/forecast";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { useModuleName } from "../../../hooks/modules";
import { modules } from "../../../utils/common/ModulesName";
import { dealViews } from "../../../utils/dealUtils";
import { default as SettingsIcon } from "../../../assets/icons/settings";
import ForecastViewFilter from "../ForecastView/ForecastViewFilter";
import DateSwitchingButton from "./DateSwitchingButton";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";

export default function Menu(props) {
	const theme = useTheme();
	const { view, handleViewToggleButtonGroup, importClicked, menuBarRef } =
		props;
	const classes = twozoStyles();
	const { getModuleName } = useModuleName();
	const [openAddNewDealsDialog, setOpenAddNewDealsDialog] = useState(false);
	const [dealsMenuElement, setDealsMenuElement] = useState(null);
	const openDealsMenu = Boolean(dealsMenuElement);
	const [favouriteDealsMenu, setFavouriteDealsMenu] = useState([]);
	const [isFilterDrawerOpened, setIsFilterDrawerOpened] = useState(false);
	const [topBarEndingPosition, setTopBarEndingPosition] = useState(0);

	const kanbanView = dealViews.kanbanView;
	const listView = dealViews.listView;
	const forecastView = dealViews.forecastView;
	const viewName = getModuleName(modules.DEAL);

	useEffect(() => {
		if (menuBarRef?.current) {
			setTopBarEndingPosition(
				menuBarRef.current.getBoundingClientRect().bottom
			);
		}
	});

	const dealsMenu = [
		"Open Deals",
		"My Deals",
		"My Territory Deals",
		"Recent Deals",
		"Recently Imported",
		"Recycle Bin",
		"Lost Deals",
		"Won Deals",
		"Hot Deals",
		"Cold Deals",
		"Closing This Week",
		"This Month Sales",
	];

	const toggleFavouriteDealMenu = (dealMenu) => {
		if (favouriteDealsMenu.includes(dealMenu)) {
			setFavouriteDealsMenu((favouriteDealsMenu) =>
				favouriteDealsMenu.filter((menu) => menu !== dealMenu)
			);
		} else {
			setFavouriteDealsMenu((favouriteDealsMenu) => [
				...favouriteDealsMenu,
				dealMenu,
			]);
		}
	};

	const toggleAddNewDealsDialog = () => {
		setOpenAddNewDealsDialog(
			(openAddNewDealsDialog) => !openAddNewDealsDialog
		);
	};

	const CloseDealsMenu = () => {
		setDealsMenuElement(null);
	};

	const toggleFilterDrawer = () => {
		setIsFilterDrawerOpened(
			(isFilterDrawerOpened) => !isFilterDrawerOpened
		);
	};

	const onViewToggleButtonGroupChange = (_, type) => {
		handleViewToggleButtonGroup(type);
		setIsFilterDrawerOpened(false);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddNewDealsDialog}
				onOpen={toggleAddNewDealsDialog}
				onClose={toggleAddNewDealsDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddNewDeals onClose={toggleAddNewDealsDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<Box>
				<MenuDialog
					minWidth="200px"
					anchorEl={dealsMenuElement}
					open={openDealsMenu}
					onClose={CloseDealsMenu}
					style={{
						marginTop: "4px",
					}}
				>
					{favouriteDealsMenu.length > 0 && (
						<React.Fragment>
							<Box px={2}>
								<Typography fontSize={13} fontWeight={500}>
									Favourites
								</Typography>
							</Box>

							{favouriteDealsMenu.map((menu, index) => (
								<MenuItem
									key={index}
									onClick={() =>
										toggleFavouriteDealMenu(menu)
									}
									style={{ height: "40px" }}
								>
									<Stack
										direction="row"
										spacing={1.5}
										alignItems="center"
									>
										{FavouriteOn(
											20,
											20,
											theme.palette.primary.main
										)}

										<Typography fontSize={13}>
											{menu}
										</Typography>
									</Stack>
								</MenuItem>
							))}

							<Divider />
						</React.Fragment>
					)}

					{dealsMenu.map((menu, index) => (
						<MenuItem
							key={index}
							onClick={() => toggleFavouriteDealMenu(menu)}
							style={{ height: "40px" }}
						>
							<Stack
								direction="row"
								spacing={1.5}
								alignItems="center"
							>
								{favouriteDealsMenu.includes(menu)
									? FavouriteOn(
											20,
											20,
											theme.palette.primary.main
										)
									: FavouriteOff(20, 20, "#000", "0.2")}
								<Typography fontSize={13}>{menu}</Typography>
							</Stack>
						</MenuItem>
					))}
				</MenuDialog>
			</Box>

			<Stack direction="row" spacing={1.5} alignItems="center">
				{view === forecastView && <DateSwitchingButton />}

				<Box pr={0.5}>
					<ToggleButtonGroup
						value={view}
						onChange={onViewToggleButtonGroupChange}
					>
						<ToggleButton value={kanbanView}>
							<Tooltip title="Pipeline" placement="bottom">
								{view === kanbanView
									? KanbanIcon(20, 20, "#fff")
									: KanbanIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
							</Tooltip>
						</ToggleButton>
						<ToggleButton value={listView}>
							<Tooltip title="List View" placement="bottom">
								{view === listView
									? listIcon(20, 20, "#fff")
									: listIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
							</Tooltip>
						</ToggleButton>
						<ToggleButton value={forecastView}>
							<Tooltip title="Deal Forecast" placement="bottom">
								<Stack
									direction="row"
									alignItems="center"
									spacing={1.5}
								>
									{view === forecastView
										? ForecastIcon(20, 20, "#fff")
										: ForecastIcon(
												20,
												20,
												"rgba(0, 0, 0, 0.6)"
											)}
								</Stack>
							</Tooltip>
						</ToggleButton>
					</ToggleButtonGroup>
				</Box>

				{!(view === forecastView) && (
					<Can permission={PERMISSIONS.deal.importRecords}>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={importClicked}
						>
							{`Import ${viewName}`}
						</Button>
					</Can>
				)}

				<Can permission={PERMISSIONS.deal.create}>
					<Button
						variant="contained"
						startIcon={AddIcon(20, 20, "#fff")}
						onClick={toggleAddNewDealsDialog}
						disableElevation
					>
						{viewName}
					</Button>
				</Can>

				{view === forecastView && (
					<Box sx={{ paddingLeft: 2 }}>
						<IconButton onClick={toggleFilterDrawer}>
							{SettingsIcon(20, 20, theme.palette.primary.main)}
						</IconButton>
					</Box>
				)}

				<CustomSwipeableDrawer
					anchor="right"
					PaperProps={{
						elevation: 0,
						style: { backgroundColor: "transparent" },
					}}
					variant="persistent"
					open={isFilterDrawerOpened}
					onOpen={toggleFilterDrawer}
					onClose={toggleFilterDrawer}
					disableBackdropClick={true}
					sx={{
						"& .MuiDrawer-paper": {
							marginTop: `${topBarEndingPosition}px`,
						},
					}}
				>
					<Box
						style={{
							height: `calc(100vh - ${topBarEndingPosition}px)`,
							width: "18vw",
							backgroundColor: "#fff",
							borderTop: "1px solid rgba(0, 0, 0, 0.1)",
						}}
					>
						<ForecastViewFilter />
					</Box>
				</CustomSwipeableDrawer>
			</Stack>
		</React.Fragment>
	);
}

import { useEffect, useContext, useState } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

/**
 * Usage
 * `usePreventReload(true || false)`.
 * If using custom modal, `{ confirmNavigation, cancelNavigation } = usePreventReload(true || false, true, openModal function)`
 **/
export const usePreventRouteChange = (
	isFormDirty,
	hasCustomModal = false,
	showCustomModal
) => {
	const { navigator } = useContext(NavigationContext);
	const [pendingNavigation, setPendingNavigation] = useState(null);

	useEffect(() => {
		if (!isFormDirty) return;

		const unblock = navigator.block((tx) => {
			const navigate = () => {
				unblock();
				tx.retry();
			};

			if (hasCustomModal) {
				// Use custom modal
				setPendingNavigation(() => navigate);
				showCustomModal(true);
			} else {
				// Use window.confirm as a fallback
				const shouldNavigate = window.confirm(
					"You have unsaved changes. Are you sure you want to leave?"
				);
				if (shouldNavigate) {
					navigate();
				}
			}
		});

		return () => {
			unblock();
		};
	}, [isFormDirty, navigator, showCustomModal, hasCustomModal]);

	return hasCustomModal
		? {
				confirmNavigation: () => {
					if (pendingNavigation) {
						pendingNavigation(); // Proceed with the pending navigation
						setPendingNavigation(null); // Clear the pending navigation
						showCustomModal(false);
					}
				},
				cancelNavigation: () => {
					setPendingNavigation(null); // Cancel the pending navigation
					showCustomModal(false);
				},
			}
		: {};
};

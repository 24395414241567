import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Divider,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import DropDownRight from "../../../../assets/icons/dropDownRight";
import { twozoStyles } from "../../../../styles/twozo";
import { useNavigate, useSearchParams } from "react-router-dom";
import ToggleButtonGroup from "../../../Elements/ToggleButtonGroup";
import Sync from "./EmailSync";
import EmailSettings from "./EmailSettings";
import OrganizationEmails from "./OrganizationEmails";
import { default as AddIcon } from "../../../../assets/icons/add";
import CalendarSync from "./CalendarSync";
import ContactSync from "./ContactSync";
import Notifications from "./Notifications";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";

const emailIntegrationFeatures = {
	emailSync: "email_sync",
	contactSync: "contact_sync",
	calendarSync: "calendar_sync",
	emailSettings: "email_settings",
	notifications: "notifications",
	organizationEmails: "organization_emails",
};

export default function EmailSync() {
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();
	const [searchParams, setSearchParams] = useSearchParams();
	const [type, setType] = useState(searchParams.get("type"));
	const emailStatusKey = "syncStatus";
	const syncedInSameOrg = "syncedInSameOrg";

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setType(type);
			setSearchParams({ type: type });
		}
	};
	useEffect(() => {
		const syncStatus = searchParams.get(emailStatusKey);

		if (syncStatus === syncedInSameOrg) {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.emailAlreadySynced,
			});
			searchParams.delete(emailStatusKey);
			setSearchParams(searchParams);
		}
	}, [searchParams, setSearchParams]);

	const getEmailFeaturesLabel = () => {
		switch (type) {
			case emailIntegrationFeatures.emailSync:
				return "Email Sync";
			case emailIntegrationFeatures.calendarSync:
				return "Calendar Sync";
			case emailIntegrationFeatures.contactSync:
				return "Contact Sync";
			case emailIntegrationFeatures.emailSettings:
				return "Email Settings";
			case emailIntegrationFeatures.notifications:
				return "Notifications";
			case emailIntegrationFeatures.organizationEmails:
				return "Organization Emails";
			default:
				"";
		}
	};

	return (
		<React.Fragment>
			<Box className={classes.menuBar}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					height="100%"
				>
					<Stack
						height="100%"
						direction="row"
						alignItems="center"
						spacing={1}
					>
						<Box
							style={{ cursor: "pointer" }}
							onClick={() => navigate("/settings")}
						>
							<Typography
								fontWeight={500}
								fontSize={15}
								color={theme.palette.secondary.contrastText}
							>
								Admin Settings
							</Typography>
						</Box>

						{DropDownRight(16, 16)}

						<Typography fontWeight={500} fontSize={15}>
							{getEmailFeaturesLabel()}
						</Typography>
					</Stack>
				</Stack>
			</Box>

			<Stack px={3} py={2} spacing={"5px"}>
				<Typography fontSize={17} fontWeight={600}>
					{getEmailFeaturesLabel()}
				</Typography>

				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{(function () {
						switch (type) {
							case emailIntegrationFeatures.calendarSync:
								return "Sync Calendar with Twozo and see all your activities in one place.";
							case emailIntegrationFeatures.contactSync:
								return "Sync your contacts in Twozo with your Google or Outlook contacts list.";
							case emailIntegrationFeatures.notifications:
								return "Enable configurations to receive in-app and email notifications.";
							default:
								return "Connect your email address to send and receive mails directly from your CRM account.";
						}
					})()}
				</Typography>
			</Stack>

			<Divider />

			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				p={2}
			>
				<Box>
					<ToggleButtonGroup
						value={type}
						onChange={handleToggleButtonGroup}
					>
						<ToggleButton
							value={emailIntegrationFeatures.emailSync}
						>
							Email Sync
						</ToggleButton>

						<ToggleButton
							value={emailIntegrationFeatures.calendarSync}
						>
							Calendar Sync
						</ToggleButton>

						<ToggleButton
							value={emailIntegrationFeatures.contactSync}
						>
							Contact Sync
						</ToggleButton>

						<ToggleButton
							value={emailIntegrationFeatures.emailSettings}
						>
							Email Settings
						</ToggleButton>

						<ToggleButton
							value={emailIntegrationFeatures.notifications}
						>
							Notifications
						</ToggleButton>

						<ToggleButton
							value={emailIntegrationFeatures.organizationEmails}
						>
							Organization Emails
						</ToggleButton>
					</ToggleButtonGroup>
				</Box>

				{type === emailIntegrationFeatures.organizationEmails && (
					<Box>
						<Button
							variant="contained"
							startIcon={AddIcon(
								20,
								20,
								theme.palette.primary.main
							)}
							color="secondary"
							disableElevation
						>
							Add Email Address
						</Button>
					</Box>
				)}
			</Stack>

			<Box>
				{(function () {
					switch (type) {
						case emailIntegrationFeatures.emailSync:
							return <Sync />;
						case emailIntegrationFeatures.emailSettings:
							return <EmailSettings />;
						case emailIntegrationFeatures.organizationEmails:
							return <OrganizationEmails />;
						case emailIntegrationFeatures.calendarSync:
							return <CalendarSync />;
						case emailIntegrationFeatures.contactSync:
							return <ContactSync />;
						case emailIntegrationFeatures.notifications:
							return <Notifications />;
						default:
							return null;
					}
				})()}
			</Box>
		</React.Fragment>
	);
}

import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getFieldDropdownData } from "../../../../api/fields/config/configApi";
import SingleDatePicker from "../../../Elements/SingleDatePicker";
import { lostDropdownCacheKey } from "../../../../utils/queryKeys";
import { dealState } from "../../../../utils/dealUtils";
import { hexToRgba, MenuItemText } from "../../../../styles/twozo";

export default function ClosedLost(props) {
	const {
		dealId,
		pipelineId,
		pipelineStageId,
		handleDealClose,
		disableSaveButton = false,
		closeLostDialog,
	} = props;
	const theme = useTheme();
	const [options, setOptions] = useState([]);
	const [reasonId, setReasonId] = useState("");
	const [dateValue, setDateValue] = useState("");

	const { status: dropdownApiStatus, data: dropdownApiData } = useQuery(
		lostDropdownCacheKey,
		() => getFieldDropdownData({ name: "lostReasonList" }),
		{
			staleTime: 30000,
		}
	);

	useEffect(() => {
		if (dropdownApiStatus === "success") {
			if (dropdownApiData?.options) {
				setOptions(dropdownApiData?.options);
			}
		}
	}, [dropdownApiStatus, dropdownApiData]);

	const handleOptions = (event) => {
		const { value } = event.target;
		setReasonId(value);
	};

	const handleDate = (event) => {
		setDateValue(event.valueOf());
	};

	const onSave = () => {
		const requestData = {};
		requestData.id = dealId;
		requestData.pipelineStageId = pipelineStageId;
		requestData.pipelineId = pipelineId;
		requestData.lostReason = reasonId;
		requestData.dealClosedOn = dateValue;
		if (!!dateValue && !!reasonId) {
			handleDealClose(requestData, dealState.lost);
		}
	};

	const isSaveButtonDiabled = () => {
		return disableSaveButton || !dateValue || !reasonId;
	};

	return (
		<React.Fragment>
			<Stack p={2} spacing={2}>
				<Stack spacing={0.5}>
					<Typography fontSize={13} fontWeight={500}>
						Reason
						<span
							style={{
								color: theme.palette.error.main,
								marginLeft: "5px",
							}}
						>
							*
						</span>
					</Typography>

					<Select
						size="small"
						onChange={handleOptions}
						value={reasonId}
					>
						{options.map((option) => (
							<MenuItem value={option.value} key={option.value}>
								<Stack
									direction="row"
									spacing={1}
									alignItems="center"
								>
									<MenuItemText>{option.name}</MenuItemText>
								</Stack>
							</MenuItem>
						))}
					</Select>
				</Stack>

				<Stack spacing={0.5}>
					<Typography fontSize={13} fontWeight={500}>
						Deal Closed on
						<span
							style={{
								color: theme.palette.error.main,
								marginLeft: "5px",
							}}
						>
							*
						</span>
					</Typography>

					<SingleDatePicker onChange={handleDate} />
				</Stack>

				<Box p={1}>
					<Stack
						direction="row"
						justifyContent="flex-end"
						spacing={2}
					>
						<Button
							onClick={closeLostDialog}
							variant="contained"
							color="secondary"
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							onClick={onSave}
							disableElevation
							disabled={isSaveButtonDiabled()}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: theme.palette.primary.main,
									color: hexToRgba("#fff", 0.6),
								},
							}}
						>
							Save
						</Button>
					</Stack>
				</Box>
			</Stack>
		</React.Fragment>
	);
}

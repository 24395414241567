import { Box, Checkbox, Stack, Typography } from "@mui/material";
import React from "react";
import { useFieldContext } from "../../FieldContext";

export default function FieldView(props) {
	const { field, isFieldEditingRestricted } = props;

	// field context
	const {
		fieldState,
		onAddViewChange,
		onRequiredFieldChange,
		onFieldValueChange,
		shouldShowFieldActions,
	} = useFieldContext(field);

	const isAddView = fieldState.unSavedFieldData.isAddView;
	const isRequired = fieldState.unSavedFieldData.isRequired;

	const setFieldRequiredValue = (value) => {
		if (isAddViewAndRequiredDisabled()) {
			return;
		}

		onFieldValueChange(value !== field.config.required);
		if (value && !isAddView) {
			onAddViewChange(true);
		}
		onRequiredFieldChange(value);
	};

	const setAddViewValue = (value) => {
		if (isAddViewAndRequiredDisabled() || isRequired) {
			return;
		}

		onFieldValueChange(value !== field.config.addView);
		onAddViewChange(value);
	};

	const isAddViewAndRequiredDisabled = () => {
		let isAutoGeneratedField = field.config.readOnly;
		let isImmutableField = field.config.immutable;
		return (
			isFieldEditingRestricted || isAutoGeneratedField || isImmutableField
		);
	};

	return (
		<React.Fragment>
			{shouldShowFieldActions && (
				<Box width="15%">
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<Checkbox
							checked={!!isAddView}
							sx={{
								opacity:
									isAddViewAndRequiredDisabled() || isRequired
										? 0.6
										: 1,
								cursor:
									isAddViewAndRequiredDisabled() || isRequired
										? "not-allowed"
										: "pointer",
							}}
							onChange={(_, value) => setAddViewValue(value)}
						/>
						<Typography fontSize={15}>Add View</Typography>
					</Stack>
				</Box>
			)}

			{shouldShowFieldActions && (
				<Box width="15%">
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<Checkbox
							checked={!!isRequired}
							sx={{
								opacity: isAddViewAndRequiredDisabled()
									? 0.6
									: 1,
								cursor: isAddViewAndRequiredDisabled()
									? "not-allowed"
									: "pointer",
							}}
							onChange={(_, value) =>
								setFieldRequiredValue(value)
							}
						/>
						<Typography fontSize={15}>Required</Typography>
					</Stack>
				</Box>
			)}
		</React.Fragment>
	);
}

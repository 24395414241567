import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
// import reportWebVitals from './reportWebVitals';
import Twozo from "./twozo";
import { ThemeProvider } from "@mui/material/styles";
import { LightTheme } from "./styles/twozo";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { getNonce } from "./utils/nonce";

const root = ReactDOM.createRoot(document.getElementById("root"));
const cspNonce = getNonce();

const cache = createCache({
	key: "twozo-css-prefix",
	nonce: cspNonce,
	prepend: true,
});

root.render(
	<CacheProvider value={cache}>
		<ThemeProvider theme={LightTheme}>
			<BrowserRouter>
				<Twozo />
			</BrowserRouter>
		</ThemeProvider>
	</CacheProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import { useTheme } from "@emotion/react";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../styles";
import { Tooltip } from "../../../../../../styles/twozo";
import DeleteDialog from "../../../../../Elements/DeleteDialog";
import { getFormattedDateMonthYearAndTime } from "../../../../../../utils/DateUtils";
import { useStopContactSync } from "../../../../../../hooks/services/contactSync";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../../utils/notification/notificationMessages";
import {
	contactSyncState,
	groupOfContactsList,
	syncDirections,
} from "../../../../../../utils/contactSyncUtils";

const syncHeadings = [
	"Total Contacts in Sync",
	"Added Today",
	"Deleted Today",
	"Updated Today",
];

export default function ContactSyncList(props) {
	const { contactSyncSettingsData, updateSyncState } = props;

	// mutation call :-
	const stopSyncMutation = useStopContactSync();

	const theme = useTheme();
	const [isStopSyncOpen, setIsStopSyncOpen] = useState(false);

	const handleCloseStopSync = () => {
		setIsStopSyncOpen(false);
	};

	const contactSyncedList = [
		{
			name: contactSyncSettingsData?.config?.totalContactInSync,
			tooltipValue: "",
			isToolTipAllowed: false,
		},
		{
			name: contactSyncSettingsData?.config?.createdToday?.total,
			tooltipValue:
				contactSyncSettingsData?.config?.createdToday?.resource,
			isToolTipAllowed: true,
		},
		{
			name: contactSyncSettingsData?.config?.deletedToday?.total,
			tooltipValue:
				contactSyncSettingsData?.config?.deletedToday?.resource,
			isToolTipAllowed: true,
		},
		{
			name: contactSyncSettingsData?.config?.updatedToday?.total,
			tooltipValue:
				contactSyncSettingsData?.config?.updatedToday?.resource,
			isToolTipAllowed: true,
		},
	];

	const getTooltipLabel = (resource) => {
		return (
			<React.Fragment>
				{Array.isArray(resource) &&
					resource.map((source, index) => (
						<Stack
							direction="row"
							alignItems="center"
							spacing={0.5}
							key={index}
						>
							<Typography style={styles.subHeading}>
								{source?.name}
							</Typography>

							<Typography>-</Typography>

							<Typography style={styles.subHeading}>
								{source?.count}
							</Typography>
						</Stack>
					))}
			</React.Fragment>
		);
	};

	const getTooltipOffsetValue = (resource) => {
		if (Array.isArray(resource)) {
			return resource.length > 1 ? [10, 0] : [0, 0];
		}

		return [0, 0];
	};

	const handleStopSync = () => {
		stopSyncMutation.mutate(
			{},
			{
				onSuccess: () => {
					handleCloseStopSync();
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.contactDisconnectMessage,
					});
				},
				onError: (error) => {
					let errorMessage = error?.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						message:
							errorMessage ?? notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	const getSyncedConfigLabel = () => {
		let oneWaySync =
			contactSyncSettingsData?.syncSettings?.syncConfig?.syncWay ===
			syncDirections.oneWay;
		let groupOfContactData = groupOfContactsList.find(
			(contact) =>
				contact.value ===
				contactSyncSettingsData?.syncSettings?.syncConfig
					?.groupOfContacts
		);

		if (oneWaySync) {
			return `One-way with ${groupOfContactData?.name}`;
		}

		return `Two-way with ${groupOfContactData?.name}`;
	};

	const onChangeSettingClick = () => {
		updateSyncState(contactSyncState.syncBridgeEstablished);
	};

	return (
		<React.Fragment>
			<DeleteDialog
				open={isStopSyncOpen}
				title="Are you sure you want to disable the sync between Twozo and your contact provider ?"
				onCancel={handleCloseStopSync}
				onDelete={handleStopSync}
				confirmButtonText="Stop Sync"
			/>

			<Box
				style={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
				}}
				m={1}
				minHeight="60vh"
			>
				<Box px={2} py={1}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Stack direction="column" spacing={2} pt={1}>
							<Stack spacing={1}>
								<Stack
									direction="row"
									alignItems="center"
									spacing={2}
								>
									<Typography style={styles.heading}>
										{
											contactSyncSettingsData?.config
												?.syncSettings?.emailAddress
										}
									</Typography>

									<Box
										style={{
											backgroundColor:
												theme.palette.secondary.main,
											borderRadius: "6px",
										}}
										py={0.5}
										px={1}
									>
										<Typography
											style={styles.subText}
											color={theme.palette.primary.main}
										>
											Active
										</Typography>
									</Box>
								</Stack>

								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
								>
									<Typography style={styles.text}>
										{getSyncedConfigLabel()}
									</Typography>

									<span>•</span>

									<Typography style={styles.text}>
										{getFormattedDateMonthYearAndTime(
											contactSyncSettingsData?.config
												?.lastSyncTime
										)}
									</Typography>
								</Stack>
							</Stack>
						</Stack>

						<Stack direction="row" alignItems="center" spacing={2}>
							<Box display="flex">
								<Button
									variant="contained"
									disableElevation
									color="secondary"
									onClick={onChangeSettingClick}
								>
									Change Settings
								</Button>
							</Box>

							<Box display="flex">
								<Button
									variant="contained"
									disableElevation
									color="error"
									onClick={() => setIsStopSyncOpen(true)}
								>
									Stop Sync
								</Button>
							</Box>
						</Stack>
					</Stack>
				</Box>

				<Stack direction="row" px={2} height="36px" alignItems="center">
					{syncHeadings.map((heading, index) => (
						<Box
							key={index}
							width={`${100 / syncHeadings.length}%`}
						>
							<Typography style={styles.contrastHeading}>
								{heading}
							</Typography>
						</Box>
					))}
				</Stack>

				<Divider />

				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					px={2}
					width="100%"
					height="42px"
				>
					{contactSyncedList.map((data, index) => (
						<Box
							width={`${100 / syncHeadings.length}%`}
							key={index}
						>
							<Tooltip
								title={getTooltipLabel(data.tooltipValue)}
								disableHoverListener={!data.isToolTipAllowed}
								placement="right"
								PopperProps={{
									modifiers: [
										{
											name: "offset",
											options: {
												offset: getTooltipOffsetValue(
													data.tooltipValue
												),
											},
										},
									],
								}}
							>
								<Typography
									style={{ fontSize: "15px" }}
									width="10%"
								>
									{data.name}
								</Typography>
							</Tooltip>
						</Box>
					))}
				</Stack>

				<Divider />
			</Box>
		</React.Fragment>
	);
}

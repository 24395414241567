import { emailPattern } from "../../../../../utils/validation";

export const validateMultipleEmailField = (field, config, isActionField) => {
	const values = field?.values || [];

	// Check if values are empty
	if (values.length === 0) {
		return {
			error: true,
			message: isActionField ? "" : "Can’t be empty",
		};
	}

	// Check for exceeding maxLimit
	if (values.length > config?.maxLimit) {
		return {
			error: true,
			message: `Max. of ${config.maxLimit} numbers are allowed`,
		};
	}

	// Validate email of each value
	const isInvalidEmail = values.some((value) => {
		const email = value?.value?.name || value?.value || "";
		if (!value?.dependsOn) {
			return !emailPattern.test(email);
		}
		return false;
	});

	if (isInvalidEmail) {
		return {
			error: true,
			message: "Invalid email address are not allowed",
		};
	}

	return { error: false, message: "" };
};

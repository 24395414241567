import { apiClient } from "../..";
import { getUserUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";
import postFieldsApi from "../../fields/postFieldsApi";

const activationId = 2;
const deactivationId = 3;

export const getUserTableData = async (
	stateId,
	searchValue,
	fieldToSort,
	pageParams
) => {
	let requestData = getUserTableDataRequest(
		stateId,
		searchValue,
		fieldToSort,
		pageParams
	);
	return await postUserManagement(requestData);
};

export const getUserList = async (searchValue, pageParam) => {
	let requestData = getUserListRequest(searchValue, pageParam);
	return postFieldsApi(requestData);
};

export const addUser = async (userData) => {
	let requestData = getaddUserRequest(userData);
	return await postAddUserManagement(requestData);
};

export const updateUser = async (userData) => {
	let requestData = getUpdateUserRequest(userData);
	return await postUserInviteManagement(requestData);
};

export const getUserData = async (id) => {
	let requestData = getUserDataRequest(id);
	return await postUserInviteManagement(requestData);
};

export const getUserSummaryData = async (id) => {
	let requestData = getUserSummaryRequest(id);
	return await postUserInviteManagement(requestData);
};

export const userPartialBulkUpdate = async (data) => {
	let requestData = getUserPartialBulkUpdateRequest(data);
	return await postUserInviteManagement(requestData);
};

export const getAuditlog = async (id, pageParams) => {
	let requestData = getAuditLogRequest(id, pageParams);
	return await postAuditLogManagement(requestData);
};

export const getUserDetailsList = async (searchValue, pageParams) => {
	const requestData = getUserDeatilsListRequest(searchValue, pageParams);
	return await postUserManagement(requestData);
};

export const resetPassword = async (email, password, token) => {
	const requestData = getResetPasswordRequest(email, password, token);
	return await postResetPasswordManagement(requestData);
};

export const transferUserRecords = async (ids) => {
	const requestData = getTransferRecordsRequest(ids);
	return await postUserManagement(requestData);
};

export const deactivateUser = async (ids) => {
	const requestData = getDeactivateRequest(ids);
	return await postUserManagement(requestData);
};

export const userReinvite = async (data) => {
	const requestData = getReinviteRequest(data);
	return await postUserInviteManagement(requestData);
};

export const activateUser = async (ids) => {
	const requestData = getUserActivationRequest(ids);
	return await postUserManagement(requestData);
};

const getUserTableDataRequest = (
	stateId,
	searchValue,
	fieldToSort,
	pageParams
) => {
	let userListRequestData = {
		type: "list",
		data: {
			state: stateId,
			...pageParams,
		},
	};

	if (searchValue) {
		userListRequestData.data.criteria = {
			type: "contains",
			value: searchValue,
		};
	}

	if (Object.keys(fieldToSort).length > 0) {
		userListRequestData.data.sort = fieldToSort;
	}

	return userListRequestData;
};

const getUserListRequest = (searchValue, pageParam) => {
	return {
		type: "dropdownData",
		data: {
			name: "userList",
			criteria: {
				type: "startsWith",
				value: searchValue,
			},
			...pageParam,
		},
	};
};

const getaddUserRequest = (userData) => {
	return {
		type: "create",
		data: userData,
	};
};

const getUpdateUserRequest = (userData) => {
	return {
		type: "update",
		data: userData,
	};
};

const getUserDataRequest = (id) => {
	return {
		type: "get",
		data: {
			id: id,
		},
	};
};

const getUserSummaryRequest = (id) => {
	return {
		type: "summary",
		data: {
			id: id,
		},
	};
};

const getUserPartialBulkUpdateRequest = (data) => {
	return {
		type: "pBulkUpdate",
		data: {
			list: data,
		},
	};
};

const getAuditLogRequest = (id, pageParams) => {
	return {
		type: "getUserAudits",
		data: {
			userId: id,
			...pageParams,
		},
	};
};

const getUserDeatilsListRequest = (searchValue = "", pageParams) => {
	return {
		type: "getUsers",
		data: {
			criteria: {
				type: "contains",
				value: searchValue,
			},
			...pageParams,
		},
	};
};

const getResetPasswordRequest = (email, password, token) => {
	return {
		type: "setPassword",
		data: {
			email: email,
			password: password,
			token: token,
		},
	};
};

const getTransferRecordsRequest = (ids) => {
	return {
		type: "transferUserRecords",
		data: {
			sourceUserId: ids.sourceUserId,
			targetUserId: ids.targetUserId,
		},
	};
};

const getDeactivateRequest = (ids) => {
	let deactivateUserRequestData = {
		type: "updateState",
		data: {
			userId: ids.userId,
			stateId: deactivationId,
		},
	};

	if (ids.targetUserId) {
		deactivateUserRequestData.data = {
			...deactivateUserRequestData.data,
			targetUserId: ids.targetUserId,
		};
	}

	return deactivateUserRequestData;
};

const getReinviteRequest = (data) => {
	return {
		type: "reInvite",
		data: data,
	};
};

const getUserActivationRequest = (ids) => {
	return {
		type: "bulkUpdateState",
		data: {
			stateId: activationId,
			userIds: ids,
		},
	};
};

const postUserManagement = async (requestData) => {
	let userManagementUrl = getUserManagementUrl();
	let response = await apiClient.post(userManagementUrl, requestData);
	assertError(response, userManagementUrl);
	return response.data.data;
};

const postUserInviteManagement = async (requestData) => {
	let userInviteManagementUrl = getUserInviteManagementUrl();
	let response = await apiClient.post(userInviteManagementUrl, requestData);
	assertError(response, userInviteManagementUrl);
	return response.data.data;
};

const postAuditLogManagement = async (requestData) => {
	let auditLogManagementUrl = getAuditlogManagementUrl();
	let response = await apiClient.post(auditLogManagementUrl, requestData);
	assertError(response, auditLogManagementUrl);
	return response.data.data;
};

const postResetPasswordManagement = async (requestData) => {
	let resetPasswordUrl = getResetPasswordUrl();
	let response = await apiClient.post(resetPasswordUrl, requestData);
	assertError(response, resetPasswordUrl);
	return response.data.data;
};

const postAddUserManagement = async (requestData) => {
	let addUserManagementUrl = getAddUserUrl();
	let response = await apiClient.post(addUserManagementUrl, requestData);
	assertError(response, addUserManagementUrl);
	return response.data.data;
};

const getUserManagementUrl = () => {
	return getUserUrl() + "/mgmt";
};

const getUserInviteManagementUrl = () => {
	return getUserUrl() + "/invite/mgmt";
};

const getAuditlogManagementUrl = () => {
	return "/audit/mgmt";
};

const getResetPasswordUrl = () => {
	return getUserUrl() + "/password/reset/mgmt";
};

const getAddUserUrl = () => {
	/* eslint no-undef: 0 */
	if (process.env.NODE_ENV === "development") {
		return getUserUrl() + "/invite/mgmt?localRedirectionRequired=true";
	} else {
		return getUserUrl() + "/invite/mgmt";
	}
};

import TextComponent from "../../../../../Elements/AddForm/Field/FieldComponents/TextComponent";
import CompanyFieldComponent from "../../../../../Elements/UpdateFieldForm/Field/FieldComponents/CompanyFieldComponent";
import AvailabilityFieldComponent from "./FieldComponents/AvailabilityFieldComponent";
import CollaboratorsFieldComponent from "./FieldComponents/CollaboratorsFieldComponent";
import ContactFieldComponent from "./FieldComponents/ContactsFieldComponent";
import DealFieldComponent from "./FieldComponents/DealFieldComponent";
import OwnerFieldComponent from "./FieldComponents/OwnerFieldComponent";
import PriorityFieldComponent from "./FieldComponents/PriorityFieldComponent";
import ReminderFieldComponent from "./FieldComponents/ReminderFieldComponent";
import ActivityTypeFieldComponent from "./FieldComponents/ActivityTypeFieldComponent";

export default function Field(params) {
	const { field, subField } = params;

	const fieldComponents = {
		title: TextComponent,
		note: TextComponent,
		location: TextComponent,
		description: TextComponent,
		companyId: CompanyFieldComponent,
		contactIds: ContactFieldComponent,
		dealId: DealFieldComponent,
		availability: AvailabilityFieldComponent,
		priority: PriorityFieldComponent,
		reminder: ReminderFieldComponent,
		typeId: ActivityTypeFieldComponent,
		assigneeId: OwnerFieldComponent,
		creator: OwnerFieldComponent,
		collaborators: CollaboratorsFieldComponent,
	};

	const FieldComponent = fieldComponents[field.id];

	return (
		<>
			{FieldComponent ? (
				<FieldComponent field={field} subField={subField} />
			) : null}
		</>
	);
}

import React, { useState } from "react";
import {
	Box,
	Divider,
	IconButton,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import {
	useCurrencyList,
	useUpdateBaseCurrencyMutation,
} from "../../../../hooks/services/currency";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import ConfirmDialog from "../../../Elements/ConfirmDialog";
import { default as InfoIcon } from "../../../../assets/icons/info";
import { MenuItemText, Tooltip } from "../../../../styles/twozo";
import MenuSearchBar from "../../../Elements/MenuSearchBar";

const styles = {
	text: {
		fontSize: "15px",
		fontWeight: 500,
	},
	menuText: {
		fontSize: "14px",
	},
};

export default function BaseCurrency(props) {
	const { currencyData, selectedCurrency, updateSelectedCurrencyDetails } =
		props;
	const theme = useTheme();

	const [isBaseCurrencyDialogOpen, setIsBaseCurrencyDialogOpen] =
		useState(false);
	const [selectedBaseCurrency, setSelectedBaseCurrency] = useState({});
	const [searchValue, setSearchValue] = useState("");

	const updateBaseCurrency = useUpdateBaseCurrencyMutation();
	const { data: currencyList } = useCurrencyList();

	const filteredCurrency = currencyList?.filter(
		(currencyDetails) =>
			currencyDetails.currency.name
				.toLowerCase()
				.startsWith(searchValue.toLowerCase()) ||
			currencyDetails.currency.code
				.toLowerCase()
				.includes(searchValue.toLowerCase())
	);

	const handleCloseBaseCurrencyDialog = () => {
		updateSelectedCurrencyDetails(currencyData?.baseCurrency);
		setIsBaseCurrencyDialogOpen(false);
	};

	const handleSelectCurrency = (_, selectedCurrencyDetails) => {
		setIsBaseCurrencyDialogOpen(true);
		setSelectedBaseCurrency(selectedCurrencyDetails.exchangeRate);
		updateSelectedCurrencyDetails(selectedCurrencyDetails.currency);
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const handleUpdateBaseCurrency = (selectedCurrency) => {
		const request = { currencyId: selectedCurrency.id };
		updateBaseCurrency.mutate(request, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.baseCurrencyUpdated,
				});
				setIsBaseCurrencyDialogOpen(false);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<ConfirmDialog
				open={isBaseCurrencyDialogOpen}
				title="Are you sure you want to change the base currency for your company?"
				subtitle={
					<Typography fontSize={15} fontWeight={400}>
						Wherever{" "}
						<span style={styles.text}>
							{selectedBaseCurrency?.baseCurrency?.currencyCode}
						</span>{" "}
						is used in the CRM, {`we${"'"}ll`} convert the value to{" "}
						<span style={styles.text}>
							{selectedBaseCurrency?.targetCurrency?.currencyCode}
						</span>
						. Note that values may change according to the selected
						currency.
						<br />
						Eg:{" "}
						<span style={styles.text}>
							{selectedBaseCurrency?.baseCurrency?.currencyCode}
						</span>{" "}
						{selectedBaseCurrency?.baseCurrency?.value} will become{" "}
						<span style={styles.text}>
							{selectedBaseCurrency?.targetCurrency?.currencyCode}
						</span>{" "}
						{selectedBaseCurrency?.targetCurrency?.value}
					</Typography>
				}
				onCancel={handleCloseBaseCurrencyDialog}
				onConfirm={() => handleUpdateBaseCurrency(selectedCurrency)}
				confirmButtonText="Change"
				cancelButtonText="Cancel"
				confirmButtonColor="primary"
			/>

			<Box py={1}>
				<Stack direction="row" alignItems="center" spacing={1}>
					<Typography fontSize={15} fontWeight={600}>
						Base Currency
					</Typography>

					<Tooltip
						title={
							<Typography fontSize={14} fontWeight={400}>
								This is your Company’s Default Currency. Before
								adding Products and Deals, it is suggested to
								decide on your base currency. All these records
								will be affected if it is done later.
							</Typography>
						}
						placement="bottom"
						PopperProps={{
							modifiers: [
								{
									name: "offset",
									options: {
										offset: [120, -8],
									},
								},
							],
						}}
					>
						<Box display="flex">
							<IconButton
								size="small"
								sx={{
									padding: 0,
								}}
							>
								{InfoIcon(17, 17, "#000")}
							</IconButton>
						</Box>
					</Tooltip>
				</Stack>

				<Box pt={1} pb={2}>
					<Typography fontSize={14} fontWeight={500} pl={1} pb={0.5}>
						This is your company’s default currency
					</Typography>

					<Box style={{ opacity: currencyData?.immutable ? 0.6 : 1 }}>
						<Select
							value={selectedCurrency}
							sx={{ width: "528px" }}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 290,
									},
								},
								autoFocus: false,
							}}
							renderValue={(selectedCurrency) => {
								if (!selectedCurrency) return "";
								return `${selectedCurrency.name} (${
									selectedCurrency.currencyCode ||
									selectedCurrency.code
								})`;
							}}
							onOpen={() => setSearchValue("")}
							disabled={currencyData?.immutable}
						>
							<Box
								p={0.5}
								marginTop="-8px"
								sx={{
									position: "sticky",
									top: 0,
									zIndex: 1,
									backgroundColor: "#fff",
								}}
							>
								<MenuSearchBar
									value={searchValue}
									onChange={handleSearchValue}
								/>
							</Box>

							{filteredCurrency?.length === 0 ? (
								<Typography
									style={{
										textAlign: "center",
										padding: 10,
										fontSize: "14px",
										color: "rgba(0, 0, 0, 0.6)",
									}}
								>
									No Results Found
								</Typography>
							) : (
								filteredCurrency?.map((currencyDetails) => (
									<MenuItem
										key={currencyDetails.currency.id}
										value={currencyDetails.currency.id}
										style={{
											height: "40px",
											backgroundColor:
												selectedCurrency.id ===
												currencyDetails.currency.id
													? theme.palette.secondary
															.main
													: "transparent",
										}}
										onClick={(event) =>
											handleSelectCurrency(
												event,
												currencyDetails
											)
										}
									>
										<Stack
											width="100%"
											direction="row"
											alignItems="center"
											justifyContent="space-between"
										>
											<MenuItemText
												style={{
													paddingLeft: "8px",
												}}
											>
												{currencyDetails.currency.name}
											</MenuItemText>

											<MenuItemText
												style={{
													paddingLeft: "8px",
												}}
											>
												{currencyDetails.currency.code}
											</MenuItemText>
										</Stack>
									</MenuItem>
								))
							)}
						</Select>
					</Box>
				</Box>
			</Box>

			<Divider />
		</React.Fragment>
	);
}

import React, { useState } from "react";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { DwarfButton, Tooltip, twozoStyles } from "../../../../styles/twozo";
import { default as DropdownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as TickIcon } from "../../../../assets/icons/tick";
import { default as EditIcon } from "../../../../assets/icons/edit";
import { default as EyeIcon } from "../../../../assets/icons/eye";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import { default as UserIcon } from "../../../../assets/icons/singleContact";
import MuiAccordion from "@mui/material/Accordion";
import { alpha, styled } from "@mui/material/styles";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import AddTemplate from "../AddTemplate";
import EmailTemplate from "./EmailTemplate";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import { useEmailTemplateContext } from "../TemplateContext";
import {
	useCreateTemplateFolder,
	useDeleteTemplateFolder,
	useEmailTemplates,
	useUpdateTemplateFolderName,
} from "../../../../hooks/services/mail/emailTemplateFolder";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useCreateEmailTemplate } from "../../../../hooks/services/mail/emailTemplate";
import SelectedMenu from "./SelectedMenu";
import VisibilityDialog from "./FolderVisibilityDialog";
import Nodes from "../../EmailComposer/EmailComposerUI/Nodes";

const styles = {
	headerText: {
		fontSize: "13px",
		fontWeight: 500,
		color: "rgba(0, 0, 0, 0.6)",
	},
	text: {
		fontSize: "14px",
	},
};

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: "8px",
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<Box display="flex">{DropdownIcon(16, 16, "#000")}</Box>}
		className=".MuiButtonBase-root-MuiAccordionSummary-root.Mui-focusVisible"
		style={{
			backgroundColor: "transparent",
		}}
		sx={{
			height: "50px",
		}}
		{...props}
	/>
))(({ theme }) => ({
	flexDirection: "row-reverse",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(180deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(2),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
	padding: "0px",
}));

export default function EmailTemplateFolder(props) {
	const {
		templateFolder,
		onChangeFolderName,
		resetFolderName,
		cancelFolderCreation,
	} = props;
	const classes = twozoStyles();
	const theme = useTheme();

	const [isAddTemplateDrawerOpened, setIsAddTemplateDrawerOpened] =
		useState(false);
	const [hoveredFolderId, setHoveredFolderId] = useState("");
	const [editedFolderId, setEditedFolderId] = useState("");
	const [showFolderVisibilityDialog, setShowFolderVisibilityDialog] =
		useState(false);
	const [showFolderDeleteDialog, setShowFolderDeleteDialog] = useState(false);
	const [selectedTemplateIds, setSelectedTemplateIds] = useState([]);

	const {
		emailTemplateState,
		onNewFolderCreation,
		onNewFolderCreationError,
		enableTemplateFolderEditMode,
		disableTemplateFolderEditMode,
		onFolderNameChanged,
		updateExpandedFolderIds,
	} = useEmailTemplateContext();

	const createTemplateFolderMutation = useCreateTemplateFolder();
	const updateTemplateFolderNameMutation = useUpdateTemplateFolderName();
	const deleteTemplateFolderMutation = useDeleteTemplateFolder();

	const createEmailTemplateMutation = useCreateEmailTemplate(
		templateFolder.id
	);

	const { data: emailTemplates } = useEmailTemplates(templateFolder.id);

	const toggleTemplateFolder = () => {
		if (!templateFolder.isNewFolder) {
			if (
				emailTemplateState.expandedFolderIds.includes(templateFolder.id)
			) {
				let updatedFolderIds =
					emailTemplateState.expandedFolderIds.filter(
						(expandedFolderId) =>
							expandedFolderId !== templateFolder.id
					);
				updateExpandedFolderIds(updatedFolderIds);
			} else {
				updateExpandedFolderIds([
					...emailTemplateState.expandedFolderIds,
					templateFolder.id,
				]);
			}
		}
	};

	const handleCreateTemplateFolder = () => {
		createTemplateFolderMutation.mutate(templateFolder.name, {
			onSuccess: () => {
				onNewFolderCreation(false);
				onNewFolderCreationError(false);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleChangeFolderName = (event) => {
		const { value } = event.target;
		onChangeFolderName(value);
	};

	const onUpdateFolderName = () => {
		let updatedFolder = {
			folderName: templateFolder.name,
			folderId: templateFolder.id,
		};
		updateTemplateFolderNameMutation.mutate(updatedFolder, {
			onSuccess: () => {
				disableTemplateFolderEditMode();
				setEditedFolderId("");
				onFolderNameChanged(false);
				onNewFolderCreationError(false);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const cancelFolderNameEdit = () => {
		disableTemplateFolderEditMode();
		setEditedFolderId("");
		resetFolderName();
		onNewFolderCreationError(false);
	};

	const onMouseOverFolder = () => {
		setHoveredFolderId(templateFolder?.id);
	};

	const onMouseOutFolder = () => {
		setHoveredFolderId("");
	};

	const isFolderAddedMode = () => {
		return templateFolder.isNewFolder;
	};

	const isFolderEditedMode = () => {
		return (
			emailTemplateState.isFolderNameEditMode &&
			editedFolderId === templateFolder.id
		);
	};

	const isTemplateFolderDisabled = () => {
		if (emailTemplateState.isNewFolderCreated) {
			if (templateFolder.isNewFolder) {
				return false;
			}
			return true;
		} else if (emailTemplateState.isFolderNameEditMode) {
			return true;
		}
		return false;
	};

	const displayTemplateFolderActions = () => {
		return (
			hoveredFolderId === templateFolder?.id &&
			emailTemplateState.expandedFolderIds.includes(templateFolder.id) &&
			!emailTemplateState.isFolderNameEditMode
		);
	};

	const isFolderErrorHighlighted = () => {
		if (isFolderAddedMode() || isFolderEditedMode()) {
			return emailTemplateState.hasNewFolderCreationError;
		} else {
			return false;
		}
	};

	const handleTemplateFolderEditMode = (event) => {
		event.stopPropagation();
		enableTemplateFolderEditMode();
		setEditedFolderId(templateFolder.id);
	};

	const openFolderVisibilityDialog = (event) => {
		event.stopPropagation();
		setShowFolderVisibilityDialog(true);
	};

	const closeFolderVisibilityDialog = () => {
		setShowFolderVisibilityDialog(false);
	};

	const openTemplateFolderDeleteDialog = (event) => {
		event.stopPropagation();
		setShowFolderDeleteDialog(true);
	};

	const closeTemplateFolderDeleteDialog = () => {
		setShowFolderDeleteDialog(false);
	};

	const onDeleteTemplateFolder = () => {
		deleteTemplateFolderMutation.mutate(templateFolder.id, {
			onSuccess: () => {
				closeTemplateFolderDeleteDialog();
				let updatedFolderIds =
					emailTemplateState.expandedFolderIds.filter(
						(expandedFolderId) =>
							expandedFolderId !== templateFolder.id
					);
				updateExpandedFolderIds(updatedFolderIds);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const openAddTemplateDrawer = () => {
		setIsAddTemplateDrawerOpened(true);
	};

	const closeAddTemplateDrawer = () => {
		setIsAddTemplateDrawerOpened(false);
	};

	const onSaveEmailTemplate = (formData) => {
		let createTemplateRequestData = {
			...formData,
			folderId: templateFolder.id,
		};
		createEmailTemplateMutation.mutate(createTemplateRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.emailTemplateCreated,
				});
				closeAddTemplateDrawer();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleSelectTemplate = (selectedTemplateId) => {
		if (selectedTemplateIds.includes(selectedTemplateId)) {
			setSelectedTemplateIds((selectedTemplateIds) =>
				selectedTemplateIds.filter(
					(templateId) => templateId !== selectedTemplateId
				)
			);
		} else {
			setSelectedTemplateIds([
				...selectedTemplateIds,
				selectedTemplateId,
			]);
		}
	};

	const clearSelectedTemplates = () => {
		setSelectedTemplateIds([]);
	};

	const displaySelectedMenu = () => {
		return (
			selectedTemplateIds.length > 0 &&
			emailTemplateState.expandedFolderIds.includes(templateFolder.id) &&
			!emailTemplateState.isFolderNameEditMode
		);
	};

	const isAddButtonDisabled = () => {
		return (
			createTemplateFolderMutation.isLoading ||
			!templateFolder.name?.trim()
		);
	};

	const isUpdateButtonDisabled = () => {
		return (
			!emailTemplateState.isFolderNameChanged ||
			updateTemplateFolderNameMutation.isLoading
		);
	};

	return (
		<React.Fragment>
			{/* Add template drawer */}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddTemplateDrawerOpened}
				onOpen={openAddTemplateDrawer}
				onClose={closeAddTemplateDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<LexicalComposer
						initialConfig={{
							theme: {
								paragraph: classes.email_paragraph,
								text: {
									bold: classes.email_font_bold,
									italic: classes.email_italic,
									underline: classes.email_underline,
									strikethrough: classes.email_strikethrough,
								},
								quote: classes.email_quote,
							},
							nodes: [...Nodes],
							onError(error) {
								throw error;
							},
						}}
					>
						<AddTemplate
							onCloseEmailTemplateDrawer={closeAddTemplateDrawer}
							onSaveEmailTemplate={onSaveEmailTemplate}
							isSaving={createEmailTemplateMutation.isLoading}
						/>
					</LexicalComposer>
				</Box>
			</CustomSwipeableDrawer>

			{/* Delete folder dialog */}
			<DeleteDialog
				title={`Are you sure you want to delete folder - ${templateFolder.name}?`}
				subtitle="This will delete all the email templates associated with this"
				open={showFolderDeleteDialog}
				onCancel={closeTemplateFolderDeleteDialog}
				onDelete={onDeleteTemplateFolder}
				disableDeleteButton={deleteTemplateFolderMutation.isLoading}
			></DeleteDialog>

			{/* Folder visibility dialog */}
			<VisibilityDialog
				isVisibilityDialogOpened={showFolderVisibilityDialog}
				onCloseVisibilityDialog={closeFolderVisibilityDialog}
				templateFolder={templateFolder}
			/>

			<Accordion
				expanded={emailTemplateState.expandedFolderIds.includes(
					templateFolder.id
				)}
				onChange={toggleTemplateFolder}
				sx={{
					border: `1px solid ${
						isFolderErrorHighlighted()
							? theme.palette.error.main
							: theme.palette.divider
					}`,
				}}
			>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					width="100%"
				>
					<AccordionSummary
						onMouseOver={onMouseOverFolder}
						onMouseOut={onMouseOutFolder}
						sx={{
							opacity:
								isTemplateFolderDisabled() &&
								templateFolder.id !== editedFolderId
									? "0.6"
									: "1",
							pointerEvents:
								isTemplateFolderDisabled() &&
								templateFolder.id !== editedFolderId
									? "none"
									: "auto",
							flexGrow: 1,
						}}
					>
						<Stack direction="row" alignItems="center" spacing={2}>
							<Box display="flex">
								{isFolderAddedMode() || isFolderEditedMode() ? (
									<TextField
										variant="standard"
										autoFocus={true}
										value={templateFolder.name}
										onChange={handleChangeFolderName}
										InputProps={{
											disableUnderline: true,
										}}
										onClick={(event) =>
											event.stopPropagation()
										}
										placeholder="Enter Folder Name"
										inputProps={{
											style: {
												padding: "0px",
												fontSize: "14px",
												fontWeight: 400,
												borderBottom:
													"1.5px solid rgba(0, 0, 0, 0.2)",
											},
										}}
									/>
								) : (
									<Typography style={styles.text}>
										{templateFolder.name}
									</Typography>
								)}
							</Box>

							{templateFolder?.hasOwnerIcon ? (
								<Tooltip
									placement="bottom"
									title={`Shared by : ${templateFolder.owner.name}`}
								>
									{UserIcon(16, 16, "rgba(0,0,0,0.4)")}
								</Tooltip>
							) : null}

							{displayTemplateFolderActions() ? (
								<Stack
									direction="row"
									alignItems="center"
									spacing={1.5}
								>
									<Divider
										orientation="vertical"
										flexItem
										style={{
											height: "20px",
											margin: "4px 0px",
										}}
									/>

									{templateFolder?.isEditable ? (
										<IconButton
											style={{ padding: "3px" }}
											onClick={
												handleTemplateFolderEditMode
											}
										>
											{EditIcon(
												20,
												20,
												theme.palette.primary.main
											)}
										</IconButton>
									) : null}

									{templateFolder?.isVisibilityEditable ? (
										<Tooltip
											placement="bottom"
											title="Folder Visibility"
											onClick={openFolderVisibilityDialog}
										>
											<IconButton
												style={{ padding: "3px" }}
											>
												{EyeIcon(
													20,
													20,
													theme.palette.primary.main
												)}
											</IconButton>
										</Tooltip>
									) : null}

									<IconButton
										style={{ padding: "3px" }}
										onClick={openTemplateFolderDeleteDialog}
									>
										{DeleteIcon(
											20,
											20,
											theme.palette.error.main
										)}
									</IconButton>
								</Stack>
							) : null}
						</Stack>
					</AccordionSummary>

					<Box pr={2}>
						{isFolderAddedMode() ? (
							<Stack direction="row" spacing={2}>
								<Button
									variant="outlined"
									startIcon={CloseIcon(20, 20, "#2EA871")}
									disableElevation
									style={{
										borderColor: "#E7F7F0",
									}}
									onClick={cancelFolderCreation}
									disabled={
										createTemplateFolderMutation.isLoading
									}
								>
									Cancel
								</Button>

								<Button
									variant="contained"
									color="secondary"
									startIcon={TickIcon(
										20,
										20,
										isAddButtonDisabled()
											? alpha("#33BC7E", 0.6)
											: "#2EA871"
									)}
									disableElevation
									onClick={handleCreateTemplateFolder}
									disabled={isAddButtonDisabled()}
									sx={{
										"&.Mui-disabled": {
											backgroundColor:
												theme.palette.secondary.main,
											color: alpha("#33BC7E", 0.6),
										},
									}}
								>
									Add
								</Button>
							</Stack>
						) : null}

						{isFolderEditedMode() ? (
							<Stack direction="row" spacing={2}>
								<Button
									variant="outlined"
									startIcon={CloseIcon(20, 20, "#2EA871")}
									disableElevation
									style={{
										borderColor: "#E7F7F0",
										height: "28px",
										width: "84px",
									}}
									onClick={cancelFolderNameEdit}
									disabled={
										updateTemplateFolderNameMutation.isLoading
									}
								>
									Cancel
								</Button>

								<Button
									variant="contained"
									color="secondary"
									disableElevation
									onClick={onUpdateFolderName}
									startIcon={TickIcon(
										20,
										20,
										isUpdateButtonDisabled()
											? alpha("#33BC7E", 0.6)
											: "#2EA871"
									)}
									disabled={isUpdateButtonDisabled()}
									sx={{
										height: "28px",
										width: "84px",
										"&.Mui-disabled": {
											backgroundColor:
												theme.palette.secondary.main,
											color: alpha("#33BC7E", 0.6),
										},
									}}
								>
									Update
								</Button>
							</Stack>
						) : null}

						{displaySelectedMenu() ? (
							<SelectedMenu
								clearSelectedTemplates={clearSelectedTemplates}
								selectedTemplateIds={selectedTemplateIds}
								templateCount={emailTemplates?.length}
								templateFolderId={templateFolder?.id}
							/>
						) : null}
					</Box>
				</Stack>

				<AccordionDetails
					sx={{
						opacity: isTemplateFolderDisabled() ? "0.6" : "1",
						pointerEvents: isTemplateFolderDisabled()
							? "none"
							: "auto",
					}}
				>
					{emailTemplates?.map((templateData, index) => (
						<Box key={index}>
							<EmailTemplate
								emailTemplateData={templateData}
								templateFolderId={templateFolder.id}
								handleSelectTemplate={handleSelectTemplate}
								selectedTemplateIds={selectedTemplateIds}
							/>
						</Box>
					))}

					<Box px={1} py={1}>
						<DwarfButton onClick={openAddTemplateDrawer}>
							<Typography fontSize={14} fontWeight={500}>
								+ Create Email Template
							</Typography>
						</DwarfButton>
					</Box>
				</AccordionDetails>
			</Accordion>
		</React.Fragment>
	);
}

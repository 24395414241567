import React, { useEffect, useState } from "react";
import TagsList from "../TagsList";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import {
	useCreateEmailTemplateTagsMutation,
	useDeleteEmailTemplateTagsMutation,
	useEmailTemplateTagsList,
	useUpdateEmailTemplateTagsMutation,
} from "../../../../hooks/services/mail/templateTags";
import { tagOperations } from "../../../../utils/tagOperations";
import { tagActionTypes } from "../../../../utils/tagActionTypes";
import { useTagContext } from "../TagsContext";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";

export default function EmailTemplateTags() {
	const { tagState, tagStateDispatcher } = useTagContext();

	// email template tags mutations:-
	const createTagMutation = useCreateEmailTemplateTagsMutation();
	const updateTagMutation = useUpdateEmailTemplateTagsMutation();
	const deleteTagMutation = useDeleteEmailTemplateTagsMutation();

	const [emailTemplateTags, setEmailTemplateTags] = useState([]);
	const [editedTagData, setEditedTagData] = useState({});
	const deleteDialogSubTitle =
		"This will also be removed all the templates if used.";
	const {
		data: emailTemplateTagsList,
		isLoading: isLoadingEmailTemplateTags,
		isFetching: isFetchingEmailTemplateTags,
		hasNextPage,
		fetchNextPage,
	} = useEmailTemplateTagsList();

	const emailTemplateTagsLastRowRef = useLastRowRef(
		fetchNextPage,
		hasNextPage,
		isFetchingEmailTemplateTags
	);

	useEffect(() => {
		if (emailTemplateTagsList?.length > 0) {
			setEmailTemplateTags(emailTemplateTagsList);
		}
	}, [emailTemplateTagsList]);

	const handleCreateEmailTemplateTag = (tagName) => {
		// create new tag mutation
		createTagMutation.mutate(tagName, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.tagCreated,
				});
				tagStateDispatcher({ type: tagActionTypes.resetCreateActions });
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleUpdateEmailTemplateTag = (tagName, tagId) => {
		// update tag mutation
		updateTagMutation.mutate(
			{ tagName, tagId },
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.tagUpdated,
					});
					tagStateDispatcher({
						type: tagActionTypes.resetUpdateActions,
					});
					setEditedTagData({});
				},
				onError: (error) => {
					let errorMessage = error?.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: errorMessage
							? errorMessage
							: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	const handleDeleteEmailTemplateTag = (tag) => {
		// delete mutation
		deleteTagMutation.mutate(tag.id, {
			onSuccess: () => {
				enqueueSnackbar({
					title: "Success!",
					variant: notificationVariants.error,
					message: notificationMessage.tagDeleted,
				});
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});

		// filter the deleted tag
		let filteredTags = emailTemplateTags.filter(
			(tags) => tags.id !== tag.id
		);
		setEmailTemplateTags(filteredTags);
	};

	const updateTags = (updatedTag, index) => {
		const updatedEmailTemplateTags = emailTemplateTags.map(
			(tag, tagIndex) => {
				if (index === tagIndex) {
					return { ...tag, ...updatedTag };
				}

				return tag;
			}
		);
		setEmailTemplateTags(updatedEmailTemplateTags);
	};

	const updateEditedTagData = (editedTagData) => {
		setEditedTagData(editedTagData);
	};

	const performCreateUpdateDelete = (action, tagName, tagId) => {
		switch (action) {
			case tagOperations.create:
				return handleCreateEmailTemplateTag(tagName);
			case tagOperations.update:
				return handleUpdateEmailTemplateTag(tagName, tagId);
			case tagOperations.delete:
				return handleDeleteEmailTemplateTag(tagName);
			default:
				break;
		}
	};

	return (
		<React.Fragment>
			{isLoadingEmailTemplateTags ? (
				<Stack
					justifyContent="center"
					alignItems="center"
					height="30vh"
				>
					<CircularProgress size="35px" />
				</Stack>
			) : emailTemplateTags?.length > 0 ||
			  isFetchingEmailTemplateTags ||
			  tagState.isCreateMode ? (
				<TagsList
					updateTags={updateTags}
					tags={emailTemplateTags}
					editedTagData={editedTagData}
					updateEditedTagData={updateEditedTagData}
					deleteDialogSubTitle={deleteDialogSubTitle}
					performCreateUpdateDelete={performCreateUpdateDelete}
					tagListLastRowRef={emailTemplateTagsLastRowRef}
					isLoadingTagList={isLoadingEmailTemplateTags}
					isFetchingTagList={isFetchingEmailTemplateTags}
				/>
			) : (
				<Stack
					p={4}
					justifyContent="center"
					alignItems="center"
					width="100%"
				>
					<Typography fontSize={15} fontWeight={400} width="75%">
						You can efficiently organize your email templates by
						creating tags and assigning them to templates. This
						feature enhances your organization and simplifies the
						process of sorting and retrieving information.
					</Typography>
				</Stack>
			)}
		</React.Fragment>
	);
}

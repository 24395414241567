export const styles = {
	heading: {
		fontSize: "15px",
		fontWeight: 600,
	},
	subHeading: {
		fontSize: "14px",
		fontWeight: 500,
	},
	text: {
		fontSize: "14px",
		fontWeight: 400,
		opacity: 0.6,
	},
	subText: {
		fontSize: "14px",
		fontWeight: 500,
	},
	contrastHeading: {
		fontSize: "14px",
		fontWeight: 500,
		opacity: 0.6,
	},
};

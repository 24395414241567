import React, { useRef, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { twozoStyles } from "../../styles/twozo";
import SettingsMenu from "./Menu";
import MenuSearchBar from "../Elements/MenuSearchBar";
import { default as MailIcon } from "../../assets/icons/mail";
import { default as MailSyncIcon } from "../../assets/icons/mailSync";
import { default as ContactSyncIcon } from "../../assets/icons/contactSync";
import { default as CalendarSyncIcon } from "../../assets/icons/calendarSync";
import { default as NotificationPendingIcon } from "../../assets/icons/notificationPending";
import { default as MailOrgIcon } from "../../assets/icons/mailOrg";
import { default as MailOpenIcon } from "../../assets/icons/mailOpen";
import { default as CallIcon } from "../../assets/icons/call";
import { default as TimeIcon } from "../../assets/icons/time";
import { default as ConversationIcon } from "../../assets/icons/conversation";
import { default as GreetingIcon } from "../../assets/icons/greeting";
import { default as ContactIcon } from "../../assets/icons/contact";
import { default as KanbanIcon } from "../../assets/icons/kanban";
import { default as WorkflowIcon } from "../../assets/icons/workflow";
import { default as ExtraIcon } from "../../assets/icons/extra";
import { default as DataIcon } from "../../assets/icons/data";
import { default as GetInIcon } from "../../assets/icons/getIn";
import { default as ImportUserIcon } from "../../assets/icons/importUser";
import { default as NoteIcon } from "../../assets/icons/note";
import { default as UserIcon } from "../../assets/icons/user";
import { default as KeyIcon } from "../../assets/icons/key";
import { default as TeamsIcon } from "../../assets/icons/teams";
import { default as LocationIcon } from "../../assets/icons/location";
import { default as CRMIcon } from "../../assets/icons/crm";
import { default as CustomIcon } from "../../assets/icons/custom";
import { default as PlanBillIcon } from "../../assets/icons/planBill";
import { useModuleName } from "../../hooks/modules";
import { modules } from "../../utils/common/ModulesName";
import { useAuth } from "../../hooks/auth";
import { PERMISSIONS } from "../../utils/Auth";
import { useEffect } from "react";

export default function Settings() {
	const classes = twozoStyles();

	// query call :-
	const { getModuleName } = useModuleName();
	const { isUserAllowedFor } = useAuth();

	// email settings
	const emailSettings = [
		{
			name: "Email Sync",
			icon: MailSyncIcon,
			link: "email?type=email_sync",
			permission: isUserAllowedFor(
				PERMISSIONS.email.viewEmailConversation
			),
		},
		{
			name: "Contact Sync",
			icon: ContactSyncIcon,
			link: "email?type=contact_sync",
			permission: isUserAllowedFor(PERMISSIONS.contact.view),
		},
		{
			name: "Calendar Sync",
			icon: CalendarSyncIcon,
			link: "email?type=calendar_sync",
			permission: true,
		},
		{
			name: "Email Settings",
			icon: MailIcon,
			link: "email?type=email_settings",
			permission: isUserAllowedFor(
				PERMISSIONS.email.viewEmailConversation
			),
		},
		{
			name: "Notifications",
			icon: NotificationPendingIcon,
			link: "email?type=notifications",
			permission: true,
		},
		{
			name: "Organization Emails",
			icon: MailOrgIcon,
			link: "email?type=organization_emails",
			permission: true,
		},
		{
			name: "Team Inbox",
			icon: MailOpenIcon,
			link: "team-inbox",
			permission: true,
		},
	];

	const getEmailSettings = () => {
		return emailSettings.filter((settings) => settings.permission);
	};

	// data fields
	const modulesAndFieldsSettings = [
		{
			name: getModuleName(modules.CONTACT),
			link: "datafields?type=Contact",
			permission: isUserAllowedFor(PERMISSIONS.contact.view),
		},
		{
			name: getModuleName(modules.COMPANY),
			link: "datafields?type=Company",
			permission: isUserAllowedFor(PERMISSIONS.company.view),
		},
		{
			name: getModuleName(modules.DEAL),
			link: "datafields?type=Deal",
			permission: isUserAllowedFor(PERMISSIONS.deal.view),
		},
		{
			name: getModuleName(modules.PRODUCT),
			link: "datafields?type=Product",
			permission: isUserAllowedFor(PERMISSIONS.product.view),
		},
		{
			name: "Contact Life Cycle Stage",
			link: "lifecycle-stage",
			permission: true,
		},
	];

	const getModulesAndFieldsSettings = () => {
		return modulesAndFieldsSettings.filter(
			(settings) => settings.permission
		);
	};

	// pipeline and goals
	const pipelineAndGoalsSettings = [
		{
			name: "Pipelines",
			link: "",
			permission: isUserAllowedFor(PERMISSIONS.deal.view),
		},
		{
			name: "Activity Goals",
			link: "",
			permission: isUserAllowedFor(PERMISSIONS.goal.view),
		},
		{
			name: "Quotas and Forecasting ",
			link: "",
			permission: isUserAllowedFor(PERMISSIONS.quotasAndForecasting.view),
		},
	];

	const getPipelineAndGoalsSettings = () => {
		return pipelineAndGoalsSettings.filter(
			(settings) => settings.permission
		);
	};

	// other settings
	const otherSettings = [
		{
			name: "Currency",
			link: "currency",
			permission: true,
		},
		{
			name: "Tags",
			link: "tags",
			permission: isUserAllowedFor(PERMISSIONS.settings.tags),
		},
		{
			name: "Sales Activities",
			link: "company/activities",
			permission: isUserAllowedFor(PERMISSIONS.activityType.view),
		},
	];

	const getOtherSettings = () => {
		return otherSettings.filter((settings) => settings.permission);
	};

	// import and migration
	const getImportPermissions = () => {
		return (
			isUserAllowedFor(PERMISSIONS.contact.importRecords) ||
			isUserAllowedFor(PERMISSIONS.company.importRecords) ||
			isUserAllowedFor(PERMISSIONS.deal.importRecords) ||
			isUserAllowedFor(PERMISSIONS.product.importRecords) ||
			isUserAllowedFor(PERMISSIONS.activity.importRecords)
		);
	};

	const importAndMigrationSettings = [
		{
			name: "Import",
			icon: DataIcon,
			link: "",
			permission: getImportPermissions(),
		},
		{
			name: "Migration",
			icon: GetInIcon,
			link: "",
			permission: isUserAllowedFor(PERMISSIONS.settings.migrateFromACrm),
		},
		{
			name: "User Import",
			icon: ImportUserIcon,
			link: "",
			permission: isUserAllowedFor(PERMISSIONS.user.access),
		},
	];

	const getImportAndMigrationSettings = () => {
		return importAndMigrationSettings.filter(
			(settings) => settings.permission
		);
	};

	// users and permissions
	const usersAndPermissionsSettings = [
		{
			name: "User",
			icon: UserIcon,
			link: "user-management?type=users",
			permissions: isUserAllowedFor(PERMISSIONS.user.access),
		},
		{
			name: "Roles",
			icon: KeyIcon,
			link: "user-management?type=roles",
			permissions: isUserAllowedFor(PERMISSIONS.role.access),
		},
		{
			name: "Teams",
			icon: TeamsIcon,
			link: "user-management?type=teams",
			permissions: true,
		},
		{
			name: "Territories",
			icon: LocationIcon,
			link: "user-management?type=territory",
			permissions: isUserAllowedFor(PERMISSIONS.territory.access),
		},
	];

	const getUsersAndPermissionsSettings = () => {
		return usersAndPermissionsSettings.filter(
			(settings) => settings.permissions
		);
	};

	// crm modules and automation
	const modulesAndAutomationSettings = [
		{
			name: "Modules & Fields",
			icon: ContactIcon,
			permission: getModulesAndFieldsSettings().length > 0,
			subSettings: {
				name: "",
				hasSubSettings: false,
				settings: getModulesAndFieldsSettings(),
			},
		},
		{
			name: "Pipeline & Goals",
			icon: KanbanIcon,
			permission: getPipelineAndGoalsSettings().length > 0,
			subSettings: {
				name: "",
				hasSubSettings: false,
				settings: getPipelineAndGoalsSettings(),
			},
		},
		{
			name: "Workflow Automation",
			icon: WorkflowIcon,
			permission: isUserAllowedFor(PERMISSIONS.settings.workFlow),
			subSettings: {
				name: "",
				hasSubSettings: false,
				settings: [
					{
						name: "Templates",
						link: "workflow-management?type=template",
					},
					{
						name: "My Automations",
						link: "workflow-management?type=automation",
					},
					{
						name: "History",
						link: "workflow-management?type=history",
					},
				],
			},
		},
		{
			name: "Other Settings",
			icon: ExtraIcon,
			permission: getOtherSettings().length > 0,
			subSettings: {
				name: "",
				hasSubSettings: false,
				settings: getOtherSettings(),
			},
		},
	];

	const getModulesAndAutomationSettings = () => {
		return modulesAndAutomationSettings.filter(
			(settings) => settings.permission
		);
	};

	// settings menu
	const settingsMenu = [
		{
			name: "Emails",
			hasSubSettings: false,
			settings: getEmailSettings(),
			permission: true,
		},
		{
			name: "Phone",
			hasSubSettings: false,
			permission: isUserAllowedFor(PERMISSIONS.settings.phoneSettings),
			settings: [
				{
					name: "Phone Numbers",
					icon: CallIcon,
					link: "",
				},
				{
					name: "Business Transfer Calls",
					icon: TimeIcon,
					link: "",
				},
				{
					name: "Messages",
					icon: ConversationIcon,
					link: "",
				},
				{
					name: "Greetings",
					icon: GreetingIcon,
					link: "",
				},
			],
		},
		{
			name: "CRM Modules & Automation",
			hasSubSettings: true,
			permission: true,
			settings: getModulesAndAutomationSettings(),
		},
		{
			name: "Data Import & Migration",
			hasSubSettings: false,
			settings: getImportAndMigrationSettings(),
			permission: getImportAndMigrationSettings().length > 0,
		},
		{
			name: "Lead Generation",
			hasSubSettings: false,
			permission: isUserAllowedFor(PERMISSIONS.webFormTracking.view),
			settings: [
				{
					name: "Webform",
					icon: NoteIcon,
					link: "webform",
				},
			],
		},
		{
			name: "Users & Permissions",
			hasSubSettings: false,
			permission: getUsersAndPermissionsSettings().length > 0,
			settings: getUsersAndPermissionsSettings(),
		},
		{
			name: "Account Settings",
			hasSubSettings: false,
			permission: isUserAllowedFor(PERMISSIONS.settings.adminSettings),
			settings: [
				{
					name: "CRM Settings",
					icon: CRMIcon,
					link: "account-settings?type=settings",
				},
				{
					name: "Account",
					icon: CustomIcon,
					link: "account-settings?type=account",
				},
				{
					name: "Plans & Billings",
					icon: PlanBillIcon,
					link: "account-settings?type=plans_and_billings",
				},
			],
		},
	];

	const getSettingsMenu = () => {
		return settingsMenu.filter((settings) => settings.permission);
	};

	const topBarRef = useRef();
	const [topStartingPosition, setTopBarStartingPosition] = useState(0);

	useEffect(() => {
		if (topBarRef) {
			setTopBarStartingPosition(
				topBarRef.current?.getBoundingClientRect().top
			);
		}
	}, []);

	return (
		<React.Fragment>
			<Box>
				<Box
					className={classes.menuBar}
					sx={{
						position: "sticky",
						backgroundColor: "#fff",
						zIndex: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						height="100%"
					>
						<Box>
							<Typography fontWeight={600}>
								Admin Settings
							</Typography>
						</Box>

						<Box width="300px">
							<MenuSearchBar />
						</Box>
					</Stack>
				</Box>

				<Divider />

				<Box
					ref={topBarRef}
					p={2}
					style={{ padding: "16px 10%" }}
					height={`calc(100vh - ${topStartingPosition}px)`}
					overflow="auto"
				>
					<Stack spacing={4}>
						{getSettingsMenu()?.map((setting, index) => (
							<Box key={index}>
								<SettingsMenu {...setting} />
							</Box>
						))}
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}

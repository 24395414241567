import React from "react";
import { ContactsOnBoardBaseComponent } from "./ContactsOnBoardBaseComponent";
import { Controller, useFormContext } from "react-hook-form";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

export function ContactsOnBoardComponent({
	field,
	inputRef,
	values,
	fieldSpecificProps: { isIconNeeded = true, isAddNewOption = true } = {},
}) {
	const { control, clearErrors } = useFormContext();

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				defaultValue={""}
				rules={handleFieldValidation(field)}
				render={({
					field: { onChange, value, ...renderField },
					fieldState: { error },
				}) => (
					<ContactsOnBoardBaseComponent
						{...renderField}
						value={value}
						onChange={onChange}
						clearErrors={clearErrors}
						field={field}
						inputRef={inputRef}
						error={error}
						formValues={values}
						isIconNeeded={isIconNeeded}
						isAddNewOption={isAddNewOption}
					/>
				)}
			></Controller>
		</React.Fragment>
	);
}

import { Controller, useFormContext } from "react-hook-form";
import { handleFieldValidation } from "../../../../../../../../utils/fieldValidation";
import Dropdown from "../../../../../../../Elements/AddForm/Field/FieldComponents/DropdownComponent/DropdownBaseComponent/Dropdown";
import { useActivityTypeList } from "../../../../../../../../hooks/services/activityType";
import { useMemo } from "react";

export default function ActivityTypeFieldComponent(params) {
	const { field } = params;
	const { control, clearErrors } = useFormContext();

	const { data: activityTypes } = useActivityTypeList(true);

	const activityTypesList = useMemo(() => {
		if (Array.isArray(activityTypes) && activityTypes.length > 0) {
			return activityTypes.map((type) => {
				return {
					name: type.name,
					value: type.id,
				};
			});
		}
		return [];
	}, [activityTypes]);

	return (
		<Controller
			control={control}
			name={field.id + ""}
			defaultValue=""
			rules={handleFieldValidation(field)}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<Dropdown
					options={activityTypesList}
					onChange={onChange}
					value={value}
					field={field}
					clearErrors={clearErrors}
					error={error}
				/>
			)}
		></Controller>
	);
}

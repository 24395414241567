import { useCallback, useReducer } from "react";
import { getSmsConfigForPermission } from "../../../../../utils/PermissionUtils/Permissions/Sms";
import { getUserSettingsConfigForPermission } from "../../../../../utils/PermissionUtils/Permissions/UserSettings";
import { getQuotasAndForecastingConfigForPermission } from "../../../../../utils/PermissionUtils/Permissions/QuotasAndForecasting";
import { getPhoneConfigForPermission } from "../../../../../utils/PermissionUtils/Permissions/Phone";
import { getLeadGenerationConfigForPermission } from "../../../../../utils/PermissionUtils/Permissions/LeadGeneration";
import { getEmailConfigForPermission } from "../../../../../utils/PermissionUtils/Permissions/Emails";
import { getAdminAccessConfigForPermission } from "../../../../../utils/PermissionUtils/Permissions/AdminAccess";
import { getActivityGoalsConfigForPermission } from "../../../../../utils/PermissionUtils/Permissions/ActivityGoals";
import { getActionsConfigForPermission } from "../../../../../utils/PermissionUtils/Permissions/Actions";
import { getSourceConfigForPermission } from "../../../../../utils/PermissionUtils/Permissions/Sources";
import { getFollowersConfigForPermission } from "../../../../../utils/PermissionUtils/Permissions/followers";

export const initialState = {
	sourceList: [],
	actionsList: [],
	activityGoalsList: [],
	adminAccessList: [],
	emailList: [],
	leadGenerationList: [],
	phoneList: [],
	quotasAndForecastingList: [],
	smsList: [],
	userSettingsList: [],
	followersList: [],
};

export const permissionActions = {
	updateSourceList: "UPDATE_SOURCE_LIST",
	updateActionsList: "UPDATE_ACTIONS_LIST",
	updateActivityGoalsList: "UPDATE_ACTIVITY_GOALS_LIST",
	updateAdminAccessList: "UPDATE_ADMIN_ACCESS_LIST",
	updateEmailList: "UPDATE_EMAIL_LIST",
	updateLeadGenerationList: "UPDATE_LEAD_GENERATION_LIST",
	updatePhoneList: "UPDATE_PHONE_LIST",
	updateQuotasAndForecastingList: "UPDATE_QUOTAS_AND_FORECASTING_LIST",
	updateUserSettingsList: "UPDATE_USER_SETTINGS_LIST",
	updateSmsList: "UPDATE_SMS_LIST",
	updateFollowersList: "UPDATE_FOLLOWERS_LIST",
	initializeSourceListForPermission: "INITIALIZE_SOURCE_LIST_FOR_PERMISSION",
	initializeActionsListForPermission:
		"INITIALIZE_ACTIONS_LIST_FOR_PERMISSION",
	initializeActivityGoalsListForPermission:
		"INITIALIZE_ACTIVITY_GOALS_FOR_PERMISSION",
	initializeAdminAccessListForPermission:
		"INITIALIZE_ADMIN_ACCESS_FOR_PERMISSION",
	initializeEmailListForPermission: "INITIALIZE_EMAIL_LIST_FOR_PERMISSION",
	initializeLeadGenerationListForPermission:
		"INITIALIZE_LEAD_GENERATION_LIST_FOR_PERMISSION",
	initializePhoneListtForPermission: "INITIALIZE_PHONE_LIST_FOR_PERMISSION",
	initializeQuotasAndForecastingListForPermission:
		"INITIALIZE_QUOTAS_AND_FORECASTING_LIST_FOR_PERMISSION",
	initializeUserSettingsListForPermission:
		"INITIALIZE_USER_SETTINGS_LIST_FOR_PERMISSION",
	initializeSmsListForPermission: "INITIALIZE_SMS_LIST_FOR_PERMISSION",
	initializeFollowersForPermission:
		"INITIALIZE_FOLLOWERS_LIST_FOR_PERMISSION",
};

export const reducer = (state, action) => {
	switch (action.type) {
		case permissionActions.updateSourceList:
			return {
				...state,
				sourceList: action.payload,
			};
		case permissionActions.updateActionsList:
			return {
				...state,
				actionsList: action.payload,
			};
		case permissionActions.updateActivityGoalsList:
			return {
				...state,
				activityGoalsList: action.payload,
			};
		case permissionActions.updateAdminAccessList:
			return {
				...state,
				adminAccessList: action.payload,
			};
		case permissionActions.updateEmailList:
			return {
				...state,
				emailList: action.payload,
			};
		case permissionActions.updateLeadGenerationList:
			return {
				...state,
				leadGenerationList: action.payload,
			};
		case permissionActions.updatePhoneList:
			return {
				...state,
				phoneList: action.payload,
			};

		case permissionActions.updateQuotasAndForecastingList:
			return {
				...state,
				quotasAndForecastingList: action.payload,
			};
		case permissionActions.updateUserSettingsList:
			return {
				...state,
				userSettingsList: action.payload,
			};
		case permissionActions.updateSmsList:
			return {
				...state,
				smsList: action.payload,
			};
		case permissionActions.updateFollowersList:
			return {
				...state,
				followersList: action.payload,
			};
		case permissionActions.initializeSourceListForPermission:
			return {
				...state,
				sourceList: getSourceConfigForPermission(
					action.payload,
					action.isCreateRole,
					action.access
				),
			};
		case permissionActions.initializeActionsListForPermission:
			return {
				...state,
				actionsList: getActionsConfigForPermission(action.payload),
			};
		case permissionActions.initializeActivityGoalsListForPermission:
			return {
				...state,
				activityGoalsList: getActivityGoalsConfigForPermission(
					action.payload
				),
			};
		case permissionActions.initializeAdminAccessListForPermission:
			return {
				...state,
				adminAccessList: getAdminAccessConfigForPermission(
					action.payload
				),
			};
		case permissionActions.initializeEmailListForPermission:
			return {
				...state,
				emailList: getEmailConfigForPermission(action.payload),
			};
		case permissionActions.initializeLeadGenerationListForPermission:
			return {
				...state,
				leadGenerationList: getLeadGenerationConfigForPermission(
					action.payload
				),
			};
		case permissionActions.initializePhoneListtForPermission:
			return {
				...state,
				phoneList: getPhoneConfigForPermission(action.payload),
			};

		case permissionActions.initializeQuotasAndForecastingListForPermission:
			return {
				...state,
				quotasAndForecastingList:
					getQuotasAndForecastingConfigForPermission(action.payload),
			};
		case permissionActions.initializeUserSettingsListForPermission:
			return {
				...state,
				userSettingsList: getUserSettingsConfigForPermission(
					action.payload
				),
			};
		case permissionActions.initializeSmsListForPermission:
			return {
				...state,
				smsList: getSmsConfigForPermission(action.payload),
			};
		case permissionActions.initializeFollowersForPermission:
			return {
				...state,
				followersList: getFollowersConfigForPermission(action.payload),
			};
		default:
			return state;
	}
};

export const usePermissionReducer = () => {
	const [permissionState, permissionStateDispatcher] = useReducer(
		reducer,
		initialState
	);

	const updateSourceList = (list) => {
		permissionStateDispatcher({
			type: permissionActions.updateSourceList,
			payload: list,
		});
	};

	const updateActionsList = (list) => {
		permissionStateDispatcher({
			type: permissionActions.updateActionsList,
			payload: list,
		});
	};

	const updateActivityGoalsList = (list) => {
		permissionStateDispatcher({
			type: permissionActions.updateActivityGoalsList,
			payload: list,
		});
	};

	const updateAdminAccessList = (list) => {
		permissionStateDispatcher({
			type: permissionActions.updateAdminAccessList,
			payload: list,
		});
	};

	const updateEmailList = (list) => {
		permissionStateDispatcher({
			type: permissionActions.updateEmailList,
			payload: list,
		});
	};

	const updateLeadGenerationList = (list) => {
		permissionStateDispatcher({
			type: permissionActions.updateLeadGenerationList,
			payload: list,
		});
	};

	const updatePhoneList = (list) => {
		permissionStateDispatcher({
			type: permissionActions.updatePhoneList,
			payload: list,
		});
	};

	const updateQuotasAndForecastingList = (list) => {
		permissionStateDispatcher({
			type: permissionActions.updateQuotasAndForecastingList,
			payload: list,
		});
	};

	const updateUserSettingsList = (list) => {
		permissionStateDispatcher({
			type: permissionActions.updateUserSettingsList,
			payload: list,
		});
	};

	const updateSmsList = (list) => {
		permissionStateDispatcher({
			type: permissionActions.updateSmsList,
			payload: list,
		});
	};

	const updateFollowersList = useCallback((list) => {
		permissionStateDispatcher({
			type: permissionActions.updateFollowersList,
			payload: list,
		});
	}, []);

	const initializeSourceListForPermission = (
		permissions,
		isCreateRole,
		access
	) => {
		permissionStateDispatcher({
			type: permissionActions.initializeSourceListForPermission,
			payload: permissions,
			isCreateRole: isCreateRole,
			access: access,
		});
	};

	const initializeActionsListForPermission = (permissions) => {
		permissionStateDispatcher({
			type: permissionActions.initializeActionsListForPermission,
			payload: permissions,
		});
	};

	const initializeActivityGoalsListForPermission = (permissions) => {
		permissionStateDispatcher({
			type: permissionActions.initializeActivityGoalsListForPermission,
			payload: permissions,
		});
	};

	const initializeAdminAccessListForPermission = (permissions) => {
		permissionStateDispatcher({
			type: permissionActions.initializeAdminAccessListForPermission,
			payload: permissions,
		});
	};

	const initializeEmailListForPermission = (permissions) => {
		permissionStateDispatcher({
			type: permissionActions.initializeEmailListForPermission,
			payload: permissions,
		});
	};

	const initializeLeadGenerationListForPermission = (permissions) => {
		permissionStateDispatcher({
			type: permissionActions.initializeLeadGenerationListForPermission,
			payload: permissions,
		});
	};

	const initializePhoneListtForPermission = (permissions) => {
		permissionStateDispatcher({
			type: permissionActions.initializePhoneListtForPermission,
			payload: permissions,
		});
	};

	const initializeQuotasAndForecastingListForPermission = (permissions) => {
		permissionStateDispatcher({
			type: permissionActions.initializeQuotasAndForecastingListForPermission,
			payload: permissions,
		});
	};

	const initializeUserSettingsListForPermission = (permissions) => {
		permissionStateDispatcher({
			type: permissionActions.initializeUserSettingsListForPermission,
			payload: permissions,
		});
	};

	const initializeSmsListForPermission = (permissions) => {
		permissionStateDispatcher({
			type: permissionActions.initializeSmsListForPermission,
			payload: permissions,
		});
	};

	const initializeFollowersListForPermission = useCallback((permission) => {
		permissionStateDispatcher({
			type: permissionActions.initializeFollowersForPermission,
			payload: permission,
		});
	}, []);

	return {
		permissionState,
		updateSourceList,
		updateActionsList,
		updateActivityGoalsList,
		updateAdminAccessList,
		updateEmailList,
		updateLeadGenerationList,
		updatePhoneList,
		updateQuotasAndForecastingList,
		updateUserSettingsList,
		updateSmsList,
		updateFollowersList,
		initializeSourceListForPermission,
		initializeActionsListForPermission,
		initializeActivityGoalsListForPermission,
		initializeAdminAccessListForPermission,
		initializeEmailListForPermission,
		initializeLeadGenerationListForPermission,
		initializePhoneListtForPermission,
		initializeQuotasAndForecastingListForPermission,
		initializeUserSettingsListForPermission,
		initializeSmsListForPermission,
		initializeFollowersListForPermission,
	};
};

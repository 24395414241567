import { ContactsOnBoardComponent } from "../../../../../../../Elements/AddForm/Field/FieldComponents/ContactsOnBoardComponent";

export default function ContactFieldComponent(params) {
	const { field, subFields } = params;

	return (
		<>
			<ContactsOnBoardComponent
				field={field}
				subFields={subFields}
				fieldSpecificProps={{
					isIconNeeded: false,
					isAddNewOption: false,
				}}
			/>
		</>
	);
}

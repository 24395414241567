import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Box, MenuItem, Select, TextField } from "@mui/material";
import { getDropdownDataByName } from "../../../../../../../utils/dropdownData";
import { MenuItemText } from "../../../../../../../styles/twozo";

export default function EmailBaseComponent({
	onChange,
	value,
	subField,
	id,
	field,
	inputRef,
	error,
	typeSx,
	valueSx,
	clearErrors,
}) {
	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subField && Array.isArray(subField)) {
			subField.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const emailTypeOptions = getDropdownDataByName("EMAIL_TYPE");
	const emailTypeField = getSubFieldObject("EMAIL_TYPE");
	const emailValueField = getSubFieldObject("EMAIL_VALUE");

	const [email, setEmail] = useState({
		[emailValueField.id]: "",
		[emailTypeField.id]: emailTypeOptions[0].value,
	});

	const handleUpdateEmail = useCallback(
		(value) => {
			let updatedEmailValue = value?.[emailValueField.id]
				? { ...value, id: emailValueField.id }
				: "";
			setEmail({
				...value,
			});
			onChange(updatedEmailValue);
		},
		[onChange, emailValueField]
	);

	useEffect(() => {
		// This useEffect is used to handle the edit case only:
		// - It displays the label in the edit mode by setting the selected email type label.
		// - It also processes server-side data in the edit case by formatting and setting `value`.
		// If `value` is an object with a `name` property, we assume it's valid data from the server or edit case,
		// so `setEmail` is called to set the label accordingly.

		const isValidServerSideData = (value) => {
			return (
				typeof value === "object" &&
				Boolean(
					value?.[emailTypeField?.id]?.name &&
						value?.[emailValueField?.id]
				)
			);
		};

		if (isValidServerSideData(value)) {
			let updatedEmailValue = {
				[emailTypeField.id]: value[emailTypeField.id]?.value,
				[emailValueField.id]: value[emailValueField.id],
			};
			handleUpdateEmail(updatedEmailValue);
		}
	}, [value, emailTypeField, emailValueField, handleUpdateEmail]);

	const handleEmail = (event, subFieldId) => {
		let { value } = event.target;
		let modifiedEmailValue = { ...email };
		modifiedEmailValue[subFieldId] = value;
		handleUpdateEmail(modifiedEmailValue);
		clearErrors(field.id);
	};

	return (
		<React.Fragment>
			<Box width="100%">
				<TextField
					placeholder={
						emailValueField.placeHolder || "example@abc.com"
					}
					type="email"
					id={id}
					sx={{
						width: "80%",
						"& .MuiOutlinedInput-notchedOutline": {
							borderRadius: "8px 0px 0px 8px",
						},
						...valueSx,
					}}
					name="EMAIL_VALUE"
					onChange={(event) => handleEmail(event, emailValueField.id)}
					value={email[emailValueField.id]}
					inputRef={inputRef}
					FormHelperTextProps={{
						sx: {
							fontSize: 13,
							ml: "8px",
						},
					}}
					error={!!error}
				/>

				<Select
					value={email[emailTypeField.id]}
					id={id}
					sx={{
						width: "20%",
						"& .MuiOutlinedInput-notchedOutline": {
							borderRadius: "0px 8px 8px 0px",
							marginLeft: "-1px",
						},
						...typeSx,
					}}
					name="EMAIL_TYPE"
					onChange={(event) => handleEmail(event, emailTypeField.id)}
					error={!!error}
				>
					{emailTypeOptions.map((emailType) => (
						<MenuItem key={emailType.value} value={emailType.value}>
							<MenuItemText>{emailType.name}</MenuItemText>
						</MenuItem>
					))}
				</Select>
			</Box>
		</React.Fragment>
	);
}

import React from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const UnsyncedEmailComposerUI = (props) => {
	const { fromAddress } = props;
	const navigate = useNavigate();
	const theme = useTheme();

	return (
		<React.Fragment>
			<Box>
				<Stack
					alignItems="center"
					justifyContent="center"
					sx={{
						backgroundColor: theme.palette.secondary.main,
					}}
					spacing={4}
					py={4}
				>
					<Stack
						alignItems="center"
						justifyContent="center"
						spacing={1.5}
						px={10}
					>
						<Box>
							<Typography fontSize={15} fontWeight={500}>
								Connect with your email
							</Typography>
						</Box>

						<Box>
							<Typography fontSize={14} textAlign="center">
								{fromAddress?.emailAddress} isn’t connected to
								Twozo. You must connect before you can use it to
								send emails from your CRM
							</Typography>
						</Box>
					</Stack>

					<Box>
						<Button
							variant="contained"
							disableElevation
							onClick={() =>
								navigate("/settings/email?type=email_sync")
							}
						>
							Connect
						</Button>
					</Box>
				</Stack>
			</Box>
		</React.Fragment>
	);
};

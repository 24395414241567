import { useEffect, useMemo, useState } from "react";
import { useStepOptions } from "../../../../../hooks/services/workflow";
import { emailPattern } from "../../../../../utils/validation";
import AutocompleteBaseComponent from "../AutocompleteBaseComponent";

export default function MultipleEmailComponent(params) {
	const {
		field,
		metadata,
		updateFieldValue,
		error,
		style,
		placeholder,
		value,
		clearError,
		isConditionField,
	} = params;

	const { data: stepOptions } = useStepOptions(metadata, field);
	const [selectedEmails, setSelectedEmails] = useState([]);

	const isValidEmail = (option) => {
		if (option.isNewOption) {
			return emailPattern.test(option.value.name);
		} else {
			return true;
		}
	};

	useEffect(() => {
		if (value && Array.isArray(value)) {
			let fieldValue = value.map((field) => {
				if (field.value?.name) {
					return field;
				}
				return {
					...field,
					value: {
						name: field.value,
					},
				};
			});

			setSelectedEmails(fieldValue);
		}
	}, [value]);

	const handleChangeEmails = (emails) => {
		setSelectedEmails(emails);
		updateFieldValue(emails, field.fieldId);
		clearError(field.fieldId);
	};

	const stepOptionFields = useMemo(() => {
		if (Array.isArray(stepOptions)) {
			return stepOptions.flatMap((stepOption) =>
				stepOption.fields.map((field) => ({
					value: { id: field.id, name: field.name },
					dependsOn: stepOption.dependsOn,
				}))
			);
		}
		return [];
	}, [stepOptions]);

	return (
		<AutocompleteBaseComponent
			style={style}
			placeholder={placeholder || "Enter"}
			error={error}
			values={selectedEmails}
			options={stepOptionFields}
			isValidOption={isValidEmail}
			onChange={handleChangeEmails}
			isNewOption
			isConditionField={isConditionField}
		/>
	);
}

import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import ActivityIcon from "./ActivityIcon";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../../utils/notification/notificationMessages";
import {
	useCreateActivityType,
	useIconList,
	useUpdateActivityType,
} from "../../../../../../hooks/services/activityType";

export default function AddActivity(props) {
	const {
		activityTypeData,
		isAddActivityMode,
		handleCloseAddEditActivityTypeDialog,
	} = props;
	const theme = useTheme();

	const [selectedIcon, setSelectedIcon] = useState("");
	const [activityTypeName, setActivityTypeName] = useState("");

	const { data: iconList } = useIconList();

	const createActivityTypeData = useCreateActivityType();

	const updateActivityTypeData = useUpdateActivityType();

	useEffect(() => {
		if (!isAddActivityMode && activityTypeData && activityTypeData.name) {
			setActivityTypeName(activityTypeData.name);
		} else {
			setActivityTypeName("");
		}
		if (
			!isAddActivityMode &&
			activityTypeData &&
			activityTypeData.icon.id
		) {
			setSelectedIcon(activityTypeData.icon.id);
		} else {
			setSelectedIcon("");
		}
	}, [activityTypeData, isAddActivityMode]);

	const updateActivityName = (event) => {
		let newActivityName = event.target.value;
		setActivityTypeName(newActivityName);
	};

	const selectIcon = (id) => {
		setSelectedIcon(id);
	};

	const handleCreateActivityType = () => {
		if (!!activityTypeName && !!selectedIcon) {
			if (isAddActivityMode) {
				createActivityTypeData.mutate(
					{
						name: activityTypeName,
						iconId: selectedIcon,
					},
					{
						onSuccess: () => {
							enqueueSnackbar({
								variant: notificationVariants.success,
								message: notificationMessage.activityTypeAdded,
							});
							handleCloseAddEditActivityTypeDialog();
						},
						onError: (error) => {
							let errorMessage = error.message;
							enqueueSnackbar({
								variant: notificationVariants.error,
								message: errorMessage
									? errorMessage
									: notificationMessage.errorMessage,
							});
						},
					}
				);
			} else {
				updateActivityTypeData.mutate(
					{
						name: activityTypeName,
						iconId: selectedIcon,
						id: activityTypeData.id,
					},
					{
						onSuccess: () => {
							enqueueSnackbar({
								variant: notificationVariants.success,
								message:
									notificationMessage.activityTypeUpdated,
							});
							handleCloseAddEditActivityTypeDialog();
						},
						onError: (error) => {
							let errorMessage = error.message;
							enqueueSnackbar({
								variant: notificationVariants.error,
								message: errorMessage
									? errorMessage
									: notificationMessage.errorMessage,
							});
						},
					}
				);
			}
		}
	};

	return (
		<React.Fragment>
			<Box>
				<Stack spacing={2}>
					<Stack spacing={0.75}>
						<Typography fontSize={14} fontWeight={500} pl={0.8}>
							Name of the activity type
						</Typography>

						<TextField
							placeholder="Eg: Meeting, Call, Email etc.,"
							fullWidth
							sx={{
								"& .MuiOutlinedInput-input": {
									fontSize: "15px !important",
								},
							}}
							value={activityTypeName}
							onChange={updateActivityName}
						/>
					</Stack>

					<Stack spacing={0.5}>
						<Typography
							fontSize={14}
							fontWeight={500}
							pl={0.75}
							pb={0.25}
						>
							Select Icon
						</Typography>

						<Box
							style={{
								backgroundColor: theme.palette.secondary.main,
								borderRadius: "8px",
							}}
							p={1}
						>
							<Stack
								direction="row"
								sx={{ flexWrap: "wrap", gap: 1 }}
							>
								{iconList?.map((icon) => (
									<ActivityIcon
										key={icon.id}
										id={icon.id}
										icon={icon.name}
										selectIcon={selectIcon}
										selected={selectedIcon === icon.id}
									/>
								))}
							</Stack>
						</Box>
					</Stack>

					<Box textAlign="right" p={1}>
						<Button
							variant="contained"
							disableElevation
							onClick={handleCreateActivityType}
							style={{
								color:
									!!activityTypeName && !!selectedIcon
										? "#ffff"
										: "rgba(255, 255, 255, 0.6)",
							}}
						>
							Save
						</Button>
					</Box>
				</Stack>
			</Box>
		</React.Fragment>
	);
}

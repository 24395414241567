import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import { hexToRgba, MenuItemText, twozoStyles } from "../../../styles/twozo";
import SummaryMenu from "./Menu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as CompanyIcon } from "../../../assets/icons/company";
import { default as ThumbsUpIcon } from "../../../assets/icons/thumbsUp";
import { default as ThumbsDownIcon } from "../../../assets/icons/thumbsDown";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import DealTimeline from "./Timeline";
import DealConversations from "./Conversations";
import DealActivities from "./Activities";
import DealFiles from "./Files";
import DealNotes from "./Notes";
import DealRelatedContacts from "./RelatedContacts";
import AddActivity from "../../Summary/Activities/AddActivity";
import Menu from "../../Elements/Menu";
import DealStagesTimeline from "./DealStagesTimeline";
import Overview from "./Overview";
import SummaryCardView from "../../Elements/SummaryCardView";
import EditDeal from "../EditDeal";
import { default as SingleContactIcon } from "../../../assets/icons/singleContact";
import { default as CloseIcon } from "../../../assets/icons/close";
import { default as EditIcon } from "../../../assets/icons/edit";
import {
	getDealCompletedActivityKey,
	getDealToDoActivityKey,
} from "../../../utils/queryKeys";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { modules } from "../../../utils/common/ModulesName";
import DealSummaryLoader from "../../Elements/Loader/SummaryLoader/DealSummaryLoader";
import { useModuleName } from "../../../hooks/modules";
import {
	useDealSummaryData,
	usePartialUpdateDeal,
} from "../../../hooks/services/deal";
import { summaryViews } from "../../../utils/summaryUtils";
import { dealState } from "../../../utils/dealUtils";
import { useActivityTypeList } from "../../../hooks/services/activityType";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";
import { useDealFieldPermissions } from "../../../hooks/modules/auth/deal";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import DealSummaryProfileEdit from "./DealSummaryProfileEdit";
import { SummaryContextProvider } from "../../Summary/SummaryContext/SummaryContextProvider";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { useAuth } from "../../../hooks/auth";
import TooltipComponent from "../../Elements/TooltipComponent";
import Notes from "../../Summary/Notes";
import Files from "../../Summary/Files";
import AddFile from "../../Summary/ShortFiles/AddFile";
import Settings from "../../../assets/icons/settings";
import ManageFollowers from "../../Summary/Followers/ManageFollowers";
import { useFollowersList } from "../../../hooks/services/summary/followers";
import Followers from "../../Summary/Followers";

export default function DealsSummary() {
	let { dealId } = useParams();
	const parsedDealId = parseInt(dealId);
	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const { getModuleName } = useModuleName();
	const { isUserAllowedFor } = useAuth();
	const { data: followerList } = useFollowersList(modules.DEAL, parsedDealId);

	// permission
	const hasEditPermission = isUserAllowedFor(PERMISSIONS.deal.edit);

	const updateMutation = usePartialUpdateDeal(parsedDealId);

	const DealsStagesTimelineRef = useRef(null);
	const profileContainerRef = useRef(null);

	const [view, setView] = useState(summaryViews.overview);
	const [openAddActivityDialog, setOpenAddActivityDialog] = useState(false);
	const [filterTimelineMenuElement, setFilterTimelineMenuElement] =
		useState(null);
	const isTimelineFilterMenuOpened = Boolean(filterTimelineMenuElement);
	const [filterActivitiesMenuElement, setFilterActivitiesMenuElement] =
		useState(null);
	const isActivitiesFilterMenuOpened = Boolean(filterActivitiesMenuElement);
	const [isEditFormDialogOpen, setIsEditFormDialogOpen] = useState(false);
	const [summaryFields, setSummaryFields] = useState([]);
	const [summarySubFields, setSummarySubFields] = useState([]);
	const [profile, setProfile] = useState({});
	const dealToDoActivityKey = getDealToDoActivityKey(parsedDealId);
	const dealCompletedActivityKey = getDealCompletedActivityKey(parsedDealId);
	const [timelineIdsToFilter, setTimelineIdsToFilter] = useState([]);
	const [activityTypeIdsToFilter, setActivityTypeIdsToFilter] = useState([]);
	const [hideEmptyFields, setHideEmptyFields] = useState(false);
	const [isProfileEditIconVisible, setIsProfileEditIconVisible] =
		useState(false);
	const [isAddFileDialogOpened, setIsAddFileDialogOpened] = useState(false);

	const [profileMenuElement, setProfileMenuElement] = useState(null);
	const isProfileEditMenuOpened = Boolean(profileMenuElement);

	const onMouseOverProfile = () => {
		setIsProfileEditIconVisible(true);
	};

	const onMouseOutProfile = () => {
		setIsProfileEditIconVisible(false);
	};

	const showFollowers = true;

	const timelineFilterList = [
		{
			id: "calls",
			name: "Calls",
		},
		{
			id: "emails",
			name: "Emails",
		},
		{
			id: "notes",
			name: "Notes",
		},
		{
			id: "activity",
			name: "Activities",
		},
		{
			id: "file",
			name: "Files",
		},
		{
			id: "updates",
			name: "Updates",
		},
	];
	const viewName = getModuleName(modules.DEAL);
	const dealFieldPermissions = useDealFieldPermissions();

	const selectedTimelineFilterCount =
		timelineIdsToFilter.length > 0 ? timelineIdsToFilter.length : "";
	const selectedActivityFilterCount =
		activityTypeIdsToFilter.length > 0
			? activityTypeIdsToFilter.length
			: "";

	const isActiveActivityType = true;
	const { data: activityTypeList } =
		useActivityTypeList(isActiveActivityType);

	const {
		status: dealSummaryStatus,
		data: dealSummaryData,
		isLoading: isDealSummaryLoading,
	} = useDealSummaryData(dealId, hideEmptyFields);

	useEffect(() => {
		if (dealSummaryStatus === "success") {
			if (dealSummaryData.profile) {
				setProfile(dealSummaryData.profile);
			}
			if (dealSummaryData.subFields) {
				setSummarySubFields(dealSummaryData.subFields);
			}
			if (dealSummaryData.summary) {
				setSummaryFields(dealSummaryData.summary);
			}
		}
	}, [dealSummaryStatus, dealSummaryData]);

	const contactData = {
		name: profile?.partialData?.name,
		value: profile?.partialData?.id,
	};
	const companyData = profile?.company?.value
		? {
				name: profile.company.value?.name,
				value: profile.company.value?.id,
			}
		: "";
	const isDealOpen =
		profile?.status?.value === dealState.open &&
		profile?.rottingDays?.value;
	const isDealWon = profile?.status?.value === dealState.won;
	const isDealLost = profile?.status?.value === dealState.lost;

	const toggleFilterTimelineMenu = (timelineFilterId) => {
		if (timelineIdsToFilter.includes(timelineFilterId)) {
			setTimelineIdsToFilter((timelineIdsToFilter) =>
				timelineIdsToFilter.filter((filterId) => {
					return filterId !== timelineFilterId;
				})
			);
		} else {
			setTimelineIdsToFilter([...timelineIdsToFilter, timelineFilterId]);
		}
	};

	const toggleFilterActivitiesMenu = (activityTypeId) => {
		if (activityTypeIdsToFilter.includes(activityTypeId)) {
			setActivityTypeIdsToFilter((activityTypeIdsToFilter) =>
				activityTypeIdsToFilter.filter((id) => {
					return id !== activityTypeId;
				})
			);
		} else {
			setActivityTypeIdsToFilter([
				...activityTypeIdsToFilter,
				activityTypeId,
			]);
		}
	};

	const toggleAddActivityDialog = () => {
		setOpenAddActivityDialog(
			(openAddCompanyDialog) => !openAddCompanyDialog
		);
	};

	const handleViewToggleButtonGroup = (_, type) => {
		if (type) {
			setView(type);
		}
	};

	const openFilterTimelineMenu = (event) => {
		setFilterTimelineMenuElement(event.currentTarget);
	};

	const closeFilterTimelineMenu = () => {
		setFilterTimelineMenuElement(null);
	};

	const openFilterActivitiesMenu = (event) => {
		setFilterActivitiesMenuElement(event.currentTarget);
	};

	const closeFilterActivitiesMenu = () => {
		setFilterActivitiesMenuElement(null);
	};

	const toggleEditFormDialog = () => {
		setIsEditFormDialogOpen((openEditFormDialog) => !openEditFormDialog);
	};

	const handleClearTimelineFilter = () => {
		setTimelineIdsToFilter([]);
		closeFilterTimelineMenu();
	};

	const handleClearActivityFilter = () => {
		setActivityTypeIdsToFilter([]);
		closeFilterActivitiesMenu();
	};

	const navigateToContactDetails = () => {
		const contactId = profile?.primaryContact?.value?.id;
		navigate(`/contacts/${contactId}`);
	};
	const navigateToCompanyDetails = (companyName) => {
		if (companyName) {
			navigate(`/companies/${companyData?.value}`);
		}
		openProfileEditMenu();
	};

	const navigateAllDealPage = () => {
		const allDealPagePath = location?.state?.prevPath
			? location.state.prevPath
			: "/deals";
		navigate(allDealPagePath);
	};
	const openProfileEditMenu = () => {
		if (hasEditPermission) {
			setProfileMenuElement(profileContainerRef.current);
		}
	};

	const closeProfileEditMenu = () => {
		setProfileMenuElement(null);
	};

	const updateSummaryField = (summaryData, onClose) => {
		updateMutation.mutate(summaryData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const openAddFileDialog = () => {
		setIsAddFileDialogOpened(true);
	};

	const closeAddFileDialog = () => {
		setIsAddFileDialogOpened(false);
	};

	const [isOpenManageFollowersDrawer, setIsOpenManageFollowersDrawer] =
		useState(false);

	const openManageFollowersDrawer = () => {
		setIsOpenManageFollowersDrawer(true);
	};

	const closeManageFollowersDrawer = () => {
		setIsOpenManageFollowersDrawer(false);
	};
	const toggleHideEmptyFields = () => {
		setHideEmptyFields((hideEmptyFields) => !hideEmptyFields);
	};

	return (
		<React.Fragment>
			{/* profile edit menu */}
			<Menu
				open={isProfileEditMenuOpened}
				onClose={closeProfileEditMenu}
				anchorEl={profileMenuElement}
			>
				<DealSummaryProfileEdit
					profile={profile}
					dealId={parsedDealId}
					onClose={closeProfileEditMenu}
					subFields={summarySubFields}
				/>
			</Menu>

			{/* Add file */}
			<AddFile
				isAddFileDialogOpened={isAddFileDialogOpened}
				onCloseAddFileDialog={closeAddFileDialog}
				sourceName={modules.DEAL}
				entityId={dealId}
			/>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditFormDialogOpen}
				onOpen={toggleEditFormDialog}
				onClose={toggleEditFormDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditDeal dealId={dealId} onClose={toggleEditFormDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddActivityDialog}
				onOpen={toggleAddActivityDialog}
				onClose={toggleAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={toggleAddActivityDialog}
						dealData={{
							name: profile?.title?.value,
							value: parsedDealId,
						}}
						companyValue={companyData}
						contacts={[contactData]}
						toDoActivityKey={dealToDoActivityKey}
						completedActivityKey={dealCompletedActivityKey}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isOpenManageFollowersDrawer}
				onOpen={openManageFollowersDrawer}
				onClose={closeManageFollowersDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					sx={{
						width: "50vw",
						minHeight: "100vh",
						backgroundColor: "#fff",
						borderRadius: "10px 0px 0px 10px",
					}}
				>
					<ManageFollowers
						onClose={closeManageFollowersDrawer}
						sourceName={modules.DEAL}
						entityId={parsedDealId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				minWidth="200px"
				anchorEl={filterTimelineMenuElement}
				open={isTimelineFilterMenuOpened}
				onClose={closeFilterTimelineMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{timelineFilterList.map((timelineFilterData) => (
					<MenuItem
						key={timelineFilterData.id}
						onClick={() =>
							toggleFilterTimelineMenu(timelineFilterData.id)
						}
						style={{ height: "40px" }}
					>
						<Stack
							direction="row"
							spacing={1.5}
							alignItems="center"
						>
							<Checkbox
								checked={timelineIdsToFilter.includes(
									timelineFilterData.id
								)}
							/>

							<MenuItemText>
								{timelineFilterData.name}
							</MenuItemText>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<Menu
				minWidth="200px"
				anchorEl={filterActivitiesMenuElement}
				open={isActivitiesFilterMenuOpened}
				onClose={closeFilterActivitiesMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{activityTypeList?.map((activityType) => (
					<MenuItem
						key={activityType.id}
						onClick={() =>
							toggleFilterActivitiesMenu(activityType.id)
						}
						style={{ height: "40px" }}
					>
						<Stack
							direction="row"
							spacing={1.5}
							alignItems="center"
						>
							<Checkbox
								checked={activityTypeIdsToFilter.includes(
									activityType?.id
								)}
							/>

							<MenuItemText>{activityType.name}</MenuItemText>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			{isDealSummaryLoading ? (
				<DealSummaryLoader moduleName={`All ${viewName}`} />
			) : (
				<Box>
					<Box className={classes.menuBar}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							height="100%"
						>
							<Stack
								direction="row"
								spacing={1.5}
								alignItems="center"
							>
								<Box
									style={{ cursor: "pointer" }}
									onClick={navigateAllDealPage}
								>
									<Typography
										fontWeight={600}
										fontSize={15}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{`All ${viewName}`}
									</Typography>
								</Box>

								<Box
									style={{
										transform: "rotate(-90deg)",
									}}
								>
									{DropDownIcon(16, 16)}
								</Box>

								<Typography
									fontSize={15}
									style={{
										fontWeight: 500,
										maxWidth: "650px",
									}}
									noWrap
								>
									{profile?.title?.value}
								</Typography>
							</Stack>

							<SummaryMenu
								dealData={{
									title: profile?.title?.value,
									id: parsedDealId,
								}}
								toggleEditFormDialog={toggleEditFormDialog}
								contactId={contactData?.value}
								dealStatus={profile?.status?.value}
							/>
						</Stack>
					</Box>

					<Box
						px={3}
						onMouseOver={onMouseOverProfile}
						onMouseLeave={onMouseOutProfile}
					>
						<Stack
							direction="row"
							alignItems="flex-start"
							spacing={3}
						>
							<Box
								sx={{
									backgroundColor: hexToRgba(
										theme.palette.primary.main,
										0.12
									),
									borderRadius: "8px",
									px: 2,
									py: 1,
								}}
							>
								<Typography
									fontSize={17}
									fontWeight={500}
									color={theme.palette.secondary.contrastText}
									style={{ cursor: "pointer" }}
								>
									{profile.dealValue?.value?.CURRENCY?.symbol}{" "}
									{profile.dealValue?.value?.PRICE?.value}
								</Typography>
							</Box>

							<Stack spacing={1}>
								<Stack
									direction="row"
									spacing={1}
									alignItems="center"
								>
									<TooltipComponent
										title={profile?.title?.value}
										placement="top"
										width="600px"
										verticalDisplacement={-3}
									>
										<Typography
											style={{
												fontSize: 17,
												fontWeight: 600,
												cursor: "pointer",
												maxWidth: "1000px",
											}}
											noWrap
										>
											{profile?.title?.value}
										</Typography>
									</TooltipComponent>

									{isDealOpen ? (
										<Box
											sx={{
												backgroundColor: hexToRgba(
													theme.palette.error.main,
													0.1
												),
												padding: "4px",
												borderRadius: "6px",
											}}
										>
											<Typography
												fontWeight={500}
												fontSize={14}
												color={theme.palette.error.main}
											>
												{`Rottings for ${profile.rottingDays.value} Days`}
											</Typography>
										</Box>
									) : null}

									{isDealWon ? (
										<Stack
											direction="row"
											spacing={0.6}
											alignItems="center"
											sx={{
												backgroundColor:
													theme.palette.primary.main,
												padding: "4px",
												borderRadius: "6px",
											}}
										>
											<Box display="flex">
												{ThumbsUpIcon(16, 16, "#fff")}
											</Box>

											<Typography
												fontWeight={500}
												fontSize={14}
												color="#fff"
											>
												Won
											</Typography>
										</Stack>
									) : null}

									{isDealLost ? (
										<Stack
											direction="row"
											spacing={0.6}
											alignItems="center"
											sx={{
												backgroundColor:
													theme.palette.error.main,
												padding: "4px",
												borderRadius: "6px",
											}}
										>
											<Box display="flex">
												{ThumbsDownIcon(16, 16, "#fff")}
											</Box>

											<Typography
												fontWeight={500}
												fontSize={14}
												color="#fff"
											>
												Lost
											</Typography>
										</Stack>
									) : null}

									<Box display="flex" width="22px">
										{isProfileEditIconVisible &&
										hasEditPermission ? (
											<IconButton
												style={{ padding: 0 }}
												onClick={openProfileEditMenu}
											>
												{EditIcon(
													19,
													19,
													theme.palette.primary.main
												)}
											</IconButton>
										) : null}
									</Box>
								</Stack>

								<Stack
									ref={profileContainerRef}
									direction="row"
									spacing={2}
									color={theme.palette.secondary.contrastText}
								>
									<Can permission={PERMISSIONS.company.view}>
										<Stack
											direction="row"
											alignItems="center"
											spacing={0.5}
											style={{ cursor: "pointer" }}
											onClick={() =>
												navigateToCompanyDetails(
													profile?.company?.value
												)
											}
										>
											{CompanyIcon(
												16,
												16,
												theme.palette.primary.main
											)}

											<TooltipComponent
												title={
													profile?.company?.value
														?.name
												}
												verticalDisplacement={-3}
												width="600px"
												placement="top"
											>
												<Typography
													style={{
														fontSize: 14,
														fontWeight: 500,
														maxWidth: "500px",
													}}
													noWrap
												>
													{profile?.company?.value
														? profile?.company
																?.value?.name
														: "Add Company"}
												</Typography>
											</TooltipComponent>
										</Stack>
									</Can>

									<Can permission={PERMISSIONS.contact.view}>
										<Stack
											direction="row"
											alignItems="center"
											spacing={0.5}
											style={{ cursor: "pointer" }}
											onClick={navigateToContactDetails}
										>
											{SingleContactIcon(
												16,
												16,
												theme.palette.primary.main
											)}

											<TooltipComponent
												title={
													profile?.primaryContact
														?.value?.fullName
												}
												verticalDisplacement={-3}
												width="600px"
												placement="top"
											>
												<Typography
													style={{
														fontSize: 14,
														fontWeight: 500,
														maxWidth: "500px",
													}}
													noWrap
												>
													{profile?.primaryContact
														?.value
														? profile
																?.primaryContact
																?.value
																?.fullName
														: null}
												</Typography>
											</TooltipComponent>
										</Stack>
									</Can>
								</Stack>
							</Stack>
						</Stack>
					</Box>

					<Box pt={2} px={1}>
						<DealStagesTimeline
							dealId={parsedDealId}
							ref={DealsStagesTimelineRef}
						/>
					</Box>

					<FieldPermissionContextProvider
						value={dealFieldPermissions}
						source={modules.DEAL}
					>
						<SummaryContextProvider
							hasEditPermission={hasEditPermission}
							entityId={parsedDealId}
							source={modules.DEAL}
							isSaving={updateMutation.isLoading}
							updateSummaryField={updateSummaryField}
						>
							<SummaryCardView
								hideEmptyFields={hideEmptyFields}
								toggleHideEmptyFields={toggleHideEmptyFields}
								summaryFields={summaryFields}
								summarySubFields={summarySubFields}
							/>
						</SummaryContextProvider>
					</FieldPermissionContextProvider>

					<Box px={2}>
						<Divider />
					</Box>

					<Box p={1}>
						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="stretch"
							spacing={1}
						>
							<Grid item xs={12} md={9}>
								<Box className={classes.detailsContainer}>
									<Stack direction="row" spacing={3}>
										<ToggleButtonGroup
											value={view}
											onChange={
												handleViewToggleButtonGroup
											}
										>
											<ToggleButton
												value={summaryViews.overview}
											>
												Overview
											</ToggleButton>
											<ToggleButton
												value={summaryViews.timeline}
											>
												Timeline
											</ToggleButton>
											<ToggleButton
												value={
													summaryViews.conversations
												}
											>
												Conversations
											</ToggleButton>
											<ToggleButton
												value={summaryViews.activities}
											>
												Activities
											</ToggleButton>
											<ToggleButton
												value={summaryViews.notes}
											>
												Notes
											</ToggleButton>
											<ToggleButton
												value={summaryViews.files}
											>
												Files
											</ToggleButton>
											{showFollowers ? (
												<ToggleButton
													value={
														summaryViews.followers
													}
												>
													Followers
												</ToggleButton>
											) : null}
										</ToggleButtonGroup>

										{view === summaryViews.timeline && (
											<ButtonGroup
												variant="contained"
												disableElevation
												color="secondary"
												sx={{
													"& .MuiButtonGroup-grouped:not(:last-child)":
														{
															borderRight: "none",
															borderRadius:
																!selectedTimelineFilterCount >
																0
																	? "8px"
																	: "8px 0px 0px 8px",
														},
												}}
											>
												<Button
													onClick={
														openFilterTimelineMenu
													}
													endIcon={DropDownIcon(
														16,
														16,
														theme.palette.secondary
															.contrastText
													)}
												>
													Filter Timeline{" "}
													{selectedTimelineFilterCount >
													0
														? `(${selectedTimelineFilterCount})`
														: ""}
												</Button>

												<Divider
													orientation="vertical"
													sx={{
														display:
															!selectedTimelineFilterCount >
																0 && "none",
														height: "95%",
													}}
												/>

												<Button
													onClick={
														handleClearTimelineFilter
													}
													sx={{
														display:
															!selectedTimelineFilterCount >
																0 && "none",
													}}
												>
													{CloseIcon(
														19,
														19,
														theme.palette.secondary
															.contrastText
													)}
												</Button>
											</ButtonGroup>
										)}

										{view === summaryViews.activities && (
											<Stack direction="row" spacing={2}>
												<Button
													variant="contained"
													startIcon={AddIcon(
														16,
														16,
														theme.palette.secondary
															.contrastText
													)}
													color="secondary"
													onClick={
														toggleAddActivityDialog
													}
													disableElevation
												>
													Activity
												</Button>

												<ButtonGroup
													variant="contained"
													disableElevation
													color="secondary"
													sx={{
														"& .MuiButtonGroup-grouped:not(:last-child)":
															{
																borderRight:
																	"none",
																borderRadius:
																	selectedActivityFilterCount >
																	0
																		? "8px 0px 0px 8px"
																		: "8px",
															},
													}}
												>
													<Button
														onClick={
															openFilterActivitiesMenu
														}
														endIcon={DropDownIcon(
															16,
															16,
															theme.palette
																.secondary
																.contrastText
														)}
													>
														Filter Activities{" "}
														{selectedActivityFilterCount >
														0
															? `(${selectedActivityFilterCount})`
															: ""}
													</Button>

													<Divider
														orientation="vertical"
														sx={{
															display:
																!selectedActivityFilterCount >
																	0 && "none",
															height: "95%",
														}}
													/>

													<Button
														onClick={
															handleClearActivityFilter
														}
														sx={{
															display:
																!selectedActivityFilterCount >
																	0 && "none",
															padding: 0,
														}}
													>
														{CloseIcon(
															19,
															19,
															theme.palette
																.secondary
																.contrastText
														)}
													</Button>
												</ButtonGroup>
											</Stack>
										)}

										{view === summaryViews.files ? (
											<Button
												variant="contained"
												startIcon={AddIcon(
													16,
													16,
													theme.palette.secondary
														.contrastText
												)}
												color="secondary"
												onClick={openAddFileDialog}
												disableElevation
											>
												File
											</Button>
										) : null}

										{view === summaryViews.followers &&
										followerList?.length > 0 ? (
											<Button
												variant="contained"
												startIcon={Settings(
													16,
													16,
													theme.palette.secondary
														.contrastText
												)}
												color="secondary"
												disableElevation
												onClick={
													openManageFollowersDrawer
												}
											>
												Manage Followers
											</Button>
										) : null}
									</Stack>

									<Box pt={1} mx={-1}>
										{(function () {
											switch (view) {
												case summaryViews.overview:
													return (
														<Overview
															dealData={{
																title: profile
																	?.title
																	?.value,
																id: parsedDealId,
															}}
														/>
													);
												case summaryViews.timeline:
													return (
														<DealTimeline
															dealId={
																parsedDealId
															}
															timelineIdsToFilter={
																timelineIdsToFilter
															}
														/>
													);
												case summaryViews.conversations:
													return (
														<DealConversations
															dealId={
																parsedDealId
															}
														/>
													);
												case summaryViews.activities:
													return (
														<DealActivities
															dealId={
																parsedDealId
															}
															activityTypeIdsToFilter={
																activityTypeIdsToFilter
															}
														/>
													);
												case summaryViews.notes:
													return (
														<Notes
															sourceName={
																modules.DEAL
															}
															entityId={dealId}
														/>
													);
												case summaryViews.files:
													return (
														<Files
															sourceName={
																modules.DEAL
															}
															entityId={dealId}
														/>
													);
												case summaryViews.followers:
													return (
														<Followers
															sourceName={
																modules.DEAL
															}
															entityId={
																parsedDealId
															}
														/>
													);
												default:
													return null;
											}
										})()}
									</Box>
								</Box>
							</Grid>

							<Grid item xs={12} md={3}>
								<Stack spacing={1}>
									<Box className={classes.detailsContainer}>
										<DealNotes dealId={parsedDealId} />
									</Box>

									<Box className={classes.detailsContainer}>
										<DealFiles dealId={parsedDealId} />
									</Box>

									<Can permission={PERMISSIONS.contact.view}>
										<Box
											className={classes.detailsContainer}
										>
											<DealRelatedContacts
												dealId={parsedDealId}
											/>
										</Box>
									</Can>
								</Stack>
							</Grid>
						</Grid>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}

import React, { useEffect, useRef } from "react";
import ProductMenu from "../ProductMenu";
import { default as CloseIcon } from "../../../../assets/icons/close";
import {
	IconButton,
	TableCell,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useProductPriceTagsDetails } from "../../../../hooks/services/product";

export default function ProductTableRow(props) {
	const {
		currencyValue,
		isTaxInclusive,
		productData,
		selectedProductIds,
		handleProductData,
		onProductDetailsChange,
		onProductMenuInputChange,
		handleRemoveProduct,
		isRemoveActionEnabled,
		handleProductTableCell,
		isTableCellSelected,
		onSelectNewProduct,
		isNewProductsAllowed,
	} = props;

	const theme = useTheme();
	const onlyOnceRef = useRef(true);

	const priceTagsEnabled = Boolean(
		productData?.product?.value && currencyValue
	);

	const { data: priceTagsData } = useProductPriceTagsDetails(
		productData?.product?.value,
		currencyValue?.id,
		isTaxInclusive,
		priceTagsEnabled
	);

	useEffect(() => {
		let formattedPriceData = {
			price: 0,
			quantity: 1,
			tax: 0,
			discount: 0,
		};

		if (
			priceTagsData &&
			Object.keys(priceTagsData)?.length > 0 &&
			onlyOnceRef.current
		) {
			formattedPriceData = { ...formattedPriceData, ...priceTagsData };
			handleProductData(formattedPriceData);
			onlyOnceRef.current = false;
		}
	}, [priceTagsData, handleProductData]);

	const styles = {
		editableTableCell: {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
		},
		tableCell: {
			fontSize: 14,
			fontWeight: 500,
			opacity: "0.6",
		},
		inputText: {
			fontSize: 14,
		},
	};

	const getProductAmount = () => {
		let price = productData?.price;
		let discount = productData?.discount;
		let quantity = productData?.quantity;
		let amount = (price - price * (discount / 100)) * quantity;
		return amount;
	};

	return (
		<React.Fragment>
			<TableRow>
				<TableCell
					id="item"
					onClick={(event) => handleProductTableCell(event)}
					sx={{
						py: "0px",
						borderBottom:
							isTableCellSelected("item") &&
							styles.editableTableCell,
					}}
				>
					<ProductMenu
						value={productData?.product}
						selectedProductIds={selectedProductIds}
						onProductInputChange={onProductMenuInputChange}
						isNewProductsAllowed={isNewProductsAllowed}
						onSelectNewProduct={onSelectNewProduct}
					/>
				</TableCell>

				<TableCell
					id="price"
					onClick={(event) => handleProductTableCell(event)}
					sx={{
						py: "0px",
						borderBottom:
							isTableCellSelected("price") &&
							styles.editableTableCell,
					}}
				>
					<TextField
						name="price"
						variant="standard"
						value={productData?.price}
						onChange={onProductDetailsChange}
						placeholder="0"
						InputProps={{ disableUnderline: true }}
						inputProps={{
							style: {
								textAlign: "right",
								...styles.inputText,
							},
						}}
					/>
				</TableCell>

				<TableCell
					id="quantity"
					onClick={(event) => handleProductTableCell(event)}
					sx={{
						py: "0px",
						borderBottom:
							isTableCellSelected("quantity") &&
							styles.editableTableCell,
					}}
				>
					<TextField
						name="quantity"
						variant="standard"
						value={productData?.quantity}
						onChange={onProductDetailsChange}
						placeholder="0"
						InputProps={{ disableUnderline: true }}
						inputProps={{
							style: {
								textAlign: "right",
								...styles.inputText,
							},
						}}
					/>
				</TableCell>

				{isTaxInclusive && (
					<TableCell
						id="tax"
						onClick={(event) => handleProductTableCell(event)}
						sx={{
							py: "0px",
							borderBottom:
								isTableCellSelected("tax") &&
								styles.editableTableCell,
						}}
					>
						<TextField
							name="tax"
							variant="standard"
							value={productData?.tax}
							onChange={onProductDetailsChange}
							placeholder="0%"
							InputProps={{
								disableUnderline: true,
								endAdornment:
									productData?.tax === 0 ||
									productData?.tax ? (
										<Typography style={styles.inputText}>
											%
										</Typography>
									) : null,
							}}
							inputProps={{
								style: {
									textAlign: "right",
									...styles.inputText,
								},
							}}
						/>
					</TableCell>
				)}

				<TableCell
					id="discount"
					onClick={(event) => handleProductTableCell(event)}
					sx={{
						py: "0px",
						borderBottom:
							isTableCellSelected("discount") &&
							styles.editableTableCell,
					}}
				>
					<TextField
						name="discount"
						variant="standard"
						value={productData?.discount}
						onChange={onProductDetailsChange}
						placeholder="0%"
						InputProps={{
							disableUnderline: true,
							endAdornment:
								productData?.discount === 0 ||
								productData?.discount ? (
									<Typography style={styles.inputText}>
										%
									</Typography>
								) : null,
						}}
						inputProps={{
							style: {
								textAlign: "right",
								...styles.inputText,
							},
						}}
					/>
				</TableCell>

				<TableCell align="right">
					<Typography style={styles.tableCell}>
						{currencyValue?.symbol}
						{getProductAmount()}
					</Typography>
				</TableCell>

				<TableCell align="right">
					{isRemoveActionEnabled ? (
						<IconButton
							onClick={handleRemoveProduct}
							style={{ padding: 0 }}
						>
							{CloseIcon(18, 18, "#000")}
						</IconButton>
					) : null}
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import {
	Box,
	Button,
	Divider,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import DropDownRight from "../../../../assets/icons/dropDownRight";
import { useNavigate } from "react-router-dom";
import { twozoStyles } from "../../../../styles/twozo";
import { default as AddIcon } from "../../../../assets/icons/add";
import ToggleButtonGroup from "../../../Elements/ToggleButtonGroup";
import AddEditAcitivityDialog from "./AddEditAcitivityDialog";
import ActivityTypeList from "./ActivityTypeList";
import Can from "../../../Auth/Can";
import { PERMISSIONS } from "../../../../utils/Auth";
import { useAuth } from "../../../../hooks/auth";

export default function ActivitiesSetting() {
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();

	const { isUserAllowedFor } = useAuth();

	const activityTypeStatus = {
		enabled: "enable",
		disabled: "disable",
	};

	const [view, setView] = useState(activityTypeStatus.enabled);
	const [openAddEditActivityTypeDialog, setOpenAddEditActivityTypeDialog] =
		useState(false);
	// true indicates it is add activity, false indicates it is edit activity
	const [isAddActivityMode, setIsAddActivityMode] = useState(true);
	const [activityTypeDataToEdit, setActivityTypeDataToEdit] = useState({});

	const handleOpenAddActivityTypeDialog = () => {
		setIsAddActivityMode(true);
		handleOpenAddEditActivityTypeDialog();
	};

	const handleOpenEditActivityTypeDialog = (activityTypeToEdit) => {
		if (isUserAllowedFor(PERMISSIONS.activityType.edit)) {
			setIsAddActivityMode(false);
			setActivityTypeDataToEdit(activityTypeToEdit);
			handleOpenAddEditActivityTypeDialog();
		}
	};

	const handleOpenAddEditActivityTypeDialog = () => {
		setOpenAddEditActivityTypeDialog(true);
	};

	const handleCloseAddEditActivityTypeDialog = () => {
		setOpenAddEditActivityTypeDialog(false);
	};

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setView(type);
		}
	};

	return (
		<React.Fragment>
			<AddEditAcitivityDialog
				openAddEditActivityTypeDialog={openAddEditActivityTypeDialog}
				handleCloseAddEditActivityTypeDialog={
					handleCloseAddEditActivityTypeDialog
				}
				isAddActivityMode={isAddActivityMode}
				activityTypeData={activityTypeDataToEdit}
			/>

			<Box>
				<Box className={classes.menuBar}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						height="100%"
					>
						<Stack
							height="100%"
							direction="row"
							alignItems="center"
							spacing={1}
						>
							<Box
								style={{ cursor: "pointer" }}
								onClick={() => navigate("/settings")}
							>
								<Typography
									fontWeight={500}
									fontSize={15}
									color={theme.palette.secondary.contrastText}
								>
									Admin Settings
								</Typography>
							</Box>

							{DropDownRight(16, 16)}

							<Typography fontWeight={500} fontSize={15}>
								Activities
							</Typography>
						</Stack>

						<Can permission={PERMISSIONS.activityType.create}>
							<Button
								variant="contained"
								startIcon={AddIcon(20, 20, "#fff")}
								onClick={handleOpenAddActivityTypeDialog}
								disableElevation
							>
								Activity Type
							</Button>
						</Can>
					</Stack>
				</Box>

				<Box px={3} pb={2}>
					<Typography fontSize={17} fontWeight={600}>
						Activity Types
					</Typography>

					<Typography
						fontSize={14}
						pt="5px"
						color="rgba(0, 0, 0, 0.6)"
					>
						Customize which type of activities are used in your
						company.
					</Typography>
				</Box>

				<Divider />

				<Box p={2}>
					<Stack direction="row" spacing={2}>
						<ToggleButtonGroup
							value={view}
							onChange={handleToggleButtonGroup}
						>
							<ToggleButton value={activityTypeStatus.enabled}>
								Enable
							</ToggleButton>
							<ToggleButton value={activityTypeStatus.disabled}>
								Disabled
							</ToggleButton>
						</ToggleButtonGroup>
					</Stack>

					{(function () {
						switch (view) {
							case activityTypeStatus.enabled:
								return (
									<ActivityTypeList
										handleOpenEditActivityTypeDialog={
											handleOpenEditActivityTypeDialog
										}
										isActive={true}
									/>
								);
							case activityTypeStatus.disabled:
								return (
									<ActivityTypeList
										handleOpenEditActivityTypeDialog={
											handleOpenEditActivityTypeDialog
										}
										isActive={false}
									/>
								);
							default:
								break;
						}
					})()}
				</Box>
			</Box>
		</React.Fragment>
	);
}

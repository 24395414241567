import React from "react";
import { Box, DialogContent, DialogTitle, Typography } from "@mui/material";
import { CustomisedDialog } from "../../../styles/twozo";

export default function UserManagementDialog(props) {
	const { title, subtitle, children, onClose, ...other } = props;

	return (
		<React.Fragment>
			<CustomisedDialog {...other}>
				{title ? (
					<DialogTitle>
						<Box p={3}>
							<Typography fontWeight={600} fontSize={15}>
								{title}
							</Typography>

							<Typography fontSize={14} style={{ opacity: 0.6 }}>
								{subtitle}
							</Typography>
						</Box>
					</DialogTitle>
				) : null}

				<DialogContent>
					{React.cloneElement(children, { onClose })}
				</DialogContent>
			</CustomisedDialog>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import Menu from "../../../../../Elements/Menu";
import UpsertDashboardDetails from "../../../../UpsertDashboardDetails";
import { useDashboardBuilderContext } from "../../Context";
import { default as EditIcon } from "../../../../../../assets/icons/edit";

export default function DashboardName(props) {
	const { name } = props;
	const theme = useTheme();
	const {
		dashboardName,
		dashboardDescription,
		setDashboardName,
		setDashboardDescription,
		setIsDashboardDirty,
	} = useDashboardBuilderContext();
	const [placeHolderMenuElement, setPlaceHolderMenuElement] = useState(null);
	const isPlaceHolderMenuOpened = Boolean(placeHolderMenuElement);
	const [dashboardNameHovered, setDashboardNameHovered] = useState(false);

	const openPlaceHolderMenu = (event) => {
		setPlaceHolderMenuElement(event.currentTarget);
	};

	const closePlaceHolderMenu = () => {
		setPlaceHolderMenuElement(null);
	};

	const updateDashboardDetails = (data) => {
		setDashboardName(data.dashboardName);
		setDashboardDescription(data.description);
		setIsDashboardDirty(true);
		closePlaceHolderMenu();
	};

	return (
		<React.Fragment>
			<Menu
				width="400px"
				anchorEl={placeHolderMenuElement}
				open={isPlaceHolderMenuOpened}
				onClose={closePlaceHolderMenu}
				style={{ marginTop: "8px" }}
			>
				<UpsertDashboardDetails
					submitButtonLabel="Save"
					defaultValues={{
						dashboardName: dashboardName,
						description: dashboardDescription || "",
					}}
					onSubmit={updateDashboardDetails}
					onCancel={closePlaceHolderMenu}
				/>
			</Menu>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="center"
				onClick={openPlaceHolderMenu}
				onMouseEnter={() => setDashboardNameHovered(true)}
				onMouseLeave={() => setDashboardNameHovered(false)}
				style={{
					cursor: "pointer",
				}}
				spacing={0.5}
			>
				<Typography fontWeight={500} fontSize={15}>
					{name}
				</Typography>

				{dashboardNameHovered &&
					EditIcon(18, 18, theme.palette.primary.main)}
			</Stack>
		</React.Fragment>
	);
}

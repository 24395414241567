import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	Stack,
	Typography,
} from "@mui/material";
import TopActivitiesProgressBar from "./TopActivitiesProgressBar";
import MostActiveUsersProgressBar from "./MostActiveUsersProgressBar";
import { useQuery } from "@tanstack/react-query";
import { getDealOverViewKey } from "../../../../utils/queryKeys";
import { getDealOverviewData } from "../../../../api/deal/dealApi";
import {
	getDealCompletedActivityKey,
	getDealToDoActivityKey,
} from "../../../../utils/queryKeys";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import { twozoStyles } from "../../../../styles/twozo";
import AddActivity from "../../../Summary/Activities/AddActivity";

export default function Overview(props) {
	const { dealData } = props;
	const classes = twozoStyles();

	const [dealOverView, setDealOverView] = useState({});
	const [isAddActivityDialogOpen, setIsAddActivityDialogOpen] =
		useState(false);

	const dealOverViewKey = getDealOverViewKey(dealData?.id);
	const {
		data: dealOverViewData,
		isLoading: isLoadingDealOverViewData,
		status: dealOverViewStatus,
	} = useQuery(dealOverViewKey, () => getDealOverviewData(dealData?.id), {
		staleTime: 30000,
	});

	useEffect(() => {
		if (dealOverViewStatus === "success") {
			if (dealOverViewData) {
				setDealOverView(dealOverViewData);
			}
		}
	}, [dealOverViewData, dealOverViewStatus]);

	const toggleAddActivityDialog = () => {
		setIsAddActivityDialogOpen(
			(isAddActivityDialogOpen) => !isAddActivityDialogOpen
		);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddActivityDialogOpen}
				onOpen={toggleAddActivityDialog}
				onClose={toggleAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={toggleAddActivityDialog}
						dealData={{
							name: dealData?.title,
							value: dealData?.id,
						}}
						toDoActivityKey={getDealToDoActivityKey}
						completedActivityKey={getDealCompletedActivityKey}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Box p={2}>
				<Box pb={3}>
					<Typography fontSize={14}>Deal Age</Typography>

					<Stack direction="row" alignItems="center" spacing={1.5}>
						<Typography fontSize={18} fontWeight={500}>
							{dealOverView?.dealAge?.age} Days
						</Typography>

						<Typography
							fontSize={14}
							style={{ color: "rgba(0, 0, 0, 0.6)" }}
						>
							&#8226;
						</Typography>

						<Typography
							fontSize={14}
							style={{ color: "rgba(0, 0, 0, 0.6)" }}
							py={0.2}
						>
							{dealOverView?.dealAge?.inactiveDays} Inactive days
						</Typography>
					</Stack>
				</Box>

				<Divider />

				{isLoadingDealOverViewData ? (
					<Stack
						justifyContent="center"
						alignItems="center"
						height="30vh"
					>
						<CircularProgress size="35px" />
					</Stack>
				) : !!dealOverView?.activityTypes &&
				  dealOverView?.activityTypes.length > 0 ? (
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="flex-start"
						py={3}
					>
						<Grid item xs={12} md={6}>
							<Stack
								spacing={1.5}
								pr={4}
								style={{
									borderRight: "1px solid rgba(0, 0, 0, 0.1)",
								}}
							>
								<Typography fontSize={14}>
									Top Activities
								</Typography>

								<TopActivitiesProgressBar
									topActivities={dealOverView?.activityTypes}
								/>
							</Stack>
						</Grid>

						<Grid item xs={12} md={6}>
							<Stack spacing={1.5} pl={4}>
								<Typography fontSize={14}>
									Most Active Users
								</Typography>

								<MostActiveUsersProgressBar
									mostActiveUsers={
										dealOverView?.mostActiveUsers
									}
								/>
							</Stack>
						</Grid>
					</Grid>
				) : (
					<Box pt={10}>
						<Stack
							spacing={1}
							justifyContent="center"
							alignItems="center"
						>
							<Typography fontSize={14} fontWeight={400}>
								No activities have been created yet.
							</Typography>

							<Box display="flex">
								<Button
									onClick={() => toggleAddActivityDialog()}
								>
									+ Add Activity
								</Button>
							</Box>
						</Stack>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import {
	Box,
	Checkbox,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { default as AddIcon } from "../../../../../../.././assets/icons/add";
import { default as DragIcon } from "../../../../../../.././assets/icons/drag";
import { default as CloseIcon } from "../../../../../../.././assets/icons/close";
import { default as TickCircle } from "../../../../../../../assets/icons/tickCircle";
import { default as RoundCheckBoxOffIcon } from "../../../../../../../assets/icons/roundCheckBoxOff";
import { default as DropDownIcon } from "../../../../../.././../assets/icons/dropDown";
import { Tooltip } from "../../../../../../.././styles/twozo";
import Menu from "../../../../../.././Elements/Menu";
import { useSelectReducer } from "../../../../../../Settings/DataFields/Field/FieldContext/SelectReducer";
import DragDropContextWithNonce from "../../../../../../Elements/DragDropContextWithNonce";

export default function DropdownField(props) {
	const { dropdownChoices, updateDropdownChoices } = props;

	// select reducer
	const {
		selectState,
		onCreateSelectChoices,
		onResetSelectChoices,
		onChoiceCreationError,
		onDuplicateChoiceError,
		errorOnCharactersLimitExceeded,
	} = useSelectReducer();

	const theme = useTheme();
	const [dropdownChoiceHovered, setDropdownChoiceHovered] = useState(null);
	const [editableChoiceIndex, setEditableChoiceIndex] = useState(null);
	const [isChoiceTooltipOpened, setIsChoiceTooltipOpened] = useState(false);
	const [hoveredChoiceIndex, setHoveredChoiceIndex] = useState(null);
	const [isNewOptionCreated, setIsNewOptionCreated] = useState(false);
	const [selectedChoiceId, setSelectedChoiceId] = useState(null);

	const hasError =
		selectState.hasCreationError ||
		selectState.hasDuplicateError ||
		selectState.isLimitExceeded;
	const defaultChoices = 2;

	const [dropdownChoicesElement, setDropdownChoicesElement] = useState(null);
	const openDropdownChoicesMenu = Boolean(dropdownChoicesElement);

	const onMouseOverDropdownChoice = (index) => {
		setDropdownChoiceHovered(index);
	};

	const onMouseOutDropdownChoice = () => {
		setDropdownChoiceHovered(null);
	};

	const setDropdownChoiceSelected = (choice, choiceIndex) => {
		if (hasError || !choice?.name?.trim()) {
			return;
		}

		if (choice?.id === selectedChoiceId) {
			setSelectedChoiceId(null);
		} else {
			setSelectedChoiceId(choice.id);
		}
		let updatedDropdownChoices = getUpdatedDropdownChoices(choiceIndex);
		updateDropdownChoices(updatedDropdownChoices);
	};

	const getUpdatedDropdownChoices = (index) => {
		let clonedDropdownChoices = [...dropdownChoices];
		let modifiedDropdownChoices = clonedDropdownChoices.map(
			(dropdown, choiceIndex) => {
				if (dropdown.isDefault) {
					delete dropdown.isDefault;
				} else if (index === choiceIndex) {
					return { ...dropdown, isDefault: true };
				}

				return dropdown;
			}
		);

		return modifiedDropdownChoices;
	};

	const setDropdownChoiceName = (index, value) => {
		const updatedOptionName = dropdownChoices.map((option, optionIndex) => {
			if (optionIndex === index) return { ...option, name: value };
			return option;
		});
		let maxChoiceLength = 126;
		if (value.trim().length > maxChoiceLength) {
			errorOnCharactersLimitExceeded();
		} else {
			setEditableChoiceIndex(index);
			updateDropdownStateOnOptionEdit(value);
			updateDropdownChoices(updatedOptionName);
		}
	};

	const updateDropdownStateOnOptionEdit = (value) => {
		if (value !== "") {
			onResetSelectChoices();
		} else {
			onCreateSelectChoices();
		}
	};

	const addDropdownChoice = () => {
		let modifiedDropdownChoices = [];
		let emptyChoice = {
			name: "",
			id: `id ${dropdownChoices.length}`,
		};

		if (dropdownChoices.length < 1) {
			modifiedDropdownChoices = [
				...dropdownChoices,
				{
					name: "",
					id: "id 0",
				},
				{
					name: "",
					id: "id 1",
				},
			];
		} else {
			modifiedDropdownChoices = [...dropdownChoices, emptyChoice];
		}
		onCreateSelectChoices();
		setIsNewOptionCreated(true);
		updateDropdownChoices(modifiedDropdownChoices);
		setEditableChoiceIndex(dropdownChoices.length);
	};

	const editDropdownChoiceName = (index) => {
		if (hasError || isNewOptionCreated) {
			return;
		}

		if (isDuplicateOption()) {
			onDuplicateChoiceError();
		} else {
			setEditableChoiceIndex(index);
		}
	};

	const removeDropdownChoice = (index) => {
		if (isOptionRemovable(index)) {
			const updatedDropdownChoices = [...dropdownChoices];
			updatedDropdownChoices.splice(index, 1);
			updateDropdownChoices([...updatedDropdownChoices]);
			setIsNewOptionCreated(false);
			onResetSelectChoices();
		}
	};

	const reorderChoice = (choice, startIndex, endIndex) => {
		const result = Array.from(choice);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onChoiceDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const modifiedReorderChoice = reorderChoice(
			dropdownChoices,
			result.source.index,
			result.destination.index
		);
		updateDropdownChoices(modifiedReorderChoice);
	};

	const handleOpenDropdownChoicesMenu = (event) => {
		setDropdownChoicesElement(event.currentTarget);
	};

	const isDuplicateOption = () => {
		const uniqueChoices = new Set();
		for (const choice of dropdownChoices) {
			const choiceName = choice.name.trim().toLowerCase();
			if (choiceName !== "" && uniqueChoices.has(choiceName)) {
				return true;
			}
			uniqueChoices.add(choiceName);
		}

		return false;
	};

	const getFilteredEmptyOptions = () => {
		let filteredEmptyChoices = dropdownChoices.filter((choice) => {
			return choice.name !== "";
		});

		return filteredEmptyChoices;
	};

	const isEmptyOption = () => {
		return getFilteredEmptyOptions()?.length < 0;
	};

	const handleCloseDropdownChoicesMenu = () => {
		setDropdownChoicesElement(null);
		setEditableChoiceIndex(null);
		setIsNewOptionCreated(false);
		onResetSelectChoices();
		updateDropdownChoices(getFilteredEmptyOptions());
	};

	const validateOnChoiceMenuClose = (_, reason) => {
		if (selectState.hasDuplicateError || selectState.isLimitExceeded) {
			return;
		}

		if (reason === "backdropClick") {
			if (isDuplicateOption()) {
				onDuplicateChoiceError();
				return;
			}
			handleCloseDropdownChoicesMenu();
		}
	};

	const validateAndDispatchDropdownState = () => {
		if (isEmptyOption()) {
			onChoiceCreationError();
			return false;
		} else if (isDuplicateOption()) {
			onDuplicateChoiceError();
			return false;
		}

		return true;
	};

	const isDropdownOptionsValid = () => {
		if (hasError) {
			return;
		}

		if (selectState.isCreateMode) {
			onChoiceCreationError();
		} else {
			return validateAndDispatchDropdownState();
		}
	};

	const handleDropdownChoiceValidation = () => {
		if (isDropdownOptionsValid()) {
			addDropdownChoice();
		}
	};

	const hasDropdownChoiceError = (choice) => {
		let defaultChoicesCount = 2;
		let maxChoiceLength = 126;

		if (dropdownChoices.length === defaultChoicesCount && hasError) {
			if (selectState.isLimitExceeded) {
				return choice.name.length >= maxChoiceLength;
			}
			return choice.name === "" || isDuplicateOption();
		}

		return hasError;
	};

	const isOptionRemovable = (choiceIndex) => {
		if (dropdownChoices.length > defaultChoices) {
			if (!hasError && !isNewOptionCreated) {
				return true;
			}

			return editableChoiceIndex === choiceIndex;
		}
	};

	const handleOpenChoiceTooltip = (event, index) => {
		setIsChoiceTooltipOpened(
			event.target.scrollWidth > event.target.clientWidth
		);
		setHoveredChoiceIndex(index);
	};

	return (
		<React.Fragment>
			<Box>
				<Box
					width="100%"
					pl={1}
					pr={0.5}
					py={0.2}
					sx={{
						backgroundColor: (theme) =>
							theme.palette.secondary.main,
						borderRadius: "3px",
					}}
					onClick={handleOpenDropdownChoicesMenu}
				>
					<Stack
						direction="row"
						spacing={0.5}
						style={{ cursor: "pointer" }}
					>
						<Typography fontSize={14}>
							{dropdownChoices.length}{" "}
							{dropdownChoices.length > 1 ? "Choices" : "Choice"}
						</Typography>

						{DropDownIcon(
							18,
							18,
							theme.palette.secondary.contrastText
						)}
					</Stack>
				</Box>

				<Menu
					minWidth="260px"
					anchorEl={dropdownChoicesElement}
					open={openDropdownChoicesMenu}
					onClose={validateOnChoiceMenuClose}
				>
					<DragDropContextWithNonce
						onDragEnd={(result) => onChoiceDragEnd(result)}
					>
						<Droppable droppableId="dropdown-choices">
							{(provided) => (
								<div
									ref={provided.innerRef}
									{...provided.droppableProps}
									style={{
										maxHeight: "160px",
										overflow: "auto",
									}}
								>
									{dropdownChoices.map((choice, index) => (
										<Draggable
											draggableId={index.toString()}
											index={index}
											key={index}
										>
											{(provided) => (
												<Box
													ref={provided.innerRef}
													{...provided.draggableProps}
												>
													<Box
														px={1}
														key={index}
														display="flex"
														style={{
															minHeight: "40px",
															backgroundColor:
																dropdownChoiceHovered ===
																index
																	? "#F4F5F5"
																	: "#fff",
														}}
														onMouseOver={() =>
															onMouseOverDropdownChoice(
																index
															)
														}
														onMouseOut={() =>
															onMouseOutDropdownChoice()
														}
													>
														<Stack
															direction="row"
															justifyContent="space-between"
															alignItems="center"
															width="100%"
															spacing={2}
														>
															<Stack
																direction="row"
																alignItems="center"
																spacing={1}
															>
																<Stack
																	direction="row"
																	alignItems="center"
																>
																	<Box
																		display="flex"
																		{...provided.dragHandleProps}
																	>
																		{DragIcon(
																			20,
																			20
																		)}
																	</Box>

																	<Tooltip
																		title={
																			selectedChoiceId ===
																			choice.id
																				? "Default Choice"
																				: "Mark as Default Choice"
																		}
																		placement="left"
																		PopperProps={{
																			modifiers:
																				[
																					{
																						name: "offset",
																						options:
																							{
																								offset: [
																									0,
																									-8,
																								],
																							},
																					},
																				],
																		}}
																	>
																		<Checkbox
																			icon={RoundCheckBoxOffIcon(
																				16.7,
																				16.7
																			)}
																			checkedIcon={TickCircle(
																				16.7,
																				16.7,
																				theme
																					.palette
																					.primary
																					.main
																			)}
																			onChange={() =>
																				setDropdownChoiceSelected(
																					choice,
																					index
																				)
																			}
																			checked={
																				choice.id ===
																				selectedChoiceId
																			}
																			value={
																				choice.id
																			}
																			sx={{
																				"& .MuiSvgIcon-root":
																					{
																						backgroundColor:
																							"transparent",
																					},
																			}}
																		/>
																	</Tooltip>
																</Stack>

																{editableChoiceIndex ===
																	index ||
																choice.name ===
																	"" ? (
																	<Box>
																		<TextField
																			sx={{
																				width: "90%",
																			}}
																			variant="standard"
																			value={
																				choice.name
																			}
																			onChange={(
																				event
																			) =>
																				setDropdownChoiceName(
																					index,
																					event
																						.target
																						.value
																				)
																			}
																			InputProps={{
																				style: {
																					fontSize: 14,
																				},
																			}}
																			fullWidth
																			autoFocus
																			error={hasDropdownChoiceError(
																				choice
																			)}
																			helperText={
																				hasDropdownChoiceError(
																					choice
																				) &&
																				selectState.errorMessage
																			}
																		/>
																	</Box>
																) : (
																	<Box
																		onClick={() =>
																			editDropdownChoiceName(
																				index
																			)
																		}
																	>
																		<Tooltip
																			title={
																				choice.name
																			}
																			placement="top"
																			open={
																				isChoiceTooltipOpened &&
																				hoveredChoiceIndex ===
																					index
																			}
																		>
																			<Typography
																				noWrap
																				fontSize={
																					14
																				}
																				onMouseOver={(
																					event
																				) =>
																					handleOpenChoiceTooltip(
																						event,
																						index
																					)
																				}
																				onMouseOut={() => {
																					setIsChoiceTooltipOpened(
																						false
																					);
																					setHoveredChoiceIndex(
																						null
																					);
																				}}
																				sx={{
																					width: "220px",
																				}}
																			>
																				{
																					choice.name
																				}
																			</Typography>
																		</Tooltip>
																	</Box>
																)}
															</Stack>

															<Box
																display="flex"
																onClick={() =>
																	removeDropdownChoice(
																		index
																	)
																}
																sx={{
																	cursor: isOptionRemovable(
																		index
																	)
																		? "pointer"
																		: "not-allowed",
																	mr: 1,
																}}
															>
																{CloseIcon(
																	20,
																	20,
																	"#000000",
																	dropdownChoices?.length >
																		defaultChoices
																		? 1
																		: 0.6
																)}
															</Box>
														</Stack>
													</Box>
												</Box>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContextWithNonce>

					<Box
						onClick={handleDropdownChoiceValidation}
						style={{
							minHeight: "40px",
							cursor: hasError ? "not-allowed" : "pointer",
							opacity: hasError ? 0.6 : 1,
						}}
						ml={4.5}
						pt={1.5}
					>
						<Stack direction="row" spacing={2}>
							{AddIcon(
								20,
								20,
								theme.palette.secondary.contrastText
							)}
							<Typography
								fontSize={14}
								color={theme.palette.secondary.contrastText}
							>
								Add Choice
							</Typography>
						</Stack>
					</Box>
				</Menu>
			</Box>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { useUpdateRoleMutation } from "../../../../../hooks/services/userManagement/roles";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { hexToRgba } from "../../../../../styles/twozo";

export default function RenameRole(props) {
	const { onClose, selectedRole } = props;
	const theme = useTheme();

	const updateRoleMutation = useUpdateRoleMutation();

	const [role, setRole] = useState(selectedRole.name);

	const handleRenameRole = (event) => {
		const { value } = event.target;
		setRole(value);
	};

	const onRenameRole = () => {
		let requestData = {
			id: selectedRole.id,
			name: role,
		};
		if (!isRenameRoleFieldEmpty() && selectedRole.name !== role) {
			updateRoleMutation.mutate(requestData, {
				onSuccess: () => {
					onClose();
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.roleUpdated,
					});
				},
				onError: (error) => {
					let errorMessage = error.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						message:
							errorMessage ??
							notificationMessage.genericErrorMessage,
					});
				},
			});
		}
	};

	const isRenameRoleFieldEmpty = () => {
		return role === "";
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontSize={17} fontWeight={600}>
							Rename Role
						</Typography>

						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(24, 24, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					p={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
					}}
				>
					<Stack>
						<Stack direction="row" alignItems="center">
							<Typography
								fontSize={14}
								fontWeight={500}
								padding="5px"
								color={
									isRenameRoleFieldEmpty()
										? theme.palette.error.main
										: "#000"
								}
							>
								Role Name
							</Typography>
							<span
								style={{
									color: theme.palette.error.main,
								}}
							>
								*
							</span>
						</Stack>
						<TextField
							value={role}
							onChange={handleRenameRole}
							error={isRenameRoleFieldEmpty()}
						/>
						{isRenameRoleFieldEmpty() && (
							<Typography
								sx={{
									color: theme.palette.error.main,
									fontSize: 14,
									fontWeight: 500,
									margin: "5px",
								}}
							>
								This field is required
							</Typography>
						)}
					</Stack>
				</Box>

				<Divider />

				<Box p={2}>
					<Stack direction="row" spacing={1}>
						<Button
							variant="contained"
							disableElevation
							onClick={onRenameRole}
							disabled={updateRoleMutation.isLoading}
							sx={{
								color:
									selectedRole.name === role ||
									isRenameRoleFieldEmpty()
										? hexToRgba("#FFFFFF", 0.6)
										: "#FFFFFF",
							}}
						>
							Save
						</Button>

						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={onClose}
							disabled={updateRoleMutation.isLoading}
						>
							Cancel
						</Button>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}

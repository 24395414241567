import React, { useState } from "react";
import {
	Avatar,
	Badge,
	Box,
	CircularProgress,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import ContactImage from "../../../assets/images/contact/unknownContact.png";
import { default as CloseIcon } from "../../../assets/icons/close";
import Menu from "../../Elements/Menu";
import AddParticipants from "./AddParticipants";
import ContactCard from "../../Elements/ContactCard";

export default function RelatedContacts(props) {
	const {
		relatedContacts,
		participantsDropdownList,
		onChangeParticipantsMenu,
		handleDeleteParticipant,
		isLoadingRelatedContacts,
		isFetchingRelatedContacts,
		relatedContactsLastRowRef,
		isAddParticipantsVisible,
		isCloseIconVisible = false,
	} = props;
	const theme = useTheme();

	const [hoveredParticipantId, setHoveredParticipantId] = useState(null);
	const [selectedParticipants, setSelectedParticipants] = useState({});
	const [participantsDetailsMenuElement, setParticipantsDetailsMenuElement] =
		useState(null);
	const isParticipantDetailsMenuOpened = Boolean(
		participantsDetailsMenuElement
	);

	const onMouseOverParticipant = (participant) => {
		setHoveredParticipantId(participant?.id);
	};

	const onMouseOutParticipant = () => {
		setHoveredParticipantId(null);
	};

	const openParticipantsDetailsMenu = (event, participant) => {
		setParticipantsDetailsMenuElement(event.currentTarget);
		setSelectedParticipants(participant);
	};

	const closeParticipantsDetailsMenu = () => {
		setParticipantsDetailsMenuElement(null);
		setSelectedParticipants({});
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="320px"
				anchorEl={participantsDetailsMenuElement}
				open={isParticipantDetailsMenuOpened}
				onClose={closeParticipantsDetailsMenu}
				style={{
					marginTop: "6px",
				}}
			>
				<ContactCard contactDetails={selectedParticipants} />
			</Menu>

			<Box p={isAddParticipantsVisible ? 1 : 0}>
				{isAddParticipantsVisible ? (
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Typography fontSize={15} fontWeight={600}>
							Related Contacts
						</Typography>

						<AddParticipants
							participantsDropdownList={participantsDropdownList}
							onChangeParticipantsMenu={onChangeParticipantsMenu}
						/>
					</Stack>
				) : null}

				{isLoadingRelatedContacts ? (
					<Stack justifyContent="center" alignItems="center">
						<CircularProgress size="30px" />
					</Stack>
				) : (
					<Box
						sx={{
							maxBlockSize: "135px",
							overflowY: "auto",
						}}
					>
						{relatedContacts?.length > 0 ? (
							<Stack
								direction="row"
								pt={isAddParticipantsVisible ? 1 : 0}
								flexWrap="wrap"
							>
								{relatedContacts?.map((participant) => (
									<Box
										key={participant?.id}
										onMouseOver={() =>
											onMouseOverParticipant(participant)
										}
										onMouseOut={onMouseOutParticipant}
										style={{
											cursor: "pointer",
											margin: "6px",
										}}
									>
										<Badge
											overlap="circular"
											anchorOrigin={{
												vertical: "top",
												horizontal: "right",
											}}
											invisible={
												hoveredParticipantId !==
													participant?.id ||
												isCloseIconVisible
											}
											badgeContent={
												<Box
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent:
															"center",
														backgroundColor:
															theme.palette
																.secondary.main,
														width: "12px",
														height: "12px",
														borderRadius: "16px",
														border: "1px solid #fff",
													}}
												>
													<IconButton
														onClick={() =>
															handleDeleteParticipant(
																participant?.id
															)
														}
													>
														{CloseIcon(
															14,
															14,
															theme.palette
																.primary.main
														)}
													</IconButton>
												</Box>
											}
										>
											<Avatar
												alt="contact"
												src={ContactImage}
												sx={{ width: 32, height: 32 }}
												onClick={(event) =>
													openParticipantsDetailsMenu(
														event,
														participant
													)
												}
											/>
										</Badge>
									</Box>
								))}
							</Stack>
						) : (
							<Stack
								alignItems="center"
								justifyContent="center"
								pt={isAddParticipantsVisible ? 2 : 0}
							>
								<Typography
									fontSize={14}
									color="rgba(0, 0, 0, 0.6)"
								>
									No related contacts found.
								</Typography>
							</Stack>
						)}

						<Box
							minHeight="1px"
							width="100%"
							ref={relatedContactsLastRowRef}
						>
							{!isLoadingRelatedContacts &&
								isFetchingRelatedContacts &&
								relatedContacts?.length > 0 && (
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="center"
										spacing={1}
										py={2}
									>
										<CircularProgress size={18} />

										<Typography fontSize={12}>
											Loading More Data
										</Typography>
									</Stack>
								)}
						</Box>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}

import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	Stack,
	TextField,
	Typography,
	ToggleButton,
	Checkbox,
	useTheme,
	MenuItem,
	alpha,
} from "@mui/material";
import Dialog from "../../../Dialog";
import ToggleButtonGroup from "../../../ToggleButtonGroup";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDown";
import Menu from "../../../Menu";
import { getDropdownDataByName } from "../../../../../utils/dropdownData";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import {
	useFilterList,
	useSaveFilterMutation,
	useUpdateFilterMutation,
	useUserTeamTerritoryList,
} from "../../../../../hooks/services/common/table/filter";
import {
	removeFieldsWithEmptyValues,
	removeFieldsWithIdKey,
} from "../../../../../utils/common";
import { useFilterDataContext } from "../Context/FilterDataContext";
import { filterVisibility } from "../../../../../utils/FilterUtills";
import SelectedView from "./SelectedView";
import { MenuItemText } from "../../../../../styles/twozo";

export default function SaveViewAsDialog(props) {
	const {
		onClose,
		open,
		hideSaveViewAsButton,
		sourceId,
		hasShareViewPermissions,
	} = props;
	const theme = useTheme();

	const {
		filterState,
		filterListByCondition,
		updateActiveFilterConfig,
		updateAppliedFilterId,
		disableApplyButton,
	} = useFilterDataContext();

	const saveFilterMutation = useSaveFilterMutation(sourceId);
	const updateFilterMutation = useUpdateFilterMutation(sourceId);

	const optionsType = {
		user: "USER",
		team: "TEAM",
		territory: "TERRITORY",
	};

	const getSaveViewName = () => {
		const fieldData = filterState.filterUICriteriaList[0];
		return (
			(fieldData?.fieldData.name ?? "") +
			" " +
			(fieldData?.additionalOption.displayName ?? "") +
			" " +
			(fieldData?.comparatorId.value.name ?? "") +
			" " +
			(fieldData?.value.value ?? "") +
			" " +
			(fieldData?.multiSelectValues[0]?.name ?? "")
		);
	};

	const { data: filterList } = useFilterList(sourceId);
	const activeFilterConfigRef = useRef(false);

	// PermissionList
	const permissionList = getDropdownDataByName("PERMISSION_LIST");
	const [permissionMenuElement, setPermissionMenuElement] = useState(null);
	const isPermissionMenuOpen = Boolean(permissionMenuElement);
	const [selectedPermission, setSelectedPermission] = useState(
		permissionList[0]
	);

	const openPermissionMenu = (event) => {
		setPermissionMenuElement(event.currentTarget);
	};

	const closePermissionMenu = () => {
		setPermissionMenuElement(null);
	};

	const handleSelectPermission = (providedAccess) => {
		setSelectedPermission(providedAccess);
		setPermissionMenuElement(null);
	};

	//VisibilityList
	const visibilityList = getDropdownDataByName("VISIBILITY_LIST");
	const [selectedVisibilityTypeId, setSelectedVisibilityTypeId] = useState(
		visibilityList[0].value
	);
	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setSelectedVisibilityTypeId(type);
		}
	};

	//Prevent Share
	const [isPreventShareFilter, setIsPreventShareFilter] = useState(true);

	const handlePreventShareFilter = () => {
		setIsPreventShareFilter(!isPreventShareFilter);
	};

	//Filter Name
	const saveViewName = getSaveViewName();
	const [viewName, setViewName] = useState("");
	const handleViewNameChange = (event) => {
		setViewName(event.target.value);
	};

	//user team territory list
	const { data: userTeamTerritoryList } = useUserTeamTerritoryList();
	const [selectedUserTeamTerritoryValue, setSelectedUserTeamTerritoryValue] =
		useState([]);

	const getSelectedUserList = () => {
		if (selectedUserTeamTerritoryValue.length > 0) {
			return selectedUserTeamTerritoryValue.filter(
				(selectedValue) => selectedValue.type === optionsType.user
			);
		}
		return [];
	};

	const getSelectedTeamList = () => {
		if (selectedUserTeamTerritoryValue.length > 0) {
			return selectedUserTeamTerritoryValue.filter(
				(selectedValue) => selectedValue.type === optionsType.team
			);
		}
		return [];
	};

	const getSelectedTerritoryList = () => {
		if (selectedUserTeamTerritoryValue.length > 0) {
			return selectedUserTeamTerritoryValue.filter(
				(selectedValue) => selectedValue.type === optionsType.territory
			);
		}
		return [];
	};

	const updateUserTeamTerritoryValue = (value) => {
		setSelectedUserTeamTerritoryValue(value);
	};

	const displayAccessTypeMenu = () => {
		return (
			selectedVisibilityTypeId === filterVisibility.everyone ||
			selectedVisibilityTypeId === filterVisibility.selected
		);
	};

	const displaySelectedTable = () => {
		return selectedVisibilityTypeId === filterVisibility.selected;
	};

	const displaySharedFilter = () => {
		return (
			selectedVisibilityTypeId === filterVisibility.everyone ||
			selectedVisibilityTypeId === filterVisibility.selected
		);
	};

	const getSaveViewRequest = () => {
		const requestData = { filterVisibility: {} };
		requestData.criteriaList = filterState.filterConditions?.criteria;
		requestData.name = viewName ? viewName : saveViewName;
		requestData.sourceId = sourceId;
		requestData.filterVisibility.visibilityId = selectedVisibilityTypeId;
		requestData.filterVisibility.id =
			filterState.activeFilterDetails?.filterVisibility?.id;
		requestData.filterVisibility.preventShare =
			selectedVisibilityTypeId === filterVisibility.everyone ||
			selectedVisibilityTypeId === filterVisibility.selected
				? isPreventShareFilter
				: "";
		requestData.filterVisibility.visibilityPermissionId =
			selectedVisibilityTypeId === filterVisibility.everyone ||
			selectedVisibilityTypeId === filterVisibility.selected
				? selectedPermission.value
				: "";

		if (getSelectedUserList().length > 0) {
			requestData.filterVisibility.users = getSelectedUserList().map(
				(user) => user.value
			);
		}
		if (getSelectedTeamList().length > 0) {
			requestData.filterVisibility.teams = getSelectedTeamList().map(
				(team) => team.value
			);
		}
		if (getSelectedTerritoryList().length > 0) {
			requestData.filterVisibility.territories =
				getSelectedTerritoryList().map((territory) => territory.value);
		}
		requestData.id = filterState.activeFilterDetails?.id
			? filterState.activeFilterDetails?.id
			: "";
		removeFieldsWithEmptyValues(requestData.filterVisibility);
		removeFieldsWithEmptyValues(requestData);
		return requestData;
	};

	function hasAppliedFilterChanged(
		currentlyAppliedFilter,
		previouslyAppliedFilter
	) {
		const currentlyAppliedFilterKeys = Object.keys(currentlyAppliedFilter);
		const previouslyAppliedFilterKeys = Object.keys(
			previouslyAppliedFilter
		);

		if (
			currentlyAppliedFilterKeys.length !==
			previouslyAppliedFilterKeys.length
		) {
			return false;
		}

		for (let key of currentlyAppliedFilterKeys) {
			if (currentlyAppliedFilter[key] !== previouslyAppliedFilter[key]) {
				return false;
			}
		}

		return true;
	}

	useEffect(() => {
		if (!activeFilterConfigRef.current && Array.isArray(filterList)) {
			const activeFilterConfig =
				filterList
					?.find((filterGroups) =>
						filterGroups.options.some(
							(option) =>
								option.id === filterState.appliedFilterId
						)
					)
					?.options.find(
						(option) => option.id === filterState.appliedFilterId
					) || null;

			if (
				activeFilterConfig &&
				!hasAppliedFilterChanged(
					activeFilterConfig,
					filterState.activeFilterConfig
				)
			) {
				updateActiveFilterConfig(activeFilterConfig);
				activeFilterConfigRef.current = true;
			}
		}
	}, [
		filterList,
		filterState.appliedFilterId,
		updateActiveFilterConfig,
		filterState.activeFilterConfig,
	]);

	const saveFilter = () => {
		let requestData = getSaveViewRequest();
		removeFieldsWithIdKey(requestData);
		saveFilterMutation.mutate(requestData, {
			onSuccess: (response) => {
				filterListByCondition({ filterId: response.createdRecords[0] });
				updateAppliedFilterId(response.createdRecords[0]);
				disableApplyButton();
				activeFilterConfigRef.current = false;

				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.filterSaved,
				});
				hideSaveViewAsButton();
				onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	//Filter Update
	const updateEditedFilter = () => {
		let requestData = getSaveViewRequest();
		updateFilterMutation.mutate(requestData, {
			onSuccess: () => {
				activeFilterConfigRef.current = false;

				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.filterUpdated,
				});
				hideSaveViewAsButton();
				onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	//To Store Saved Filter Values
	useEffect(() => {
		if (Object.keys(filterState.activeFilterDetails).length > 0) {
			const getInitialSelectedValues = (initialValues) => {
				return userTeamTerritoryList?.filter((userTeamTerritory) =>
					initialValues?.some(
						(initialValue) =>
							initialValue.id === userTeamTerritory.value
					)
				);
			};
			setViewName(
				filterState.activeFilterDetails?.name ??
					filterState.activeFilterDetails?.name
			);
			setSelectedVisibilityTypeId(
				filterState.activeFilterDetails?.filterVisibility?.visibilityId
			);
			setIsPreventShareFilter(
				filterState.activeFilterDetails?.filterVisibility
					?.visibilityId !== filterVisibility.justMe
					? filterState.activeFilterDetails?.filterVisibility
							?.preventShare
					: true
			);
			setSelectedPermission(
				filterState.activeFilterDetails?.filterVisibility
					?.visibilityPermissionId
					? permissionList.find(
							(permission) =>
								permission.value ===
								filterState.activeFilterDetails
									?.filterVisibility?.visibilityPermissionId
						)
					: permissionList[0]
			);

			let users =
				getInitialSelectedValues(
					filterState.activeFilterDetails?.filterVisibility?.users
				) || [];
			let teams =
				getInitialSelectedValues(
					filterState.activeFilterDetails?.filterVisibility?.teams
				) || [];
			let territories =
				getInitialSelectedValues(
					filterState.activeFilterDetails?.filterVisibility
						?.territories
				) || [];

			setSelectedUserTeamTerritoryValue([
				...users,
				...teams,
				...territories,
			]);
		} else {
			setViewName("");
			setSelectedVisibilityTypeId(visibilityList[0].value);
			setIsPreventShareFilter(true);
			setSelectedPermission(permissionList[0]);
		}
	}, [
		filterState.activeFilterDetails,
		permissionList,
		userTeamTerritoryList,
		visibilityList,
	]);

	return (
		<React.Fragment>
			<Menu
				minWidth="150px"
				anchorEl={permissionMenuElement}
				open={isPermissionMenuOpen}
				onClose={closePermissionMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{permissionList.map((access) => (
					<MenuItem
						key={access.value}
						onClick={() => handleSelectPermission(access)}
						style={{
							height: "40px",
							backgroundColor:
								access.value === selectedPermission.value
									? alpha(theme.palette.primary.main, 0.1)
									: "transparent",
						}}
					>
						<MenuItemText>{access.name}</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			<Dialog open={open} title="Save View">
				<Box p={2}>
					<Stack spacing={1.5}>
						<Stack>
							<Typography
								fontSize={14}
								fontWeight={500}
								padding="5px"
							>
								Filter Name
							</Typography>
							<TextField
								value={viewName}
								onChange={(event) =>
									handleViewNameChange(event)
								}
								placeholder={saveViewName}
							/>
						</Stack>
						<Stack>
							<Typography
								fontSize={14}
								fontWeight={500}
								padding="5px"
							>
								Visible To
							</Typography>

							<Stack direction="row" spacing={3}>
								<Box>
									<ToggleButtonGroup
										value={selectedVisibilityTypeId}
										onChange={handleToggleButtonGroup}
									>
										{visibilityList.map((data) => (
											<ToggleButton
												key={data.value}
												value={data.value}
												disabled={
													!hasShareViewPermissions
												}
											>
												{data.name}
											</ToggleButton>
										))}
									</ToggleButtonGroup>
								</Box>

								{displayAccessTypeMenu() && (
									<Button
										variant="contained"
										endIcon={DropDownIcon(
											16,
											16,
											theme.palette.primary.main
										)}
										color="secondary"
										disableElevation
										onClick={openPermissionMenu}
									>
										{selectedPermission.name}
									</Button>
								)}
							</Stack>
						</Stack>
						{displaySelectedTable() && (
							<SelectedView
								updateUserTeamTerritoryValue={
									updateUserTeamTerritoryValue
								}
								setSelectedUserTeamTerritoryValue={
									setSelectedUserTeamTerritoryValue
								}
								getSelectedUserList={getSelectedUserList}
								getSelectedTeamList={getSelectedTeamList}
								getSelectedTerritoryList={
									getSelectedTerritoryList
								}
							/>
						)}
						{displaySharedFilter() && (
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
								pt={1}
							>
								<Checkbox
									checked={isPreventShareFilter}
									onChange={handlePreventShareFilter}
								/>
								<Typography fontSize={15} fontWeight={500}>
									Prevent recepients from sharing this view
									with others
								</Typography>
							</Stack>
						)}

						{filterState?.activeFilterDetails?.criteriaList
							?.length >= 1 &&
						filterState.activeFilterConfig?.isEditable ? (
							<Stack
								direction="row"
								justifyContent="space-between"
								pt={1}
							>
								<Stack>
									<Button
										variant="contained"
										color="secondary"
										disableElevation
										onClick={() => onClose()}
									>
										Cancel
									</Button>
								</Stack>

								<Stack direction="row" spacing={2}>
									<Button
										variant="contained"
										color="secondary"
										disableElevation
										onClick={saveFilter}
									>
										Save as New
									</Button>

									<Button
										variant="contained"
										disableElevation
										onClick={updateEditedFilter}
										sx={{ width: "112px" }}
									>
										Save
									</Button>
								</Stack>
							</Stack>
						) : (
							<Stack
								direction="row"
								justifyContent="flex-end"
								spacing={2}
								pt={1}
							>
								<Button
									variant="contained"
									color="secondary"
									disableElevation
									onClick={() => onClose()}
								>
									Cancel
								</Button>

								<Button
									variant="contained"
									disableElevation
									onClick={saveFilter}
									sx={{ width: "112px" }}
								>
									Save
								</Button>
							</Stack>
						)}
					</Stack>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}

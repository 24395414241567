import {
	Box,
	Button,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import {
	useRules,
	useUpdateRules,
} from "../../../../hooks/services/lifecycleStage";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { enqueueSnackbar } from "notistack";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { useState, useEffect } from "react";
import { DEAL_STAGE_TYPES } from "../LifecycleStageConfig";
import { MenuItemText } from "../../../../styles/twozo";

export default function LifecycleStageRules({ lifecycleStagesList }) {
	// query call:-
	const { data: rulesList } = useRules();

	// mutation call:-
	const updateRule = useUpdateRules();

	const [stageOnDealAdded, setStageOnDealAdded] = useState({
		name: "",
		value: "",
	});
	const [stageOnDealWon, setStageOnDealWon] = useState({
		name: "",
		value: "",
	});

	const [stageOnDealAddedSearchValue, setStageOnDealAddedSearchValue] =
		useState("");
	const [stageOnDealWonSearchValue, setStageOnDealWonSearchValue] =
		useState("");

	useEffect(() => {
		if (Array.isArray(lifecycleStagesList) && Array.isArray(rulesList)) {
			rulesList.forEach(({ type, stageId }) => {
				const matchedRule = lifecycleStagesList.find(
					(stage) => stage.id === stageId
				);
				if (matchedRule) {
					const { name, id } = matchedRule;
					type === DEAL_STAGE_TYPES.DEAL_ADDED
						? setStageOnDealAdded({ name, value: id })
						: setStageOnDealWon({ name, value: id });
				}
			});
		}
	}, [rulesList, lifecycleStagesList]);

	const handleChangeFirstRule = (event, child) => {
		const { value } = event.target;
		setStageOnDealAdded({
			name: child.props.name || "",
			value: value,
		});
	};

	const filteredStageOnDealAddedList = stageOnDealAddedSearchValue.trim()
		? lifecycleStagesList?.filter((option) =>
				option.name
					.toLowerCase()
					.includes(stageOnDealAddedSearchValue.toLowerCase())
			)
		: lifecycleStagesList;

	const handleSearchFirstRuleValue = (event) => {
		const { value } = event.target;
		setStageOnDealAddedSearchValue(value);
	};

	const handleRemoveStageOnDealAdded = () => {
		setStageOnDealAdded({ name: "", value: "" });
	};

	const handleChangeSecondRule = (event, child) => {
		const { value } = event.target;
		setStageOnDealWon({
			name: child.props.name,
			value: value,
		});
	};

	const filteredStageOnDealWonList = stageOnDealWonSearchValue.trim()
		? lifecycleStagesList?.filter((option) =>
				//filter for search
				option.name
					.toLowerCase()
					.includes(stageOnDealWonSearchValue.toLowerCase())
			)
		: lifecycleStagesList;

	const handleSearchSecondRuleValue = (event) => {
		const { value } = event.target;
		setStageOnDealWonSearchValue(value);
	};

	const handleRemoveStageOnDealWon = () => {
		setStageOnDealWon({ name: "", value: "" });
	};

	const renderEmptyView = (options) => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					{options?.length === 0
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	const getRulesRequestData = () => {
		const rules = [];

		if (stageOnDealAdded.value) {
			rules.push({
				type: DEAL_STAGE_TYPES.DEAL_ADDED,
				stageId: stageOnDealAdded.value,
			});
		}

		if (stageOnDealWon.value) {
			rules.push({
				type: DEAL_STAGE_TYPES.DEAL_WON,
				stageId: stageOnDealWon.value,
			});
		}

		return rules;
	};

	const handleSaveRules = () => {
		let requestData = getRulesRequestData();
		updateRule.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.lifecycleStageUpdated,
				});
			},
		});
	};

	return (
		<>
			<Typography fontSize={18} fontWeight={600} py={1}>
				Rules for changing stages
			</Typography>

			<Stack spacing={2} pb={3}>
				<Box>
					<Typography fontSize={13} fontWeight={500} pl={1} pb="6px">
						{"Whenever a deal is added, change contact's stage to"}
					</Typography>

					<Select
						sx={{ width: "530px" }}
						onChange={handleChangeFirstRule}
						displayEmpty
						onOpen={() => setStageOnDealAddedSearchValue("")}
						value={stageOnDealAdded.value}
						renderValue={() => (
							<Typography
								noWrap
								fontSize={14}
								color={
									stageOnDealAdded.value
										? "#000"
										: "rgba(0, 0, 0, 0.6)"
								}
							>
								{stageOnDealAdded.name
									? stageOnDealAdded.name
									: "Select"}
							</Typography>
						)}
					>
						<Box
							p={0.5}
							marginTop="-8px"
							sx={{
								position: "sticky",
								top: 0,
								zIndex: 1,
								backgroundColor: "#fff",
							}}
						>
							<MenuSearchBar
								value={stageOnDealAddedSearchValue}
								onChange={handleSearchFirstRuleValue}
							/>
						</Box>

						{filteredStageOnDealAddedList?.length > 0
							? filteredStageOnDealAddedList?.map((option) => (
									<MenuItem
										value={option.id || ""}
										key={option.id}
										name={option?.name || ""}
									>
										<Stack
											direction="row"
											spacing={1}
											alignItems="center"
											justifyContent="space-between"
											width="100%"
										>
											<MenuItemText>
												{option.name}
											</MenuItemText>

											{stageOnDealAdded.value ===
											option?.id ? (
												<Box
													display="flex"
													onClick={
														handleRemoveStageOnDealAdded
													}
												>
													{CloseIcon(
														20,
														20,
														"#000",
														0.6
													)}
												</Box>
											) : null}
										</Stack>
									</MenuItem>
								))
							: renderEmptyView()}
					</Select>
				</Box>

				<Box>
					<Typography fontSize={13} fontWeight={500} pl={1} pb="6px">
						{"Whenever a deal is won, change contact's stage to"}
					</Typography>

					<Select
						sx={{ width: "530px" }}
						onChange={handleChangeSecondRule}
						displayEmpty
						onOpen={() => setStageOnDealWonSearchValue("")}
						value={stageOnDealWon.value}
						renderValue={() => (
							<Typography
								noWrap
								fontSize={14}
								color={
									stageOnDealWon.value
										? "#000"
										: "rgba(0, 0, 0, 0.6)"
								}
							>
								{stageOnDealWon.name
									? stageOnDealWon.name
									: "Select"}
							</Typography>
						)}
					>
						<Box
							p={0.5}
							marginTop="-8px"
							sx={{
								position: "sticky",
								top: 0,
								zIndex: 1,
								backgroundColor: "#fff",
							}}
						>
							<MenuSearchBar
								value={stageOnDealWonSearchValue}
								onChange={handleSearchSecondRuleValue}
							/>
						</Box>

						{filteredStageOnDealWonList?.length > 0
							? filteredStageOnDealWonList?.map((option) => (
									<MenuItem
										value={option.id || ""}
										key={option.id}
										name={option?.name || ""}
									>
										<Stack
											direction="row"
											spacing={1}
											alignItems="center"
											justifyContent="space-between"
											width="100%"
										>
											<MenuItemText>
												{option.name}
											</MenuItemText>

											{stageOnDealWon.value ===
											option?.id ? (
												<Box
													display="flex"
													onClick={
														handleRemoveStageOnDealWon
													}
												>
													{CloseIcon(
														20,
														20,
														"#000",
														0.6
													)}
												</Box>
											) : null}
										</Stack>
									</MenuItem>
								))
							: renderEmptyView()}
					</Select>
				</Box>
			</Stack>

			<Stack
				direction="row"
				justifyContent="flex-start"
				spacing={2}
				pb={3}
			>
				<Button
					variant="contained"
					disableElevation
					onClick={handleSaveRules}
					disabled={updateRule.isLoading}
				>
					Save
				</Button>

				<Button variant="contained" color="secondary" disableElevation>
					Cancel
				</Button>
			</Stack>
		</>
	);
}

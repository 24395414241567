import {
	Box,
	Stack,
	Checkbox,
	Divider,
	MenuItem,
	MenuList,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Menu from "../../Elements/Menu";
import { default as RoundCheckBoxOnIcon } from "../../../assets/icons/roundCheckBoxOn";
import { default as RoundCheckBoxOffIcon } from "../../../assets/icons/roundCheckBoxOff";
import { MenuItemText } from "../../../styles/twozo";

const timeIntervalOptions = [
	{
		id: 1,
		label: "Anytime",
	},
	{
		id: 2,
		label: "Longer than a day",
	},
	{
		id: 3,
		label: "Longer than a week",
	},
	{
		id: 4,
		label: "Longer than a month",
	},
	{
		id: 5,
		label: "Longer than 6 month",
	},
	{
		id: 6,
		label: "Longer than a year",
	},
];

export default function EmailFilter(props) {
	const { initialFilterData, onChangeFilter, filterConfig } = props;
	const theme = useTheme();

	const [trackOpensMenuElement, setTrackOpensMenuElement] = useState(null);
	const isTrackOpensMenuOpened = Boolean(trackOpensMenuElement);
	const [trackClicksMenuElement, setTrackClicksMenuElement] = useState(null);
	const isTrackClicksMenuOpened = Boolean(trackClicksMenuElement);

	const [selectedFilters, setSelectedFilters] = useState({});

	useEffect(() => {
		setSelectedFilters(initialFilterData);
	}, [initialFilterData]);

	const filtersForLinking = [
		{
			id: 1,
			label: "Linked with a deal",
		},
		{
			id: 2,
			label: "Linked with an open deal",
		},
		{
			id: 3,
			label: "Not linked with a deal",
		},
		{
			id: 4,
			label: "With attachments",
		},
	];

	const openTrackOpensMenu = (event) => {
		event.stopPropagation();
		setTrackOpensMenuElement(event.currentTarget);
	};

	const closeTrackOpensMenu = () => {
		setTrackOpensMenuElement(null);
	};

	const openTrackClicksMenu = (event) => {
		event.stopPropagation();
		setTrackClicksMenuElement(event.currentTarget);
	};

	const closeTrackClicksMenu = () => {
		setTrackClicksMenuElement(null);
	};

	const handleUnreadFilter = () => {
		let filters = { ...selectedFilters };
		if (filters.unread) {
			delete filters.unread;
		} else {
			filters.unread = true;
		}

		setSelectedFilters(filters);
		onChangeFilter(filters);
	};

	const handleTrackingFilter = (filter) => {
		let filters = { ...selectedFilters };
		if (filters[filter.requestKey]) {
			delete filters[filter.requestKey];
		} else {
			filters[filter.requestKey] = timeIntervalOptions[0].id;
		}

		setSelectedFilters(filters);
		onChangeFilter(filters);
	};

	const handleLinkingFilter = (filterId) => {
		let filters = { ...selectedFilters };
		if (filters.linkedCategory === filterId) {
			delete filters.linkedCategory;
		} else {
			filters.linkedCategory = filterId;
		}

		setSelectedFilters(filters);
		onChangeFilter(filters);
	};

	const handleSelectTrackOpensInterval = (intervalId) => {
		let filters = { ...selectedFilters };
		filters.openedInterval = intervalId;
		setSelectedFilters(filters);
		onChangeFilter(filters);
		closeTrackOpensMenu();
	};

	const handleSelectTrackClicksInterval = (intervalId) => {
		let filters = { ...selectedFilters };
		filters.clickedInterval = intervalId;
		setSelectedFilters(filters);
		onChangeFilter(filters);
		closeTrackClicksMenu();
	};

	const getTimeIntervalName = (intervalId) => {
		let selectedInterval = timeIntervalOptions.find(
			(interval) => interval.id === intervalId
		);
		return selectedInterval?.label;
	};

	const filtersForTracking = [
		{
			id: 1,
			label: "Opened",
			requestKey: "openedInterval",
			action: openTrackOpensMenu,
		},
		{
			id: 2,
			label: "Clicked",
			requestKey: "clickedInterval",
			action: openTrackClicksMenu,
		},
	];

	return (
		<React.Fragment>
			{/* IntervalMenu for TrackOpens filter */}
			<Menu
				minWidth="200px"
				anchorEl={trackOpensMenuElement}
				open={isTrackOpensMenuOpened}
				onClose={closeTrackOpensMenu}
			>
				{timeIntervalOptions.map((interval) => (
					<MenuItem
						key={interval.id}
						sx={{
							height: "40px",
							backgroundColor:
								selectedFilters?.openedInterval === interval.id
									? "rgba(51, 188, 126, 0.12)"
									: "transparent",
						}}
						onClick={() =>
							handleSelectTrackOpensInterval(interval.id)
						}
					>
						<MenuItemText>{interval.label}</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			{/* IntervalMenu for TrackClicks filter */}
			<Menu
				minWidth="200px"
				anchorEl={trackClicksMenuElement}
				open={isTrackClicksMenuOpened}
				onClose={closeTrackClicksMenu}
			>
				{timeIntervalOptions.map((interval) => (
					<MenuItem
						key={interval.id}
						sx={{
							height: "40px",
							backgroundColor:
								selectedFilters?.clickedInterval === interval.id
									? "rgba(51, 188, 126, 0.12)"
									: "transparent",
						}}
						onClick={() =>
							handleSelectTrackClicksInterval(interval.id)
						}
					>
						<MenuItemText>{interval.label}</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			<Box>
				<MenuList style={{ padding: 0 }}>
					{filterConfig?.isUnreadFilterEnabled ? (
						<MenuItem
							style={{ height: "40px" }}
							onClick={handleUnreadFilter}
						>
							<Stack
								direction="row"
								spacing={1}
								alignItems="center"
							>
								<Checkbox
									sx={{ pl: "0px" }}
									checked={!!selectedFilters?.unread}
								/>
								<MenuItemText>Unread</MenuItemText>
							</Stack>
						</MenuItem>
					) : null}

					<Divider
						style={{ margin: 0 }}
						hidden={!filterConfig?.isUnreadFilterEnabled}
					/>

					{filterConfig?.isTrackingFilterEnabled
						? filtersForTracking.map((filter, index) => (
								<MenuItem
									key={index}
									style={{ minHeight: "40px" }}
									onClick={() => handleTrackingFilter(filter)}
								>
									<Stack
										direction="row"
										spacing={1}
										alignItems="flex-start"
									>
										<Checkbox
											sx={{ pl: "0px" }}
											checked={
												!!selectedFilters?.[
													filter.requestKey
												]
											}
										/>

										<Stack pt={0.4}>
											<MenuItemText>
												{filter.label}
											</MenuItemText>

											{selectedFilters?.[
												filter.requestKey
											] ? (
												<Box
													onClick={(event) =>
														filter.action(event)
													}
												>
													<MenuItemText
														color={
															theme.palette
																.secondary
																.contrastText
														}
													>
														{getTimeIntervalName(
															selectedFilters?.[
																filter
																	.requestKey
															]
														)}
													</MenuItemText>
												</Box>
											) : null}
										</Stack>
									</Stack>
								</MenuItem>
							))
						: null}

					<Divider
						style={{ margin: 0 }}
						hidden={!filterConfig?.isTrackingFilterEnabled}
					/>

					{filterConfig?.isLinkingFilterEnabled
						? filtersForLinking.map((filter, index) => (
								<MenuItem
									key={index}
									style={{ height: "40px" }}
									onClick={() =>
										handleLinkingFilter(filter.id)
									}
								>
									<Stack
										direction="row"
										spacing={1}
										alignItems="center"
									>
										<Checkbox
											icon={RoundCheckBoxOffIcon(20, 20)}
											checkedIcon={RoundCheckBoxOnIcon(
												20,
												20,
												theme.palette.primary.main
											)}
											checked={
												filter.id ===
												selectedFilters?.linkedCategory
											}
										/>

										<MenuItemText>
											{filter.label}
										</MenuItemText>
									</Stack>
								</MenuItem>
							))
						: null}

					<Divider style={{ margin: 0 }} />
				</MenuList>
			</Box>
		</React.Fragment>
	);
}

import React from "react";
import { ContactOnBoardBaseComponent } from "./ContactOnBoardBaseComponent";
import { Controller, useFormContext } from "react-hook-form";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

export function ContactOnBoardComponent(props) {
	const { field, inputRef, values, onChangeFieldValueLabel } = props;

	const { control, clearErrors } = useFormContext();
	const contactValue = values?.contactValue;

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				defaultValue={contactValue || ""}
				rules={handleFieldValidation(field)}
				render={({
					field: { onChange, value, ...renderField },
					fieldState: { error },
				}) => (
					<ContactOnBoardBaseComponent
						{...renderField}
						onContactInputChange={onChange}
						clearErrors={clearErrors}
						value={value}
						field={field}
						inputRef={inputRef}
						error={error}
						formValues={values}
						onChangeFieldValueLabel={onChangeFieldValueLabel}
					/>
				)}
			></Controller>
		</React.Fragment>
	);
}

import FieldEdit from "../../../../FieldEdit";
import TerritoryFieldEditForm from "../../../../FieldEditFormComponents/TerritoryFieldEditForm";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { default as DropDownIcon } from "../../../../../../assets/icons/dropDown";
import {
	SummaryFieldName,
	Tooltip,
	twozoStyles,
} from "../../../../../../styles/twozo";
import { useSummaryContext } from "../../../../../Summary/SummaryContext";

export default function TerritoryComponent(params) {
	const { summaryField } = params;

	// summary context:-
	const { updateSummaryField, entityId } = useSummaryContext();

	const theme = useTheme();
	const classes = twozoStyles();
	const [isTerritoryHovered, setIsTerritoryHovered] = useState(false);
	const [isToolTipOpened, setIsToolTipOpened] = useState(false);

	const openToolTip = (event) => {
		setIsToolTipOpened(event.target.scrollWidth > event.target.clientWidth);
	};

	const closeTooltip = () => {
		setIsToolTipOpened(false);
	};

	const getTerritoryFieldLabel = (onFieldClicked) => {
		return (
			<Box onClick={onFieldClicked}>
				{summaryField?.value ? (
					<Tooltip
						title={summaryField.value?.name}
						open={isToolTipOpened}
						placement="top"
					>
						<Box
							py={0.3}
							px={1}
							style={{
								backgroundColor: theme.palette.secondary.main,
								borderRadius: "6px",
							}}
						>
							<Typography
								fontWeight={500}
								fontSize={14}
								className={classes.summaryData}
								width="fit-content"
								noWrap
								onMouseOver={openToolTip}
								onMouseLeave={closeTooltip}
								sx={{
									maxWidth: "134px",
								}}
							>
								{summaryField.value?.name}
							</Typography>
						</Box>
					</Tooltip>
				) : summaryField.config.readOnly ? (
					<SummaryFieldName style={{ opacity: 0.6 }}>
						-
					</SummaryFieldName>
				) : (
					<Stack
						onMouseOver={() => setIsTerritoryHovered(true)}
						onMouseLeave={() => setIsTerritoryHovered(false)}
						padding={0}
						sx={{
							borderBottom: isTerritoryHovered
								? "1px solid rgba(0, 0, 0, 0.2)"
								: "none",
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							spacing={0.5}
							width="150px"
						>
							<Typography
								fontSize="15px"
								fontWeight={500}
								style={{
									color: theme.palette.secondary.contrastText,
									opacity: "0.4",
								}}
							>
								Select
							</Typography>
							{isTerritoryHovered ? (
								<Box display="flex">
									{DropDownIcon(20, 20, "rgba(0, 0, 0, 0.2)")}
								</Box>
							) : null}
						</Stack>
					</Stack>
				)}
			</Box>
		);
	};

	const getTerritoryRequestData = (territoryFieldValue) => {
		return {
			...territoryFieldValue,
			id: entityId,
		};
	};

	const hasValueOrIsChanged = (territoryFieldValue) => {
		return (
			territoryFieldValue[summaryField?.fieldId] !==
				summaryField?.value?.id &&
			!!territoryFieldValue[summaryField?.fieldId]
		);
	};

	const handleSave = (territoryFieldValue, onClose) => {
		if (hasValueOrIsChanged(territoryFieldValue)) {
			let requestData = getTerritoryRequestData(territoryFieldValue);
			updateSummaryField(requestData, onClose);
		} else {
			onClose();
		}
	};

	return (
		<>
			<FieldEdit field={summaryField} fieldLabel={getTerritoryFieldLabel}>
				<TerritoryFieldEditForm
					field={summaryField}
					value={summaryField?.value}
					onSave={handleSave}
				/>
			</FieldEdit>
		</>
	);
}

import { Controller, useFormContext } from "react-hook-form";
import Collaborator from "../../../../../../../Summary/Activities/AddActivity/CollaboratorComponent";

export default function CollaboratorsFieldComponent({ field }) {
	const { control } = useFormContext();

	return (
		<>
			<Controller
				name={field.id + ""}
				control={control}
				render={({ field: { value, onChange } }) => (
					<Collaborator value={value} onChange={onChange} />
				)}
			/>
		</>
	);
}

import { MenuItem, Radio, Stack, Typography, useTheme } from "@mui/material";
import { getDropdownDataByName } from "../../../../../../utils/dropdownData";
import { default as TickCircle } from "../../../../../../assets/icons/tickCircle";
import { default as RoundCheckBoxOffIcon } from "../../../../../../assets/icons/roundCheckBoxOff";
import { useMemo, useState } from "react";
import Menu from "../../../../../Elements/Menu";
import { MenuItemText } from "../../../../../../styles/twozo";

export default function StatusFilter(params) {
	const { styles, criteria, handleUpdateFilter } = params;
	const theme = useTheme();

	const statusList = getDropdownDataByName("WORKFLOW_STATUS_LIST");
	const [statusMenuElement, setStatusMenuElement] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState(criteria || [-1]);
	const isStatusMenuOpened = Boolean(statusMenuElement);

	const closeStatusMenu = () => setStatusMenuElement(null);
	const openStatusMenu = (event) => setStatusMenuElement(event.currentTarget);

	const handleSelectStatus = (status) => {
		setSelectedStatus([status.value]);
		handleUpdateFilter([status.value]);
	};

	const isStatusSelected = (statusValue) => {
		return selectedStatus.includes(statusValue);
	};

	const displayedSelectedStatus = useMemo(() => {
		return (
			statusList
				?.filter((status) => selectedStatus.includes(status.value))
				.map((status) => status.name)
				.join(", ") || ""
		);
	}, [selectedStatus, statusList]);

	return (
		<>
			<Menu
				minWidth="200px"
				anchorEl={statusMenuElement}
				open={isStatusMenuOpened}
				onClose={closeStatusMenu}
				style={{ marginTop: "4px" }}
			>
				{statusList?.map((status) => (
					<MenuItem
						key={status.value}
						style={{ height: "40px", padding: 0 }}
					>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							p={1}
							onClick={() => handleSelectStatus(status)}
						>
							<Radio
								icon={RoundCheckBoxOffIcon(19, 19)}
								checkedIcon={TickCircle(
									19,
									19,
									theme.palette.primary
								)}
								checked={isStatusSelected(status.value)}
							/>

							<MenuItemText>{status.name}</MenuItemText>
						</Stack>
					</MenuItem>
				))}
			</Menu>
			<Stack style={styles.boxStyle} onClick={openStatusMenu}>
				<Typography style={styles.headerStyle}>Status</Typography>

				<Typography style={styles.contentStyle}>
					{displayedSelectedStatus}
				</Typography>
			</Stack>
		</>
	);
}

import React, { useCallback, useEffect, useState } from "react";
import {
	Stack,
	Typography,
	TextField,
	Divider,
	Box,
	MenuItem,
	ToggleButton,
	IconButton,
	useTheme,
} from "@mui/material";
import { default as closeIcon } from "../../../../../assets/icons/close";
import Menu from "../../../Menu";
import ToggleButtonGroup from "../../../ToggleButtonGroup";
import { getDropdownDataByName } from "../../../../../utils/dropdownData";
import SaveViewAsDialog from "../SaveViewAsDialog";
import {
	useAdditionalOptions,
	useFilterData,
	useSourceList,
} from "../../../../../hooks/services/common/table/filter";
import { getFilterFieldListData } from "../../../../../api/filter/filterApi";
import {
	fieldType,
	filterConnectorsList,
	filterFieldNames,
} from "../../../../../utils/FilterUtills";
import { useFilterSaveViewContext } from "../Context/FilterSaveViewContext";
import { useFilterDataContext } from "../Context/FilterDataContext";
import CriteriaList from "./CriteriaList";
import { MenuItemText } from "../../../../../styles/twozo";
import FooterButtons from "./FooterButtons";

export default function SwipeableFilter(props) {
	const { moduleName, hasShareViewPermissions } = props;
	const theme = useTheme();
	const [filterFieldForSource, setFilterFieldForSource] = useState({});

	const {
		filterState,
		updateFilterCount,
		updateFilterUICriteriaList,
		closeSwipeableFilter,
		updateActiveFilterDetails,
	} = useFilterDataContext();

	const { saveViewState, hideSaveViewAsButton, handleCloseSaveViewAsDialog } =
		useFilterSaveViewContext();

	//Filter SourceList
	const { data: filterSourceList } = useSourceList();
	const sourceId =
		filterSourceList?.length >= 1
			? filterSourceList?.find((module) => module.name === moduleName)?.id
			: null;

	//filterConnector
	const filterConnectors = getDropdownDataByName("CONNECTOR_LIST");
	const [connector, setConnector] = useState(filterConnectors[0].name);

	const handleViewToggleButtonGroup = (_, type) => {
		if (type) {
			setConnector(type);
		}
	};

	//Source dropdown
	const [filterSourceListMenuElement, setFilterSourceListMenuElement] =
		useState(null);
	const isOpenSourceMenu = Boolean(filterSourceListMenuElement);
	const [filterIdCounter, setFilterIdCounter] = useState(0);

	const openSourceMenu = (event) => {
		setFilterSourceListMenuElement(event.currentTarget);
	};

	const closeSourceMenu = () => {
		setFilterSourceListMenuElement(null);
	};

	const handleSelectSource = (source) => {
		updateFilterUICriteriaList([
			...filterState.filterUICriteriaList,
			{
				id: "id" + filterIdCounter,
				sourceId: {
					fieldName: filterFieldNames.sourceId,
					value: {
						id: source.id,
						name: source.alternateName,
					},
				},
				connectorId: {
					fieldName: filterFieldNames.connectorId,
					value: {
						id: connector === filterConnectorsList.AND ? 1 : 2,
						name:
							connector === filterConnectorsList.AND
								? filterConnectorsList.AND
								: filterConnectorsList.OR,
					},
				},
				comparatorId: {
					fieldName: filterFieldNames.comparatorId,
					value: {
						id: "",
						name: "",
					},
				},
				fieldId: {
					fieldName: filterFieldNames.fieldId,
					value: {
						id: "",
						name: "",
					},
				},
				optionId: {
					fieldName: filterFieldNames.optionId,
					value: {
						id: "",
						name: "",
					},
				},
				additionalOption: {
					fieldName: filterFieldNames.additionalOption,
					displayName: "",
					value: {
						name: "",
						entityId: "",
					},
				},
				valueEntityId: {
					fieldName: filterFieldNames.valueEntityId,
					value: "",
				},
				value: {
					fieldName: filterFieldNames.value,
					value: "",
				},
				fieldData: {}, // to handle the UI structure
				additionalOptions: {}, // to handle the case which have both value dropdown and additional options
				multiSelectValues: [], //To handle multiselect value
				singleSelectValue: {}, //To handle singleselect value
			},
		]);

		setFilterSourceListMenuElement(null);
		setFilterIdCounter(filterIdCounter + 1);
	};

	//Get filter by Id
	const { status: applyFilterFetchStatus, data: applyFilterApiData } =
		useFilterData(filterState.appliedFilterId);

	useEffect(() => {
		if (applyFilterFetchStatus === "success") {
			if (applyFilterApiData) {
				updateActiveFilterDetails(applyFilterApiData.filter);
			}
		}
	}, [applyFilterFetchStatus, applyFilterApiData, updateActiveFilterDetails]);

	const [selectedoptionid, setSelectedOptionId] = useState(null);

	const { data: additionaloptions } = useAdditionalOptions(
		selectedoptionid,
		!!selectedoptionid
	);

	const hyderateAndGetCriteriaList = useCallback(
		async (criteriaList) => {
			try {
				const hyderatedCriteriaList = [];
				for (const criteria of criteriaList || []) {
					let hyderatedCriteria = { ...criteria };
					//filterFieldForSourceLocalVariable is used here as set state is async, so the next line might not get the data always
					let filterFieldForSourceLocalVariable = {
						...filterFieldForSource,
					};
					if (!filterFieldForSource[criteria.source.id]) {
						const filterFieldsForSource =
							await getFilterFieldListData(criteria.source.id);
						filterFieldForSourceLocalVariable = {
							...filterFieldForSourceLocalVariable,
							[criteria.source.id]: filterFieldsForSource,
						};
						setFilterFieldForSource(
							filterFieldForSourceLocalVariable
						);
					}
					const fieldList =
						filterFieldForSourceLocalVariable[criteria.source.id];
					const fieldData = fieldList.options.find(
						(field) => field.name === criteria.field.name
					);

					if (criteria.field?.additionalOption) {
						setSelectedOptionId(criteria.field.fieldTypeId);

						const selectedOption = additionaloptions?.find(
							(field) =>
								field.id ===
								criteria.field.additionalOption.entityId
						);

						hyderatedCriteria = {
							...hyderatedCriteria,
							additionalOptions: selectedOption,
						};
					}

					if (fieldData) {
						hyderatedCriteria = {
							...hyderatedCriteria,
							fieldDetails: fieldData,
						};
					}

					hyderatedCriteriaList.push(hyderatedCriteria);
				}
				return hyderatedCriteriaList;
			} catch (error) {
				console.error(error);
			}

			return [];
		},
		[additionaloptions, filterFieldForSource]
	);

	const updateActiveFilterViewInCriteriaList = useCallback(
		async (criteriaListOfActiveFilterView = []) => {
			const filterCriteriaList = await hyderateAndGetCriteriaList(
				criteriaListOfActiveFilterView
			);
			if (
				filterCriteriaList.length >= 1 &&
				filterCriteriaList?.[0].fieldDetails
			) {
				const criteriaListInUISpecificFormat = filterCriteriaList.map(
					(criteria) => convertCriteriaToUISpecificFormat(criteria)
				);
				updateFilterUICriteriaList(criteriaListInUISpecificFormat);
				updateFilterCount(filterCriteriaList.length);
			}
		},
		[
			hyderateAndGetCriteriaList,
			updateFilterUICriteriaList,
			updateFilterCount,
		]
	);

	useEffect(() => {
		updateActiveFilterViewInCriteriaList(
			filterState?.activeFilterDetails?.criteriaList || []
		);
	}, [
		filterState?.activeFilterDetails?.criteriaList,
		updateActiveFilterViewInCriteriaList,
	]);

	return (
		<React.Fragment>
			<SaveViewAsDialog
				open={saveViewState.isSaveViewAsDialogOpened}
				onClose={handleCloseSaveViewAsDialog}
				hideSaveViewAsButton={hideSaveViewAsButton}
				sourceId={sourceId}
				hasShareViewPermissions={hasShareViewPermissions}
			/>

			<Menu
				minWidth="250px"
				anchorEl={filterSourceListMenuElement}
				open={isOpenSourceMenu}
				onClose={closeSourceMenu}
				style={{ transform: "translateX(-10px)" }}
			>
				{filterSourceList?.map((source) => (
					<MenuItem
						key={source.id}
						style={{ height: "40px" }}
						onClick={() => handleSelectSource(source)}
					>
						<MenuItemText>{source.alternateName}</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			<Box
				style={{
					height: "100%",
					width: "250px",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box pt={1.5} pb={1} px={1}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-around"
					>
						<IconButton
							style={{ cursor: "pointer" }}
							onClick={closeSwipeableFilter}
						>
							{closeIcon(20, 20, theme.palette.primary.main)}
						</IconButton>

						<Stack>
							<Typography
								fontSize={14}
								fontWeight={500}
								color={theme.palette.primary.main}
							>
								Filter{" "}
								{filterState.filterUICriteriaList.length >= 1
									? `(${filterState.filterUICriteriaList.length})`
									: ""}
							</Typography>
							<TextField
								onClick={openSourceMenu}
								variant="standard"
								placeholder="Select Module..."
								inputProps={{
									readOnly: true,
									style: {
										fontSize: "14px",
									},
								}}
								InputProps={{ disableUnderline: true }}
							/>
						</Stack>
					</Stack>
				</Box>

				<Divider />

				<Box
					style={{
						flex: "1 1 auto",
						overflowY: "auto",
					}}
				>
					{(filterState.filterUICriteriaList.length >= 1 ||
						filterState.activeFilterDetails?.criteriaList?.length >=
							1) && (
						<Stack>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-around"
								p={1}
							>
								<ToggleButtonGroup
									value={connector}
									onChange={handleViewToggleButtonGroup}
								>
									{filterConnectors.map((data) => (
										<ToggleButton
											key={data.value}
											value={data.name}
										>
											{data.name}
										</ToggleButton>
									))}
								</ToggleButtonGroup>
							</Stack>

							<Stack alignItems="center">
								{connector === "AND" || connector === "OR" ? (
									<CriteriaList connector={connector} />
								) : null}
							</Stack>
						</Stack>
					)}
				</Box>

				<Divider />

				<FooterButtons />
			</Box>
		</React.Fragment>
	);
}

const hasMultipleOptions = (data) => {
	return (
		data?.fieldDetails?.fieldType.name === fieldType.multiSelect ||
		data?.fieldDetails?.fieldType.name === fieldType.tags ||
		data?.fieldDetails?.fieldType.name === fieldType.contactsOnboard
	);
};

const convertCriteriaToUISpecificFormat = (criteria) => {
	return {
		id: criteria.id,
		sourceId: {
			fieldName: filterFieldNames.sourceId,
			id: criteria.id ? criteria.id : "",
			value: {
				id: criteria?.source.id,
				name: criteria?.source.name,
			},
		},
		connectorId: {
			fieldName: filterFieldNames.connectorId,
			value: {
				id: criteria?.connector.id,
				name: criteria?.connector.name,
			},
		},
		comparatorId: {
			fieldName: filterFieldNames.comparatorId,
			value: {
				id: criteria?.comparator.id,
				name: criteria?.comparator.name,
			},
		},
		fieldId: {
			fieldName: filterFieldNames.fieldId,
			value: {
				id: criteria?.field.fieldId ? criteria?.field.fieldId : "",
				name: criteria?.field.fieldId ? criteria?.field.name : "",
			},
		},
		optionId: {
			fieldName: filterFieldNames.optionId,
			value: {
				id: criteria?.field.optionId ? criteria?.field.optionId : "",
				name: criteria?.field.optionId ? criteria?.field.name : "",
			},
		},
		additionalOption: {
			fieldName: filterFieldNames.additionalOption,
			displayName: criteria?.field?.additionalOption
				? criteria?.field?.additionalOption.displayName
				: "",
			value: {
				name: criteria?.field?.additionalOption
					? criteria?.field?.additionalOption.name
					: "",
				entityId: criteria?.field?.additionalOption
					? criteria?.field?.additionalOption.entityId
					: "",
				id: criteria?.field?.additionalOption
					? criteria?.field?.additionalOption.id
					: "",
			},
		},
		valueEntityId: {
			fieldName: filterFieldNames.valueEntityId,
			value:
				criteria.values && !hasMultipleOptions(criteria)
					? criteria?.values[0]?.valueEntityId
						? criteria?.values[0]?.valueEntityId
						: ""
					: "",
		},
		value: {
			fieldName: filterFieldNames.value,
			id:
				criteria.values && !hasMultipleOptions(criteria)
					? criteria?.values[0]?.id
					: "",
			value:
				criteria.values && !hasMultipleOptions(criteria)
					? criteria?.values[0]?.value
					: "",
		},
		fieldData: criteria.fieldDetails,
		additionalOptions: criteria?.additionalOptions
			? criteria.additionalOptions
			: {},
		multiSelectValues:
			criteria.values && hasMultipleOptions(criteria)
				? criteria?.values.map((option) => ({
						id: option?.id,
						value: option?.valueEntityId,
						name: option?.value,
					}))
				: [],
		singleSelectValue:
			criteria.values && !hasMultipleOptions(criteria)
				? {
						name: criteria?.values[0]?.value,
						value: criteria?.values[0]?.valueEntityId,
					}
				: {},
	};
};

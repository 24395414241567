import React, { useState } from "react";
import { useValueDropDown } from "../../../../../../../../hooks/services/common/table/filter";
import {
	Box,
	MenuItem,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import Menu from "../../../../../../Menu";
import { default as DropDownIcon } from "../../../../../../../../assets/icons/dropDown";
import { useFilterDataContext } from "../../../../Context/FilterDataContext";
import { fieldType } from "../../../../../../../../utils/FilterUtills";
import { MenuItemText } from "../../../../../../../../styles/twozo";

export default function SubfieldValueInput(props) {
	const { filterCondition, onChange } = props;

	const theme = useTheme();

	const styles = {
		header: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			paddingLeft: "10px",
		},
		data: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			height: "38px",
			padding: "0px 10px",
			backgroundColor: "rgba(51, 188, 126, 0.12)",
		},
		focusTextField: {
			whiteSpace: "normal",
			overflow: "visible",
			textOverflow: "clip",
		},
		defaultTextField: {
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	};

	const { enableApplyButton } = useFilterDataContext();

	//TextField
	const [focusTextField, setFocusTextField] = useState(false);

	const onFocusTextField = () => {
		setFocusTextField(true);
	};

	const onBlurTextField = () => {
		setFocusTextField(false);
	};

	const handleInputChange = (event) => {
		const { value } = event.target;

		const updatedFilterCondition = {
			...filterCondition,
			value: {
				...filterCondition.value,
				value: value,
			},
		};
		onChange(updatedFilterCondition);
		enableApplyButton();
	};

	//Dropdown for additional option field
	const selectedAdditionalOption = filterCondition?.additionalOptions || {};
	const dropdownName = selectedAdditionalOption?.valueDropdown
		?.valueDropdownName
		? selectedAdditionalOption?.valueDropdown?.valueDropdownName
		: null;
	const dropdownRequest = { name: dropdownName };

	const { data: dropdownValue } = useValueDropDown(
		dropdownRequest,
		selectedAdditionalOption?.id,
		!!selectedAdditionalOption?.valueDropdown
	);

	const [dropdownMenuElement, setDropdownMenuElement] = useState(null);
	const isOpenDropdownMenu = Boolean(dropdownMenuElement);

	const openDropdownMenu = (event) => {
		setDropdownMenuElement(event.currentTarget);
	};

	const closeDropdownMenu = () => {
		setDropdownMenuElement(null);
	};

	const handleSelectValue = (selectedValue) => {
		const updatedFilterCondition = {
			...filterCondition,
			valueEntityId: {
				...filterCondition.valueEntityId,
				value: selectedValue.value
					? selectedValue.value
					: selectedValue.id,
			},
			value: {
				...filterCondition.value,
				value: selectedValue.name,
			},
		};
		onChange(updatedFilterCondition);
		enableApplyButton();
		setDropdownMenuElement(null);
	};

	const getField = (filterCondition) => {
		switch (filterCondition?.additionalOptions?.fieldType?.name) {
			case fieldType.dropdown:
				return (
					<Stack
						direction="row"
						style={{
							...styles.data,
							cursor: "pointer",
							borderRadius: "0px 0px 8px 8px",
							borderBottom: dropdownMenuElement
								? `1px solid ${theme.palette.primary.main}`
								: null,
						}}
						onClick={(event) => openDropdownMenu(event)}
					>
						{filterCondition.value.value ? (
							<Typography fontSize={14} noWrap>
								{filterCondition.value.value}
							</Typography>
						) : (
							<Typography
								sx={{
									fontSize: "14px",
									color: "rgba(0,0,0,0.4)",
								}}
							>
								Select
							</Typography>
						)}
						<Box sx={{ pl: 1 }}>{DropDownIcon(12, 12)}</Box>
					</Stack>
				);
			default:
				return (
					<Stack
						direction="row"
						style={{
							...styles.data,
							borderRadius: "0px 0px 8px 8px",
							borderBottom: focusTextField
								? `1px solid ${theme.palette.primary.main}`
								: null,
						}}
					>
						<TextField
							name={filterCondition.value.fieldName}
							value={filterCondition.value.value}
							onChange={(event) => handleInputChange(event)}
							variant="standard"
							InputProps={{
								disableUnderline: true,
							}}
							sx={{
								"& .MuiInputBase-input": focusTextField
									? styles.focusTextField
									: styles.defaultTextField,
							}}
							placeholder="Enter Here"
							onFocus={() => onFocusTextField()}
							onBlur={() => onBlurTextField()}
						/>
					</Stack>
				);
		}
	};
	return (
		<React.Fragment>
			<Menu
				minWidth="202px"
				anchorEl={dropdownMenuElement}
				open={isOpenDropdownMenu}
				onClose={closeDropdownMenu}
				style={{ transform: "translateY(3px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				{dropdownValue?.map((option, optionIndex) => (
					<MenuItem
						style={{ height: "40px" }}
						key={optionIndex}
						onClick={() => handleSelectValue(option)}
					>
						<MenuItemText>{option.name}</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			{getField(filterCondition)}
		</React.Fragment>
	);
}

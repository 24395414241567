import React, { useState, useMemo } from "react";
import Dialog from "../Dialog";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as CloseIcon } from "../../../assets/icons/close";
import {
	Box,
	Button,
	Divider,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import MenuSearchBar from "../MenuSearchBar";
import { useUpdateFieldList } from "../../../hooks/services/common/field";
import { FormProvider, useForm } from "react-hook-form";
import Field from "./Field";
import { FormFieldName } from "../../../styles/twozo";

const dialogBoxSx = {
	"& .MuiPaper-root": {
		width: "578px",
		borderRadius: "10px",
		position: "absolute",
		top: 40,
	},
};

export default function UpdateFieldForm(props) {
	const { moduleName, onSave, open, onClose, isSaving } = props;

	// query call
	const { data: fieldListData } = useUpdateFieldList(moduleName);

	// update field form
	const formMethods = useForm({ reValidateMode: false });
	const { handleSubmit, unregister, reset } = formMethods;

	const theme = useTheme();
	const [fieldToUpdate, setFieldToUpdate] = useState([
		{ fieldName: "", id: "fieldId" },
	]);
	const [selectedFieldIds, setSelectedFieldIds] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [fieldState, setFieldState] = useState({
		isEmptyField: true,
		isErrorMode: false,
	});

	// Static data: The fields array is derived from fieldListData and does not involve expensive calculations or frequent updates.
	// Therefore, using useMemo is unnecessary here.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fields = fieldListData?.fields || [];

	const subFields = fieldListData?.subFields || [];

	const handleSelectChange = (event, fields, index) => {
		const { value } = event.target;
		let fieldData = fields.find((field) => field.fieldName === value);

		// Unregister the previously selected field
		if (fieldToUpdate[index].id) {
			unregister(fieldToUpdate[index].id + "");
		}

		setFieldToUpdate((fieldToUpdate) => {
			let updatedFieldToUpdate = fieldToUpdate.map(
				(field, fieldIndex) => {
					if (fieldIndex === index) {
						return fieldData;
					}
					return { ...field };
				}
			);

			return [...updatedFieldToUpdate];
		});

		setSelectedFieldIds((selectedFieldIds) => {
			selectedFieldIds.splice(index, 1, fieldData.id);
			return [...selectedFieldIds];
		});

		setFieldState((fieldState) => ({
			...fieldState,
			isEmptyField: false,
			isErrorMode: false,
		}));
		setSearchValue("");
	};

	const onAddMoreClick = () => {
		if (fieldState.isErrorMode) {
			return;
		}

		if (fieldState.isEmptyField) {
			setFieldState((fieldState) => ({
				...fieldState,
				isErrorMode: true,
			}));
		} else {
			setFieldState((fieldState) => ({
				...fieldState,
				isEmptyField: true,
			}));
			setFieldToUpdate((fieldToUpdate) => [
				...fieldToUpdate,
				{ fieldName: "" },
			]);
		}
	};

	const handleSearchField = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const handleRemoveField = (index, field) => {
		setFieldToUpdate((fieldToUpdate) => {
			fieldToUpdate.splice(index, 1);
			return [...fieldToUpdate];
		});
		setSelectedFieldIds((selectedFieldIds) => {
			selectedFieldIds.splice(index, 1);
			return [...selectedFieldIds];
		});
		setFieldState((fieldState) => ({
			...fieldState,
			isEmptyField: false,
			isErrorMode: false,
		}));
		unregister(field.id + "");
	};

	const handleCloseMenu = () => {
		onClose();
		setFieldToUpdate([{ fieldName: "" }]);
		setSelectedFieldIds([]);
		setFieldState((fieldState) => ({
			...fieldState,
			isEmptyField: true,
			isErrorMode: false,
		}));
		reset();
	};

	const renderEmptyView = () => {
		return (
			<Box sx={{ cursor: "not-allowed", opacity: 0.7 }} width="100%">
				<TextField
					placeholder="Enter Value"
					sx={{ pointerEvents: "none", width: "100%" }}
				/>
			</Box>
		);
	};

	const filteredFields = useMemo(() => {
		if (Array.isArray(fields)) {
			if (searchValue?.trim()) {
				return fields.filter((field) =>
					field?.fieldName
						?.toLowerCase()
						?.startsWith(searchValue?.toLowerCase())
				);
			}
			return fields;
		}
		return [];
	}, [searchValue, fields]);

	const renderNoResultsMessage = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Dialog open={open} title="Update Field" sx={dialogBoxSx}>
				<Box
					display="flex"
					flexDirection="column"
					width="100%"
					maxHeight="600px"
					p={1}
				>
					<Stack direction="row" px={1} pb={0.5}>
						<Box width="45%">
							<Typography fontSize={13} fontWeight={500}>
								Select Field
							</Typography>
						</Box>

						<Box style={{ width: "5%" }}></Box>

						<Box width="45%">
							<Typography fontSize={13} fontWeight={500}>
								Value
							</Typography>
						</Box>

						<Box style={{ width: "5%" }}></Box>
					</Stack>

					<Stack
						style={{
							minHeight: 0,
							maxHeight: "410px",
							flex: "1 1 auto",
							overflow: "auto",
						}}
						spacing={0.5}
						px={1}
					>
						<FormProvider {...formMethods}>
							{fieldToUpdate.map((field, index) => (
								<Stack
									key={field.id}
									direction="row"
									width="100%"
								>
									<Box
										height="100%"
										display="flex"
										style={{ width: "45%" }}
									>
										<Select
											size="small"
											displayEmpty
											name="field"
											sx={{ width: "100%" }}
											onChange={(event) =>
												handleSelectChange(
													event,
													fields,
													index
												)
											}
											value={field.fieldName}
											fullWidth
											renderValue={(value) => {
												if (
													value === "" ||
													value === undefined
												) {
													return (
														<Typography
															color={
																"rgba(0, 0, 0, 0.5)"
															}
															fontSize={14}
														>
															Select
														</Typography>
													);
												} else {
													return (
														<FormFieldName>
															{field.fieldName}
															{field.config
																.required ? (
																<span
																	style={{
																		color: theme
																			.palette
																			.error
																			.main,
																		marginLeft:
																			"5px",
																	}}
																>
																	*
																</span>
															) : null}
														</FormFieldName>
													);
												}
											}}
											MenuProps={{
												autoFocus: false,
												PaperProps: {
													style: {
														maxHeight: "375px",
														marginTop: "5px",
														borderRadius: "5px",
													},
												},
											}}
											error={
												fieldState.isErrorMode &&
												field.fieldName === ""
											}
											onClose={() => setSearchValue("")}
										>
											<Box
												p={0.5}
												sx={{
													backgroundColor: "#fff",
												}}
												marginTop="-8px"
												position="sticky"
												top={0}
												zIndex={1}
											>
												<MenuSearchBar
													value={searchValue}
													onChange={handleSearchField}
													autoFocus={false}
												/>
											</Box>

											{filteredFields?.length > 0
												? filteredFields?.map(
														(fieldData) => (
															<MenuItem
																key={
																	fieldData.id
																}
																value={
																	fieldData.fieldName
																}
																sx={{
																	height: "40px",
																}}
																disabled={selectedFieldIds.includes(
																	fieldData.id
																)}
															>
																<FormFieldName>
																	{
																		fieldData.fieldName
																	}
																	{fieldData
																		.config
																		.required ? (
																		<span
																			style={{
																				color: theme
																					.palette
																					.error
																					.main,
																				marginLeft:
																					"5px",
																			}}
																		>
																			*
																		</span>
																	) : null}
																</FormFieldName>
															</MenuItem>
														)
													)
												: renderNoResultsMessage()}
										</Select>
									</Box>

									<Box
										style={{ width: "5%" }}
										px={0.5}
										pt={2.8}
									>
										<Divider />
									</Box>

									<Box
										height="100%"
										display="flex"
										width="45%"
										flexGrow={1}
										overflow="hidden"
									>
										{field.fieldName === "" ? (
											renderEmptyView()
										) : (
											<Field
												field={field}
												subFields={subFields}
											/>
										)}
									</Box>

									{fieldToUpdate.length !== 1 && (
										<Box
											display="flex"
											style={{
												cursor: "pointer",
												paddingLeft: "8px",
											}}
											onClick={() =>
												handleRemoveField(index, field)
											}
											pt={1.5}
										>
											{CloseIcon(20, 20, "grey")}
										</Box>
									)}
								</Stack>
							))}
						</FormProvider>
					</Stack>

					<Box>
						<Box pt={1}>
							<Button
								onClick={onAddMoreClick}
								startIcon={AddIcon(
									20,
									20,
									theme.palette.primary.main
								)}
								size="small"
								style={{
									opacity: fieldState.isErrorMode ? 0.6 : 1,
								}}
							>
								Add More Field
							</Button>
						</Box>

						<Stack
							p={1}
							direction="row"
							justifyContent="flex-end"
							spacing={2}
							width="100%"
						>
							<Box>
								<Button
									variant="contained"
									color="secondary"
									disableElevation
									disabled={isSaving}
									onClick={handleCloseMenu}
								>
									Cancel
								</Button>
							</Box>

							<Box>
								<Button
									variant="contained"
									disableElevation
									sx={{
										"&.Mui-disabled": {
											backgroundColor:
												theme.palette.primary.main,
											color: "rgba(255,255,255, 0.6)",
										},
									}}
									disabled={
										fieldState.isErrorMode || isSaving
									}
									onClick={handleSubmit(onSave)}
								>
									Save
								</Button>
							</Box>
						</Stack>
					</Box>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}

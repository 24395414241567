import { default as DropDownIcon } from "../../../../assets/icons/dropDown";
import {
	Box,
	CircularProgress,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { TableHeaderLabel } from "../../../../styles/twozo";
import TagsCard from "./TagsCard";
import React, { useEffect, useRef, useState } from "react";
import { tagOperations } from "../../../../utils/tagOperations";
import { useTagContext } from "../TagsContext";

export default function TagsList(props) {
	const {
		tags,
		updateTags,
		editedTagData,
		updateEditedTagData,
		deleteDialogSubTitle,
		performCreateUpdateDelete,
		tagListLastRowRef,
		isLoadingTagList,
		isFetchingTagList,
	} = props;

	const tagListRef = useRef();
	const { tagState } = useTagContext();

	const [deletedTagData, setDeletedTagData] = useState({});
	const [tagListStartingPosition, setTagListStartingPosition] = useState(0);
	const [
		isDeleteConfirmationDialogOpened,
		setIsDeleteConfirmationDialogOpened,
	] = useState(false);

	const handleOpenDeleteConfirmationDialog = () => {
		setIsDeleteConfirmationDialogOpened(true);
	};

	const handleCloseDeleteConfirmationDialog = () => {
		setIsDeleteConfirmationDialogOpened(false);
	};

	const updateDeletedTagData = (deletedTagData) => {
		setDeletedTagData(deletedTagData);
	};

	const getNewTagObj = () => {
		return {
			tag: "",
			isNewTag: true,
		};
	};

	const handleDeleteTag = () => {
		setIsDeleteConfirmationDialogOpened();
		performCreateUpdateDelete(tagOperations.delete, deletedTagData);
	};

	const onTagUpdate = (updatedTag, updatedTagIndex) => {
		updateTags(updatedTag, updatedTagIndex);
	};

	useEffect(() => {
		if (tagListRef?.current) {
			setTagListStartingPosition(
				tagListRef.current?.getBoundingClientRect()?.top
			);
		}
	}, []);

	return (
		<React.Fragment>
			<DeleteDialog
				open={isDeleteConfirmationDialogOpened}
				onCancel={handleCloseDeleteConfirmationDialog}
				onDelete={handleDeleteTag}
				title={`Are you sure you want to delete tag "${deletedTagData.tag}"?`}
				subtitle={deleteDialogSubTitle}
			/>

			<Box>
				<Stack direction="row" pb={1} px={2} width="100%">
					<Stack
						direction="row"
						width="35%"
						alignItems="center"
						pl={2}
					>
						<TableHeaderLabel>Name</TableHeaderLabel>

						<Box display="flex">
							<IconButton>
								{DropDownIcon(12, 12, "rgba(0, 0, 0, 0.6")}
							</IconButton>
						</Box>
					</Stack>

					<Stack direction="row" width="18%" alignItems="center">
						<TableHeaderLabel>Created By</TableHeaderLabel>

						<Box display="flex">
							<IconButton>
								{DropDownIcon(12, 12, "rgba(0, 0, 0, 0.6")}
							</IconButton>
						</Box>
					</Stack>

					<Stack direction="row" width="14%" alignItems="center">
						<TableHeaderLabel>Created At</TableHeaderLabel>

						<Box display="flex">
							<IconButton>
								{DropDownIcon(12, 12, "rgba(0, 0, 0, 0.6")}
							</IconButton>
						</Box>
					</Stack>

					<Stack direction="row" width="14%" alignItems="center">
						<TableHeaderLabel>Last Used</TableHeaderLabel>

						<Box display="flex">
							<IconButton>
								{DropDownIcon(12, 12, "rgba(0, 0, 0, 0.6")}
							</IconButton>
						</Box>
					</Stack>

					<Stack width="19%"></Stack>
				</Stack>

				<Stack
					ref={tagListRef}
					px={2}
					pb={1}
					spacing={1}
					style={{
						height: `calc(100vh - ${tagListStartingPosition}px)`,
						overflowY: "auto",
					}}
				>
					{tags.map((tag, index) => (
						<Box key={tag.id}>
							<TagsCard
								tag={tag}
								editedTagData={editedTagData}
								performCreateUpdateDelete={
									performCreateUpdateDelete
								}
								onTagUpdate={(tagData) =>
									onTagUpdate(tagData, index)
								}
								handleOpenDeleteConfirmationDialog={
									handleOpenDeleteConfirmationDialog
								}
								updateEditedTagData={updateEditedTagData}
								updateDeletedTagData={updateDeletedTagData}
							/>
						</Box>
					))}

					{tagState.isCreateMode ? (
						<TagsCard
							tag={getNewTagObj()}
							performCreateUpdateDelete={
								performCreateUpdateDelete
							}
						/>
					) : null}

					<Box ref={tagListLastRowRef}>
						{!isLoadingTagList && isFetchingTagList && (
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="center"
								spacing={1}
								py={2}
							>
								<CircularProgress size={18} />

								<Typography fontSize={12}>
									Loading More Data
								</Typography>
							</Stack>
						)}
					</Box>
				</Stack>
			</Box>
		</React.Fragment>
	);
}

import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	Button,
	Stack,
	TextField,
	Typography,
	ToggleButton,
	Checkbox,
	useTheme,
	MenuItem,
	alpha,
} from "@mui/material";
import Dialog from "../../../Dialog";
import ToggleButtonGroup from "../../../ToggleButtonGroup";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDown";
import Menu from "../../../Menu";
import { getDropdownDataByName } from "../../../../../utils/dropdownData";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import {
	useFilterData,
	useUpdateFilterMutation,
	useUserTeamTerritoryList,
} from "../../../../../hooks/services/common/table/filter";
import { removeFieldsWithEmptyValues } from "../../../../../utils/common";
import { filterVisibility } from "../../../../../utils/FilterUtills";
import SelectedView from "./SelectedView";
import { MenuItemText } from "../../../../../styles/twozo";

export default function EditViewDialog(props) {
	const { onClose, open, filterId, sourceId, hasShareViewPermissions } =
		props;
	const theme = useTheme();

	const styles = {
		tableData: {
			backgroundColor: "#FFF",
			borderRadius: "8px",
			justifyContent: "space-between",
			alignItems: "center",
			padding: "4px",
		},
		disabled: {
			opacity: hasShareViewPermissions ? 1 : 0.6,
			pointerEvents: hasShareViewPermissions ? "auto" : "none",
		},
	};

	const updateFilterMutation = useUpdateFilterMutation(sourceId);

	const optionsType = {
		user: "USER",
		team: "TEAM",
		territory: "TERRITORY",
	};

	//To Get Filter Details
	const [activeFilterDetails, setActiveFilterDetails] = useState({});

	const { status: applyFilterFetchStatus, data: applyFilterApiData } =
		useFilterData(filterId);

	useEffect(() => {
		if (applyFilterFetchStatus === "success") {
			if (applyFilterApiData) {
				setActiveFilterDetails(applyFilterApiData.filter);
			}
		}
	}, [applyFilterFetchStatus, applyFilterApiData, setActiveFilterDetails]);

	// PermissionList
	const permissionList = getDropdownDataByName("PERMISSION_LIST");
	const [permissionMenuElement, setPermissionMenuElement] = useState(null);
	const isPermissionMenuOpen = Boolean(permissionMenuElement);
	const [selectedPermission, setSelectedPermission] = useState(
		permissionList[0]
	);

	const openPermissionMenu = (event) => {
		setPermissionMenuElement(event.currentTarget);
	};

	const closePermissionMenu = () => {
		setPermissionMenuElement(null);
	};

	const handleSelectPermission = (providedAccess) => {
		setSelectedPermission(providedAccess);
		setPermissionMenuElement(null);
	};

	//VisibilityList
	const visibilityList = getDropdownDataByName("VISIBILITY_LIST");
	const [selectedVisibilityTypeId, setSelectedVisibilityTypeId] = useState(
		visibilityList[0].value
	);

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setSelectedVisibilityTypeId(type);
		}
	};

	//Prevent Share
	const [isPreventShareFilter, setIsPreventShareFilter] = useState(true);

	const handlePreventShareFilter = () => {
		setIsPreventShareFilter(!isPreventShareFilter);
	};

	//Filter Name
	const [viewName, setViewName] = useState("");
	const handleViewNameChange = (event) => {
		setViewName(event.target.value);
	};

	//user team territory list
	const { data: userTeamTerritoryList } = useUserTeamTerritoryList();
	const [selectedUserTeamTerritoryValue, setSelectedUserTeamTerritoryValue] =
		useState([]);

	const selectedUserList = useMemo(() => {
		if (selectedUserTeamTerritoryValue.length > 0) {
			return selectedUserTeamTerritoryValue.filter(
				(selectedValue) => selectedValue.type === optionsType.user
			);
		}
		return [];
	}, [selectedUserTeamTerritoryValue]);

	const selectedTeamList = useMemo(() => {
		if (selectedUserTeamTerritoryValue.length > 0) {
			return selectedUserTeamTerritoryValue.filter(
				(selectedValue) => selectedValue.type === optionsType.team
			);
		}
		return [];
	}, [selectedUserTeamTerritoryValue]);

	const selectedTerritoryList = useMemo(() => {
		if (selectedUserTeamTerritoryValue.length > 0) {
			return selectedUserTeamTerritoryValue.filter(
				(selectedValue) => selectedValue.type === optionsType.territory
			);
		}
		return [];
	}, [selectedUserTeamTerritoryValue]);

	const updateUserTeamTerritoryValue = (value) => {
		setSelectedUserTeamTerritoryValue(value);
	};

	const displaySelectedTable = () => {
		return selectedVisibilityTypeId === filterVisibility.selected;
	};

	useEffect(() => {
		if (activeFilterDetails) {
			const getInitialSelectedValues = (initialValues) => {
				return userTeamTerritoryList?.filter((userTeamTerritory) =>
					initialValues?.some(
						(initialValue) =>
							initialValue.id === userTeamTerritory.value
					)
				);
			};
			setViewName(activeFilterDetails?.name ?? activeFilterDetails?.name);
			setSelectedVisibilityTypeId(
				activeFilterDetails?.filterVisibility?.visibilityId
			);
			setIsPreventShareFilter(
				activeFilterDetails?.filterVisibility?.visibilityId !==
					filterVisibility.justMe
					? activeFilterDetails?.filterVisibility?.preventShare
					: true
			);
			setSelectedPermission(
				activeFilterDetails?.filterVisibility?.visibilityPermissionId
					? permissionList.find(
							(permission) =>
								permission.value ===
								activeFilterDetails?.filterVisibility
									?.visibilityPermissionId
						)
					: permissionList[0]
			);

			let users =
				getInitialSelectedValues(
					activeFilterDetails?.filterVisibility?.users
				) || [];
			let teams =
				getInitialSelectedValues(
					activeFilterDetails?.filterVisibility?.teams
				) || [];
			let territories =
				getInitialSelectedValues(
					activeFilterDetails?.filterVisibility?.territories
				) || [];

			setSelectedUserTeamTerritoryValue([
				...users,
				...teams,
				...territories,
			]);
		}
	}, [activeFilterDetails]);

	const getUpdateFilterDetails = () => {
		const requestData = { filterVisibility: {} };
		requestData.criteriaList = [];

		for (
			let index = 0;
			index < activeFilterDetails.criteriaList.length;
			index++
		) {
			const requestCriteriaListData = { field: {} };

			if (activeFilterDetails.criteriaList[index].field.optionId) {
				requestCriteriaListData.field.optionId =
					activeFilterDetails.criteriaList[index].field.optionId;
			} else {
				requestCriteriaListData.field.fieldId =
					activeFilterDetails.criteriaList[index].field.fieldId;
			}

			if (
				activeFilterDetails?.criteriaList[index]?.field
					?.additionalOption
			) {
				requestCriteriaListData.field.additionalOption = {};
				requestCriteriaListData.field.additionalOption.id =
					activeFilterDetails.criteriaList[
						index
					].field.additionalOption.id;
				requestCriteriaListData.field.additionalOption.name =
					activeFilterDetails.criteriaList[
						index
					].field.additionalOption.name;
				requestCriteriaListData.field.additionalOption.entityId =
					activeFilterDetails.criteriaList[
						index
					].field.additionalOption.entityId;
			}

			requestCriteriaListData.sourceId =
				activeFilterDetails.criteriaList[index].source.id;
			requestCriteriaListData.connectorId =
				activeFilterDetails.criteriaList[index].connector.id;
			requestCriteriaListData.comparatorId =
				activeFilterDetails.criteriaList[index].comparator.id;
			requestCriteriaListData.id =
				activeFilterDetails.criteriaList[index].id;

			if (activeFilterDetails.criteriaList[index].values) {
				const inputValue = {};
				inputValue.value =
					activeFilterDetails.criteriaList[index].values[0].value;
				inputValue.id =
					activeFilterDetails.criteriaList[index].values[0].id;

				if (
					activeFilterDetails?.criteriaList[index]?.values[0]
						?.valueEntityId
				) {
					inputValue.valueEntityId =
						activeFilterDetails?.criteriaList[
							index
						]?.values[0]?.valueEntityId;
				}

				requestCriteriaListData.values = [inputValue];
			}

			requestData.criteriaList.push(requestCriteriaListData);
		}

		requestData.name = viewName;
		requestData.sourceId = sourceId;
		requestData.filterVisibility.visibilityId = selectedVisibilityTypeId;
		requestData.filterVisibility.id =
			activeFilterDetails?.filterVisibility?.id;
		requestData.filterVisibility.visibilityPermissionId =
			selectedVisibilityTypeId === filterVisibility.everyone ||
			selectedVisibilityTypeId === filterVisibility.selected
				? selectedPermission.value
				: "";
		requestData.filterVisibility.preventShare =
			selectedVisibilityTypeId === filterVisibility.everyone ||
			selectedVisibilityTypeId === filterVisibility.selected
				? isPreventShareFilter
				: "";
		requestData.id = activeFilterDetails?.id ? activeFilterDetails?.id : "";
		if (selectedUserList.length > 0) {
			requestData.filterVisibility.users = selectedUserList.map(
				(user) => user.value
			);
		}
		if (selectedTeamList.length > 0) {
			requestData.filterVisibility.teams = selectedTeamList.map(
				(team) => team.value
			);
		}
		if (selectedTerritoryList.length > 0) {
			requestData.filterVisibility.territories =
				selectedTerritoryList.map((territory) => territory.value);
		}

		removeFieldsWithEmptyValues(requestData.filterVisibility);
		removeFieldsWithEmptyValues(requestData);
		return requestData;
	};

	//Filter Update
	const updateFilter = () => {
		let requestData = getUpdateFilterDetails();
		updateFilterMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.filterUpdated,
				});
				onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="150px"
				anchorEl={permissionMenuElement}
				open={isPermissionMenuOpen}
				onClose={closePermissionMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{permissionList.map((access) => (
					<MenuItem
						key={access.value}
						onClick={() => handleSelectPermission(access)}
						style={{
							height: "40px",
							backgroundColor:
								access.value === selectedPermission.value
									? alpha(theme.palette.primary.main, 0.1)
									: "transparent",
						}}
					>
						<MenuItemText>{access.name}</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			<Dialog open={open} title="Edit View">
				<Box p={2}>
					<Stack spacing={1.5}>
						<Stack>
							<Typography
								fontSize={13}
								fontWeight={500}
								padding="5px"
							>
								Filter Name
							</Typography>
							<TextField
								value={viewName}
								onChange={(event) =>
									handleViewNameChange(event)
								}
							/>
						</Stack>

						<Stack style={styles.disabled}>
							<Typography
								fontSize={13}
								fontWeight={500}
								padding="5px"
							>
								Visible To
							</Typography>

							<Stack direction="row" spacing={3}>
								<Box>
									<ToggleButtonGroup
										value={selectedVisibilityTypeId}
										onChange={handleToggleButtonGroup}
									>
										{visibilityList.map((data) => (
											<ToggleButton
												key={data.value}
												value={data.value}
											>
												{data.name}
											</ToggleButton>
										))}
									</ToggleButtonGroup>
								</Box>

								{(selectedVisibilityTypeId ===
									filterVisibility.everyone ||
									selectedVisibilityTypeId ===
										filterVisibility.selected) && (
									<Button
										variant="contained"
										endIcon={DropDownIcon(
											16,
											16,
											theme.palette.primary.main
										)}
										color="secondary"
										disableElevation
										onClick={openPermissionMenu}
									>
										{selectedPermission.name}
									</Button>
								)}
							</Stack>
						</Stack>

						{displaySelectedTable() && (
							<SelectedView
								updateUserTeamTerritoryValue={
									updateUserTeamTerritoryValue
								}
								setSelectedUserTeamTerritoryValue={
									setSelectedUserTeamTerritoryValue
								}
								selectedUserList={selectedUserList}
								selectedTeamList={selectedTeamList}
								selectedTerritoryList={selectedTerritoryList}
							/>
						)}

						{(selectedVisibilityTypeId ===
							filterVisibility.everyone ||
							selectedVisibilityTypeId ===
								filterVisibility.selected) && (
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
								padding="8px 0"
								style={styles.disabled}
							>
								<Checkbox
									checked={isPreventShareFilter}
									onChange={handlePreventShareFilter}
								/>
								<Typography fontSize={14} fontWeight={500}>
									Prevent recepients from sharing this view
									with others
								</Typography>
							</Stack>
						)}

						<Stack
							direction="row"
							justifyContent="flex-end"
							spacing={2}
						>
							<Button
								variant="contained"
								color="secondary"
								disableElevation
								onClick={() => onClose()}
							>
								Cancel
							</Button>

							<Button
								variant="contained"
								disableElevation
								sx={{ width: "112px" }}
								onClick={() => updateFilter()}
							>
								Update
							</Button>
						</Stack>
					</Stack>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}

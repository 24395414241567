import { Controller, useFormContext } from "react-hook-form";
import { getDropdownDataByName } from "../../../../../../../../utils/dropdownData";
import { handleFieldValidation } from "../../../../../../../../utils/fieldValidation";
import Dropdown from "../../../../../../../Elements/AddForm/Field/FieldComponents/DropdownComponent/DropdownBaseComponent/Dropdown";

export default function StaticDropdownFieldComponent(params) {
	const { field, dropdownName } = params;
	const { control, clearErrors } = useFormContext();

	const options = getDropdownDataByName(dropdownName);

	return (
		<Controller
			control={control}
			name={field.id + ""}
			defaultValue=""
			rules={handleFieldValidation(field)}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<Dropdown
					options={options}
					onChange={onChange}
					value={value}
					field={field}
					clearErrors={clearErrors}
					error={error}
				/>
			)}
		></Controller>
	);
}

import React, { useState } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MenuItemText, TableCellText } from "../../../../styles/twozo";
import Menu from "../../Menu";

export default function Deals(props) {
	const { data } = props;
	const theme = useTheme();
	const navigate = useNavigate();

	const [dealsMenuElement, setDealsMenuElement] = useState(null);
	const isDealsMenuOpened = Boolean(dealsMenuElement);

	const openDealsMenu = (event) => {
		event.stopPropagation();
		setDealsMenuElement(event.currentTarget);
	};

	const closeDealsMenu = (event) => {
		event.stopPropagation();
		setDealsMenuElement(null);
	};

	const navigateToDeal = (event, dealId) => {
		event?.stopPropagation();
		navigate(`/deals/${dealId?.toString()}`);
	};

	const displayDividerline = (dealIndex) => {
		return data?.dealList?.length - 1 !== dealIndex;
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="360px"
				anchorEl={dealsMenuElement}
				open={isDealsMenuOpened}
				onClose={closeDealsMenu}
				onClick={(event) => event.stopPropagation()}
				style={{ marginTop: "8px" }}
				sx={{
					"& .MuiMenu-list": {
						padding: "4px 0px",
					},
				}}
			>
				{data?.dealList?.map((dealDetail, index) => (
					<Stack
						key={dealDetail?.id}
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						p={1.5}
						sx={{
							borderBottom: displayDividerline(index)
								? `1px solid ${theme.palette.divider}`
								: "none",
						}}
					>
						<Stack px={0.5} spacing={0.5}>
							<MenuItemText
								color={theme.palette.secondary.contrastText}
								fontWeight={500}
								style={{
									cursor: "pointer",
								}}
								onClick={(event) =>
									navigateToDeal(event, dealDetail?.id)
								}
							>
								{dealDetail?.title}
							</MenuItemText>

							<MenuItemText color="rgba(0, 0, 0, 0.6)">
								{dealDetail?.pipelineStage}
							</MenuItemText>
						</Stack>

						<Typography fontSize={12}>
							{dealDetail?.dealValue}
						</Typography>
					</Stack>
				))}
			</Menu>

			{data?.dealList?.length > 0 ? (
				<TableCellText
					onClick={openDealsMenu}
					color={theme.palette.secondary.contrastText}
				>
					{data.dealList.length}{" "}
					{data.dealList.length === 1 ? "Deal" : "Deals"}
				</TableCellText>
			) : (
				<TableCellText>-</TableCellText>
			)}
		</React.Fragment>
	);
}

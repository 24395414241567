import React, { useMemo, useState } from "react";
import {
	default as MuiAutocomplete,
	createFilterOptions,
} from "@mui/material/Autocomplete";
import {
	Box,
	Chip,
	CircularProgress,
	IconButton,
	InputAdornment,
	Paper,
	Popper,
	Skeleton,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../assets/icons/close";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as ContactIcon } from "../../../assets/icons/contact";
import { useAuth } from "../../../hooks/auth";
import { PERMISSIONS } from "../../../utils/Auth";
import { useDebouncedCallback } from "use-debounce";
import { useContactList } from "../../../hooks/services/contact";
import { useLastRowRef } from "../../../hooks/common/InfiniteScroll";
import { MenuItemText } from "../../../styles/twozo";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
			}}
		/>
	);
};

export default function ContactAutoComplete(props) {
	const {
		placeHolder,
		value,
		onChange,
		onCancel,
		isAllowNewOption = false,
		isIconNeeded = true,
		multiple = false,
		inputRef,
		error,
		clearErrors,
		textFieldSx,
		filterCriteria,
		disabled = false,
	} = props;

	const theme = useTheme();

	const [contactInputValue, setContactInputValue] = useState("");

	const { isUserAllowedFor } = useAuth();
	const isAllowToAddNewOption =
		isUserAllowedFor(PERMISSIONS.contact.create) && isAllowNewOption;
	const isContactListEnabled = isUserAllowedFor(PERMISSIONS.contact.view);
	const {
		data: contacts,
		isLoading: isLoadingContacts,
		isFetching: isFetchingContacts,
		hasNextPage: hasNextPage,
		fetchNextPage: fetchNextPage,
	} = useContactList(isContactListEnabled, contactInputValue);

	const lastOptionRef = useLastRowRef(
		fetchNextPage,
		hasNextPage,
		isFetchingContacts
	);

	const filteredContacts = useMemo(() => {
		if (Array.isArray(contacts) && contacts?.length > 0) {
			if (filterCriteria?.excludeContactIds?.length > 0) {
				return contacts.filter(
					(contact) =>
						!filterCriteria.excludeContactIds.includes(
							contact?.value
						)
				);
			}
			return contacts;
		}
		return [];
	}, [contacts, filterCriteria?.excludeContactIds]);

	const displayCancelIcon = Boolean(value) && !multiple;

	const onInputChange = (event) => {
		const { value } = event.target;
		setContactInputValue(value?.trimStart());
		clearErrors ? clearErrors() : null;
	};

	const handleContactInputChange = useDebouncedCallback(
		(event) => {
			onInputChange(event);
		},
		500 // delay in ms
	);

	const onBlurContactInput = () => {
		setContactInputValue("");
	};

	const handleSelectContact = (_, value) => {
		setContactInputValue("");
		onChange(value);
	};

	const filteredValues = createFilterOptions({
		matchFrom: "start",
		stringify: (option) => option.name,
	});

	const filterOptions = (options, params) => {
		if (isLoadingContacts) {
			return [];
		}
		const { inputValue } = params;
		const filteredOption = filteredValues(options, params);
		const isExisting = filteredOption.some(
			(option) => inputValue === option.name
		);

		if (!isExisting && isAllowToAddNewOption) {
			filteredOption.unshift({
				name: inputValue,
				isNewOption: true,
			});
		}
		return filteredOption;
	};

	const hasLastOption = (option) => {
		let renderOptionIndex = filteredContacts?.indexOf(option);
		let lastOptionIndex = filteredContacts?.indexOf(
			filteredContacts?.[filteredContacts?.length - 1]
		);

		return renderOptionIndex === lastOptionIndex;
	};

	const renderOption = (option) => {
		if (isAllowToAddNewOption && option.isNewOption) {
			return (
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					width="100%"
				>
					<MenuItemText
						fontWeight={500}
						color={theme.palette.primary.main}
					>
						{option?.name}
					</MenuItemText>

					<Box display="flex">
						{AddIcon(20, 20, theme.palette.primary.main)}
					</Box>
				</Stack>
			);
		} else {
			return (
				<Stack spacing={1}>
					<MenuItemText>{option?.name}</MenuItemText>

					{hasNextPage && hasLastOption(option) ? (
						<Box ref={lastOptionRef}>
							{!isLoadingContacts && isFetchingContacts && (
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="center"
								>
									<Skeleton width="200px" height="16px" />
								</Stack>
							)}
						</Box>
					) : null}
				</Stack>
			);
		}
	};

	return (
		<MuiAutocomplete
			size="small"
			multiple={multiple}
			disabled={disabled}
			options={filteredContacts || []}
			onChange={handleSelectContact}
			value={value || ""}
			open={Boolean(contactInputValue)}
			readOnly={Boolean(value) && !multiple}
			PopperComponent={CustomPopper}
			PaperComponent={CustomPaper}
			forcePopupIcon={false}
			noOptionsText={
				<Typography style={{ fontSize: "14px" }}>
					No Results Found
				</Typography>
			}
			loading={isLoadingContacts}
			loadingText={
				<Stack
					alignItems="center"
					justifyContent="center"
					height="140px"
				>
					<CircularProgress size="18px" />
				</Stack>
			}
			style={{
				width: "100%",
			}}
			disableClearable
			filterSelectedOptions={multiple}
			getOptionLabel={(option) => {
				return option?.name || "";
			}}
			renderTags={(value) =>
				value.map((option, index) => (
					<Chip
						key={index}
						size="small"
						disabled={disabled}
						label={
							<Typography fontSize={14} color="#000">
								{option?.name}
							</Typography>
						}
						color="secondary"
						deleteIcon={CloseIcon(16, 16, "#000")}
						onDelete={() => onCancel(index)}
						sx={{
							borderRadius: "8px",
							marginRight: "4px",
						}}
					/>
				))
			}
			renderOption={(props, option) => {
				return (
					<Box
						{...props}
						key={option?.value}
						style={{
							minHeight: "40px",
							width: "100%",
						}}
					>
						{renderOption(option)}
					</Box>
				);
			}}
			filterOptions={filterOptions}
			renderInput={(params) => (
				<TextField
					{...params}
					inputRef={inputRef}
					placeholder={placeHolder || "Select"}
					value={contactInputValue}
					onChange={handleContactInputChange}
					onBlur={onBlurContactInput}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<>
								<InputAdornment
									position="start"
									style={{
										paddingLeft: isIconNeeded
											? "8px"
											: "0px",
									}}
								>
									{isIconNeeded &&
										ContactIcon(
											20,
											20,
											error
												? theme.palette.error.main
												: "#666666"
										)}
								</InputAdornment>
								{params.InputProps.startAdornment}
							</>
						),
						endAdornment: (
							<InputAdornment position="end">
								{displayCancelIcon && (
									<IconButton onClick={onCancel}>
										{CloseIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</IconButton>
								)}
							</InputAdornment>
						),
					}}
					inputProps={{
						...params.inputProps,
						onKeyDown: (event) => {
							if (event.key === "Enter") {
								event.stopPropagation();
							}
						},
						style: {
							fontSize: "14px",
						},
					}}
					sx={{
						...textFieldSx,
					}}
					error={!!error}
				/>
			)}
		/>
	);
}

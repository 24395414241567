import React, { useState } from "react";
import Menu from "../../../../../Elements/Menu";
import {
	Box,
	CircularProgress,
	MenuItem,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import { useContactList } from "../../../../../../hooks/services/contact";
import { PERMISSIONS } from "../../../../../../utils/Auth";
import { useAuth } from "../../../../../../hooks/auth";
import MenuSearchBar from "../../../../../Elements/MenuSearchBar";
import { useLinkMailToSource } from "../../../../../../hooks/services/conversations";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../../utils/notification/notificationMessages";
import { modules } from "../../../../../../utils/common/ModulesName";
import CustomSwipeableDrawer from "../../../../../Elements/CustomSwipeableDrawer";
import { MenuItemText, twozoStyles } from "../../../../../../styles/twozo";
import AddForm from "../../../../../Elements/AddForm";
import FieldPermissionContextProvider from "../../../../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import { useContactFieldPermissions } from "../../../../../../hooks/modules/auth/contact";
import { getDropdownDataByName } from "../../../../../../utils/dropdownData";
import { removeFieldsWithEmptyValues } from "../../../../../../utils/common";
import Can from "../../../../../Auth/Can";
import { useModuleName } from "../../../../../../hooks/modules";
import { useLastRowRef } from "../../../../../../hooks/common/InfiniteScroll";

export default function RelatedContactsMenu(props) {
	const {
		addContactMenuElement,
		isAddContactMenuOpened,
		closeAddContactMenu,
		selectedContactData,
		menuContext,
	} = props;

	const classes = twozoStyles();
	const [displayExistingContacts, setDisplayExistingContacts] =
		useState(false);
	const [searchedContactValue, setSearchedContactValue] = useState("");
	const [isAddContactDrawerOpened, setIsAddContactDrawerOpened] =
		useState(false);

	const contactFieldPermissions = useContactFieldPermissions();
	const { isUserAllowedFor } = useAuth();
	const isContactListEnabled = isUserAllowedFor(PERMISSIONS.contact.view);
	const {
		data: contactList,
		isLoading: isLoadingcontactList,
		isFetching: isFetchingcontactList,
		hasNextPage: hasNextPage,
		fetchNextPage: fetchNextPage,
	} = useContactList(isContactListEnabled, searchedContactValue);

	const linkMailToContactMutation = useLinkMailToSource(
		menuContext?.threadId,
		menuContext?.mailListName
	);

	const lastOptionRef = useLastRowRef(
		fetchNextPage,
		hasNextPage,
		isFetchingcontactList
	);

	const { getModuleName } = useModuleName();
	const moduleName = modules.CONTACT;
	const formHeaderLabel = `Add ${getModuleName(moduleName)}`;

	const emailTypeOptions = getDropdownDataByName("EMAIL_TYPE");
	const addContactFormData = {
		[menuContext?.firstNameFieldId]: selectedContactData?.contactName,
		[menuContext?.emailsFieldId]: [
			{
				[menuContext?.emailsSubTypeField?.valueFieldId]:
					selectedContactData?.email,
				[menuContext?.emailsSubTypeField?.typeFieldId]:
					emailTypeOptions?.[0]?.value, // initial email type id
			},
		],
	};

	const handleExistingContact = () => {
		setDisplayExistingContacts(true);
	};

	const onCloseContactMenu = () => {
		closeAddContactMenu();
		setDisplayExistingContacts(false);
		setSearchedContactValue("");
	};

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearchedContactValue(value?.trimStart());
	};

	const handleLinkMailToSource = (linkMailToSourceRequestData) => {
		linkMailToContactMutation.mutate(linkMailToSourceRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.linkToContact,
				});
				onCloseContactMenu();
				closeAddContactDrawer();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleLinkExistingContact = (contactId) => {
		let linkMailToExsistingContactRequest = {
			threadId: menuContext?.threadId,
			sourceName: modules.CONTACT,
			entityData: {
				contact: {
					contactId: contactId,
					emailValue: selectedContactData?.email,
				},
			},
		};
		handleLinkMailToSource(linkMailToExsistingContactRequest);
	};

	const handleLinkMailtoNewContact = (newContactData) => {
		removeFieldsWithEmptyValues(newContactData);
		let linkMailToExsistingContactRequest = {
			threadId: menuContext?.threadId,
			sourceName: modules.CONTACT,
			entityData: {
				contact: newContactData,
			},
		};
		handleLinkMailToSource(linkMailToExsistingContactRequest);
	};

	const openAddContactDrawer = () => {
		setIsAddContactDrawerOpened(true);
		onCloseContactMenu();
	};

	const closeAddContactDrawer = () => {
		setIsAddContactDrawerOpened(false);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography
					fontSize={14}
					color="rgba(0, 0, 0, 0.6)"
					textAlign="center"
				>
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			{/* Add contact drawer */}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddContactDrawerOpened}
				onOpen={openAddContactDrawer}
				onClose={closeAddContactDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<FieldPermissionContextProvider
						value={contactFieldPermissions}
					>
						<AddForm
							moduleName={moduleName}
							onClose={closeAddContactDrawer}
							isSaving={linkMailToContactMutation.isLoading}
							onSave={handleLinkMailtoNewContact}
							formData={addContactFormData}
							hiddenSaveAndNewButton={true}
							formHeaderLabel={formHeaderLabel}
						></AddForm>
					</FieldPermissionContextProvider>
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				width="250px"
				anchorEl={addContactMenuElement}
				open={isAddContactMenuOpened}
				onClose={onCloseContactMenu}
				style={{
					marginTop: "8px",
				}}
				menuListProps={{
					padding: "0px 0px 4px 0px",
				}}
			>
				<Box
					p={0.5}
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "#fff",
					}}
					hidden={!displayExistingContacts}
				>
					<MenuSearchBar
						value={searchedContactValue}
						onChange={handleSearch}
					/>
				</Box>

				<Box sx={{ maxHeight: "200px", overflowY: "auto" }}>
					{displayExistingContacts ? (
						<Box>
							{isLoadingcontactList ? (
								<Stack
									alignItems="center"
									justifyContent="center"
									height="100px"
								>
									<CircularProgress size="25px" />
								</Stack>
							) : contactList?.length > 0 ? (
								contactList.map((contact) => (
									<MenuItem
										key={contact?.id}
										style={{ height: "40px" }}
										onClick={() =>
											handleLinkExistingContact(
												contact?.value
											)
										}
									>
										<MenuItemText noWrap>
											{contact?.name}
										</MenuItemText>
									</MenuItem>
								))
							) : (
								renderEmptyView()
							)}

							<Box ref={lastOptionRef}>
								{!isLoadingcontactList &&
									isFetchingcontactList &&
									contactList?.length > 0 && (
										<MenuItem style={{ height: "40px" }}>
											<Skeleton
												width="190px"
												height="12px"
											/>
										</MenuItem>
									)}
							</Box>
						</Box>
					) : (
						<Box>
							<MenuItem
								style={{ height: "40px" }}
								onClick={handleExistingContact}
							>
								<MenuItemText>to Existing Contact</MenuItemText>
							</MenuItem>

							<Can permission={PERMISSIONS.contact.create}>
								<MenuItem
									style={{ height: "40px" }}
									onClick={openAddContactDrawer}
								>
									<MenuItemText>New Contact</MenuItemText>
								</MenuItem>
							</Can>
						</Box>
					)}
				</Box>
			</Menu>
		</React.Fragment>
	);
}

import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { default as DragIcon } from "../../../../../assets/icons/drag";
import { useDashboardBuilderContext } from "../Context";

export default function ChartWrapper(props) {
	const { config, index } = props;
	const theme = useTheme();
	const { getChartIcon, selectedChart, selectChart } =
		useDashboardBuilderContext();

	return (
		<React.Fragment>
			<Box
				style={{
					height: "380px",
					backgroundColor: "white",
					borderRadius: "5px",
					cursor: "pointer",
					border:
						selectedChart === config.viewId
							? `1px solid ${theme.palette.primary.main}`
							: "1px solid #fff",
				}}
				onClick={() => {
					selectChart(config.viewId, index);
				}}
				p={2}
			>
				<Stack direction="row" alignItems="center" spacing={1}>
					<Box display="flex">{DragIcon(24, 24, "grey")}</Box>

					<Typography>{config.viewName}</Typography>
				</Stack>

				<Stack
					alignItems="center"
					justifyContent="center"
					style={{
						height: "80%",
						pointerEvents: "none",
						userSelect: "none",
					}}
				>
					<Box display="flex">
						{getChartIcon(config.meta.viewIcon.name, 45, 45)}
					</Box>

					<Typography>{config.meta.viewIcon.displayName}</Typography>
				</Stack>
			</Box>
		</React.Fragment>
	);
}

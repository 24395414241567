import React from "react";
import ContactPersonProfileImage from "../../../../assets/images/contact/unknownContact.png";
import {
	Box,
	Checkbox,
	CircularProgress,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { TableCellText, TableHeaderLabel } from "../../../../styles/twozo";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { getDateMonthAndYearFormat } from "../../../../utils/DateUtils";
import { useProductDealList } from "../../../../hooks/services/product/deal";
import TooltipComponent from "../../../Elements/TooltipComponent";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";

export default function Deals(props) {
	const { productId, selectedDealStateId } = props;

	const {
		data: dealList,
		isLoading: isDealListLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useProductDealList(productId, selectedDealStateId);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const hasDealLists =
		dealList &&
		dealList?.pages?.length > 0 &&
		dealList?.pages[0]?.list &&
		dealList?.pages[0]?.list?.length > 0;

	return (
		<React.Fragment>
			<Box>
				{isDealListLoading ? (
					<Stack
						justifyContent="center"
						alignItems="center"
						height="30vh"
					>
						<CircularProgress size="35px" />
					</Stack>
				) : (
					<TableContainer>
						<Table sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow sx={{ height: "36px" }}>
									<TableCell padding="checkbox"></TableCell>

									<TableCell width="24%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Title
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="15%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Deal Value
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="15%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Product Quantity
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="22%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Primary Contact
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="19%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Expected Close Date
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{hasDealLists ? (
									dealList.pages.map((page) =>
										page.list.map((deal) => (
											<TableRow key={deal.id}>
												<TableCell padding="checkbox">
													<Checkbox />
												</TableCell>

												<TableCell>
													<TooltipComponent
														title={deal.title}
														placement="top"
														width="350px"
													>
														<TableCellText
															noWrap
															maxWidth="300px"
														>
															{deal.title}
														</TableCellText>
													</TooltipComponent>
												</TableCell>

												<TableCell>
													<TableCellText>
														{/* This $ "dollar" sign will be removed later */}
														${deal.dealValue.value}
													</TableCellText>
												</TableCell>

												<TableCell>
													<TableCellText>
														{deal.productQuantity}
													</TableCellText>
												</TableCell>

												<TableCell>
													<Stack
														direction="row"
														spacing={1}
														alignItems="center"
													>
														<img
															src={
																ContactPersonProfileImage
															}
															alt="profile"
															width="26px"
															height="26px"
														/>
														<TooltipComponent
															title={
																deal
																	.primaryContact
																	.fullName
															}
															placement="top"
															width="350px"
														>
															<Typography
																fontSize={15}
																noWrap
																maxWidth="266px"
															>
																{
																	deal
																		.primaryContact
																		.fullName
																}
															</Typography>
														</TooltipComponent>
													</Stack>
												</TableCell>

												<TableCell>
													<TableCellText>
														{deal.expectedCloseDate
															? getDateMonthAndYearFormat(
																	deal.expectedCloseDate
																)
															: "-"}
													</TableCellText>
												</TableCell>
											</TableRow>
										))
									)
								) : (
									<TableRow
										sx={{
											"&:hover": {
												backgroundColor: "transparent",
											},
										}}
									>
										<TableCell
											colSpan={6}
											sx={{
												border: "none",
											}}
										>
											<Stack
												alignItems="center"
												justifyContent="center"
												style={{
													height: "50vh",
												}}
											>
												<Typography
													fontSize={14}
													fontWeight={400}
													style={{
														opacity: 0.6,
													}}
												>
													This product isn’t linked to
													any deals yet
												</Typography>
											</Stack>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>

						<Box ref={lastRowRef}>
							{!isDealListLoading && isFetching && (
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="center"
									spacing={1}
									py={2}
								>
									<CircularProgress size={18} />

									<Typography fontSize={12}>
										Loading More Data
									</Typography>
								</Stack>
							)}
						</Box>
					</TableContainer>
				)}
			</Box>
		</React.Fragment>
	);
}

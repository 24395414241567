import React, { useEffect, useState } from "react";
import DropdownBaseComponent from "../DropdownBaseComponent";
import { useQuery } from "@tanstack/react-query";
import { getDropdownKeyByFieldId } from "../../../../../utils/queryKeys";
import { getFieldDropdownData } from "../../../../../api/fields/config/configApi";

export default function ContactComponent(props) {
	const { field, style, updateFieldValue, error, value, clearError } = props;
	const [selected, setSelected] = useState(null);

	useEffect(() => {
		if (value) {
			setSelected(value);
		}
	}, [value]);

	const { data: options } = useQuery(
		getDropdownKeyByFieldId(field.fieldId),
		() => getFieldDropdownData({ name: "contactList" }),
		{
			enabled: !!field?.fieldId,
			select: (data) => {
				//TODO - Get consistent value from dropdown API
				return (
					data?.options?.map((option) => {
						if (option.id) {
							option["value"] = option["id"];
						} else if (option.value) {
							option["id"] = option["value"];
						}
						return option;
					}) || []
				);
			},
		}
	);

	const onFieldChange = (value) => {
		setSelected(value);
		updateFieldValue([value], field.fieldId);
		clearError(field.fieldId);
	};

	return (
		<React.Fragment>
			<DropdownBaseComponent
				selected={selected}
				options={options || []}
				onFieldChange={onFieldChange}
				style={style}
				error={error}
			/>
		</React.Fragment>
	);
}

import React, { useMemo, useState } from "react";
import {
	Box,
	CircularProgress,
	Divider,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as AddIcon } from "../../../../../../assets/icons/add";
import MenuSearchBar from "../../../../../Elements/MenuSearchBar";
import { MenuItemText, TextButton } from "../../../../../../styles/twozo";
import {
	useTemplateData,
	useTemplateDataWithValues,
	useTemplateList,
} from "../../../../../../hooks/services/mail/emailTemplate";
import ConfirmDialog from "../../../../../Elements/ConfirmDialog";
import AddTemplateDialog from "../../../../EmailTemplates/AddTemplateDialog";

export default function TemplateMenu(props) {
	const { closeTemplateMenu, subject, onApplyTemplate, firstRecipientId } =
		props;
	const theme = useTheme();

	const [searchValue, setSearchValue] = useState("");
	const [selectedTemplateId, setSelectedTemplateId] = useState("");
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [showAddTemplateDialog, setShowAddTemplateDialog] = useState(false);

	const { data: templateList, isLoading: isTemplateListLoading } =
		useTemplateList();
	const { data: templateData } = useTemplateData(selectedTemplateId);
	const { data: templateDataWithValue } = useTemplateDataWithValues(
		selectedTemplateId,
		firstRecipientId
	);

	const filteredTemplates = useMemo(() => {
		if (templateList?.length) {
			if (searchValue) {
				return templateList.filter((template) =>
					template.name
						.toLowerCase()
						.startsWith(searchValue.toLowerCase())
				);
			} else {
				return templateList;
			}
		}
		return [];
	}, [searchValue, templateList]);

	const handleSearchTemplate = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const renderEmptyView = () => {
		return (
			<Stack
				justifyContent="center"
				alignItems="center"
				height="150px"
				width="100%"
			>
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{templateList?.length > 0
						? "No results found"
						: "No templates found"}
				</Typography>
			</Stack>
		);
	};

	const openConfirmDialog = () => {
		setShowConfirmDialog(true);
	};

	const closeConfirmDialog = () => {
		setShowConfirmDialog(false);
	};

	const onSelectTemplate = (templateId) => {
		setSelectedTemplateId(templateId);
		openConfirmDialog();
	};

	const handleApplyTemplate = () => {
		if (templateDataWithValue) {
			onApplyTemplate(templateDataWithValue, false);
		} else {
			onApplyTemplate(templateData, true, selectedTemplateId);
		}
		closeConfirmDialog();
		closeTemplateMenu();
	};

	const openAddTemplateDialog = () => {
		setShowAddTemplateDialog(true);
	};

	const closeAddTemplateDialog = () => {
		setShowAddTemplateDialog(false);
	};

	return (
		<React.Fragment>
			<ConfirmDialog
				title="Are you sure you want to use this template?"
				subtitle="The template will replace the subject and content of this email."
				open={showConfirmDialog}
				onCancel={closeConfirmDialog}
				onConfirm={handleApplyTemplate}
				confirmButtonColor="secondary"
			/>

			<AddTemplateDialog
				open={showAddTemplateDialog}
				onCloseAddTemplateDialog={closeAddTemplateDialog}
				subject={subject}
			/>

			<Stack maxHeight="350px">
				<Box>
					<Box p={1.5}>
						<Typography fontSize={14} fontWeight={500}>
							Email Templates
						</Typography>
					</Box>

					<Box px={1.5} pb={0.5} style={{ textAlign: "center" }}>
						<MenuSearchBar
							value={searchValue}
							onChange={handleSearchTemplate}
						/>
					</Box>
				</Box>

				<Box height="69%" sx={{ overflowY: "auto" }}>
					{isTemplateListLoading ? (
						<Stack
							justifyContent="center"
							alignItems="center"
							height="250px"
							width="100%"
						>
							<CircularProgress size="25px" />
						</Stack>
					) : filteredTemplates?.length > 0 ? (
						filteredTemplates.map((template) => (
							<MenuItem
								key={template.id}
								style={{
									minHeight: "40px",
								}}
								onClick={() => {
									onSelectTemplate(template?.id);
								}}
							>
								<MenuItemText>{template.name}</MenuItemText>
							</MenuItem>
						))
					) : (
						renderEmptyView()
					)}
				</Box>

				<Divider />

				<Box px={2} py={1} height="10%">
					<TextButton
						startIcon={AddIcon(20, 20, theme.palette.primary.main)}
						onClick={openAddTemplateDialog}
					>
						Save as Template
					</TextButton>
				</Box>
			</Stack>
		</React.Fragment>
	);
}

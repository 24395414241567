import React from "react";
import { Typography } from "@mui/material";
import TextComponent from "./FieldComponent/TextComponent";
import DropdownBaseComponent from "./FieldComponent/DropdownBaseComponent";
import CustomFieldComponent from "./FieldComponent/CustomFieldComponent";

const fieldComponents = {
	SINGLE_STRING: TextComponent,
	DROPDOWN: DropdownBaseComponent,
	FROM: CustomFieldComponent,
	TO: CustomFieldComponent,
};

export default function Field(props) {
	const { fieldType, ...other } = props;
	const FieldComponent = fieldComponents[fieldType];

	return (
		<React.Fragment>
			{FieldComponent ? (
				<FieldComponent {...other} />
			) : (
				<Typography fontSize={14}>
					{fieldType} is not implemented
				</Typography>
			)}
		</React.Fragment>
	);
}

const timeList = [
	{
		name: "12:00 AM",
		value: 1,
		meta: {
			hour: 0,
			minute: 0,
		},
	},
	{
		name: "12:15 AM",
		value: 2,
		meta: {
			hour: 0,
			minute: 15,
		},
	},
	{
		name: "12:30 AM",
		value: 3,
		meta: {
			hour: 0,
			minute: 30,
		},
	},
	{
		name: "12:45 AM",
		value: 4,
		meta: {
			hour: 0,
			minute: 45,
		},
	},
	{
		name: "01:00 AM",
		value: 5,
		meta: {
			hour: 1,
			minute: 0,
		},
	},
	{
		name: "01:15 AM",
		value: 6,
		meta: {
			hour: 1,
			minute: 15,
		},
	},
	{
		name: "01:30 AM",
		value: 7,
		meta: {
			hour: 1,
			minute: 30,
		},
	},
	{
		name: "01:45 AM",
		value: 8,
		meta: {
			hour: 1,
			minute: 45,
		},
	},
	{
		name: "02:00 AM",
		value: 9,
		meta: {
			hour: 2,
			minute: 0,
		},
	},
	{
		name: "02:15 AM",
		value: 10,
		meta: {
			hour: 2,
			minute: 15,
		},
	},
	{
		name: "02:30 AM",
		value: 11,
		meta: {
			hour: 2,
			minute: 30,
		},
	},
	{
		name: "02:45 AM",
		value: 12,
		meta: {
			hour: 2,
			minute: 45,
		},
	},
	{
		name: "03:00 AM",
		value: 13,
		meta: {
			hour: 3,
			minute: 0,
		},
	},
	{
		name: "03:15 AM",
		value: 14,
		meta: {
			hour: 3,
			minute: 15,
		},
	},
	{
		name: "03:30 AM",
		value: 15,
		meta: {
			hour: 3,
			minute: 30,
		},
	},
	{
		name: "03:45 AM",
		value: 16,
		meta: {
			hour: 3,
			minute: 45,
		},
	},
	{
		name: "04:00 AM",
		value: 17,
		meta: {
			hour: 4,
			minute: 0,
		},
	},
	{
		name: "04:15 AM",
		value: 18,
		meta: {
			hour: 4,
			minute: 15,
		},
	},
	{
		name: "04:30 AM",
		value: 19,
		meta: {
			hour: 4,
			minute: 30,
		},
	},
	{
		name: "04:45 AM",
		value: 20,
		meta: {
			hour: 4,
			minute: 45,
		},
	},
	{
		name: "05:00 AM",
		value: 21,
		meta: {
			hour: 5,
			minute: 0,
		},
	},
	{
		name: "05:15 AM",
		value: 22,
		meta: {
			hour: 5,
			minute: 15,
		},
	},
	{
		name: "05:30 AM",
		value: 23,
		meta: {
			hour: 5,
			minute: 30,
		},
	},
	{
		name: "05:45 AM",
		value: 24,
		meta: {
			hour: 5,
			minute: 45,
		},
	},
	{
		name: "06:00 AM",
		value: 25,
		meta: {
			hour: 6,
			minute: 0,
		},
	},
	{
		name: "06:15 AM",
		value: 26,
		meta: {
			hour: 6,
			minute: 15,
		},
	},
	{
		name: "06:30 AM",
		value: 27,
		meta: {
			hour: 6,
			minute: 30,
		},
	},
	{
		name: "06:45 AM",
		value: 28,
		meta: {
			hour: 6,
			minute: 45,
		},
	},
	{
		name: "07:00 AM",
		value: 29,
		meta: {
			hour: 7,
			minute: 0,
		},
	},
	{
		name: "07:15 AM",
		value: 30,
		meta: {
			hour: 7,
			minute: 15,
		},
	},
	{
		name: "07:30 AM",
		value: 31,
		meta: {
			hour: 7,
			minute: 30,
		},
	},
	{
		name: "07:45 AM",
		value: 32,
		meta: {
			hour: 7,
			minute: 45,
		},
	},
	{
		name: "08:00 AM",
		value: 33,
		meta: {
			hour: 8,
			minute: 0,
		},
	},
	{
		name: "08:15 AM",
		value: 34,
		meta: {
			hour: 8,
			minute: 15,
		},
	},
	{
		name: "08:30 AM",
		value: 35,
		meta: {
			hour: 8,
			minute: 30,
		},
	},
	{
		name: "08:45 AM",
		value: 36,
		meta: {
			hour: 8,
			minute: 45,
		},
	},
	{
		name: "09:00 AM",
		value: 37,
		meta: {
			hour: 9,
			minute: 0,
		},
	},
	{
		name: "09:15 AM",
		value: 38,
		meta: {
			hour: 9,
			minute: 15,
		},
	},
	{
		name: "09:30 AM",
		value: 39,
		meta: {
			hour: 9,
			minute: 30,
		},
	},
	{
		name: "09:45 AM",
		value: 40,
		meta: {
			hour: 9,
			minute: 45,
		},
	},
	{
		name: "10:00 AM",
		value: 41,
		meta: {
			hour: 10,
			minute: 0,
		},
	},
	{
		name: "10:15 AM",
		value: 42,
		meta: {
			hour: 10,
			minute: 15,
		},
	},
	{
		name: "10:30 AM",
		value: 43,
		meta: {
			hour: 10,
			minute: 30,
		},
	},
	{
		name: "10:45 AM",
		value: 44,
		meta: {
			hour: 10,
			minute: 45,
		},
	},
	{
		name: "11:00 AM",
		value: 45,
		meta: {
			hour: 11,
			minute: 0,
		},
	},
	{
		name: "11:15 AM",
		value: 46,
		meta: {
			hour: 11,
			minute: 15,
		},
	},
	{
		name: "11:30 AM",
		value: 47,
		meta: {
			hour: 11,
			minute: 30,
		},
	},
	{
		name: "11:45 AM",
		value: 48,
		meta: {
			hour: 11,
			minute: 45,
		},
	},
	{
		name: "12:00 PM",
		value: 49,
		meta: {
			hour: 12,
			minute: 0,
		},
	},
	{
		name: "12:15 PM",
		value: 50,
		meta: {
			hour: 12,
			minute: 15,
		},
	},
	{
		name: "12:30 PM",
		value: 51,
		meta: {
			hour: 12,
			minute: 30,
		},
	},
	{
		name: "12:45 PM",
		value: 52,
		meta: {
			hour: 12,
			minute: 45,
		},
	},
	{
		name: "01:00 PM",
		value: 53,
		meta: {
			hour: 13,
			minute: 0,
		},
	},
	{
		name: "01:15 PM",
		value: 54,
		meta: {
			hour: 13,
			minute: 15,
		},
	},
	{
		name: "01:30 PM",
		value: 55,
		meta: {
			hour: 13,
			minute: 30,
		},
	},
	{
		name: "01:45 PM",
		value: 56,
		meta: {
			hour: 13,
			minute: 45,
		},
	},
	{
		name: "02:00 PM",
		value: 57,
		meta: {
			hour: 14,
			minute: 0,
		},
	},
	{
		name: "02:15 PM",
		value: 58,
		meta: {
			hour: 14,
			minute: 15,
		},
	},
	{
		name: "02:30 PM",
		value: 59,
		meta: {
			hour: 14,
			minute: 30,
		},
	},
	{
		name: "02:45 PM",
		value: 60,
		meta: {
			hour: 14,
			minute: 45,
		},
	},
	{
		name: "03:00 PM",
		value: 61,
		meta: {
			hour: 15,
			minute: 0,
		},
	},
	{
		name: "03:15 PM",
		value: 62,
		meta: {
			hour: 15,
			minute: 15,
		},
	},
	{
		name: "03:30 PM",
		value: 63,
		meta: {
			hour: 15,
			minute: 30,
		},
	},
	{
		name: "03:45 PM",
		value: 64,
		meta: {
			hour: 15,
			minute: 45,
		},
	},
	{
		name: "04:00 PM",
		value: 65,
		meta: {
			hour: 16,
			minute: 0,
		},
	},
	{
		name: "04:15 PM",
		value: 66,
		meta: {
			hour: 16,
			minute: 15,
		},
	},
	{
		name: "04:30 PM",
		value: 67,
		meta: {
			hour: 16,
			minute: 30,
		},
	},
	{
		name: "04:45 PM",
		value: 68,
		meta: {
			hour: 16,
			minute: 45,
		},
	},
	{
		name: "05:00 PM",
		value: 69,
		meta: {
			hour: 17,
			minute: 0,
		},
	},
	{
		name: "05:15 PM",
		value: 70,
		meta: {
			hour: 17,
			minute: 15,
		},
	},
	{
		name: "05:30 PM",
		value: 71,
		meta: {
			hour: 17,
			minute: 30,
		},
	},
	{
		name: "05:45 PM",
		value: 72,
		meta: {
			hour: 17,
			minute: 45,
		},
	},
	{
		name: "06:00 PM",
		value: 73,
		meta: {
			hour: 18,
			minute: 0,
		},
	},
	{
		name: "06:15 PM",
		value: 74,
		meta: {
			hour: 18,
			minute: 15,
		},
	},
	{
		name: "06:30 PM",
		value: 75,
		meta: {
			hour: 18,
			minute: 30,
		},
	},
	{
		name: "06:45 PM",
		value: 76,
		meta: {
			hour: 18,
			minute: 45,
		},
	},
	{
		name: "07:00 PM",
		value: 77,
		meta: {
			hour: 19,
			minute: 0,
		},
	},
	{
		name: "07:15 PM",
		value: 78,
		meta: {
			hour: 19,
			minute: 15,
		},
	},
	{
		name: "07:30 PM",
		value: 79,
		meta: {
			hour: 19,
			minute: 30,
		},
	},
	{
		name: "07:45 PM",
		value: 80,
		meta: {
			hour: 19,
			minute: 45,
		},
	},
	{
		name: "08:00 PM",
		value: 81,
		meta: {
			hour: 20,
			minute: 0,
		},
	},
	{
		name: "08:15 PM",
		value: 82,
		meta: {
			hour: 20,
			minute: 15,
		},
	},
	{
		name: "08:30 PM",
		value: 83,
		meta: {
			hour: 20,
			minute: 30,
		},
	},
	{
		name: "08:45 PM",
		value: 84,
		meta: {
			hour: 20,
			minute: 45,
		},
	},
	{
		name: "09:00 PM",
		value: 85,
		meta: {
			hour: 21,
			minute: 0,
		},
	},
	{
		name: "09:15 PM",
		value: 86,
		meta: {
			hour: 21,
			minute: 15,
		},
	},
	{
		name: "09:30 PM",
		value: 87,
		meta: {
			hour: 21,
			minute: 30,
		},
	},
	{
		name: "09:45 PM",
		value: 88,
		meta: {
			hour: 21,
			minute: 45,
		},
	},
	{
		name: "10:00 PM",
		value: 89,
		meta: {
			hour: 22,
			minute: 0,
		},
	},
	{
		name: "10:15 PM",
		value: 90,
		meta: {
			hour: 22,
			minute: 15,
		},
	},
	{
		name: "10:30 PM",
		value: 91,
		meta: {
			hour: 22,
			minute: 30,
		},
	},
	{
		name: "10:45 PM",
		value: 92,
		meta: {
			hour: 22,
			minute: 45,
		},
	},
	{
		name: "11:00 PM",
		value: 93,
		meta: {
			hour: 23,
			minute: 0,
		},
	},
	{
		name: "11:15 PM",
		value: 94,
		meta: {
			hour: 23,
			minute: 15,
		},
	},
	{
		name: "11:30 PM",
		value: 95,
		meta: {
			hour: 23,
			minute: 30,
		},
	},
	{
		name: "11:45 PM",
		value: 96,
		meta: {
			hour: 23,
			minute: 45,
		},
	},
];

export default timeList;

import { createContext, useContext } from "react";
import { useFilterDataReducer } from "./FilterDataReducer";

const FilterDataContext = createContext(null);

const useFilterDataContext = () => {
	return useContext(FilterDataContext);
};

const FilterDataProvider = (props) => {
	const { children } = props;

	const {
		filterState,
		toggleSwipeableFilter,
		filterListByCondition,
		removeAppliedFilter,
		disableApplyButton,
		updateFilterCount,
		updateAppliedFilterId,
		updateFilterUICriteriaList,
		enableApplyButton,
		openSwipeableFilter,
		closeSwipeableFilter,
		updateActiveFilterConfig,
		updateActiveFilterDetails,
	} = useFilterDataReducer();

	return (
		<FilterDataContext.Provider
			value={{
				filterState,
				toggleSwipeableFilter,
				filterListByCondition,
				removeAppliedFilter,
				disableApplyButton,
				updateFilterCount,
				updateAppliedFilterId,
				updateFilterUICriteriaList,
				enableApplyButton,
				openSwipeableFilter,
				closeSwipeableFilter,
				updateActiveFilterConfig,
				updateActiveFilterDetails,
			}}
		>
			{children}
		</FilterDataContext.Provider>
	);
};

export { useFilterDataContext, FilterDataProvider };

import { emailPattern } from "../../../../../utils/validation";

export const validateEmailField = (field, config, isActionField) => {
	const value = field?.values?.[0]?.value?.trim();
	const minLength = config?.minLength;
	const maxLength = config?.maxLength;

	if (!value) {
		return { error: true, message: isActionField ? "" : "Can’t be empty" };
	}
	if (minLength && value.length < minLength) {
		return {
			error: true,
			message: `Min. of ${minLength} characters are required`,
		};
	}
	if (value.length > maxLength) {
		return {
			error: true,
			message: `Max. of ${maxLength} characters are allowed`,
		};
	}
	if (!emailPattern.test(value)) {
		return {
			error: true,
			message: "Invalid Email Address",
		};
	}
	return { error: false, message: "" };
};

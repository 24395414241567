import React from "react";
import dayjs from "dayjs";
import DateComponent from "../DateComponent";

export default function ActivityEndDateComponent(props) {
	const {
		field = {},
		updateFieldValue,
		error,
		style = {},
		value,
		clearError,
	} = props;

	const handleChangeEndTime = (value) => {
		updateFieldValue(
			[{ ...value[0], isEndTimeChanged: true }],
			field.fieldId
		);
	};

	return (
		<React.Fragment>
			<DateComponent
				field={field}
				error={error}
				style={style}
				clearError={clearError}
				value={value ? value : { value: dayjs(new Date()) }}
				updateFieldValue={handleChangeEndTime}
			/>
		</React.Fragment>
	);
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	Box,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import contactImage from "../../../../assets/images/contact/unknownContact.png";
import ReplyIcon from "../../../../assets/icons/reply";
import ReplyAllIcon from "../../../../assets/icons/replyAll";
import MoreIcon from "../../../../assets/icons/more";
import DownloadIcon from "../../../../assets/icons/download";
import {
	getDateMonthAndYearFormat,
	getFormattedDateMonthYearAndTime,
	getHourAndMinuteFormat,
} from "../../../../utils/DateUtils";
import DOMPurify from "dompurify";
import { Markup } from "interweave";
import { getFormattedFileSize } from "../../../../utils/fileUtills";
import Menu from "../../../Elements/Menu";
import EmailComposer from "../../EmailComposer";
import { useDeleteMail } from "../../../../hooks/services/mail";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { enqueueSnackbar } from "notistack";
import Can from "../../../Auth/Can";
import { PERMISSIONS } from "../../../../utils/Auth";
import { MenuItemText, Tooltip } from "../../../../styles/twozo";

export default function EmailView(props) {
	const {
		collapsable = false,
		hideHeader = false,
		mailData,
		handleMailSubject,
		disableViewActions,
		onMailViewerClose,
		threadId,
		mailListName,
		viewerMode,
		isMailViewerFirstRender,
		sourceName,
		entityId,
	} = props;
	const theme = useTheme();

	const styles = {
		menuText: {
			fontWeight: 500,
		},
		text: {
			fontSize: 14,
			color: "rgba(0, 0, 0, 0.6)",
		},
		subText: {
			fontSize: 14,
		},
	};
	const composerRef = useRef();
	const domPurify = DOMPurify();

	const [mailViewerMenuElement, setMailViewerMenuElement] = useState(null);
	const isMailViewerMenuOpened = Boolean(mailViewerMenuElement);
	const [isEmailComposerOpened, setIsEmailComposerOpened] = useState(false);
	const [defaultComposerValues, setDefaultComposerValues] = useState({});
	const [composerMode, setComposerMode] = useState({});

	const replyMode = composerMode.reply || composerMode?.replyAll;
	const toAddressData = mailData?.recipients?.to || [];
	const ccAddressData = mailData?.recipients?.cc || [];
	const bccAddressData = mailData?.recipients?.bcc || [];
	const subject = mailData?.subject ? mailData?.subject : "";
	const isReplyAllActionEnabled =
		[...toAddressData, ...ccAddressData, ...bccAddressData].length > 1;
	const scheduled = "SCHEDULED";
	const conversationId = mailData?.conversationId || mailData?.id;

	const successNotificationTitle = "Success!";

	const deleteMailMutation = useDeleteMail(
		mailListName,
		threadId,
		sourceName,
		entityId
	);

	domPurify.addHook("afterSanitizeAttributes", function (node) {
		if (node.tagName === "A") {
			node.setAttribute("target", "_blank");
			node.setAttribute("rel", "noreferrer noopener");
		}
	});

	useEffect(() => {
		if (!!mailData && !!handleMailSubject) {
			handleMailSubject(mailData?.subject);
		}
	}, [mailData, handleMailSubject]);

	useEffect(() => {
		if (composerRef?.current && isEmailComposerOpened) {
			const resizeObserver = new ResizeObserver(() => {
				composerRef.current.scrollIntoView({ behavior: "smooth" });
			});

			resizeObserver.observe(composerRef.current);
			return () => resizeObserver.disconnect(); // clean up
		}
	}, [isEmailComposerOpened]);

	const getBodyContent = (body) => {
		const bodyContent = domPurify.sanitize(body);
		return bodyContent;
	};

	const openMailViewerMenu = (event) => {
		setMailViewerMenuElement(event.currentTarget);
	};

	const closeMailViewerMenu = () => {
		setMailViewerMenuElement(null);
	};

	const getReplyAllToAddress = () => {
		let toAddress = toAddressData?.map(
			(toAddress) => toAddress.id || { email: toAddress.mailId }
		);
		let fromAddress = mailData?.from?.contactId
			? mailData?.from?.contactId
			: { email: mailData?.from.email };
		return [fromAddress, ...toAddress];
	};

	const getReplySubject = useCallback(() => {
		return subject.startsWith("Re:") ? subject : `Re: ${subject}`;
	}, [subject]);

	const handleReplyAll = () => {
		if (mailData?.messageCategory === "INBOUND") {
			setDefaultComposerValues({
				toAddress: getReplyAllToAddress(),
				ccAddress: ccAddressData?.map(
					(ccAddress) => ccAddress.id || { email: ccAddress.mailId }
				),
				subject: getReplySubject(),
				body: mailData?.message,
			});
		} else {
			setDefaultComposerValues({
				toAddress: toAddressData?.map(
					(toAddress) => toAddress.id || { email: toAddress.mailId }
				),
				ccAddress: ccAddressData?.map(
					(ccAddress) => ccAddress.id || { email: ccAddress.mailId }
				),
				subject: getReplySubject(),
				body: mailData?.message,
			});
		}
		openEmailComposer();
		setComposerMode({
			replyAll: true,
		});
	};

	const handleReply = useCallback(() => {
		if (mailData?.messageCategory === "INBOUND") {
			setDefaultComposerValues({
				toAddress: mailData?.from?.contactId
					? [mailData?.from?.contactId]
					: [{ email: mailData?.from.email }],
				subject: getReplySubject(),
				body: mailData?.message,
			});
		} else {
			setDefaultComposerValues({
				toAddress: mailData?.recipients?.to?.map(
					(toAddress) =>
						toAddress?.contactId ||
						toAddress?.id || { email: toAddress.mailId }
				),
				subject: getReplySubject(),
				body: mailData?.message,
			});
		}
		openEmailComposer();
		setComposerMode({
			reply: true,
		});
	}, [getReplySubject, mailData]);

	const openEmailComposer = () => {
		setIsEmailComposerOpened(true);
	};

	const closeEmailComposer = () => {
		setIsEmailComposerOpened(false);
		setDefaultComposerValues({});
		setComposerMode({});
	};

	const handleDownloadFile = (fileId) => {
		window.location.href = `/api/file/download?fileId=${fileId}`;
	};

	const handleDeleteMail = (conversationId) => {
		deleteMailMutation.mutate(conversationId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.mailUnactive,
					title: successNotificationTitle,
				});
				setMailViewerMenuElement(null);
				onMailViewerClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const getForwardSubject = useCallback(() => {
		return subject.startsWith("Fwd:") ? subject : `Fwd: ${subject}`;
	}, [subject]);

	const handleForwardMail = useCallback(() => {
		setComposerMode({
			forward: true,
		});
		closeMailViewerMenu();
		setIsEmailComposerOpened(true);
		setDefaultComposerValues({
			subject: getForwardSubject(),
			body: mailData?.message,
		});
	}, [getForwardSubject, mailData]);

	useEffect(() => {
		if (isMailViewerFirstRender) {
			if (viewerMode?.reply) {
				handleReply();
			} else if (viewerMode?.forward) {
				handleForwardMail();
			}
		}
	}, [viewerMode, isMailViewerFirstRender, handleReply, handleForwardMail]);

	return (
		<React.Fragment>
			<Menu
				minWidth="120px"
				anchorEl={mailViewerMenuElement}
				open={isMailViewerMenuOpened}
				onClose={closeMailViewerMenu}
			>
				<Can permission={PERMISSIONS.email.individualEmailLimit}>
					{mailData?.messageCategory !== scheduled ? (
						<MenuItem
							style={{ height: "40px" }}
							onClick={handleForwardMail}
						>
							<MenuItemText
								style={styles.menuText}
								color={theme.palette.primary.main}
							>
								Forward
							</MenuItemText>
						</MenuItem>
					) : null}
				</Can>

				<MenuItem
					style={{ height: "40px" }}
					onClick={() => handleDeleteMail(conversationId)}
				>
					<MenuItemText
						style={styles.menuText}
						color={theme.palette.primary.main}
					>
						Delete
					</MenuItemText>
				</MenuItem>
			</Menu>

			<Stack height="100%" justifyContent="space-between">
				<Box>
					<Box
						style={{ padding: "0px 8px 16px 16px" }}
						hidden={hideHeader}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							spacing={2}
							pb={2}
						>
							<Stack
								direction="row"
								alignItems="center"
								spacing={2}
							>
								<img
									src={contactImage}
									alt="contact_image"
									height="50px"
									width="50px"
								/>

								<Box>
									<Stack direction="row" spacing={1}>
										<Typography
											fontWeight={500}
											fontSize={15}
											style={{ wordBreak: "break-word" }}
										>
											{mailData?.from?.contactName
												? `${mailData.from.contactName} <${mailData.from?.email}>`
												: `${mailData?.from?.name} <${mailData?.from?.email}>`}
										</Typography>

										{mailData.isBounced ? (
											<Box
												sx={{
													p: 0.5,
													color: theme.palette
														.secondary.contrastText,
													backgroundColor:
														theme.palette.secondary
															.main,
													borderRadius: "6px",
												}}
											>
												<Typography
													fontSize={13}
													fontWeight={500}
												>
													Bounced
												</Typography>
											</Box>
										) : null}
									</Stack>

									<Typography style={styles.text}>
										{mailData?.messageCategory === scheduled
											? "Scheduled for "
											: ""}
										{getDateMonthAndYearFormat(
											mailData?.timeStamp
										)}{" "}
										{getHourAndMinuteFormat(
											mailData?.timeStamp
										)}
										{mailData?.tracking ? (
											<span
												style={{
													color: "#000",
													cursor: "pointer",
												}}
											>
												{mailData.tracking?.opened ? (
													<span>
														&nbsp;&nbsp;•&nbsp;&nbsp;
														<Tooltip
															placement="top"
															title={getFormattedDateMonthYearAndTime(
																mailData
																	.tracking
																	.opened
																	?.timeStamp
															)}
														>
															<span>{`Opened(${mailData.tracking.opened?.count})`}</span>
														</Tooltip>
													</span>
												) : null}

												{mailData.tracking?.clicked ? (
													<span>
														&nbsp;&nbsp;•&nbsp;&nbsp;
														<Tooltip
															placement="top"
															title={getFormattedDateMonthYearAndTime(
																mailData
																	.tracking
																	.clicked
																	?.timeStamp
															)}
														>
															<span>
																{`Clicked(${mailData.tracking.clicked?.count})`}
															</span>
														</Tooltip>
													</span>
												) : null}
											</span>
										) : null}
									</Typography>
								</Box>
							</Stack>

							<Stack
								direction="row"
								alignItems="center"
								style={{
									pointerEvents: disableViewActions
										? "none"
										: "auto",
								}}
							>
								<Can
									permission={
										PERMISSIONS.email.individualEmailLimit
									}
								>
									{mailData.messageCategory !== scheduled ? (
										<Box display="flex">
											{isReplyAllActionEnabled ? (
												<IconButton
													onClick={handleReplyAll}
												>
													{ReplyAllIcon(
														20,
														20,
														theme.palette.primary
															.main
													)}
												</IconButton>
											) : null}

											<IconButton onClick={handleReply}>
												{ReplyIcon(
													20,
													20,
													theme.palette.primary.main
												)}
											</IconButton>
										</Box>
									) : null}
								</Can>

								<IconButton onClick={openMailViewerMenu}>
									{MoreIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
							</Stack>
						</Stack>

						{toAddressData?.length > 0 ? (
							<Stack
								direction="row"
								alignItems="center"
								spacing={2.5}
							>
								<Typography style={styles.text} pl={4}>
									To
								</Typography>

								<Typography style={styles.subText}>
									{toAddressData?.map((recipient, index) =>
										recipient?.name
											? `${recipient.name} <${
													recipient?.mailId
												}>${
													index !==
													toAddressData?.length - 1
														? ", "
														: ""
												}`
											: `${recipient} ${
													index !==
													toAddressData?.length - 1
														? ", "
														: ""
												}`
									)}
								</Typography>
							</Stack>
						) : null}

						{ccAddressData?.length > 0 ? (
							<Stack
								direction="row"
								alignItems="center"
								spacing={2.5}
								pt={1}
							>
								<Typography style={styles.text} pl={4}>
									CC
								</Typography>

								<Typography style={styles.subText}>
									{ccAddressData?.map(
										(recipient, index) =>
											`${recipient?.name} <${
												recipient?.mailId
											}>${
												index !==
												ccAddressData?.length - 1
													? ", "
													: ""
											}`
									)}
								</Typography>
							</Stack>
						) : null}

						{bccAddressData?.length > 0 ? (
							<Stack
								direction="row"
								alignItems="center"
								spacing={2.5}
								pt={1}
							>
								<Typography style={styles.text} pl={4}>
									BCC
								</Typography>

								<Typography style={styles.subText}>
									{bccAddressData?.map(
										(recipient, index) =>
											`${recipient?.name} <${
												recipient?.mailId
											}>${
												index !==
												bccAddressData?.length - 1
													? ", "
													: ""
											}`
									)}
								</Typography>
							</Stack>
						) : null}
					</Box>

					<Divider hidden={hideHeader} />

					<Box
						style={{
							overflow: "auto",
							pointerEvents: disableViewActions ? "none" : "auto",
						}}
						p={collapsable ? 1 : 3}
						pt={3}
					>
						<Typography
							fontSize={14}
							fontWeight={400}
							style={{ wordBreak: "break-word" }}
						>
							<Markup
								content={getBodyContent(mailData?.message)}
							/>
						</Typography>
					</Box>
				</Box>

				{mailData?.attachments ? (
					<Box>
						<Divider hidden={!collapsable} />

						<Stack
							spacing={1}
							padding={collapsable ? "8px" : "12px 24px"}
							style={{
								pointerEvents: disableViewActions
									? "none"
									: "auto",
							}}
						>
							<Typography
								pt={1}
								fontWeight={500}
								style={styles.text}
							>
								Attachments
							</Typography>

							<Stack direction="row" flexWrap="wrap" spacing={1}>
								{mailData?.attachments?.map((attachment) => (
									<Box key={attachment.id} pb={0.5}>
										<Stack
											direction="row"
											alignItems="center"
											borderRadius={2}
											bgcolor={
												theme.palette.secondary.main
											}
											p={0.5}
											spacing={1}
											key={attachment.id}
											style={{ cursor: "pointer" }}
											onClick={() =>
												handleDownloadFile(
													attachment.id
												)
											}
										>
											<Typography
												style={styles.subText}
												component="span"
											>
												{attachment.fileName}{" "}
												<Typography
													style={styles.text}
													component="span"
												>
													{`(${getFormattedFileSize(
														attachment.fileSize
													)})`}
												</Typography>
											</Typography>

											<Box>
												{DownloadIcon(
													16,
													16,
													theme.palette.primary.main
												)}
											</Box>
										</Stack>
									</Box>
								))}
							</Stack>
						</Stack>
					</Box>
				) : null}

				{isEmailComposerOpened ? (
					<Box
						style={{
							pointerEvents: disableViewActions ? "none" : "auto",
						}}
						ref={composerRef}
					>
						{replyMode ? (
							<EmailComposer
								composerValues={defaultComposerValues}
								onClose={closeEmailComposer}
								composerMode={composerMode}
								conversationId={conversationId}
								emailContext={{
									threadId: threadId,
									mailListName: mailListName,
								}}
								trimmedContentContext={{
									sendTime: getFormattedDateMonthYearAndTime(
										mailData?.timeStamp
									),
									fromAddress: mailData?.from,
								}}
								sourceName={sourceName}
								entityId={entityId}
							/>
						) : null}

						{composerMode.forward ? (
							<EmailComposer
								onClose={closeEmailComposer}
								conversationId={conversationId}
								emailContext={{
									threadId: threadId,
									mailListName: mailListName,
								}}
								composerMode={composerMode}
								composerValues={defaultComposerValues}
								forwardMailContext={{
									sendTime: getFormattedDateMonthYearAndTime(
										mailData?.timeStamp
									),
									fromAddress: mailData?.from,
									toAddress: toAddressData,
								}}
								sourceName={sourceName}
								entityId={entityId}
							/>
						) : null}
					</Box>
				) : null}
			</Stack>
		</React.Fragment>
	);
}

import React, { useMemo, useState } from "react";
import {
	Box,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { DwarfButton, MenuItemText, twozoStyles } from "../../../styles/twozo";
import { default as MoreIcon } from "../../../assets/icons/more";
import { default as CloseIcon } from "../../../assets/icons/close";
import Menu from "../../Elements/Menu";
import AddActivity from "../../Summary/Activities/AddActivity";
import ContactAssignOwner from "./assignOwner";
import UpdateField from "../../Elements/UpdateField";
import AddTags from "../../Tags/AddTags";
import { deleteMultipleContacts } from "../../../api/contacts/contactsApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import DeleteDialog from "../../Elements/DeleteDialog";
import { getContactTableKey } from "../../../utils/queryKeys";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import RemoveTags from "../../Tags/RemoveTags";
import { modules } from "../../../utils/common/ModulesName";
import EmailComposerDrawer from "../../Mail/EmailComposer/EmailComposerDrawer";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";
import { useAuth } from "../../../hooks/auth";
import { usePartialBulkUpdateContact } from "../../../hooks/services/contact";
import AssignFollowers from "../../Elements/AssignFollowersDialog";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import { useContactFieldPermissions } from "../../../hooks/modules/auth/contact";

export default function SelectedMenu(props) {
	const { tableRowsData, selected, deSelectAll } = props;
	const theme = useTheme();
	const queryClient = useQueryClient();
	const classes = twozoStyles();

	const [isEmailComposerOpened, setIsEmailComposerOpened] = useState(false);
	const [isAddActivityDialogOpened, setIsAddActivityDialogOpened] =
		useState(false);
	const [isAssignOwnerDialogOpened, setIsAssignOwnerDialogOpened] =
		useState(false);
	const [isUpdateFieldDialogOpened, setIsUpdateFieldDialogOpened] =
		useState(false);
	const [isAddTagsDialogOpened, setIsAddTagsDialogOpened] = useState(false);
	const [isRemoveTagsDialogOpened, setIsRemoveTagsDialogOpened] =
		useState(false);
	const [selectedContactMenuElement, setSelectedContactMenuElement] =
		useState(null);
	const isSelectedContactMenuOpened = Boolean(selectedContactMenuElement);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [isFollowersDialogOpened, setIsFollowersDialogOpened] =
		useState(false);

	const selectedRowIds = [...selected];
	const moduleName = modules.CONTACT;
	const contactTableKey = getContactTableKey();
	const activityAddedSuccessMessage =
		selectedRowIds.length === 1
			? "You added a activity for 1 contact."
			: `You added a activities for ${selectedRowIds.length} contacts.`;

	const { isUserAllowedFor } = useAuth();
	const updateMutation = usePartialBulkUpdateContact();
	const contactFieldPermissions = useContactFieldPermissions();

	const openSelectedContactMenu = (event) => {
		setSelectedContactMenuElement(event.currentTarget);
	};

	const closeSelectedContactMenu = () => {
		setSelectedContactMenuElement(null);
	};

	const toggleAddActivityDialog = () => {
		setIsAddActivityDialogOpened(
			(isAddActivityDialogOpened) => !isAddActivityDialogOpened
		);
	};

	const openAssignOwnerDialog = () => {
		setIsAssignOwnerDialogOpened(true);
	};

	const closeAssignOwnerDialog = () => {
		setIsAssignOwnerDialogOpened(false);
	};

	const openUpdateFieldDialog = () => {
		setIsUpdateFieldDialogOpened(true);
	};

	const closeUpdateFieldDialog = () => {
		setIsUpdateFieldDialogOpened(false);
	};

	const openAddTagsDialog = () => {
		setSelectedContactMenuElement(null);
		setIsAddTagsDialogOpened(true);
	};

	const closeAddTagsDialog = () => {
		setIsAddTagsDialogOpened(false);
	};

	const handleDeleteConatcts = () => {
		setShowDeleteDialog(true);
	};

	const openRemoveTagsDialog = () => {
		setSelectedContactMenuElement(null);
		setIsRemoveTagsDialogOpened(true);
	};

	const closeRemoveTagsDialog = () => {
		setIsRemoveTagsDialogOpened(false);
	};

	const cancelDelete = () => {
		setShowDeleteDialog(false);
	};

	const performDeleteConfirm = () => {
		deleteContactsMutation.mutate(selectedRowIds);
	};

	const deleteContactsMutation = useMutation(
		async (contactIds) => deleteMultipleContacts(contactIds),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(contactTableKey);
				if (selectedRowIds.length > 1) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: `${selectedRowIds.length} contacts deleted.`,
					});
				} else {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.contactDeleted,
					});
				}
				deSelectAll();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		}
	);

	const handleSaveUpdateField = (formData) => {
		updateMutation.mutate(formData, {
			onSuccess: () => {
				closeUpdateFieldDialog();
				if (selectedRowIds.length > 1) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: `${selectedRowIds.length} contacts updated.`,
					});
				} else {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.contactUpdated,
					});
				}
				deSelectAll();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const onActivityAdded = () => {
		deSelectAll();
	};

	const openEmailComposer = () => {
		setIsEmailComposerOpened(true);
	};

	const closeEmailComposer = () => {
		setIsEmailComposerOpened(false);
	};

	const handleUnSelectContact = () => {
		deSelectAll();
	};

	const onTagsAdded = () => {
		deSelectAll();
		closeAddTagsDialog();
	};

	const onTagsRemoved = () => {
		deSelectAll();
		closeRemoveTagsDialog();
	};

	const onOwnerUpdated = () => {
		deSelectAll();
	};

	const openFollowersDialog = () => {
		setIsFollowersDialogOpened(true);
	};

	const closeFollowersDialog = () => {
		setIsFollowersDialogOpened(false);
	};

	const selectedMenuOptions = [
		{
			id: 1,
			name: "Send Email",
			action: openEmailComposer,
			hasPermissionToAccess: isUserAllowedFor(
				PERMISSIONS.email.bulkEmailLimit
			),
		},
		{
			id: 2,
			name: "Add Activity",
			action: toggleAddActivityDialog,
			hasPermissionToAccess: true,
		},
		{
			id: 3,
			name: "Assign Owner",
			action: openAssignOwnerDialog,
			hasPermissionToAccess: isUserAllowedFor(
				PERMISSIONS.contact.bulkAssignRecords
			),
		},
		{
			id: 4,
			name: "Assign Followers",
			action: openFollowersDialog,
			hasPermissionToAccess: true,
		},
		{
			id: 5,
			name: "Update Field",
			action: openUpdateFieldDialog,
			hasPermissionToAccess: isUserAllowedFor(
				PERMISSIONS.contact.bulkUpdateRecords
			),
		},
		{
			id: 6,
			name: "Delete",
			action: handleDeleteConatcts,
			hasPermissionToAccess: isUserAllowedFor(PERMISSIONS.contact.delete),
		},
	];

	const getSelectedMenuOptions = () => {
		return selectedMenuOptions.filter(
			(selectedMenuOption) => selectedMenuOption.hasPermissionToAccess
		);
	};

	const hasEditOrExportPermission =
		isUserAllowedFor(PERMISSIONS.contact.edit) ||
		isUserAllowedFor(PERMISSIONS.contact.exportRecords);

	const onFollowersAssigned = () => {
		deSelectAll();
	};

	const selectedContacts = useMemo(() => {
		return tableRowsData?.map((rowData) => {
			if (selected.has(rowData?.id)) {
				return {
					name: rowData?.partialData?.name,
					value: rowData?.partialData?.id,
				};
			}
		});
	}, [selected, tableRowsData]);

	return (
		<React.Fragment>
			{isEmailComposerOpened ? (
				<EmailComposerDrawer
					onClose={closeEmailComposer}
					isBulkMailEnabled={true}
					composerValues={{ toAddress: selectedRowIds }}
					sourceName={modules.CONTACT}
				/>
			) : null}

			<Box>
				<Menu
					minWidth="200px"
					anchorEl={selectedContactMenuElement}
					open={isSelectedContactMenuOpened}
					onClose={closeSelectedContactMenu}
					style={{
						marginTop: "8px",
					}}
				>
					<Can permission={PERMISSIONS.contact.exportRecords}>
						<MenuItem style={{ height: "40px" }}>
							<MenuItemText
								color={theme.palette.secondary.contrastText}
							>
								Export
							</MenuItemText>
						</MenuItem>
					</Can>

					<Can permission={PERMISSIONS.contact.edit}>
						<MenuItem
							style={{ height: "40px" }}
							onClick={openAddTagsDialog}
						>
							<MenuItemText
								color={theme.palette.secondary.contrastText}
							>
								Add Tags
							</MenuItemText>
						</MenuItem>
					</Can>

					<Can permission={PERMISSIONS.contact.edit}>
						<MenuItem
							style={{ height: "40px" }}
							onClick={openRemoveTagsDialog}
						>
							<MenuItemText
								color={theme.palette.secondary.contrastText}
							>
								Remove Tags
							</MenuItemText>
						</MenuItem>
					</Can>
				</Menu>
			</Box>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddActivityDialogOpened}
				onOpen={toggleAddActivityDialog}
				onClose={toggleAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={toggleAddActivityDialog}
						contacts={selectedContacts}
						onActivityAdded={onActivityAdded}
						activityAddedSuccessMessage={
							activityAddedSuccessMessage
						}
						isAddActivityFromListView={true}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<ContactAssignOwner
				contactIds={selectedRowIds}
				open={isAssignOwnerDialogOpened}
				onClose={closeAssignOwnerDialog}
				onOwnerUpdated={onOwnerUpdated}
			/>

			<AssignFollowers
				open={isFollowersDialogOpened}
				onClose={closeFollowersDialog}
				entityIds={selectedRowIds}
				moduleName={moduleName}
				onFollowersAssigned={onFollowersAssigned}
			/>

			<FieldPermissionContextProvider value={contactFieldPermissions}>
				<UpdateField
					selected={selectedRowIds}
					onSave={handleSaveUpdateField}
					moduleName={moduleName}
					open={isUpdateFieldDialogOpened}
					onClose={closeUpdateFieldDialog}
					isSaving={updateMutation.isLoading}
				/>
			</FieldPermissionContextProvider>

			<AddTags
				sourceName={moduleName}
				isAddTagsDialogOpened={isAddTagsDialogOpened}
				closeAddTagsDialog={closeAddTagsDialog}
				selectedIds={selectedRowIds}
				onTagsAdded={onTagsAdded}
			/>

			<RemoveTags
				sourceName={moduleName}
				isRemoveTagsDialogOpened={isRemoveTagsDialogOpened}
				closeRemoveTagsDialog={closeRemoveTagsDialog}
				selectedIds={selectedRowIds}
				onTagsRemoved={onTagsRemoved}
			/>

			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={2}
				height="100%"
			>
				<Stack direction="row" spacing={0.5}>
					<Typography fontSize={14} fontWeight={500}>
						{selectedRowIds.length} Selected
					</Typography>

					<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
						of {tableRowsData?.length}
					</Typography>

					<IconButton
						disableRipple
						style={{ cursor: "pointer", padding: 0 }}
						onClick={handleUnSelectContact}
					>
						{CloseIcon(18, 18, "rgba(0,0,0,0.6)")}
					</IconButton>
				</Stack>

				<Divider orientation="vertical" style={{ height: "20px" }} />

				{getSelectedMenuOptions().map((menuOption) => (
					<DwarfButton
						key={menuOption.id}
						variant="contained"
						color="secondary"
						disableElevation
						onClick={menuOption.action}
					>
						{menuOption.name}
					</DwarfButton>
				))}

				<DeleteDialog
					title="Are you sure you want to delete the selected contacts?"
					subtitle="It will delete all the selected contacts and the data associated with it"
					open={showDeleteDialog}
					onCancel={cancelDelete}
					onDelete={performDeleteConfirm}
				></DeleteDialog>

				{hasEditOrExportPermission ? (
					<IconButton onClick={openSelectedContactMenu} size="small">
						{MoreIcon(20, 20, theme.palette.primary.main)}
					</IconButton>
				) : null}
			</Stack>
		</React.Fragment>
	);
}

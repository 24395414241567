import React, { useState } from "react";
import {
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import {
	DwarfButton,
	hexToRgba,
	MenuItemText,
} from "../../../../../styles/twozo";
import Menu from "../../../../Elements/Menu";
import {
	useBulkDeleteEmailTemplate,
	useChangeEmailTemplateToFolder,
} from "../../../../../hooks/services/mail/emailTemplate";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { enqueueSnackbar } from "notistack";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import DeleteDialog from "../../../../Elements/DeleteDialog";
import { useEmailTemplateAllFolderList } from "../../../../../hooks/services/mail/emailTemplateFolder";

export default function SelectedMenu(props) {
	const {
		clearSelectedTemplates,
		selectedTemplateIds,
		templateCount,
		templateFolderId,
	} = props;
	const theme = useTheme();

	const [templateFolderMenuElement, setTemplateFolderMenuElement] =
		useState(null);
	const isTemplateFolderMenuOpened = Boolean(templateFolderMenuElement);
	const [showBulkDeleteTemplateDialog, setShowBulkDeleteTemplateDialog] =
		useState(false);

	const successNotificationTitle = "Success!";

	const { data: templateAllFolderList } = useEmailTemplateAllFolderList();
	const changeFolderMutation = useChangeEmailTemplateToFolder();

	const bulkDeleteEmailTemplateMutation =
		useBulkDeleteEmailTemplate(templateFolderId);

	const openTemplateFolderMenu = (event) => {
		setTemplateFolderMenuElement(event.currentTarget);
	};

	const closeTemplateFolderMenu = () => {
		setTemplateFolderMenuElement(null);
	};

	const openBulkDeleteTemplateDialog = () => {
		setShowBulkDeleteTemplateDialog(true);
	};

	const closeBulkDeleteTemplateDialog = () => {
		setShowBulkDeleteTemplateDialog(false);
	};

	const onDeleteEmailTemplates = () => {
		bulkDeleteEmailTemplateMutation.mutate(selectedTemplateIds, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					title: successNotificationTitle,
					message: notificationMessage.emailTemplateDeleted,
				});
				closeBulkDeleteTemplateDialog();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const filterTemplateFolderList = () => {
		if (templateAllFolderList?.length > 0) {
			return templateAllFolderList?.filter(
				(templateFolder) => templateFolder?.id !== templateFolderId
			);
		}
		return [];
	};

	const handleSelectFolder = (selectedFolderId) => {
		let changeFolderRequestData = {
			sourceId: templateFolderId,
			destinationId: selectedFolderId,
			templateIds: selectedTemplateIds,
		};

		changeFolderMutation.mutate(changeFolderRequestData, {
			onSuccess: () => {
				closeTemplateFolderMenu();
				clearSelectedTemplates();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	return (
		<React.Fragment>
			{/* BulkDelete template dialog */}
			<DeleteDialog
				title="Are you sure you want to delete the selected email templates?"
				subtitle="This will delete the email templates with all the metrics associated with it"
				open={showBulkDeleteTemplateDialog}
				onCancel={closeBulkDeleteTemplateDialog}
				onDelete={onDeleteEmailTemplates}
				disableDeleteButton={bulkDeleteEmailTemplateMutation.isLoading}
			></DeleteDialog>

			<Menu
				minWidth="280px"
				anchorEl={templateFolderMenuElement}
				open={isTemplateFolderMenuOpened}
				onClose={closeTemplateFolderMenu}
				style={{
					marginTop: "4px",
				}}
				sx={{ transform: "translateX(-5px)" }}
			>
				{filterTemplateFolderList()?.map((folderData) => (
					<MenuItem
						key={folderData?.id}
						style={{ height: "40px" }}
						onClick={() => handleSelectFolder(folderData?.id)}
					>
						<MenuItemText>{folderData?.name}</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			<Stack direction="row" alignItems="center" spacing={2}>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Typography fontSize={14} fontWeight={500}>
						{selectedTemplateIds?.length} Selected
						<Typography
							component="span"
							fontSize={14}
							fontWeight={500}
							style={{
								paddingLeft: "4px",
								color: "rgba(0, 0, 0, 0.6)",
							}}
						>
							of {templateCount}
						</Typography>
					</Typography>

					<IconButton
						onClick={clearSelectedTemplates}
						size="small"
						style={{ padding: 0, paddingTop: "1px" }}
					>
						{CloseIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
					</IconButton>
				</Stack>

				<Divider orientation="vertical" style={{ height: "20px" }} />

				<DwarfButton
					variant="contained"
					color="secondary"
					disableElevation
					onClick={openTemplateFolderMenu}
					disabled={templateAllFolderList?.length <= 1}
					sx={{
						"&.Mui-disabled": {
							backgroundColor: "#E7F7F0",
							color: hexToRgba(theme.palette.primary.main, "0.6"),
						},
					}}
				>
					Move To
				</DwarfButton>

				<DwarfButton
					variant="contained"
					color="secondary"
					disableElevation
					onClick={openBulkDeleteTemplateDialog}
				>
					Delete
				</DwarfButton>
			</Stack>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import {
	MenuItemText,
	TableCellText,
	TableHeaderLabel,
	Tooltip,
} from "../../../../../styles/twozo";
import {
	Box,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { default as EditIcon } from "../../../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../../../assets/icons/delete";
import { default as InfoIcon } from "../../../../../assets/icons/info";
import { default as CallIcon } from "../../../../../assets/icons/call";
import { default as MailIcon } from "../../../../../assets/icons/mail";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDownCentered";
import UnKnownImage from "../../../../../assets/images/contact/unknownContact.png";
import { getDayMonthTimeAndMinuteFormat } from "../../../../../utils/DateUtils";
import Menu from "../../../../Elements/Menu";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import DeleteDialog from "../../../../Elements/DeleteDialog";
import EditTeam from "../EditTeam";
import CustomSwipeableDrawer from "../../../../Elements/CustomSwipeableDrawer";
import { useUserManagementContext } from "../../UserManagementContext";
import {
	useDeleteTeam,
	useTeamUsersList,
} from "../../../../../hooks/services/userManagement/teams";
import { useAuth } from "../../../../../hooks/auth";
import { PERMISSIONS } from "../../../../../utils/Auth";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

export default function TeamsList() {
	const theme = useTheme();

	const sortTypes = {
		ascending: "asc",
		decending: "desc",
	};

	// teams list context
	const {
		listData: teamsListData,
		handleSort: handleSort,
		isLoadingListData: isLoadingTeamList,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useUserManagementContext();

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const [selectedTeamDetails, setSelectedTeamDetails] = useState({
		teamId: null,
		isManager: false,
	});

	// query call :-
	const deleteMutation = useDeleteTeam();
	const { data: userList, isLoading } = useTeamUsersList(selectedTeamDetails);
	const { isUserAllowedFor } = useAuth();

	const [userMenuElement, setUserMenuElement] = useState(null);
	const isUserMenuOpened = Boolean(userMenuElement);
	const [userCardElement, setUserCardElement] = useState(null);
	const isUserCardOpened = Boolean(userCardElement);

	const [selectedUser, setSelectedUser] = useState(null);
	const [hoveredUserId, setHoveredUserId] = useState(null);
	const [deletedTeamName, setDeletedTeamName] = useState("");
	const [tableRowHovered, setTableRowHovered] = useState(null);
	const [selectedTeamId, setSelectedTeamId] = useState(null);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [isEditTeamDialogOpened, setsEditTeamDialogOpened] = useState(false);

	const [tableHeader, setTableHeader] = useState([
		{
			id: 1,
			displayName: "Team Name",
			name: "name",
			config: {
				width: "20%",
			},
		},
		{
			id: 2,
			displayName: "Team Managers",
			name: "managers",
			config: {
				width: "20%",
			},
		},
		{
			id: 3,
			displayName: "Users",
			name: "users",
			config: {
				width: "15%",
			},
		},
		{
			id: 4,
			displayName: "Created by",
			name: "createdBy",
			config: {
				width: "20%",
			},
		},
		{
			id: 5,
			displayName: "Updated by",
			name: "updatedBy",
			config: {
				width: "15%",
			},
		},
		{
			id: 6,
			displayName: "",
			config: {
				width: "10%",
			},
		},
	]);

	const openEditTeamDialog = (team) => {
		setSelectedTeamId(team.id);
		setsEditTeamDialogOpened(true);
	};

	const closeEditTeamDialog = () => {
		setSelectedTeamId(null);
		setsEditTeamDialogOpened(false);
	};

	const onMouseOverTableRow = (id) => setTableRowHovered(id);
	const onMouseOutTableRow = () => setTableRowHovered(null);

	const openDeleteDialog = (teamId, teamName) => {
		setSelectedTeamId(teamId);
		setDeletedTeamName(teamName);
		setIsDeleteDialogOpened(true);
	};

	const closeDeleteDialog = () => {
		setSelectedTeamId(null);
		setIsDeleteDialogOpened(false);
	};

	const openUserListMenu = (event, teamData) => {
		setUserMenuElement(event.currentTarget);
		setSelectedTeamDetails((selectedTeamDetails) => ({
			...selectedTeamDetails,
			teamId: teamData.id,
			isManager: false,
		}));
	};

	const openManagerListMenu = (event, teamData) => {
		if (teamData.managers > 0) {
			setUserMenuElement(event.currentTarget);
			setSelectedTeamDetails((selectedTeamDetails) => ({
				...selectedTeamDetails,
				teamId: teamData.id,
				isManager: true,
			}));
		}
	};

	const closeUserListMenu = () => {
		setUserMenuElement(null);
	};

	const openUserCard = (event, user) => {
		setUserCardElement(event.currentTarget);
		setSelectedUser(user);
	};

	const closeUserCard = () => {
		setUserCardElement(null);
	};

	const getManagerLabel = (managerCount) => {
		if (managerCount <= 0) {
			return "-";
		}
		if (managerCount > 1) {
			return `${managerCount} Managers`;
		}
		return `${managerCount} Manager`;
	};

	const getUserLabel = (userCount) => {
		if (userCount > 1) {
			return `${userCount} Users`;
		}
		return `${userCount} User`;
	};

	const handleDeleteTeams = () => {
		deleteMutation.mutate(selectedTeamId, {
			onSuccess: () => {
				enqueueSnackbar({
					title: "Success!",
					variant: notificationVariants.error,
					message: notificationMessage.teamDeleted,
				});
				closeDeleteDialog();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const isHideEditAndDeleteIcon = (team) => {
		return (
			!(selectedTeamId !== team.id && tableRowHovered !== team.id) &&
			isUserAllowedFor(PERMISSIONS.team.access)
		);
	};

	const isActionColumn = (index, tableHeader) => {
		return index === tableHeader.length - 1;
	};

	const toggleSort = (sortIndex) => {
		let sortRequest = {};

		if (tableHeader[sortIndex].sort === sortTypes.ascending) {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.decending,
			};
		} else {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.ascending,
			};
		}

		handleSort(sortRequest);

		setTableHeader((headers) => {
			const updatedHeaders = [...headers];

			updatedHeaders.forEach((column, index) => {
				if (index === sortIndex) {
					column.sort =
						column.sort === sortTypes.ascending
							? sortTypes.decending
							: sortTypes.ascending;
				} else {
					column.sort = sortTypes.decending;
				}
			});

			return updatedHeaders;
		});
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditTeamDialogOpened}
				onOpen={openEditTeamDialog}
				onClose={closeEditTeamDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					sx={{
						width: "50vw",
						minHeight: "100vh",
						backgroundColor: "#fff",
						borderRadius: "10px 0px 0px 10px",
					}}
				>
					<EditTeam
						onClose={closeEditTeamDialog}
						teamId={selectedTeamId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{/* Delete Dialog */}
			<DeleteDialog
				title={`Are you sure you want to delete the team ${deletedTeamName}?`}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteTeams}
			/>

			{/* User List Menu*/}
			<Menu
				minWidth="200px"
				anchorEl={userMenuElement}
				open={isUserMenuOpened}
				onClose={closeUserListMenu}
				PaperProps={{ style: { maxHeight: "213px" } }}
			>
				{isLoading ? (
					<Stack
						height="148px"
						minWidth="200px"
						justifyContent="center"
						alignItems="center"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					Array.isArray(userList) &&
					userList.map((user) => (
						<Stack
							key={user.id}
							minWidth="300px"
							minHeight="40px"
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							px={2}
							onMouseOver={() => setHoveredUserId(user.id)}
							onMouseOut={() => setHoveredUserId(null)}
							backgroundColor={
								user.id === hoveredUserId
									? "#F4F5F5"
									: "transparent"
							}
						>
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
							>
								<img
									src={UnKnownImage}
									alt="img"
									width={20}
									height={20}
								/>
								<MenuItemText pl={1}>{user.name}</MenuItemText>
							</Stack>

							<IconButton
								onClick={(event) => openUserCard(event, user)}
							>
								{InfoIcon(16, 16, "rgba(0, 0, 0, 0.6)")}
							</IconButton>
						</Stack>
					))
				)}
			</Menu>

			{/* User Card Details Menu*/}
			<Menu
				minWidth="320px"
				anchorEl={userCardElement}
				open={isUserCardOpened}
				onClose={closeUserCard}
			>
				<Box px={2} py={1}>
					<Stack direction="row" spacing={2}>
						<Stack>
							<img
								src={UnKnownImage}
								alt="img"
								width={45}
								height={45}
							/>
						</Stack>

						<Stack>
							<Typography
								fontSize={17}
								fontWeight={600}
								color={theme.palette.secondary.contrastText}
							>
								{selectedUser?.name}
							</Typography>

							{!!selectedUser?.phone && (
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
									pt={0.5}
								>
									{CallIcon(
										16,
										16,
										theme.palette.primary.main
									)}

									<Typography
										fontSize={15}
										fontWeight={500}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{selectedUser?.phone}
									</Typography>
								</Stack>
							)}

							{!!selectedUser?.email && (
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
								>
									{MailIcon(
										16,
										16,
										theme.palette.primary.main
									)}

									<Typography
										fontSize={15}
										fontWeight={500}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{selectedUser?.email}
									</Typography>
								</Stack>
							)}
						</Stack>
					</Stack>
				</Box>
			</Menu>
			<TableContainer sx={{ maxHeight: "100%" }}>
				<Table stickyHeader sx={{ minWidth: 650 }} size="small">
					<TableHead>
						<TableRow sx={{ height: "36px" }}>
							{tableHeader.map((header, index) => (
								<TableCell
									key={header.id}
									width={header.config.width}
									onClick={() => toggleSort(index)}
								>
									<Stack
										direction="row"
										alignItems="center"
										spacing={1}
									>
										<TableHeaderLabel>
											{header.displayName}
										</TableHeaderLabel>
										<Box display="flex">
											{!isActionColumn(
												index,
												tableHeader
											) ? (
												header.sort ===
												sortTypes.ascending ? (
													<Box
														display="flex"
														style={{
															transform:
																"rotate(180deg)",
														}}
													>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.6)"
														)}
													</Box>
												) : (
													<Box display="flex">
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.6)"
														)}
													</Box>
												)
											) : (
												""
											)}
										</Box>
									</Stack>
								</TableCell>
							))}
						</TableRow>
					</TableHead>

					<TableBody>
						{isLoadingTeamList ? (
							<TableRow
								style={{
									height: "60vh",
								}}
							>
								<TableCell colSpan={6} sx={{ borderBottom: 0 }}>
									<Stack
										height="100%"
										justifyContent="center"
										alignItems="center"
										width="100%"
									>
										<CircularProgress />
									</Stack>
								</TableCell>
							</TableRow>
						) : (
							teamsListData?.pages?.map((page) =>
								page?.teams?.map((team) => (
									<TableRow
										key={team.id}
										onMouseOver={() =>
											onMouseOverTableRow(team.id)
										}
										onMouseOut={onMouseOutTableRow}
									>
										<TableCell width="20%">
											<TableCellText>
												{team.name}
											</TableCellText>
										</TableCell>

										<TableCell width="20%">
											<TableCellText
												fontWeight={500}
												color={
													team.managers > 0
														? theme.palette
																.secondary
																.contrastText
														: "#000"
												}
												onClick={(event) =>
													openManagerListMenu(
														event,
														team
													)
												}
											>
												{getManagerLabel(team.managers)}
											</TableCellText>
										</TableCell>

										<TableCell width="15%">
											<TableCellText
												fontWeight={500}
												color={
													theme.palette.secondary
														.contrastText
												}
												onClick={(event) =>
													openUserListMenu(
														event,
														team
													)
												}
											>
												{getUserLabel(team.usersCount)}
											</TableCellText>
										</TableCell>

										<TableCell width="20%">
											<Tooltip
												title={getDayMonthTimeAndMinuteFormat(
													team.createdTime
												)}
												placement="right"
											>
												<Stack
													direction="row"
													alignItems="center"
													spacing={1}
													width="fit-content"
												>
													<img
														src={UnKnownImage}
														alt="img"
														width={26}
														height={26}
													/>

													<TableCellText>
														{team.createdBy.name}
													</TableCellText>
												</Stack>
											</Tooltip>
										</TableCell>

										<TableCell width="15%">
											<Tooltip
												title={getDayMonthTimeAndMinuteFormat(
													team.updatedTime
												)}
												placement="right"
											>
												<Stack
													direction="row"
													alignItems="center"
													spacing={1}
													width="fit-content"
												>
													<img
														src={UnKnownImage}
														alt="img"
														width={26}
														height={26}
													/>

													<TableCellText>
														{team.updatedBy.name}
													</TableCellText>
												</Stack>
											</Tooltip>
										</TableCell>

										<TableCell width="10%" sx={{ py: 0 }}>
											<Stack alignItems="flex-end">
												{isHideEditAndDeleteIcon(
													team
												) ? (
													<Box
														style={{
															height: "37px",
															width: "100px",
															border: `1px solid ${theme.palette.primary.main}`,
															borderRadius: "8px",
														}}
														py={1}
														px={1.5}
													>
														<Stack
															direction="row"
															justifyContent="center"
															alignItems="center"
															style={{
																height: "100%",
															}}
															spacing={1.5}
														>
															<Box
																display="flex"
																onClick={() =>
																	openEditTeamDialog(
																		team
																	)
																}
															>
																{EditIcon(
																	20,
																	20,
																	theme
																		.palette
																		.primary
																		.main
																)}
															</Box>

															<Divider orientation="vertical" />

															<Box
																display="flex"
																onClick={() =>
																	openDeleteDialog(
																		team.id,
																		team.name
																	)
																}
															>
																{DeleteIcon(
																	20,
																	20,
																	theme
																		.palette
																		.error
																		.main
																)}
															</Box>
														</Stack>
													</Box>
												) : null}
											</Stack>
										</TableCell>
									</TableRow>
								))
							)
						)}
						<TableRow>
							<TableCell
								colSpan={6}
								sx={{ borderBottom: "none" }}
							>
								<Box ref={lastRowRef}>
									{!isLoadingTeamList && isFetching && (
										<Stack
											direction="row"
											alignItems="center"
											justifyContent="center"
											spacing={1}
											py={2}
										>
											<CircularProgress size={18} />

											<Typography fontSize={12}>
												Loading More Data
											</Typography>
										</Stack>
									)}
								</Box>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</React.Fragment>
	);
}

export const validateNumberField = (field, config, isActionField) => {
	const value = field?.values?.[0]?.value?.trim()
		? Number(field?.values?.[0]?.value?.trim())
		: "";
	const min = config?.min || 0;
	const max = config?.max;

	if (!value?.toString()) {
		return { error: true, message: isActionField ? "" : "Can’t be empty" };
	}
	if (!value.toString() || value < min) {
		return {
			error: true,
			message: `Min. of ${
				min.toString().length || 1
			} numbers are required`,
		};
	}
	if (max && value && BigInt(value) > max) {
		return {
			error: true,
			message: `Max. of ${max.toString().length} numbers are allowed`,
		};
	}
	return { error: false, message: "" };
};

import React from "react";
import TooltipComponent from "../../../TooltipComponent";
import { Stack, Typography } from "@mui/material";
import { twozoStyles } from "../../../../../styles/twozo";

export default function SummaryCardTitle(props) {
	const { summaryField } = props;
	const classes = twozoStyles();

	return (
		<React.Fragment>
			<Stack direction={"row"} alignItems="center">
				<TooltipComponent
					title={summaryField.fieldName}
					placement="top"
					width="219px"
					verticalDisplacement={-3}
				>
					<Typography fontSize={15} className={classes.summaryHead}>
						{summaryField.fieldName}
					</Typography>
				</TooltipComponent>
				<Typography
					fontSize={15}
					sx={{ paddingRight: "16px", marginLeft: "4px" }}
				>
					{" "}
					:{" "}
				</Typography>
			</Stack>
		</React.Fragment>
	);
}

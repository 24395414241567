import {
	Box,
	Checkbox,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { default as AddIcon } from "../../../../../../assets/icons/add";
import { default as DragIcon } from "../../../../../../assets/icons/drag";
import { default as CloseIcon } from "../../../../../../assets/icons/close";
import { default as DropDownIcon } from "../../../../../../assets/icons/dropDown";
import Menu from "../../../../../Elements/Menu";
import { useSelectReducer } from "../../FieldContext/SelectReducer";
import { fieldTypes } from "../../../../../../utils/dataFieldsUtils";
import { Tooltip } from "../../../../../../styles/twozo";
import DragDropContextWithNonce from "../../../../../Elements/DragDropContextWithNonce";

export default function MultiselectChoices(props) {
	const {
		field,
		onClosedMultiselectChoiceMenu,
		multiselectChoices = [],
		updateMultiselectChoices,
		isFieldEditingRestricted,
	} = props;

	const theme = useTheme();

	// select reducer
	const {
		selectState,
		onCreateSelectChoices,
		onResetSelectChoices,
		onChoiceCreationError,
		onDuplicateChoiceError,
		errorOnCharactersLimitExceeded,
	} = useSelectReducer();

	const [multiselectChoicesElement, setMultiselectChoicesElement] =
		useState(null);
	const openMultiselectChoicesMenu = Boolean(multiselectChoicesElement);

	const [isNewOptionCreated, setIsNewOptionCreated] = useState(false);
	const [multiselectChoiceHovered, setMultiselectChoiceHovered] =
		useState(null);
	const [isChoiceTooltipOpened, setIsChoiceTooltipOpened] = useState(false);
	const [hoveredChoiceIndex, setHoveredChoiceIndex] = useState(null);
	const [editableChoiceIndex, setEditableChoiceIndex] = useState(null);
	const isStaticDropdown = field.config.dropdownType === fieldTypes.static;
	const hasError =
		selectState.hasCreationError ||
		selectState.hasDuplicateError ||
		selectState.isLimitExceeded;

	const editMultiSelectChoiceName = (index) => {
		if (hasError || isNewOptionCreated || isStaticDropdown) {
			return;
		}
		if (isDuplicateOption()) {
			onDuplicateChoiceError();
		} else {
			setEditableChoiceIndex(index);
		}
	};

	const OpenMultiselectChoicesMenu = (event) => {
		if (isFieldEditingRestricted) {
			return;
		}

		setMultiselectChoicesElement(event.currentTarget);
	};

	const isDuplicateOption = () => {
		const uniqueChoices = new Set();
		for (const choice of multiselectChoices) {
			const choiceName = choice.name.trim().toLowerCase();
			if (choiceName !== "" && uniqueChoices.has(choiceName)) {
				return true;
			}
			uniqueChoices.add(choiceName);
		}

		return false;
	};

	const getFilteredEmptyOptions = () => {
		let filteredEmptyChoices = multiselectChoices.filter((choice) => {
			return choice.name !== "";
		});

		return filteredEmptyChoices;
	};

	const isEmptyOption = () => {
		return getFilteredEmptyOptions()?.length < 0;
	};

	const validateOnFieldClose = (_, reason) => {
		if (selectState.hasDuplicateError || selectState.isLimitExceeded) {
			return;
		}

		if (reason === "backdropClick") {
			if (isDuplicateOption()) {
				onDuplicateChoiceError();
				return;
			}
			closeMultiselectChoicesMenu();
		}
	};

	const closeMultiselectChoicesMenu = () => {
		onClosedMultiselectChoiceMenu();
		setIsNewOptionCreated(false);
		setMultiselectChoicesElement(null);
		setEditableChoiceIndex(null);
		onResetSelectChoices();
		updateMultiselectChoices(getFilteredEmptyOptions());
	};

	const setMultiSelectChoiceName = (index, value) => {
		const modifiedMultiselectChoices = multiselectChoices.map(
			(choice, choiceIndex) => {
				if (choiceIndex === index) {
					return { ...choice, name: value };
				}

				return choice;
			}
		);

		let maxChoiceLength = 126;
		if (value.trim().length > maxChoiceLength) {
			errorOnCharactersLimitExceeded();
		} else {
			updateMultiselectStateOnOptionEdit(value);
			updateMultiselectChoices(modifiedMultiselectChoices);
			setEditableChoiceIndex(index);
		}
	};

	const updateMultiselectStateOnOptionEdit = (value) => {
		if (value !== "") {
			onResetSelectChoices();
		} else {
			onCreateSelectChoices();
		}
	};

	const handleRemoveOption = (index) => {
		if (isOptionRemovable(index)) {
			removeChoice(index);
			onResetSelectChoices();
			setEditableChoiceIndex(null);
			setIsNewOptionCreated(false);
		}
	};

	const removeChoice = (index) => {
		let updatedMultiselectChoices = [...multiselectChoices];
		updatedMultiselectChoices.splice(index, 1);
		updateMultiselectChoices([...updatedMultiselectChoices]);
	};

	const onMouseOverMultiselectChoice = (id) =>
		setMultiselectChoiceHovered(id);

	const onMouseOutMultiselectChoice = () => setMultiselectChoiceHovered(null);

	const handleMultiselectChoiceValidation = () => {
		if (isMultiselectOptionsValid()) {
			addMultiSelectChoice();
		}
	};

	const isMultiselectOptionsValid = () => {
		if (!isMultiselectChoicesLimitExceeded()) {
			return;
		}

		if (hasError) {
			return;
		}

		if (selectState.isCreateMode) {
			onChoiceCreationError();
		} else {
			return validateAndDispatchMultiselectState();
		}
	};

	const validateAndDispatchMultiselectState = () => {
		if (isEmptyOption()) {
			onChoiceCreationError();
			return false;
		} else if (isDuplicateOption()) {
			onDuplicateChoiceError();
			return false;
		}

		return true;
	};

	const addMultiSelectChoice = () => {
		let modifiedMultiselectChoices = [];
		let emptyChoice = {
			name: "",
			isNew: true,
		};

		if (multiselectChoices.length < 1) {
			modifiedMultiselectChoices = [
				...multiselectChoices,
				emptyChoice,
				emptyChoice,
			];
		} else {
			modifiedMultiselectChoices = [...multiselectChoices, emptyChoice];
		}
		setIsNewOptionCreated(true);
		onCreateSelectChoices();
		setEditableChoiceIndex(multiselectChoices.length);
		updateMultiselectChoices(modifiedMultiselectChoices);
	};

	const reorderChoice = (choice, startIndex, endIndex) => {
		const result = Array.from(choice);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onChoiceDragEnd = (result, choice) => {
		if (!hasError) {
			if (!result.destination) {
				return;
			}

			if (result.destination.index === result.source.index) {
				return;
			}

			const updatedOptionOrderData = reorderChoice(
				choice,
				result.source.index,
				result.destination.index
			);
			updateMultiselectChoices(updatedOptionOrderData);
		}
	};

	const handleUpdateDefaultOption = (checked, selectedIndex) => {
		if (!hasError) {
			let modifiedMultiselectChoices = multiselectChoices.map(
				(choice, index) => {
					if (selectedIndex === index) {
						if (!choice.isDefault) {
							return { ...choice, isDefault: true };
						} else if (!checked) {
							let updatedChoice = { ...choice };
							delete updatedChoice.isDefault;
							return updatedChoice;
						}
					}

					return choice;
				}
			);
			updateMultiselectChoices(modifiedMultiselectChoices);
		}
	};

	const isMultiselectChoicesLimitExceeded = () => {
		return multiselectChoices.length < 10;
	};

	const isOptionRemovable = (choiceIndex) => {
		let defaultChoices = 2;

		if (multiselectChoices.length > defaultChoices) {
			if (!hasError && !isNewOptionCreated) {
				return true;
			}

			return editableChoiceIndex === choiceIndex;
		}
	};

	const hasMultiselectChoiceError = (choice) => {
		let defaultChoicesCount = 2;
		let maxChoiceLength = 126;

		if (multiselectChoices.length === defaultChoicesCount && hasError) {
			if (selectState.isLimitExceeded) {
				return choice.name.length >= maxChoiceLength;
			}
			return choice.name === "" || isDuplicateOption();
		}

		return hasError;
	};

	const getNotAllowedCursor = () => {
		if (hasError) {
			return "not-allowed";
		}
	};

	const isOptionInEditOrNew = (choice, index) => {
		return editableChoiceIndex === index || choice.name === "";
	};

	const isDragEnabled = () => {
		return hasError || isNewOptionCreated;
	};

	const handleOpenChoiceTooltip = (event, index) => {
		setIsChoiceTooltipOpened(
			event.target.scrollWidth > event.target.clientWidth
		);
		setHoveredChoiceIndex(index);
	};

	return (
		<React.Fragment>
			<Box
				pl={1}
				pr={0.5}
				py={0.2}
				sx={{
					backgroundColor: (theme) => theme.palette.secondary.main,
					borderRadius: "3px",
				}}
				onClick={OpenMultiselectChoicesMenu}
			>
				<Stack direction="row" spacing={0.5}>
					<Typography fontSize={14}>
						{multiselectChoices.length}{" "}
						{multiselectChoices.length > 1 ? "Choices" : "Choice"}{" "}
						Added
					</Typography>

					{DropDownIcon(18, 18, theme.palette.secondary.contrastText)}
				</Stack>
			</Box>

			<Menu
				minWidth="250px"
				anchorEl={multiselectChoicesElement}
				open={openMultiselectChoicesMenu}
				onClose={validateOnFieldClose}
			>
				<DragDropContextWithNonce
					onDragEnd={(result) =>
						onChoiceDragEnd(result, multiselectChoices)
					}
				>
					<Droppable droppableId="multiselect-choices">
						{(provided) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								<Box overflow="auto">
									{multiselectChoices.map((choice, index) => (
										<Draggable
											draggableId={index.toString()}
											index={index}
											key={index}
											isDragDisabled={isDragEnabled()}
										>
											{(provided) => (
												<Box
													ref={provided.innerRef}
													{...provided.draggableProps}
												>
													<Box
														px={
															!isStaticDropdown
																? 1
																: 0
														}
														key={index}
														style={{
															minHeight: "40px",
															backgroundColor:
																multiselectChoiceHovered ===
																index
																	? "#F4F5F5"
																	: "#fff",
														}}
														onMouseOver={() =>
															onMouseOverMultiselectChoice(
																index
															)
														}
														onMouseOut={() =>
															onMouseOutMultiselectChoice()
														}
													>
														<Stack
															direction="row"
															justifyContent="space-between"
															alignItems="center"
															height="40px"
															spacing={1}
															pl={
																isStaticDropdown &&
																1
															}
															pr={2}
														>
															<Stack
																pl={0.6}
																direction="row"
																spacing={0.5}
																alignItems="center"
																justifyContent="center"
															>
																<Stack
																	direction="row"
																	alignItems="center"
																>
																	<Box
																		display="flex"
																		sx={{
																			cursor: getNotAllowedCursor(),
																		}}
																		{...provided.dragHandleProps}
																	>
																		{isStaticDropdown
																			? null
																			: DragIcon(
																					20,
																					20,
																					"#000000"
																				)}
																	</Box>

																	<Tooltip
																		title={
																			choice.isDefault
																				? "Default Choice"
																				: "Mark as Default Choice"
																		}
																		placement="left"
																		PopperProps={{
																			modifiers:
																				[
																					{
																						name: "offset",
																						options:
																							{
																								offset: [
																									0,
																									-8,
																								],
																							},
																					},
																				],
																		}}
																	>
																		<Checkbox
																			sx={{
																				cursor: getNotAllowedCursor(),
																			}}
																			checked={
																				!!choice.isDefault
																			}
																			value={
																				choice.value
																			}
																			onChange={(
																				_,
																				value
																			) =>
																				handleUpdateDefaultOption(
																					value,
																					index
																				)
																			}
																			disableRipple
																		/>
																	</Tooltip>
																</Stack>

																{isOptionInEditOrNew(
																	choice,
																	index
																) ? (
																	<TextField
																		variant="standard"
																		value={
																			choice.name
																		}
																		onChange={(
																			event
																		) =>
																			setMultiSelectChoiceName(
																				index,
																				event
																					.target
																					.value
																			)
																		}
																		InputProps={{
																			style: {
																				fontSize: 13,
																			},
																		}}
																		fullWidth
																		autoFocus
																		FormHelperTextProps={{
																			sx: {
																				fontSize: 13,
																				fontWeight: 500,
																			},
																		}}
																		error={hasMultiselectChoiceError(
																			choice
																		)}
																		helperText={
																			hasMultiselectChoiceError(
																				choice
																			) &&
																			selectState.errorMessage
																		}
																	/>
																) : (
																	<Box
																		onClick={() =>
																			editMultiSelectChoiceName(
																				index
																			)
																		}
																	>
																		<Tooltip
																			title={
																				choice.name
																			}
																			placement="top"
																			open={
																				isChoiceTooltipOpened &&
																				hoveredChoiceIndex ===
																					index
																			}
																		>
																			<Typography
																				noWrap
																				fontSize={
																					13
																				}
																				onMouseOver={(
																					event
																				) =>
																					handleOpenChoiceTooltip(
																						event,
																						index
																					)
																				}
																				onMouseOut={() => {
																					setIsChoiceTooltipOpened(
																						false
																					);
																					setHoveredChoiceIndex(
																						null
																					);
																				}}
																				style={{
																					opacity:
																						isStaticDropdown
																							? 0.5
																							: 1,
																				}}
																				sx={{
																					cursor: getNotAllowedCursor(),
																					width: "220px",
																				}}
																			>
																				{
																					choice.name
																				}
																			</Typography>
																		</Tooltip>
																	</Box>
																)}
															</Stack>
															{!isStaticDropdown ? (
																<Box
																	onClick={() =>
																		handleRemoveOption(
																			index
																		)
																	}
																	display="flex"
																	sx={{
																		opacity:
																			multiselectChoices.length ===
																			2
																				? 0.6
																				: 1,
																		cursor: isOptionRemovable(
																			index
																		)
																			? "pointer"
																			: "not-allowed",
																	}}
																>
																	{CloseIcon(
																		20,
																		20,
																		"#000"
																	)}
																</Box>
															) : null}
														</Stack>
													</Box>
												</Box>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</Box>
							</div>
						)}
					</Droppable>
				</DragDropContextWithNonce>

				{!isStaticDropdown ? (
					<Box
						onClick={handleMultiselectChoiceValidation}
						style={{
							height: "40px",
							cursor:
								hasError || !isMultiselectChoicesLimitExceeded()
									? "not-allowed"
									: "pointer",
						}}
						pl={multiselectChoices.length > 0 ? 3.5 : 3}
						pt={0.5}
					>
						<Stack
							height="100%"
							direction="row"
							spacing={2}
							alignItems="center"
							sx={{
								opacity:
									hasError ||
									!isMultiselectChoicesLimitExceeded()
										? 0.6
										: 1,
							}}
						>
							{AddIcon(
								20,
								20,
								theme.palette.secondary.contrastText
							)}

							<Typography
								fontSize={14}
								color={theme.palette.secondary.contrastText}
							>
								Add Choice
							</Typography>
						</Stack>
					</Box>
				) : null}
			</Menu>
		</React.Fragment>
	);
}

import {
	Box,
	Divider,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";
import { default as DropdownIcon } from "../../../../../../../../../../../../assets/icons/dropDownRight";
import SingleDateTimePicker from "../../../../../../../../../../../Elements/SingleDateTimePicker";
import dayjs from "dayjs";

export default function Dropdown(params) {
	const {
		functions,
		field,
		value,
		functionId,
		onFunctionChange,
		onFieldValueChange,
		updateCustomDropdown,
		isCustomDropdown,
	} = params;
	const theme = useTheme();

	const selectedValue = useMemo(() => {
		if (functionId) {
			let fnValue = functions[functionId];
			return fnValue;
		}
		return {};
	}, [functionId, functions]);

	const [dateTimeRangeValue, setDateTimeRangeValue] = useState(value ?? null);

	const dropdownStyle = {
		backgroundColor: theme.palette.secondary.main,
		borderRadius: "0px 8px 8px 0px",
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none",
			borderRadius: "0px 8px 8px 0px",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				border: "none",
			},
		},
		height: "42px",
	};

	const options = useMemo(() => {
		if (functions && Array.isArray(field?.functionIds)) {
			return field.functionIds
				.map((functionId) => functions[functionId])
				.filter(Boolean);
		}
		return [];
	}, [field?.functionIds, functions]);

	function getKeyByName(data, targetName) {
		const entry = Object.entries(data).find(
			([, value]) => value.name === targetName
		);
		return entry ? entry[0] : null;
	}

	const handleChangeFieldName = (event) => {
		const { value } = event.target;
		updateCustomDropdown(value?.isCustom ?? false);
		const key = getKeyByName(functions, value?.name);
		onFunctionChange(key);
	};

	const handleDateTimeRangeValueChange = (dateTimeRangeValue) => {
		setDateTimeRangeValue(dateTimeRangeValue?.valueOf());
		onFieldValueChange(dateTimeRangeValue?.valueOf());
	};

	const getParsedValue = (value) => {
		return value ? dayjs(value) : null;
	};

	return (
		<>
			<Divider orientation="vertical" style={{ height: "42px" }} />
			{isCustomDropdown ? (
				<SingleDateTimePicker
					value={getParsedValue(dateTimeRangeValue)}
					onChange={(value) => handleDateTimeRangeValueChange(value)}
					textFieldProps={{
						sx: {
							backgroundColor: theme.palette.secondary.main,
							borderRadius: "0px 8px 8px 0px",
							"& .MuiOutlinedInput-notchedOutline": {
								border: "none",
								borderRadius: "0px 8px 8px 0px",
								width: "100%",
							},
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									border: "none",
								},
							},
							height: "42px",
							width: "100%",
						},
						inputProps: {
							style: { textAlign: "left", fontSize: "14px" },
						},
					}}
					slotProps={{ inputAdornment: { position: "end" } }}
					popperProps={{ placement: "bottom-end" }}
				/>
			) : (
				<Select
					value={selectedValue || ""}
					size="medium"
					fullWidth
					renderValue={() => (
						<Typography
							noWrap
							fontSize={14}
							color={
								selectedValue?.name
									? "#000"
									: "rgba(0, 0, 0, 0.5)"
							}
						>
							{selectedValue?.name
								? selectedValue.name
								: "Select"}
						</Typography>
					)}
					sx={dropdownStyle}
					onChange={handleChangeFieldName}
				>
					{options?.map((option) => (
						<MenuItem
							key={option.name}
							value={option}
							style={{ width: "100%" }}
						>
							<Stack
								direction="row"
								spacing={1}
								justifyContent="space-between"
								alignItems="center"
								width="100%"
							>
								<Typography fontSize={13}>
									{option?.name || ""}
								</Typography>

								{option?.isCustom && (
									<Box display="flex">
										{DropdownIcon(14, 14)}
									</Box>
								)}
							</Stack>
						</MenuItem>
					))}
				</Select>
			)}
		</>
	);
}

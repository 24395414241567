import React from "react";
import {
	Box,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	useTheme,
} from "@mui/material";
import { TableCellText, TableHeaderLabel } from "../../../../../styles/twozo";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDownCentered";

export default function ExportHistory() {
	const theme = useTheme();
	const exportHistoryData = [
		{
			id: 1,
			exportPeriod: "01 Jan 2024, 05:30 AM",
			recordsExported: [
				{
					id: 1,
					name: "Activities",
				},
			],
			exportedRequestedOn: "5 Minutes Ago",
			exportedBy: "James Vijay",
			status: "Completed",
		},
		{
			id: 2,
			exportPeriod: "01 Jan 2024, 05:30 AM",
			recordsExported: [
				{
					id: 1,
					name: "Contacts",
				},
				{
					id: 2,
					name: "Companies",
				},
			],
			exportedRequestedOn: "5 Minutes Ago",
			exportedBy: "James Vijay",
			status: "Completed",
		},
	];

	return (
		<React.Fragment>
			<Box
				py={2}
				px={1}
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					width: "100%",
				}}
			>
				<Box
					style={{
						flex: "1 1 auto",
						overflowY: "auto",
						border: "1px solid rgb(0, 0, 0, 0.1)",
						borderRadius: "8px",
					}}
					m={1}
				>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow sx={{ height: "36px" }}>
									<TableCell style={{ width: "20%" }}>
										<Stack
											direction="row"
											alignItems="center"
											spacing={0.5}
										>
											<TableHeaderLabel>
												Export Period
											</TableHeaderLabel>
											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell style={{ width: "20%" }}>
										<Stack
											direction="row"
											alignItems="center"
											spacing={0.5}
										>
											<TableHeaderLabel>
												Records Exported
											</TableHeaderLabel>
											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell style={{ width: "20%" }}>
										<Stack
											direction="row"
											alignItems="center"
											spacing={0.5}
										>
											<TableHeaderLabel>
												Export Requested On
											</TableHeaderLabel>
											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell style={{ width: "20%" }}>
										<Stack
											direction="row"
											alignItems="center"
											spacing={0.5}
										>
											<TableHeaderLabel>
												Exported By
											</TableHeaderLabel>
											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell style={{ width: "20%" }}>
										<Stack
											direction="row"
											alignItems="center"
											spacing={0.5}
										>
											<TableHeaderLabel>
												Status
											</TableHeaderLabel>
											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{exportHistoryData.map((history) => (
									<TableRow key={history.id}>
										<TableCell style={{ width: "20%" }}>
											<TableCellText>
												{history.exportPeriod}
											</TableCellText>
										</TableCell>

										<TableCell style={{ width: "20%" }}>
											<Stack direction="row" spacing={1}>
												{history.recordsExported.map(
													(record) => (
														<TableCellText
															key={record.id}
															px={1}
															py={0.3}
															style={{
																fontSize:
																	"14px",
																borderRadius:
																	"8px",
																backgroundColor:
																	theme
																		.palette
																		.secondary
																		.main,
															}}
														>
															{record.name}
														</TableCellText>
													)
												)}
											</Stack>
										</TableCell>

										<TableCell style={{ width: "20%" }}>
											<TableCellText>
												{history.exportedRequestedOn}
											</TableCellText>
										</TableCell>

										<TableCell style={{ width: "20%" }}>
											<TableCellText>
												{history.exportedBy}
											</TableCellText>
										</TableCell>

										<TableCell style={{ width: "20%" }}>
											<TableCellText
												color={
													theme.palette.secondary
														.contrastText
												}
											>
												{history.status}
											</TableCellText>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useBulkAssignFollowers } from "../../../hooks/services/summary/followers";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import FollowersInput from "./FollowersInput";
import Dialog from "../Dialog";

export default function AssignFollowersDialog(props) {
	const { open, onClose, entityIds, moduleName, onFollowersAssigned } = props;
	const theme = useTheme();

	const [selectedFollowers, setSelectedFollowers] = useState([]);

	const bulkAssignFollowersMutation = useBulkAssignFollowers();
	const assignButtonDisabled =
		bulkAssignFollowersMutation.isLoading ||
		selectedFollowers?.length === 0;
	const title = "Assign Followers";

	const handleSelectFollowers = (value) => {
		setSelectedFollowers(value);
	};

	const closeFollowersDialog = () => {
		onClose();
		setSelectedFollowers([]);
	};

	const handleAssignFollowers = () => {
		let bulkAssignRequestData = {
			entityIds: entityIds,
			followerIds: selectedFollowers?.map(
				(followerData) => followerData?.value
			),
			sourceName: moduleName,
		};
		bulkAssignFollowersMutation.mutate(bulkAssignRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.bulkAssignFollowers,
				});
				onFollowersAssigned();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<Dialog open={open} title={title}>
				<Box p={2}>
					<Stack spacing={1}>
						<Typography fontSize={13} pl={0.6}>
							Assign to
						</Typography>

						<FollowersInput
							value={selectedFollowers}
							onChange={handleSelectFollowers}
						/>

						<Box p={1} pt={2}>
							<Stack
								direction="row"
								justifyContent="flex-end"
								spacing={2}
							>
								<Button
									onClick={closeFollowersDialog}
									variant="contained"
									color="secondary"
									disableElevation
									disabled={
										bulkAssignFollowersMutation.isLoading
									}
								>
									Cancel
								</Button>

								<Button
									variant="contained"
									disableElevation
									onClick={handleAssignFollowers}
									disabled={assignButtonDisabled}
									sx={{
										"&.Mui-disabled": {
											backgroundColor: `${theme.palette.primary.main}`,
											color: "rgba(255,255,255, 0.6)",
										},
									}}
								>
									{`Assign (${selectedFollowers?.length})`}
								</Button>
							</Stack>
						</Box>
					</Stack>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}

import React, { useEffect, useMemo, useState } from "react";
import Field from "../../Field";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useDashboardBuilderContext } from "../../../Context";
import {
	useGetMetrics,
	useMetricsFilter,
	useMetricsFilterField,
} from "../../../../../../../hooks/services/analytics";
import { default as AddIcon } from "../../../../../../../assets/icons/add";
import { useWatch } from "react-hook-form";
import Dialog from "../../../../../../Elements/Dialog";
import AddFilter from "./AddFilter";
import { TextButton } from "../../../../../../../styles/twozo";

const hideBorderSx = {
	".MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"& .MuiSelect-select": {
		paddingLeft: 2,
	},
	height: "32px",
	width: "100%",
};

const FieldName = ({ children }) => (
	<Typography
		fontSize="14px"
		fontWeight={500}
		color="rgba(0,0,0,0.6)"
		px={2}
		pt={1}
	>
		{children}
	</Typography>
);

export default function MetricsYAxis(props) {
	const theme = useTheme();
	const { control, setValue, index, getValues } = props;
	const { selectedChartProperty } = useDashboardBuilderContext();
	const [moduleOptions, setModuleOptions] = useState([]);
	const { data: metricsData } = useGetMetrics(
		selectedChartProperty.viewTypeId
	);
	const filterMutation = useMetricsFilter();

	const [measureId, moduleData, moduleId, filters, periodicFilters] =
		useWatch({
			control,
			name: [
				`viewProperties.metrics.${index}.measureId`,
				`viewProperties.metrics.${index}.moduleData`,
				`viewProperties.metrics.${index}.moduleId`,
				`viewProperties.metrics.${index}.filters`,
				`viewProperties.metrics.${index}.periodicFilters`,
			],
		});

	const { data: filterData, isLoading: isFilterDataLoading } =
		useMetricsFilterField(moduleId);

	const [isFilterMenuOpened, setIsFilterMenuOpened] = useState(false);

	const handleOpenFilterMenu = () => {
		if (!moduleId) {
			return;
		}
		setIsFilterMenuOpened(true);
	};

	const handleCloseFilterMenu = () => {
		setIsFilterMenuOpened(false);
	};

	const measureOptions = useMemo(() => {
		if (metricsData && metricsData.metrics) {
			return metricsData.metrics.map((metric) => ({
				id: metric.measureId,
				label: metric.measureName,
			}));
		}
		return [];
	}, [metricsData]);

	useEffect(() => {
		if (measureId && metricsData) {
			const metricData = metricsData?.metrics?.find(
				(metric) => metric.measureId === measureId
			);
			setModuleOptions(
				metricData?.modules?.map((module) => ({
					id: module.moduleId,
					label: module.moduleName,
					options:
						module.fields?.map((field) => ({
							id: JSON.stringify({
								moduleId: module.moduleId,
								fieldId: field.fieldId,
							}),
							label: field.fieldName,
						})) || [],
				})) || []
			);
		}
	}, [measureId, metricsData]);

	useEffect(() => {
		if (moduleData) {
			const { moduleId, fieldId } = JSON.parse(moduleData);
			setValue(`viewProperties.metrics.${index}.moduleId`, moduleId);
			setValue(`viewProperties.metrics.${index}.fieldId`, fieldId);
		}
	}, [setValue, index, moduleData]);

	const handleRemoveFilter = (index) => {
		const values = getValues(`viewProperties.metrics.${index}`);
		if (values) {
			const updatedMetrics = [...values];

			if (updatedMetrics[index]) {
				delete updatedMetrics[index].filters;
				delete updatedMetrics[index].periodicFilters;
			}
			setValue("viewProperties.metrics", updatedMetrics);
		}
	};

	const handleChangeModule = (currentModuleData) => {
		const { moduleId: currentModuleId } = JSON.parse(currentModuleData);

		if (currentModuleId !== moduleId) {
			handleRemoveFilter();
			filterMutation.mutate(currentModuleId, {
				onSuccess: (data) => {
					if (data?.defaultPeriodicFilterValue) {
						setValue(
							`viewProperties.metrics.${index}.periodicFilters`,
							data?.defaultPeriodicFilterValue
						);
					}
				},
			});
		}
	};

	const addedFilterCount = useMemo(() => {
		const hasValidFirstFilter = !!filters?.[0]?.fieldId;
		const hasPeriodicFilter = !!periodicFilters?.fieldId;

		if (!hasValidFirstFilter && !hasPeriodicFilter) {
			return "Add Filter";
		}

		const filterCount =
			(hasValidFirstFilter ? filters.length : 0) +
			(hasPeriodicFilter ? 1 : 0);
		return `${filterCount} Filters`;
	}, [filters, periodicFilters]);

	return (
		<React.Fragment>
			<Dialog
				open={isFilterMenuOpened}
				title="Add Filter"
				maxWidth={"lg"}
				minHeight="400px"
				sx={{
					"& .MuiPaper-root": {
						width: "860px",
						top: 0,
						bottom: 0,
						height: "fit-content",
					},
				}}
			>
				<Box>
					<AddFilter
						index={index}
						control={control}
						filterData={filterData}
						setValue={setValue}
						getValues={getValues}
						isFilterDataLoading={isFilterDataLoading}
						onClose={handleCloseFilterMenu}
					/>
				</Box>
			</Dialog>

			<Box
				style={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
				}}
			>
				<Box>
					<FieldName>Measure</FieldName>

					<Field
						fieldType="DROPDOWN"
						name={`viewProperties.metrics.${index}.measureId`}
						control={control}
						options={measureOptions}
						variant="standard"
						sx={{
							"&:before": {
								borderBottom: !measureId
									? "none"
									: "1px solid rgba(0, 0, 0, 0.1)",
							},
							"&:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):hover::before":
								{
									borderColor: "rgba(0, 0, 0, 0.4)",
									borderBottomWidth: "1px",
									borderRadius: "8px",
								},
							":after": { borderBottomWidth: "1px" },
							"& .MuiSelect-select": {
								paddingLeft: 2,
							},
							height: "32px",
							width: "100%",
						}}
						rules={{
							required: {
								value: true,
								message: "Measure is required",
							},
						}}
					/>
				</Box>

				{measureId && (
					<Box>
						<FieldName>Module</FieldName>

						<Field
							fieldType="DROPDOWN"
							name={`viewProperties.metrics.${index}.moduleData`}
							control={control}
							options={moduleOptions}
							sx={hideBorderSx}
							handleChangeModule={handleChangeModule}
							rules={{
								required: {
									value: true,
									message: "Module is required",
								},
							}}
							grouping
						/>

						<Divider />

						<FieldName>Metric name</FieldName>

						<Field
							name={`viewProperties.metrics.${index}.label`}
							control={control}
							fieldType="SINGLE_STRING"
							placeholder="Eg: Products"
							sx={{
								"& .MuiOutlinedInput-root": hideBorderSx,
							}}
							rules={{
								required: {
									value: true,
									message: "Metric name is required",
								},
							}}
						/>

						<Divider />

						<TextButton
							startIcon={AddIcon(
								18,
								18,
								theme.palette.primary.main
							)}
							onClick={handleOpenFilterMenu}
							disableElevation
							disableFocusRipple
							disableRipple
							style={{
								opacity: moduleId ? 1 : 0.6,
								padding: "12px 16px",
							}}
						>
							{addedFilterCount}
						</TextButton>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}

import { Box, Stack, Typography } from "@mui/material";
import FilterList from "./FilterList";
import React from "react";

export default function Filter({
	filterFields,
	comparators,
	addFilter,
	deleteFilter,
	updateFilter,
	filterList,
}) {
	return (
		<React.Fragment>
			<Stack spacing={1}>
				<Typography
					style={{
						fontSize: 14,
						fontWeight: 400,
						opacity: 0.6,
					}}
				>
					Filter
				</Typography>

				<Box>
					<FilterList
						filterList={filterList}
						fields={filterFields}
						addFilter={addFilter}
						deleteFilter={deleteFilter}
						comparators={comparators}
						updateFilter={updateFilter}
					/>
				</Box>
			</Stack>
		</React.Fragment>
	);
}

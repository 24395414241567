import React from "react";
import FieldProvider from "./FieldContext/FieldProvider";
import DropdownField from "./DropdownField";
import DefaultField from "./DefaultField";
import MultiSelectField from "./MultiSelectField";

const fieldTypes = {
	DROPDOWN: "DROPDOWN",
	MULTI_SELECT: "MULTI_SELECT",
};

export default function Field(props) {
	const {
		field,
		editingFieldRef,
		moduleName,
		clearCreatedField,
		handleOpenDeleteDialog,
		fieldTypeOption,
		changeFieldType,
		isDependableField,
		shouldShowFieldActions,
	} = props;

	return (
		<React.Fragment>
			<FieldProvider
				field={field}
				editingFieldRef={editingFieldRef}
				isDependableField={isDependableField}
				shouldShowFieldActions={shouldShowFieldActions}
			>
				{(function () {
					switch (field.type) {
						case fieldTypes.DROPDOWN:
							return (
								<DropdownField
									field={field}
									changeFieldType={changeFieldType}
									moduleName={moduleName}
									clearCreatedField={clearCreatedField}
									dragHandleProps={props.dragHandleProps}
									handleOpenDeleteDialog={
										handleOpenDeleteDialog
									}
									fieldTypeOption={fieldTypeOption}
								/>
							);
						case fieldTypes.MULTI_SELECT:
							return (
								<MultiSelectField
									field={field}
									changeFieldType={changeFieldType}
									moduleName={moduleName}
									clearCreatedField={clearCreatedField}
									dragHandleProps={props.dragHandleProps}
									handleOpenDeleteDialog={
										handleOpenDeleteDialog
									}
									fieldTypeOption={fieldTypeOption}
								/>
							);
						default:
							return (
								<DefaultField
									field={field}
									changeFieldType={changeFieldType}
									moduleName={moduleName}
									clearCreatedField={clearCreatedField}
									dragHandleProps={props.dragHandleProps}
									handleOpenDeleteDialog={
										handleOpenDeleteDialog
									}
									fieldTypeOption={fieldTypeOption}
								/>
							);
					}
				})()}
			</FieldProvider>
		</React.Fragment>
	);
}

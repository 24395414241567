import React from "react";
import {
	Box,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { TableCellText, TableHeaderLabel } from "../../../../.././styles/twozo";
import { styles } from "../../Styles";

export default function MappedFileList(props) {
	const { headers, rows, uploadedFileName } = props;

	return (
		<React.Fragment>
			<Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					p={2}
				>
					<Typography style={styles.heading}>
						{uploadedFileName}
					</Typography>

					<Typography style={styles.subText}>
						Columns : {headers.length}
					</Typography>
				</Stack>
			</Box>

			<Box>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small">
						<TableHead>
							<TableRow sx={{ height: "36px" }}>
								{headers.map((headerData, headerIndex) => (
									<TableCell
										key={headerIndex}
										style={{ minWidth: "250px" }}
									>
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel noWrap>
												{headerData}
											</TableHeaderLabel>
										</Stack>
									</TableCell>
								))}
							</TableRow>
						</TableHead>

						<TableBody>
							{rows.map((row, index) => (
								<TableRow key={index}>
									{row.map((rowData, rowIndex) => (
										<TableCell key={rowIndex}>
											<TableCellText
												sx={{ whiteSpace: "nowrap" }}
											>
												{rowData ? rowData : "-"}
											</TableCellText>
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</React.Fragment>
	);
}

import { Controller, useFormContext } from "react-hook-form";
import DealAutocomplete from "../../../../../../../Elements/DealAutoCompleteComponent";
import { useState } from "react";

export default function DealFieldComponent(params) {
	const { field } = params;

	const { control } = useFormContext();
	const [selectedDeal, setSelectedDeal] = useState({});

	const handleDealChange = (value, onChange) => {
		setSelectedDeal(value);
		onChange(value?.value);
	};

	const onCancelClicked = (onChange) => {
		onChange("");
		setSelectedDeal({});
	};

	return (
		<>
			<Controller
				name={field.id + ""}
				control={control}
				render={({ field: { onChange } }) => (
					<DealAutocomplete
						value={selectedDeal}
						isIconNeeded={false}
						onCancel={() => onCancelClicked(onChange)}
						onChange={(value) => handleDealChange(value, onChange)}
					/>
				)}
			/>
		</>
	);
}

import React, { useState } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ActivitiesList from "./ActivitiesList";
import AddActivity from "./AddActivity";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { twozoStyles } from "../../../styles/twozo";

export default function Activities(props) {
	const {
		toDoActivitiesKey,
		completedActivitiesKey,
		isLoadingTodoActivities,
		summaryKey,
		todoActivities,
		isLoadingCompletedActivities,
		completedActivities,
		todoActivitiesLastRowRef,
		completedActivitiesLastRowRef,
		isFetchingMoreTodoActivitiesData,
		isFetchingMoreCompletedActivitiesData,
	} = props;

	const classes = twozoStyles();

	const [isAddActivityDialogOpened, setIsAddActivityDialogOpened] =
		useState(false);
	const [activityFormData, setActivityFormData] = useState({});

	const dealData = activityFormData?.deal
		? {
				name: activityFormData.deal?.title,
				value: activityFormData.deal?.id,
			}
		: null;

	const openAddActivityDialog = () => {
		setIsAddActivityDialogOpened(true);
	};

	const closeAddActivityDialog = () => {
		setIsAddActivityDialogOpened(false);
		setActivityFormData({});
	};

	const handleNewActivity = (activityData) => {
		setActivityFormData(activityData);
		openAddActivityDialog();
	};

	return (
		<React.Fragment>
			{isLoadingCompletedActivities || isLoadingTodoActivities ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="50vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<>
					{/* AddActivity Drawer */}
					<CustomSwipeableDrawer
						anchor="right"
						PaperProps={{
							elevation: 0,
							style: { backgroundColor: "transparent" },
						}}
						open={isAddActivityDialogOpened}
						onOpen={openAddActivityDialog}
						onClose={closeAddActivityDialog}
						disableBackdropClick={true}
						disableSwipeToOpen
					>
						<Box className={classes.addDialogContainer}>
							<AddActivity
								onClose={closeAddActivityDialog}
								companyValue={
									activityFormData?.company
										? {
												name: activityFormData?.company
													?.name,
												value: activityFormData?.company
													?.id,
											}
										: null
								}
								dealData={dealData}
								contactIds={
									activityFormData?.contact
										? [activityFormData?.contact?.id]
										: null
								}
							/>
						</Box>
					</CustomSwipeableDrawer>

					<Box>
						<Typography
							fontWeight={600}
							px={3}
							pt={1}
							fontSize={17}
						>
							Activities To do
						</Typography>

						{todoActivities?.length > 0 ? (
							<Box>
								<ActivitiesList
									activities={todoActivities}
									toDoActivitiesKey={toDoActivitiesKey}
									summaryKey={summaryKey}
									completedActivitiesKey={
										completedActivitiesKey
									}
									latRowRef={todoActivitiesLastRowRef}
									isFetchingMoreData={
										isFetchingMoreTodoActivitiesData
									}
									handleNewActivity={handleNewActivity}
								/>
							</Box>
						) : (
							<Stack
								direction="row"
								justifyContent="center"
								alignItems="center"
							>
								<Typography fontSize={14}>
									You have no upcoming activities.
								</Typography>
							</Stack>
						)}
					</Box>

					<Box>
						{completedActivities?.length > 0 ? (
							<Box pt={1}>
								<Typography
									fontWeight={600}
									px={3}
									py={1}
									fontSize={17}
								>
									Completed Activities
								</Typography>

								<ActivitiesList
									activities={completedActivities}
									toDoActivitiesKey={toDoActivitiesKey}
									summaryKey={summaryKey}
									completedActivitiesKey={
										completedActivitiesKey
									}
									latRowRef={completedActivitiesLastRowRef}
									isFetchingMoreData={
										isFetchingMoreCompletedActivitiesData
									}
								/>
							</Box>
						) : null}
					</Box>
				</>
			)}
		</React.Fragment>
	);
}

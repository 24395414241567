import React, { useState } from "react";
import {
	Box,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import Menu from "../../Elements/Menu";
import CompanyProfileImage from "../../../assets/images/company/companyProfile.png";
import ContactProfileImage from "../../../assets/images/contact/unknownContact.png";
import { default as TimeIcon } from "../../../assets/icons/time";
import { default as CallIcon } from "../../../assets/icons/call";
import { default as MailIcon } from "../../../assets/icons/mail";
import { default as EditIcon } from "../../../assets/icons/edit";
import { default as CautionIcon } from "../../../assets/icons/caution";
import { default as ToDoListIcon } from "../../../assets/icons/todoList";
import { default as InfoIcon } from "../../../assets/icons/info";
import { default as AddIcon } from "../../../assets/icons/add";
import {
	getDateAndMonthFormat,
	getDateDifference,
	getFormattedDateMonthYearAndTime,
} from "../../../utils/DateUtils";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { DateCalendar } from "@mui/x-date-pickers";
import {
	useUpcomingActivitiesToDeal,
	useUpdateExpectedCloseDateMutation,
} from "../../../hooks/services/deal";
import { getIconByName } from "../../../utils";
import EmailComposerDrawer from "../../Mail/EmailComposer/EmailComposerDrawer";
import { modules } from "../../../utils/common/ModulesName";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import EditDeal from "../EditDeal";
import { MenuItemText, Tooltip, twozoStyles } from "../../../styles/twozo";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";
import AddActivity from "../../Summary/Activities/AddActivity";
import EditActivity from "../../Summary/Activities/EditActivity";
import { useNavigate } from "react-router-dom";
import { useModuleName } from "../../../hooks/modules";

const styles = {
	imageContainer: {
		cursor: "pointer",
	},
	dealName: {
		fontSize: 15,
		fontWeight: 500,
	},
	text: {
		fontSize: 14,
	},
	subText: {
		fontSize: 14,
		color: "rgba(0, 0, 0, 0.6)",
	},
};

const dealActivityStatus = {
	scheduled: "scheduled",
	upcomingActivities: "upcomingActivities",
	overdue: "overdue",
	noActivities: "noActivities",
};

export default function DealCard(props) {
	const { deal, pipelineStageId } = props;
	const updateExpectedCloseDateMutation = useUpdateExpectedCloseDateMutation(
		deal?.id,
		pipelineStageId
	);

	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();
	const companyData = deal?.company
		? { name: deal.company?.name, value: deal.company?.id }
		: "";

	const contactData = {
		name: deal?.partialData?.name,
		value: deal?.partialData?.id,
	};

	const [cardHovered, setCardHovered] = useState(null);
	const [selectedDealId, setSelectedDealId] = useState(null);

	const [openAddActivityDialog, setOpenAddActivityDialog] = useState(false);
	const [isEditActivityDialogOpened, setIsEditActivityDialogOpened] =
		useState(false);
	const [selectedActivityId, setSelectedActivityId] = useState({});

	const [contactCompanyElement, setContactCompanyElement] = useState(null);
	const isContactCompanyMenuOpened = Boolean(contactCompanyElement);

	const [activityMenuElement, setActivityMenuElement] = useState(null);
	const openActivityMenu = Boolean(activityMenuElement);

	const [datePickerMenuElement, setDatePickerMenuElement] = useState(null);
	const isDatePickerMenuOpened = Boolean(datePickerMenuElement);

	const [isEmailComposerOpened, setIsEmailComposerOpened] = useState(false);
	const [isEditDialogOpened, setIsEditDialogOpened] = useState(false);

	const { getModuleName } = useModuleName();
	const contactModuleName = getModuleName(modules.CONTACT);
	const companyModuleName = getModuleName(modules.COMPANY);

	const onMouseOverCard = (event) => setCardHovered(event.currentTarget);
	const onMouseOutCard = () => setCardHovered(null);

	const { data: upcomingActivities, isLoading: isUpcomingActivitiesLoading } =
		useUpcomingActivitiesToDeal(selectedDealId);

	const OpenActivityMenu = (event) => {
		event.stopPropagation();
		setSelectedDealId(deal?.id);
		setActivityMenuElement(event.currentTarget);
	};

	const CloseActivityMenu = () => {
		setActivityMenuElement(null);
	};

	const openContactCompanyMenu = (event) => {
		event.stopPropagation();
		setContactCompanyElement(event.currentTarget);
	};

	const closeContactCompanyMenu = () => {
		setContactCompanyElement(null);
	};

	const openDatePickerMenu = (event) => {
		event.stopPropagation();
		setDatePickerMenuElement(event.currentTarget);
	};

	const closeDatePickerMenu = () => {
		setDatePickerMenuElement(null);
	};

	const getExpectedCloseDate = (closeDateUnixTimestamp) => {
		let formattedDate = getDateAndMonthFormat(closeDateUnixTimestamp);
		const currentDate = new Date().valueOf();

		return `${formattedDate} ${getDateDifference(
			closeDateUnixTimestamp,
			true
		)} ${currentDate > closeDateUnixTimestamp ? "overdue" : ""}`;
	};

	const updateExpectedClosedDate = (date) => {
		let requestData = getExpectedClosedDateRequest(date.valueOf());
		updateExpectedCloseDateMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.expectedCloseDateUpdated,
				});
				setDatePickerMenuElement(null);
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	const getExpectedClosedDateRequest = (expectedCloseDate) => {
		return {
			dealId: deal.id,
			expectedCloseDate: expectedCloseDate,
		};
	};

	const openEmailComposer = (event) => {
		event.stopPropagation();
		setIsEmailComposerOpened(true);
	};

	const closeEmailComposer = () => {
		setIsEmailComposerOpened(false);
	};

	const openEditDialog = (event) => {
		event.stopPropagation();
		setIsEditDialogOpened(true);
	};

	const closeEditDialog = () => {
		setIsEditDialogOpened(false);
	};
	const navigateToContactDetails = (contactId) => {
		navigate(`/contacts/${contactId.toString()}`);
	};
	const navigateToCompanyDetails = (companyId) => {
		navigate(`/companies/${companyId.toString()}`);
	};
	const getPrimaryContactId = () => {
		let primaryContact = deal?.relatedContacts?.find(
			(contact) => contact?.isPrimary
		);
		if (primaryContact) {
			return primaryContact?.id;
		}
	};

	const renderActivityStatusIcon = () => {
		switch (deal?.icon) {
			case dealActivityStatus.noActivities:
				return CautionIcon(20, 20);
			case dealActivityStatus.scheduled:
				return ToDoListIcon(20, 20);
			case dealActivityStatus.upcomingActivities:
				return ToDoListIcon(20, 20, "#000", 0.2);
			case dealActivityStatus.overdue:
				return InfoIcon(20, 20, theme.palette.error.main);
			default:
				return null;
		}
	};

	const renderMultipleContact = (contacts) => {
		const maximumContactLimit = 4;
		const isContactLimitExceeded = contacts.length > maximumContactLimit;
		const supplementaryContacts = contacts.slice(0, maximumContactLimit);

		return (
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={0.5}
				onClick={openContactCompanyMenu}
			>
				{supplementaryContacts?.map((contact) => (
					<Box
						key={contact.id}
						style={{
							...styles.imageContainer,
							marginRight: "-15px",
						}}
						display="flex"
					>
						<img
							src={ContactProfileImage}
							alt="profile"
							width="24px"
							height="24px"
							style={{
								border: "1px solid #ffffff",
								borderRadius: "100%",
							}}
						/>
					</Box>
				))}

				{isContactLimitExceeded ? (
					<Typography
						pl={2}
						fontSize={14}
						fontWeight={500}
						sx={{ cursor: "pointer" }}
						color={theme.palette.secondary.contrastText}
					>
						{`+${contacts.length - maximumContactLimit}`}
					</Typography>
				) : null}
			</Stack>
		);
	};

	const renderSingleContact = (contacts) => {
		return contacts?.map((contact) => (
			<Box
				key={contact.id}
				style={styles.imageContainer}
				onClick={openContactCompanyMenu}
			>
				<img
					src={ContactProfileImage}
					alt="profile"
					width="24px"
					height="24px"
					style={{
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "100%",
					}}
				/>
			</Box>
		));
	};

	const renderCompanyIcon = () => {
		return (
			<Box
				style={styles.imageContainer}
				display="flex"
				onClick={openContactCompanyMenu}
			>
				<img
					src={CompanyProfileImage}
					alt="profile"
					width="24px"
					height="24px"
					style={{
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "100%",
					}}
				/>
			</Box>
		);
	};

	const renderContacts = (contacts) => {
		let isSingleContact = contacts.length === 1;

		if (isSingleContact) {
			return renderSingleContact(contacts);
		}

		return renderMultipleContact(contacts);
	};

	const toggleAddActivityDialog = () => {
		setOpenAddActivityDialog(
			(openAddCompanyDialog) => !openAddCompanyDialog
		);
		CloseActivityMenu();
	};

	const toggleEditActivityDialog = () => {
		setIsEditActivityDialogOpened(
			(isEditActivityDialogOpened) => !isEditActivityDialogOpened
		);
	};

	const openEditActivity = (activity) => {
		setSelectedActivityId(activity?.activities?.id);
		toggleEditActivityDialog();
		CloseActivityMenu();
	};

	return (
		<React.Fragment>
			{isEmailComposerOpened ? (
				<EmailComposerDrawer
					onClose={closeEmailComposer}
					composerValues={{ toAddress: [getPrimaryContactId()] }}
					sourceName={modules.DEAL}
					entityId={deal?.id}
				/>
			) : null}

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddActivityDialog}
				onOpen={toggleAddActivityDialog}
				onClose={toggleAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={toggleAddActivityDialog}
						dealData={{ name: deal?.title, value: deal?.id }}
						companyValue={companyData}
						contacts={[contactData]}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditActivityDialogOpened}
				onOpen={toggleEditActivityDialog}
				onClose={toggleEditActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditActivity
						onClose={toggleEditActivityDialog}
						activityId={selectedActivityId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditDialogOpened}
				onOpen={openEditDialog}
				onClose={closeEditDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditDeal dealId={deal?.id} onClose={closeEditDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				anchorEl={datePickerMenuElement}
				open={isDatePickerMenuOpened}
				onClose={closeDatePickerMenu}
			>
				<DateCalendar
					onChange={(props) => updateExpectedClosedDate(props)}
				/>
			</Menu>

			<Menu
				open={isContactCompanyMenuOpened}
				anchorEl={contactCompanyElement}
				onClose={closeContactCompanyMenu}
			>
				<Stack
					minWidth="332px"
					py={0.5}
					spacing={3}
					px={1.5}
					maxWidth="400px"
					maxHeight="184px"
				>
					<Can permission={PERMISSIONS.company.view}>
						{deal?.company?.name ? (
							<Stack
								spacing={3}
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<MenuItemText
									maxWidth="176px"
									noWrap
									color="rgba(0, 0, 0, 0.6)"
									style={{ cursor: "default" }}
								>
									{companyModuleName}
								</MenuItemText>
								<MenuItemText
									noWrap
									maxWidth="176px"
									fontWeight={500}
									color={theme.palette.secondary.contrastText}
									onClick={() =>
										navigateToCompanyDetails(
											deal?.company?.id
										)
									}
									style={{ cursor: "pointer" }}
								>
									{deal?.company?.name}
								</MenuItemText>
							</Stack>
						) : null}
					</Can>

					<Can permission={PERMISSIONS.contact.view}>
						<Stack
							direction="row"
							justifyContent="space-between"
							spacing={3}
						>
							<MenuItemText
								noWrap
								maxWidth="176px"
								color="rgba(0, 0, 0, 0.6)"
								style={{ cursor: "default" }}
							>
								{contactModuleName}
							</MenuItemText>
							<Stack spacing={1}>
								{deal?.relatedContacts?.map(
									(relatedContact) => (
										<Stack
											key={relatedContact.id}
											onClick={() =>
												navigateToContactDetails(
													relatedContact?.id
												)
											}
											style={{ cursor: "pointer" }}
										>
											<MenuItemText
												noWrap
												maxWidth="176px"
												textAlign="right"
												fontWeight={500}
												color={
													theme.palette.secondary
														.contrastText
												}
											>
												{relatedContact.fullName}
											</MenuItemText>
										</Stack>
									)
								)}
							</Stack>
						</Stack>
					</Can>
				</Stack>
			</Menu>

			<Menu
				minWidth={
					upcomingActivities?.length < 1 ||
					isUpcomingActivitiesLoading
						? "200px"
						: "480px"
				}
				anchorEl={activityMenuElement}
				open={openActivityMenu}
				onClose={CloseActivityMenu}
			>
				{isUpcomingActivitiesLoading &&
				deal?.icon !== dealActivityStatus.noActivities ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="140px"
					>
						<CircularProgress size={18} />
					</Stack>
				) : (
					<Box>
						<Stack sx={{ cursor: "pointer" }}>
							{upcomingActivities?.map((activitiesData) => (
								<Box key={activitiesData?.activities.id}>
									<Tooltip
										title={
											activitiesData?.isOverDue
												? `${getDateDifference(
														activitiesData?.time,
														true,
														false
													)} ${" "} ${"Overdue"}`
												: ""
										}
										placement="right"
									>
										<Stack
											direction="row"
											alignItems="center"
											justifyContent="space-between"
											p={1}
											px={1.5}
										>
											<Stack
												direction="row"
												alignItems="center"
												spacing={2}
											>
												{getIconByName(
													activitiesData?.activities
														?.activityType?.icon
														?.name
												)(
													20,
													20,
													activitiesData?.isOverDue
														? theme.palette.error
																.main
														: theme.palette.primary
																.main
												)}

												<MenuItemText fontWeight={500}>
													<span
														style={{
															color: theme.palette
																.primary.main,
														}}
														onClick={() =>
															openEditActivity(
																activitiesData
															)
														}
													>
														{
															activitiesData
																?.activities
																?.title
														}
													</span>{" "}
													-{" "}
													{activitiesData?.ownerName}{" "}
												</MenuItemText>
											</Stack>
											<MenuItemText
												fontWeight={500}
												color={
													activitiesData?.isOverDue
														? theme.palette.error
																.main
														: "rgba(0, 0, 0, 0.6)"
												}
											>
												{getFormattedDateMonthYearAndTime(
													activitiesData.time
												)}
											</MenuItemText>
										</Stack>
									</Tooltip>
									<Divider />
								</Box>
							))}
						</Stack>

						<Stack
							sx={{ cursor: "pointer" }}
							p={1}
							pl={2}
							direction="row"
							alignItems="center"
							spacing={2}
							onClick={toggleAddActivityDialog}
						>
							{AddIcon(18, 18, theme.palette.primary.main)}
							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
							>
								Add Activity
							</Typography>
						</Stack>
					</Box>
				)}
			</Menu>

			<Box
				onMouseOver={onMouseOverCard}
				onMouseOut={() => onMouseOutCard()}
				sx={{
					backgroundColor: deal?.isRotten ? "#F3E5E5" : "#fff",
					borderRadius: "8px",
					p: 2,
					boxShadow: cardHovered
						? "0px 4px 6px rgba(0, 0, 0, 0.1)"
						: "none",
				}}
				onClick={props.onClick}
			>
				<Stack spacing={"6px"} width="100%">
					<Stack
						direction="row"
						alignItems="strech"
						justifyContent="space-between"
					>
						<Typography
							style={styles.dealName}
							width="70%"
							sx={{
								display: "-webkit-box",
								WebkitBoxOrient: "vertical",
								overflow: "hidden",
								WebkitLineClamp: 2,
								wordBreak: "break-all",
							}}
						>
							{deal?.title}
						</Typography>
						{cardHovered && (
							<Stack direction="row" spacing={1}>
								<IconButton sx={{ padding: "0px" }}>
									{CallIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>

								<Can
									permission={
										PERMISSIONS.email.individualEmailLimit
									}
								>
									<Can permission={PERMISSIONS.contact.view}>
										<IconButton
											sx={{ padding: "0px" }}
											onClick={openEmailComposer}
										>
											{MailIcon(
												20,
												20,
												theme.palette.primary.main
											)}
										</IconButton>
									</Can>
								</Can>

								<Can permission={PERMISSIONS.deal.edit}>
									<IconButton
										onClick={openEditDialog}
										sx={{ padding: "0px" }}
									>
										{EditIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</IconButton>
								</Can>
							</Stack>
						)}
					</Stack>

					<Stack direction="row" spacing={1}>
						<Typography style={styles.text}>
							{deal?.dealValue?.symbol}
							{deal?.dealValue?.value % 1 === 0
								? deal?.dealValue?.value
								: deal?.dealValue?.value}
						</Typography>
						<Can permission={PERMISSIONS.deal.edit}>
							<Typography style={styles.text}>&#8226;</Typography>
						</Can>

						<Stack
							direction="row"
							alignItems="center"
							spacing={0.5}
						>
							{deal?.expectedCloseDate ? (
								<>
									{TimeIcon(16, 16, "#000", 0.6)}
									<Typography
										style={{
											...styles.subText,
											color: deal.rotten
												? theme.palette.error.main
												: "rgba(0, 0, 0, 0.6)",
										}}
									>
										{getExpectedCloseDate(
											deal?.expectedCloseDate
										)}
									</Typography>
								</>
							) : (
								<Can permission={PERMISSIONS.deal.edit}>
									<Typography
										fontSize={14}
										fontWeight={500}
										pl={0.5}
										style={{ cursor: "pointer" }}
										color={theme.palette.primary.main}
										onClick={openDatePickerMenu}
									>
										Set expected close date
									</Typography>
								</Can>
							)}
						</Stack>
					</Stack>

					<Stack
						direction="row"
						spacing={2}
						alignItems="center"
						justifyContent="space-between"
						width="100%"
					>
						<Stack direction="row" spacing={1} pt={"6px"}>
							<Can permission={PERMISSIONS.company.view}>
								{deal?.company ? renderCompanyIcon() : null}
							</Can>

							<Can permission={PERMISSIONS.contact.view}>
								{deal?.relatedContacts?.length > 0 &&
									renderContacts(deal?.relatedContacts)}
							</Can>
						</Stack>

						<Box display="flex" onClick={OpenActivityMenu}>
							{renderActivityStatusIcon()}
						</Box>
					</Stack>
				</Stack>
			</Box>
		</React.Fragment>
	);
}

import React, { useCallback, useEffect, useState } from "react";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import MenuSearchBar from "../../../../../MenuSearchBar";
import { default as CloseIcon } from "../../../../../../../assets/icons/close";
import { default as DropdownIcon } from "../../../../../../../assets/icons/dropDownCentered";
import { useTerritoryDropdownList } from "../../../../../../../hooks/services/userManagement/territory";
import { MenuItemText } from "../../../../../../../styles/twozo";

export default function TerritoryBaseComponent({
	onChange,
	value,
	inputRef,
	clearErrors,
	field,
	errors,
}) {
	const [selectedTerritory, setSelectedTerritory] = useState({
		name: "",
		value: "",
	});
	const [searchValue, setSearchValue] = useState("");

	const { data: territoryList } = useTerritoryDropdownList();

	const hiddenSearchBar = territoryList?.length === 0;

	const updateTerritoryValue = useCallback(
		(updatedTerritoryValue) => {
			setSelectedTerritory(updatedTerritoryValue);
			onChange(updatedTerritoryValue?.value);
		},
		[onChange]
	);

	// This useEffect will run only initial render
	useEffect(() => {
		// Check if the server value needs to be formatted
		const isNotServerSideFormatted = value && typeof value === "object";
		if (isNotServerSideFormatted) {
			updateTerritoryValue(value);
		}
	}, [value, updateTerritoryValue]);

	const handleTerritoryInputChange = (event, child) => {
		const { value } = event.target;
		updateTerritoryValue({ name: child.props.name, value: value });
		clearErrors(field.id);
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const getFilteredTerritoryList = () => {
		if (searchValue.trim()) {
			return territoryList?.filter((territory) =>
				//filter for search
				territory.name
					.toLowerCase()
					.startsWith(searchValue.toLowerCase())
			);
		}
		return territoryList;
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{territoryList?.length === 0
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	const handleRemove = () => {
		setSelectedTerritory({ name: "", value: "" });
		onChange("");
	};

	return (
		<React.Fragment>
			<Select
				size="small"
				displayEmpty
				value={selectedTerritory.value}
				inputRef={inputRef}
				onChange={handleTerritoryInputChange}
				MenuProps={{
					autoFocus: false,
					PaperProps: {
						style: {
							maxHeight: 300,
							marginTop: "4px",
							borderRadius: "8px",
						},
					},
				}}
				sx={{ width: "100%" }}
				renderValue={() => (
					<Typography
						fontSize={14}
						color={
							selectedTerritory.name
								? "#000"
								: "rgba(0, 0, 0, 0.6)"
						}
					>
						{selectedTerritory.name
							? selectedTerritory.name
							: field?.placeHolder || "Select"}
					</Typography>
				)}
				IconComponent={(props) => (
					<Box {...props}>{DropdownIcon(14, 14)}</Box>
				)}
				onOpen={() => setSearchValue("")}
				error={!!errors[field.id]}
			>
				<Box
					p={0.5}
					marginTop="-8px"
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "#fff",
					}}
					hidden={hiddenSearchBar}
				>
					<MenuSearchBar
						value={searchValue}
						onChange={handleSearchValue}
					/>
				</Box>

				{getFilteredTerritoryList()?.length > 0
					? getFilteredTerritoryList().map((territory) => (
							<MenuItem
								value={territory.value}
								key={territory.value}
								style={{ height: "40px" }}
								name={territory?.name || ""}
							>
								<Stack
									direction="row"
									spacing={1}
									alignItems="center"
									justifyContent="space-between"
									width="100%"
								>
									<MenuItemText>
										{territory.name}
									</MenuItemText>

									{selectedTerritory.value ===
									territory?.value ? (
										<Box
											display="flex"
											onClick={handleRemove}
										>
											{CloseIcon(20, 20, "#000", 0.6)}
										</Box>
									) : null}
								</Stack>
							</MenuItem>
						))
					: renderEmptyView()}
			</Select>
		</React.Fragment>
	);
}

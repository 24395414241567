import {
	Box,
	Button,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropdownIcon } from "../../../../../assets/icons/dropDownCentered";
import { MenuItemText, TextButton } from "../../../../../styles/twozo";
import { useEffect, useMemo, useState } from "react";
import Menu from "../../../../Elements/Menu";
import {
	useContactLifecycleStageList,
	useUpdateContactLifecycleStatus,
} from "../../../../../hooks/services/contact";
import { useLifeCycleStatusList } from "../../../../../hooks/services/lifecycleStage";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import Dialog from "../../../../Elements/Dialog";
import ClosedLost from "../ClosedLost";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";

const state = {
	LOST: 1,
	WON: 2,
};

export default function LifecycleStageView({ contactId }) {
	const theme = useTheme();

	const [selectedStage, setSelectedStage] = useState({
		name: "",
		value: "",
	});
	const [selectedStatus, setSelectedStatus] = useState({
		name: "",
		value: "",
	});

	// query call:-
	const { data: lifecycleStageList } =
		useContactLifecycleStageList(contactId);
	const { data: lifecycleStatusList } = useLifeCycleStatusList(
		selectedStage?.value
	);

	// mutation call:-
	const updateMutation = useUpdateContactLifecycleStatus(contactId);

	const [stageMenuElement, setStageMenuElement] = useState(null);
	const isStatusMenuOpened = Boolean(stageMenuElement);
	const [isLostDialogOpened, setIsLostDialogOpened] = useState(false);

	const openLostDialog = () => {
		setIsLostDialogOpened(true);
	};

	const closeLostDialog = () => {
		setIsLostDialogOpened(false);
		closeStageMenu();
	};

	const defaultStage = useMemo(() => {
		return lifecycleStageList?.find((stage) => stage?.isAssociated);
	}, [lifecycleStageList]);

	useEffect(() => {
		if (
			Array.isArray(lifecycleStageList) &&
			lifecycleStageList.length > 0
		) {
			setSelectedStage(defaultStage);
		}
	}, [defaultStage, lifecycleStageList]);

	const defaultStatus = useMemo(() => {
		return lifecycleStatusList?.find((status) => status?.isDefault);
	}, [lifecycleStatusList]);

	useEffect(() => {
		if (
			Array.isArray(lifecycleStatusList) &&
			lifecycleStatusList.length > 0
		) {
			setSelectedStatus(defaultStatus);
		}
	}, [defaultStatus, lifecycleStatusList]);

	const openStageMenu = (event) => {
		setStageMenuElement(event.currentTarget);
	};

	const closeStageMenu = () => {
		setStageMenuElement(null);
		setSelectedStage(defaultStage);
		setSelectedStatus(defaultStatus);
	};

	const handleChangeStage = (event, child) => {
		setSelectedStage({
			name: child.props.name,
			value: event.target.value,
		});
	};

	const handleChangeStatus = (event, child) => {
		setSelectedStatus({
			name: child.props.name,
			value: event.target.value,
		});

		if (child.props?.label === state.LOST) {
			openLostDialog();
		}
	};

	const handleUpdateLifecycle = () => {
		let requestData = {
			id: contactId,
			lifeCycleStageId: selectedStage?.value,
			lifeCycleStatusId: selectedStatus?.value,
		};

		updateMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.contactLifecycleUpdated,
				});
				closeStageMenu();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const onUpdateStatusSuccess = () => {
		closeLostDialog();
		closeStageMenu();
	};

	return (
		<>
			<Dialog open={isLostDialogOpened} title="Closed Lost">
				<ClosedLost
					contactId={contactId}
					statusData={selectedStatus}
					closeLostDialog={closeLostDialog}
					lifeCycleStageId={selectedStage.value}
					lifeCycleStatusId={selectedStatus.value}
					onUpdateStatusSuccess={onUpdateStatusSuccess}
				/>
			</Dialog>

			<Menu
				minWidth="400px"
				anchorEl={stageMenuElement}
				open={isStatusMenuOpened}
				onClose={closeStageMenu}
				style={{ marginTop: "18px" }}
			>
				<Box p={2}>
					<Stack spacing={0.5} pb={2}>
						<Typography fontSize={14} fontWeight={500} pl={1}>
							Lifecycle Stage
						</Typography>

						<Box maxWidth="370px">
							<Select
								fullWidth
								value={selectedStage.value}
								renderValue={() => (
									<Typography
										noWrap
										fontSize={14}
										color={
											selectedStage.value
												? "#000"
												: "rgba(0, 0, 0, 0.6)"
										}
									>
										{selectedStage.name
											? selectedStage.name
											: "Select"}
									</Typography>
								)}
								onChange={handleChangeStage}
							>
								{lifecycleStageList?.map((stage) => (
									<MenuItem
										value={stage.value}
										key={stage.value}
										name={stage.name}
									>
										<MenuItemText>
											{stage.name}
										</MenuItemText>
									</MenuItem>
								))}
							</Select>
						</Box>
					</Stack>

					<Stack spacing={0.5}>
						<Typography fontSize={14} fontWeight={500} pl={1}>
							Status
						</Typography>

						<Box maxWidth="370px">
							<Select
								fullWidth
								value={selectedStatus.value}
								renderValue={() => (
									<Typography
										noWrap
										fontSize={14}
										color={
											selectedStatus.value
												? "#000"
												: "rgba(0, 0, 0, 0.6)"
										}
									>
										{selectedStatus.name
											? selectedStatus.name
											: "Select"}
									</Typography>
								)}
								onChange={handleChangeStatus}
							>
								{lifecycleStatusList?.map((status) => (
									<MenuItem
										value={status.value}
										key={status.value}
										name={status.name}
										label={status?.label}
									>
										<MenuItemText>
											{status.name}
										</MenuItemText>
									</MenuItem>
								))}
							</Select>
						</Box>
					</Stack>
				</Box>

				<Stack
					direction="row"
					justifyContent="flex-end"
					spacing={2}
					p={3}
					pt={1}
				>
					<Button
						variant="contained"
						color="secondary"
						disableElevation
						onClick={closeStageMenu}
					>
						Cancel
					</Button>

					<Button
						variant="contained"
						disableElevation
						onClick={handleUpdateLifecycle}
						disabled={updateMutation.isLoading}
					>
						Save
					</Button>
				</Stack>
			</Menu>

			<Stack spacing={0.5} alignItems="flex-start">
				<Typography fontSize={14} fontWeight={400}>
					Lifecycle Stage
				</Typography>

				<TextButton onClick={openStageMenu}>
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<Typography
							fontSize={15}
							fontWeight={500}
							maxWidth="180px"
							noWrap
						>
							{selectedStage.name}
						</Typography>

						{DropdownIcon(16, 16, theme.palette.primary.main)}
					</Stack>
				</TextButton>
			</Stack>
		</>
	);
}

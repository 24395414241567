import React, { useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	Chip,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { FormFieldName, MenuItemText } from "../../../../../styles/twozo";
import DateRangePicker from "../../../../Elements/DateRangePicker";
import MenuSearchBar from "../../../../Elements/MenuSearchBar";

export default function ExportData(props) {
	const { onClose } = props;
	const theme = useTheme();

	const timeRange = [
		{
			id: 1,
			value: "Last 7 Days",
		},
		{
			id: 2,
			value: "Last 30 Days ",
		},
		{
			id: 3,
			value: "Last 90 Days ",
		},
		{
			id: 4,
			value: "Untill Today",
		},
		{
			id: 5,
			value: "Custom Period",
		},
	];

	const recordsList = [
		{
			id: 1,
			name: "Contacts",
		},
		{
			id: 2,
			name: "Companies",
		},
		{
			id: 3,
			name: "Deals",
		},
		{
			id: 4,
			name: "Products",
		},
		{
			id: 5,
			name: "Activities",
		},
		{
			id: 6,
			name: "Notes",
		},
		{
			id: 7,
			name: "Call Logs",
		},
		{
			id: 8,
			name: "Email Conversations",
		},
		{
			id: 9,
			name: "Users",
		},
	];

	const [selectedTimeRange, setSelectedTimeRange] = useState(
		timeRange[0].value
	);
	const [searchValue, setSearchValue] = useState("");
	const [selectedRecordIds, setSelectedRecordIds] = useState([]);

	const handleSelectTimeRange = (event) => {
		setSelectedTimeRange(event.target.value);
	};

	const selectRecord = (event) => {
		const { value } = event.target;
		setSelectedRecordIds(value);
	};

	const unSelectRecord = (value) => {
		setSelectedRecordIds((prevRecordIds) =>
			prevRecordIds.filter((id) => id !== value)
		);
	};

	const handleFilteredField = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontSize={17} fontWeight={600}>
							Export Data
						</Typography>

						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(24, 24, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					p={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
					}}
				>
					<Grid
						container
						direction="row"
						alignItems="baseline"
						justifyContent="center"
						spacing={2}
					>
						<Grid item xs={10} md={6}>
							<Stack>
								<Stack direction="row">
									<FormFieldName>
										Select Time Range
									</FormFieldName>

									<span
										style={{
											color: theme.palette.error.main,
											marginLeft: "5px",
										}}
									>
										*
									</span>
								</Stack>

								<Select
									value={selectedTimeRange}
									onChange={handleSelectTimeRange}
								>
									{timeRange.map((range) => (
										<MenuItem
											style={{ height: "40px" }}
											key={range.id}
											value={range.value}
										>
											<MenuItemText>
												{range.value}
											</MenuItemText>
										</MenuItem>
									))}
								</Select>
							</Stack>
						</Grid>

						<Grid item xs={12} md={6}>
							<Stack>
								<Stack direction="row">
									<FormFieldName>Date Range</FormFieldName>

									<span
										style={{
											color: theme.palette.error.main,
											marginLeft: "5px",
										}}
									>
										*
									</span>
								</Stack>

								<DateRangePicker />
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Stack>
								<Stack direction="row">
									<FormFieldName>
										Choose Records To Export
									</FormFieldName>

									<span
										style={{
											color: theme.palette.error.main,
											marginLeft: "5px",
										}}
									>
										*
									</span>
								</Stack>

								<Select
									displayEmpty
									multiple
									value={selectedRecordIds}
									onChange={selectRecord}
									renderValue={
										selectedRecordIds.length > 0
											? (selectedRecord) => (
													<Box>
														{selectedRecord.map(
															(record) => (
																<Chip
																	size="small"
																	key={record}
																	label={
																		<Typography
																			fontSize={
																				14
																			}
																			color="#000"
																		>
																			{
																				recordsList.find(
																					(
																						option
																					) =>
																						option.id ===
																						record
																				)
																					.name
																			}
																		</Typography>
																	}
																	color="secondary"
																	deleteIcon={
																		<Stack
																			direction="row"
																			alignItems="center"
																			onMouseDown={(
																				event
																			) =>
																				event.stopPropagation()
																			}
																		>
																			{CloseIcon(
																				16,
																				16,
																				"grey"
																			)}
																		</Stack>
																	}
																	onDelete={() =>
																		unSelectRecord(
																			record
																		)
																	}
																	sx={{
																		borderRadius:
																			"8px",
																		marginRight:
																			"4px",
																	}}
																/>
															)
														)}
													</Box>
												)
											: () => (
													<Typography
														fontSize={15}
														color="rgba(0, 0, 0, 0.6)"
													>
														Select
													</Typography>
												)
									}
									onOpen={() => setSearchValue("")}
								>
									<Box p={0.5}>
										<MenuSearchBar
											value={searchValue}
											onChange={handleFilteredField}
										/>
									</Box>

									{recordsList
										.filter((team) =>
											//filter for search
											team.name
												.toLowerCase()
												.startsWith(
													searchValue.toLowerCase()
												)
										)
										.map((record) => (
											<MenuItem
												key={record.id}
												value={record.id}
												style={{
													height: "40px",
												}}
											>
												<Stack
													style={{
														width: "100%",
													}}
													direction="row"
													alignItems="center"
													justifyContent="space-between"
												>
													<MenuItemText>
														{record.name}
													</MenuItemText>

													<Checkbox
														checked={selectedRecordIds.includes(
															record.id
														)}
													/>
												</Stack>
											</MenuItem>
										))}
								</Select>
							</Stack>
						</Grid>
					</Grid>
				</Box>

				<Divider />

				<Box p={2}>
					<Button variant="contained" disableElevation>
						Export Data
					</Button>
				</Box>
			</Box>
		</React.Fragment>
	);
}

import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	deleteImportedFile,
	getImportFieldsList,
	getImportHistoryData,
	getImportModulesList,
	getImportStatus,
	importUploadedFile,
	importFileStatistics,
	previewUploadedFile,
	readUploadedFile,
	revertImportedFile,
	stopImportedFile,
	updateFieldsMapping,
	uploadFile,
} from "../../../api/import/importApi";
import {
	fieldTypeKey,
	getImportFieldsListKey,
	getImportDetailsKey,
	getImportPreviewKey,
	getImportReadDataKey,
	getImportStatusKey,
	importHistoryKey,
	importModulesListKey,
} from "../../../utils/queryKeys";
import {
	createFieldRequestData,
	getFieldTypeData,
} from "../../../api/fields/config/configApi";
import { useInvalidateAllModuleTables } from "../common/table";
import { importHistoryPageSize } from "../../../utils/queryConstants/import";

// upload file mutation:-
const useUploadFileData = () => {
	return useMutation(uploadFile);
};

// fields mapping query call:-
const useMappingList = (uploadedOrReadFileId) => {
	const importReadFileKey = getImportReadDataKey(uploadedOrReadFileId);

	return useQuery(
		importReadFileKey,
		() => readUploadedFile(uploadedOrReadFileId),
		{
			enabled: !!uploadedOrReadFileId,
			refetchOnWindowFocus: false,
			refetchInterval: (data) => readFileRefetchInterval(data),
		}
	);
};

const readFileRefetchInterval = (data) => {
	if (!data || data.isInProgress) {
		return 1000;
	}

	return false;
};

// preview query call:-
const usePreviewList = (previewId) => {
	const previewKey = getImportPreviewKey(previewId);
	return useQuery(previewKey, () => previewUploadedFile(previewId), {
		enabled: !!previewId,
	});
};

// import file statistics query call:-
const useImportStatusList = (importedFileId) => {
	let invalidateAllModuleTablesList = useInvalidateAllModuleTables();
	let invalidateHistoryList = useInvalidateImportHistory();
	const importStatusKey = getImportStatusKey(importedFileId);
	return useQuery(importStatusKey, () => getImportStatus(importedFileId), {
		enabled: !!importedFileId,
		refetchInterval: (data) =>
			handleStatusRefetchInterval(
				data,
				invalidateAllModuleTablesList,
				invalidateHistoryList
			),
	});
};

const handleStatusRefetchInterval = (
	data,
	invalidateAllModulesTableList,
	invalidateHistoryList
) => {
	if (!data || !data.isDone) {
		return 1000;
	} else {
		invalidateAllModulesTableList();
		invalidateHistoryList();
		return false;
	}
};

//import modules query call:-
export const useImportModuleList = () => {
	return useQuery(importModulesListKey, () => getImportModulesList(), {
		select: (data) => data.list,
	});
};

// import field list query call:-
const useImportFieldsList = (sourceType) => {
	const FieldListKey = getImportFieldsListKey(sourceType);
	return useQuery(FieldListKey, () => getImportFieldsList(sourceType));
};

// import field type query call:-
const useFieldTypeList = () => {
	return useQuery(fieldTypeKey, () => getFieldTypeData(), {
		select: (data) => data?.list,
	});
};

// invalidate field list:-
const useInvalidateFieldList = (sourceType) => {
	let queryClient = useQueryClient();
	let FieldListKey = getImportFieldsListKey(sourceType);

	return () => {
		queryClient.invalidateQueries(FieldListKey);
	};
};

// field create mutation:-
const useCreateField = (sourceType) => {
	let invalidateFieldList = useInvalidateFieldList(sourceType);
	return useMutation(
		async (createFieldData) => createFieldRequestData(createFieldData),
		{
			onSuccess: () => {
				invalidateFieldList();
			},
		}
	);
};

const historySelect = (historyList) => {
	return historyList.pages.flatMap((page) =>
		page?.list?.length > 0 ? page?.list : []
	);
};

// History query call:-
const useImportHistoryList = () => {
	return useInfiniteQuery({
		queryKey: importHistoryKey,
		queryFn: ({ pageParam }) => {
			return getImportHistoryData({
				start: pageParam
					? (pageParam - 1) * importHistoryPageSize + 1
					: 1,
				limit: importHistoryPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: historySelect,
	});
};

// imported file details query call:-
const useImportHistoryDetailsList = (importedFileId) => {
	const importDetailsKey = getImportDetailsKey(importedFileId);

	return useQuery(
		importDetailsKey,
		() => importFileStatistics(importedFileId),
		{
			select: (data) => data.list,
			enabled: !!importedFileId,
		}
	);
};

// update mapping mutation:-
const useUpdateFieldsMapping = () => {
	return useMutation(updateFieldsMapping);
};

// import the file mutation:-
const useImportUploadedFile = () => {
	let invalidateHistoryList = useInvalidateImportHistory();
	return useMutation(importUploadedFile, {
		onSuccess: () => {
			invalidateHistoryList();
		},
	});
};

// revert mutation:-
const useRevertImport = () => {
	let invalidateHistoryList = useInvalidateImportHistory();
	let invalidateTableList = useInvalidateAllModuleTables();
	return useMutation(revertImportedFile, {
		onSuccess: () => {
			invalidateHistoryList();
			invalidateTableList();
		},
	});
};

// stop mutation:-
const useStopImport = () => {
	let invalidateHistoryList = useInvalidateImportHistory();
	return useMutation(stopImportedFile, {
		onSuccess: () => {
			invalidateHistoryList();
		},
	});
};

// delete mutation:-
const useDeleteImport = () => {
	let invalidateHistoryList = useInvalidateImportHistory();
	return useMutation(deleteImportedFile, {
		onSuccess: () => {
			invalidateHistoryList();
		},
	});
};

// query invalidation call :-
const useInvalidateImportHistory = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(importHistoryKey);
	};
};

export {
	useImportHistoryList,
	useImportHistoryDetailsList,
	useUploadFileData,
	useMappingList,
	useImportFieldsList,
	useFieldTypeList,
	useUpdateFieldsMapping,
	usePreviewList,
	useCreateField,
	useImportUploadedFile,
	useImportStatusList,
	useDeleteImport,
	useRevertImport,
	useStopImport,
};

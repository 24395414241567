import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	MenuItem,
	Select,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import UserManagementDialog from "../../../../Elements/UserManagementDialog";
import { useTransferUserRecordMutation } from "../../../../../hooks/services/userManagement/user";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { default as UserProfileImage } from "../../../../../assets/images/contact/unknownContact.png";
import { useUsers } from "../../../../../hooks/account/user";
import MenuSearchBar from "../../../../Elements/MenuSearchBar";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";
import { MenuItemText } from "../../../../../styles/twozo";

export default function TransferRecordDialog(props) {
	const { open, onClose, sourceUserDataForTransfer, title } = props;

	const transferUserRecordMutation = useTransferUserRecordMutation();

	const [targetUserForTransfer, setTargetUserForTransfer] = useState(null);
	const [searchedValue, setSearchedValue] = useState("");

	const {
		activeUserList,
		isLoadingUsers,
		isFetchingUsers,
		hasNextUsersPage,
		fetchNextUsersPage,
	} = useUsers(searchedValue);

	const lastOptionRef = useLastRowRef(
		fetchNextUsersPage,
		hasNextUsersPage,
		isFetchingUsers
	);

	useEffect(() => {
		if (sourceUserDataForTransfer && sourceUserDataForTransfer?.name) {
			setTargetUserForTransfer({
				name: sourceUserDataForTransfer.name,
				value: sourceUserDataForTransfer?.id,
			});
		}
	}, [sourceUserDataForTransfer]);

	const onChange = (event, child) => {
		const { value } = event.target;
		setTargetUserForTransfer({ name: child?.props?.name, value: value });
	};

	const transferUserRecords = (
		sourceUserIdForTransfer,
		targetUserIdForTransfer
	) => {
		transferUserRecordMutation.mutate(
			{
				sourceUserId: sourceUserIdForTransfer,
				targetUserId: targetUserIdForTransfer,
			},
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.recordsTransfered,
					});
					onClose();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	const filteredUserList = useMemo(() => {
		let deactivate = "Deactivated";

		if (Array.isArray(activeUserList)) {
			if (sourceUserDataForTransfer?.state === deactivate) {
				return [
					...new Set([
						...activeUserList,
						{
							name: sourceUserDataForTransfer?.name,
							value: sourceUserDataForTransfer?.id,
						},
					]),
				];
			}
			return activeUserList;
		}
		return [];
	}, [activeUserList, sourceUserDataForTransfer]);

	const handleSearchUser = (event) => {
		const { value } = event.target;
		setSearchedValue(value?.trimStart());
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					{activeUserList?.length === 0
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<UserManagementDialog open={open} onClose={onClose} title={title}>
				<Box px={3} pb={3}>
					<Typography fontSize={13} fontWeight={500} pb={0.5}>
						Transfer to
					</Typography>

					<Box>
						<Select
							size="small"
							sx={{
								width: "100%",
							}}
							onChange={onChange}
							value={targetUserForTransfer?.value}
							renderValue={() => (
								<Typography fontSize={14}>
									{sourceUserDataForTransfer?.id ===
									targetUserForTransfer?.value
										? "Same user (Leave as is)"
										: targetUserForTransfer?.value}
								</Typography>
							)}
							MenuProps={{
								autoFocus: false,
								PaperProps: {
									style: {
										marginTop: "4px",
										borderRadius: "8px",
										maxHeight: "255px",
									},
								},
							}}
							displayEmpty
							onOpen={() => setSearchedValue("")}
						>
							<Box
								p={0.5}
								marginTop="-8px"
								sx={{
									position: "sticky",
									top: 0,
									zIndex: 1,
									backgroundColor: "#fff",
								}}
							>
								<MenuSearchBar
									value={searchedValue}
									onChange={handleSearchUser}
								/>
							</Box>

							{isLoadingUsers ? (
								<Stack
									alignItems="center"
									justifyContent="center"
									height="150px"
								>
									<CircularProgress size="25px" />
								</Stack>
							) : filteredUserList?.length > 0 ? (
								filteredUserList.map((user) => (
									<MenuItem
										value={user.value}
										key={user.value}
										name={user?.name}
										style={{
											minHeight: "40px",
										}}
									>
										<Stack
											direction="row"
											spacing={2}
											alignItems="center"
										>
											{sourceUserDataForTransfer?.id !==
												user.value && (
												<img
													alt="user"
													src={UserProfileImage}
													width="20px"
													height="20px"
												/>
											)}

											<MenuItemText>
												{user.value ===
												sourceUserDataForTransfer?.id
													? "Same user (Leave as is)"
													: user.name}
											</MenuItemText>
										</Stack>
									</MenuItem>
								))
							) : (
								renderEmptyView()
							)}

							<Box ref={lastOptionRef}>
								{!isLoadingUsers &&
									isFetchingUsers &&
									activeUserList?.length > 0 && (
										<MenuItem style={{ height: "40px" }}>
											<Stack
												direction="row"
												alignItems="center"
												justifyContent="center"
												spacing={1}
											>
												<Skeleton
													variant="circular"
													width="24px"
													height="24px"
												/>

												<Skeleton
													width="190px"
													height="12px"
												/>
											</Stack>
										</MenuItem>
									)}
							</Box>
						</Select>
					</Box>

					<Box pt={2}>
						<Stack
							direction="row"
							spacing={2}
							justifyContent="flex-end"
						>
							<Button
								onClick={onClose}
								variant="contained"
								color="secondary"
								disableElevation
							>
								Cancel
							</Button>

							<Button
								variant="contained"
								color="primary"
								disableElevation
								onClick={() =>
									transferUserRecords(
										sourceUserDataForTransfer?.id,
										targetUserForTransfer?.value
									)
								}
							>
								Transfer
							</Button>
						</Stack>
					</Box>
				</Box>
			</UserManagementDialog>
		</React.Fragment>
	);
}

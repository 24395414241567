import React from "react";
import { FormHelperText, Typography } from "@mui/material";
import TextComponent from "./FieldComponent/TextComponent";
import DropdownComponent from "./FieldComponent/DropdownComponent";
import TextAreaComponent from "./FieldComponent/TextAreaComponent";
import DateComponent from "./FieldComponent/DateComponent";
import NumberComponent from "./FieldComponent/NumberComponent";
import TimeComponent from "./FieldComponent/TimeComponent";
import MultipleEmailComponent from "./FieldComponent/MultipleEmailComponent";
import MultiplePhonesComponent from "./FieldComponent/MultiplePhonesComponent";
import ContactComponent from "./FieldComponent/ContactComponent";
import CompanyComponent from "./FieldComponent/CompanyComponent";
import OwnerComponent from "./FieldComponent/OwnerComponent";
import TagsComponent from "./FieldComponent/TagsComponent";
import RelatedContacts from "./FieldComponent/RelatedContactsComponent";
import MultiSelectComponent from "./FieldComponent/MultiSelectComponent";
import StepPickerComponent from "./FieldComponent/StepPickerComponent";
import EmailComponent from "./FieldComponent/EmailComponent";
import PhoneComponent from "./FieldComponent/PhoneComponent";
import CheckboxComponent from "./FieldComponent/CheckboxComponent";
import OwnersComponent from "./FieldComponent/OwnersComponent";
import TerritoryComponent from "./FieldComponent/TerritoryComponent";
import ActivityStartDateComponent from "./FieldComponent/ActivityStartDateComponent";
import ActivityEndDateComponent from "./FieldComponent/ActivityEndDateComponent";
import MultiDropdownComponent from "./FieldComponent/MultiDropdowComponent";

const fieldComponents = {
	SINGLE_STRING: TextComponent,
	IDENTIFIER: TextComponent,
	SINGLE_NUMBER: NumberComponent,
	DUAL_PICKER: DropdownComponent,
	DUAL_PICKERS: DropdownComponent,
	OWNER: OwnerComponent,
	DROPDOWN: DropdownComponent,
	MULTI_DROPDOWN: MultiDropdownComponent,
	TEXT: TextAreaComponent,
	DATE: DateComponent,
	ADDRESS: TextComponent,
	CONTACT: ContactComponent,
	COMPANY: CompanyComponent,
	PRODUCT: DropdownComponent,
	DEAL: DropdownComponent,
	TIME: TimeComponent,
	MONETARY: NumberComponent,
	EMAIL: EmailComponent,
	PHONE: PhoneComponent,
	TAGS: TagsComponent,
	TEXT_PICKERS: MultipleEmailComponent,
	EMAILS: MultipleEmailComponent,
	PHONES: MultiplePhonesComponent,
	CONTACTS_ONBOARD: RelatedContacts,
	CONTACT_ONBOARD: DropdownComponent,
	COMPANY_ONBOARD: DropdownComponent,
	TERRITORY: TerritoryComponent,
	MULTI_SELECT: MultiSelectComponent,
	STEP_PICKER: StepPickerComponent,
	NUMBER_PICKERS: MultiplePhonesComponent,
	NUMBER_PICKER: NumberComponent,
	DEAL_VALUE: NumberComponent,
	CHECKBOX: CheckboxComponent,
	OWNERS: OwnersComponent,
	ACTIVITY_START_DATE: ActivityStartDateComponent,
	ACTIVITY_END_DATE: ActivityEndDateComponent,
};

export default function Field(props) {
	const { field, metadata, style, error, ...other } = props;
	const FieldComponent = fieldComponents[field.fieldType];

	return (
		<React.Fragment>
			{FieldComponent ? (
				<FieldComponent
					field={field}
					error={error}
					metadata={metadata}
					style={style}
					{...other}
				/>
			) : (
				<Typography fontSize={14}>
					{field.fieldType} is not implemented
				</Typography>
			)}

			{!!error?.message && (
				<FormHelperText style={{ paddingLeft: "8px" }} error>
					{error.message}
				</FormHelperText>
			)}
		</React.Fragment>
	);
}

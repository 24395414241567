import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import MenuSearchBar from "../../Elements/MenuSearchBar";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { default as MenuDialog } from "../../Elements/Menu";
import EmailTemplateFolder from "./EmailTemplateFolder";
import { useEmailTemplateFolderList } from "../../../hooks/services/mail/emailTemplateFolder";
import { useEmailTemplateContext } from "./TemplateContext";
import { MenuItemText } from "../../../styles/twozo";

const styles = {
	headerText: {
		fontSize: "14px",
		fontWeight: 500,
		color: "rgba(0, 0, 0, 0.6)",
	},
	text: {
		fontSize: "14px",
	},
};

const templatesView = [
	{
		view: "All Email Templates",
		id: 1,
	},
	{
		view: "Created by Me",
		id: 2,
	},
	{
		view: "Shared with Me",
		id: 3,
	},
];

export default function EmailTemplates() {
	const theme = useTheme();

	const [templateMenuElement, setTemplateMenuElement] = useState(null);
	const isTemplateMenuOpened = Boolean(templateMenuElement);
	const [selectedTemplatesView, setSelectedTemplatesView] = useState(
		templatesView[0]
	);
	const [searchTemplateFolderValue, setSearchTemplateFolderValue] =
		useState("");
	const [templateFolders, setTemplateFolders] = useState([]);

	const {
		emailTemplateState,
		onFolderNameChanged,
		onNewFolderCreation,
		onNewFolderCreationError,
	} = useEmailTemplateContext();

	const {
		data: templateFolderList,
		status: templateFolderListStatus,
		isLoading: isTemplateFolderListLoading,
	} = useEmailTemplateFolderList(
		selectedTemplatesView.id,
		searchTemplateFolderValue
	);

	useEffect(() => {
		if (
			templateFolderListStatus === "success" &&
			Array.isArray(templateFolderList)
		) {
			setTemplateFolders(templateFolderList);
		}
	}, [templateFolderList, templateFolderListStatus]);

	const handleOpenTemplateMenu = (event) => {
		if (
			!emailTemplateState.isFolderNameEditMode &&
			!emailTemplateState.isNewFolderCreated
		) {
			setTemplateMenuElement(event.currentTarget);
		}
	};

	const handleCloseTemplateMenu = () => {
		setTemplateMenuElement(null);
	};

	const handleSelectedTemplateView = (templateView) => {
		setSelectedTemplatesView(templateView);
		setTemplateMenuElement(null);
	};

	const handleAddNewFolder = () => {
		if (emailTemplateState.isFolderNameEditMode) {
			onNewFolderCreationError(true);
		} else {
			if (emailTemplateState.isNewFolderCreated) {
				onNewFolderCreationError(true);
			} else {
				const newFolder = {
					name: "",
					isNewFolder: true,
				};
				setTemplateFolders([...templateFolders, newFolder]);
				onNewFolderCreation(true);
			}
		}
	};

	const resetFolderName = (resetFolderIndex) => {
		setTemplateFolders((templateFolders) =>
			templateFolders.map((templateFolder, index) => {
				if (index === resetFolderIndex) {
					return {
						...templateFolder,
						name: templateFolderList[resetFolderIndex]?.name,
					};
				}
				return templateFolder;
			})
		);
		onFolderNameChanged(false);
	};

	const onChangeFolderName = (updatedFolderName, updatedFolderIndex) => {
		setTemplateFolders((templateFolders) =>
			templateFolders.map((templateFolder, index) => {
				if (index === updatedFolderIndex) {
					return {
						...templateFolder,
						name: updatedFolderName,
					};
				}
				return templateFolder;
			})
		);

		if (
			templateFolderList[updatedFolderIndex]?.name === updatedFolderName
		) {
			onFolderNameChanged(false);
		} else {
			onFolderNameChanged(true);
		}
	};

	const cancelFolderCreation = () => {
		setTemplateFolders((templateFolders) =>
			templateFolders.filter(
				(templateFolder) => !templateFolder.isNewFolder
			)
		);
		onNewFolderCreation(false);
		onNewFolderCreationError(false);
	};

	const handleSearchTemplateFolder = (event) => {
		const { value } = event.target;
		setSearchTemplateFolderValue(value.trimStart());
	};

	return (
		<React.Fragment>
			<Box>
				<MenuDialog
					minWidth="280px"
					anchorEl={templateMenuElement}
					open={isTemplateMenuOpened}
					onClose={handleCloseTemplateMenu}
					style={{
						marginTop: "4px",
					}}
					sx={{ transform: "translateX(-5px)" }}
				>
					{templatesView.map((templateview) => (
						<MenuItem
							key={templateview.id}
							style={{ height: "40px" }}
							onClick={() =>
								handleSelectedTemplateView(templateview)
							}
						>
							<MenuItemText>{templateview.view}</MenuItemText>
						</MenuItem>
					))}
				</MenuDialog>
			</Box>

			<Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					py={1.25}
					sx={{ height: "50px" }}
				>
					<Box
						style={{ cursor: "pointer" }}
						onClick={handleOpenTemplateMenu}
					>
						<Stack
							direction="row"
							alignItems="center"
							px={3}
							spacing={1}
						>
							<Typography fontWeight={500} fontSize={15}>
								{selectedTemplatesView.view}
							</Typography>

							{DropDownIcon(16, 16)}
						</Stack>
					</Box>

					<Stack
						direction="row"
						alignItems="center"
						spacing={3}
						pr={3}
					>
						<MenuSearchBar
							placeholder="Search by Name or Tag"
							style={{ width: "300px" }}
							onChange={handleSearchTemplateFolder}
							value={searchTemplateFolderValue}
							disabled={
								emailTemplateState.isFolderNameEditMode ||
								emailTemplateState.isNewFolderCreated
							}
						/>

						<Button
							variant="contained"
							color="secondary"
							startIcon={AddIcon(
								20,
								20,
								theme.palette.primary.main
							)}
							disableElevation
							onClick={handleAddNewFolder}
						>
							New Folder
						</Button>
					</Stack>
				</Stack>

				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					style={{
						borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					}}
					px={3}
					py={1}
				>
					<Stack
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						width="40%"
					>
						<Typography style={styles.headerText}>Name</Typography>
					</Stack>

					<Stack direction="row" width="60%">
						<Box width="15%">
							<Typography style={styles.headerText}>
								Sent
							</Typography>
						</Box>

						<Box width="15%">
							<Typography style={styles.headerText}>
								Opened
							</Typography>
						</Box>

						<Box width="15%">
							<Typography style={styles.headerText}>
								Clicked
							</Typography>
						</Box>

						<Box width="15%">
							<Typography style={styles.headerText}>
								Replied
							</Typography>
						</Box>

						<Box width="15%">
							<Typography style={styles.headerText}>
								Unsubscribed
							</Typography>
						</Box>

						<Box width="25%"></Box>
					</Stack>
				</Stack>

				{isTemplateFolderListLoading ? (
					<Stack
						justifyContent="center"
						alignItems="center"
						height="70vh"
						width="100%"
					>
						<CircularProgress />
					</Stack>
				) : (
					<Stack
						spacing={1}
						p={1}
						style={{ height: "76vh", overflowY: "auto" }}
					>
						{templateFolders?.map((templateFolder, index) => (
							<Box key={index}>
								<EmailTemplateFolder
									templateFolder={templateFolder}
									resetFolderName={() =>
										resetFolderName(index)
									}
									onChangeFolderName={(updatedFolderName) =>
										onChangeFolderName(
											updatedFolderName,
											index
										)
									}
									cancelFolderCreation={cancelFolderCreation}
								/>
							</Box>
						))}
					</Stack>
				)}
			</Box>
		</React.Fragment>
	);
}

import React from "react";
import {
	Box,
	Checkbox,
	CircularProgress,
	Stack,
	Typography,
} from "@mui/material";
import MenuSearchBar from "../../Elements/MenuSearchBar";
import { useMailList } from "../../../hooks/services/mail";
import { useLastRowRef } from "../../../hooks/common/InfiniteScroll";

export default function BulkEmailList(props) {
	const { listHeader, header, body, footer, mailListName } = props;
	const {
		data: mails,
		isLoading: isMailListLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useMailList(mailListName);

	const firstPageMails = mails?.pages?.[0]?.list;
	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	return (
		<React.Fragment>
			<Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					py={1.25}
					style={{
						borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					}}
					sx={{ height: "50px" }}
				>
					<Stack direction="row" alignItems="center">
						<Box px={2}>
							<Checkbox disabled />
						</Box>

						<Typography fontWeight={500} fontSize={15}>
							{listHeader}
						</Typography>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						spacing={3}
						pr={3}
					>
						<MenuSearchBar style={{ width: "300px" }} />
					</Stack>
				</Stack>

				<Box>
					{isMailListLoading ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="80vh"
						>
							<CircularProgress />
						</Stack>
					) : mails?.pages?.length > 0 &&
					  firstPageMails?.length > 0 ? (
						<Box>
							{mails.pages.map((page) =>
								page?.list?.map((mail, index) => (
									<Stack
										key={index}
										direction="row"
										justifyContent="flex-start"
										alignItems="center"
										style={{
											borderBottom:
												"1px solid rgba(0, 0, 0, 0.1)",
										}}
										px={2}
										py={1}
									>
										<Stack
											direction="row"
											alignItems="center"
											spacing={2}
											width="25%"
										>
											<Box>
												<Checkbox />
											</Box>

											{!!header && (
												<props.header {...mail} />
											)}
										</Stack>

										<Box width="60%">
											{!!body && <props.body {...mail} />}
										</Box>

										<Box
											style={{
												width: "15%",
												textAlign: "right",
											}}
										>
											{!!footer && (
												<props.footer {...mail} />
											)}
										</Box>
									</Stack>
								))
							)}
						</Box>
					) : (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="70vh"
						>
							<Typography
								fontSize={17}
								fontWeight={500}
								color="rgba(0, 0, 0, 0.6)"
							>
								No Conversations Found
							</Typography>
						</Stack>
					)}

					<Box style={{ height: "52px" }} ref={lastRowRef}>
						{!isMailListLoading && isFetching ? (
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="center"
								spacing={1}
								py={2}
							>
								<CircularProgress size={18} />
								<Typography fontSize={12}>
									Loading More Data
								</Typography>
							</Stack>
						) : null}
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}

export const fieldType = {
	IDENTIFIER: "IDENTIFIER",
	SINGLE_STRING: "SINGLE_STRING",
	SINGLE_NUMBER: "SINGLE_NUMBER",
	DUAL_PICKER: "DUAL_PICKER",
	DUAL_PICKERS: "DUAL_PICKERS",
	OWNERS: "OWNERS",
	OWNER: "OWNER",
	DROPDOWN: "DROPDOWN",
	MULTI_DROPDOWN: "MULTI_DROPDOWN",
	TEXT: "TEXT",
	DATE: "DATE",
	ADDRESS: "ADDRESS",
	CONTACT: "CONTACT",
	COMPANY: "COMPANY",
	PRODUCT: "PRODUCT",
	DEAL: "DEAL",
	TIME: "TIME",
	MONETARY: "MONETARY",
	EMAIL: "EMAIL",
	PHONE: "PHONE",
	TAGS: "TAGS",
	TEXT_PICKERS: "TEXT_PICKERS",
	EMAILS: "EMAILS",
	PHONES: "PHONES",
	CONTACTS_ONBOARD: "CONTACTS_ONBOARD",
	CONTACT_ONBOARD: "CONTACT_ONBOARD",
	COMPANY_ONBOARD: "COMPANY_ONBOARD",
	TERRITORY: "TERRITORY",
	MULTI_SELECT: "MULTI_SELECT",
	STEP_PICKER: "STEP_PICKER",
	NUMBER_PICKERS: "NUMBER_PICKERS",
	NUMBER_PICKER: "NUMBER_PICKER",
	DEAL_VALUE: "DEAL_VALUE",
};

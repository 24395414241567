import React, { useMemo, useState } from "react";
import {
	Box,
	CircularProgress,
	IconButton,
	InputAdornment,
	MenuItem,
	Paper,
	Popper,
	Skeleton,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as MuiAutocomplete } from "@mui/material/Autocomplete";
import { default as CloseIcon } from "../../../assets/icons/close";
import { default as DealIcon } from "../../../assets/icons/deals";
import { useDebouncedCallback } from "use-debounce";
import { useDealList } from "../../../hooks/services/deal";
import { useLastRowRef } from "../../../hooks/common/InfiniteScroll";
import { useAuth } from "../../../hooks/auth";
import { PERMISSIONS } from "../../../utils/Auth";
import { MenuItemText } from "../../../styles/twozo";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
			}}
		/>
	);
};

export default function DealAutocomplete(props) {
	const {
		value,
		onChange,
		onCancel,
		filterCriteria,
		disabled = false,
		isIconNeeded = true,
	} = props;

	const theme = useTheme();

	const [dealInputValue, setDealInputValue] = useState("");

	const { isUserAllowedFor } = useAuth();
	const isDealListEnabled = isUserAllowedFor(PERMISSIONS.deal.view);
	const {
		data: dealList,
		isLoading: isLoadingDeals,
		isFetching: isFetchingDeals,
		hasNextPage: hasDealNextPage,
		fetchNextPage: fetchDealNextPage,
	} = useDealList(isDealListEnabled, dealInputValue);

	const dealLastOptionRef = useLastRowRef(
		fetchDealNextPage,
		hasDealNextPage,
		isFetchingDeals
	);

	const filteredDealList = useMemo(() => {
		if (dealList?.length > 0) {
			if (filterCriteria?.excludeDealId) {
				return dealList?.filter(
					(deal) => deal?.value !== filterCriteria?.excludeDealId
				);
			}
			return dealList;
		}
		return [];
	}, [dealList, filterCriteria?.excludeDealId]);

	const handleDealInputChange = (event) => {
		const { value } = event.target;
		setDealInputValue(value?.trimStart());
	};

	const onInputChange = useDebouncedCallback(
		(event) => {
			handleDealInputChange(event);
		},
		500 // delay in ms
	);

	const onBlurDealInput = () => {
		setDealInputValue("");
	};

	const handleSelectDeal = (_, value) => {
		setDealInputValue("");
		onChange(value);
	};

	const renderOption = (option) => {
		return (
			<MenuItem
				style={{
					height: "40px",
					width: "100%",
				}}
			>
				<MenuItemText>{option?.name}</MenuItemText>
			</MenuItem>
		);
	};

	const CustomListboxComponent = function (props) {
		return (
			<Box {...props}>
				{props.children}

				<Box ref={dealLastOptionRef}>
					{!isLoadingDeals && isFetchingDeals && hasDealNextPage ? (
						<MenuItem
							style={{
								height: "40px",
								width: "100%",
							}}
						>
							<Skeleton width="200px" height="16px" />
						</MenuItem>
					) : null}
				</Box>
			</Box>
		);
	};

	return (
		<MuiAutocomplete
			size="small"
			disableClearable
			value={value}
			options={filteredDealList || []}
			onChange={handleSelectDeal}
			open={Boolean(dealInputValue)}
			readOnly={Boolean(value?.name)}
			disabled={disabled}
			PopperComponent={CustomPopper}
			PaperComponent={CustomPaper}
			getOptionLabel={(option) => option?.name || ""}
			noOptionsText={
				<Typography fontSize={14}>No Results Found</Typography>
			}
			loading={isLoadingDeals}
			loadingText={
				<Stack
					alignItems="center"
					justifyContent="center"
					height="140px"
				>
					<CircularProgress size="18px" />
				</Stack>
			}
			renderOption={(props, option) => (
				<Box {...props} key={option?.value} style={{ padding: "0px" }}>
					{renderOption(option)}
				</Box>
			)}
			ListboxComponent={CustomListboxComponent}
			style={{
				padding: "0px",
				width: "100%",
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					value={dealInputValue}
					onChange={onInputChange}
					inputProps={{
						...params.inputProps,
						style: {
							fontSize: "14px",
						},
					}}
					onBlur={onBlurDealInput}
					placeholder="Select"
					InputProps={{
						...params.InputProps,
						startAdornment: isIconNeeded && (
							<InputAdornment
								position="start"
								sx={{ paddingLeft: "10px" }}
							>
								{DealIcon(
									20,
									20,
									"#666666",
									disabled ? 0.6 : 1
								)}
							</InputAdornment>
						),

						endAdornment: (
							<InputAdornment position="end">
								{!!value?.name && !disabled && (
									<IconButton onClick={onCancel}>
										{CloseIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</IconButton>
								)}
							</InputAdornment>
						),
					}}
				/>
			)}
		/>
	);
}

export const styles = {
	boxContainer: {
		border: "1px solid rgba(0, 0, 0, 0.1)",
		borderRadius: "8px",
	},
	heading: {
		fontSize: "15px",
		fontWeight: 600,
	},
	text: {
		fontSize: "14px",
		color: "rgba(0, 0, 0, 0.6)",
	},
	subText: {
		fontSize: "14px",
		fontWeight: 500,
		color: "rgba(0, 0, 0, 0.6)",
	},
	fileName: {
		fontSize: "14px",
		fontWeight: 500,
		cursor: "pointer",
	},
	textButton: {
		fontSize: "14px",
		fontWeight: 500,
		cursor: "pointer",
	},
};

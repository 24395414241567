import {
	Box,
	Checkbox,
	IconButton,
	Stack,
	TableCell,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { default as ContactImage } from "../../../../assets/images/contact/unknownContact.png";
import { default as MailIcon } from "../../../../assets/icons/mail";
import { default as MailOpenedIcon } from "../../../../assets/icons/mailOpenSimple";
import { default as MailSentIcon } from "../../../../assets/icons/mailSent";
import { default as MailDraftIcon } from "../../../../assets/icons/mailDraft";
import { default as MailSheduledIcon } from "../../../../assets/icons/mailScheduled";
import { default as MailTrashIcon } from "../../../../assets/icons/delete";
import { default as DealIcon } from "../../../../assets/icons/deals";
import { default as AttachmentIcon } from "../../../../assets/icons/attachment";
import { default as FavouriteOnIcon } from "../../../../assets/icons/favouriteOn";
import { default as FavouriteOffIcon } from "../../../../assets/icons/favouriteOff";
import { default as MoreIcon } from "../../../../assets/icons/more";
import { Tooltip } from "../../../../styles/twozo";
import { getFormattedDateMonthYearAndTime } from "../../../../utils/DateUtils";
import React, { useState } from "react";
import { useUpdateMailState } from "../../../../hooks/services/mail";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import EmailTableRowMenu from "../EmailTableRowMenu";

const messageCategory = {
	scheduled: "SCHEDULED",
	outbound: "OUTBOUND",
};

export default function EmailTableRow(props) {
	const {
		mailData,
		isSelected,
		onRowClicked,
		handleCheckboxClick,
		mailListName,
		tableRowMenuConfig,
		hiddenFavoriteAction,
		isRowHighlighted,
		hiddenBodyContent,
		hasTrashed,
	} = props;

	const theme = useTheme();
	const [hoveredTableRow, setHoveredTableRow] = useState(false);
	const [tableRowMenuElement, setTableRowMenuElement] = useState(null);
	const isTableRowMenuOpened = Boolean(tableRowMenuElement);
	const [selectedMailData, setSelectedMailData] = useState({});

	const onMouseOverTableRow = () => {
		setHoveredTableRow(true);
	};

	const onMouseOutTableRow = () => {
		if (!isTableRowMenuOpened) {
			setHoveredTableRow(false);
		}
	};

	const getMailIcon = (mailData) => {
		if (hasTrashed) {
			return MailTrashIcon(20, 20, "rgba(0, 0, 0, 0.3)");
		} else if (mailData?.messageCategory === messageCategory.scheduled) {
			return MailSheduledIcon(20, 20, "rgba(0, 0, 0, 0.3");
		} else if (mailData?.hasDraft) {
			return MailDraftIcon(20, 20, "rgba(0, 0, 0, 0.3)");
		} else if (mailData?.messageCategory === messageCategory.outbound) {
			return MailSentIcon(20, 20, "rgba(0, 0, 0, 0.3)");
		} else if (mailData?.isRead) {
			return MailOpenedIcon(20, 20, "rgba(0, 0, 0, 0.3)");
		} else {
			return MailIcon(20, 20, theme.palette.primary.main);
		}
	};

	const isMailRead = (mail) => {
		return (
			mail?.isRead ||
			mail?.messageCategory === messageCategory.outbound ||
			mail?.isScheduled ||
			mail?.hasDraft ||
			hasTrashed
		);
	};

	const updateMailStateMutation = useUpdateMailState(mailListName);

	const toggleFavouriteMail = (mail) => {
		const updatedMailStateRequest = {
			ids: [mail?.id],
			isStarred: !mail.isStarred,
		};
		updateMailStateMutation.mutate(updatedMailStateRequest, {
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const openTableRowMenu = (event, selectedMail) => {
		event.stopPropagation();
		setTableRowMenuElement(event.currentTarget);
		setSelectedMailData(selectedMail);
	};

	const closeTableRowMenu = () => {
		setTableRowMenuElement(null);
		setHoveredTableRow(false);
	};

	return (
		<React.Fragment>
			<EmailTableRowMenu
				anchorEl={tableRowMenuElement}
				isTableRowMenuOpened={isTableRowMenuOpened}
				closeTableRowMenu={closeTableRowMenu}
				menuConfig={tableRowMenuConfig}
				selectedMailData={selectedMailData}
				menuSpecificProps={{
					mailListName: mailListName,
					hasTrashMail: hasTrashed,
				}}
			/>

			<TableRow
				selected={isSelected || isRowHighlighted}
				onMouseOver={() => onMouseOverTableRow(mailData.id)}
				onMouseOut={() => onMouseOutTableRow()}
				sx={{
					height: "52px",
					backgroundColor: isMailRead(mailData)
						? "transparent"
						: "#FAFAFA",
					opacity: hasTrashed ? 0.8 : 1,
				}}
				onClick={() => onRowClicked(mailData)}
			>
				<TableCell
					style={{
						width: "50px",
						borderLeft: isMailRead(mailData)
							? "none"
							: `4px solid ${theme.palette.primary.main}`,
						padding: isMailRead(mailData) ? "6px 16px" : "6px 12px",
					}}
					onClick={(event) => event.stopPropagation()}
				>
					<Checkbox
						checked={isSelected}
						onChange={(event) =>
							handleCheckboxClick(event, mailData)
						}
					/>
				</TableCell>

				<TableCell
					style={{
						width: "30px",
						padding: "6px 4px",
					}}
				>
					<Box display="flex">{getMailIcon(mailData)}</Box>
				</TableCell>
				{!hiddenFavoriteAction ? (
					<TableCell
						style={{
							width: "40px",
							padding: "6px 8px",
						}}
						onClick={(event) => event.stopPropagation()}
					>
						<Box display="flex">
							<IconButton
								style={{
									padding: "2px",
								}}
								onClick={() => toggleFavouriteMail(mailData)}
							>
								{mailData?.isStarred
									? FavouriteOnIcon(
											20,
											20,
											theme.palette.primary.main
										)
									: FavouriteOffIcon(
											20,
											20,
											"rgba(0, 0, 0, 0.2)"
										)}
							</IconButton>
						</Box>
					</TableCell>
				) : null}

				<TableCell
					style={{
						width: "180px",
						padding: "6px",
					}}
				>
					<Stack direction="row" alignItems="center" spacing={1}>
						<img
							src={ContactImage}
							alt="contact_image"
							height="24px"
							width="24px"
						/>

						<Typography
							sx={{
								color: hasTrashed
									? "rgba(0, 0, 0, 0.6)"
									: "#000",
								fontWeight: isMailRead(mailData) ? 400 : 500,
								fontSize: 14,
							}}
							noWrap
						>
							{mailData?.contact?.contactName
								? mailData.contact.contactName
								: mailData?.email}
						</Typography>
					</Stack>
				</TableCell>

				<TableCell>
					<Stack direction="row" spacing={1} alignItems={"center"}>
						{mailData.hasDraft ? (
							<Typography fontSize={14} color="#EB5454">
								[Draft]
							</Typography>
						) : null}

						{mailData.conversationCount > 1 ? (
							<Box>
								<Box
									sx={{
										border: "1px solid rgba(0, 0, 0, 0.1)",
										height: "23px",
										width: "23px",
										textAlign: "center",
										borderRadius: "100%",
										color: theme.palette.secondary
											.contrastText,
									}}
								>
									{mailData.conversationCount}
								</Box>
							</Box>
						) : null}
						<Typography
							fontSize={14}
							noWrap
							sx={{
								color: hasTrashed
									? "rgba(0, 0, 0, 0.6)"
									: "#000",
							}}
						>
							{mailData?.subject
								? mailData.subject
								: "(no subject)"}
							{!hiddenBodyContent ? (
								<Typography
									component="span"
									fontSize={14}
									style={{
										color: "rgba(0, 0, 0, 0.6)",
									}}
								>
									&nbsp; - &nbsp;
									{mailData.message}
								</Typography>
							) : null}
						</Typography>

						{mailData?.Opened && mailData.Opened.time ? (
							<Box
								sx={{
									p: 0.5,
									color: theme.palette.secondary.contrastText,
									backgroundColor:
										theme.palette.secondary.main,
									borderRadius: "6px",
								}}
							>
								<Tooltip
									title={getFormattedDateMonthYearAndTime(
										mailData.Opened.time
									)}
								>
									<Typography fontSize={13} fontWeight={500}>
										Opened
									</Typography>
								</Tooltip>
							</Box>
						) : null}

						{mailData?.Clicked && mailData.Clicked.time ? (
							<Box
								sx={{
									p: 0.5,
									color: theme.palette.secondary.contrastText,
									backgroundColor:
										theme.palette.secondary.main,
									borderRadius: "6px",
								}}
							>
								<Tooltip
									title={getFormattedDateMonthYearAndTime(
										mailData.Clicked.time
									)}
								>
									<Typography fontSize={13} fontWeight={500}>
										Clicked
									</Typography>
								</Tooltip>
							</Box>
						) : null}
					</Stack>
				</TableCell>

				<TableCell
					style={{
						width: "35px",
						padding: "0px",
					}}
				>
					{mailData?.dealId && (
						<Box
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								style={{
									padding: "2px",
								}}
							>
								{DealIcon(18, 18, theme.palette.primary.main)}
							</IconButton>
						</Box>
					)}
				</TableCell>

				<TableCell
					style={{
						width: "35px",
						padding: "0px",
					}}
				>
					{mailData.hasAttachment && (
						<Box
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								style={{
									padding: "2px",
								}}
							>
								{AttachmentIcon(
									18,
									18,
									theme.palette.primary.main
								)}
							</IconButton>
						</Box>
					)}
				</TableCell>

				<TableCell
					style={{
						width: "200px",
						padding: "6px 16px",
						textAlign: "end",
						position: "relative",
					}}
				>
					<Typography
						fontSize={14}
						color={
							isMailRead(mailData) ? "rgba(0, 0, 0, 0.6)" : "#000"
						}
						noWrap
					>
						{mailData?.scheduledTime
							? `@ ${getFormattedDateMonthYearAndTime(
									mailData?.scheduledTime
								)}`
							: getFormattedDateMonthYearAndTime(
									mailData?.timeStamp
								)}
					</Typography>

					{hoveredTableRow ? (
						<Box
							style={{
								position: "absolute",
								top: 8,
								right: 12,
								bottom: 8,
								left: 135,
								border: `1px solid ${theme.palette.primary.main}`,
								borderRadius: "8px",
								backgroundColor: "#fff",
								alignContent: "center",
								textAlign: "center",
							}}
							onClick={(event) =>
								openTableRowMenu(event, mailData)
							}
						>
							<IconButton size="small">
								{MoreIcon(16, 16, theme.palette.primary.main)}
							</IconButton>
						</Box>
					) : null}
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

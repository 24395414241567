import React, { useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as ContactImage } from "../../../../../assets/images/contact/unknownContact.png";
import { default as DropdownIcon } from "../../../../../assets/icons/dropDownCentered";
import { default as ActivityIcon } from "../../../../../assets/icons/addActivity";
import { default as CallIcon } from "../../../../../assets/icons/call";
import { default as LinkedinLogo } from "../../../../../assets/images/socialMedia/linkedin.png";
import { default as FacebookLogo } from "../../../../../assets/images/socialMedia/facebook.png";
import RelatedContactsMenu from "./RelatedContactsMenu";
import CustomSwipeableDrawer from "../../../../Elements/CustomSwipeableDrawer";
import AddActivity from "../../../../Summary/Activities/AddActivity";
import { MenuItemText, twozoStyles } from "../../../../../styles/twozo";
import TooltipComponent from "../../../../Elements/TooltipComponent";
import { useNavigate } from "react-router-dom";
import Menu from "../../../../Elements/Menu";

const styles = {
	socialMediaLogoContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "50px",
		width: 20,
		height: 20,
		cursor: "pointer",
	},
};

export default function RelatedContacts(props) {
	const { relatedContactsAndDeal, threadId, mailListName } = props;

	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();

	const [expandedContactIndex, setExpandedContactIndex] = useState("");
	const [addContactMenuElement, setAddContactMenuElement] = useState(null);
	const isAddContactMenuOpened = Boolean(addContactMenuElement);
	const [selectedContactData, setSelectedContactData] = useState({});
	const [isAddActivityDrawerOpened, setIsAddActivityDrawerOpened] =
		useState(false);
	const [phoneMenuElement, setPhoneMenuElement] = useState(null);
	const isPhoneMenuOpened = Boolean(phoneMenuElement);

	const handleContactOpen =
		(expandedIndex, contactData) => (_, hasNewThread) => {
			setExpandedContactIndex(hasNewThread ? expandedIndex : "");
			setSelectedContactData(hasNewThread ? contactData : {});
		};

	const openAddContactMenu = (event) => {
		setAddContactMenuElement(event.currentTarget);
	};

	const closeAddContactMenu = () => {
		setAddContactMenuElement(null);
	};

	const openAddActivityDrawer = (event) => {
		event.stopPropagation();
		setIsAddActivityDrawerOpened(true);
	};

	const closeAddActivityDrawer = () => {
		setIsAddActivityDrawerOpened(false);
	};

	const openPhoneMenu = (event) => {
		if (selectedContactData?.phone?.length > 1) {
			setPhoneMenuElement(event.currentTarget);
		}
	};

	const closePhoneMenu = () => {
		setPhoneMenuElement(null);
	};

	const redirectFacebookAndLinkedIn = (link) => {
		if (link) {
			window.open(link);
		}
	};

	const navigateToContact = (contactId) => {
		navigate(`/contacts/${contactId?.toString()}`);
	};

	const getEmailValue = (contactData) => {
		if (contactData?.isNewContact) {
			return contactData?.email;
		} else {
			return contactData?.email?.emailValue;
		}
	};

	return (
		<React.Fragment>
			{/* Contacts Menu */}
			<RelatedContactsMenu
				addContactMenuElement={addContactMenuElement}
				isAddContactMenuOpened={isAddContactMenuOpened}
				closeAddContactMenu={closeAddContactMenu}
				selectedContactData={selectedContactData}
				menuContext={{
					threadId: threadId,
					mailListName: mailListName,
					emailsFieldId: relatedContactsAndDeal?.emailsFieldId,
					emailsSubTypeField:
						relatedContactsAndDeal?.emailsSubTypeField,

					firstNameFieldId: relatedContactsAndDeal?.firstNameFieldId,
				}}
			/>

			{/* Phone Menu */}
			<Menu
				minWidth="200px"
				anchorEl={phoneMenuElement}
				open={isPhoneMenuOpened}
				onClose={closePhoneMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{selectedContactData?.phone?.map((phone) => (
					<MenuItem key={phone?.id}>
						<MenuItemText
							fontWeight={500}
							color={theme.palette.secondary.contrastText}
						>
							{phone?.phoneNumber}
						</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			{/* Add Activity Drawer */}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddActivityDrawerOpened}
				onOpen={openAddActivityDrawer}
				onClose={closeAddActivityDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={closeAddActivityDrawer}
						contacts={[
							{
								name: selectedContactData?.fullName,
								value: selectedContactData?.id,
							},
						]}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Box>
				<Typography fontSize={14} fontWeight={500} pl={0.5} pb={1}>
					Related Contacts
				</Typography>

				<Box>
					{relatedContactsAndDeal?.relatedContacts?.map(
						(contactData, index) => (
							<Accordion
								key={contactData.id}
								disableGutters
								elevation={0}
								expanded={expandedContactIndex === index}
								onChange={handleContactOpen(index, contactData)}
								sx={{
									border: `1px solid ${theme.palette.divider}`,
									"&:first-child": {
										borderRadius: "8px 8px 0px 0px",
									},
									"&:not(:last-child)": {
										borderBottom: 0,
									},
									"&:last-child": {
										borderRadius: "0px 0px 8px 8px",
									},
									"&:before": {
										display: "none",
									},
								}}
							>
								<AccordionSummary
									expandIcon={DropdownIcon(
										16,
										16,
										"rgba(0, 0, 0, 0.6)"
									)}
								>
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between"
										spacing={0.5}
										width="100%"
										pr={1}
									>
										<Stack
											direction="row"
											alignItems="center"
											spacing={2}
											width="100%"
										>
											<img
												src={ContactImage}
												width="20px"
												height="20px"
												alt="contact"
											/>

											<TooltipComponent
												title={getEmailValue(
													contactData
												)}
												placement="top"
												verticalDisplacement={-3}
												width="600px"
											>
												<Typography
													fontSize={14}
													maxWidth="200px"
													noWrap
													sx={{ cursor: "pointer" }}
												>
													{getEmailValue(contactData)}
												</Typography>
											</TooltipComponent>
										</Stack>

										{expandedContactIndex === index &&
										!contactData?.isNewContact ? (
											<IconButton
												size="small"
												style={{ padding: 0 }}
												onClick={openAddActivityDrawer}
											>
												{ActivityIcon(
													16,
													18,
													theme.palette.primary.main
												)}
											</IconButton>
										) : null}
									</Stack>
								</AccordionSummary>

								<AccordionDetails style={{ paddingTop: 0 }}>
									{contactData?.isNewContact ? (
										<Box>
											<Stack
												direction="row"
												alignItems="center"
												justifyContent="space-between"
												spacing={1}
												width="100%"
											>
												<TooltipComponent
													title={
														contactData?.contactName
													}
													placement="top"
													width="600px"
												>
													<Typography
														fontWeight={600}
														fontSize={14}
														color={
															theme.palette
																.secondary
																.contrastText
														}
														pr={0.5}
														maxWidth="200px"
														noWrap
														sx={{
															cursor: "pointer",
														}}
													>
														{
															contactData?.contactName
														}
													</Typography>
												</TooltipComponent>

												<Button
													variant="outlined"
													size="small"
													style={{
														height: "30px",
														backgroundColor:
															isAddContactMenuOpened
																? theme.palette
																		.secondary
																		.main
																: "transparent",
													}}
													onClick={openAddContactMenu}
												>
													Add
												</Button>
											</Stack>
										</Box>
									) : (
										<Box>
											<Stack
												direction="row"
												alignItems="center"
												spacing={1}
											>
												<Box
													onClick={() =>
														navigateToContact(
															contactData?.id
														)
													}
													display="flex"
												>
													<TooltipComponent
														title={
															contactData?.fullName
														}
														placement="top"
														width="600px"
													>
														<Typography
															fontWeight={600}
															fontSize={14}
															color={
																theme.palette
																	.secondary
																	.contrastText
															}
															pr={0.5}
															maxWidth="200px"
															noWrap
															sx={{
																cursor: "pointer",
															}}
														>
															{
																contactData?.fullName
															}
														</Typography>
													</TooltipComponent>
												</Box>

												<Box
													style={{
														...styles.socialMediaLogoContainer,
														backgroundColor:
															"#225982",
														opacity:
															contactData?.linkedIn
																? 1
																: 0.6,
													}}
													onClick={() =>
														redirectFacebookAndLinkedIn(
															contactData?.linkedIn
														)
													}
												>
													<img
														src={LinkedinLogo}
														alt={"linkedin"}
														width="11px"
														height="11px"
													/>
												</Box>

												<Box
													style={{
														...styles.socialMediaLogoContainer,
														backgroundColor:
															"#4267B2",
														opacity:
															contactData?.facebook
																? 1
																: 0.6,
													}}
													onClick={() =>
														redirectFacebookAndLinkedIn(
															contactData?.facebook
														)
													}
												>
													<img
														src={FacebookLogo}
														alt={"facebook"}
														width="11px"
														height="11px"
													/>
												</Box>
											</Stack>

											<Box display="flex">
												{contactData?.company ? (
													<TooltipComponent
														title={
															contactData.company
																?.name
														}
														placement="top"
													>
														<Typography
															fontSize={14}
															maxWidth="250px"
															noWrap
															sx={{
																cursor: "pointer",
															}}
														>
															{
																contactData
																	.company
																	?.name
															}
														</Typography>
													</TooltipComponent>
												) : null}
											</Box>

											{contactData?.phone?.length > 0 ? (
												<Stack
													pt={1.5}
													direction="row"
													alignItems="center"
													spacing={1}
													style={{
														cursor: "pointer",
													}}
												>
													{CallIcon(
														16,
														16,
														theme.palette.secondary
															.contrastText
													)}

													<Typography
														fontSize={14}
														fontWeight={500}
														color={
															theme.palette
																.secondary
																.contrastText
														}
													>
														{
															contactData
																?.phone?.[0]
																?.phoneNumber
														}
														&nbsp;
														<Typography
															fontSize={14}
															component="span"
															onClick={
																openPhoneMenu
															}
														>
															{contactData?.phone
																?.length > 1 &&
																`+${
																	contactData
																		?.phone
																		?.length -
																	1
																}`}
														</Typography>
													</Typography>
												</Stack>
											) : null}
										</Box>
									)}
								</AccordionDetails>
							</Accordion>
						)
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}

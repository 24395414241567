import { useEffect, useMemo, useState } from "react";
import { DashboardBuilderContext } from "../Context";
import {
	// useChartApplyData,
	useGetChartType,
	useGetDashboard,
	useUpdateDashboardMutation,
} from "../../../../../hooks/services/analytics";
import { default as BarChartIcon } from "../../../../../assets/icons/barChart";
import { default as PieChartIcon } from "../../../../../assets/icons/pieChart";
import { default as LineChartIcon } from "../../../../../assets/icons/lineChart";
import { default as KpiCardIcon } from "../../../../../assets/icons/numbersChart";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { useNavigate } from "react-router-dom";
import { MAX_CHART_LIMIT } from "../Config";
import { usePreventRouteChange } from "../../../../../hooks/common/PreventRouterChange";

const DashboardBuilderProvider = ({ children, dashboardId }) => {
	const navigate = useNavigate();
	const [dashboardName, setDashboardName] = useState("");
	const [dashboardDescription, setDashboardDescription] = useState("");
	const [chartConfiguration, setChartConfiguration] = useState([]);
	const [isDashboardSaving, setIsDashboardSaving] = useState(false);
	const [selectedChart, setSelectedChart] = useState(null);
	const [selectedChartIndex, setSelectedChartIndex] = useState(-1);
	const [isSelectedChartDirty, setIsSelectedChartDirty] = useState(false);
	const [isDashboardDirty, setIsDashboardDirty] = useState(false);
	const [showPreventNavigationDialog, setShowPreventNavigationDialog] =
		useState(false);
	const [
		showPreventChartSelectionDialog,
		setShowPreventChartSelectionDialog,
	] = useState(false);
	const [confirmChartSelection, setConfirmChartSelection] = useState(null);
	const [newChartDirtyMap, setNewChartDirtyMap] = useState({});
	// const chartApplyDataMutation = useChartApplyData();
	const { data: chartTypeListData, isLoading: isChartTypeListDataLoading } =
		useGetChartType();
	const { data: dashboardData, isLoading: isDashboardDataLoading } =
		useGetDashboard(dashboardId, true, {
			enabled: !!(chartTypeListData && chartTypeListData.viewList),
		});
	const updateDashboardMutation = useUpdateDashboardMutation(dashboardData);

	const { confirmNavigation, cancelNavigation } = usePreventRouteChange(
		isSelectedChartDirty || isDashboardDirty,
		true,
		setShowPreventNavigationDialog
	);

	useEffect(() => {
		if (!isDashboardDataLoading && dashboardData) {
			setDashboardName(dashboardData.dashboardName);
			setDashboardDescription(dashboardData.description);

			if (
				dashboardData.childViewInfo &&
				dashboardData.childViewInfo.length > 0
			) {
				setChartConfiguration(
					dashboardData.childViewInfo.map((childViewInfo, index) => {
						// Select first chart
						if (index === 0) {
							selectChart(childViewInfo.viewId, 0);
						}

						const chartTypeData = chartTypeListData.viewList?.find(
							(chartTypeData) =>
								chartTypeData.id === childViewInfo.viewTypeId
						);
						return {
							...childViewInfo,
							meta: {
								viewType: chartTypeData?.viewBy,
								viewIcon: chartTypeData?.icon,
							},
						};
					})
				);
			} else {
				setChartConfiguration([]);
			}
		}
		// eslint-disable-next-line
	}, [dashboardData, isDashboardDataLoading, chartTypeListData]);

	const addNewChart = (chartData) => {
		const chartConfigurationLength = chartConfiguration.length;

		if (chartConfigurationLength >= MAX_CHART_LIMIT) {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.DashboardMaxWidgetReached,
			});
			return;
		}

		const newChartId = "new_chart_" + (chartConfigurationLength + 1);
		setChartConfiguration((chartConfiguration) => [
			...chartConfiguration,
			{
				...chartData,
				viewId: newChartId,
				position: chartConfigurationLength + 1,
			},
		]);
		selectChart(newChartId, chartConfigurationLength);
		setIsDashboardDirty(true);
		setNewChartDirtyMap((newChartDirtyMap) => ({
			...newChartDirtyMap,
			[newChartId]: true,
		}));
	};

	const updateChartProperties = (chartIndex, chartProperties) => {
		setChartConfiguration((chartConfiguration) => {
			chartConfiguration[chartIndex] = {
				...chartConfiguration[chartIndex],
				...chartProperties,
			};

			// let chartConfig = chartConfiguration[chartIndex];

			// chartApplyDataMutation.mutate({
			// 	viewTypeId: chartConfig.viewTypeId,
			// 	viewName: chartConfig.viewName,
			// 	viewProperties: {
			// 		viewBy: {
			// 			...chartConfig.viewProperties.viewBy
			// 		},
			// 		metrics: chartConfig.viewProperties.metrics.map((metric, index) => {
			// 			const parsedMetric = {...metric};
			// 			delete parsedMetric["moduleData"];
			// 			parsedMetric["order"] = index + 1;
			// 			return parsedMetric;
			// 		}),
			// 		// periodicFilters: {
			// 		// 	fieldId: 1622,
			// 		// 	comparatorId: 1487,
			// 		// 	values: [
			// 		// 		{
			// 		// 			value: {
			// 		// 				timeUnitId: 27,
			// 		// 				timeUnitValue: 10
			// 		// 			}
			// 		// 		}
			// 		// 	]
			// 		// }
			// 	}
			// }, {
			// 	onSuccess: (data) => {
			// 		console.log("------- Chart Data ---------");
			// 		console.log(data);
			// 		console.log("----------------------------");
			// 	}
			// });
			// console.log(chartConfiguration);
			return [...chartConfiguration];
		});
		setNewChartDirtyMap((newChartDirtyMap) => {
			const updatedNewChartDirtyMap = { ...newChartDirtyMap };
			delete updatedNewChartDirtyMap[
				chartConfiguration[chartIndex]["viewId"]
			];
			return updatedNewChartDirtyMap;
		});
		setIsDashboardDirty(true);
	};

	const parseChartProperty = (chartProperty) => {
		delete chartProperty["meta"];
		delete chartProperty["viewId"];

		chartProperty["viewProperties"]["metrics"]?.forEach((_, index) => {
			delete chartProperty["viewProperties"]["metrics"]?.[index][
				"moduleData"
			];
			if (
				chartProperty["viewProperties"]["metrics"]?.[index]["filters"]
					?.length <= 0
			) {
				delete chartProperty["viewProperties"]["metrics"]?.[index][
					"filters"
				];
			}
			if (
				!chartProperty["viewProperties"]["metrics"]?.[index][
					"periodicFilters"
				]?.fieldId
			) {
				delete chartProperty["viewProperties"]["metrics"]?.[index][
					"periodicFilters"
				];
			}

			chartProperty["viewProperties"]["metrics"][index]["order"] =
				index + 1;
		});
		return chartProperty;
	};

	const isNewChartDirty = useMemo(() => {
		return Object.values(newChartDirtyMap).some((value) => value === true);
	}, [newChartDirtyMap]);

	const saveDashboard = (
		checkChartDirty = true,
		chartConfig = chartConfiguration
	) => {
		if (checkChartDirty && (isSelectedChartDirty || isNewChartDirty)) {
			if (isSelectedChartDirty) {
				setConfirmChartSelection(() => () => {
					setIsSelectedChartDirty(false);
					setShowPreventChartSelectionDialog(false);
					saveDashboard(false, chartConfig);
				});
			}

			if (isNewChartDirty) {
				setConfirmChartSelection(() => () => {
					const newChartKeys = new Set(Object.keys(newChartDirtyMap));
					chartConfig = chartConfig.filter(
						(config) => !newChartKeys.has(config.viewId)
					);
					setChartConfiguration(chartConfig);
					setNewChartDirtyMap({});
					setIsSelectedChartDirty(false);
					setShowPreventChartSelectionDialog(false);
					saveDashboard(false, chartConfig);
				});
			}

			setShowPreventChartSelectionDialog(true);
			return;
		}

		const dashboardData = {
			dashboardId: dashboardId,
			dashboardName: dashboardName,
			description: dashboardDescription,
			childViewInfo: chartConfig.map((chartProperty) =>
				parseChartProperty({ ...chartProperty })
			),
		};

		setIsDashboardSaving(true);
		setIsDashboardDirty(false);

		updateDashboardMutation.mutate(dashboardData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.DashboardSaved,
				});
				setIsDashboardSaving(false);
				navigate("/analytics/dashboard/view/" + dashboardId);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
				setIsDashboardSaving(false);
				setIsDashboardDirty(true);
			},
		});
	};

	const selectChart = (
		chartId,
		chartIndex,
		checkSelectedChartDirty = true
	) => {
		if (checkSelectedChartDirty && isSelectedChartDirty) {
			setConfirmChartSelection(() => () => {
				selectChart(chartId, chartIndex, false);
				setShowPreventChartSelectionDialog(false);
			});
			setShowPreventChartSelectionDialog(true);
			return;
		}
		setSelectedChart(chartId);
		setSelectedChartIndex(chartIndex);
	};

	const discardChartSelection = () => {
		setShowPreventChartSelectionDialog(false);
		setConfirmChartSelection(null);
	};

	const selectedChartProperty = useMemo(() => {
		return (
			chartConfiguration.find(
				(config) => config.viewId === selectedChart
			) || {}
		);
	}, [selectedChart, chartConfiguration]);

	const getChartIcon = (iconName, width, height) => {
		switch (iconName) {
			case "barChart":
				return BarChartIcon(width, height);
			case "pieChart":
				return PieChartIcon(width, height);
			case "lineChart":
				return LineChartIcon(width, height);
			case "kpiCard":
				return KpiCardIcon(width, height);
			default:
				return null;
		}
	};

	return (
		<DashboardBuilderContext.Provider
			value={{
				dashboardName,
				dashboardDescription,
				setDashboardName,
				setDashboardDescription,
				chartTypeListData,
				isDashboardSaving,
				isChartTypeListDataLoading,
				chartConfiguration,
				updateChartProperties,
				getChartIcon,
				addNewChart,
				selectedChart,
				selectedChartIndex,
				selectChart,
				selectedChartProperty,
				saveDashboard,
				setIsSelectedChartDirty,
				showPreventNavigationDialog,
				confirmNavigation,
				cancelNavigation,
				showPreventChartSelectionDialog,
				confirmChartSelection,
				discardChartSelection,
				setIsDashboardDirty,
			}}
		>
			{children}
		</DashboardBuilderContext.Provider>
	);
};

export default DashboardBuilderProvider;

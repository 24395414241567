import React, { useState } from "react";
import {
	Box,
	Button,
	Divider,
	List,
	ListItem,
	ListItemButton,
	Stack,
	ToggleButton,
	Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import { default as MailIcon } from "../../../assets/icons/mail";
import { default as CallIcon } from "../../../assets/icons/call";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as MailNotificationIcon } from "../../../assets/icons/mailNotification";
import EmailComposerDrawer from "../EmailComposer/EmailComposerDrawer";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";

export default function NavigationBar() {
	const navigate = useNavigate();
	const location = useLocation();
	const [isEmailComposerOpened, setIsEmailComposerOpened] = useState(false);

	const menus = [
		{
			menu: [
				{
					name: "Team Inbox",
					path: "team-inbox",
				},
			],
		},
		{
			menu: [
				{
					name: "Awaiting Response",
					path: "awaiting-response",
				},
				{
					name: "Inbox",
					path: "inbox",
				},
				{
					name: "Sent",
					path: "sent",
				},
				{
					name: "Scheduled",
					path: "scheduled",
				},
				{
					name: "Starred",
					path: "starred",
				},
				{
					name: "Drafts",
					path: "drafts",
				},
				{
					name: "Email Templates",
					path: "email-templates",
				},
			],
		},
		{
			menu: [
				{
					name: "Archive",
					path: "archive",
				},
				{
					name: "Trash",
					path: "trash",
				},
			],
		},
		{
			name: "Bulk Email",
			menu: [
				{
					name: "Metrics",
					path: "bulk-email/metrics",
				},
				{
					name: "Bulk Emails Scheduled",
					path: "bulk-email/scheduled",
				},
				{
					name: "Bulk Email Drafts",
					path: "bulk-email/drafts",
				},
			],
		},
		{
			name: "Email Tracking",
			menu: [
				{
					name: "Opens",
					path: "email-tracking/opens",
				},
				{
					name: "Clicks",
					path: "email-tracking/clicks",
				},
				{
					name: "Bounces",
					path: "email-tracking/bounces",
				},
			],
		},
	];

	const openEmailComposer = () => {
		setIsEmailComposerOpened(true);
	};

	const closeEmailComposer = () => {
		setIsEmailComposerOpened(false);
	};

	return (
		<React.Fragment>
			{isEmailComposerOpened ? (
				<EmailComposerDrawer onClose={closeEmailComposer} />
			) : null}

			<Box
				sx={{
					minHeight: "100%",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
					px={1}
					pt={1.5}
					pb={0.5}
				>
					<Stack spacing={1.5} alignItems="center">
						<ToggleButtonGroup value="mail">
							<ToggleButton value="mail">
								{MailIcon(20, 20, "#fff")}
							</ToggleButton>
							<ToggleButton value="call">
								{CallIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
							</ToggleButton>
							<ToggleButton value="mailNotification">
								{MailNotificationIcon(
									20,
									20,
									"rgba(0, 0, 0, 0.6)"
								)}
							</ToggleButton>
						</ToggleButtonGroup>

						<Can
							permission={PERMISSIONS.email.individualEmailLimit}
						>
							<Button
								variant="contained"
								startIcon={AddIcon(20, 20, "#fff")}
								onClick={openEmailComposer}
								disableElevation
								sx={{ width: "170px", height: "40px" }}
							>
								Compose Mail
							</Button>
						</Can>
					</Stack>
				</Box>

				<Box
					px={1}
					style={{
						height: "77vh",
						overflow: "auto",
					}}
				>
					{menus.map((menu, index) => (
						<React.Fragment key={index}>
							{!!menu.name && (
								<Box pt={2} pl={2}>
									<Typography fontSize={14} fontWeight={500}>
										{menu.name}
									</Typography>
								</Box>
							)}

							<List style={{ padding: "4px 0px" }}>
								{menu.menu.map((subMenu, index) => (
									<ListItem key={index} disablePadding>
										<ListItemButton
											style={{
												height: "40px",
												backgroundColor:
													location.pathname.startsWith(
														`/mail/${subMenu.path}`
													)
														? "#E7F7F0"
														: "transparent",
												borderRadius: "8px",
											}}
											onClick={() =>
												navigate(subMenu.path)
											}
										>
											<Typography fontSize={14}>
												{subMenu.name}
											</Typography>
										</ListItemButton>
									</ListItem>
								))}
							</List>

							<Box>
								<Divider />
							</Box>
						</React.Fragment>
					))}
				</Box>
			</Box>
		</React.Fragment>
	);
}

import { Controller, useFormContext } from "react-hook-form";
import { handleFieldValidation } from "../../../../../../../../utils/fieldValidation";
import UsersMenu from "../../../../../../../Elements/UsersMenu";
import { useState } from "react";

export default function OwnerFieldComponent(params) {
	const { field } = params;

	const { control } = useFormContext();
	const [selectedUser, setSelectedUser] = useState({});

	const handleOwnerInputChange = (value, onChange) => {
		setSelectedUser(value);
		onChange(value?.value);
	};

	return (
		<>
			<Controller
				control={control}
				name={field.id + ""}
				defaultValue=""
				rules={handleFieldValidation(field)}
				render={({ field: { onChange } }) => (
					<UsersMenu
						value={selectedUser}
						onChange={(value) =>
							handleOwnerInputChange(value, onChange)
						}
					/>
				)}
			/>
		</>
	);
}

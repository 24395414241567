import React from "react";
import {
	Button,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from "@mui/material";
import Dialog from "../../Dialog";

export default function ExportDialog(props) {
	const { open, onClose, exportFile } = props;

	const fileFormat = [
		{
			id: 1,
			name: "XLSX",
		},
		{
			id: 2,
			name: "XLS",
		},
		{
			id: 3,
			name: "CSV",
		},
	];

	return (
		<React.Fragment>
			<Dialog open={open} title="Export">
				<Stack p={3} spacing={1.5}>
					<Typography fontSize={14} fontWeight={500}>
						Choose file format for export
					</Typography>

					<RadioGroup row defaultValue={fileFormat[0].name}>
						{fileFormat.map((format) => (
							<FormControlLabel
								key={format.id}
								value={format.name}
								control={
									<Radio
										style={{
											paddingTop: 0,
											paddingBottom: 0,
											paddingRight: "4px",
										}}
									/>
								}
								label={
									<Typography
										fontSize={15}
										fontWeight={400}
										style={{
											paddingRight: "16px",
										}}
									>
										{format.name}
									</Typography>
								}
							/>
						))}
					</RadioGroup>

					<Stack direction="row" spacing={2} justifyContent="end">
						<Button
							variant="contained"
							color="secondary"
							onClick={onClose}
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							color="primary"
							disableElevation
							onClick={exportFile}
						>
							Export
						</Button>
					</Stack>
				</Stack>
			</Dialog>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { default as AddIcon } from "../../../assets/icons/add";
import { Tooltip } from "../../../styles/twozo";
import Dialog from "../../Elements/Dialog";
import AddNewDashboard from "./AddNewDashboard";

export default function NavigationBar(props) {
	const theme = useTheme();
	const { menus } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const [queryParams] = useSearchParams();
	const reportName = queryParams.get("ref");

	const [openAddNewDashboard, setOpenAddNewDashboard] = useState(false);

	const toggleAddNewDashboardDialog = () => {
		setOpenAddNewDashboard((openAddNewDashboard) => !openAddNewDashboard);
	};

	return (
		<React.Fragment>
			<Dialog open={openAddNewDashboard} title="New Dashboard">
				<AddNewDashboard closeDialog={toggleAddNewDashboardDialog} />
			</Dialog>

			<Box
				sx={{
					minHeight: "100%",
					px: 1,
				}}
			>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					pt={2}
					pl={2}
				>
					<Typography fontWeight={500} fontSize={17}>
						Analytics
					</Typography>

					<Tooltip placement="bottom" title="New Dashboard">
						<IconButton onClick={toggleAddNewDashboardDialog}>
							{AddIcon(20, 20, theme.palette.primary.main)}
						</IconButton>
					</Tooltip>
				</Stack>

				<Box>
					{menus.map((menu, index) => (
						<React.Fragment key={index}>
							{!!menu.name && (
								<Box pt={2} pl={2}>
									<Typography fontSize={14} fontWeight={500}>
										{menu.name}
									</Typography>
								</Box>
							)}

							<List>
								{menu.menu.map((subMenu, index) => (
									<ListItem key={index} disablePadding>
										<ListItemButton
											style={{
												height: "40px",
												backgroundColor:
													location.pathname.startsWith(
														`/analytics/dashboard/list/${subMenu.path}`
													) ||
													subMenu.path === reportName
														? "#E7F7F0"
														: "transparent",
												borderRadius: "8px",
											}}
											onClick={() =>
												navigate(
													`/analytics/dashboard/list/${subMenu.path}`
												)
											}
										>
											<Typography fontSize={14}>
												{subMenu.name}
											</Typography>
										</ListItemButton>
									</ListItem>
								))}
							</List>

							<Box>
								<Divider />
							</Box>
						</React.Fragment>
					))}
				</Box>
			</Box>
		</React.Fragment>
	);
}

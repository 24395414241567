import React, { useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDownCentered";
import {
	TableCellText,
	TableHeaderLabel,
	hexToRgba,
} from "../../../../../styles/twozo";
import UnKnownImage from "../../../../../assets/images/contact/unknownContact.png";
import { useLinkUserMutation } from "../../../../../hooks/services/userManagement/roles";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { useUserDetailsList } from "../../../../../hooks/services/userManagement/user";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

export default function AssignUser(props) {
	const { onClose, selectedRole } = props;
	const theme = useTheme();
	const [selectedUser, setSelectedUser] = useState([]);

	const {
		data: userList,
		isLoading: isUserListLoading,
		hasNextPage,
		fetchNextPage,
		isFetching,
	} = useUserDetailsList();

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const linkUserMutation = useLinkUserMutation();

	const isSomeUsersSelected =
		selectedUser.length > 0 && selectedUser.length < userList?.length;
	const isAllUsersSelected =
		userList?.length > 0 && selectedUser.length === userList?.length;

	const handleSelectAllUsers = (event) => {
		if (event.target.checked) {
			const newSelected = userList.map((user) => user.id);
			setSelectedUser(newSelected);
		} else {
			setSelectedUser([]);
		}
	};

	const isUserSelected = (id) => selectedUser.indexOf(id) !== -1;

	const handleSelectUser = (event, id) => {
		event.stopPropagation();
		const selectedIndex = selectedUser.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedUser, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedUser.slice(1));
		} else if (selectedIndex === selectedUser.length - 1) {
			newSelected = newSelected.concat(selectedUser.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selectedUser.slice(0, selectedIndex),
				selectedUser.slice(selectedIndex + 1)
			);
		}
		setSelectedUser(newSelected);
	};

	const getTotalUserCount = () => {
		if (userList?.length > 9) {
			return userList.length;
		}
		return `0${userList?.length}`;
	};

	const isAnyUserSelected = () => {
		if (selectedUser.length > 0) {
			return true;
		}
		return false;
	};

	const assignUser = () => {
		let requestData = {
			roleId: selectedRole.id,
			usersId: selectedUser,
		};
		if (isAnyUserSelected()) {
			linkUserMutation.mutate(requestData, {
				onSuccess: () => {
					onClose();
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.userAssigned,
					});
				},
				onError: (error) => {
					let errorMessage = error?.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: errorMessage
							? errorMessage
							: notificationMessage.genericErrorMessage,
					});
				},
			});
		}
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontSize={17} fontWeight={600}>
							Assign User
						</Typography>

						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(24, 24, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					px={2}
					py={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
					}}
				>
					<Box sx={{ height: "100%" }}>
						<Stack pb={2} sx={{ height: "10%" }} spacing={"5px"}>
							<Typography fontSize={15} fontWeight={600}>
								{selectedRole?.name}
							</Typography>
							<Typography
								fontSize={14}
								color="rgba(0, 0, 0, 0.6)"
							>
								Assign Users to this Role
							</Typography>
						</Stack>

						<Box
							sx={{
								border: "1px solid rgba(0, 0, 0, 0.1)",
								borderRadius: "8px",
								height: "90%",
								overflowY: "auto",
							}}
						>
							{isUserListLoading ? (
								<Stack
									height="100%"
									justifyContent="center"
									alignItems="center"
									width="100%"
								>
									<CircularProgress />
								</Stack>
							) : (
								<TableContainer sx={{ height: "100%" }}>
									<Table stickyHeader size="small">
										<TableHead>
											<TableRow sx={{ height: "36px" }}>
												<TableCell width="10%">
													<Checkbox
														indeterminate={
															isSomeUsersSelected
														}
														checked={
															isAllUsersSelected
														}
														onChange={
															handleSelectAllUsers
														}
													/>
												</TableCell>

												<TableCell width="30%">
													<Stack
														direction="row"
														alignItems="center"
														spacing={0.5}
													>
														<TableHeaderLabel>
															User
														</TableHeaderLabel>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.5)"
														)}
													</Stack>
												</TableCell>

												<TableCell width="30%">
													<Stack
														direction="row"
														alignItems="center"
														spacing={0.5}
													>
														<TableHeaderLabel>
															Email
														</TableHeaderLabel>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.5)"
														)}
													</Stack>
												</TableCell>

												<TableCell width="30%">
													<Stack
														direction="row"
														alignItems="center"
														spacing={0.5}
													>
														<TableHeaderLabel>
															Role
														</TableHeaderLabel>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.5)"
														)}
													</Stack>
												</TableCell>
											</TableRow>
										</TableHead>

										<TableBody>
											{userList?.map((user) => (
												<TableRow
													key={user.id}
													selected={isUserSelected(
														user.id
													)}
												>
													<TableCell width="10%">
														<Checkbox
															checked={isUserSelected(
																user.id
															)}
															onClick={(event) =>
																handleSelectUser(
																	event,
																	user.id
																)
															}
														/>
													</TableCell>

													<TableCell width="30%">
														<Stack
															direction="row"
															alignItems="center"
															spacing={1}
														>
															<img
																src={
																	UnKnownImage
																}
																alt="img"
																width={26}
																height={26}
															/>
															<TableCellText>
																{user.name}
															</TableCellText>
														</Stack>
													</TableCell>

													<TableCell width="30%">
														<TableCellText>
															{user.email}
														</TableCellText>
													</TableCell>

													<TableCell width="30%">
														<TableCellText
															fontWeight={500}
														>
															{user.role.name}
														</TableCellText>
													</TableCell>
												</TableRow>
											))}
											<TableRow>
												<TableCell
													colSpan={4}
													sx={{
														borderBottom: "none",
													}}
												>
													<Box ref={lastRowRef}>
														{!isUserListLoading &&
															isFetching && (
																<Stack
																	direction="row"
																	alignItems="center"
																	justifyContent="center"
																	spacing={1}
																	py={2}
																>
																	<CircularProgress
																		size={
																			18
																		}
																	/>

																	<Typography
																		fontSize={
																			12
																		}
																	>
																		Loading
																		More
																		Data
																	</Typography>
																</Stack>
															)}
													</Box>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							)}
						</Box>
					</Box>

					{!isUserListLoading ? (
						<Stack
							direction="row"
							justifyContent="flex-end"
							py={1}
							px={2}
							sx={{
								position: "fixed",
								bottom: "108px",
								right: "40px",
								backgroundColor: theme.palette.secondary.main,
								borderRadius: "8px",
							}}
						>
							<Typography
								style={{
									fontSize: "14px",
									fontWeight: "500",
									color: "rgba(0, 0, 0, 0.6)",
								}}
							>
								Total: {getTotalUserCount()}
							</Typography>
						</Stack>
					) : null}
				</Box>

				<Divider />

				<Box p={2}>
					<Stack direction="row" spacing={1}>
						<Button
							variant="contained"
							disableElevation
							onClick={assignUser}
							disabled={linkUserMutation.isLoading}
							style={{
								color: isAnyUserSelected()
									? "#FFFFFF"
									: hexToRgba("#FFFFFF", 0.6),
							}}
						>
							Assign ({selectedUser.length})
						</Button>

						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={onClose}
							disabled={linkUserMutation.isLoading}
						>
							Cancel
						</Button>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}

import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { getNonce } from "../../../utils/nonce";

const cspNonce = getNonce();

export default function DragDropContextWithNonce(props) {
	const { onBeforeCapture, onDragEnd, children } = props;

	return (
		<React.Fragment>
			<DragDropContext
				onBeforeCapture={onBeforeCapture}
				onDragEnd={onDragEnd}
				nonce={cspNonce}
			>
				{children}
			</DragDropContext>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import {
	Box,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import Menu from "../../../Elements/Menu";
import { default as DragIcon } from "../../../../assets/icons/drag";
import { default as AddIcon } from "../../../../assets/icons/add";
import { default as MoreIcon } from "../../../../assets/icons/more";
import Stage from "./Stage";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import {
	useDefaultPipeline,
	useDeletePipeline,
	useUpdatePipelineStageOrder,
} from "../../../../hooks/services/deal/pipeline";
import DragDropContextWithNonce from "../../../Elements/DragDropContextWithNonce";
import { MenuItemText } from "../../../../styles/twozo";

export default function Pipeline(props) {
	const {
		pipeline,
		handlePipelineChange,
		openEditPipelineDialog,
		onAddStageClicked,
		updateIsPipelineInEditMode,
	} = props;

	const theme = useTheme();
	const stageLimitCount = 15;
	const isStageLimitNotExceeded = pipeline.stages.length < stageLimitCount;

	// mutation call:-
	const deleteMutation = useDeletePipeline();
	const updatePipelineStageOrderMutation = useUpdatePipelineStageOrder();
	const updateDefaultPipelineMutation = useDefaultPipeline();

	const [pipelineMenuElement, setPipelineMenuElement] = useState(null);
	const isPipelineMenuOpened = Boolean(pipelineMenuElement);
	const [pipelineHeaderHovered, setPipelineHeaderHovered] = useState(false);
	const [isDeletePipelineDialogOpen, setIsDeletePipelineDialogOpen] =
		useState(false);
	const [pipelineId, setPipelineId] = useState(null);
	const [isHideEditMoreIcon, setIsHideEditMoreIcon] = useState(false);

	const handleOpenPipelineMenu = (event) => {
		setPipelineMenuElement(event.currentTarget);
	};

	const closePipelineMenu = () => {
		setPipelineMenuElement(null);
	};

	const editPipeline = () => {
		closePipelineMenu();
		openEditPipelineDialog();
		updateIsPipelineInEditMode(true);
	};

	const handleOpenDeletePipelineDialog = (id) => {
		closePipelineMenu();
		setIsDeletePipelineDialogOpen(true);
		setPipelineId(id);
	};

	const closeDeletePipelineDialog = () => {
		setIsDeletePipelineDialogOpen(false);
	};

	const handleDeletePipeline = () => {
		deleteMutation.mutate(pipelineId, {
			onSuccess: () => {
				setIsDeletePipelineDialogOpen(false);
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.pipelineDeleted,
				});
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					title: "Failed!",
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const getPipelineStageOrderRequest = (stages, pipelineId) => {
		let filteredDraggableStages = stages
			.filter((stage) => stage.isDraggable)
			.map((pipeline) => pipeline.id);

		let requestData = {};
		requestData.ids = filteredDraggableStages;
		requestData.pipelineId = pipelineId;

		return requestData;
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onStageDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		// default stages
		let defaultStage = 0;
		let wonStage = pipeline.stages.length - 2;
		let lostStage = pipeline.stages.length - 1;

		// default stages should not be draggable
		if (
			result.destination.index === defaultStage ||
			result.destination.index === lostStage ||
			result.destination.index === wonStage
		) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const updatedData = reorder(
			pipeline.stages,
			result.source.index,
			result.destination.index
		);

		// update the pipeline stage order
		let requestData = getPipelineStageOrderRequest(
			updatedData,
			pipeline.id
		);
		updatePipelineStageOrderMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.pipelineStageUpdated,
				});
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});

		handlePipelineChange({ ...pipeline, stages: updatedData });
	};

	const handleUpdateDefaultPipeline = (pipelineId, isDefault) => {
		if (!isDefault) {
			let requestData = getDefaultPipelineRequest(pipelineId, isDefault);
			updateDefaultPipelineMutation.mutate(requestData, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.pipelineUpdated,
					});
					closePipelineMenu();
				},
				onError: (error) => {
					let errorMessage = error?.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						message:
							errorMessage ?? notificationMessage.errorMessage,
					});
				},
			});
		} else {
			closePipelineMenu();
		}
	};

	const getDefaultPipelineRequest = (pipelineId, isDefault) => {
		let request = {};
		request.id = pipelineId;
		request.isDefault = !isDefault;

		return request;
	};

	const onClickAddStage = () => {
		onAddStageClicked();
	};

	return (
		<React.Fragment>
			<DeleteDialog
				open={isDeletePipelineDialogOpen}
				onCancel={closeDeletePipelineDialog}
				onDelete={handleDeletePipeline}
				disableConfirmButton={deleteMutation.isLoading}
				title={`Are you sure you want to delete "${pipeline.name}"?`}
				subtitle="All the deals in this pipeline will be moved to the General Pipeline's New stage"
			/>

			<Menu
				minWidth="200px"
				anchorEl={pipelineMenuElement}
				open={isPipelineMenuOpened}
				onClose={closePipelineMenu}
				style={{
					marginTop: "4px",
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				{!pipeline.isDefault && (
					<MenuItem
						onClick={() =>
							handleUpdateDefaultPipeline(
								pipeline.id,
								pipeline.isDefault
							)
						}
						style={{ height: "40px" }}
					>
						<MenuItemText
							fontWeight={500}
							color={theme.palette.secondary.contrastText}
						>
							Mark as default pipeline
						</MenuItemText>
					</MenuItem>
				)}

				<MenuItem onClick={editPipeline} style={{ height: "40px" }}>
					<MenuItemText
						fontWeight={500}
						color={theme.palette.secondary.contrastText}
					>
						Edit
					</MenuItemText>
				</MenuItem>

				{!pipeline.isDefault && (
					<MenuItem
						onClick={() =>
							handleOpenDeletePipelineDialog(pipeline.id)
						}
						style={{ height: "40px" }}
					>
						<MenuItemText
							fontWeight={500}
							color={theme.palette.secondary.contrastText}
						>
							Delete
						</MenuItemText>
					</MenuItem>
				)}
			</Menu>

			<Box
				style={{
					height: "100%",
					width: "inherit",
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
					backgroundColor: "#fff",
				}}
			>
				<Box
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						onMouseEnter={() => setPipelineHeaderHovered(true)}
						onMouseLeave={() => setPipelineHeaderHovered(false)}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							spacing={1}
							pl={2}
							pr={1}
							py={2}
						>
							<Stack
								direction="row"
								alignItems="center"
								spacing={2}
							>
								<Box
									display="flex"
									{...props.dragHandleProps}
									sx={{
										pointerEvents: pipeline.isDefault
											? "none"
											: "auto",
									}}
								>
									{DragIcon(
										24,
										24,
										"grey",
										pipeline.isDefault ? 0.5 : 1
									)}
								</Box>

								<Stack>
									<Typography
										width="200px"
										fontSize={15}
										fontWeight={500}
										noWrap
									>
										{pipeline.name}
									</Typography>
									<Typography fontSize={14}>
										Deals rots after {pipeline.rottenDays}{" "}
										days
									</Typography>
								</Stack>
							</Stack>

							<Box
								hidden={
									!pipelineHeaderHovered &&
									!isPipelineMenuOpened
								}
							>
								<Box>
									<IconButton
										size="small"
										onClick={handleOpenPipelineMenu}
										style={{
											backgroundColor:
												theme.palette.secondary.main,
										}}
									>
										{MoreIcon(
											24,
											24,
											theme.palette.primary.main
										)}
									</IconButton>
								</Box>
							</Box>
						</Stack>
					</Box>

					<Divider />

					<Box
						p={1}
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<DragDropContextWithNonce onDragEnd={onStageDragEnd}>
							<Droppable droppableId="stages">
								{(provided) => (
									<div
										ref={provided.innerRef}
										{...provided.droppableProps}
									>
										{pipeline.stages.map((stage, index) => (
											<Draggable
												draggableId={stage?.id?.toString()}
												index={index}
												key={stage.id}
											>
												{(provided) => (
													<Box
														ref={provided.innerRef}
														{...provided.draggableProps}
														pb={
															!isStageLimitNotExceeded &&
															pipeline.stages
																.length -
																1 ===
																index
																? 0
																: 1
														}
													>
														<Stage
															key={stage.id}
															stage={stage}
															updateIsHideEditMoreIcon={
																setIsHideEditMoreIcon
															}
															isHideEditMoreIcon={
																isHideEditMoreIcon
															}
															dragHandleProps={
																provided.dragHandleProps
															}
														/>
													</Box>
												)}
											</Draggable>
										))}

										{provided.placeholder}

										{isStageLimitNotExceeded && (
											<Box
												py={1.5}
												px={2.5}
												style={{
													border: "1px solid rgba(0, 0, 0, 0.1)",
													borderRadius: "8px",
													cursor: "pointer",
												}}
												onClick={onClickAddStage}
											>
												<Stack
													direction="row"
													alignItems="center"
													spacing={2.5}
												>
													{AddIcon(
														24,
														24,
														theme.palette.secondary
															.contrastText
													)}

													<Typography
														fontSize={14}
														fontWeight={500}
														color={
															theme.palette
																.secondary
																.contrastText
														}
													>
														Add Stage
													</Typography>
												</Stack>
											</Box>
										)}
									</div>
								)}
							</Droppable>
						</DragDropContextWithNonce>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}

import {
	Box,
	IconButton,
	InputAdornment,
	TableCell,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { default as MoreIcon } from "../../../../../assets/icons/more";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { default as AddIcon } from "../../../../../assets/icons/add";
import { TextButton } from "../../../../../styles/twozo";

const styles = {
	noActionTableCell: {
		borderBottom: "none",
		cursor: "default",
	},
};

export const StageList = (props) => {
	const { stages = [], updateStages } = props;
	const theme = useTheme();

	// Default stages count
	const numberOfWonAndLostStages = 2;

	const stageLimitCount = 15;
	const isStageLimitExceeded = stages.length >= stageLimitCount;

	const [selectedTableCellId, setSelectedTableCellId] = useState("");
	const [stageEditRestrictionIndex, setStageEditRestrictionIndex] =
		useState();
	const [isStageEditRestrictionEnabled, setIsStageEditRestrictionEnabled] =
		useState(false);

	useEffect(() => {
		let filteredEmptyStages = stages
			.filter((stage) => stage.name === "")
			.map((stage) => stage.id);
		let stageIds = stages.map((stage) => stage.id);
		let emptyStageIndex = stageIds.indexOf(filteredEmptyStages?.[0]);

		if (filteredEmptyStages.length > 0) {
			setSelectedTableCellId(`name_${emptyStageIndex}`);
			setStageEditRestrictionIndex(emptyStageIndex);
			setIsStageEditRestrictionEnabled(true);
		} else {
			setStageEditRestrictionIndex(null);
			setIsStageEditRestrictionEnabled(false);
		}
	}, [stages]);

	const handleChangeStageName = (event, index) => {
		const { value } = event.target;

		let updatedPipelineStages = stages.map(
			(pipelineStage, pipelineStageIndex) => {
				if (index === pipelineStageIndex) {
					return { ...pipelineStage, name: value };
				}

				return pipelineStage;
			}
		);

		updateStages(updatedPipelineStages);
	};

	const handleChangeStageProbability = (event, index) => {
		const { value } = event.target;
		const maxProbabilityValue = 100;
		const newValue = value === "" ? "" : Number(value);

		if (newValue <= maxProbabilityValue && newValue >= 0) {
			let updatedPipelineStages = stages.map(
				(pipelineStage, pipelineStageIndex) => {
					if (index === pipelineStageIndex) {
						return { ...pipelineStage, probability: newValue };
					}

					return pipelineStage;
				}
			);
			updateStages(updatedPipelineStages);
		}
	};

	const isEditableStage = (stage, stageIndex) => {
		if (isWonOrLostStage(stage)) {
			return true;
		}

		return (
			stageEditRestrictionIndex !== stageIndex &&
			isStageEditRestrictionEnabled
		);
	};

	const handleRemovePipelineStage = (stage, index) => {
		if (isEditableStage(stage, index)) {
			return;
		}

		let updatedPipelineStages = [...stages];
		updatedPipelineStages.splice(index, 1);
		updateStages(updatedPipelineStages);
		setSelectedTableCellId(null);
	};

	// Function to check if a stage is either won or lost
	const isWonOrLostStage = (stage) => {
		// Return true if the stage is not the default stage and is not editable
		return !stage?.isDefault && !stage?.isEditable;
	};

	const isTableCellSelected = (key, stageIndex) => {
		return selectedTableCellId === `${key}_${stageIndex}`;
	};

	const addPipelineStage = () => {
		if (isStageEditRestrictionEnabled) {
			return;
		}

		let emptyPipelineStage = {
			name: "",
			isNewStage: true,
			probability: 100,
			id: `id ${stages.length - numberOfWonAndLostStages}`,
			isEditable: true,
			isDefault: false,
		};

		let updatedPipelineStages = [
			...stages.slice(0, stages.length - numberOfWonAndLostStages),
			emptyPipelineStage,
			...stages.slice(stages.length - numberOfWonAndLostStages),
		];
		updateStages(updatedPipelineStages);
	};

	const highlightSelectedPipelineStage = (event) => {
		setSelectedTableCellId(event.currentTarget.id);
	};

	const isDefaultStage = (stage) => {
		return isWonOrLostStage(stage) || (stage.isDefault && stage.isEditable);
	};

	const getStylesForTextTableCell = (stage, stageIndex, name) => {
		let lastStage = stageIndex === stages.length - 1;

		if (isStageLimitExceeded && lastStage) {
			return styles.noActionTableCell;
		}

		return {
			paddingRight: "8px",
			pointerEvents: isEditableStage(stage, stageIndex) ? "none" : "auto",
			borderBottom:
				isTableCellSelected(name, stageIndex) &&
				stage.isEditable &&
				`2px solid ${theme.palette.primary.main}`,
		};
	};

	const getStylesForIconTableCell = (stageIndex) => {
		let lastStage = stageIndex === stages.length - 1;

		if (isStageLimitExceeded && lastStage) {
			return styles.noActionTableCell;
		}

		return {};
	};

	return (
		<React.Fragment>
			{stages.map((stage, stageIndex) => (
				<TableRow key={stageIndex}>
					<TableCell sx={getStylesForIconTableCell(stageIndex)}>
						<IconButton size="small">
							{MoreIcon(20, 20, "#5C6360")}
						</IconButton>
					</TableCell>

					<TableCell
						id={`name_${stageIndex}`}
						onClick={(event) =>
							highlightSelectedPipelineStage(event, stageIndex)
						}
						sx={getStylesForTextTableCell(
							stage,
							stageIndex,
							"name"
						)}
					>
						<TextField
							variant="standard"
							inputProps={{
								style: {
									fontSize: "14px",
									fontWeight: 400,
									padding: 0,
									color: isWonOrLostStage(stage) && "#666666",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									overflow: "hidden",
								},
							}}
							InputProps={{
								disableUnderline: true,
								placeholder: "Enter Stage Name",
								endAdornment: stage.isDefault &&
									stage.isEditable && (
										<InputAdornment>
											<Box
												p={0.5}
												ml={1}
												style={{
													backgroundColor: "#fff",
													borderRadius: "8px",
												}}
											>
												<Typography
													color={
														theme.palette.primary
															.main
													}
													fontSize={14}
													fontWeight={500}
												>
													Default
												</Typography>
											</Box>
										</InputAdornment>
									),
							}}
							onChange={(event) =>
								handleChangeStageName(event, stageIndex)
							}
							value={stages[stageIndex].name || ""}
							autoComplete="off"
						/>
					</TableCell>

					<TableCell
						align="right"
						id={`probability_${stageIndex}`}
						onClick={(event) =>
							highlightSelectedPipelineStage(event, stageIndex)
						}
						sx={getStylesForTextTableCell(
							stage,
							stageIndex,
							"probability"
						)}
					>
						<TextField
							variant="standard"
							sx={{
								"& .MuiInput-underline:before": {
									borderBottomColor: "rgba(0, 0, 0, 0.1)",
								},
							}}
							inputProps={{
								style: {
									fontSize: "14px",
									fontWeight: 400,
									textAlign: "right",
									padding: 0,
									color: isWonOrLostStage(stage) && "#666666",
								},
							}}
							InputProps={{
								disableUnderline: true,
								placeholder: "Probability",
							}}
							autoComplete="off"
							onChange={(event) =>
								handleChangeStageProbability(event, stageIndex)
							}
							value={stages[stageIndex].probability}
						/>
					</TableCell>

					<TableCell sx={getStylesForIconTableCell(stageIndex)}>
						{isDefaultStage(stage) ? null : (
							<Box
								display="flex"
								onClick={() =>
									handleRemovePipelineStage(stage, stageIndex)
								}
							>
								<IconButton
									size="small"
									style={{
										cursor: isEditableStage(
											stage,
											stageIndex
										)
											? "not-allowed"
											: "pointer",
									}}
								>
									{CloseIcon(20, 20, "#5C6360")}
								</IconButton>
							</Box>
						)}
					</TableCell>
				</TableRow>
			))}
			{isStageLimitExceeded ? null : (
				<TableRow>
					<TableCell sx={styles.noActionTableCell} />

					<TableCell sx={styles.noActionTableCell}>
						<TextButton
							onClick={addPipelineStage}
							style={{
								opacity: isStageEditRestrictionEnabled
									? 0.6
									: 1,
								cursor: isStageEditRestrictionEnabled
									? "not-allowed"
									: "pointer",
							}}
							size="small"
							startIcon={AddIcon(
								13,
								13,
								theme.palette.secondary.contrastText
							)}
						>
							Add Stage
						</TextButton>
					</TableCell>
				</TableRow>
			)}
		</React.Fragment>
	);
};

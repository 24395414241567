export default function TextPickersParser(field) {
	return field.values.map((value) => {
		const { dependsOn, value: fieldValue } = value;

		const result = {};

		if (dependsOn) {
			result.dependsOn = dependsOn;
			result.value = fieldValue?.id || fieldValue?.value || fieldValue;
		} else {
			result.value = fieldValue?.name || fieldValue;
		}

		return result;
	});
}

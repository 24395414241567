import { Checkbox, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Field from "../../../../Field";

export default function SingleFieldComponent(params) {
	const {
		metadata,
		actionField,
		error,
		updateFieldValue,
		toggleEnableField,
		formValues,
		fieldNameStyle = {},
		fieldValueStyle = {},
		clearError,
		values,
	} = params;

	return (
		<React.Fragment>
			<Grid item xs={12} sm={6}>
				<Stack
					direction="row"
					alignItems="center"
					spacing={0.5}
					px={1}
					style={{
						borderRadius: "8px",
						border: "1px solid rgba(0, 0, 0, 0.1)",
						backgroundColor: "#fff",
						minHeight: 42,
						opacity:
							actionField?.config?.isRequired ||
							actionField?.isEnabled
								? 1
								: 0.5,
						...fieldNameStyle,
					}}
				>
					<Checkbox
						onChange={(event) =>
							toggleEnableField(event.target.checked)
						}
						disabled={actionField?.config?.isRequired}
						checked={
							actionField?.config?.isRequired ||
							actionField?.isEnabled ||
							false
						}
					/>

					<Typography fontSize={15} maxWidth="300px" noWrap>
						{actionField?.displayName}
					</Typography>
				</Stack>
			</Grid>

			<Grid
				item
				xs={12}
				sm={6}
				style={{
					opacity:
						actionField?.config?.isRequired ||
						actionField?.isEnabled
							? 1
							: 0.5,
					pointerEvents:
						actionField?.config?.isRequired ||
						actionField?.isEnabled
							? "auto"
							: "none",
				}}
			>
				<Field
					value={values}
					formValues={formValues}
					field={actionField}
					error={error}
					metadata={metadata}
					style={{ ...fieldValueStyle }}
					updateFieldValue={updateFieldValue}
					clearError={clearError}
				/>
			</Grid>
		</React.Fragment>
	);
}

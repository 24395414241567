import React from "react";
import { Typography } from "@mui/material";

export default function EmailReplied(props) {
	const { notificationData, notificationContentStyle } = props;

	if (!notificationData) {
		return null;
	}

	return (
		<React.Fragment>
			<Typography
				component="span"
				style={{ ...notificationContentStyle, opacity: "0.6" }}
			>
				“
				{notificationData.data.subject
					? notificationData.data.subject
					: "(No Subject)"}
				”
			</Typography>
		</React.Fragment>
	);
}

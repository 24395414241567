import React from "react";
import { TextField } from "@mui/material";

export default function EmailComponent(props) {
	const {
		style = {},
		field = {},
		updateFieldValue,
		error,
		value,
		clearError,
		...rest
	} = props;

	const handleChange = (event) => {
		const { value } = event.target;
		updateFieldValue([{ value: value || "" }], field.fieldId);
		clearError(field.fieldId);
	};

	return (
		<React.Fragment>
			<TextField
				{...rest}
				placeholder="-Enter-"
				defaultValue={value ? value?.value : ""}
				sx={{
					backgroundColor: "#fff",
					borderRadius: 2,
					...style,
				}}
				inputProps={{
					style: {
						fontSize: "15px",
					},
				}}
				onChange={handleChange}
				error={error?.error}
				fullWidth
			/>
		</React.Fragment>
	);
}

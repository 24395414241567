import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControlLabel,
	FormGroup,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropdownIcon } from "../../../../../assets/icons/dropDown";
import { default as mailIcon } from "../../../../../assets/icons/mail";
import { default as notificationIcon } from "../../../../../assets/icons/notification";
import Menu from "../../../../Elements/Menu";
import {
	useNotificationSettingsData,
	useResetNotificationSettings,
	useUpdateNotificationSettings,
} from "../../../../../hooks/services/notification/notificationSettings";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { MenuItemText } from "../../../../../styles/twozo";

const styles = {
	configTitle: {
		fontSize: "15px",
		fontWeight: 600,
	},
	configText: {
		fontSize: "15px",
		fontWeight: 400,
		paddingLeft: "16px",
	},
};

export default function Notifications() {
	const theme = useTheme();

	const [notificationSettingsConfig, setNotificationSettingsConfig] =
		useState({});
	const [selectedReminderSettingName, setSelectedReminderSettingName] =
		useState("");
	const [reminderMenuElement, setReminderMenuElement] = useState(null);
	const isReminderMenuOpened = Boolean(reminderMenuElement);

	const {
		data: notificationSettingsData,
		status: notificationSettingsDataStatus,
		isLoading: isNotificationSettingsDataLoading,
	} = useNotificationSettingsData();
	const updateNotificationMutation = useUpdateNotificationSettings();
	const resetNotificationMutation = useResetNotificationSettings();

	useEffect(() => {
		if (notificationSettingsDataStatus === "success") {
			setNotificationSettingsConfig(notificationSettingsData);
		}
	}, [notificationSettingsData, notificationSettingsDataStatus]);

	const reminderOptions = [
		{
			name: 15,
			value: 1,
		},
		{
			name: 30,
			value: 2,
		},
		{
			name: 45,
			value: 3,
		},
		{
			name: 60,
			value: 4,
		},
	];

	const notificationConfig = {
		emailAlert: {
			settingName: "emailAlert",
			settingTypes: {
				isActivityAssigned: "isActivityAssigned",
				isModifiedActivity: "isModifiedActivity",
				isSourceAssigned: "isSourceAssigned",
				isSourceTransferred: "isSourceTransferred",
			},
		},
		summary: {
			settingName: "summary",
			settingTypes: {
				isAssigned: "isAssigned",
				isTransferred: "isTransferred",
			},
		},
		assignment: {
			settingName: "assignment",
			settingTypes: {
				isSourceAssigned: "isSourceAssigned",
				isActivityAssigned: "isActivityAssigned",
			},
		},
		tracking: {
			settingName: "tracking",
			settingTypes: {
				isEmailOpened: "isEmailOpened",
				isEmailLinkClicked: "isEmailLinkClicked",
				isRepliedEmail: "isRepliedEmail",
				isTeamInboxEmail: "isTeamInboxEmail",
			},
		},
		activity: {
			settingName: "activity",
		},
		email: {
			settingName: "email",
		},
	};

	const openReminderMenu = (event, reminderTypeName) => {
		setReminderMenuElement(event.currentTarget);
		setSelectedReminderSettingName(reminderTypeName);
	};

	const closeReminderMenu = () => {
		setReminderMenuElement(null);
		setSelectedReminderSettingName("");
	};

	const selectReminder = (value) => {
		setNotificationSettingsConfig((notificationSettingsConfig) => {
			return {
				...notificationSettingsConfig,
				reminder: {
					...notificationSettingsConfig.reminder,
					[selectedReminderSettingName]: {
						...notificationSettingsConfig.reminder[
							selectedReminderSettingName
						],
						time: value,
					},
				},
			};
		});
		closeReminderMenu();
	};

	const getMenuBackgroundColor = (reminder) => {
		if (
			notificationSettingsConfig?.reminder?.[selectedReminderSettingName]
				?.time === reminder
		) {
			return theme.palette.secondary.main;
		} else {
			return "transparent";
		}
	};

	const handleNotificationConfigChange = (
		event,
		settingType,
		settingName
	) => {
		const { checked } = event.target;

		setNotificationSettingsConfig((notificationSettingsConfig) => {
			return {
				...notificationSettingsConfig,
				[settingType]: {
					...notificationSettingsConfig[settingType],
					[settingName]: checked,
				},
			};
		});
	};

	const handleReminderConfigChange = (event, reminderName) => {
		const { checked } = event.target;

		setNotificationSettingsConfig((notificationSettingsConfig) => {
			return {
				...notificationSettingsConfig,
				reminder: {
					...notificationSettingsConfig.reminder,
					[reminderName]: {
						...notificationSettingsConfig.reminder[reminderName],
						isEnabled: checked,
					},
				},
			};
		});
	};

	const handleSaveNotificationSettings = () => {
		updateNotificationMutation.mutate(notificationSettingsConfig, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.notificationSettingsSaved,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleResetNotificationSettings = () => {
		resetNotificationMutation.mutate(
			{},
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.notificationSettingsReset,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.somethingWentWrong,
					});
				},
			}
		);
	};

	const getReminderNameById = (reminderValue) => {
		return reminderOptions.find(
			(reminderOption) => reminderOption.value === reminderValue
		)?.name;
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="102px"
				anchorEl={reminderMenuElement}
				open={isReminderMenuOpened}
				onClose={closeReminderMenu}
				style={{
					marginTop: "8px",
				}}
			>
				{reminderOptions.map((reminder) => (
					<MenuItem
						key={reminder.name}
						style={{
							height: "40px",
							backgroundColor: getMenuBackgroundColor(
								reminder.name
							),
						}}
						onClick={() => selectReminder(reminder.value)}
					>
						<MenuItemText
							color={theme.palette.secondary.contrastText}
						>
							{reminder.name}
						</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			{isNotificationSettingsDataLoading ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="80vh"
				>
					<CircularProgress />
				</Stack>
			) : (
				<Box
					style={{
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
					}}
					m={1}
					px={2}
				>
					<Stack spacing={1} pt={3} pb={1.5}>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							px={0.4}
						>
							{mailIcon(18, 18)}
							<Typography sx={styles.configTitle}>
								Email Alerts
							</Typography>
						</Stack>

						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={
											!!notificationSettingsConfig
												?.emailAlert?.isActivityAssigned
										}
									/>
								}
								label={
									<Typography sx={styles.configText}>
										Get email when an activity is assigned
										to you
									</Typography>
								}
								style={{
									marginBottom: "10px",
									paddingLeft: "11px",
									width: "fit-content",
								}}
								onChange={(event) =>
									handleNotificationConfigChange(
										event,
										notificationConfig.emailAlert
											.settingName,
										notificationConfig.emailAlert
											.settingTypes.isActivityAssigned
									)
								}
							/>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											!!notificationSettingsConfig
												?.emailAlert?.isModifiedActivity
										}
									/>
								}
								label={
									<Typography sx={styles.configText}>
										Get email when you create or modify an
										activity
									</Typography>
								}
								style={{
									marginBottom: "10px",
									paddingLeft: "11px",
									width: "fit-content",
								}}
								onChange={(event) =>
									handleNotificationConfigChange(
										event,
										notificationConfig.emailAlert
											.settingName,
										notificationConfig.emailAlert
											.settingTypes.isModifiedActivity
									)
								}
							/>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											!!notificationSettingsConfig
												?.emailAlert?.isSourceAssigned
										}
									/>
								}
								label={
									<Typography sx={styles.configText}>
										Get email when a contact, company, deal
										or product is assigned to you
									</Typography>
								}
								style={{
									marginBottom: "10px",
									paddingLeft: "11px",
									width: "fit-content",
								}}
								onChange={(event) =>
									handleNotificationConfigChange(
										event,
										notificationConfig.emailAlert
											.settingName,
										notificationConfig.emailAlert
											.settingTypes.isSourceAssigned
									)
								}
							/>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											!!notificationSettingsConfig
												?.emailAlert
												?.isSourceTransferred
										}
									/>
								}
								label={
									<Typography sx={styles.configText}>
										Get email when a contact, company, deal
										or product is transferred from you
									</Typography>
								}
								style={{
									marginBottom: "10px",
									paddingLeft: "11px",
									width: "fit-content",
								}}
								onChange={(event) =>
									handleNotificationConfigChange(
										event,
										notificationConfig.emailAlert
											.settingName,
										notificationConfig.emailAlert
											.settingTypes.isSourceTransferred
									)
								}
							/>
						</FormGroup>
					</Stack>

					<Divider />

					<Stack spacing={1} pt={3} pb={1.5}>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							px={0.4}
						>
							{mailIcon(18, 18)}
							<Typography sx={styles.configTitle}>
								Daily Email Digest
							</Typography>
						</Stack>

						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={
											!!notificationSettingsConfig
												?.summary?.isAssigned
										}
									/>
								}
								label={
									<Typography sx={styles.configText}>
										Get daily email summary of contacts,
										companies, deals and products assigned
										to you
									</Typography>
								}
								style={{
									marginBottom: "10px",
									paddingLeft: "11px",
									width: "fit-content",
								}}
								onChange={(event) =>
									handleNotificationConfigChange(
										event,
										notificationConfig.summary.settingName,
										notificationConfig.summary.settingTypes
											.isAssigned
									)
								}
								u
							/>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											!!notificationSettingsConfig
												?.summary?.isTransferred
										}
									/>
								}
								label={
									<Typography sx={styles.configText}>
										Get daily email summary of contacts,
										companies, deals and products
										transferred from you
									</Typography>
								}
								style={{
									marginBottom: "10px",
									paddingLeft: "11px",
									width: "fit-content",
								}}
								onChange={(event) =>
									handleNotificationConfigChange(
										event,
										notificationConfig.summary.settingName,
										notificationConfig.summary.settingTypes
											.isTransferred
									)
								}
							/>
						</FormGroup>
					</Stack>

					<Divider />

					<Stack spacing={1} pt={3} pb={2}>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							px={0.4}
						>
							{mailIcon(18, 18)}
							<Typography sx={styles.configTitle}>
								Mail Reminders (In Twozo)
							</Typography>
						</Stack>

						<Stack direction="row" alignItems="center" spacing={2}>
							<Checkbox
								checked={
									!!notificationSettingsConfig?.reminder
										?.activity?.isEnabled
								}
								onChange={(event) =>
									handleReminderConfigChange(
										event,
										notificationConfig.activity.settingName
									)
								}
							/>

							<Stack
								direction="row"
								alignItems="center"
								spacing={0.5}
							>
								<Typography
									sx={{
										...styles.configText,
										paddingLeft: "0px",
									}}
									fontSize={15}
									fontWeight={400}
								>
									Get reminder
								</Typography>
								<Stack
									direction="row"
									alignItems="center"
									spacing={0.5}
									pr={0.5}
									sx={{ cursor: "pointer" }}
									onClick={(event) =>
										openReminderMenu(
											event,
											notificationConfig.activity
												.settingName
										)
									}
								>
									<Typography
										fontSize={15}
										fontWeight={500}
										color={theme.palette.primary.main}
										width={18}
									>
										{getReminderNameById(
											notificationSettingsConfig?.reminder
												?.activity?.time
										)}
									</Typography>

									{DropdownIcon(
										16,
										16,
										theme.palette.primary.main
									)}
								</Stack>
								<Typography
									sx={{
										...styles.configText,
										paddingLeft: "0px",
									}}
								>
									minutes before an upcoming activity
								</Typography>
							</Stack>
						</Stack>

						<Stack direction="row" alignItems="center" spacing={2}>
							<Checkbox
								checked={
									!!notificationSettingsConfig?.reminder
										?.email?.isEnabled
								}
								onChange={(event) =>
									handleReminderConfigChange(
										event,
										notificationConfig.email.settingName
									)
								}
							/>

							<Stack
								direction="row"
								alignItems="center"
								spacing={0.5}
							>
								<Typography
									sx={{
										...styles.configText,
										paddingLeft: "0px",
									}}
								>
									Get reminder
								</Typography>
								<Stack
									direction="row"
									alignItems="center"
									spacing={0.5}
									pr={0.5}
									sx={{ cursor: "pointer" }}
									onClick={(event) =>
										openReminderMenu(
											event,
											notificationConfig.email.settingName
										)
									}
								>
									<Typography
										fontSize={15}
										fontWeight={500}
										color={theme.palette.primary.main}
										width={18}
									>
										{getReminderNameById(
											notificationSettingsConfig?.reminder
												?.email?.time
										)}
									</Typography>

									{DropdownIcon(
										16,
										16,
										theme.palette.primary.main
									)}
								</Stack>
								<Typography
									sx={{
										...styles.configText,
										paddingLeft: "0px",
									}}
								>
									minutes before an upcoming email
								</Typography>
							</Stack>
						</Stack>
					</Stack>

					<Divider />

					<Stack spacing={1} pt={3} pb={1.5}>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							px={0.4}
						>
							{notificationIcon(18, 18)}
							<Typography sx={styles.configTitle}>
								Assignment Notification
							</Typography>
						</Stack>

						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={
											!!notificationSettingsConfig
												?.assignment?.isSourceAssigned
										}
									/>
								}
								label={
									<Typography sx={styles.configText}>
										Get notification when a contact,
										company, deal or product is assigned to
										you
									</Typography>
								}
								style={{
									marginBottom: "10px",
									paddingLeft: "11px",
								}}
								onChange={(event) =>
									handleNotificationConfigChange(
										event,
										notificationConfig.assignment
											.settingName,
										notificationConfig.assignment
											.settingTypes.isSourceAssigned
									)
								}
							/>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											!!notificationSettingsConfig
												?.assignment?.isActivityAssigned
										}
									/>
								}
								label={
									<Typography sx={styles.configText}>
										Get notification when an activity is
										assigned to you
									</Typography>
								}
								style={{
									marginBottom: "10px",
									paddingLeft: "11px",
								}}
								onChange={(event) =>
									handleNotificationConfigChange(
										event,
										notificationConfig.assignment
											.settingName,
										notificationConfig.assignment
											.settingTypes.isActivityAssigned
									)
								}
							/>
						</FormGroup>
					</Stack>

					<Divider />

					<Stack spacing={1} pt={3} pb={1.5}>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							px={0.4}
						>
							{notificationIcon(18, 18)}
							<Typography sx={styles.configTitle}>
								Email Tracking Notification
							</Typography>
						</Stack>

						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={
											!!notificationSettingsConfig
												?.tracking?.isEmailOpened
										}
									/>
								}
								label={
									<Typography sx={styles.configText}>
										Get notification when a recipient opens
										your email
									</Typography>
								}
								style={{
									marginBottom: "10px",
									paddingLeft: "11px",
								}}
								onChange={(event) =>
									handleNotificationConfigChange(
										event,
										notificationConfig.tracking.settingName,
										notificationConfig.tracking.settingTypes
											.isEmailOpened
									)
								}
							/>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											!!notificationSettingsConfig
												?.tracking?.isEmailLinkClicked
										}
									/>
								}
								label={
									<Typography sx={styles.configText}>
										Get notification when a recipient clicks
										on a link in your email
									</Typography>
								}
								style={{
									marginBottom: "10px",
									paddingLeft: "11px",
								}}
								onChange={(event) =>
									handleNotificationConfigChange(
										event,
										notificationConfig.tracking.settingName,
										notificationConfig.tracking.settingTypes
											.isEmailLinkClicked
									)
								}
							/>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											!!notificationSettingsConfig
												?.tracking?.isRepliedEmail
										}
									/>
								}
								label={
									<Typography sx={styles.configText}>
										Get notification when a recipient
										replies to your email
									</Typography>
								}
								style={{
									marginBottom: "10px",
									paddingLeft: "11px",
								}}
								onChange={(event) =>
									handleNotificationConfigChange(
										event,
										notificationConfig.tracking.settingName,
										notificationConfig.tracking.settingTypes
											.isRepliedEmail
									)
								}
							/>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											!!notificationSettingsConfig
												?.tracking?.isTeamInboxEmail
										}
									/>
								}
								label={
									<Typography sx={styles.configText}>
										Get notification when you receive an
										email in your Team Inbox
									</Typography>
								}
								style={{
									marginBottom: "10px",
									paddingLeft: "11px",
								}}
								onChange={(event) =>
									handleNotificationConfigChange(
										event,
										notificationConfig.tracking.settingName,
										notificationConfig.tracking.settingTypes
											.isTeamInboxEmail
									)
								}
							/>
						</FormGroup>
					</Stack>

					<Divider />

					<Box py={3}>
						<Stack direction="row" spacing={2}>
							<Button
								variant="contained"
								color="secondary"
								disableElevation
								onClick={handleSaveNotificationSettings}
								disabled={
									updateNotificationMutation.isLoading ||
									resetNotificationMutation.isLoading
								}
							>
								Save
							</Button>

							<Button
								variant="outlined"
								disableElevation
								style={{
									borderColor: theme.palette.secondary.main,
								}}
								onClick={handleResetNotificationSettings}
								disabled={
									updateNotificationMutation.isLoading ||
									resetNotificationMutation.isLoading
								}
							>
								Reset
							</Button>
						</Stack>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}

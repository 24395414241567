import React, { useState } from "react";
import {
	Autocomplete,
	Box,
	Chip,
	CircularProgress,
	MenuItem,
	Paper,
	Popper,
	Skeleton,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { useUsers } from "../../../../hooks/account/user";
import CloseIcon from "../../../../assets/icons/close";
import { default as UnKnownImage } from "../../../../assets/images/contact/unknownContact.png";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";
import { useDebouncedCallback } from "use-debounce";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
			}}
		/>
	);
};

export default function FollowersInput(props) {
	const { onChange, value } = props;
	const [searchedValue, setSearchedValue] = useState("");

	const {
		activeUserList,
		isLoadingUsers,
		isFetchingUsers,
		hasNextUsersPage,
		fetchNextUsersPage,
	} = useUsers(searchedValue);

	const lastOptionRef = useLastRowRef(
		fetchNextUsersPage,
		hasNextUsersPage,
		isFetchingUsers
	);

	const handleUserInputChange = useDebouncedCallback(
		(event) => {
			onInputChange(event);
		},
		500 // delay in ms
	);

	const onInputChange = (event) => {
		const { value } = event.target;
		setSearchedValue(value?.trimStart());
	};

	const renderOption = (option) => {
		return (
			<MenuItem
				style={{
					height: "40px",
					width: "100%",
				}}
			>
				<Stack direction="row" spacing={1} alignItems="center">
					<img
						src={UnKnownImage}
						alt="owner_image"
						height="24px"
						width="24px"
					/>
					<Typography fontSize={13}>{option?.name}</Typography>
				</Stack>
			</MenuItem>
		);
	};

	const CustomListboxComponent = function (props) {
		return (
			<Box {...props}>
				{props.children}

				<Box ref={lastOptionRef}>
					{!isLoadingUsers && isFetchingUsers ? (
						<MenuItem style={{ height: "40px" }}>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="center"
								spacing={1}
							>
								<Skeleton
									variant="circular"
									width="24px"
									height="24px"
								/>
								<Skeleton width="190px" height="16px" />
							</Stack>
						</MenuItem>
					) : null}
				</Box>
			</Box>
		);
	};

	const handleSelectUser = (_, value) => {
		onChange(value);
		setSearchedValue("");
	};

	const isOptionEqualToValue = (option, value) => {
		return option?.value === value?.value;
	};

	return (
		<React.Fragment>
			<Autocomplete
				size="small"
				multiple={true}
				forcePopupIcon={false}
				disableClearable
				options={activeUserList || []}
				onChange={handleSelectUser}
				value={value || []}
				open={Boolean(searchedValue)}
				PopperComponent={CustomPopper}
				PaperComponent={CustomPaper}
				noOptionsText="No results found"
				loading={isLoadingUsers}
				loadingText={
					<Stack
						alignItems="center"
						justifyContent="center"
						height="140px"
					>
						<CircularProgress size="18px" />
					</Stack>
				}
				isOptionEqualToValue={isOptionEqualToValue}
				filterSelectedOptions={true}
				getOptionLabel={(option) => {
					return option?.name || "";
				}}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							key={index}
							size="small"
							label={
								<Typography fontSize={14} color="#000">
									{option?.name}
								</Typography>
							}
							color="secondary"
							deleteIcon={CloseIcon(16, 16, "#000")}
							sx={{
								borderRadius: "8px",
								marginRight: "4px",
							}}
							{...getTagProps({ index })}
						/>
					))
				}
				renderOption={(props, option) => {
					return (
						<Box
							{...props}
							key={option?.value}
							style={{ padding: "0px" }}
						>
							{renderOption(option)}
						</Box>
					);
				}}
				ListboxComponent={CustomListboxComponent}
				renderInput={(params) => (
					<TextField
						{...params}
						value={searchedValue}
						onChange={handleUserInputChange}
						autoFocus={true}
						onBlur={() => setSearchedValue("")}
						placeholder="Enter"
						inputProps={{
							...params.inputProps,
							style: {
								...params.inputProps?.style,
								fontSize: "14px",
							},
						}}
					/>
				)}
			/>
		</React.Fragment>
	);
}

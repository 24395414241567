import React, { useState } from "react";
import {
	alpha,
	Box,
	Button,
	IconButton,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as TickIcon } from "../../../../../assets/icons/tick";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { default as EditIcon } from "../../../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../../../assets/icons/delete";
import { getDateMonthAndYearFormat } from "../../../../../utils/DateUtils";
import { tagOperations } from "../../../../../utils/tagOperations";
import { tagActionTypes } from "../../../../../utils/tagActionTypes";
import { useTagContext } from "../../TagsContext";
import TooltipComponent from "../../../../Elements/TooltipComponent";

const style = {
	tableValueText: {
		fontSize: "15px",
		fontWeight: 400,
	},
};

export default function TagsCard(props) {
	const {
		tag,
		onTagUpdate,
		editedTagData,
		updateEditedTagData,
		updateDeletedTagData,
		performCreateUpdateDelete,
		handleOpenDeleteConfirmationDialog,
	} = props;

	const theme = useTheme();
	const { tagState, tagStateDispatcher } = useTagContext();
	const [tagName, setTagName] = useState(tag.tag);
	const [isRowHovered, setIsTableRowHovered] = useState(false);
	const [isErrorMode, setErrorMode] = useState(false);

	const errorMessage = "Max. of 255 characters are allowed";

	const handleUpdateInputChange = (event) => {
		event.stopPropagation();
		const { value } = event.target;
		let nameCharacterLimit = 255;
		if (value.trimStart().length > nameCharacterLimit) {
			setErrorMode(true);
		} else {
			setErrorMode(false);
			setTagName(value);

			if (tagState?.isUpdateMode) {
				const updatedTag = {
					...tag,
					tag: value,
				};
				onTagUpdate(updatedTag);
			}
		}
	};

	const handleOnMouseOverTableRow = () => {
		setIsTableRowHovered(true);
	};

	const handleOnMouseLeaveTableRow = () => {
		setIsTableRowHovered(false);
	};

	const handleCancelNewTag = () => {
		tagStateDispatcher({ type: tagActionTypes.resetCreateActions });
	};

	const handleCancelExistingTag = () => {
		setTagName(editedTagData.tag);
		onTagUpdate({ ...editedTagData });
		tagStateDispatcher({ type: tagActionTypes.resetUpdateActions });
		updateEditedTagData({});
	};

	const handleEditTag = () => {
		tagStateDispatcher({ type: tagActionTypes.updateTag });
		updateEditedTagData(tag);
	};

	const handleDelete = () => {
		handleOpenDeleteConfirmationDialog();
		updateDeletedTagData(tag);
	};

	const handleUpdate = () => {
		if (tagName !== "") {
			performCreateUpdateDelete(tagOperations.update, tagName, tag.id);
		}
	};

	const handleCreateNewTag = () => {
		if (tagName !== "") {
			performCreateUpdateDelete(tagOperations.create, tagName);
		}
	};

	// create or update the tag when press the enter key
	const handleEnterKeyPress = (event) => {
		if (tagName !== "") {
			if (event.key === "Enter") {
				if (tag.isNewTag) {
					performCreateUpdateDelete(tagOperations.create, tagName);
				} else {
					if (tagState.isUpdateMode) {
						performCreateUpdateDelete(
							tagOperations.update,
							tagName,
							tag.id
						);
					}
				}
			}
		}
	};

	return (
		<React.Fragment>
			<Stack
				direction="row"
				alignItems="center"
				style={{
					minHeight: "52px",
					border: `1px solid ${
						(tag.isNewTag && tagState?.hasCreationError) ||
						(tagState?.hasUpdateError &&
							editedTagData?.id === tag.id &&
							!tag.isNewTag) ||
						isErrorMode
							? theme.palette.error.main
							: theme.palette.divider
					}`,
					boxShadow: isRowHovered
						? "0px 2px 4px rgba(0, 0, 0, 0.1)"
						: "none",
					borderRadius: "8px",
				}}
				onMouseOver={handleOnMouseOverTableRow}
				onMouseLeave={handleOnMouseLeaveTableRow}
			>
				<Box width="35%" pl={2}>
					{tag.isNewTag || editedTagData?.id === tag.id ? (
						<TextField
							onKeyDown={handleEnterKeyPress}
							variant="standard"
							name="tag"
							placeholder="Enter Tag Name"
							value={tagName}
							autoFocus={true}
							onChange={handleUpdateInputChange}
							error={isErrorMode}
							helperText={isErrorMode ? errorMessage : ""}
							inputProps={{
								style: {
									...style.tableValueText,
								},
							}}
						/>
					) : (
						<TooltipComponent title={tag.tag} placement="top">
							<Typography
								maxWidth="30vw"
								noWrap
								sx={{
									...style.tableValueText,
									cursor: "pointer",
								}}
							>
								{tag.tag}
							</Typography>
						</TooltipComponent>
					)}
				</Box>

				<Box width="18%">
					<TooltipComponent
						title={tag.createdBy?.name}
						placement="top"
					>
						<Typography
							noWrap
							maxWidth="15vw"
							sx={{
								...style.tableValueText,
								cursor: "pointer",
							}}
						>
							{tag.createdBy?.name}
						</Typography>
					</TooltipComponent>
				</Box>

				<Box width="14%">
					<Typography sx={style.tableValueText}>
						{tag.createdTime
							? getDateMonthAndYearFormat(tag.createdTime)
							: ""}
					</Typography>
				</Box>

				<Box width="14%">
					<Typography sx={style.tableValueText}>
						{tag.lastUsedTime}
					</Typography>
				</Box>

				<Box width="19%" pr={2}>
					<Stack
						direction="row"
						spacing={2}
						justifyContent="flex-end"
					>
						{tag.isNewTag ||
						(tagState.isUpdateMode &&
							editedTagData?.id === tag.id) ? (
							<Button
								size="small"
								variant="outlined"
								style={{
									borderColor: theme.palette.secondary.main,
									maxHeight: "28px",
								}}
								startIcon={CloseIcon(
									20,
									20,
									theme.palette.primary.main
								)}
								onClick={
									tag.isNewTag
										? handleCancelNewTag
										: handleCancelExistingTag
								}
							>
								Cancel
							</Button>
						) : null}

						{tag.isNewTag ? (
							<Button
								size="small"
								variant="contained"
								style={{
									maxHeight: "28px",
									opacity: tagName === "" ? 0.6 : 1,
								}}
								color="secondary"
								startIcon={TickIcon(
									20,
									20,
									isErrorMode
										? alpha(
												theme.palette.secondary
													.contrastText,
												0.6
											)
										: theme.palette.primary.main
								)}
								disabled={isErrorMode}
								sx={{
									"&.Mui-disabled": {
										backgroundColor:
											theme.palette.secondary.main,
										color: alpha(
											theme.palette.secondary
												.contrastText,
											0.6
										),
									},
								}}
								disableElevation
								onClick={handleCreateNewTag}
							>
								Add
							</Button>
						) : null}

						{!tag.isNewTag &&
						tagState.isUpdateMode &&
						editedTagData?.id === tag.id ? (
							<Button
								size="small"
								variant="contained"
								style={{
									maxHeight: "28px",
									opacity: tagName === "" ? 0.6 : 1,
								}}
								sx={{
									"&.Mui-disabled": {
										backgroundColor:
											theme.palette.secondary.main,
										color: alpha(
											theme.palette.secondary
												.contrastText,
											0.6
										),
									},
								}}
								color="secondary"
								startIcon={TickIcon(
									20,
									20,
									isErrorMode
										? alpha(
												theme.palette.secondary
													.contrastText,
												0.6
											)
										: theme.palette.primary.main
								)}
								disabled={isErrorMode}
								disableElevation
								onClick={handleUpdate}
							>
								Update
							</Button>
						) : null}
					</Stack>

					{isRowHovered && !tag.isNewTag && !tagState.isUpdateMode ? (
						<Stack
							direction="row"
							spacing={3}
							justifyContent="flex-end"
						>
							<Box display="flex">
								<IconButton
									size="small"
									style={{ padding: 0 }}
									onClick={handleEditTag}
								>
									{EditIcon(
										18,
										18,
										theme.palette.primary.main
									)}
								</IconButton>
							</Box>

							<Box display="flex">
								<IconButton
									size="small"
									style={{ padding: 0 }}
									onClick={handleDelete}
								>
									{DeleteIcon(
										18,
										18,
										theme.palette.primary.main
									)}
								</IconButton>
							</Box>
						</Stack>
					) : null}
				</Box>
			</Stack>
		</React.Fragment>
	);
}

import { fieldType } from "../../../utils/fieldType";

export const maxActionLimit = 5;

export const maxConditionLimit = 10;

export const andCondition = 1;

export const orCondition = 2;

// workflow name, description validation config

export const maxWorkflowNameLength = 100;

export const maxWorkflowDescriptionLength = 255;

export const multipleValuesFieldTypes = [
	fieldType.TEXT_PICKERS,
	fieldType.EMAILS,
	fieldType.PHONES,
	fieldType.CONTACTS_ONBOARD,
	fieldType.MULTI_SELECT,
	fieldType.NUMBER_PICKERS,
	fieldType.OWNERS,
	fieldType.TAGS,
];
// regex patters

export const phoneNumberPattern =
	/^(\+?\d{1,4}[ -]?)?(\(?\d{1,4}\)?[ -]?)?(\d{1,4}[ -]?){1,2}\d{1,4}(?:[ -]?\d+)?$/;

export const phoneRegexPattern = /[^0-9+\-()\s]/g;

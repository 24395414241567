import StaticDropdownFieldComponent from "../StaticDropdownFieldComponent";

export default function AvailabilityFieldComponent(params) {
	const { field } = params;

	return (
		<StaticDropdownFieldComponent
			field={field}
			dropdownName="AVAILABILITY"
		/>
	);
}

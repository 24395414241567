export const checkFieldVisibility = (field, formValues) => {
	// Validate a single rule
	function validateRule(rule, formData) {
		const fieldValue =
			formData[rule.field]?.value?.id ||
			formData[rule.field]?.value?.value;

		const isConditionEquals = (fieldValue) => {
			if (typeof fieldValue === "object" && fieldValue?.value) {
				return fieldValue.value === rule.value;
			} else {
				return fieldValue === rule.value;
			}
		};

		switch (rule.condition) {
			case "EQUALS":
				return isConditionEquals(fieldValue);

			default:
				return false;
		}
	}

	// Validate Nested rule
	function validateRules(rules, formData, connector) {
		let allRulesValid = true; //AND
		let anyRuleValid = false; //OR

		for (const rule of rules) {
			let ruleValid;

			if (rule.condition) {
				ruleValid = validateRule(rule, formData);
			} else if (rule.rules) {
				// Nested rule with connector
				ruleValid = validateRules(rule.rules, formData, rule.connector);
			} else {
				return false;
			}

			if (connector === "AND" && !ruleValid) {
				return false;
			} else if (connector === "OR" && ruleValid) {
				return true;
			}
		}

		return connector === "AND" ? allRulesValid : anyRuleValid;
	}

	if (field?.config?.showWhen) {
		const showWhen = field?.config?.showWhen;
		const rules = showWhen.rules;
		const connector = showWhen.connector;

		if (connector) {
			return validateRules(rules, formValues, connector);
		} else {
			if (rules[0]?.connector) {
				return validateRules(
					rules[0].rules,
					formValues,
					rules[0]?.connector
				);
			} else {
				// single rule validation
				const rule = rules[0];
				if (rule) {
					return validateRule(rule, formValues);
				}
			}
		}
	} else {
		return true;
	}
};

const providerList = [
	{
		displayName: "Gmail",
		name: "google",
		value: 1,
	},
	{
		displayName: "Outlook",
		name: "outlook",
		value: 2,
	},
];

export default providerList;

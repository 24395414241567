import React from "react";
import { Box, Stack, Divider, Typography } from "@mui/material";
import { Breadcrumbs } from "./Breadcrumbs";
import { useDashboardBuilderContext } from "../Context";
import ChartProperties from "./ChartProperties";
import KpiCardProperties from "./KpiCardProperties";
import DeleteDialog from "../../../../Elements/DeleteDialog";

export const Properties = () => {
	const {
		selectedChart,
		selectedChartProperty,
		selectedChartIndex,
		updateChartProperties,
		showPreventChartSelectionDialog,
		confirmChartSelection,
		discardChartSelection,
	} = useDashboardBuilderContext();

	const onSubmit = (data) => {
		updateChartProperties(selectedChartIndex, data);
	};

	return (
		<React.Fragment>
			<DeleteDialog
				open={showPreventChartSelectionDialog}
				title="Are you sure you want to proceed?"
				subtitle="All of your unsaved changes will be discarded"
				confirmButtonText="Remove"
				onCancel={discardChartSelection}
				onDelete={confirmChartSelection}
			/>

			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Breadcrumbs />

				<Divider />

				<Box
					flex={1}
					style={{
						overflowY: "auto",
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography fontWeight={500} p={1.5}>
						Properties
					</Typography>

					<Box flex={1} style={{ overflowY: "auto" }}>
						{selectedChart ? (
							(function () {
								switch (selectedChartProperty?.meta?.viewType) {
									case "barChart":
									case "pieChart":
									case "lineChart":
										return (
											<ChartProperties
												chartConfiguration={
													selectedChartProperty
												}
												onSubmit={onSubmit}
											/>
										);
									case "kpiCard":
										return (
											<KpiCardProperties
												chartConfiguration={
													selectedChartProperty
												}
												onSubmit={onSubmit}
											/>
										);
									default:
										return (
											<PropertyError errorMessage="Invalid chart type." />
										);
								}
							})()
						) : (
							<PropertyError errorMessage="Add charts to see the properties" />
						)}
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
};

const PropertyError = ({ errorMessage }) => (
	<Stack alignItems="center" justifyContent="center" height="100%">
		<Typography style={{ fontSize: 14, opacity: 0.6 }}>
			{errorMessage}
		</Typography>
	</Stack>
);

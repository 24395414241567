import React, { useEffect, useMemo, useState } from "react";
import DropdownBaseComponent from "../DropdownBaseComponent";
import { useTerritoryDropdownList } from "../../../../../hooks/services/userManagement/territory";

export default function TerritoryComponent(props) {
	const { field, style, error, updateFieldValue, value, clearError } = props;
	const [selected, setSelected] = useState(null);

	const { data: territoryList } = useTerritoryDropdownList();

	useEffect(() => {
		if (value) {
			setSelected(value);
		} else {
			setSelected(null);
		}
	}, [value]);

	const onFieldChange = (value) => {
		setSelected(value);
		updateFieldValue([value], field.fieldId);
		clearError(field.fieldId);
	};

	const formattedTerritoryList = useMemo(() => {
		if (Array.isArray(territoryList) && territoryList.length > 0) {
			return (
				territoryList.map((territory) => {
					if (territory.title) {
						territory["name"] = territory["title"];
					}
					if (territory.id) {
						territory["value"] = territory["id"];
					} else if (territory.value) {
						territory["id"] = territory["value"];
					}
					return territory;
				}) || []
			);
		}
	}, [territoryList]);

	return (
		<React.Fragment>
			<DropdownBaseComponent
				selected={selected}
				options={formattedTerritoryList || []}
				onFieldChange={onFieldChange}
				style={style}
				error={error}
			/>
		</React.Fragment>
	);
}

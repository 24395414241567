import {
	Box,
	Button,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { default as BackIcon } from "../../../assets/icons/leftArrow";
import InfoIcon from "../../../assets/icons/info";
import DuplicateNotFound from "./DuplicateNotFound";
import DuplicatesFound from "./DuplicatesFound";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { enqueueSnackbar } from "notistack";
import {
	useContactDuplicateList,
	useMergeDuplicateContactMutation,
} from "../../../hooks/services/contact";
import { useLastRowRef } from "../../../hooks/common/InfiniteScroll";

const styles = {
	contentName: {
		fontSize: 14,
		fontWeight: 500,
	},
	text: {
		fontSize: 14,
	},
};

function CheckDuplicates(props) {
	const { contactDetails, onDuplicateMergeSuccess, onClose } = props;
	const theme = useTheme();
	const [selectedContactId, setSelectedContactId] = useState("");
	const updateMutation = useMergeDuplicateContactMutation(contactDetails);

	const {
		isLoading: isDuplicatesListLoading,
		data: duplicateList,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useContactDuplicateList(contactDetails);
	const isDuplicateFound = duplicateList?.pages[0]?.totalRecords >= 1;

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const handleSelectContact = (contactId) => {
		setSelectedContactId(contactId);
	};

	const getMergeRequestData = () => {
		let mergeRequestData = {
			primaryContactId: selectedContactId,
			contact: contactDetails,
		};

		return mergeRequestData;
	};

	const mergeDuplicateContact = () => {
		let mergeRequestData = getMergeRequestData();
		updateMutation.mutate(mergeRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.fieldValuesMerged,
				});
				onDuplicateMergeSuccess();
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack direction="row" alignItems="center" spacing={2}>
						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{BackIcon(20, 20)}
						</IconButton>
						<Typography fontWeight={600} fontSize={17}>
							Duplicates
						</Typography>
					</Stack>
				</Box>

				<Divider />

				{isDuplicatesListLoading ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="100vh"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					<Box
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<Box
							m={2}
							p={2}
							style={{
								backgroundColor: "#E7F7F0",
								borderRadius: "8px",
							}}
						>
							<Stack
								direction="row"
								alignItems="center"
								spacing={2}
							>
								<IconButton>
									{InfoIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
								<Stack>
									<Typography style={styles.text}>
										We check for exact matches in these
										fields:
									</Typography>
									<Typography style={styles.text}>
										Emails, Work email, Work, Mobile, Other
										phone numbers
									</Typography>
								</Stack>
							</Stack>
						</Box>

						<Box>
							{isDuplicateFound ? (
								<Box>
									<DuplicatesFound
										duplicateList={duplicateList}
										handleSelectContact={
											handleSelectContact
										}
										contactId={selectedContactId}
									/>

									<Box ref={lastRowRef}>
										{!isDuplicatesListLoading &&
											isFetching && (
												<Stack
													direction="row"
													alignItems="center"
													justifyContent="center"
													spacing={1}
													py={2}
												>
													<CircularProgress
														size={18}
													/>

													<Typography fontSize={12}>
														Loading More Data
													</Typography>
												</Stack>
											)}
									</Box>
								</Box>
							) : (
								<DuplicateNotFound />
							)}
						</Box>
					</Box>
				)}

				{!isDuplicatesListLoading
					? isDuplicateFound && (
							<>
								<Divider />

								<Box>
									<Stack direction="row" p={2}>
										<Button
											variant="contained"
											disableElevation
											sx={{
												"&.Mui-disabled": {
													backgroundColor:
														theme.palette.primary
															.main,
													color: "rgba(255,255,255, 0.6)",
												},
											}}
											onClick={mergeDuplicateContact}
											disabled={!selectedContactId}
										>
											Merge
										</Button>
									</Stack>
								</Box>
							</>
						)
					: null}
			</Box>
		</React.Fragment>
	);
}

export default CheckDuplicates;

const workflowTriggerList = [
	{
		name: "All Triggers",
		value: -1,
	},
	{
		name: "Activity Created",
		value: 1,
	},
	{
		name: "Activity Updated",
		value: 2,
	},
	{
		name: "Activity Deleted",
		value: 3,
	},
	{
		name: "Contact Created",
		value: 4,
	},
	{
		name: "Contact Updated",
		value: 5,
	},
	{
		name: "Contact Deleted",
		value: 6,
	},
	{
		name: "Deal Created",
		value: 7,
	},
	{
		name: "Deal Updated",
		value: 8,
	},
	{
		name: "Deal Deleted",
		value: 9,
	},
	{
		name: "Company Created",
		value: 10,
	},
	{
		name: "Company Updated",
		value: 11,
	},
	{
		name: "Company Deleted",
		value: 12,
	},
];

export default workflowTriggerList;

import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	IconButton,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../assets/icons/close";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import Field from "../../Field";
import { MenuItemText } from "../../../../styles/twozo";

const selectMenuProps = {
	autoFocus: false,
	PaperProps: {
		style: {
			width: "300px",
			borderRadius: "8px",
			maxHeight: "300px",
		},
	},
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left",
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left",
	},
};

export default function SingleCondition(props) {
	const theme = useTheme();
	const {
		deleteCondition,
		isDeleteEnabled,
		condition,
		readOnly,
		formValues,
		value,
		handleUpdateCondition,
		fieldsList,
		comparatorsList,
	} = props;

	const [searchValue, setSearchValue] = useState("");

	const initialField = useMemo(
		() =>
			fieldsList?.find(
				(field) =>
					field?.fieldId === condition?.field?.id ||
					condition?.field?.fieldId
			),
		[condition, fieldsList]
	);

	const comparators = useMemo(() => {
		if (!initialField) return [];

		return (
			initialField?.comparators?.map((comparator) => ({
				...comparatorsList[comparator],
				id: comparator,
			})) || []
		);
	}, [initialField, comparatorsList]);

	const initialComparator = useMemo(() => {
		if (!initialField) return null;
		return comparators.find(
			(comp) => comp?.id === condition?.comparator?.id
		);
	}, [comparators, condition, initialField]);

	useEffect(() => {
		if (initialField && !condition.fieldType) {
			const updatedCondition = {
				...condition,
				field: {
					...condition?.field,
					config: !!initialField?.config && initialField.config,
				},
				fieldType: initialField?.fieldType,
			};
			handleUpdateCondition(updatedCondition);
		}
	}, [initialField, condition, handleUpdateCondition]);

	const handleFieldValueChange = (field) => {
		const conditionUpdate = {
			field: {
				id: field.fieldId,
				config: field?.config,
			},
			error: {
				error: false,
				message: "",
			},
			fieldType: field.fieldType,
			comparator: { id: null },
			values: [],
		};

		handleUpdateCondition(conditionUpdate);
	};

	const handleComparatorChange = (comparator) => {
		const conditionUpdate = {
			...condition,
			comparator: {
				id: comparator.id,
				hasValue: comparator.hasValue,
			},
			error: {
				error: false,
				message: "",
			},
			values: [],
		};

		handleUpdateCondition(conditionUpdate);
	};

	const handleFieldValueUpdate = (value) => {
		const conditionUpdate = {
			...condition,
			values: value,
			error: {
				error: false,
				message: "",
			},
		};

		handleUpdateCondition(conditionUpdate);
	};

	const filteredFields = useMemo(() => {
		if (searchValue.trim()) {
			return fieldsList?.filter((field) =>
				field?.name
					?.toLowerCase()
					.startsWith(searchValue.toLocaleLowerCase())
			);
		}

		return fieldsList;
	}, [searchValue, fieldsList]);

	const renderEmptyView = () => {
		return (
			<Stack
				alignItems="center"
				justifyContent="center"
				width="300px"
				height="100px"
			>
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Stack
				direction="row"
				alignItems="center"
				width="100%"
				height="auto"
			>
				<Select
					displayEmpty
					value={initialField || ""}
					sx={{
						width: "50%",
						backgroundColor: theme.palette.secondary.main,
						borderRadius: "8px 0px 0px 8px",
						"& .MuiOutlinedInput-notchedOutline": {
							border: "none",
							borderRadius: "8px 0px 0px 8px",
						},
					}}
					onChange={(event) => {
						handleFieldValueChange(event.target.value);
					}}
					onOpen={() => setSearchValue("")}
					renderValue={
						initialField
							? () => initialField?.name || ""
							: () => (
									<Typography
										fontSize={14}
										color="rgba(0, 0, 0, 0.6)"
									>
										Select
									</Typography>
								)
					}
					MenuProps={selectMenuProps}
				>
					<Box
						p={0.5}
						marginTop="-8px"
						sx={{
							position: "sticky",
							top: 0,
							zIndex: 1,
							backgroundColor: "#fff",
						}}
					>
						<MenuSearchBar
							value={searchValue}
							onChange={(event) =>
								setSearchValue(event.target.value)
							}
						/>
					</Box>

					{filteredFields?.length > 0
						? filteredFields?.map((field) => (
								<MenuItem
									key={field.fieldId}
									value={field}
									style={{ minHeight: "40px" }}
								>
									<MenuItemText>{field.name}</MenuItemText>
								</MenuItem>
							))
						: renderEmptyView()}
				</Select>

				{initialField && (
					<>
						<Select
							displayEmpty
							sx={{
								backgroundColor: theme.palette.secondary.main,
								borderRadius:
									initialComparator &&
									initialComparator?.hasValue
										? "0px"
										: "0px 8px 8px 0px",
								"& .MuiOutlinedInput-notchedOutline": {
									border: "none",
									borderLeft: "1px solid rgba(0, 0, 0, 0.10)",
									borderRight:
										initialComparator &&
										initialComparator?.hasValue
											? "1px solid rgba(0, 0, 0, 0.10)"
											: "none",
									borderRadius:
										initialComparator &&
										initialComparator?.hasValue
											? "0px"
											: "0px 8px 8px 0px",
								},
							}}
							value={initialComparator || ""}
							onChange={(event) => {
								handleComparatorChange(event.target.value);
							}}
							renderValue={
								initialComparator
									? () => initialComparator?.name
									: () => (
											<Typography
												fontSize={14}
												color="rgba(0, 0, 0, 0.6)"
											>
												Select
											</Typography>
										)
							}
							MenuProps={selectMenuProps}
						>
							{comparators.map((comparator) => (
								<MenuItem
									key={comparator.id}
									value={comparator}
									style={{ minHeight: "40px" }}
								>
									<MenuItemText>
										{comparator.name}
									</MenuItemText>
								</MenuItem>
							))}
						</Select>

						{initialComparator && initialComparator?.hasValue && (
							<Field
								formValues={formValues}
								clearError={() => {}}
								value={value}
								field={{
									...initialField,
									values: condition.values || [],
								}}
								isConditionField
								updateFieldValue={handleFieldValueUpdate}
								hasError={condition?.error?.error}
								metadata={{}}
								style={{
									backgroundColor:
										theme.palette.secondary.main,
									borderRadius: "0px 8px 8px 0px",
									"& .MuiOutlinedInput-notchedOutline": {
										border: condition?.error?.error
											? "1px solid red"
											: "none",
										borderRadius: "0px 8px 8px 0px",
									},
									"& .MuiOutlinedInput-root": {
										"& fieldset": {
											border: condition?.error?.error
												? "1px solid red"
												: "none",
										},
									},
									height: "42px",
								}}
							/>
						)}
					</>
				)}

				<Box pl={1}>
					{!readOnly && isDeleteEnabled && (
						<IconButton size="small" onClick={deleteCondition}>
							{CloseIcon(20, 20, "#000")}
						</IconButton>
					)}
				</Box>
			</Stack>

			<Typography
				fontSize={13}
				fontWeight={500}
				color={theme.palette.error.main}
				pl={1}
			>
				{condition?.error?.message || ""}
			</Typography>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { default as FileIcon } from "../../../../assets/icons/files";
import { default as ImageIcon } from "../../../../assets/icons/image";
import { default as CloseIcon } from "../../../../assets/icons/close";
import {
	getDateMonthAndYearFormat,
	getHourAndMinuteFormat,
} from "../../../../utils/DateUtils";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import TooltipComponent from "../../../Elements/TooltipComponent";
import { useDeleteFile } from "../../../../hooks/services/summary/files";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { imageExtension } from "../../../../utils/fileUtills";

export default function ShortFileItem(props) {
	const { fileData, sourceName, entityId } = props;
	const theme = useTheme();

	const [hoveredFile, setHoveredFile] = useState(false);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);

	const deleteFileMutation = useDeleteFile(sourceName, entityId);

	const handleDeleteFile = () => {
		deleteFileMutation.mutate(
			{ id: fileData?.id },
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.filesDeleted,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	const onMouseOverFile = () => setHoveredFile(true);
	const onMouseOutFile = () => setHoveredFile(false);

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const handleDownloadFile = () => {
		window.location.href = `/api/file/download?fileId=${fileData?.id}`;
	};

	return (
		<React.Fragment>
			{/* Delete Dialog */}
			<DeleteDialog
				open={isDeleteDialogOpened}
				title="Are you sure you want to delete this file?"
				subtitle="This action cannot be undone."
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteFile}
				disableDeleteButton={deleteFileMutation.isLoading}
			/>

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				onMouseOver={onMouseOverFile}
				onMouseOut={onMouseOutFile}
				sx={{
					backgroundColor: hoveredFile ? "#F4F5F5" : "transparent",
					borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
				}}
				px={2}
				py={1.5}
			>
				<Stack direction="row" alignItems="center" spacing={2}>
					<Box display="flex">
						{imageExtension?.includes(
							fileData?.extension?.toLowerCase()
						)
							? ImageIcon(20, 20)
							: FileIcon(20, 20)}
					</Box>

					<Stack>
						<Box display="flex" onClick={handleDownloadFile}>
							<TooltipComponent
								title={fileData.fileName}
								placement="top"
								width="300px"
								verticalDisplacement={-3}
							>
								<Typography
									fontSize={13}
									fontWeight={500}
									noWrap
									style={{
										color: hoveredFile
											? theme.palette.secondary
													.contrastText
											: "#000",
										maxWidth: "245px",
									}}
								>
									{fileData.fileName}
								</Typography>
							</TooltipComponent>
						</Box>

						<Stack direction="row" spacing={1}>
							<TooltipComponent
								title={fileData.ownerName}
								placement="top"
								width="259px"
							>
								<Typography
									noWrap
									maxWidth="100px"
									fontSize={12}
									color={"rgba(0, 0, 0, 0.6)"}
								>
									{fileData.ownerName}
								</Typography>
							</TooltipComponent>

							<Typography
								fontSize={12}
								color={"rgba(0, 0, 0, 0.6)"}
							>
								&#8226;
							</Typography>

							<Typography
								fontSize={12}
								color={"rgba(0, 0, 0, 0.6)"}
							>
								{getDateMonthAndYearFormat(
									fileData.uploadedTime
								)}
								,{" "}
								{getHourAndMinuteFormat(fileData.uploadedTime)}
							</Typography>
						</Stack>
					</Stack>
				</Stack>

				{hoveredFile ? (
					<IconButton size="small" onClick={openDeleteDialog}>
						{CloseIcon(20, 20, theme.palette.primary.main)}
					</IconButton>
				) : null}
			</Stack>
		</React.Fragment>
	);
}

import React, { useEffect, useState } from "react";
import {
	Box,
	CircularProgress,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { TableHeaderLabel } from "../../../../styles/twozo";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { getDropdownDataByName } from "../../../../utils/dropdownData";
import { useWorkflowAutomationList } from "../../../../hooks/services/workflow";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";
import AutomationRow from "./AutomationRow";

const sortTypes = {
	ascending: "asc",
	decending: "desc",
};

const initialTableHeaderData = [
	{
		id: 1,
		displayName: "Status",
		name: "status",
		isSortEnabled: false,
		width: "10%",
	},
	{
		id: 2,
		displayName: "Workflow Name",
		name: "name",
		isSortEnabled: true,
		width: "30%",
	},
	{
		id: 3,
		displayName: "Module",
		name: "triggerSource",
		isSortEnabled: true,
		width: "13%",
	},
	{
		id: 4,
		displayName: "Execute on",
		name: "triggerEvent",
		isSortEnabled: true,
		width: "13%",
	},
	{
		id: 5,
		displayName: "Actions",
		name: "actions",
		isSortEnabled: true,
		width: "12%",
	},
	{
		id: 6,
		displayName: "Last Updated",
		name: "modifiedTime",
		isSortEnabled: true,
		width: "12%",
	},
	{
		id: 7,
		displayName: "",
		isSortEnabled: false,
		width: "80px",
	},
];

export default function Automation(params) {
	const { filterCriteria, isFilterMenuOpened, searchText } = params;

	const theme = useTheme();

	const [workflowList, setWorkflowList] = useState([]);
	const [sortData, setSortData] = useState(null);
	const [tableHeader, setTableHeader] = useState(initialTableHeaderData);

	const sources = getDropdownDataByName("WORKFLOW_TRIGGER_SOURCE_LIST");
	const events = getDropdownDataByName("WORKFLOW_TRIGGER_EVENT_LIST");

	// query call:-
	const {
		data: workflowListData,
		isLoading: isWorkflowListLoading,
		isFetching,
		fetchNextPage,
		hasNextPage,
	} = useWorkflowAutomationList(filterCriteria, searchText, sortData);
	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	useEffect(() => {
		if (workflowListData && Array.isArray(workflowListData)) {
			let sourcesMap = {};
			sources.forEach((source) => {
				sourcesMap[source.value] = source;
			});

			let eventsMap = {};
			events.forEach((event) => {
				eventsMap[event.value] = event;
			});

			setWorkflowList(
				workflowListData.map((workflow) => ({
					...workflow,
					module: sourcesMap[workflow.triggerSource],
					executeOn: eventsMap[workflow.triggerEvent],
				}))
			);
		}
	}, [workflowListData, events, sources]);

	const toggleSort = (sortIndex, isSortEnabled) => {
		if (!isSortEnabled) {
			return;
		}

		let sortRequest = {};

		if (tableHeader[sortIndex].sort === sortTypes.ascending) {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.decending,
			};
		} else {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.ascending,
			};
		}
		setSortData(sortRequest);
		setTableHeader((headers) => {
			const updatedHeaders = [...headers];

			updatedHeaders.forEach((header, index) => {
				if (index === sortIndex) {
					header.sort =
						header.sort === sortTypes.ascending
							? sortTypes.decending
							: sortTypes.ascending;
				} else {
					header.sort = sortTypes.decending;
				}
			});

			return updatedHeaders;
		});
	};

	return (
		<React.Fragment>
			<Box p={1} pt={0} height="100%">
				<Box
					sx={{
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
						height: "100%",
						overflow: "auto",
					}}
				>
					<Box
						style={{
							width: isFilterMenuOpened ? "80%" : "100%",
							transition: isFilterMenuOpened
								? theme.transitions.create("width", {
										easing: theme.transitions.easing
											.easeOut,
										duration:
											theme.transitions.duration
												.enteringScreen,
									})
								: theme.transitions.create("width", {
										easing: theme.transitions.easing.sharp,
										duration:
											theme.transitions.duration
												.leavingScreen,
									}),
						}}
					>
						<TableContainer>
							<Table sx={{ minWidth: 650 }} size="small">
								<TableHead>
									<TableRow sx={{ height: "36px" }}>
										{tableHeader.map((header, index) => (
											<TableCell
												width={header.width}
												key={header.id}
												onClick={() =>
													toggleSort(
														index,
														header.isSortEnabled
													)
												}
											>
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<TableHeaderLabel>
														{header.displayName}
													</TableHeaderLabel>

													{header.isSortEnabled ? (
														header.sort ===
														sortTypes.ascending ? (
															<Box
																display={"flex"}
																style={{
																	transform:
																		"rotate(180deg)",
																}}
															>
																{DropDownIcon(
																	12,
																	12,
																	"rgba(0, 0, 0, 0.6)"
																)}
															</Box>
														) : (
															<Box
																display={"flex"}
															>
																{DropDownIcon(
																	12,
																	12,
																	"rgba(0, 0, 0, 0.6)"
																)}{" "}
															</Box>
														)
													) : (
														""
													)}
												</Stack>
											</TableCell>
										))}
									</TableRow>
								</TableHead>

								<TableBody>
									{isWorkflowListLoading ? (
										new Array(10)
											.fill(0)
											.map((_, index) => (
												<TableRow key={index}>
													<TableCell padding="checkbox">
														<Skeleton
															variant="text"
															sx={{
																fontSize:
																	"13px",
															}}
														/>
													</TableCell>

													{tableHeader
														.slice(1)
														.map((data, index) => (
															<TableCell
																key={index}
																style={{
																	minWidth:
																		data.width,
																}}
															>
																<Skeleton
																	variant="text"
																	sx={{
																		fontSize:
																			"13px",
																	}}
																/>
															</TableCell>
														))}
												</TableRow>
											))
									) : !!workflowList &&
									  workflowList?.length > 0 ? (
										workflowList.map((workflow, index) => (
											<AutomationRow
												key={index}
												workflow={workflow}
											/>
										))
									) : (
										<TableRow>
											<TableCell
												colSpan={7}
												sx={{ borderBottom: "none" }}
											>
												<Stack
													alignItems="center"
													justifyContent="center"
													style={{
														position: "absolute",
														top: "60%",
														left: "35%",
													}}
												>
													<Typography
														fontSize={17}
														fontWeight={500}
														color="rgba(0, 0, 0, 0.6)"
													>
														No Automations added yet
													</Typography>
													<Typography
														fontSize={15}
														color="rgba(0, 0, 0, 0.6)"
													>
														{
															"Explore the Power of Automation: Get Started by Adding Automations!"
														}
													</Typography>
												</Stack>
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										<TableCell
											colSpan={6}
											sx={{ borderBottom: "none" }}
										>
											<Box ref={lastRowRef}>
												{!isWorkflowListLoading &&
													isFetching && (
														<Stack
															direction="row"
															alignItems="center"
															justifyContent="center"
															spacing={1}
															py={2}
														>
															<CircularProgress
																size={18}
															/>

															<Typography
																fontSize={12}
															>
																Loading More
																Data
															</Typography>
														</Stack>
													)}
											</Box>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}

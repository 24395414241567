import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	Skeleton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as ShareIcon } from "../../../../assets/icons/share";
import { default as EditIcon } from "../../../../assets/icons/edit";
import { default as MoreIcon } from "../../../../assets/icons/more";
import { default as FavouriteOffIcon } from "../../../../assets/icons/favouriteOff";
import { useGetDashboard } from "../../../../hooks/services/analytics";
import Charts from "../Charts";

export default function DashboardView() {
	const theme = useTheme();
	const navigate = useNavigate();
	let { dashboardId } = useParams();
	const { data: dashboardData, isLoading: isDashboardDataLoading } =
		useGetDashboard(dashboardId, false);

	return (
		<React.Fragment>
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
				}}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					p={1.5}
				>
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<IconButton
							size="small"
							onClick={() => {}}
							disabled={isDashboardDataLoading}
						>
							{FavouriteOffIcon(20, 20, "rgba(0, 0, 0, 0.2)")}
						</IconButton>

						{isDashboardDataLoading ? (
							<Skeleton
								variant="text"
								width={250}
								sx={{
									fontSize: "13px",
								}}
							/>
						) : (
							<Typography fontWeight={500} fontSize={15}>
								{dashboardData.dashboardName}
							</Typography>
						)}
					</Stack>

					{isDashboardDataLoading ? (
						<Stack direction="row" alignItems="center" spacing={2}>
							<Skeleton
								variant="rectangular"
								width={92}
								height={36}
								sx={{
									borderRadius: "6px",
								}}
							/>

							<Skeleton
								variant="rectangular"
								width={92}
								height={36}
								sx={{
									borderRadius: "6px",
								}}
							/>

							<Skeleton
								variant="circular"
								width={36}
								height={36}
							/>
						</Stack>
					) : (
						<Stack direction="row" alignItems="center" spacing={2}>
							<Button
								variant="contained"
								color="secondary"
								startIcon={ShareIcon(
									20,
									20,
									theme.palette.primary.main
								)}
								disableElevation
							>
								Share
							</Button>

							<Button
								variant="contained"
								color="secondary"
								startIcon={EditIcon(
									20,
									20,
									theme.palette.primary.main
								)}
								onClick={() => {
									navigate(
										`/analytics/dashboard/builder/${dashboardId}`
									);
								}}
								disableElevation
							>
								Edit
							</Button>

							<IconButton size="small">
								{MoreIcon(20, 20, theme.palette.primary.main)}
							</IconButton>
						</Stack>
					)}
				</Stack>

				<Divider />

				<Box
					style={{
						backgroundColor: "#f4f5f5",
						flex: "1 1 auto",
						minHeight: 0,
						overflow: "auto",
						padding: "16px",
					}}
				>
					{isDashboardDataLoading ? (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							height="100%"
							spacing={2}
						>
							<CircularProgress size={32} />
							<Typography>Loading</Typography>
						</Stack>
					) : dashboardData.childViewInfo?.length > 0 ? (
						<Grid container spacing={2}>
							{dashboardData.childViewInfo.map((chartData) => (
								<Grid
									item
									key={chartData.viewId}
									md={12}
									lg={6}
								>
									<Box
										style={{
											padding: "8px",
											borderRadius: "6px",
											backgroundColor: "#fff",
											height: "380px",
										}}
									>
										<Typography p={1} pb={2}>
											{chartData.viewName}
										</Typography>

										<Charts
											viewId={chartData.viewId}
											viewTypeId={chartData.viewTypeId}
											dashboardId={dashboardId}
										/>
									</Box>
								</Grid>
							))}
						</Grid>
					) : (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							height="100%"
							spacing={2}
						>
							<Typography fontSize={17}>
								No chart added to the dashboard
							</Typography>
						</Stack>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}

import { validateEmailField } from "./FieldValidationMethods/validateEmailField";
import { validateMultipleEmailField } from "./FieldValidationMethods/validateMultipleEmailField";
import { validateMultiplePhonesField } from "./FieldValidationMethods/validateMultiplePhonesField";
import { validateNonEmptyField } from "./FieldValidationMethods/validateNonEmptyField";
import { validateNumberField } from "./FieldValidationMethods/validateNumberField";
import { validatePhoneField } from "./FieldValidationMethods/validatePhoneField";
import { validateStringField } from "./FieldValidationMethods/validateStringField";

export const handleFieldValidation = (fields, isActionField) => {
	if (!Array.isArray(fields)) return [];

	let updatedFields = [];

	const initializeError = (field) => {
		field.error = field.error || { error: false, message: "" };
	};

	fields.forEach((field) => {
		initializeError(field);
		let config = field.field?.config || field.config;

		if (field.hasValue || field.comparator?.hasValue || isActionField) {
			switch (field.fieldType) {
				case "SINGLE_STRING":
				case "IDENTIFIER":
				case "ADDRESS":
				case "TEXT":
					field.error = validateStringField(
						field,
						config,
						isActionField
					);
					break;
				case "EMAIL":
					field.error = validateEmailField(
						field,
						config,
						isActionField
					);
					break;
				case "PHONE":
					field.error = validatePhoneField(
						field,
						config,
						isActionField
					);
					break;
				case "DEAL_VALUE":
				case "SINGLE_NUMBER":
				case "NUMBER_PICKER":
				case "MONETARY":
					field.error = validateNumberField(
						field,
						config,
						isActionField
					);
					break;

				case "OWNER":
				case "CONTACT":
				case "COMPANY":
				case "PRODUCT":
				case "TAGS":
				case "CONTACTS_ONBOARD":
				case "CONTACT_ONBOARD":
				case "COMPANY_ONBOARD":
				case "DEAL":
				case "TERRITORY":
				case "DROPDOWN":
				case "DUAL_PICKER":
				case "MULTI_SELECT":
				case "DUAL_PICKERS":
				case "MULTI_DROPDOWN":
					field.error = validateNonEmptyField(
						field,
						config,
						isActionField
					);
					break;

				case "NUMBER_PICKERS":
				case "PHONES":
					field.error = validateMultiplePhonesField(
						field,
						config,
						isActionField
					);
					break;

				case "EMAILS":
				case "TEXT_PICKERS":
					field.error = validateMultipleEmailField(
						field,
						config,
						isActionField
					);
					break;

				default:
					break;
			}
		} else {
			field.error = { error: false, message: "" };
		}

		updatedFields.push({ ...field });
	});

	return updatedFields;
};

import React from "react";
import { CustomisedDialog } from "../../../styles/twozo";
import { default as CloseIcon } from "../../../assets/icons/close";
import {
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";

export default function Dialog(props) {
	const { title, subtitle, children, onClose, ...other } = props;
	const theme = useTheme();

	return (
		<React.Fragment>
			<CustomisedDialog {...other}>
				{title ? (
					<DialogTitle>
						<Stack p={2} pb={1.5}>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								spacing={2}
							>
								<Typography fontWeight={600} fontSize={17}>
									{title}
								</Typography>

								{props.onClose ? (
									<IconButton
										onClick={onClose}
										size="small"
										style={{ padding: 0 }}
									>
										{CloseIcon(
											25,
											25,
											theme.palette.primary.main
										)}
									</IconButton>
								) : null}
							</Stack>

							<Typography fontSize={14} style={{ opacity: 0.6 }}>
								{subtitle}
							</Typography>
						</Stack>

						<Divider />
					</DialogTitle>
				) : null}

				<DialogContent>
					{/*{children}*/}
					{React.cloneElement(children, { onClose })}
				</DialogContent>
			</CustomisedDialog>
		</React.Fragment>
	);
}

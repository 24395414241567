import React from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import FormField from "./FormField";
import { getDropdownDataByName } from "../../../../../../utils/dropdownData";
import { webFormCustomizeOptions } from "../../../../../../utils/WebFormUtils";
import { default as TickIcon } from "../../../../../../assets/icons/tick";
import { useFieldList } from "../../../../../../hooks/services/webform";

export default function FormComponent(props) {
	const { formToPreview, type } = props;
	const theme = useTheme();

	const { data: fieldList } = useFieldList();

	const alignments = getDropdownDataByName("ALIGNMENTS");
	const fieldAlignments = getDropdownDataByName("FIELD_ALIGNMENTS");

	const getSubmitButtonAlignment = (id) => {
		const alignmentData = alignments.find(
			(alignment) => alignment.value === id
		);
		return alignmentData?.name?.toLowerCase();
	};

	const getFieldAlignment = (id) => {
		const fieldAlignmentData = fieldAlignments.find(
			(fieldAlignment) => fieldAlignment.value === id
		);
		return fieldAlignmentData?.name?.toLowerCase();
	};

	const styles = {
		formContent: {
			border: "1px solid rgba(51, 188, 126, 0.24)",
			borderRadius: "8px",
			backgroundColor: formToPreview?.style?.formStyle?.backgroundColor,
			p: 2,
			width: "88%",
			boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
		},
	};

	const formStyle = {
		labelColor: {
			color: formToPreview?.style?.fieldStyle?.label.color,
			fontSize: formToPreview?.style?.fieldStyle?.label.fontSize,
			textAlign: getFieldAlignment(
				formToPreview?.style?.formStyle?.alignmentId
			),
		},
		lableInput: {
			fontSize: formToPreview?.style?.fieldStyle?.input?.fontSize,
			color: formToPreview?.style?.fieldStyle?.input?.textColor,
			backgroundColor:
				formToPreview?.style?.fieldStyle?.input?.backgroundColor,
			textAlign: getFieldAlignment(
				formToPreview?.style?.formStyle?.alignmentId
			),
		},
		headerStyle: {
			fontSize: formToPreview?.style?.formStyle?.header?.fontSize,
			color: formToPreview?.style?.formStyle?.header?.textColor,
			textAlign: getFieldAlignment(
				formToPreview?.style?.formStyle?.alignmentId
			),
		},
		subHeaderStyle: {
			fontSize: formToPreview?.style?.formStyle?.subHeader?.fontSize,
			color: formToPreview?.style?.formStyle?.subHeader?.textColor,
			textAlign: getFieldAlignment(
				formToPreview?.style?.formStyle?.alignmentId
			),
		},
		submitButtonStyle: {
			color: formToPreview?.style?.submitButtonStyle?.textColor,
			backgroundColor:
				formToPreview?.style?.submitButtonStyle?.backgroundColor,
			justifyContent: getSubmitButtonAlignment(
				formToPreview?.style?.submitButtonStyle?.alignmentId
			),
		},
	};

	return (
		<React.Fragment>
			<Stack alignItems="center" justifyContent="center" py={3}>
				<Stack
					sx={{
						...styles.formContent,
					}}
				>
					{formToPreview?.onSubmit?.thankYouMessage?.isEnabled &&
					type === webFormCustomizeOptions.submitOptions ? (
						<Stack
							minHeight="400px"
							direction="row"
							alignItems="center"
							justifyContent="center"
						>
							<Stack
								height="100%"
								alignItems="center"
								justifyContent="center"
								spacing={2}
							>
								<Stack
									borderRadius="100%"
									p={4}
									backgroundColor="rgba(0, 0, 0, 0.06)"
								>
									{TickIcon(
										48,
										48,
										theme.palette.primary.main
									)}
								</Stack>
								<Stack
									alignItems="center"
									justifyContent="center"
								>
									<Typography fontSize={17} fontWeight={500}>
										{
											formToPreview?.onSubmit
												?.thankYouMessage?.message
										}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					) : (
						<Box>
							{formToPreview?.formName ? (
								<Typography
									pl={1}
									fontSize="17px"
									fontWeight={600}
								>
									{formToPreview.formName}
								</Typography>
							) : (
								<Typography
									sx={{
										pl: 1,
										fontSize: 17,
										fontWeight: 600,
										opacity: 0.6,
									}}
								>
									Form Name
								</Typography>
							)}

							<Stack spacing={2} pt={3}>
								{formToPreview?.formProperties?.map(
									(formProperty, index) => (
										<Box
											key={index}
											hidden={
												formProperty.isFieldNotSaved
											}
										>
											<Stack spacing={0.5}>
												<FormField
													formProperty={formProperty}
													fieldList={fieldList}
													formStyle={formStyle}
													isField={
														formProperty.type ===
														"FIELD"
													}
												/>
											</Stack>
										</Box>
									)
								)}
							</Stack>

							<Stack
								direction="row"
								pt={3}
								justifyContent={
									formStyle.submitButtonStyle.justifyContent
								}
							>
								<Button
									sx={formStyle.submitButtonStyle}
									disableElevation
									variant="contained"
								>
									{formToPreview?.submitButtonName
										? formToPreview.submitButtonName
										: "Submit"}
								</Button>
							</Stack>
						</Box>
					)}
				</Stack>
			</Stack>
		</React.Fragment>
	);
}

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getFieldDropdownData } from "../../../../api/fields/config/configApi";
import {
	getAdditionalOptionKey,
	getAppliedFilterKey,
	getComparatorForAdditionalOptionKey,
	getComparatorKey,
	getFilterFieldListKey,
	getFilterListKey,
	getSalesOwnerFilterListKey,
	getUserTeamTerritoryListKey,
	getValueDropdownKey,
	sourceListKey,
} from "../../../../utils/queryKeys";
import {
	deleteFilter,
	getAdditionalOptionData,
	getComparatorData,
	getComparatorListForAdditionalOptions,
	getFilterData,
	getFilterFieldListData,
	getFilterList,
	getSalesOwnerFilterList,
	getSourceListData,
	getUserTeamTerritoryList,
	saveViewFilter,
	updateFavouriteFilter,
	updateFilter,
} from "../../../../api/filter/filterApi";

//Filter SourceList
const useSourceList = () => {
	return useQuery(sourceListKey, () => getSourceListData(), {
		select: (sourceList) => sourceList?.options,
		staleTime: Infinity,
		refetchOnWindowFocus: false,
	});
};

//Filter FieldList
const useFilterFieldList = (sourceId) => {
	return useQuery(
		getFilterFieldListKey(sourceId),
		() => getFilterFieldListData(sourceId),
		{
			enabled: !!sourceId,
			select: (fieldList) => fieldList?.options,
		}
	);
};

//Comparator
const useComparator = (fieldTypeId) => {
	return useQuery(
		getComparatorKey(fieldTypeId),
		() => getComparatorData(fieldTypeId),
		{
			enabled: !!fieldTypeId,
			select: (comparatorList) => comparatorList?.options,
		}
	);
};

const useComparatorForAdditionOption = (fieldSubTypeId) => {
	return useQuery(
		getComparatorForAdditionalOptionKey(fieldSubTypeId),
		() => getComparatorListForAdditionalOptions(fieldSubTypeId),
		{
			enabled: !!fieldSubTypeId,
			select: (comparatorList) => comparatorList?.options,
		}
	);
};

//Additional Options
const useAdditionalOptions = (fieldTypeId, enableAdditionalOption) => {
	return useQuery(
		getAdditionalOptionKey(fieldTypeId),
		() => getAdditionalOptionData(fieldTypeId),
		{
			enabled: !!enableAdditionalOption,
			select: (additionaloptions) => additionaloptions?.options,
		}
	);
};

//ValueDropDown
const useValueDropDown = (requestData, fieldId, enableDropdown) => {
	return useQuery(
		getValueDropdownKey(fieldId),
		() => getFieldDropdownData(requestData),
		{
			enabled: enableDropdown,
			select: (dropDownOptions) => dropDownOptions?.options,
		}
	);
};

//filter list
const useFilterList = (sourceId) => {
	return useQuery(getFilterListKey(sourceId), () => getFilterList(sourceId), {
		enabled: !!sourceId,
		select: (filterList) => filterList?.options,
	});
};

//SalesOwner Filter List
const useSalesOwnerFilterList = (sourceId) => {
	return useQuery(
		getSalesOwnerFilterListKey(sourceId),
		() => getSalesOwnerFilterList(sourceId),
		{
			enabled: !!sourceId,
			select: (salesOwnerfilterList) => salesOwnerfilterList?.options,
		}
	);
};

//Get Filter Conditions by. Id
const useFilterData = (filterId) => {
	return useQuery(
		getAppliedFilterKey(filterId),
		() => getFilterData(filterId),
		{
			enabled: !!filterId,
		}
	);
};

//User Team Territory List
const useUserTeamTerritoryList = () => {
	return useQuery(
		getUserTeamTerritoryListKey(),
		() => getUserTeamTerritoryList(),
		{
			select: (data) => data?.options,
		}
	);
};

//Invalidate Filter List
const useInvalidateFilterList = (sourceId) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getFilterListKey(sourceId));
	};
};

// Invalidate Filter Source List
const useInvalidateFilterSourceList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(sourceListKey);
	};
};

//Update Filter Favourite
const useUpdateFavouriteFilterMutation = (sourceId) => {
	let invalidateModuleList = useInvalidateFilterList(sourceId);
	return useMutation(updateFavouriteFilter, {
		onSuccess: () => {
			invalidateModuleList(sourceId);
		},
	});
};

//Save view
const useSaveFilterMutation = () => {
	return useMutation(saveViewFilter);
};

//Update Filter
const useUpdateFilterMutation = (sourceId) => {
	let invalidateModuleList = useInvalidateFilterList(sourceId);
	return useMutation(updateFilter, {
		onSuccess: () => {
			invalidateModuleList(sourceId);
		},
	});
};

//Delete Filter
const useDeleteFilterMutation = (sourceId) => {
	let invalidateModuleList = useInvalidateFilterList(sourceId);
	return useMutation(deleteFilter, {
		onSuccess: () => {
			invalidateModuleList(sourceId);
		},
	});
};

export {
	useSourceList,
	useFilterFieldList,
	useComparator,
	useAdditionalOptions,
	useValueDropDown,
	useFilterList,
	useInvalidateFilterList,
	useUpdateFavouriteFilterMutation,
	useSaveFilterMutation,
	useUpdateFilterMutation,
	useFilterData,
	useDeleteFilterMutation,
	useUserTeamTerritoryList,
	useSalesOwnerFilterList,
	useComparatorForAdditionOption,
	useInvalidateFilterSourceList,
};

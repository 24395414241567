import { useCallback, useReducer } from "react";

export const fieldActionTypes = {
	createField: "CREATE_FIELD",
	updateField: "UPDATE_FIELD",
	errorOnUpdate: "ERROR_ON_UPDATE",
	errorOnCreate: "ERROR_ON_CREATE",
	resetUpdateMode: "RESET_UPDATE_ACTIONS",
	resetCreateMode: "RESET_CREATE_MODE",
};

export const fieldListReducer = (state, action) => {
	switch (action.type) {
		case fieldActionTypes.createField:
			return {
				...state,
				isAnyFieldInCreateMode: true,
			};
		case fieldActionTypes.errorOnCreate:
			return {
				...state,
				hasCreationError: true,
			};
		case fieldActionTypes.resetCreateMode:
			return {
				...state,
				isAnyFieldInCreateMode: false,
				hasCreationError: false,
			};
		case fieldActionTypes.updateField:
			return {
				...state,
				isAnyFieldInEditMode: true,
			};
		case fieldActionTypes.errorOnUpdate:
			return {
				...state,
				hasUpdateError: true,
			};
		case fieldActionTypes.resetUpdateMode:
			return {
				...state,
				isAnyFieldInEditMode: false,
				hasUpdateError: false,
			};
		default:
			state;
	}
};

const initialFieldListState = {
	isAnyFieldInCreateMode: false,
	isAnyFieldInEditMode: false,
	hasUpdateError: false,
	hasCreationError: false,
};

export const useFieldListReducer = () => {
	const [fieldListState, fieldListStateDispatcher] = useReducer(
		fieldListReducer,
		initialFieldListState
	);

	const updateCreateModeOnFieldCreation = useCallback(() => {
		fieldListStateDispatcher({ type: fieldActionTypes.createField });
	}, []);

	const updateEditModeOnFieldEdit = useCallback(() => {
		fieldListStateDispatcher({ type: fieldActionTypes.updateField });
	}, []);

	const errorOnUpdate = useCallback(() => {
		fieldListStateDispatcher({ type: fieldActionTypes.errorOnUpdate });
	}, []);

	const errorOnCreate = useCallback(() => {
		fieldListStateDispatcher({ type: fieldActionTypes.errorOnCreate });
	}, []);

	const resetCreateMode = useCallback(() => {
		fieldListStateDispatcher({ type: fieldActionTypes.resetCreateMode });
	}, []);

	const resetUpdateMode = useCallback(() => {
		fieldListStateDispatcher({ type: fieldActionTypes.resetUpdateMode });
	}, []);

	return {
		fieldListState,
		updateCreateModeOnFieldCreation,
		updateEditModeOnFieldEdit,
		errorOnCreate,
		errorOnUpdate,
		resetCreateMode,
		resetUpdateMode,
	};
};

import DateRange from "./FieldComponents/dateRange/dateRange";
import Dropdown from "./FieldComponents/dropdown";
import TimePeriod from "./FieldComponents/timePeriod/timePeriod";

export default function Field(params) {
	const { field, ...others } = params;

	const FieldComponents = {
		dropdown: Dropdown,
		dateRange: DateRange,
		timePeriod: TimePeriod,
	};

	const FieldComponent = FieldComponents[field.type];

	return (
		<>
			{FieldComponent ? (
				<FieldComponent field={field} {...others} />
			) : (
				"field Not implemented"
			)}
		</>
	);
}

import React, { useMemo } from "react";
import AddActivity from "../AddActivity";
import { useQueryClient } from "@tanstack/react-query";
import { removeFieldsWithEmptyValues } from "../../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { CircularProgress, Stack } from "@mui/material";
import {
	useActivityData,
	useEditActivity,
} from "../../../../hooks/services/activities";

export default function EditActivity(props) {
	const { onClose, activityId, toDoActivitesKey, completedActivitesKey } =
		props;
	const queryClient = useQueryClient();

	const { data: activityFormData, isLoading: isLoadingEditActivity } =
		useActivityData(activityId);
	const updateMutation = useEditActivity(activityId);

	const formattedActivityData = useMemo(() => {
		if (activityFormData) {
			let formData = { ...activityFormData };

			if (formData?.contacts?.length > 0) {
				let contactIds = [];
				for (let contact of formData.contacts) {
					contactIds.push(contact?.id);
				}
				formData.contactIds = contactIds;
			}

			if (formData?.availability?.name) {
				formData.availability = formData.availability.value;
			}

			if (formData?.reminder?.name) {
				formData.reminder = formData.reminder.value;
			}

			if (formData?.priority?.name) {
				formData.priority = formData.priority.value;
			}

			if (formData?.status?.name) {
				formData.status = formData.status.value;
			}

			if (formData?.guests?.length > 0) {
				let formattedGuestsData = {
					contacts: [],
					emails: [],
				};

				for (let guest of formData.guests) {
					if (guest.email && guest.email.value) {
						if (guest.id) {
							formattedGuestsData.contacts.push({
								id: guest.id,
								emailId: guest.email.id,
							});
						}

						if (!guest.id) {
							formattedGuestsData.emails.push({
								id: guest.email.id,
								email: guest.email.value,
							});
						}
					}
				}

				if (formattedGuestsData.contacts.length === 0) {
					delete formattedGuestsData.contacts;
				}

				if (formattedGuestsData.emails.length === 0) {
					delete formattedGuestsData.emails;
				}

				formData.guests = formattedGuestsData;
			}

			formData.typeId = formData?.activityType?.id;

			formData.companyData = formData?.company;

			formData.dealData = {
				name: formData?.deal?.title,
				value: formData?.deal?.id,
			};

			formData.note = formData?.note?.note;

			formData.assigneeId = formData?.assignee;

			delete formData.contacts;
			delete formData.activityType;
			delete formData.company;
			delete formData.deal;
			delete formData.assignee;

			return formData;
		}
	}, [activityFormData]);

	const handleUpdateActivity = (updatedActivityData) => {
		if (updatedActivityData?.assigneeId) {
			updatedActivityData.assigneeId =
				updatedActivityData.assigneeId?.value;
		}

		if (updatedActivityData?.companyData) {
			updatedActivityData.companyId =
				updatedActivityData.companyData?.value;
		}

		if (updatedActivityData?.dealData) {
			updatedActivityData.dealId = updatedActivityData.dealData?.value;
		}

		removeFieldsWithEmptyValues(updatedActivityData);
		updateMutation.mutate(updatedActivityData, {
			onSuccess: () => {
				queryClient.invalidateQueries(toDoActivitesKey);
				queryClient.invalidateQueries(completedActivitesKey);
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.activityUpdated,
				});
				onClose();
			},
		});
	};

	return (
		<React.Fragment>
			{isLoadingEditActivity ? (
				<Stack
					height="100vh"
					alignItems="center"
					justifyContent="center"
				>
					<CircularProgress size={30} />
				</Stack>
			) : (
				<AddActivity
					onUpdate={handleUpdateActivity}
					onClose={onClose}
					activityFormData={formattedActivityData}
					isActivityEditMode={true}
					updateInProgress={updateMutation.isLoading}
				/>
			)}
		</React.Fragment>
	);
}

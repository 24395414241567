import React from "react";
import { Typography } from "@mui/material";
import NewActivityTitle from "./NewActivityTitle";
import { notificationMessageComponents } from "../../../../../utils/notificationUtils";

export default function NotificationTitle(props) {
	const { notificationData, onClose, isNotificationActionEnabled } = props;

	const notificationTitle = {
		NEW_ACTIVITY: NewActivityTitle,
	};

	const NotificationTitleContent =
		notificationTitle?.[notificationData?.type];

	return (
		<React.Fragment>
			<Typography fontSize={15} fontWeight={400} noWrap>
				{NotificationTitleContent ? (
					<NotificationTitleContent
						notificationData={notificationData}
						onClose={onClose}
						isNotificationActionEnabled={
							isNotificationActionEnabled
						}
					/>
				) : (
					notificationMessageComponents?.[notificationData?.type]
						?.title
				)}
			</Typography>
		</React.Fragment>
	);
}

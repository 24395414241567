import React, { useState } from "react";
import {
	Box,
	CircularProgress,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as DealsIcon } from "../../../../assets/icons/deals";
import { default as ActivityIcon } from "../../../../assets/icons/activity";
import { default as ContactIcon } from "../../../../assets/icons/contact";
import { default as CompanyIcon } from "../../../../assets/icons/company";
import { TableCellText, TableHeaderLabel } from "../../../../styles/twozo";
import UnKnownImage from "../../../../assets/images/contact/unknownContact.png";
import { useWorkflowHistoryList } from "../../../../hooks/services/workflow";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";
import { getFormattedDateMonthYearAndTime } from "../../../../utils/DateUtils";
import { useNavigate } from "react-router-dom";

const initialTableHeaderData = [
	{
		id: 1,
		displayName: "Status",
		name: "status",
		isSortEnabled: true,
		width: "10%",
	},
	{
		id: 2,
		displayName: "Workflow Name",
		name: "workflowName",
		isSortEnabled: true,
		width: "30%",
	},
	{
		id: 3,
		displayName: "Module",
		name: "triggerSource",
		isSortEnabled: true,
		width: "15%",
	},
	{
		id: 4,
		displayName: "Execution Started on",
		name: "executedTime",
		isSortEnabled: true,
		width: "15%",
	},
	{
		id: 5,
		displayName: "Actions",
		name: "actions",
		isSortEnabled: true,
		width: "10%",
	},
	{
		id: 6,
		displayName: "Sales Owner",
		name: "salesOwner",
		isSortEnabled: true,
		width: "20%",
	},
];

const sortTypes = {
	ascending: "asc",
	descending: "desc",
};

export default function History(params) {
	const { isFilterMenuOpened, filterCriteria } = params;
	const theme = useTheme();
	const navigate = useNavigate();

	const [sortData, setSortData] = useState(null);
	const [tableHeader, setTableHeader] = useState(initialTableHeaderData);

	// query call:-
	const {
		data: historyList,
		isLoading: isHistoryListLoading,
		hasNextPage,
		isFetching,
		fetchNextPage,
	} = useWorkflowHistoryList(filterCriteria, sortData);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const getModuleData = (module) => {
		let moduleData = {
			name: "",
			icon: () => {},
		};

		switch (module) {
			case 1:
				moduleData["name"] = "Activity";
				moduleData["icon"] = ActivityIcon;
				break;
			case 2:
				moduleData["name"] = "Deals";
				moduleData["icon"] = DealsIcon;
				break;
			case 3:
				moduleData["name"] = "Contact";
				moduleData["icon"] = ContactIcon;
				break;
			case 4:
				moduleData["name"] = "Company";
				moduleData["icon"] = CompanyIcon;
				break;
			default:
				break;
		}

		return moduleData;
	};

	const toggleSort = (sortIndex, isSortEnabled) => {
		if (!isSortEnabled) {
			return;
		}

		let sortRequest = {};

		if (tableHeader[sortIndex].sort === sortTypes.ascending) {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.descending,
			};
		} else {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.ascending,
			};
		}
		setSortData(sortRequest);
		setTableHeader((headers) => {
			const updatedHeaders = [...headers];

			updatedHeaders.forEach((header, index) => {
				if (index === sortIndex) {
					header.sort =
						header.sort === sortTypes.ascending
							? sortTypes.descending
							: sortTypes.ascending;
				} else {
					header.sort = sortTypes.descending;
				}
			});

			return updatedHeaders;
		});
	};

	return (
		<React.Fragment>
			<Box p={1} pt={0} height="100%">
				<Box
					sx={{
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
						height: "100%",
						overflow: "auto",
					}}
				>
					<Box
						style={{
							width: isFilterMenuOpened ? "80%" : "100%",
							transition: isFilterMenuOpened
								? theme.transitions.create("width", {
										easing: theme.transitions.easing
											.easeOut,
										duration:
											theme.transitions.duration
												.enteringScreen,
									})
								: theme.transitions.create("width", {
										easing: theme.transitions.easing.sharp,
										duration:
											theme.transitions.duration
												.leavingScreen,
									}),
						}}
					>
						<TableContainer>
							<Table sx={{ minWidth: 650 }} size="small">
								<TableHead>
									<TableRow sx={{ height: "36px" }}>
										{tableHeader.map((header, index) => (
											<TableCell
												width={header.width}
												key={header.id}
												onClick={() =>
													toggleSort(
														index,
														header.isSortEnabled
													)
												}
											>
												<Stack
													direction="row"
													alignItems="center"
													spacing={1}
												>
													<TableHeaderLabel noWrap>
														{header.displayName}
													</TableHeaderLabel>

													{header.isSortEnabled ? (
														header.sort ===
														sortTypes.ascending ? (
															<Box
																display={"flex"}
																style={{
																	transform:
																		"rotate(180deg)",
																}}
															>
																{DropDownIcon(
																	12,
																	12,
																	"rgba(0, 0, 0, 0.6)"
																)}
															</Box>
														) : (
															<Box
																display={"flex"}
															>
																{DropDownIcon(
																	12,
																	12,
																	"rgba(0, 0, 0, 0.6)"
																)}{" "}
															</Box>
														)
													) : (
														""
													)}
												</Stack>
											</TableCell>
										))}
									</TableRow>
								</TableHead>

								<TableBody>
									{isHistoryListLoading ? (
										new Array(10)
											.fill(0)
											.map((_, index) => (
												<TableRow key={index}>
													{tableHeader.map(
														(data, index) => (
															<TableCell
																key={index}
																style={{
																	width: data.width,
																}}
															>
																<Skeleton
																	variant="text"
																	sx={{
																		fontSize:
																			"13px",
																	}}
																/>
															</TableCell>
														)
													)}
												</TableRow>
											))
									) : historyList?.length > 0 ? (
										historyList.map((history) => (
											<TableRow
												key={history.id}
												onClick={() =>
													navigate(
														`/settings/workflow/
															${history.workflowId}?
															preview=true&
															historyId=${history.id}
															`
													)
												}
											>
												<TableCell>
													{history.status ===
													"Success" ? (
														<Typography
															fontSize={15}
															color={
																theme.palette
																	.primary
																	.main
															}
														>
															Success
														</Typography>
													) : (
														<Typography
															fontSize={15}
															color={
																theme.palette
																	.error.main
															}
														>
															Failure
														</Typography>
													)}
												</TableCell>

												<TableCell>
													<Typography
														fontSize={15}
														fontWeight={500}
													>
														{history.name}
													</Typography>
												</TableCell>

												<TableCell>
													{(function () {
														const moduleData =
															getModuleData(
																history.triggerSource
															);
														return (
															<Stack
																direction="row"
																alignItems="center"
																spacing={1}
															>
																<Box display="flex">
																	{moduleData.icon(
																		20,
																		20,
																		"rgba(0, 0, 0, 0.6)"
																	)}
																</Box>

																<TableCellText>
																	{
																		moduleData.name
																	}
																</TableCellText>
															</Stack>
														);
													})()}
												</TableCell>

												<TableCell>
													<TableCellText>
														{getFormattedDateMonthYearAndTime(
															history.timeStamp
														)}
													</TableCellText>
												</TableCell>

												<TableCell>
													<TableCellText>
														{history.actions}
													</TableCellText>
												</TableCell>

												<TableCell>
													<Stack
														direction="row"
														alignItems="center"
														spacing={1}
													>
														<img
															src={UnKnownImage}
															alt="img"
															width={26}
															height={26}
														/>
														<TableCellText>
															{history.owner.name}
														</TableCellText>
													</Stack>
												</TableCell>
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell
												colSpan={6}
												sx={{ borderBottom: "none" }}
											>
												<Stack
													alignItems="center"
													justifyContent="center"
													style={{
														position: "absolute",
														top: "60%",
														left: "40%",
													}}
												>
													<Typography
														fontSize={17}
														fontWeight={500}
														color="rgba(0, 0, 0, 0.6)"
													>
														No automation executions
														found
													</Typography>
													<Typography
														fontSize={15}
														color="rgba(0, 0, 0, 0.6)"
													>
														Try resetting your
														filters or create new
														automation
													</Typography>
												</Stack>
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										<TableCell
											colSpan={6}
											sx={{ borderBottom: "none" }}
										>
											<Box ref={lastRowRef}>
												{!isHistoryListLoading &&
													isFetching && (
														<Stack
															direction="row"
															alignItems="center"
															justifyContent="center"
															spacing={1}
															py={2}
														>
															<CircularProgress
																size={18}
															/>

															<Typography
																fontSize={12}
															>
																Loading More
																Data
															</Typography>
														</Stack>
													)}
											</Box>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}

import React, { useCallback, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import AddNewContact from "../../../../../../Deals/AddNewDeals/AddNewContact";
import { removeFieldsWithEmptyValues } from "../../../../../../../utils/common";
import ContactAutoComplete from "../../../../../ContactAutoComplete";

export function ContactOnBoardBaseComponent(props) {
	const {
		field,
		onContactInputChange,
		inputRef,
		value,
		error,
		clearErrors,
		formValues,
		onChangeFieldValueLabel,
	} = props;

	const [isAddNewContact, setIsAddNewContact] = useState(false);
	const [selectedContact, setSelectedContact] = useState("");

	const relatedFieldId = field?.relatedField?.id;
	const relatedContactIds = formValues?.[field?.config?.associatedWith] || [];

	const contactFormData = {
		[relatedFieldId]: selectedContact?.name,
	};

	const handleUpdateContact = useCallback(
		(value) => {
			setSelectedContact(value);
			onContactInputChange(value?.value || "");
			onChangeFieldValueLabel
				? onChangeFieldValueLabel({
						[field?.id]: value?.name ?? "",
					})
				: null;
		},
		[field?.id, onChangeFieldValueLabel, onContactInputChange]
	);

	useEffect(() => {
		// This useEffect is used to handle the edit case only:
		// - It displays the label in the edit mode by setting the selected contact label.
		// - It also processes server-side data in the edit case by formatting and setting `value`.
		// If `value` is an object with a `name` property, we assume it's valid data from the server or edit case,
		// so `setSelectedContact` is called to set the label accordingly.

		const isValidServerSideData = (value) => {
			return typeof value === "object" && Boolean(value?.name);
		};

		if (isValidServerSideData(value)) {
			handleUpdateContact(value);
		}
	}, [value, handleUpdateContact]);

	const onClearErrors = () => {
		clearErrors(field.id);
	};

	const updateContactValue = (contactValue) => {
		handleUpdateContact(contactValue ?? "");
	};

	const handleSelectedContacts = (value) => {
		if (!value) {
			return;
		}
		let selectedContact = { ...value };

		if (selectedContact?.isNewOption) {
			delete selectedContact?.isNewOption;
			setSelectedContact(selectedContact);
			setIsAddNewContact(true);
		} else {
			updateContactValue(selectedContact);
		}
		onClearErrors();
	};

	const removeContactValue = () => {
		updateContactValue();
	};

	const onAddNewContact = (formSubmitData) => {
		removeFieldsWithEmptyValues(formSubmitData);
		onContactInputChange(formSubmitData);

		const contactValue = {
			...selectedContact,
			name: formSubmitData[relatedFieldId],
		};
		onChangeFieldValueLabel({
			[field?.id]: formSubmitData[relatedFieldId],
		});
		setSelectedContact(contactValue);
		setIsAddNewContact(false);
		onClearErrors();
	};

	const onCloseAddNewContactForm = () => {
		setIsAddNewContact(false);
		setSelectedContact("");
	};

	return (
		<React.Fragment>
			<Stack spacing={1}>
				{isAddNewContact ? (
					<AddNewContact
						onSubmit={onAddNewContact}
						onClose={onCloseAddNewContactForm}
						contactFormData={contactFormData}
					></AddNewContact>
				) : (
					<ContactAutoComplete
						placeHolder={field?.placeHolder}
						value={selectedContact}
						onChange={handleSelectedContacts}
						onCancel={removeContactValue}
						isAllowNewOption={true}
						inputRef={inputRef}
						error={error}
						clearError={onClearErrors}
						filterCriteria={{
							excludeContactIds: relatedContactIds,
						}}
					/>
				)}
			</Stack>
		</React.Fragment>
	);
}

import React from "react";
import Field from "../Field";
import { Box } from "@mui/material";

export default function FieldGroup(props) {
	const {
		field,
		editingFieldRef,
		changeFieldType,
		moduleName,
		clearCreatedField,
		dragHandleProps,
		handleOpenDeleteDialog,
		fieldTypeOption,
	} = props;
	const isMultipleFieldColumn = field.length > 1;

	const getMultipleFieldColumn = () => {
		return (
			<React.Fragment>
				{field.map((fieldColumn, fieldIndex) => {
					let isGroupedFieldHead = fieldIndex === 0;
					let isGroupedFieldFoot = fieldIndex === field.length - 1;

					return (
						<Box
							key={fieldColumn.id}
							sx={{
								border: (theme) =>
									`1px solid ${theme.palette.divider}`,
								borderBottom: (theme) =>
									isGroupedFieldFoot
										? `1px solid ${theme.palette.divider}`
										: "none",
								borderRadius: isGroupedFieldHead
									? "8px 8px 0px 0px"
									: isGroupedFieldFoot
										? "0px 0px 8px 8px"
										: "0px",
							}}
						>
							<Field
								editingFieldRef={editingFieldRef}
								field={fieldColumn}
								moduleName={moduleName}
								isDependableField={true}
								changeFieldType={changeFieldType}
								dragHandleProps={dragHandleProps}
								fieldTypeOption={fieldTypeOption}
								clearCreatedField={clearCreatedField}
								shouldShowFieldActions={isGroupedFieldHead}
								handleOpenDeleteDialog={handleOpenDeleteDialog}
							/>
						</Box>
					);
				})}
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			{field ? (
				isMultipleFieldColumn ? (
					getMultipleFieldColumn()
				) : (
					<Field
						editingFieldRef={editingFieldRef}
						field={field[0]}
						moduleName={moduleName}
						dragHandleProps={dragHandleProps}
						shouldShowFieldActions={true}
						changeFieldType={changeFieldType}
						fieldTypeOption={fieldTypeOption}
						clearCreatedField={clearCreatedField}
						handleOpenDeleteDialog={handleOpenDeleteDialog}
					/>
				)
			) : null}
		</React.Fragment>
	);
}

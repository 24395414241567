import React, { useState } from "react";
import { Box, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { default as DropDownIcon } from "../../../../../../../../assets/icons/dropDown";
import Menu from "../../../../../../Menu";
import { useFilterDataContext } from "../../../../Context/FilterDataContext";
import { useFilterFieldList } from "../../../../../../../../hooks/services/common/table/filter";
import MenuSearchBar from "../../../../../../MenuSearchBar";
import { MenuItemText } from "../../../../../../../../styles/twozo";

export default function Field(props) {
	const { filterCondition, onChange } = props;

	const theme = useTheme();

	const styles = {
		data: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			height: "38px",
			padding: "0px 10px",
			backgroundColor: "rgba(51, 188, 126, 0.12)",
		},
	};

	const { enableApplyButton } = useFilterDataContext();
	const selectedSource = filterCondition?.sourceId.value || {};

	const { data: fieldList } = useFilterFieldList(selectedSource?.id);

	const [fieldListMenuElement, setFieldListMenuElement] = useState(null);
	const isOpenFieldListMenu = Boolean(fieldListMenuElement);
	const [searchValue, setSearchValue] = useState("");

	const openFieldMenu = (event) => {
		setFieldListMenuElement(event.currentTarget);
		setSearchValue("");
	};

	const closeFieldMenu = () => {
		setFieldListMenuElement(null);
	};

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const getFilteredFieldList = () => {
		if (searchValue.trim()) {
			return fieldList?.filter((field) =>
				field.name.toLowerCase().startsWith(searchValue.toLowerCase())
			);
		}
		return fieldList;
	};

	const filteredFieldList = getFilteredFieldList();

	const handleSelectField = (selectedField) => {
		const updatedFilterCondition = {
			...filterCondition,
			comparatorId: {
				...filterCondition.comparatorId,
				value: { id: "", name: "" },
			},
			additionalOption: {
				...filterCondition.additionalOption,
				displayName: "",
				value: { name: "", entityId: "" },
			},
			valueEntityId: { ...filterCondition.valueEntityId, value: "" },
			value: { ...filterCondition.value, value: "" },
			fieldData: selectedField,
			fieldId: {
				...filterCondition.fieldId,
				value: {
					id: selectedField.fieldId || "",
					name: selectedField.fieldId ? selectedField.name : "",
				},
			},
			optionId: {
				...filterCondition.optionId,
				value: {
					id: selectedField.optionId || "",
					name: selectedField.optionId ? selectedField.name : "",
				},
			},
			multiSelectValues: [],
			singleSelectValue: {},
		};
		onChange(updatedFilterCondition);
		enableApplyButton();
		setFieldListMenuElement(null);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			{/* fieldList */}
			<Menu
				minWidth="202px"
				anchorEl={fieldListMenuElement}
				open={isOpenFieldListMenu}
				onClose={closeFieldMenu}
				style={{ transform: "translateX(8px) translateY(-1px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				<Box
					p={0.5}
					marginTop="-8px"
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "#fff",
					}}
				>
					<MenuSearchBar
						value={searchValue}
						onChange={handleSearch}
					/>
				</Box>
				{filteredFieldList?.length > 0
					? filteredFieldList?.map((field, fieldIndex) => (
							<MenuItem
								style={{ height: "40px" }}
								key={fieldIndex}
								onClick={() => handleSelectField(field)}
							>
								<MenuItemText>{field.name}</MenuItemText>
							</MenuItem>
						))
					: renderEmptyView()}
			</Menu>

			<Stack
				direction="row"
				style={{
					...styles.data,
					cursor: "pointer",
					borderRadius:
						filterCondition.fieldId.value?.name ||
						filterCondition.optionId.value?.name
							? "8px 8px 0px 0px"
							: "8px 8px 8px 8px",
					borderBottom:
						(fieldListMenuElement
							? `1px solid ${theme.palette.primary.main}`
							: null) ||
						(filterCondition.fieldId.value?.name ||
						filterCondition.optionId.value?.name
							? "1px solid rgba(0,0,0,0.1)"
							: null),
				}}
				onClick={(event) => openFieldMenu(event)}
			>
				{filterCondition.fieldId.value?.name ||
				filterCondition.optionId.value?.name ? (
					<Typography fontSize={14} noWrap>
						{filterCondition.fieldId.value?.name
							? filterCondition.fieldId.value?.name
							: filterCondition.optionId.value?.name}
					</Typography>
				) : (
					<Typography
						sx={{
							fontSize: "14px",
							color: "rgba(0,0,0,0.4)",
						}}
					>
						Select Field
					</Typography>
				)}

				<Box sx={{ pl: 1 }}>{DropDownIcon(12, 12)}</Box>
			</Stack>
		</React.Fragment>
	);
}

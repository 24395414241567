import React, { useMemo, useState, useCallback } from "react";
import ContactMenu from "./Menu";
import SelectedMenu from "./SelectedMenu";
import Table from "../Elements/Table";
import { sourceType } from "../../utils/queryKeys";
import { useNavigate } from "react-router-dom";
import { modules } from "../../utils/common/ModulesName";
import ImportModuleDialog from "../Elements/ImportModuleDialog";
import { FilterSaveViewProvider } from "../Elements/Table/Filter/Context/FilterSaveViewContext";
import { FilterDataProvider } from "../Elements/Table/Filter/Context/FilterDataContext";
import { default as MailIcon } from "../../assets/icons/mail";
import { default as CallIcon } from "../../assets/icons/call";
import { default as TaskIcon } from "../../assets/icons/task";
import { default as EditIcon } from "../../assets/icons/edit";
import { default as DeleteIcon } from "../../assets/icons/delete";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../utils/notification/notificationConfig";
import { notificationMessage } from "../../utils/notification/notificationMessages";
import CustomSwipeableDrawer from "../Elements/CustomSwipeableDrawer";
import EditContact from "./EditContact";
import { Box } from "@mui/material";
import AddActivity from "../Summary/Activities/AddActivity";
import EmailComposerDrawer from "../Mail/EmailComposer/EmailComposerDrawer";
import { twozoStyles } from "../../styles/twozo";
import { useDeleteContact } from "../../hooks/services/contact";
import DeleteDialog from "../Elements/DeleteDialog";
import { useAuth } from "../../hooks/auth";
import { PERMISSIONS } from "../../utils/Auth";
import { useModuleName } from "../../hooks/modules";
import { tableActions } from "../../utils/tableUtills";

const noResultsMessage = {
	title: "No Contacts Found",
	message: "Try resetting your filters or create new contact",
};

export default function Contacts() {
	const navigate = useNavigate();
	const classes = twozoStyles();

	const [selectedRowData, setSelectedRowData] = useState(null);
	const [isEditFormDialogOpen, setIsEditFormDialogOpen] = useState(false);
	const [isEmailComposerOpened, setIsEmailComposerOpened] = useState(false);
	const [openAddActivityDialog, setOpenAddActivityDialog] = useState(false);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [openImportContactsDialog, setOpenImportContactsDialog] =
		useState(false);

	const { getModuleName } = useModuleName();

	const { isUserAllowedFor } = useAuth();
	const hasShareViewPermissions = isUserAllowedFor(
		PERMISSIONS.contact.shareView
	);

	// delete mutation
	const deleteMutation = useDeleteContact();

	const contactId = selectedRowData?.id ?? "";
	const companyData = selectedRowData?.company
		? {
				name: selectedRowData.company?.name,
				value: selectedRowData.company?.id,
			}
		: null;

	const handleCloseDialog = () => {
		setOpenImportContactsDialog(false);
	};

	const onImportClicked = () => {
		setOpenImportContactsDialog(true);
	};

	const openContactSummary = useCallback(
		(row) => {
			navigate(row.id.toString());
		},
		[navigate]
	);

	const handleTableRowAction = useCallback(
		(action, actionData) => {
			switch (action) {
				case tableActions.rowClickAction:
					openContactSummary(actionData);
					break;
				default:
					break;
			}
		},
		[openContactSummary]
	);

	const toggleEditFormDialog = () => {
		setIsEditFormDialogOpen((openEditFormDialog) => !openEditFormDialog);
	};

	const closeEmailComposer = () => {
		setIsEmailComposerOpened(false);
		setSelectedRowData(null);
	};

	const toggleAddActivityDialog = () => {
		setOpenAddActivityDialog(
			(openAddCompanyDialog) => !openAddCompanyDialog
		);
	};

	const onMailClicked = (row) => {
		setIsEmailComposerOpened(true);
		setSelectedRowData(row);
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const handleDeleteContact = () => {
		deleteMutation.mutate(contactId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.contactDeleted,
				});
				closeDeleteDialog();
			},
		});
	};

	const filteredContactMenuOptions = useMemo(() => {
		const onAddActivityClicked = (row) => {
			setSelectedRowData(row);
			toggleAddActivityDialog();
		};

		const onEditClicked = (row) => {
			setSelectedRowData(row);
			toggleEditFormDialog();
		};

		const onDeleteClicked = (row) => {
			openDeleteDialog();
			setSelectedRowData(row);
		};

		const contactMenuOptions = [
			{
				id: 1,
				name: "Edit",
				icon: EditIcon,
				action: onEditClicked,
				hasPermissionToAccess: isUserAllowedFor(
					PERMISSIONS.contact.edit
				),
			},
			{
				id: 2,
				name: "Email",
				icon: MailIcon,
				action: onMailClicked,
				hasPermissionToAccess: true,
			},
			{
				id: 3,
				name: "Call",
				icon: CallIcon,
				action: () => {},
				hasPermissionToAccess: true,
			},
			{
				id: 4,
				name: "Add Activity",
				icon: TaskIcon,
				action: onAddActivityClicked,
				hasPermissionToAccess: true,
			},
			{
				id: 5,
				name: "Delete",
				icon: DeleteIcon,
				action: onDeleteClicked,
				hasPermissionToAccess: isUserAllowedFor(
					PERMISSIONS.contact.delete
				),
			},
		];

		return contactMenuOptions.filter(
			(contactMenuOption) => contactMenuOption.hasPermissionToAccess
		);
	}, [isUserAllowedFor]);

	let moduleName = getModuleName(modules.CONTACT).toLowerCase();

	return (
		<React.Fragment>
			<DeleteDialog
				title={`Are you sure you want to delete the ${moduleName}?`}
				subtitle={`It will delete the ${moduleName} and the data associated with it. You can retrieve it from the Recycle Bin. It remains there for 90 days.`}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteContact}
				disableDeleteButton={deleteMutation.isLoading}
			></DeleteDialog>

			<ImportModuleDialog
				open={openImportContactsDialog}
				onClose={handleCloseDialog}
				moduleName={modules.CONTACT}
				disableBackdropClick={true}
			/>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditFormDialogOpen}
				onOpen={toggleEditFormDialog}
				onClose={toggleEditFormDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditContact
						contactId={contactId}
						onClose={toggleEditFormDialog}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddActivityDialog}
				onOpen={toggleAddActivityDialog}
				onClose={toggleAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={toggleAddActivityDialog}
						companyValue={companyData}
						contactIds={[contactId]}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{isEmailComposerOpened ? (
				<EmailComposerDrawer
					onClose={closeEmailComposer}
					composerValues={{ toAddress: [contactId] }}
					sourceName={modules.CONTACT}
					entityId={contactId}
				/>
			) : null}

			<FilterDataProvider>
				<FilterSaveViewProvider>
					<Table
						table={sourceType.CONTACT}
						moduleName={modules.CONTACT}
						menu={<ContactMenu onImportClicked={onImportClicked} />}
						selectedMenu={<SelectedMenu />}
						handleTableRowAction={handleTableRowAction}
						isActionsVisible={true}
						noResultsMessage={noResultsMessage}
						tableMenuOptions={filteredContactMenuOptions}
						hasShareViewPermissions={hasShareViewPermissions}
					/>
				</FilterSaveViewProvider>
			</FilterDataProvider>
		</React.Fragment>
	);
}

import React from "react";
import { Typography } from "@mui/material";
import { getFormattedDateMonthYearAndTime } from "../../../../utils/DateUtils";

export default function ActivityAssigned(props) {
	const { notificationData, notificationContentStyle } = props;

	if (!notificationData) {
		return null;
	}

	return (
		<React.Fragment>
			<Typography
				component="span"
				style={{ ...notificationContentStyle, opacity: "0.6" }}
			>
				{getFormattedDateMonthYearAndTime(
					notificationData?.data?.startTime
				)}{" "}
				-{" "}
				{getFormattedDateMonthYearAndTime(
					notificationData?.data?.endTime
				)}
			</Typography>
		</React.Fragment>
	);
}

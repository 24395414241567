import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";

export default function TextComponent(props) {
	const {
		style = {},
		field = {},
		error,
		placeholder = "-Enter-",
		updateFieldValue,
		value,
		clearError,
		...rest
	} = props;

	const [fieldValue, setFieldValue] = useState("");

	useEffect(() => {
		if (value) {
			setFieldValue(value?.value);
		} else {
			setFieldValue("");
		}
	}, [value]);

	const handleChange = (event) => {
		const { value } = event.target;

		clearError(field.fieldId);
		if (rest.type === "number") {
			if (/^[0-9]*$/.test(value)) {
				setFieldValue(value);
				updateFieldValue([{ value: value || "" }], field.fieldId);
			}
		} else {
			updateFieldValue([{ value: value || "" }], field.fieldId);
			setFieldValue(value);
		}
	};

	return (
		<React.Fragment>
			<TextField
				placeholder={placeholder}
				value={fieldValue}
				inputProps={{
					style: {
						textOverflow: "ellipsis",
						fontSize: "14px",
						fontWeight: 400,
					},
				}}
				sx={{
					backgroundColor: "#fff",
					borderRadius: 2,
					"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
						{
							"-webkit-appearance": "none",
						},
					...style,
				}}
				onChange={handleChange}
				error={error?.error}
				fullWidth
			/>
		</React.Fragment>
	);
}

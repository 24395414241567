import { useTheme } from "@emotion/react";
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	Typography,
	styled,
	tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { default as CautionIcon } from "../../../../../../assets/icons/caution";
import { MenuItemText, Tooltip } from "../../../../../../styles/twozo";
import {
	useStartCalendarSync,
	useCalendarSyncStatus,
	useStopCalendarSync,
} from "../../../../../../hooks/services/calendarSync";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../../utils/notification/notificationMessages";
import { getFormattedDateMonthYearAndTime } from "../../../../../../utils/DateUtils";
import { calendarSyncState } from "../../../../../../utils/calendarSyncUtils";

const syncSettingsTypes = {
	oneWay: "oneWay",
	twoWay: "twoWay",
};

const syncInfo = {
	linked: "linked",
	unLinked: "notLinked",
};

const styles = {
	heading: {
		fontSize: "15px",
		fontWeight: 600,
	},
	text: {
		fontSize: "15px",
	},
	contrastText: {
		fontSize: "14px",
		opacity: 0.6,
	},
	subText: {
		fontSize: "14px",
		fontWeight: 500,
	},
};

const StyledTooltip = styled(Tooltip)(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: "500px",
	},
}));

export default function CalendarSyncSettings(props) {
	const { calendarSyncSettingsData } = props;
	const theme = useTheme();
	const [syncState, setSyncState] = useState(
		calendarSyncSettingsData?.state || ""
	);

	const isSyncStateSyncing = syncState === calendarSyncState.SYNCING;
	const isSyncStateActive = syncState === calendarSyncState.ACTIVE;

	// query call
	const { data: calendarSyncStatus, status: calendarSyncStatusApiStatus } =
		useCalendarSyncStatus(calendarSyncSettingsData.id, isSyncStateSyncing);

	// mutation call
	const calendarSyncMutation = useStartCalendarSync();
	const stopSyncMutation = useStopCalendarSync();

	const [activityTypes, setActivityTypes] = useState([]);
	const [sourceLinkStatus, setSourceLinkStatus] = useState("");
	const [syncSettingsType, setSyncSettingsType] = useState("");
	const [selectedActivity, setSelectedActivity] = useState("");

	useEffect(() => {
		setSyncSettingsType(calendarSyncSettingsData.syncConfig.syncType.type);
		setActivityTypes(calendarSyncSettingsData.syncConfig.activityTypes);
		setSourceLinkStatus(
			calendarSyncSettingsData.syncConfig.sourceLinkStatus
		);
		setSelectedActivity(
			calendarSyncSettingsData.syncConfig.syncType.calendarEventId
		);
		setSyncState(calendarSyncSettingsData.state);
	}, [calendarSyncSettingsData]);

	useEffect(() => {
		// This useEffect handles showing a notification when the sync state is active.
		// It checks if the calendarSyncStatusApiStatus is "success" and then updates the sync state accordingly.
		// If the calendarSyncStatus is ACTIVE, a success notification is displayed to the user.

		if (calendarSyncStatusApiStatus === "success") {
			if (calendarSyncStatus && syncState !== calendarSyncState.ACTIVE) {
				setSyncState(calendarSyncStatus);
				if (calendarSyncStatus === calendarSyncState.ACTIVE) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.calendarSyncMessage,
					});
				}
			}
		}
	}, [calendarSyncStatus, calendarSyncStatusApiStatus, syncState]);

	const handleChangeSynSettingsType = (event) => {
		const { value } = event.target;
		setSyncSettingsType(value);
	};

	const handleChangeSourceLinkStatus = (event) => {
		const { value } = event.target;
		setSourceLinkStatus(value);
	};

	const handleChangeActivityType = (event, index) => {
		const { checked } = event.target;
		let updatedActivityTypes = activityTypes.map(
			(activity, activityIndex) => {
				if (index === activityIndex) {
					return { ...activity, isActive: checked };
				}

				return activity;
			}
		);
		setActivityTypes(updatedActivityTypes);
	};

	const handleSelectActivityType = (event) => {
		const { value } = event.target;
		setSelectedActivity(value);
	};

	const getCalendarSyncRequestData = () => {
		let requestData = { ...calendarSyncSettingsData };
		requestData.syncConfig.sourceLinkStatus = sourceLinkStatus;
		requestData.syncConfig.syncType.type = syncSettingsType;
		requestData.syncConfig.syncType.calendarEventId = selectedActivity;
		requestData.syncConfig.activityTypes = getFilteredActivityTypes();

		return requestData;
	};

	const getFilteredActivityTypes = () => {
		let filteredActivityTypes = activityTypes
			.filter((activity) => activity.isActive)
			.map((activity) => activity.id);
		return filteredActivityTypes;
	};

	const startSync = () => {
		let requestData = getCalendarSyncRequestData();
		calendarSyncMutation.mutate(requestData, {
			onSuccess: () => {
				setSyncState(calendarSyncState.SYNCING);
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const stopSync = () => {
		stopSyncMutation.mutate(calendarSyncSettingsData.id, {
			onSuccess: () => {
				// Notification throws only sync Active state
				if (isSyncStateActive) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.calendarDisconnectMessage,
					});
				}
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const getLastSyncTime = () => {
		if (!isSyncStateActive) {
			return;
		}

		if (calendarSyncSettingsData?.lastSyncTime) {
			return calendarSyncSettingsData.lastSyncTime;
		} else {
			return new Date();
		}
	};

	return (
		<React.Fragment>
			<Box
				style={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
					pointerEvents: isSyncStateSyncing ? "none" : "auto",
				}}
				m={1}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1.5}
						>
							<Typography style={styles.heading}>
								{calendarSyncSettingsData.emailAddress}
							</Typography>

							<Stack
								direction="row"
								alignItems="center"
								style={{
									backgroundColor:
										theme.palette.secondary.main,
									borderRadius: "6px",
								}}
								py={0.5}
								px={1}
								spacing={1}
							>
								{isSyncStateSyncing && (
									<CircularProgress
										size="16px"
										thickness={6}
									/>
								)}
								<Typography
									fontSize={14}
									fontWeight={500}
									color={theme.palette.primary.main}
								>
									{syncState}
								</Typography>
							</Stack>
						</Stack>

						{isSyncStateActive ? (
							<Box display="flex">
								<Button
									variant="contained"
									disableElevation
									color="error"
									disabled={stopSyncMutation.isLoading}
									onClick={stopSync}
								>
									Stop Sync
								</Button>
							</Box>
						) : null}
					</Stack>

					{getLastSyncTime() && (
						<Typography style={styles.contrastText}>
							Last sync{" "}
							{getFormattedDateMonthYearAndTime(
								getLastSyncTime()
							)}
						</Typography>
					)}
				</Box>

				{!isSyncStateActive && <Divider />}

				<Box
					px={2}
					pt={1}
					style={{
						opacity: isSyncStateSyncing && 0.6,
					}}
				>
					<Typography style={styles.heading}>
						Sync Settings
					</Typography>

					<FormControl sx={{ mt: 1, pb: 2 }}>
						<RadioGroup
							value={syncSettingsType}
							onChange={handleChangeSynSettingsType}
						>
							<FormControlLabel
								value={syncSettingsTypes.oneWay}
								control={<Radio />}
								label={
									<Stack pb={1}>
										<Typography style={styles.text}>
											One-Way
										</Typography>

										<Typography style={styles.contrastText}>
											Twozo activities sync to calendar.
											Only edits to Twozo activities will
											sync back to Twozo
										</Typography>
									</Stack>
								}
							/>

							<FormControlLabel
								value={syncSettingsTypes.twoWay}
								control={<Radio />}
								label={
									<Stack>
										<Typography style={styles.text}>
											Two-Way
										</Typography>

										<Typography style={styles.contrastText}>
											Twozo activities sync to calendar.
											Calendar events sync to Twozo as
											activities, accessible to other
											users in your company
										</Typography>
									</Stack>
								}
							/>
						</RadioGroup>
					</FormControl>

					{syncSettingsType === syncSettingsTypes.twoWay && (
						<Stack pt={1} spacing={0.5} pb={2}>
							<Typography style={styles.subText} pl={1}>
								Save calendar event to Twozo as
							</Typography>

							<Select
								sx={{ width: "528px" }}
								value={selectedActivity}
								onChange={handleSelectActivityType}
							>
								{activityTypes.map((activity, index) => (
									<MenuItem key={index} value={activity.id}>
										<MenuItemText>
											{activity.name}
										</MenuItemText>
									</MenuItem>
								))}
							</Select>
						</Stack>
					)}

					<Divider />

					<Box py={2}>
						<Typography style={styles.heading}>
							Sync these Twozo activities to Calendar
						</Typography>

						<Stack pt={1} spacing={1}>
							{activityTypes.map((activity, index) => (
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
									key={activity.id}
								>
									<Checkbox
										checked={activity.isActive}
										onChange={(event) =>
											handleChangeActivityType(
												event,
												index
											)
										}
									/>

									<Typography sx={styles.text}>
										{activity.name}
									</Typography>
								</Stack>
							))}
						</Stack>
					</Box>

					<Divider />

					<Typography style={styles.heading} pt={2}>
						Sync info about linked contacts and deal to calendar
						event
					</Typography>

					<FormControl sx={{ pb: 1 }}>
						<RadioGroup
							value={sourceLinkStatus}
							onChange={handleChangeSourceLinkStatus}
						>
							<FormControlLabel
								value={syncInfo.linked}
								control={<Radio />}
								label={
									<Stack
										direction="row"
										spacing={1}
										alignItems="center"
									>
										<Typography style={styles.text}>
											Don’t include contact and deal info
										</Typography>

										<Box
											style={{
												backgroundColor:
													theme.palette.secondary
														.main,
												borderRadius: "6px",
											}}
											py={0.5}
											px={1}
										>
											<Typography
												style={styles.subText}
												color={
													theme.palette.primary.main
												}
											>
												Recommended
											</Typography>
										</Box>
									</Stack>
								}
							/>

							<FormControlLabel
								value={syncInfo.unLinked}
								control={<Radio />}
								label={
									<Stack direction="row" spacing={1}>
										<Typography style={styles.text}>
											Include contact and deal info in the
											description
										</Typography>

										{sourceLinkStatus !==
											syncInfo.linked && (
											<StyledTooltip
												PopperProps={{
													maxWidth: "500px",
												}}
												title={
													<Typography fontSize={14}>
														The included information
														will be visible to all
														guests of the calendar
														event
													</Typography>
												}
												placement="right"
											>
												<Box display="flex">
													{CautionIcon(20, 20)}
												</Box>
											</StyledTooltip>
										)}
									</Stack>
								}
							/>
						</RadioGroup>
					</FormControl>

					<>
						<Divider />

						{!isSyncStateActive ? (
							<Stack direction="row" py={3} spacing={2}>
								<Button
									variant="contained"
									disableElevation
									onClick={startSync}
									disabled={calendarSyncMutation.isLoading}
								>
									Start Syncing
								</Button>

								<Button
									variant="contained"
									disableElevation
									color="secondary"
									onClick={stopSync}
								>
									Cancel
								</Button>
							</Stack>
						) : (
							<Box py={3}>
								<Button
									variant="contained"
									color="secondary"
									disableElevation
									onClick={startSync}
									disabled={calendarSyncMutation.isLoading}
								>
									Sync
								</Button>
							</Box>
						)}
					</>
				</Box>
			</Box>
		</React.Fragment>
	);
}

import React, { useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useDuration } from "../../../../../../../hooks/services/analytics";
import Field from "../../Field";

const FieldName = ({ children }) => (
	<Typography
		fontSize="13px"
		fontWeight={500}
		color="rgba(0,0,0,0.6)"
		px={2}
		pt={1}
	>
		{children}
	</Typography>
);

export default function Duration(props) {
	const { control, setValue } = props;
	const [measureId, durationFieldId] = useWatch({
		control,
		name: [
			"viewProperties.metrics.0.measureId",
			"viewProperties.duration.fieldId",
		],
	});
	const { data: durationData } = useDuration(measureId, {
		enabled: !!measureId,
	});

	const [durationOptions, timeUnitsOptions] = useMemo(() => {
		if (durationData) {
			const durationOptions =
				durationData.fields?.map((option) => ({
					id: option.fieldId,
					label: option.fieldName,
				})) || [];

			const timeUnitsOptions =
				durationData.timeUnits?.map((option) => ({
					id: option.timeUnitId,
					label: option.timeUnitName,
				})) || [];

			return [durationOptions, timeUnitsOptions];
		}
		return [[], []];
	}, [durationData]);

	useEffect(() => {
		if (durationFieldId) {
			const durationOption = durationOptions.find(
				(option) => option.id === durationFieldId
			);
			setValue("viewProperties.duration.label", durationOption?.label);
		} else {
			setValue("viewProperties.duration.label", "");
		}
	}, [durationFieldId, durationOptions, setValue]);

	return (
		<React.Fragment>
			<Box
				style={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
				}}
			>
				<FieldName>Field</FieldName>

				<Field
					fieldType="DROPDOWN"
					name="viewProperties.duration.fieldId"
					control={control}
					options={durationOptions}
					disabled={!measureId}
					variant="standard"
					sx={{
						"&:before": {
							borderBottom: !durationFieldId
								? "none"
								: "1px solid rgba(0, 0, 0, 0.1)",
						},
						"&:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):hover::before":
							{
								borderColor: "rgba(0, 0, 0, 0.4)",
								borderBottomWidth: "1px",
								borderRadius: "8px",
							},
						":after": { borderBottomWidth: "1px" },
						"& .MuiSelect-select": {
							paddingLeft: 2,
						},
						"&.Mui-disabled": {
							"&:before": {
								borderBottom: "none",
							},
						},
						height: "32px",
						width: "100%",
					}}
					rules={{
						required: {
							value: true,
							message: "Duration is required",
						},
					}}
				/>

				{durationFieldId && (
					<Box>
						<FieldName>Time Period</FieldName>

						<Field
							fieldType="DROPDOWN"
							name="viewProperties.duration.timeUnitId"
							control={control}
							options={timeUnitsOptions}
							variant="standard"
							sx={{
								"&:before": {
									borderBottom: "none",
								},
								"&:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):hover::before":
									{
										borderColor: "rgba(0, 0, 0, 0.4)",
										borderBottomWidth: "1px",
										borderRadius: "8px",
									},
								":after": { borderBottomWidth: "1px" },
								"& .MuiSelect-select": {
									paddingLeft: 2,
								},
								height: "32px",
								width: "100%",
							}}
							rules={{
								required: {
									value: true,
									message: "Unit is required",
								},
							}}
						/>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}

import {
	Box,
	Button,
	Divider,
	List,
	ListItem,
	ListItemButton,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import MenuSearchBar from "../../../../Elements/MenuSearchBar";
import { getDropdownDataByName } from "../../../../../utils/dropdownData";
import moment from "moment-timezone";
import StaticDatePicker from "../../../../Elements/StaticDatePicker";
import SingleTimePicker from "../../../../Elements/SingleTimePicker";
import { MenuItemText } from "../../../../../styles/twozo";

const ScheduledMailUI = (props) => {
	const { onScheduledMail, disableScheduledButton } = props;
	const theme = useTheme();

	const [searchedTimeZoneValue, setSearchedTimeZoneValue] = useState("");
	const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState("");
	const [scheduledDate, setScheduledDate] = useState(new Date());
	const [scheduledTime, setScheduledTime] = useState(new Date());

	const timeZoneList = getDropdownDataByName("TIME_ZONE_LIST");

	const getInitialTimeZoneId = () => {
		let initialTimeZone = timeZoneList.find(
			(timeZone) =>
				timeZone.zoneId ===
				Intl.DateTimeFormat().resolvedOptions().timeZone
		);
		return initialTimeZone?.zoneId;
	};

	const [selectedTimeZoneId, setSelectedTimeZoneId] = useState(
		getInitialTimeZoneId()
	);

	const handleSearchTimeZoneValue = (event) => {
		const { value } = event.target;
		setSearchedTimeZoneValue(value);
	};

	const handleChangeTimeZone = (event) => {
		event.stopPropagation();
		const { value } = event.target;
		setSelectedTimeZoneId(value);
	};

	const getNextMonday = () => {
		const date = new Date();
		const nextMonday = new Date(
			date.setDate(date.getDate() + ((8 - date.getDay()) % 7 || 7))
		);
		return nextMonday;
	};

	const getNextDay = () => {
		const today = new Date();
		const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
		return tomorrow;
	};

	const handleNextMonday = (suggestionDateIndex) => {
		const newDate = new Date(getNextMonday());
		newDate.setHours(9, 0);
		setSelectedSuggestionIndex(suggestionDateIndex);
		setScheduledDate(newDate);
		setScheduledTime(newDate);
	};

	const handleNextDayMorning = (suggestionDateIndex) => {
		const newDate = new Date(getNextDay());
		newDate.setHours(9, 0);
		setSelectedSuggestionIndex(suggestionDateIndex);
		setScheduledDate(newDate);
		setScheduledTime(newDate);
	};

	const handleNextDayAfternoon = (suggestionDateIndex) => {
		const newDate = new Date(getNextDay());
		newDate.setHours(14, 0);
		setSelectedSuggestionIndex(suggestionDateIndex);
		setScheduledDate(newDate);
		setScheduledTime(newDate);
	};

	const onDateInputChange = (value) => {
		setScheduledDate(value.valueOf());
		setSelectedSuggestionIndex("");
	};

	const onTimeInputChange = (totalMinutesFromStartOfDay) => {
		var hours = Math.floor(totalMinutesFromStartOfDay / 60);
		var minutes = totalMinutesFromStartOfDay % 60;
		const newDate = new Date(scheduledDate);

		newDate.setHours(hours, minutes);
		setScheduledTime(newDate);
		setSelectedSuggestionIndex("");
	};

	const suggestionsForScheduledMail = [
		{
			name: "Next day Morning",
			key: "next day morning",
			action: handleNextDayMorning,
		},
		{
			name: "Next day Afternoon",
			path: "next day afternoon",
			action: handleNextDayAfternoon,
		},
		{
			name: "Monday Morning",
			path: "monday morning",
			action: handleNextMonday,
		},
	];

	const handleSchedule = () => {
		const newDate = new Date(scheduledDate);

		let hours = scheduledTime.getHours();
		let minutes = scheduledTime.getMinutes();
		newDate.setHours(hours, minutes);
		let scheduledDateAndTime = moment
			.tz(newDate, selectedTimeZoneId)
			.valueOf();
		onScheduledMail(scheduledDateAndTime);
	};

	const getFormattedTimeValue = (timeValue) => {
		let totalMinutesFromStartOfDay =
			timeValue.getHours() * 60 + timeValue.getMinutes();
		return totalMinutesFromStartOfDay;
	};

	const getFilteredTimeZoneList = () => {
		if (searchedTimeZoneValue.trim()) {
			return timeZoneList?.filter((timeZone) =>
				timeZone.name
					.toLowerCase()
					.includes(searchedTimeZoneValue.toLowerCase())
			);
		}
		return timeZoneList;
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Box p={1}>
				<Select
					sx={{ width: "100%" }}
					value={selectedTimeZoneId || ""}
					onChange={handleChangeTimeZone}
					onOpen={() => setSearchedTimeZoneValue("")}
					renderValue={(selectedTimeZone) => {
						let selectedOption = timeZoneList.find(
							(timeZone) => timeZone.zoneId === selectedTimeZone
						);
						return selectedOption?.name;
					}}
					MenuProps={{
						autoFocus: false,
						PaperProps: {
							style: {
								maxHeight: 250,
							},
						},
					}}
				>
					<Box
						p={0.5}
						marginTop="-8px"
						sx={{
							position: "sticky",
							top: 0,
							zIndex: 1,
							backgroundColor: "#fff",
						}}
					>
						<MenuSearchBar
							value={searchedTimeZoneValue}
							onChange={handleSearchTimeZoneValue}
						/>
					</Box>

					{getFilteredTimeZoneList()?.length > 0
						? getFilteredTimeZoneList().map((timeZone, index) => (
								<MenuItem
									key={index}
									value={timeZone.zoneId || ""}
									style={{ height: "40px" }}
								>
									<MenuItemText pl={2}>
										{timeZone.name}
									</MenuItemText>
								</MenuItem>
							))
						: renderEmptyView()}
				</Select>
			</Box>

			<Divider />

			<Stack direction="row" justifyContent="space-between">
				<Box width="40%">
					<List>
						{suggestionsForScheduledMail.map(
							(suggestion, index) => (
								<ListItem key={index} disablePadding>
									<ListItemButton
										style={{
											height: "40px",
											backgroundColor:
												selectedSuggestionIndex ===
												index
													? "#E7F7F0"
													: "transparent",
										}}
										onClick={() => suggestion.action(index)}
									>
										<Typography fontSize={13}>
											{suggestion.name}
										</Typography>
									</ListItemButton>
								</ListItem>
							)
						)}
					</List>
				</Box>

				<Divider orientation="vertical" flexItem />

				<Stack width="60%">
					<StaticDatePicker
						onChange={onDateInputChange}
						value={scheduledDate}
						disablePast
					/>

					<Divider flexItem style={{ margin: "0px 10px" }} />

					<Box style={{ padding: "16px 16px 0px 16px" }}>
						<SingleTimePicker
							value={getFormattedTimeValue(scheduledTime)}
							onChange={onTimeInputChange}
							style={{
								border: "none",
								padding: "0px",
								backgroundColor: theme.palette.secondary.main,
								backfaceVisibility: "hidden",
								width: "fit-content",
								minHeight: "35px",
								margin: "0px",
							}}
							hiddenTimeIcon={true}
							toggleButtonStyle={{ marginLeft: "0px" }}
						/>
					</Box>

					<Stack direction="row" justifyContent="end" pr={2} pb={2}>
						<Button
							variant="contained"
							disableElevation
							onClick={handleSchedule}
							disabled={disableScheduledButton}
						>
							Schedule
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</React.Fragment>
	);
};

export default ScheduledMailUI;

import { Box, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import ActivityType from "../Type";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import {
	useActivityTypeList,
	useUpdateOrderActivityTypeList,
} from "../../../../../hooks/services/activityType";
import { useAuth } from "../../../../../hooks/auth";
import { PERMISSIONS } from "../../../../../utils/Auth";
import DragDropContextWithNonce from "../../../../Elements/DragDropContextWithNonce";

export default function ActivityTypeList(props) {
	const { isActive, handleOpenEditActivityTypeDialog } = props;

	const [activityTypes, setActivityTypes] = useState([]);

	const { isUserAllowedFor } = useAuth();

	const isDragActionDisabled = !isUserAllowedFor(
		PERMISSIONS.activityType.edit
	);

	const {
		data: activityTypeListData,
		isLoading: isActivityTypeListDataLoading,
		status: activityTypeListStatus,
	} = useActivityTypeList(isActive);

	useEffect(() => {
		if (activityTypeListStatus === "success") {
			if (activityTypeListData) {
				setActivityTypes(activityTypeListData);
			}
		}
	}, [activityTypeListData, activityTypeListStatus]);

	const handleUpdateActvityTypeList = (updatedActivityTypeList) => {
		setActivityTypes(updatedActivityTypeList);
	};

	const updateActivityTypeOrderMutation = useUpdateOrderActivityTypeList();

	const getBulkUpdateRequest = (updatedData) => {
		let requestData = [];

		for (let index = 0; index < updatedData.length; index++) {
			requestData.push(updatedData[index].id);
		}

		return requestData;
	};

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const updatedData = reorder(
			activityTypes,
			result.source.index,
			result.destination.index
		);
		handleUpdateActvityTypeList(updatedData);

		const updateOrderRequestData = getBulkUpdateRequest(updatedData);
		updateActivityTypeOrderMutation.mutate(updateOrderRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.activityTypeOrderUpdated,
				});
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	return (
		<React.Fragment>
			{isActivityTypeListDataLoading ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="60vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<Box pt={2}>
					<DragDropContextWithNonce onDragEnd={onDragEnd}>
						<Droppable droppableId="activity_type_list">
							{(provided) => (
								<div
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									<Box>
										{Array.isArray(activityTypes) &&
											activityTypes.map(
												(activityType, index) => (
													<Draggable
														draggableId={activityType.id.toString()}
														index={index}
														key={activityType.id}
														isDragDisabled={
															isDragActionDisabled
														}
													>
														{(provided) => (
															<Box
																ref={
																	provided.innerRef
																}
																{...provided.draggableProps}
																pb={1}
															>
																<ActivityType
																	dragHandleProps={
																		provided.dragHandleProps
																	}
																	activityType={
																		activityType
																	}
																	key={
																		activityType.id
																	}
																	isActive={
																		isActive
																	}
																	handleOpenEditActivityTypeDialog={() => {
																		handleOpenEditActivityTypeDialog(
																			activityType
																		);
																	}}
																/>
															</Box>
														)}
													</Draggable>
												)
											)}
									</Box>
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContextWithNonce>
				</Box>
			)}
		</React.Fragment>
	);
}

const workflowTriggerEventList = [
	{
		name: "Created",
		value: 1,
	},
	{
		name: "Updated",
		value: 2,
	},
	{
		name: "Deleted",
		value: 3,
	},
];

export default workflowTriggerEventList;

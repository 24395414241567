import React, { useState } from "react";
import {
	SummaryFieldName,
	Tooltip,
	twozoStyles,
} from "../../../../../../styles/twozo";
import { useTheme } from "@emotion/react";
import { Box, Stack, Typography } from "@mui/material";
import DropDownIcon from "../../../../../../assets/icons/dropDown";
import { useSummaryContext } from "../../../../../Summary/SummaryContext";
import DropdownFieldEditForm from "../../../../FieldEditFormComponents/DropdownFieldEditForm";
import FieldEdit from "../../../../FieldEdit";

const fieldEditStyles = {
	fieldEditContainer: {
		width: "371px",
	},
	fieldEditForm: {
		width: "300px",
	},
};

export default function DropdownComponent(props) {
	const { summaryField } = props;

	const theme = useTheme();
	const classes = twozoStyles();

	// summary context:-
	const { updateSummaryField, entityId } = useSummaryContext();

	const [isDropdownHovered, setIsDropdownHovered] = useState(false);
	const [openToolTip, setOpenToolTip] = useState(false);

	const handleOpenTooltip = (event) => {
		setOpenToolTip(event.target.scrollWidth > event.target.clientWidth);
	};

	const getDropdownRequestData = (dropdownFieldValue) => {
		let dropdownValue = dropdownFieldValue[summaryField?.fieldId];
		return {
			[summaryField?.fieldId]: dropdownValue ? dropdownValue : null,
			id: entityId,
		};
	};

	const hasValueOrIsChanged = (dropdownFieldValue) => {
		return (
			(summaryField?.value &&
				summaryField?.value?.id !==
					dropdownFieldValue[summaryField?.fieldId]) ||
			(!summaryField?.value && dropdownFieldValue[summaryField?.fieldId])
		);
	};

	const handleSave = (dropdownFieldValue, onClose) => {
		if (hasValueOrIsChanged(dropdownFieldValue)) {
			let requestData = getDropdownRequestData(dropdownFieldValue);
			updateSummaryField(requestData, onClose);
		} else {
			onClose();
		}
	};

	const getDropdownFieldLabel = (onFieldClicked) => {
		return (
			<Box onClick={onFieldClicked}>
				{summaryField?.value ? (
					<Tooltip
						title={summaryField.value?.name}
						open={openToolTip}
						placement="top"
					>
						<SummaryFieldName
							color={theme.palette.primary.ma}
							className={classes.summaryData}
							onMouseOver={handleOpenTooltip}
							onMouseLeave={() => setOpenToolTip(false)}
						>
							{summaryField.value?.name}
						</SummaryFieldName>
					</Tooltip>
				) : summaryField.config.readOnly ? (
					<SummaryFieldName style={{ opacity: 0.6 }}>
						-
					</SummaryFieldName>
				) : (
					<Stack
						onMouseOver={() => setIsDropdownHovered(true)}
						onMouseLeave={() => setIsDropdownHovered(false)}
						padding={0}
						sx={{
							borderBottom: isDropdownHovered
								? "1px solid rgba(0, 0, 0, 0.2)"
								: "none",
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							spacing={0.5}
							width="150px"
						>
							<Typography
								fontSize="15px"
								fontWeight={500}
								style={{
									color: theme.palette.secondary.contrastText,
									opacity: "0.4",
								}}
							>
								Click to Add
							</Typography>

							{isDropdownHovered ? (
								<Box display="flex">
									{DropDownIcon(20, 20, "rgba(0, 0, 0, 0.2)")}
								</Box>
							) : null}
						</Stack>
					</Stack>
				)}
			</Box>
		);
	};

	return (
		<React.Fragment>
			<FieldEdit
				fieldLabel={getDropdownFieldLabel}
				field={summaryField}
				styles={fieldEditStyles}
			>
				<DropdownFieldEditForm
					onSave={handleSave}
					value={summaryField?.value}
					field={summaryField}
				/>
			</FieldEdit>
		</React.Fragment>
	);
}

import React, { useCallback } from "react";
import dayjs from "dayjs";
import DateComponent from "../DateComponent";
import moment from "moment-timezone";

export default function ActivityStartEndComponent(props) {
	const {
		field = {},
		updateFieldValue,
		error,
		style = {},
		value,
		clearError,
		formValues,
	} = props;

	const currentDate = dayjs().startOf("day").valueOf();

	const manuallySetEndTime = useCallback(
		(from, to, preFromDate) => {
			const startDate = new Date(from);
			const endDate = new Date(to);
			const preStartDate = new Date(preFromDate);

			// Calculate the difference in days only
			const dateDiff = moment(startDate)
				.startOf("day")
				.diff(moment(preStartDate).startOf("day"), "days");

			const adjustedEndDate = new Date(endDate);
			adjustedEndDate.setDate(endDate.getDate() + dateDiff);

			// Update the field value based on date comparison
			if (startDate > endDate) {
				updateFieldValue(
					[{ value: adjustedEndDate, isEndTimeChanged: true }],
					field?.config?.dependsOn,
					true
				);
			} else {
				const nextDate = new Date(endDate);
				nextDate.setDate(nextDate.getDate() + dateDiff);

				updateFieldValue(
					[{ value: nextDate, isEndTimeChanged: true }],
					field?.config?.dependsOn,
					true
				);
			}
		},
		[field?.config?.dependsOn, updateFieldValue]
	);

	const handleChangeDateTimeRangeValue = useCallback(
		(newDateRangeValue) => {
			const newStartDateValue = moment(
				newDateRangeValue?.[0]?.value ?? currentDate
			)
				.startOf("day")
				.valueOf();

			updateFieldValue([{ value: newStartDateValue }], field.fieldId);

			const dependentFieldValue =
				formValues?.[field?.config?.dependsOn]?.value;
			const isEndTimeChanged =
				formValues?.[field?.config?.dependsOn]?.isEndTimeChanged;

			if (isEndTimeChanged) {
				manuallySetEndTime(
					newStartDateValue,
					dependentFieldValue,
					moment(formValues?.[field.fieldId]?.value ?? currentDate)
						.startOf("day")
						.valueOf()
				);
			} else {
				updateFieldValue(
					[{ value: newStartDateValue, isEndTimeChanged: true }],
					field?.config?.dependsOn,
					true
				);
			}
		},
		[
			field?.config?.dependsOn,
			field.fieldId,
			formValues,
			manuallySetEndTime,
			updateFieldValue,
			currentDate,
		]
	);

	return (
		<React.Fragment>
			<DateComponent
				field={field}
				error={error}
				style={style}
				clearError={clearError}
				value={value ?? currentDate}
				updateFieldValue={handleChangeDateTimeRangeValue}
			/>
		</React.Fragment>
	);
}

import { FormHelperText, Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import SingleDatePicker from "../../../../../../../Elements/SingleDatePicker";
import dayjs from "dayjs";

const styles = {
	text: {
		fontSize: "13px",
		fontWeight: 500,
		opacity: 0.6,
	},
};

const textFieldProps = {
	sx: {
		width: "100%",
		border: "none",
		padding: 0,
		"& .MuiInputBase-root": {
			padding: 0,
			minHeight: 0,
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none !important",
			padding: "0 !important",
		},
	},
	inputProps: {
		style: { textAlign: "left", fontSize: "14px" },
	},
};

const slotProps = { inputAdornment: { position: "end" } };
const popperProps = { placement: "bottom-end" };

export default function CustomFieldComponent(params) {
	const { label, control, rules, name, fromValue } = params;

	const handleDateChange = (event, onChange) => {
		let selectedDate = event.valueOf() ? event.valueOf() : "";
		let modifiedEvent = {
			...event,
			target: {
				...event.target,
				value: selectedDate,
			},
		};

		onChange(modifiedEvent);
	};

	const getParsedValue = (value) => {
		return value ? dayjs(value) : null;
	};

	const handleDisableToField = (date) => {
		if (fromValue) {
			return date < getParsedValue(fromValue);
		}
	};

	const getErrorMessage = (data) => {
		const keys = name.split(".");
		const message = keys.reduce((acc, key) => acc?.[key], data)?.message;
		return message ? message : false;
	};

	return (
		<>
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({
					field: { value, onChange, ...fieldState },
					formState: { errors },
				}) => (
					<>
						<Stack spacing={0.5} padding="12px 16px">
							<Typography style={styles.text}>{label}</Typography>

							<SingleDatePicker
								{...fieldState}
								placeholder="dd mmm yyyy"
								value={getParsedValue(value)}
								onChange={(event) =>
									handleDateChange(event, onChange)
								}
								error={!!getErrorMessage(errors)}
								popperProps={popperProps}
								slotProps={slotProps}
								textFieldProps={textFieldProps}
								shouldDisableDate={handleDisableToField}
								shouldDisableMonth={handleDisableToField}
								shouldDisableYear={handleDisableToField}
							/>
						</Stack>

						{!!getErrorMessage(errors) && (
							<FormHelperText
								style={{ paddingLeft: "16px" }}
								error
							>
								{getErrorMessage(errors)}
							</FormHelperText>
						)}
					</>
				)}
			/>
		</>
	);
}

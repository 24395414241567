import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import Field from "../../Field";
import { useWatch } from "react-hook-form";
import { useGetXAxisMetrics } from "../../../../../../../hooks/services/analytics";
import { useDashboardBuilderContext } from "../../../Context";

const hideBorderSx = {
	".MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"& .MuiSelect-select": {
		paddingLeft: 2,
	},
	height: "32px",
	width: "100%",
};

const FieldName = ({ children }) => (
	<Typography
		fontSize="14px"
		fontWeight={500}
		color="rgba(0,0,0,0.6)"
		px={2}
		pt={1}
	>
		{children}
	</Typography>
);

export default function ViewByXAxis(props) {
	const { control, setValue, unregister } = props;
	const { selectedChartIndex } = useDashboardBuilderContext();
	const [hasBucket, setHasBucket] = useState(false);
	const [selectedMetricData, setSelectedMetricData] = useState(null);
	const [moduleId, viewByFieldId] = useWatch({
		control,
		name: [
			"viewProperties.metrics.0.moduleId",
			"viewProperties.viewBy.fieldId",
		],
	});
	const { data: metricsData, isLoading: isMetricsDataLoading } =
		useGetXAxisMetrics([moduleId], {
			enabled: !!moduleId,
			select: (data) =>
				data.fields.map(({ fieldName, fieldId, ...others }) => ({
					id: fieldId,
					label: fieldName,
					...others,
				})),
		});

	useEffect(() => {
		if (viewByFieldId && !isMetricsDataLoading) {
			const selectedMetricData = metricsData.find(
				(metric) => metric.id === viewByFieldId
			);
			setValue("viewProperties.viewBy.label", selectedMetricData?.label);
			setSelectedMetricData(selectedMetricData);
			if (
				!selectedMetricData?.bucket ||
				!selectedMetricData.bucket.isFromAndToAllowed
			) {
				setHasBucket(false);
				unregister("viewProperties.viewBy.bucket.unitId");
				unregister("viewProperties.viewBy.bucket.from");
				unregister("viewProperties.viewBy.bucket.to");
			} else {
				setHasBucket(true);
			}
		} else {
			setHasBucket(false);
			setSelectedMetricData(null);
			setValue("viewProperties.viewBy.label", "");
			unregister("viewProperties.viewBy.bucket.unitId");
			unregister("viewProperties.viewBy.bucket.from");
			unregister("viewProperties.viewBy.bucket.to");
		}
	}, [
		viewByFieldId,
		metricsData,
		unregister,
		setValue,
		isMetricsDataLoading,
	]);

	return (
		<React.Fragment>
			<Box
				style={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
				}}
			>
				<Field
					name="viewProperties.viewBy.fieldId"
					control={control}
					fieldType="DROPDOWN"
					options={metricsData || []}
					disabled={!moduleId}
					variant="standard"
					sx={{
						"&:before": {
							borderBottom: !hasBucket
								? "none"
								: "1px solid rgba(0, 0, 0, 0.1)",
						},
						"&:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):hover::before":
							{
								borderColor: "rgba(0, 0, 0, 0.4)",
								borderBottomWidth: "1px",
								borderRadius: "8px",
							},
						":after": { borderBottomWidth: "1px" },
						"& .MuiSelect-select": {
							paddingLeft: 2,
						},
						"&.Mui-disabled": {
							"&:before": {
								borderBottom: "none",
							},
						},
						width: "100%",
					}}
					rules={{
						required: {
							value: true,
							message: "Field is required",
						},
					}}
				/>

				{hasBucket && selectedMetricData?.bucket?.units && (
					<Box>
						<FieldName>Unit</FieldName>

						<Field
							fieldKey={`chart-${selectedChartIndex}-unit-select`}
							name="viewProperties.viewBy.bucket.unitId"
							control={control}
							fieldType="DROPDOWN"
							sx={hideBorderSx}
							options={
								selectedMetricData.bucket.units.map((unit) => ({
									id: unit.unitId,
									label: unit.unitName,
								})) || []
							}
							rules={{
								required: {
									value: true,
									message: "Unit is required",
								},
							}}
						/>

						<Divider />

						<FieldName>Metric name</FieldName>

						<Field
							name="viewProperties.viewBy.bucket.from"
							control={control}
							fieldType="SINGLE_STRING"
							type="number"
							placeholder="1,234,567"
							sx={{
								"& .MuiOutlinedInput-root": hideBorderSx,
							}}
							rules={{
								required: {
									value: true,
									message: "From is required",
								},
							}}
						/>

						<Divider />

						<FieldName>Metric name</FieldName>

						<Field
							name="viewProperties.viewBy.bucket.to"
							control={control}
							fieldType="SINGLE_STRING"
							type="number"
							placeholder="1,234,567"
							sx={{
								"& .MuiOutlinedInput-root": hideBorderSx,
							}}
							rules={{
								required: {
									value: true,
									message: "To is required",
								},
							}}
						/>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}

import React, { useEffect, useState } from "react";
import { alpha, Box, Button, useTheme } from "@mui/material";
import { Tooltip } from "../../../../../../styles/twozo";
import { useFilterSaveViewContext } from "../../Context/FilterSaveViewContext";
import { removeFieldsWithEmptyValues } from "../../../../../../utils/common";
import {
	filterConnectorsList,
	filterFieldNames,
} from "../../../../../../utils/FilterUtills";
import { useFilterDataContext } from "../../Context/FilterDataContext";

export default function FooterButtons() {
	const theme = useTheme();
	const {
		filterState,
		removeAppliedFilter,
		filterListByCondition,
		updateFilterCount,
		updateFilterUICriteriaList,
	} = useFilterDataContext();

	const { hideSaveViewAsButton, showSaveViewAsButton } =
		useFilterSaveViewContext();

	//To identify if the filter is fulfilled.
	const [isValidFilter, setIsValidFilter] = useState(false);

	const isCriteriaValid = (criteria) => {
		return (
			(criteria.value.value === "" &&
				criteria.valueEntityId.value === "" &&
				(criteria.comparatorId.value.name === "is empty" ||
					criteria.comparatorId.value.name === "is not empty")) ||
			criteria.value.value !== "" ||
			criteria.valueEntityId.value !== ""
		);
	};

	useEffect(() => {
		const hasValidFilterCondition =
			filterState.filterUICriteriaList.some(isCriteriaValid);
		setIsValidFilter(hasValidFilterCondition);
	}, [filterState.filterUICriteriaList]);

	//Clear All filter condition
	const clearFilterCondition = () => {
		hideSaveViewAsButton();
		removeAppliedFilter();
	};

	//Apply Filter Condition
	const getApplyFilterRequestData = () => {
		let requestData = {};
		requestData.criteriaList = [];

		//Grouped and ordered filter conditions based on connector type (AND first, then OR)
		const andFilterConditions = filterState.filterUICriteriaList?.filter(
			(item) => item.connectorId.value.name === filterConnectorsList.AND
		);
		const orFilterConditions = filterState.filterUICriteriaList?.filter(
			(item) => item.connectorId.value.name === filterConnectorsList.OR
		);

		const filterConditions = [
			...andFilterConditions,
			...orFilterConditions,
		];

		for (let index = 0; index < filterConditions.length; index++) {
			const requestCriteriaListData = { field: {} };
			const inputValue = {};

			for (const filterDataElementKey in filterConditions[index]) {
				if (
					Object.hasOwnProperty.call(
						filterConditions[index],
						filterDataElementKey
					)
				) {
					const formElement =
						filterConditions[index][filterDataElementKey];
					const fieldName = formElement.fieldName;

					if (
						filterDataElementKey ===
						filterFieldNames.multiSelectValues
					) {
						const values =
							formElement?.length > 0
								? formElement.map((option) => {
										const valueObject = {
											valueEntityId: option.value,
											value: option.name,
										};

										if (option.id) {
											valueObject.id = option.id;
										}

										return valueObject;
									})
								: "";
						requestCriteriaListData.values = values;
					}

					if (fieldName === filterFieldNames.valueEntityId) {
						inputValue[fieldName] = formElement.value;
					}

					if (fieldName === filterFieldNames.value) {
						inputValue[fieldName] = formElement.value;
						inputValue.id =
							formElement.id && formElement.value
								? formElement.id
								: "";
					}

					if (
						(fieldName === filterFieldNames.fieldId &&
							formElement.value.id !== "") ||
						fieldName === filterFieldNames.optionId
					) {
						requestCriteriaListData.field[fieldName] =
							formElement.value.id;
					}

					if (
						fieldName === filterFieldNames.additionalOption &&
						formElement.value.name !== ""
					) {
						requestCriteriaListData.field[fieldName] =
							formElement.value;
					}

					if (fieldName === filterFieldNames.sourceId) {
						requestCriteriaListData[fieldName] =
							formElement.value.id;
						requestCriteriaListData.id = formElement.id
							? formElement.id
							: "";
					}

					if (
						fieldName === filterFieldNames.comparatorId ||
						fieldName === filterFieldNames.connectorId
					) {
						requestCriteriaListData[fieldName] =
							formElement.value.id;
					}
				}
			}

			removeFieldsWithEmptyValues(inputValue);
			if (Object.keys(inputValue).length !== 0) {
				requestCriteriaListData.values = [inputValue];
			}

			removeFieldsWithEmptyValues(requestCriteriaListData.field);
			removeFieldsWithEmptyValues(requestCriteriaListData);
			requestData.criteriaList.push(requestCriteriaListData);
			removeFieldsWithEmptyValues(requestData);
		}

		return requestData;
	};

	const applyFilter = () => {
		//To Filter the valid filters
		const validFilters =
			filterState.filterUICriteriaList.filter(isCriteriaValid);
		updateFilterUICriteriaList(validFilters);

		updateFilterCount(filterState.filterUICriteriaList.length);
		let requestData = getApplyFilterRequestData();
		filterListByCondition({ criteria: requestData.criteriaList });
		showSaveViewAsButton();
	};

	return (
		<Box
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-around",
				backgroundColor: "#FFF",
				padding: "15px 0px",
			}}
		>
			<Button
				variant="contained"
				color="secondary"
				disableElevation
				disabled={filterState.filterUICriteriaList.length === 0}
				sx={{
					"&.Mui-disabled": {
						backgroundColor: theme.palette.secondary.main,
						color: alpha(theme.palette.secondary.contrastText, 0.6),
					},
				}}
				onClick={clearFilterCondition}
			>
				Clear All
			</Button>

			<Tooltip
				title={
					!isValidFilter
						? "Please add at least one condition to the filter"
						: ""
				}
				placement="right"
			>
				<span
					style={{
						cursor:
							!filterState.isEdited || !isValidFilter
								? "not-allowed"
								: "pointer",
					}}
				>
					<Button
						variant="contained"
						disableElevation
						disabled={!filterState.isEdited || !isValidFilter}
						sx={{
							"&.Mui-disabled": {
								backgroundColor: "rgba(51, 188, 126)",
								color: alpha("#FFFFFF", 0.6),
							},
						}}
						onClick={applyFilter}
					>
						Apply
					</Button>
				</span>
			</Tooltip>
		</Box>
	);
}

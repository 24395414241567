import React, { useEffect, useMemo, useRef, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	IconButton,
	Paper,
	Stack,
	TextField,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";
import { default as DragIcon } from "../../../../../../../../assets/icons/drag";
import { default as TickIcon } from "../../../../../../../../assets/icons/tick";
import { default as EditIcon } from "../../../../../../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../../../../../../assets/icons/delete";
import { default as CloseIcon } from "../../../../../../../../assets/icons/close";
import { TextButton } from "../../../../../../../../styles/twozo";

export default function Field(props) {
	const {
		formProperty,
		index,
		dragHandleProps,
		isField,
		handleDeleteField,
		handleFieldUpdate,
		handleFieldAddition,
		fieldListData,
	} = props;

	const theme = useTheme();
	const styles = {
		text: {
			fontSize: 15,
		},
		subText: {
			fontSize: 14,
		},
	};
	const inputRef = useRef(null);
	const [isFieldHovered, setIsFieldHovered] = useState(false);
	const [isFieldEditable, setIsFieldEditable] = useState(false);
	const [isFieldValueChanged, setIsFieldValueChanged] = useState(false);
	const [fieldName, setFieldName] = useState(formProperty.label);
	const [isFieldRequired, setIsFieldRequired] = useState(
		formProperty?.config?.isRequired
	);
	const [isFieldNotSaved, setIsFieldNotSaved] = useState(
		formProperty.isFieldNotSaved
	);

	const onMouseOverField = () => setIsFieldHovered(true);
	const onMouseOutField = () => setIsFieldHovered(false);

	const handleFieldEditable = () => {
		setIsFieldEditable(true);
	};

	const handleFieldName = (event) => {
		const { value } = event.target;
		setFieldName(value);
	};

	const handleUpdateClick = () => {
		if (fieldName !== "") {
			handleFieldUpdate(fieldName, isFieldRequired, index);
			setIsFieldEditable(false);
			setIsFieldValueChanged(false);
		}
	};

	const handleAddClick = () => {
		if (fieldName !== "") {
			setIsFieldNotSaved(false);
			setIsFieldValueChanged(false);
			delete formProperty.isFieldNotSaved;
			handleFieldAddition(fieldName, isFieldRequired, index);
		}
	};

	const handleFieldNameOnBlur = () => {
		if (!isFieldValueChanged) {
			setIsFieldEditable(false);
		}
	};

	const setFieldRequiredValue = (value) => {
		setIsFieldRequired(value);
	};

	useEffect(() => {
		if (
			fieldName !== formProperty.label ||
			isFieldRequired !== formProperty.config.isRequired
		) {
			setIsFieldValueChanged(true);
		} else {
			setIsFieldValueChanged(false);
		}
	}, [fieldName, formProperty, isFieldRequired]);

	useEffect(() => {
		if (!(!isFieldEditable && !isFieldNotSaved)) {
			inputRef.current.focus();
		}
	}, [isFieldEditable, isFieldNotSaved]);

	const handleRevertFieldName = () => {
		setIsFieldEditable(false);
		if (isFieldValueChanged) {
			setFieldName(formProperty.label);
			setIsFieldRequired(formProperty.config.isRequired);
		}
	};

	const fieldType = useMemo(() => {
		const fieldData = fieldListData?.find(
			(field) => field.fieldId === formProperty?.fieldId
		);
		return fieldData?.fieldType?.displayName;
	}, [fieldListData, formProperty?.fieldId]);

	return (
		<React.Fragment>
			<Box>
				<Paper
					elevation={0}
					onMouseOver={onMouseOverField}
					onMouseOut={onMouseOutField}
					sx={{
						height: "56px",
						border: (theme) => `1px solid ${theme.palette.divider}`,
						borderRadius: (theme) => theme.spacing(1),
						padding: (theme) => theme.spacing(1),
						boxShadow: isFieldHovered
							? "0px 2px 4px rgba(0, 0, 0, 0.1)"
							: "none",
						cursor: "pointer",
						spacing: 1,
					}}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={2}
						height="100%"
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="flex-start"
							spacing={2}
							width="75%"
						>
							<Box display="flex" {...dragHandleProps}>
								{DragIcon(24, 24, "grey")}
							</Box>

							<Stack
								direction="row"
								width="65%"
								spacing={1.5}
								alignItems="center"
							>
								<Stack>
									{!isField && (
										<Typography
											style={styles.subText}
											fontWeight={500}
										>
											{formProperty?.name}
										</Typography>
									)}

									{!isFieldEditable && !isFieldNotSaved ? (
										<Stack direction="row" spacing={1}>
											<Typography
												style={styles.text}
												onClick={handleFieldEditable}
											>
												{fieldName}
											</Typography>
											<Typography
												color={theme.palette.error.main}
											>
												{isFieldRequired ? "*" : ""}
											</Typography>
										</Stack>
									) : (
										<TextField
											inputRef={inputRef}
											autoFocus
											value={fieldName}
											onChange={handleFieldName}
											onBlur={handleFieldNameOnBlur}
											variant="standard"
											inputProps={{
												style: {
													padding: "0px",
													fontSize: 15,
												},
											}}
										/>
									)}
								</Stack>

								{isField && (
									<Box
										py={0.2}
										px={1}
										sx={{
											background: (theme) =>
												theme.palette.secondary.main,
											borderRadius: "3px",
										}}
									>
										<Stack>
											<Typography style={styles.subText}>
												{fieldType}
											</Typography>
										</Stack>
									</Box>
								)}
							</Stack>

							<Box width="20%" hidden={!isField}>
								<Stack
									direction="row"
									alignItems="center"
									spacing={0.5}
								>
									<Checkbox
										checked={isFieldRequired}
										onChange={(_, value) =>
											setFieldRequiredValue(value)
										}
									/>
									<Typography style={styles.text}>
										Required
									</Typography>
								</Stack>
							</Box>
						</Stack>

						<Box hidden={!isFieldNotSaved}>
							<Stack
								direction="row"
								alignItems="center"
								spacing={1.5}
							>
								<TextButton
									onClick={() => handleDeleteField(index)}
									startIcon={CloseIcon(
										20,
										20,
										theme.palette.secondary.contrastText
									)}
									sx={{
										border: `1px solid ${theme.palette.secondary.main}`,
										px: 1,
										py: 0.5,
									}}
								>
									<Typography
										style={styles.subText}
										fontWeight={600}
										color={
											theme.palette.secondary.contrastText
										}
									>
										Cancel
									</Typography>
								</TextButton>

								<Button
									variant="contained"
									color="secondary"
									startIcon={TickIcon(
										20,
										20,
										theme.palette.secondary.contrastText,
										fieldName === "" ? 0.6 : 1
									)}
									disableElevation
									onClick={handleAddClick}
									sx={{
										color:
											fieldName === "" &&
											alpha(
												theme.palette.secondary
													.contrastText,
												0.6
											),
									}}
								>
									Add
								</Button>
							</Stack>
						</Box>

						<Box hidden={!isFieldValueChanged || isFieldNotSaved}>
							<Stack
								direction="row"
								alignItems="center"
								spacing={1.5}
							>
								<TextButton
									onClick={handleRevertFieldName}
									startIcon={CloseIcon(
										20,
										20,
										theme.palette.secondary.contrastText
									)}
									sx={{
										border: `1px solid ${theme.palette.secondary.main}`,
										px: 1,
										py: 0.5,
									}}
								>
									<Typography
										style={styles.subText}
										fontWeight={600}
										color={
											theme.palette.secondary.contrastText
										}
									>
										Cancel
									</Typography>
								</TextButton>

								<Button
									variant="contained"
									color="secondary"
									startIcon={TickIcon(
										20,
										20,
										theme.palette.secondary.contrastText,
										fieldName === "" ? 0.6 : 1
									)}
									disableElevation
									onClick={handleUpdateClick}
									sx={{
										color:
											fieldName === "" &&
											alpha(
												theme.palette.secondary
													.contrastText,
												0.6
											),
									}}
								>
									Update
								</Button>
							</Stack>
						</Box>

						<Box
							hidden={
								!isFieldHovered ||
								isFieldNotSaved ||
								isFieldValueChanged
							}
						>
							<Stack direction="row" spacing={0.5}>
								<IconButton
									onClick={() => setIsFieldEditable(true)}
								>
									{EditIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>

								<IconButton
									onClick={() => handleDeleteField(index)}
								>
									{DeleteIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
							</Stack>
						</Box>
					</Stack>
				</Paper>
			</Box>
		</React.Fragment>
	);
}

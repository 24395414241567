import { useState } from "react";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import SingleDateTimePicker from "../../../../../../../../../../../Elements/SingleDateTimePicker";
import dayjs from "dayjs";

export default function DateRange({ value, onFieldValueChange }) {
	const theme = useTheme();

	const [dateTimeRangeValue, setDateTimeRangeValue] = useState(value ?? {});

	const handleDateTimeRangeValueChange = (dateTimeRangeValue) => {
		if (onFieldValueChange) {
			onFieldValueChange(dateTimeRangeValue);
		}
	};

	const onStartDateTimeChange = (value) => {
		//here value is a dayjs object, valueOf gives unixtimestamp
		let newValue = { ...dateTimeRangeValue, from: value.valueOf() };
		setDateTimeRangeValue(newValue);
		handleDateTimeRangeValueChange(newValue);
	};

	const onEndDateTimeChange = (value) => {
		//here value is a dayjs object, valueOf gives unixtimestamp
		let newValue = { ...dateTimeRangeValue, to: value.valueOf() };
		setDateTimeRangeValue(newValue);
		handleDateTimeRangeValueChange(newValue);
	};

	const getParsedValue = (value) => {
		return value ? dayjs(value) : null;
	};

	const fromTextFieldSx = {
		backgroundColor: theme.palette.secondary.main,
		borderRadius: "0px",
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none",
			borderRadius: "0px",
			width: "100%",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				border: "none",
			},
		},
		height: "42px",
		width: "50%",
	};

	const toTextFieldSx = {
		backgroundColor: theme.palette.secondary.main,
		borderRadius: "0px 8px 8px 0px",
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none",
			borderRadius: "0px 8px 8px 0px",
			width: "100%",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				border: "none",
			},
		},
		height: "42px",
		width: "50%",
	};

	return (
		<>
			<Divider orientation="vertical" style={{ height: "42px" }} />

			<Box width="100%" position="relative">
				<SingleDateTimePicker
					textFieldProps={{
						sx: {
							width: "50%",
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "8px 0px 0px 8px",
							},
							...fromTextFieldSx,
						},
					}}
					onChange={(value) => {
						onStartDateTimeChange(value);
					}}
					value={getParsedValue(dateTimeRangeValue.from)}
				/>

				<Box
					style={{
						display: "inline",
						position: "absolute",
						marginTop: "10px",
						marginLeft: "-8px",
						backgroundColor: "#fff",
						zIndex: 2,
					}}
				>
					<Typography
						fontSize={14}
						color={"rgba(0, 0, 0, 0.6)"}
						bgcolor={theme.palette.secondary.main}
					>
						To
					</Typography>
				</Box>

				<SingleDateTimePicker
					textFieldProps={{
						sx: {
							width: "50%",
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "0px 8px 8px 0px",
								marginLeft: "-1px",
							},
							...toTextFieldSx,
						},
						inputProps: {
							style: { textAlign: "right", fontSize: "14px" },
						},
					}}
					slotProps={{ inputAdornment: { position: "end" } }}
					popperProps={{ placement: "bottom-end" }}
					onChange={(value) => {
						onEndDateTimeChange(value);
					}}
					value={getParsedValue(dateTimeRangeValue.to)}
					minDate={getParsedValue(dateTimeRangeValue.from)} //Prevents the selection of all values before minDate
				/>
			</Box>
		</>
	);
}

import React, { useState } from "react";
import { Paper, Box, Radio, Typography, Stack, useTheme } from "@mui/material";
import { default as RadioOffIcon } from "../../../../../../assets/icons/radioOff";
import { default as RadioTickedIcon } from "../../../../../../assets/icons/radioTicked";
import companyProfile from "../../../../../../assets/images/company/companyProfile.png";
import { getDateMonthAndYearFormat } from "../../../../../../utils/DateUtils";

const styles = {
	personName: {
		fontSize: 15,
		fontWeight: 500,
	},
	details: {
		fontSize: 15,
	},
	text: {
		fontSize: 13,
	},
};

export default function CompanyCardList(props) {
	const theme = useTheme();
	const { companyData, handleSelectCompany, isSelected } = props;
	const [hoveredCardId, setHoveredCardId] = useState(null);

	const onMouseOverCard = (id) => setHoveredCardId(id);
	const onMouseOutCard = () => setHoveredCardId(null);
	return (
		<Stack direction="column" spacing={1}>
			<Paper
				key={companyData.id}
				elevation={0}
				onMouseOver={() => onMouseOverCard(companyData.id)}
				onMouseOut={() => onMouseOutCard()}
				onClick={() => handleSelectCompany(companyData)}
				sx={{
					boxShadow:
						companyData.id === hoveredCardId
							? "0px 2px 4px rgba(0, 0, 0, 0.1)"
							: "none",
					border: isSelected
						? `1px solid ${theme.palette.primary.main}`
						: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
					cursor: "pointer",
				}}
			>
				<Box>
					<Stack
						direction="row"
						spacing={2}
						alignItems="center"
						justifyContent="space-between"
						width="100%"
						p={1}
					>
						<Stack
							direction="row"
							spacing={2}
							p={1}
							alignItems="center"
						>
							<img
								src={companyProfile}
								alt="company_profile"
								width="24px"
								height="24px"
							/>
							<Typography
								onClick={() =>
									window.open(
										`/companies/${companyData?.id?.toString()}`,
										"_blank"
									)
								}
								style={styles.personName}
								color={theme.palette.secondary.contrastText}
							>
								{companyData.name}
							</Typography>
						</Stack>
						<Radio
							icon={RadioOffIcon(
								22,
								22,
								theme.palette.primary.main
							)}
							checkedIcon={RadioTickedIcon(
								22,
								22,
								theme.palette.primary.main
							)}
							value={companyData.id}
							checked={isSelected}
						/>
					</Stack>
					<Box p={2} pt={0}>
						<table>
							<tbody>
								<tr>
									<td style={{ minWidth: "70px" }}>
										<Typography
											style={styles.details}
											pr={1}
										>
											Sales Owner :
										</Typography>
									</td>
									<td>
										<Typography style={styles.details}>
											{companyData.salesOwner.name}
										</Typography>
									</td>
								</tr>
								<tr>
									<td>
										<Typography style={styles.details}>
											Created On :
										</Typography>
									</td>
									<td>
										<Typography style={styles.details}>
											{getDateMonthAndYearFormat(
												companyData.createdOn
											)}
										</Typography>
									</td>
								</tr>
							</tbody>
						</table>
					</Box>
				</Box>
			</Paper>
		</Stack>
	);
}

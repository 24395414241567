import React from "react";
import FieldList from "../FieldList";

export default function Deals(props) {
	const {
		newFieldCreated,
		clearCreatedField,
		fieldTypeOption,
		moduleName,
		editingFieldRef,
	} = props;

	return (
		<React.Fragment>
			<FieldList
				editingFieldRef={editingFieldRef}
				clearCreatedField={clearCreatedField}
				newFieldCreated={newFieldCreated}
				moduleName={moduleName}
				fieldTypeOption={fieldTypeOption}
			></FieldList>
		</React.Fragment>
	);
}

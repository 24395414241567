import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getDropdownKeyByFieldId } from "../../../../../utils/queryKeys";
import { getFieldDropdownData } from "../../../../../api/fields/config/configApi";
import { useStepOptions } from "../../../../../hooks/services/workflow";
import MultiSelectBaseComponent from "./MultiSelectBaseComponent";

const getSelectOptions = (data) => {
	return (
		data?.options?.map((option) => ({
			value: {
				name: option.name,
				id: option.id || option.value,
			},
		})) || []
	);
};

export default function MultiSelectComponent(params) {
	const {
		field = {},
		hasError,
		metadata,
		updateFieldValue,
		value,
		style = {},
		clearError,
		isConditionField,
	} = params;
	const [selectedValues, setSelectedValues] = useState([]);

	const { data: stepOptions } = useStepOptions(metadata, field);

	const { data: options } = useQuery(
		getDropdownKeyByFieldId(field.fieldId),
		() => getFieldDropdownData({ id: field.fieldId }),
		{
			enabled: !!field?.fieldId,
			select: getSelectOptions,
		}
	);

	useEffect(() => {
		if (Array.isArray(value)) {
			setSelectedValues(value);
		}
	}, [value]);

	const handleFieldChange = (values) => {
		setSelectedValues(values);
		updateFieldValue(values, field.fieldId);
		clearError(field.fieldId);
	};

	return (
		<MultiSelectBaseComponent
			style={style}
			isConditionField={isConditionField}
			options={options || []}
			hasError={hasError}
			stepOptions={stepOptions || []}
			selectedValues={selectedValues}
			onFieldChange={handleFieldChange}
			hasStepOptions={field?.config?.hasStepOptions}
		/>
	);
}

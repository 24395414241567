import React, { useEffect, useMemo, useState } from "react";
import { MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { default as DropDownIcon } from "../../../../../../../../assets/icons/dropDown";
import {
	useComparator,
	useComparatorForAdditionOption,
} from "../../../../../../../../hooks/services/common/table/filter";
import { useFilterDataContext } from "../../../../Context/FilterDataContext";
import Menu from "../../../../../../Menu";
import { MenuItemText } from "../../../../../../../../styles/twozo";

export default function Comparator(props) {
	const { filterCondition, onChange } = props;

	const theme = useTheme();

	const styles = {
		data: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			height: "38px",
			padding: "0px 10px",
			backgroundColor: "rgba(51, 188, 126, 0.12)",
		},
	};

	const { enableApplyButton } = useFilterDataContext();
	const selectedField = filterCondition.fieldData || {};

	const [comparatorMenuElement, setComparatorMenuElement] = useState(null);
	const isComparatorMenuOpen = Boolean(comparatorMenuElement);

	const openComparatorMenu = (event) => {
		setComparatorMenuElement(event.currentTarget);
	};

	const closeComparatorMenu = () => {
		setComparatorMenuElement(null);
	};

	const handleSelectComparator = (selectedValue) => {
		const updatedComparator = {
			...filterCondition,
			valueEntityId: { ...filterCondition.valueEntityId, value: "" },
			value: { ...filterCondition.value, value: "" },
			comparatorId: {
				...filterCondition.comparatorId,
				value: { id: selectedValue.id, name: selectedValue.name },
			},
			multiSelectValues: [],
			singleSelectValue: {},
		};
		onChange(updatedComparator);
		enableApplyButton();
		setComparatorMenuElement(null);
	};

	//Comparator
	const { data: comparator } = useComparator(selectedField?.fieldType?.id);

	let defaultOption = comparator?.find(
		(comparatorOption) => comparatorOption.isDefault
	);

	useEffect(() => {
		if (defaultOption && !filterCondition.comparatorId.value?.id) {
			const updatedComparator = {
				...filterCondition,
				comparatorId: {
					...filterCondition.comparatorId,
					value: { id: defaultOption?.id, name: defaultOption?.name },
				},
			};
			onChange(updatedComparator);
		}
	}, [defaultOption, filterCondition, onChange]);

	//Comparator For addtional options field
	const selectedAdditionalOption = filterCondition?.additionalOptions;

	const { data: comparatorForAdditionalOption } =
		useComparatorForAdditionOption(selectedAdditionalOption?.id);

	const comparatorDefaultOption = useMemo(() => {
		return comparatorForAdditionalOption?.find(
			(comparatorOption) => comparatorOption.isDefault
		);
	}, [comparatorForAdditionalOption]);

	useEffect(() => {
		if (
			comparatorDefaultOption &&
			!filterCondition.comparatorId.value?.id
		) {
			const updatedComparator = {
				...filterCondition,
				comparatorId: {
					...filterCondition.comparatorId,
					value: {
						id: comparatorDefaultOption?.id,
						name: comparatorDefaultOption?.name,
					},
				},
			};
			onChange(updatedComparator);
		}
	}, [comparatorDefaultOption, filterCondition, onChange]);

	const getComparator = () => {
		return filterCondition?.additionalOption.displayName
			? comparatorForAdditionalOption
			: comparator;
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="202px"
				anchorEl={comparatorMenuElement}
				open={isComparatorMenuOpen}
				onClose={closeComparatorMenu}
				style={{ transform: "translateX(8px) translateY(-1px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				{getComparator()?.map((comparator, comparatorIndex) => (
					<MenuItem
						style={{ height: "40px" }}
						key={comparatorIndex}
						onClick={() => handleSelectComparator(comparator)}
					>
						<MenuItemText>{comparator.name}</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			<Stack
				direction="row"
				style={{
					...styles.data,
					cursor: "pointer",
					borderRadius:
						filterCondition.comparatorId.value.name ===
							"is empty" ||
						filterCondition.comparatorId.value.name ===
							"is not empty"
							? "0px 0px 8px 8px"
							: null,
					borderBottom: comparatorMenuElement
						? `1px solid ${theme.palette.primary.main}`
						: filterCondition.comparatorId.value.name ===
									"is empty" ||
							  filterCondition.comparatorId.value.name ===
									"is not empty"
							? null
							: "1px solid rgba(0,0,0,0.1)",
				}}
				onClick={(event) => openComparatorMenu(event)}
			>
				{filterCondition.comparatorId.value.name ? (
					<Typography fontSize={14}>
						{filterCondition.comparatorId.value.name}
					</Typography>
				) : (
					<Typography
						sx={{
							fontSize: "14px",
							color: "rgba(0,0,0,0.4)",
						}}
					>
						Select
					</Typography>
				)}
				{DropDownIcon(12, 12)}
			</Stack>
		</React.Fragment>
	);
}

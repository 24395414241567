import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import NavigationBar from "./NavigationBar";

export default function Reports() {
	const drawerWidth = 300;
	const navigate = useNavigate();
	const location = useLocation();
	const ref = useRef(null);
	const [topBarHeight, setTopBarHeight] = useState(0);

	useEffect(() => {
		setTopBarHeight(ref.current.getBoundingClientRect().top);
	}, [ref]);

	useEffect(() => {
		if (location.pathname === "/analytics") {
			navigate("dashboard/list/favourites");
		}
	}, [location, navigate]);

	const menus = [
		{
			menu: [
				{
					name: "Favourites",
					path: "favourites",
				},
			],
		},
		{
			menu: [
				{
					name: "All Dashboards",
					path: "all",
				},
				{
					name: "Curated Dashboards",
					path: "curated",
				},
				{
					name: "Private Dashboards",
					path: "private",
				},
				{
					name: "Shared Dashboards",
					path: "shared",
				},
				{
					name: "Owned Dashboards",
					path: "owned",
				},
				{
					name: "Trash",
					path: "trash",
				},
			],
		},
	];

	return (
		<React.Fragment>
			<React.Fragment>
				<Box
					ref={ref}
					sx={{
						display: "flex",
						height: `calc(100vh - ${topBarHeight}px)`,
					}}
				>
					<Box sx={{ width: drawerWidth, overflowY: "auto" }}>
						<NavigationBar menus={menus} />
					</Box>

					<Box
						sx={{
							flexGrow: 1,
							width: `calc(100% - ${drawerWidth}px)`,
							overflowY: "auto",
						}}
					>
						<Box
							style={{
								height: "100%",
							}}
							p={1}
						>
							<Box
								style={{
									border: "1px solid rgba(0, 0, 0, 0.1)",
									borderRadius: "8px",
									height: "100%",
								}}
							>
								<Outlet />
							</Box>
						</Box>
					</Box>
				</Box>
			</React.Fragment>
		</React.Fragment>
	);
}

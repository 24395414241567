import { Box, Stack } from "@mui/material";
import SingleCondition from "../../../../../../../../../Workflow/Condition/SingleCondition";
import { useMemo } from "react";
import {
	maxConditionLimit,
	multipleValuesFieldTypes,
} from "../../../../../../../../../Workflow/WorkflowConfig/config";
import { DwarfButton } from "../../../../../../../../../../styles/twozo";

export default function FilterList(params) {
	const {
		filterList = [],
		addFilter,
		updateFilter,
		deleteFilter,
		comparators,
		fields,
	} = params;

	const fieldValues = useMemo(() => {
		if (!Array.isArray(filterList)) return {};

		const formFieldValue = {};

		filterList.forEach((field, index) => {
			if (field?.values && Array.isArray(field?.values)) {
				const { fieldType: type, values } = field;

				formFieldValue[index] = multipleValuesFieldTypes.includes(type)
					? values
					: values[0];
			}
		});

		return formFieldValue;
	}, [filterList]);

	const handleUpdateField = (updatedField, criteriaIndex) => {
		let clonedFilterList = [...filterList];
		clonedFilterList[criteriaIndex] = {
			...clonedFilterList[criteriaIndex],
			...updatedField,
		};
		updateFilter(clonedFilterList);
	};

	const isAddFilterEnabled = useMemo(() => {
		return (
			filterList[filterList?.length - 1]?.field?.id ||
			filterList[filterList?.length - 1]?.fieldId
		);
	}, [filterList]);

	return (
		<>
			<Stack spacing={2} alignItems="flex-start">
				{filterList?.map((field, index) => (
					<Stack
						key={`${field?.field?.fieldId}-${index}`}
						direction="row"
						alignItems="center"
						spacing={1.5}
					>
						<Box minWidth="500px" height="100%">
							<SingleCondition
								key={"fieldCondition-" + field?.field?.fieldId}
								isDeleteEnabled={
									filterList?.length > 1
										? true
										: filterList?.length === 1 &&
											!!filterList?.[0]?.field?.id
								}
								handleUpdateCondition={(data) =>
									handleUpdateField(data, index)
								}
								formValues={fieldValues}
								value={fieldValues[index]}
								condition={field}
								deleteCondition={() => deleteFilter(index)}
								comparatorsList={comparators}
								fieldsList={fields}
							/>
						</Box>
					</Stack>
				))}

				{filterList?.length < maxConditionLimit && (
					<DwarfButton
						onClick={addFilter}
						style={{
							marginLeft: "-5px",
							opacity: isAddFilterEnabled ? 1 : 0.6,
							pointerEvents: isAddFilterEnabled ? "auto" : "none",
						}}
					>
						+ Add Filter
					</DwarfButton>
				)}
			</Stack>
		</>
	);
}

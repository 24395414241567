import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getFollowersListKey,
	getUserAndTeamKey,
} from "../../../../utils/queryKeys/summary/followers";
import {
	bulkUpdateFollowers,
	getFollowers,
	getUserAndTeamList,
	updateFollowers,
} from "../../../../api/followers";

const useFollowersList = (sourceName, entityId, fieldToSort) => {
	const followerListKey = getFollowersListKey(
		sourceName,
		entityId,
		fieldToSort
	);
	return useQuery(
		followerListKey,
		() => getFollowers(sourceName, entityId, fieldToSort),
		{
			select: (followers) => followers?.list,
		}
	);
};

const useUserAndTeamList = (sourceName, searchValue) => {
	return useQuery(getUserAndTeamKey(sourceName, searchValue), () =>
		getUserAndTeamList(sourceName, searchValue)
	);
};

const useInvalidateFollowersList = (sourceName, entityId) => {
	let queryClient = useQueryClient();
	let followersListKey = getFollowersListKey(sourceName, entityId);
	return () => {
		queryClient.invalidateQueries(followersListKey);
	};
};

const useUpdateFollowers = (sourceName, entityId) => {
	let invalidateFollowersList = useInvalidateFollowersList(
		sourceName,
		entityId
	);
	return useMutation(updateFollowers, {
		onSuccess: () => {
			invalidateFollowersList();
		},
	});
};

const useBulkAssignFollowers = () => {
	return useMutation(bulkUpdateFollowers, {
		onSuccess: () => {},
	});
};

export {
	useFollowersList,
	useUserAndTeamList,
	useUpdateFollowers,
	useBulkAssignFollowers,
};

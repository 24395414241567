import React, { useState } from "react";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { twozoStyles, DwarfButton } from "../../../styles/twozo";
import AddDeal from "../AddDeal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteMultipleProducts } from "../../../api/product/productApi";
import DeleteDialog from "../../Elements/DeleteDialog";
import UpdateField from "../../Elements/UpdateField";
import { getProductTableKey } from "../../../utils/queryKeys";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { default as CloseIcon } from "../../../assets/icons/close";
import ProductAssignOwner from "./assignOwner";
import { PERMISSIONS } from "../../../utils/Auth";
import { useAuth } from "../../../hooks/auth";
import { usePartialBulkUpdateProduct } from "../../../hooks/services/product";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import { useProductFieldPermissions } from "../../../hooks/modules/auth/product";

export default function SelectedMenu(props) {
	const {
		data: productTableRowsData,
		selected,
		deSelectAll,
		setSelected,
	} = props;
	const classes = twozoStyles();
	const [isAssignOwnerDialogOpened, setIsAssignOwnerDialogOpened] =
		useState(false);
	const [isAddDealDrawerOpened, setIsAddDealDrawerOpened] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const queryClient = useQueryClient();
	const [isUpdateFieldDialogOpened, setIsUpdateFieldDialogOpened] =
		useState(false);
	const [selectedProducts, setSelectedProducts] = useState([]);

	const settingsType = "Product";
	const productTableKey = getProductTableKey();

	const { isUserAllowedFor } = useAuth();
	const updateMutation = usePartialBulkUpdateProduct();
	const productFieldPermissions = useProductFieldPermissions();

	const openAddDealDrawer = () => {
		setIsAddDealDrawerOpened(true);
	};

	const closeAddDealDrawer = () => {
		setIsAddDealDrawerOpened(false);
	};

	const handleAddProductsToDeal = () => {
		let selectedProductData = [];
		for (let productData of productTableRowsData) {
			if (selected?.includes(productData?.id)) {
				selectedProductData.push({
					name: productData?.name,
					value: productData?.id,
				});
			}
		}
		setSelectedProducts(selectedProductData);
		openAddDealDrawer();
	};

	const handleDeleteProducts = () => {
		setShowDeleteDialog(true);
	};

	const cancelDelete = () => {
		setShowDeleteDialog(false);
	};

	const performDeleteConfirm = () => {
		deleteProductsMutation.mutate(selected);
	};

	const handleOpenUpdateFieldDialog = () => {
		setIsUpdateFieldDialogOpened(true);
	};

	const handleCloseUpdateFieldDialog = () => {
		setIsUpdateFieldDialogOpened(false);
	};

	const handleUnCheckSelectedProducts = () => {
		setSelected([]);
	};

	const deleteProductsMutation = useMutation(
		async (productIds) => deleteMultipleProducts(productIds),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(productTableKey);
				if (selected.length > 1) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: `${selected.length} products deleted.`,
					});
				} else {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.productDeleted,
					});
				}

				deSelectAll();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		}
	);

	const handleSaveUpdateField = (formData) => {
		updateMutation.mutate(formData, {
			onSuccess: () => {
				handleCloseUpdateFieldDialog();
				setSelected([]);
				if (selected.length > 1) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: `${selected.length} products updated.`,
					});
				} else {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.productUpdated,
					});
				}
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const onAddProductsToDeal = () => {
		setSelected([]);
	};

	const openAssignOwnerDialog = () => {
		setIsAssignOwnerDialogOpened(true);
	};

	const closeAssignOwnerDialog = () => {
		setIsAssignOwnerDialogOpened(false);
	};

	const onOwnerUpdated = () => {
		deSelectAll();
	};

	const isAddToDealEnabled =
		isUserAllowedFor(PERMISSIONS.product.edit) &&
		isUserAllowedFor(PERMISSIONS.deal.create);

	const selectedMenuOptions = [
		{
			id: 1,
			name: "Add to Deal",
			action: handleAddProductsToDeal,
			hasPermissionToAccess: isAddToDealEnabled,
		},
		{
			id: 2,
			name: "Assign Owner",
			action: openAssignOwnerDialog,
			hasPermissionToAccess: isUserAllowedFor(
				PERMISSIONS.product.bulkAssignRecords
			),
		},
		{
			id: 3,
			name: "Update Field",
			action: handleOpenUpdateFieldDialog,
			hasPermissionToAccess: isUserAllowedFor(
				PERMISSIONS.product.bulkUpdateRecords
			),
		},
		{
			id: 4,
			name: "Delete",
			action: handleDeleteProducts,
			hasPermissionToAccess: isUserAllowedFor(PERMISSIONS.product.delete),
		},
		{
			id: 5,
			name: "Export",
			action: () => {},
			hasPermissionToAccess: isUserAllowedFor(
				PERMISSIONS.product.exportRecords
			),
		},
	];

	const getSelectedMenuOptions = () => {
		return selectedMenuOptions.filter(
			(selectedMenuOption) => selectedMenuOption.hasPermissionToAccess
		);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddDealDrawerOpened}
				onOpen={openAddDealDrawer}
				onClose={closeAddDealDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					className={classes.addDialogContainer}
					style={{ width: "55vw" }}
				>
					<AddDeal
						onClose={closeAddDealDrawer}
						selectedProducts={selectedProducts}
						onAddProductsToDeal={onAddProductsToDeal}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<FieldPermissionContextProvider value={productFieldPermissions}>
				<UpdateField
					selected={selected}
					updateMutation={updateMutation}
					moduleName={settingsType}
					onSave={handleSaveUpdateField}
					onClose={handleCloseUpdateFieldDialog}
					open={isUpdateFieldDialogOpened}
					isSaving={updateMutation.isLoading}
				/>
			</FieldPermissionContextProvider>

			<ProductAssignOwner
				open={isAssignOwnerDialogOpened}
				onClose={closeAssignOwnerDialog}
				productIds={selected}
				onOwnerUpdated={onOwnerUpdated}
			/>

			<DeleteDialog
				title="Are you sure you want to delete the selected products?"
				subtitle="It will delete all the selected products and the data associated with it"
				open={showDeleteDialog}
				onCancel={cancelDelete}
				onDelete={performDeleteConfirm}
			></DeleteDialog>

			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={2}
				height="100%"
			>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Typography fontSize={14} fontWeight={500}>
						{selected.length} Selected
					</Typography>

					<Typography fontSize={14}>
						of {productTableRowsData?.length}
					</Typography>

					<IconButton
						sx={{ padding: "0px" }}
						size="small"
						onClick={handleUnCheckSelectedProducts}
					>
						{CloseIcon(18, 18, "rgba(0, 0, 0, 0.6)")}
					</IconButton>
				</Stack>

				{getSelectedMenuOptions().length > 0 ? (
					<Divider
						orientation="vertical"
						style={{ height: "20px" }}
					/>
				) : null}

				{getSelectedMenuOptions().map((menuOption) => (
					<DwarfButton
						key={menuOption.id}
						variant="contained"
						color="secondary"
						disableElevation
						onClick={menuOption.action}
					>
						{menuOption.name}
					</DwarfButton>
				))}
			</Stack>
		</React.Fragment>
	);
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import EmailView from "../EmailView";
import {
	Box,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import contactImage from "../../../../assets/images/contact/unknownContact.png";
import ReplyAllIcon from "../../../../assets/icons/replyAll";
import ReplyIcon from "../../../../assets/icons/reply";
import MoreIcon from "../../../../assets/icons/more";
import {
	getDateMonthAndYearFormat,
	getFormattedDateMonthYearAndTime,
	getHourAndMinuteFormat,
} from "../../../../utils/DateUtils";
import Menu from "../../../Elements/Menu";
import EmailComposer from "../../EmailComposer";
import ConfirmDialog from "../../../Elements/ConfirmDialog";
import { useDeleteMail } from "../../../../hooks/services/mail";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import DraftComposer from "../../Drafts/DraftComposer";
import { PERMISSIONS } from "../../../../utils/Auth";
import Can from "../../../Auth/Can";
import { useAuth } from "../../../../hooks/auth";
import { MenuItemText, Tooltip } from "../../../../styles/twozo";

export default function ThreadEmailView(props) {
	const {
		mails,
		handleMailSubject,
		mailListName,
		threadId,
		disableViewActions,
		viewerMode,
		isMailViewerFirstRender,
	} = props;
	const theme = useTheme();
	const styles = {
		subText: {
			fontSize: "14px",
		},
	};
	const composerRef = useRef();

	const [defaultComposerValues, setDefaultComposerValues] = useState({});
	const [expandedThreadId, setExpandedThreadId] = useState(
		mails?.[mails?.length - 1]?.id
	);
	const [mailViewerMenuElement, setMailViewerMenuElement] = useState(null);
	const isMailViewerMenuOpened = Boolean(mailViewerMenuElement);
	const [selectedConversationId, setSelectedConversationId] = useState("");
	const [
		replyMailIdForDuplicateComposer,
		setReplyMailIdForDuplicateComposer,
	] = useState("");

	const [showDiscardDialog, setShowDiscardDialog] = useState(false);
	const [composerMode, setComposerMode] = useState({});
	const [showDraftComposer, setShowDraftComposer] = useState(true);

	const scheduled = "SCHEDULED";
	const draft = "DRAFT";
	const successNotificationTitle = "Success!";
	const replyMode = composerMode.reply || composerMode.replyAll;

	const deleteMailMutation = useDeleteMail(mailListName, threadId);
	const { isUserAllowedFor } = useAuth();

	const handleMailThreadOpen = (thread) => (_, newThread) => {
		setExpandedThreadId(newThread ? thread : false);
	};

	useEffect(() => {
		const selectedMail = mails?.find(
			(mail) => mail?.id === expandedThreadId
		);
		if (selectedMail) {
			handleMailSubject(selectedMail?.subject);
		}
	}, [expandedThreadId, handleMailSubject, mails]);

	const isReplyAllActionEnabled = (mailData) => {
		const toAddressData = mailData?.recipients?.to || [];
		const ccAddressData = mailData?.recipients?.cc || [];
		const bccAddressData = mailData?.recipients?.bcc || [];
		const isMultipleRecipients =
			[...toAddressData, ...ccAddressData, ...bccAddressData].length > 1;
		return isMultipleRecipients;
	};

	const openMailViewerMenu = (event) => {
		event.stopPropagation();
		setMailViewerMenuElement(event.currentTarget);
	};

	const closeMailViewerMenu = (event) => {
		event.stopPropagation();
		setMailViewerMenuElement(null);
	};

	useEffect(() => {
		if (composerRef.current) {
			if (!composerRef.current) return;

			const resizeObserver = new ResizeObserver(() => {
				composerRef.current.scrollIntoView({ behavior: "smooth" });
			});

			resizeObserver.observe(composerRef.current);
			return () => resizeObserver.disconnect(); // clean up
		}
	}, [selectedConversationId, showDiscardDialog, composerRef]);

	const getReplySubject = (subject) => {
		return subject?.startsWith("Re:") ? subject : `Re: ${subject}`;
	};

	const openEmailComposer = useCallback(
		(mail) => {
			if (!selectedConversationId) {
				setSelectedConversationId(mail.conversationId);
			} else if (selectedConversationId !== mail.conversationId) {
				setShowDiscardDialog(true);
				setReplyMailIdForDuplicateComposer(mail.conversationId);
			}
		},
		[selectedConversationId]
	);

	const handleReply = useCallback(
		(mail, event) => {
			event?.stopPropagation();
			const toAddressData = mail?.recipients?.to || [];
			const subject = mail?.subject;

			if (mail?.messageCategory === "INBOUND") {
				setDefaultComposerValues({
					toAddress: mail?.from?.contactId
						? [mail?.from?.contactId]
						: [{ email: mail?.from.email }],
					subject: getReplySubject(subject),
					body: mail.message,
				});
			} else {
				setDefaultComposerValues({
					toAddress: toAddressData?.map(
						(toAddress) =>
							toAddress.id || { email: toAddress.mailId }
					),
					subject: getReplySubject(subject),
					body: mail.message,
				});
			}
			setComposerMode({ reply: true });
			openEmailComposer(mail);
		},
		[openEmailComposer]
	);

	const getReplyAllToAddress = (mail, toAddressData) => {
		let toAddress = toAddressData?.map(
			(toAddress) => toAddress.id || { email: toAddress.mailId }
		);
		let fromAddress = mail?.from?.contactId
			? mail?.from?.contactId
			: { email: mail?.from.email };
		return [fromAddress, ...toAddress];
	};

	const handleReplyAll = (event, mail) => {
		event.stopPropagation();
		const toAddressData = mail?.recipients?.to || [];
		const ccAddressData = mail?.recipients?.cc || [];
		const subject = mail?.subject;
		if (mail?.messageCategory === "INBOUND") {
			setDefaultComposerValues({
				toAddress: getReplyAllToAddress(mail, toAddressData),
				ccAddress: ccAddressData?.map(
					(ccAddress) => ccAddress.id || { email: ccAddress.mailId }
				),
				subject: getReplySubject(subject),
				body: mail.message,
			});
		} else {
			setDefaultComposerValues({
				toAddress: toAddressData?.map(
					(toAddress) => toAddress.id || { email: toAddress.mailId }
				),
				ccAddress: ccAddressData?.map(
					(ccAddress) => ccAddress.id || { email: ccAddress.mailId }
				),
				subject: getReplySubject(subject),
				body: mail.message,
			});
		}
		setComposerMode({ replyAll: true });
		openEmailComposer(mail);
	};

	const closeEmailComposer = () => {
		setSelectedConversationId("");
		setReplyMailIdForDuplicateComposer("");
		setComposerMode({});
		setDefaultComposerValues({});
		setShowDraftComposer(false);
	};

	const handleConfirm = () => {
		setSelectedConversationId(replyMailIdForDuplicateComposer);
		setShowDiscardDialog(false);
	};

	const handleDeleteMail = (conversationId) => {
		setMailViewerMenuElement(null);
		deleteMailMutation.mutate(conversationId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.mailUnactive,
					title: successNotificationTitle,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const getForwardSubject = (subject) => {
		return subject.startsWith("Fwd:") ? subject : `Fwd: ${subject}`;
	};

	const handleForwardMail = useCallback((mail, event) => {
		event?.stopPropagation();
		setComposerMode({
			forward: true,
		});
		setMailViewerMenuElement(null);
		setSelectedConversationId(mail?.conversationId);
		setDefaultComposerValues({
			subject: getForwardSubject(mail?.subject),
			body: mail.message,
		});
	}, []);

	useEffect(() => {
		const lastConversationData = mails?.[mails?.length - 1];
		if (lastConversationData && isMailViewerFirstRender) {
			if (viewerMode?.reply) {
				handleReply(lastConversationData);
			} else if (viewerMode?.forward) {
				handleForwardMail(lastConversationData);
			}
		}
	}, [
		handleForwardMail,
		handleReply,
		isMailViewerFirstRender,
		mails,
		viewerMode,
	]);

	return (
		<React.Fragment>
			<ConfirmDialog
				open={showDiscardDialog}
				title="Are you sure you want to discard it and create a new one ?"
				subtitle="This action will discard the existing draft and open a new composer."
				onCancel={() => setShowDiscardDialog(false)}
				onConfirm={() => handleConfirm()}
				confirmButtonColor="primary"
			/>

			<Box sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
				{mails?.map((mail) =>
					mail.messageCategory === draft &&
					isUserAllowedFor(PERMISSIONS.email.individualEmailLimit) &&
					!disableViewActions ? (
						<Box
							key={mail.id}
							ref={composerRef}
							hidden={!showDraftComposer}
						>
							<DraftComposer
								onClose={closeEmailComposer}
								conversationId={mail?.conversationId}
								emailContext={{
									threadId: threadId,
									mailListName: mailListName,
								}}
							/>
						</Box>
					) : (
						<Box key={mail.id}>
							<MuiAccordion
								key={mail.id}
								expanded={expandedThreadId === mail.id}
								onChange={handleMailThreadOpen(mail.id)}
								elevation={0}
								sx={{
									borderBottom: `1px solid ${theme.palette.divider}`,
									borderLeft:
										expandedThreadId === mail.id
											? `2px solid ${theme.palette.primary.main}`
											: "2px solid #fff",
								}}
								square
								disableGutters
							>
								<MuiAccordionSummary
									sx={{
										backgroundColor:
											expandedThreadId === mail.id
												? "#fff"
												: "#FAFAFA",
									}}
								>
									{expandedThreadId === mail?.id ? (
										<Menu
											minWidth="120px"
											anchorEl={mailViewerMenuElement}
											open={isMailViewerMenuOpened}
											onClose={closeMailViewerMenu}
										>
											<Can
												permission={
													PERMISSIONS.email
														.individualEmailLimit
												}
											>
												{mail?.messageCategory !==
												scheduled ? (
													<MenuItem
														style={{
															height: "40px",
														}}
														onClick={(event) =>
															handleForwardMail(
																mail,
																event
															)
														}
													>
														<MenuItemText
															fontWeight={500}
															color={
																theme.palette
																	.primary
																	.main
															}
														>
															Forward
														</MenuItemText>
													</MenuItem>
												) : null}
											</Can>

											<MenuItem
												style={{ height: "40px" }}
												onClick={() =>
													handleDeleteMail(
														mail.conversationId
													)
												}
											>
												<MenuItemText
													fontWeight={500}
													color={
														theme.palette.primary
															.main
													}
												>
													Delete
												</MenuItemText>
											</MenuItem>
										</Menu>
									) : null}

									<Box width="42vw">
										<Stack
											direction="row"
											alignItems="center"
											justifyContent="space-between"
											flexWrap="wrap"
											spacing={2}
											pb={2}
										>
											<Stack
												direction="row"
												alignItems="center"
												spacing={2}
											>
												<img
													src={contactImage}
													alt="contact_image"
													height="50px"
													width="50px"
												/>

												<Box>
													<Stack
														direction="row"
														spacing={1}
													>
														<Typography
															fontWeight={500}
															fontSize={15}
															style={{
																overflowWrap:
																	"anywhere",
															}}
														>
															{mail?.from
																?.contactName
																? `${mail.from.contactName} <${mail.from?.email}>`
																: `${mail?.from?.name} <${mail?.from?.email}>`}
														</Typography>
														{mail.isBounced ? (
															<Box
																sx={{
																	p: 0.5,
																	color: theme
																		.palette
																		.secondary
																		.contrastText,
																	backgroundColor:
																		theme
																			.palette
																			.secondary
																			.main,
																	borderRadius:
																		"6px",
																}}
															>
																<Typography
																	fontSize={
																		12
																	}
																	fontWeight={
																		500
																	}
																>
																	Bounced
																</Typography>
															</Box>
														) : null}
													</Stack>

													<Typography
														style={styles.subText}
														color={
															"rgba(0, 0, 0, 0.6)"
														}
													>
														{mail?.messageCategory ===
														scheduled
															? "Scheduled for "
															: ""}
														{getDateMonthAndYearFormat(
															mail?.timeStamp
														)}{" "}
														{getHourAndMinuteFormat(
															mail?.timeStamp
														)}
														{expandedThreadId ===
															mail.id && (
															<>
																{mail?.tracking ? (
																	<span
																		style={{
																			color: "#000",
																			cursor: "pointer",
																		}}
																	>
																		{mail
																			.tracking
																			?.opened ? (
																			<span>
																				&nbsp;&nbsp;•&nbsp;&nbsp;
																				<Tooltip
																					placement="top"
																					title={getFormattedDateMonthYearAndTime(
																						mail
																							.tracking
																							.opened
																							?.timeStamp
																					)}
																				>
																					<span>{`Opened(${mail.tracking.opened?.count})`}</span>
																				</Tooltip>
																			</span>
																		) : null}

																		{mail
																			.tracking
																			?.clicked ? (
																			<span>
																				&nbsp;&nbsp;•&nbsp;&nbsp;
																				<Tooltip
																					placement="top"
																					title={getFormattedDateMonthYearAndTime(
																						mail
																							.tracking
																							.clicked
																							?.timeStamp
																					)}
																				>
																					<span>
																						{`Clicked(${mail.tracking.clicked?.count})`}
																					</span>
																				</Tooltip>
																			</span>
																		) : null}
																	</span>
																) : null}
															</>
														)}
													</Typography>
												</Box>
											</Stack>

											{expandedThreadId === mail?.id ? (
												<Stack
													direction="row"
													alignItems="center"
													style={{
														pointerEvents:
															disableViewActions
																? "none"
																: "auto",
													}}
												>
													<Can
														permission={
															PERMISSIONS.email
																.individualEmailLimit
														}
													>
														{mail.messageCategory !==
														scheduled ? (
															<Box display="flex">
																{isReplyAllActionEnabled(
																	mail
																) ? (
																	<IconButton
																		onClick={(
																			event
																		) =>
																			handleReplyAll(
																				event,
																				mail
																			)
																		}
																	>
																		{ReplyAllIcon(
																			20,
																			20,
																			theme
																				.palette
																				.primary
																				.main
																		)}
																	</IconButton>
																) : null}

																<IconButton
																	onClick={(
																		event
																	) =>
																		handleReply(
																			mail,
																			event
																		)
																	}
																>
																	{ReplyIcon(
																		20,
																		20,
																		theme
																			.palette
																			.primary
																			.main
																	)}
																</IconButton>
															</Box>
														) : null}
													</Can>

													<IconButton
														onClick={(event) =>
															openMailViewerMenu(
																event
															)
														}
													>
														{MoreIcon(
															20,
															20,
															theme.palette
																.primary.main
														)}
													</IconButton>
												</Stack>
											) : null}
										</Stack>

										{expandedThreadId === mail.id ? (
											<Box>
												{mail?.recipients?.to?.length >
												0 ? (
													<Stack
														direction="row"
														alignItems="center"
														spacing={2.5}
													>
														<Typography
															style={
																styles.subText
															}
															color="rgba(0, 0, 0, 0.6)"
															pl={4}
														>
															To
														</Typography>

														<Typography
															style={
																styles.subText
															}
														>
															{mail?.recipients?.to?.map(
																(
																	recipient,
																	index
																) =>
																	`${
																		recipient?.name
																	} <${
																		recipient?.mailId
																	}>${
																		index !==
																		mail
																			?.recipients
																			?.to
																			?.length -
																			1
																			? ", "
																			: ""
																	}`
															)}
														</Typography>
													</Stack>
												) : null}

												{mail?.recipients?.cc?.length >
												0 ? (
													<Stack
														direction="row"
														alignItems="center"
														spacing={2.5}
														pt={1}
													>
														<Typography
															style={
																styles.subText
															}
															color="rgba(0, 0, 0, 0.6)"
															pl={4}
														>
															CC
														</Typography>

														<Typography
															style={
																styles.subText
															}
														>
															{mail?.recipients?.cc?.map(
																(
																	recipient,
																	index
																) =>
																	`${
																		recipient?.name
																	} <${
																		recipient?.mailId
																	}>${
																		index !==
																		mail
																			?.recipients
																			?.cc
																			?.length -
																			1
																			? ", "
																			: ""
																	}`
															)}
														</Typography>
													</Stack>
												) : null}

												{mail?.recipients?.bcc?.length >
												0 ? (
													<Stack
														direction="row"
														alignItems="center"
														spacing={2.5}
														pt={1}
													>
														<Typography
															style={
																styles.subText
															}
															color="rgba(0, 0, 0, 0.6)"
															pl={4}
														>
															BCC
														</Typography>

														<Typography
															style={
																styles.subText
															}
														>
															{mail?.recipients?.bcc?.map(
																(
																	recipient,
																	index
																) =>
																	`${
																		recipient?.name
																	} <${
																		recipient?.mailId
																	}>${
																		index !==
																		mail
																			?.recipients
																			?.bcc
																			?.length -
																			1
																			? ", "
																			: ""
																	}`
															)}
														</Typography>
													</Stack>
												) : null}
											</Box>
										) : null}

										{expandedThreadId !== mail.id && (
											<Typography
												fontSize={14}
												pr={2}
												noWrap
											>
												{mail?.subject
													? mail?.subject
													: "(no subject)"}{" "}
												<span
													style={{
														color: "rgba(0, 0, 0, 0.6)",
													}}
												>
													- {mail?.messagePreview}
												</span>
											</Typography>
										)}
									</Box>
								</MuiAccordionSummary>
								<MuiAccordionDetails>
									<Divider />
									<EmailView
										collapsable
										hideHeader
										mailData={mail}
										disableViewActions={disableViewActions}
									/>
								</MuiAccordionDetails>
							</MuiAccordion>

							{expandedThreadId === mail.id ? (
								<Box ref={composerRef}>
									<Box
										hidden={
											selectedConversationId !==
											mail.conversationId
										}
									>
										{replyMode ? (
											<EmailComposer
												composerValues={
													defaultComposerValues
												}
												onClose={closeEmailComposer}
												composerMode={composerMode}
												conversationId={
													mail?.conversationId
												}
												emailContext={{
													threadId: threadId,
													mailListName: mailListName,
												}}
												trimmedContentContext={
													expandedThreadId === mail.id
														? {
																sendTime:
																	getFormattedDateMonthYearAndTime(
																		mail?.timeStamp
																	),
																fromAddress:
																	mail?.from,
															}
														: {}
												}
											/>
										) : null}

										{composerMode?.forward ? (
											<EmailComposer
												onClose={closeEmailComposer}
												conversationId={
													selectedConversationId
												}
												emailContext={{
													threadId: threadId,
													mailListName: mailListName,
												}}
												composerValues={
													defaultComposerValues
												}
												composerMode={composerMode}
												forwardMailContext={{
													sendTime:
														getFormattedDateMonthYearAndTime(
															mail?.timeStamp
														),
													fromAddress: mail?.from,
													toAddress:
														mail?.recipients?.to,
												}}
											/>
										) : null}
									</Box>
								</Box>
							) : null}
						</Box>
					)
				)}
			</Box>
		</React.Fragment>
	);
}

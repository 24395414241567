import React, { useState } from "react";
import {
	Box,
	Button,
	Stack,
	Typography,
	IconButton,
	Divider,
	useTheme,
	CircularProgress,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import InfoIcon from "../../../../../assets/icons/info";
import CompanyCardList from "./CompanyCardList";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import {
	useMergeDuplicateCompany,
	useReviewCompanyDuplicateList,
} from "../../../../../hooks/services/company/companyDuplicates";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

const styles = {
	contentName: {
		fontSize: 15,
		fontWeight: 500,
	},
	subText: {
		fontSize: 14,
	},
	text: {
		fontSize: 14,
	},
};

export default function ReviewDuplicates(props) {
	const { primaryCompanyId, onClose } = props;
	const theme = useTheme();
	const [selectedCompany, setSelectedCompany] = useState({});
	const mergeDuplicateCompanyMutation = useMergeDuplicateCompany();

	const errorNotificationTitle = "Unable to merge";

	const {
		isLoading: isLoadingDuplicateRecords,
		data: duplicateRecords,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useReviewCompanyDuplicateList(primaryCompanyId);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const hasDuplicateRecords =
		duplicateRecords &&
		duplicateRecords?.pages?.length > 0 &&
		duplicateRecords?.pages[0]?.duplicates &&
		duplicateRecords?.pages[0]?.duplicates?.length > 0;

	const handleSelectCompany = (company) => {
		setSelectedCompany(company);
	};

	const getMergeRequestData = () => {
		let mergeCompanyRequestData = {
			primaryCompanyId: selectedCompany.id,
			duplicateCompanyIds: [],
		};

		if (duplicateRecords?.pages?.length > 0) {
			for (let page of duplicateRecords.pages) {
				if (page.duplicates?.length > 0) {
					for (let duplicateData of page.duplicates) {
						if (duplicateData.id !== selectedCompany.id) {
							mergeCompanyRequestData.duplicateCompanyIds.push(
								duplicateData.id
							);
						}
					}
				}
			}
		}
		return mergeCompanyRequestData;
	};

	const handleMergeCompany = () => {
		if (selectedCompany?.id) {
			let mergeCompanyRequestData = getMergeRequestData();
			mergeDuplicateCompanyMutation.mutate(mergeCompanyRequestData, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message:
							duplicateRecords?.pages?.length > 0 &&
							duplicateRecords?.pages[0]?.totalRecords === 2
								? `${
										duplicateRecords?.pages[0]
											?.totalRecords - 1
									} company have been merged successfully`
								: `${
										duplicateRecords?.pages[0]
											?.totalRecords - 1
									} companies have been merged successfully`,
					});
					onClose();
				},
				onError: (error) => {
					let errorMessage = error.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						title: errorNotificationTitle,
						message: errorMessage
							? errorMessage
							: notificationMessage.errorMessage,
					});
				},
			});
		}
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={2}
					>
						<Typography fontWeight={600} fontSize={17}>
							Review Possible Duplicates
						</Typography>
						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(24, 24, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				{isLoadingDuplicateRecords ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="100vh"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					<Box
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<Box
							m={2}
							p={2}
							style={{
								backgroundColor: "#E7F7F0",
								borderRadius: "8px",
							}}
						>
							<Stack
								direction="row"
								alignItems="center"
								spacing={2}
							>
								<IconButton>
									{InfoIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
								<Stack>
									<Typography style={styles.text}>
										We check for an exact match in the
										field: Company name
									</Typography>
								</Stack>
							</Stack>
						</Box>

						<Box ml={2.5} p={1} ref={lastRowRef}>
							<Typography style={styles.subText} fontWeight={500}>
								Which Company would you like to retain?
							</Typography>
						</Box>

						<Stack px={2} py={1} spacing={1}>
							{hasDuplicateRecords &&
								duplicateRecords?.pages.map((page) =>
									page.duplicates?.map((companyData) => (
										<Box key={companyData.id}>
											<CompanyCardList
												companyData={companyData}
												isSelected={
													selectedCompany.id ===
													companyData.id
												}
												handleSelectCompany={
													handleSelectCompany
												}
											/>
										</Box>
									))
								)}
							<Box ref={lastRowRef}>
								{!isLoadingDuplicateRecords && isFetching && (
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="center"
										spacing={1}
										py={2}
									>
										<CircularProgress size={18} />

										<Typography fontSize={12}>
											Loading More Data
										</Typography>
									</Stack>
								)}
							</Box>
						</Stack>
					</Box>
				)}

				{!isLoadingDuplicateRecords && (
					<>
						<Divider />

						<Box p={2}>
							<Button
								variant="contained"
								disableElevation
								onClick={handleMergeCompany}
								disabled={
									!selectedCompany.id ||
									mergeDuplicateCompanyMutation.isLoading
								}
								sx={{
									"&.Mui-disabled": {
										backgroundColor:
											theme.palette.primary.main,
										color: "rgba(255,255,255, 0.6)",
									},
								}}
							>
								Merge
							</Button>
						</Box>
					</>
				)}
			</Box>
		</React.Fragment>
	);
}

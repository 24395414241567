import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import BoardHeading from "../BoardHeading";
import DealCard from "../DealCard";
import DealAction from "../DealAction";
import { useDealContext } from "../DealContext";
import { useDealForecastViewlistData } from "../../../hooks/services/deal/forecast";
import { useLocation, useNavigate } from "react-router-dom";
import DragDropContextWithNonce from "../../Elements/DragDropContextWithNonce";

const styles = {
	table: {
		borderCollapse: "collapse",
		textAlign: "left",
		width: "100%",
		height: "100%",
	},
	tableHead: {
		width: "20%",
		border: "1px solid rgba(0, 0, 0, 0.1)",
	},
	tableCell: {
		width: "20%",
		border: "1px solid rgba(0, 0, 0, 0.1)",
	},
};

export default function ForecastView(props) {
	const { menuBarRef, isSwipeableFilterOpened, selectedPipeline, criteria } =
		props;
	const theme = useTheme();
	const navigate = useNavigate();
	const { pathname, search } = useLocation();

	const [menuBarEndingPosition, setMenuBarEndingPosition] = useState(0);
	const [showDealAction, setShowDealAction] = useState(false);

	const { startDate, dealColumn, interval, dealState, dealField } =
		useDealContext();
	let formattedStartDate = startDate.valueOf();

	const { data: dealForecastViewListData } = useDealForecastViewlistData(
		selectedPipeline,
		formattedStartDate,
		dealColumn.id,
		interval.id,
		dealState.id,
		dealField.id,
		criteria
	);

	useEffect(() => {
		if (menuBarRef) {
			setMenuBarEndingPosition(
				menuBarRef.current.getBoundingClientRect().bottom
			);
		}
	}, [menuBarRef]);

	const onBeforeCapture = () => {
		setShowDealAction(true);
	};

	const onDragEnd = () => {
		setShowDealAction(false);
	};

	const navigateDealDetailsPage = (dealId) => {
		navigate(dealId.toString(), {
			state: {
				prevPath: `${pathname}${search ? search : ""}`,
			},
		});
	};

	return (
		<React.Fragment>
			<Box
				sx={{
					backgroundColor: "#F4F5F5",
					height: `calc(100vh - ${menuBarEndingPosition}px)`,
					width: "100%",
				}}
				style={{
					marginLeft: isSwipeableFilterOpened ? "250px" : null,
					transition: isSwipeableFilterOpened
						? theme.transitions.create("margin", {
								easing: theme.transitions.easing.easeOut,
								duration:
									theme.transitions.duration.enteringScreen,
							})
						: theme.transitions.create("margin", {
								easing: theme.transitions.easing.sharp,
								duration:
									theme.transitions.duration.leavingScreen,
							}),
				}}
			>
				<DragDropContextWithNonce
					onBeforeCapture={onBeforeCapture}
					onDragEnd={onDragEnd}
				>
					<table style={styles.table}>
						<thead>
							<tr>
								{Array.isArray(dealForecastViewListData) &&
									dealForecastViewListData.map(
										(dealForecastViewHeader) => (
											<th
												key={
													dealForecastViewHeader.title
												}
												style={styles.tableHead}
											>
												<BoardHeading
													name={
														dealForecastViewHeader.title
													}
													amount={
														dealForecastViewHeader
															.totalSummary
															?.totalValue
													}
													dealSize={
														dealForecastViewHeader.dealCount
													}
													forecastView={true}
													symbol={
														dealForecastViewHeader
															.totalSummary
															.currency.symbol
													}
												/>
											</th>
										)
									)}
							</tr>
						</thead>
						<tbody>
							<tr
								style={{
									height: "100%",
								}}
							>
								{Array.isArray(dealForecastViewListData) &&
									dealForecastViewListData.map(
										(dealForecastViewContent) => (
											<td
												key={
													dealForecastViewContent.title
												}
												style={styles.tableCell}
											>
												<Droppable
													droppableId={
														dealForecastViewContent.title
													}
												>
													{(droppableProvided) => (
														<div
															ref={
																droppableProvided.innerRef
															}
															style={{
																height: "100%",
																overflow:
																	"auto",
															}}
														>
															{dealForecastViewContent
																.deals?.length >
															0 ? (
																<Stack
																	height="100%"
																	p={1}
																>
																	{dealForecastViewContent.deals.map(
																		(
																			dealContent,
																			index
																		) => (
																			<Draggable
																				key={
																					dealForecastViewContent.title +
																					"-" +
																					dealContent.id +
																					"-" +
																					index
																				}
																				draggableId={
																					dealForecastViewContent.title +
																					"-" +
																					dealContent.id +
																					"-" +
																					index
																				}
																				index={
																					index
																				}
																				isDragDisabled
																			>
																				{(
																					draggableProvided
																				) => (
																					<Box
																						ref={
																							draggableProvided.innerRef
																						}
																						{...draggableProvided.draggableProps}
																						{...draggableProvided.dragHandleProps}
																						sx={{
																							marginBottom:
																								"8px",
																						}}
																					>
																						<DealCard
																							deal={
																								dealContent
																							}
																							selectedPipeline={
																								selectedPipeline
																							}
																							onClick={() =>
																								navigateDealDetailsPage(
																									dealContent.id
																								)
																							}
																							isLastDealCard={
																								dealForecastViewContent
																									.deals
																									.length -
																									1 ===
																								index
																							}
																						/>
																					</Box>
																				)}
																			</Draggable>
																		)
																	)}
																	{
																		droppableProvided.placeholder
																	}
																</Stack>
															) : (
																<Stack
																	justifyContent="center"
																	alignItems="center"
																	height="100%"
																	p={1}
																>
																	<Typography
																		fontSize={
																			14
																		}
																		style={{
																			color: "rgba(0, 0, 0, 0.6)",
																		}}
																	>
																		No Deals
																		Found
																		here
																	</Typography>
																</Stack>
															)}
														</div>
													)}
												</Droppable>
											</td>
										)
									)}
							</tr>
						</tbody>
					</table>
					{showDealAction ? <DealAction /> : null}
				</DragDropContextWithNonce>
			</Box>
		</React.Fragment>
	);
}

import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function CheckboxComponent(params) {
	const { field, updateFieldValue, value } = params;

	const [isSelected, setIsSelected] = useState(false);

	useEffect(() => {
		if (value) {
			setIsSelected(value?.value);
		}
	}, [value]);

	const handleChangeValue = (event) => {
		const { checked } = event.target;
		setIsSelected(checked);
		updateFieldValue([{ value: checked || false }], field.fieldId);
	};

	return (
		<>
			<Stack flexDirection="row" alignItems={"center"}>
				<Box>
					<Checkbox
						checked={isSelected}
						onChange={handleChangeValue}
					/>
				</Box>

				<Typography fontSize={14} fontWeight={500}>
					{field?.displayName || ""}
				</Typography>
			</Stack>
		</>
	);
}

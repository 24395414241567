import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import {
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	Grid,
	Box,
	IconButton,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../../../assets/icons/close";
import { default as DropdownIcon } from "../../../../../../../assets/icons/dropDownCentered";
import { getDropdownDataByName } from "../../../../../../../utils/dropdownData";
import MenuSearchBar from "../../../../../MenuSearchBar";
import { MenuItemText } from "../../../../../../../styles/twozo/twozo";

export default function AddressBaseComponent({
	onChange,
	value,
	subField,
	field,
	id,
	inputRef,
	error,
	clearErrors,
}) {
	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subField && Array.isArray(subField)) {
			subField.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const addressLineOneField = getSubFieldObject("ADDRESS_LINE1");
	const addressLineTwoField = getSubFieldObject("ADDRESS_LINE2");
	const cityField = getSubFieldObject("CITY");
	const stateField = getSubFieldObject("STATE");
	const countryField = getSubFieldObject("COUNTRY");
	const zipcodeField = getSubFieldObject("ZIPCODE");
	const countryList = getDropdownDataByName("COUNTRY_CODE");
	const [searchCountryName, setSearchCountryName] = useState("");

	const [address, setAddress] = useState({
		[addressLineOneField.id]: "",
		[addressLineTwoField.id]: "",
		[cityField.id]: "",
		[stateField.id]: "",
		[countryField.id]: "",
		[zipcodeField.id]: "",
	});

	const isRemoveActionEnabled =
		address[countryField.id] && !field?.config?.required;

	useEffect(() => {
		if (value) {
			// Check if the server value needs to be formatted
			if (value?.[countryField?.id]?.name) {
				let modifiedAddressValue = { ...value };
				modifiedAddressValue[countryField.id] =
					value?.[countryField.id]?.value;
				setAddress(modifiedAddressValue);
				onChange(modifiedAddressValue);
			} else {
				setAddress(value);
			}
		}
	}, [value, countryField, onChange]);

	const selectedCountryName = useMemo(() => {
		if (!address[countryField.id]) {
			return "";
		}
		const selectedCountry = countryList?.find(
			(country) => country.value === address[countryField.id]
		);
		return selectedCountry ? selectedCountry?.name : "";
	}, [address, countryField.id, countryList]);

	const handleInputChange = (event, subFieldId) => {
		let { value } = event.target;
		let modifiedAddressValue = { ...address };
		modifiedAddressValue[subFieldId] = value;
		let updatedAddressValue = modifiedAddressValue[addressLineOneField.id]
			? modifiedAddressValue
			: "";

		if (modifiedAddressValue[addressLineOneField.id]) {
			clearErrors(field.id);
		}
		setAddress(modifiedAddressValue);
		onChange(updatedAddressValue);
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchCountryName(value);
	};

	const handleRemoveValue = (event) => {
		event.stopPropagation();
		let modifiedAddressValue = { ...address, [countryField.id]: "" };
		setAddress(modifiedAddressValue);
		onChange(modifiedAddressValue);
	};

	const getFilteredCountryList = () => {
		if (searchCountryName.trim()) {
			return countryList?.filter((country) =>
				//filter for search
				country.name
					.toLowerCase()
					.startsWith(searchCountryName.toLowerCase())
			);
		}
		return countryList;
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{countryList?.length === 0
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Grid
				container
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				rowSpacing={1}
			>
				<Grid item xs={12} md={12} style={{ padding: 0 }}>
					<Stack spacing={0.5}>
						<TextField
							id={id}
							placeholder={
								addressLineOneField.placeHolder ||
								"Address Line 1"
							}
							name="ADDRESS_LINE_1"
							onChange={(event) =>
								handleInputChange(event, addressLineOneField.id)
							}
							value={address[addressLineOneField.id]}
							error={!!error}
							inputRef={inputRef}
						/>
					</Stack>
				</Grid>
				<Grid item xs={12} md={12}>
					<Stack spacing={0.5}>
						<TextField
							id={id}
							placeholder={
								addressLineTwoField.placeHolder ||
								"Address Line 2"
							}
							name="ADDRESS_LINE_2"
							onChange={(event) =>
								handleInputChange(event, addressLineTwoField.id)
							}
							value={address[addressLineTwoField.id]}
						/>
					</Stack>
				</Grid>
				<Grid item xs={12} md={5.9}>
					<Stack spacing={0.5}>
						<TextField
							id={id}
							placeholder={cityField.placeHolder || "City"}
							name="CITY"
							onChange={(event) =>
								handleInputChange(event, cityField.id)
							}
							value={address[cityField.id]}
						/>
					</Stack>
				</Grid>
				<Grid item xs={12} md={5.9}>
					<Stack spacing={0.5}>
						<TextField
							id={id}
							placeholder={stateField.placeHolder || "State"}
							name="STATE"
							onChange={(event) =>
								handleInputChange(event, stateField.id)
							}
							value={address[stateField.id]}
						/>
					</Stack>
				</Grid>
				<Grid item xs={12} md={5.9}>
					<Stack spacing={0.5}>
						<Select
							size="small"
							onChange={(event) =>
								handleInputChange(event, countryField.id)
							}
							MenuProps={{
								autoFocus: false,
								PaperProps: {
									style: {
										maxHeight: 300,
										marginTop: "5px",
									},
								},
							}}
							value={address[countryField.id]}
							renderValue={() => (
								<Typography
									fontSize={14}
									color={
										address[countryField.id]
											? "#000"
											: "rgba(0, 0, 0, 0.6)"
									}
									style={{
										opacity: address[countryField.id]
											? 1
											: 0.6,
									}}
								>
									{address[countryField.id]
										? selectedCountryName
										: countryField.placeHolder || "Country"}
								</Typography>
							)}
							IconComponent={(props) =>
								isRemoveActionEnabled ? (
									<Box pr={1.6}>
										<IconButton
											sx={{ padding: 0 }}
											onClick={handleRemoveValue}
										>
											{CloseIcon(
												18,
												18,
												"rgba(0, 0, 0, 0.6)"
											)}
										</IconButton>
									</Box>
								) : (
									<Box {...props}>{DropdownIcon(14, 14)}</Box>
								)
							}
							displayEmpty
							onOpen={() => setSearchCountryName("")}
						>
							<Box
								p={0.5}
								marginTop="-8px"
								sx={{
									position: "sticky",
									top: 0,
									zIndex: 1,
									backgroundColor: "#fff",
								}}
							>
								<MenuSearchBar
									value={searchCountryName}
									onChange={handleSearchValue}
								/>
							</Box>

							{getFilteredCountryList()?.length > 0
								? getFilteredCountryList().map((country) => (
										<MenuItem
											value={country.value}
											key={country.value}
										>
											<Stack
												direction="row"
												spacing={1}
												alignItems="center"
											>
												<MenuItemText>
													{country.name}
												</MenuItemText>
											</Stack>
										</MenuItem>
									))
								: renderEmptyView()}
						</Select>
					</Stack>
				</Grid>
				<Grid item xs={12} md={5.9}>
					<Stack spacing={0.5}>
						<TextField
							id={id}
							placeholder={zipcodeField.placeHolder || "Pincode"}
							name="PINCODE"
							onChange={(event) =>
								handleInputChange(event, zipcodeField.id)
							}
							value={address[zipcodeField.id]}
						/>
					</Stack>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

import React, { useMemo } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import SingleCondition from "../SingleCondition";
import { DwarfButton } from "../../../../styles/twozo";
import {
	andCondition,
	maxConditionLimit,
	orCondition,
	multipleValuesFieldTypes,
} from "../../WorkflowConfig/config";
import { useQuery } from "@tanstack/react-query";
import { getCriteriaFieldsData } from "../../../../api/workflow/workflowApi";
import { getCriteriaFieldsKey } from "../../../../utils/queryKeys/workflow";

export default function ConditionList(props) {
	const {
		readOnly,
		conditionSet,
		addCondition,
		deleteCondition,
		hideNewConditionSetButton,
		addNewConditionSet,
		updateCondition,
		trigger,
	} = props;
	const theme = useTheme();

	const criteriaFieldsKey = getCriteriaFieldsKey(
		trigger?.sourceId,
		trigger?.eventId
	);
	const { data: criteriaFieldsData } = useQuery(
		criteriaFieldsKey,
		() => getCriteriaFieldsData(trigger?.sourceId, trigger?.eventId),
		{}
	);

	const handleChangeConditionOperator = (condition) => {
		let clonedCondition = [...conditionSet];
		let connector =
			condition.connector === andCondition ? orCondition : andCondition;
		let updatedConditionList = clonedCondition.map((condition) => {
			return { ...condition, connector: connector };
		});
		updateCondition(updatedConditionList);
	};

	const formValues = useMemo(() => {
		if (!Array.isArray(conditionSet)) return {};

		const formFieldValue = {};

		conditionSet.forEach((field, index) => {
			if (field?.values && Array.isArray(field?.values)) {
				const { fieldType: type, values } = field;

				formFieldValue[index] = multipleValuesFieldTypes.includes(type)
					? values
					: values[0];
			}
		});

		return formFieldValue;
	}, [conditionSet]);

	const handleUpdateCondition = (updatedCondition, criteriaIndex) => {
		let clonedConditionSet = [...conditionSet];
		clonedConditionSet[criteriaIndex] = {
			...clonedConditionSet[criteriaIndex],
			...updatedCondition,
		};

		updateCondition(clonedConditionSet);
	};

	return (
		<React.Fragment>
			<Box>
				<Stack spacing={1.5}>
					{conditionSet?.map((condition, index) => (
						<Stack
							key={"condition-set-" + index}
							direction="row"
							alignItems="center"
							spacing={1.5}
						>
							{index > 0 && (
								<Typography
									fontSize={14}
									fontWeight={500}
									color={
										readOnly
											? "#000"
											: theme.palette.secondary
													.contrastText
									}
									onClick={() =>
										handleChangeConditionOperator(condition)
									}
									style={{
										cursor: "pointer",
									}}
								>
									{condition?.connector === andCondition
										? "AND"
										: "OR"}
								</Typography>
							)}

							<Box minWidth="500px" height="100%">
								<SingleCondition
									key={"condition-" + index}
									isDeleteEnabled={conditionSet?.length > 1}
									handleUpdateCondition={(data) =>
										handleUpdateCondition(data, index)
									}
									formValues={formValues}
									value={formValues[index]}
									readOnly={readOnly}
									condition={condition}
									deleteCondition={() =>
										deleteCondition(index)
									}
									comparatorsList={
										criteriaFieldsData?.comparator
									}
									fieldsList={
										criteriaFieldsData?.criteriaFields
									}
								/>
							</Box>
						</Stack>
					))}
				</Stack>

				<Stack direction="row" spacing={1} pt={1.5}>
					{conditionSet?.length < maxConditionLimit && (
						<DwarfButton
							onClick={addCondition}
							sx={{ opacity: readOnly ? 0.6 : 1 }}
						>
							+ Add Condition
						</DwarfButton>
					)}

					{!hideNewConditionSetButton && (
						<DwarfButton
							onClick={addNewConditionSet}
							sx={{ opacity: readOnly ? 0.6 : 1 }}
						>
							+ Add New Set
						</DwarfButton>
					)}
				</Stack>
			</Box>
		</React.Fragment>
	);
}

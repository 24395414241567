import {
	followersIds,
	manageFollowers,
	permissionTypes,
	sourceIds,
	type,
} from "..";

export const getFollowersConfigForPermission = (permissions) => {
	return [
		{
			name: "Contact Follower Records",
			sourceId: sourceIds.contacts,
			id: followersIds.contactFollowersRecords,
			access: permissions?.contactFollowersRecords.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: permissionTypes.view,
						name: "View",
						value: permissions?.contactFollowersRecords.view,
						disabled: !permissions?.contactFollowersRecords.access,
					},
					{
						id: permissionTypes.edit,
						name: "Edit",
						value: permissions?.contactFollowersRecords.edit,
						disabled: !permissions?.contactFollowersRecords.access,
					},
					{
						id: permissionTypes.delete,
						name: "Delete",
						value: permissions?.contactFollowersRecords.delete,
						disabled: !permissions?.contactFollowersRecords.access,
					},
				],
			},
		},
		{
			name: "Company Follower Records",
			id: followersIds.companyFollowersRecords,
			sourceId: sourceIds.companies,
			access: permissions?.companyFollowersRecords.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: permissionTypes.view,
						name: "View",
						value: permissions?.companyFollowersRecords.view,
						disabled: !permissions?.companyFollowersRecords.access,
					},
					{
						id: permissionTypes.edit,
						name: "Edit",
						value: permissions?.companyFollowersRecords.edit,
						disabled: !permissions?.companyFollowersRecords.access,
					},
					{
						id: permissionTypes.delete,
						name: "Delete",
						value: permissions?.companyFollowersRecords.delete,
						disabled: !permissions?.companyFollowersRecords.access,
					},
				],
			},
		},
		{
			name: "Deal Follower Records",
			id: followersIds.dealFollowersRecords,
			sourceId: sourceIds.deals,
			access: permissions?.dealFollowersRecords.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: permissionTypes.view,
						name: "View",
						value: permissions?.dealFollowersRecords.view,
						disabled: !permissions?.dealFollowersRecords.access,
					},
					{
						id: permissionTypes.edit,
						name: "Edit",
						value: permissions?.dealFollowersRecords.edit,
						disabled: !permissions?.dealFollowersRecords.access,
					},
					{
						id: permissionTypes.delete,
						name: "Delete",
						value: permissions?.dealFollowersRecords.delete,
						disabled: !permissions?.dealFollowersRecords.access,
					},
				],
			},
		},
		{
			name: "Manage Followers",
			id: followersIds.manageFollowers,
			access: permissions?.manageFollowers.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						name: "Contact Followers",
						id: manageFollowers.contactFollowers,
						value: permissions?.manageFollowers.contactFollowers,
						disabled: !permissions?.manageFollowers.access,
					},
					{
						name: "Company Followers",
						id: manageFollowers.companyFollowers,
						value: permissions?.manageFollowers.companyFollowers,
						disabled: !permissions?.manageFollowers.access,
					},
					{
						name: "Deal Followers",
						id: manageFollowers.dealFollowers,
						value: permissions?.manageFollowers.dealFollowers,
						disabled: !permissions?.manageFollowers.access,
					},
				],
			},
		},
	];
};

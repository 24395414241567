import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	MenuItem,
	Select,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import UserManagementDialog from "../../../../Elements/UserManagementDialog";
import { useDeactivateUserMutation } from "../../../../../hooks/services/userManagement/user";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { default as UserProfileImage } from "../../../../../assets/images/contact/unknownContact.png";
import { useUsers } from "../../../../../hooks/account/user";
import MenuSearchBar from "../../../../Elements/MenuSearchBar";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";
import { MenuItemText } from "../../../../../styles/twozo";

export default function DeactivateDialog(props) {
	const { open, onClose, userData, ...others } = props;

	const [userList, setUserList] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [searchedValue, setSearchedValue] = useState("");

	useEffect(() => {
		if (userData && userData?.name) {
			setSelectedUser({
				name: userData.name,
				value: userData?.id,
			});
		}
	}, [userData]);

	const {
		activeUserList,
		isLoadingUsers,
		isFetchingUsers,
		hasNextUsersPage,
		fetchNextUsersPage,
	} = useUsers(searchedValue);

	const lastOptionRef = useLastRowRef(
		fetchNextUsersPage,
		hasNextUsersPage,
		isFetchingUsers
	);

	const deactivateUserMutation = useDeactivateUserMutation();

	useEffect(() => {
		if (activeUserList?.length > 0 && userData?.id) {
			const updatedUserList = activeUserList?.map((user) => {
				if (user.value === userData?.id) {
					return {
						...user,
						name: "Same user (Leave as is)",
					};
				} else return user;
			});

			setUserList(updatedUserList);
		} else {
			setUserList([]);
		}
	}, [activeUserList, userData]);

	const onChange = (event, child) => {
		const { value } = event.target;
		setSelectedUser({ name: child?.props?.name, value: value });
	};

	const handleSearchUser = (event) => {
		const { value } = event.target;
		setSearchedValue(value?.trimStart());
	};

	const deactivateUser = (userId, selectedUserId) => {
		const deactivateUserRequestData = {
			userId: userId,
		};

		if (userId !== selectedUserId) {
			deactivateUserRequestData.targetUserId = selectedUserId;
		}

		deactivateUserMutation.mutate(deactivateUserRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.userDeactivated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{activeUserList?.length === 0
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<UserManagementDialog open={open} onClose={onClose} {...others}>
				<Box px={3} pb={3}>
					<Typography fontSize={14} fontWeight={500} pb={0.5}>
						Assign to
					</Typography>

					<Box>
						<Select
							size="small"
							sx={{
								width: "100%",
							}}
							onChange={onChange}
							value={selectedUser?.value}
							renderValue={() => (
								<Typography fontSize={15}>
									{userData?.id === selectedUser?.value
										? "Same user (Leave as is)"
										: selectedUser?.name}
								</Typography>
							)}
							MenuProps={{
								autoFocus: false,
								PaperProps: {
									style: {
										marginTop: "4px",
										borderRadius: "8px",
										maxHeight: "255px",
									},
								},
							}}
							displayEmpty
							onOpen={() => setSearchedValue("")}
						>
							<Box
								p={0.5}
								marginTop="-8px"
								sx={{
									position: "sticky",
									top: 0,
									zIndex: 1,
									backgroundColor: "#fff",
								}}
							>
								<MenuSearchBar
									value={searchedValue}
									onChange={handleSearchUser}
								/>
							</Box>

							{isLoadingUsers ? (
								<Stack
									alignItems="center"
									justifyContent="center"
									height="150px"
								>
									<CircularProgress size="25px" />
								</Stack>
							) : userList?.length > 0 ? (
								userList?.map((user) => (
									<MenuItem
										value={user.value}
										key={user.value}
										name={user?.name}
										style={{
											minHeight: "40px",
										}}
									>
										<Stack
											direction="row"
											spacing={2}
											alignItems="center"
										>
											{userData?.id !== user.value && (
												<img
													alt="user"
													src={UserProfileImage}
													width="20px"
													height="20px"
												/>
											)}

											<MenuItemText>
												{user.name}
											</MenuItemText>
										</Stack>
									</MenuItem>
								))
							) : (
								renderEmptyView()
							)}

							<Box ref={lastOptionRef}>
								{!isLoadingUsers &&
									isFetchingUsers &&
									activeUserList?.length > 0 && (
										<MenuItem style={{ height: "40px" }}>
											<Stack
												direction="row"
												alignItems="center"
												justifyContent="center"
												spacing={1}
											>
												<Skeleton
													variant="circular"
													width="24px"
													height="24px"
												/>

												<Skeleton
													width="190px"
													height="12px"
												/>
											</Stack>
										</MenuItem>
									)}
							</Box>
						</Select>
					</Box>

					<Typography fontSize={14} fontWeight={400} pt={0.5}>
						{userData?.id === selectedUser?.value
							? "Assignment of records remain unchanged, if same user is selected."
							: `All the records will be re-assigned to ${selectedUser?.name}`}
					</Typography>

					<Box pt={2}>
						<Stack
							direction="row"
							spacing={2}
							justifyContent="flex-end"
						>
							<Button
								onClick={onClose}
								variant="contained"
								color="secondary"
								disableElevation
							>
								Cancel
							</Button>

							<Button
								variant="contained"
								color="error"
								disableElevation
								onClick={() =>
									deactivateUser(
										userData?.id,
										selectedUser?.value
									)
								}
							>
								Deactivate
							</Button>
						</Stack>
					</Box>
				</Box>
			</UserManagementDialog>
		</React.Fragment>
	);
}

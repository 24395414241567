import React, { useState } from "react";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { default as AddIcon } from "../../../assets/icons/add";
import AddFile from "./AddFile";
import ShortFileList from "./ShortFileList";

export default function ShortFiles(props) {
	const { sourceName, entityId, isAddButtonDiabled } = props;
	const theme = useTheme();

	const [isAddFileDialogOpened, setIsAddFileDialogOpened] = useState(false);

	const handleOpenAddFileDialog = () => {
		setIsAddFileDialogOpened(true);
	};

	const handleCloseAddFileDialog = () => {
		setIsAddFileDialogOpened(false);
	};

	return (
		<React.Fragment>
			<Box>
				<AddFile
					isAddFileDialogOpened={isAddFileDialogOpened}
					onCloseAddFileDialog={handleCloseAddFileDialog}
					sourceName={sourceName}
					entityId={entityId}
				/>

				<Box hidden={isAddButtonDiabled}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						p={1}
					>
						<Typography fontSize={15} fontWeight={600}>
							Files
						</Typography>

						<IconButton
							size="small"
							onClick={handleOpenAddFileDialog}
						>
							{AddIcon(20, 20, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<ShortFileList sourceName={sourceName} entityId={entityId} />
			</Box>
		</React.Fragment>
	);
}

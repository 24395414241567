import React, { useState } from "react";
import {
	Box,
	Button,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { hexToRgba, MenuItemText } from "../../../../../styles/twozo";
import {
	useContactLostReasonList,
	useUpdateContactLifecycleStatus,
} from "../../../../../hooks/services/contact";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";

export default function ClosedLost(props) {
	const {
		closeLostDialog,
		statusData,
		contactId,
		lifeCycleStageId,
		lifeCycleStatusId,
		onUpdateStatusSuccess,
	} = props;

	const theme = useTheme();

	// query call
	const { data: lostReasonsList } = useContactLostReasonList();

	// mutation call:-
	const updateMutation = useUpdateContactLifecycleStatus(contactId);

	const [selectedStatus, setSelectedStatus] = useState(
		statusData || { name: "", value: "" }
	);
	const [lostReasonId, setLostReasonId] = useState("");

	const handleChangeLostReason = (event) => {
		const { value } = event.target;
		setLostReasonId(value);
	};

	const handleChange = (event) => {
		setSelectedStatus(event.target.value);
	};

	const onSave = () => {
		let requestData = {
			id: contactId,
			lifeCycleStageId: lifeCycleStageId,
			lifeCycleStatusId: lifeCycleStatusId,
			contactLostReason: lostReasonId,
		};

		updateMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.contactLifecycleUpdated,
				});
				onUpdateStatusSuccess();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const isSaveButtonDisabled = () => {
		return !lostReasonId;
	};

	return (
		<React.Fragment>
			<Stack p={2} spacing={2}>
				<Stack spacing={0.5}>
					<Typography
						fontSize={13}
						fontWeight={500}
						style={{ opacity: 0.6 }}
					>
						Status
					</Typography>

					<Select
						size="small"
						value={selectedStatus?.value}
						onChange={handleChange}
						readOnly={true}
					>
						<MenuItem
							style={{ color: "rgba(0, 0, 0, 1)" }}
							value={selectedStatus?.value}
						>
							{selectedStatus?.name}
						</MenuItem>
					</Select>
				</Stack>

				<Stack spacing={0.5}>
					<Typography fontSize={13} fontWeight={500}>
						Reason
						<span
							style={{
								color: theme.palette.error.main,
								marginLeft: "5px",
							}}
						>
							*
						</span>
					</Typography>

					<Select
						size="small"
						onChange={handleChangeLostReason}
						value={lostReasonId}
					>
						{lostReasonsList?.map((option) => (
							<MenuItem value={option.value} key={option.value}>
								<Stack
									direction="row"
									spacing={1}
									alignItems="center"
								>
									<MenuItemText>{option.name}</MenuItemText>
								</Stack>
							</MenuItem>
						))}
					</Select>
				</Stack>

				<Box p={1}>
					<Stack
						direction="row"
						justifyContent="flex-end"
						spacing={2}
					>
						<Button
							onClick={closeLostDialog}
							variant="contained"
							color="secondary"
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							onClick={onSave}
							disableElevation
							disabled={isSaveButtonDisabled()}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: theme.palette.primary.main,
									color: hexToRgba("#fff", 0.6),
								},
							}}
						>
							Save
						</Button>
					</Stack>
				</Box>
			</Stack>
		</React.Fragment>
	);
}

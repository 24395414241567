import React from "react";
import AddForm from "../../Elements/AddForm";
import { removeFieldsWithEmptyValues } from "../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { modules } from "../../../utils/common/ModulesName";
import { useCreateDeal } from "../../../hooks/services/deal";
import { useDealFieldPermissions } from "../../../hooks/modules/auth/deal";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import { useModuleName } from "../../../hooks/modules";

export default function AddNewDeals(props) {
	const { onClose, formData } = props;
	const contactId = formData?.contactValue?.value;
	const companyId = formData?.companyValue?.value;

	const { getModuleName } = useModuleName();
	const moduleName = modules.DEAL;
	const formHeaderLabel = `Add ${getModuleName(moduleName)}`;

	const createMutation = useCreateDeal(contactId, companyId);
	const dealFieldPermissions = useDealFieldPermissions();

	const createNewDeal = (formSubmitData, isFormShouldClose) => {
		let formData = { ...formSubmitData };
		delete formData?.companyValue;
		delete formData?.contactValue;
		removeFieldsWithEmptyValues(formData);

		createMutation.mutate(formData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealAdded,
				});
				isFormShouldClose && onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<FieldPermissionContextProvider value={dealFieldPermissions}>
				<AddForm
					moduleName={moduleName}
					onSave={createNewDeal}
					onClose={onClose}
					isSaving={createMutation.isLoading}
					formData={formData}
					formHeaderLabel={formHeaderLabel}
				></AddForm>
			</FieldPermissionContextProvider>
		</React.Fragment>
	);
}

import {
	Divider,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";

export default function TimePeriod(params) {
	const {
		functions,
		field,
		value,
		onFieldValueChange,
		functionId,
		onFunctionChange,
	} = params;

	const theme = useTheme();

	const getInitFunction = () => {
		if (functionId) {
			let fnValue = functions[functionId];
			return fnValue;
		}
		return {};
	};

	const [fieldValue, setFieldValue] = useState(value ?? {});
	const [selectedValue, setSelectedValue] = useState(getInitFunction());

	const options = useMemo(() => {
		if (functions && Array.isArray(field?.functionIds)) {
			return field.functionIds
				.map((functionId) => functions[functionId])
				.filter(Boolean);
		}
		return [];
	}, [field?.functionIds, functions]);

	const textStyle = {
		backgroundColor: theme.palette.secondary.main,
		borderRadius: "0px",
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none",
			borderRadius: "0px",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				border: "none",
			},
		},
		height: "42px",
	};

	const dropdownStyle = {
		backgroundColor: theme.palette.secondary.main,
		borderRadius: "0px 8px 8px 0px",
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none",
			borderRadius: "0px 8px 8px 0px",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				border: "none",
			},
		},
		height: "42px",
	};

	const handleChangeFieldValue = (event) => {
		const { value } = event.target;
		setFieldValue(value);
		onFieldValueChange(value);
	};

	function getKeyByName(data, targetName) {
		const entry = Object.entries(data).find(
			([, value]) => value.name === targetName
		);
		return entry ? entry[0] : null;
	}

	const handleChangeFieldName = (event) => {
		const { value } = event.target;
		setSelectedValue(value);
		const key = getKeyByName(functions, value?.name);
		onFunctionChange(key);
	};

	return (
		<>
			<Stack direction="row">
				<Divider orientation="vertical" style={{ height: "42px" }} />

				<TextField
					type="number"
					sx={textStyle}
					value={fieldValue}
					onChange={handleChangeFieldValue}
					placeholder="Enter"
				/>

				<Divider orientation="vertical" style={{ height: "42px" }} />

				<Select
					value={selectedValue || ""}
					size="medium"
					fullWidth
					sx={dropdownStyle}
					onChange={handleChangeFieldName}
					renderValue={() => (
						<Typography
							noWrap
							fontSize={14}
							color={
								selectedValue?.name
									? "#000"
									: "rgba(0, 0, 0, 0.5)"
							}
						>
							{selectedValue?.name
								? selectedValue.name
								: "Select"}
						</Typography>
					)}
				>
					{options?.map((option) => (
						<MenuItem key={option.name} value={option}>
							<Typography fontSize={13}>
								{option?.name || ""}
							</Typography>
						</MenuItem>
					))}
				</Select>
			</Stack>
		</>
	);
}

import React from "react";
import {
	FormHelperText,
	ListSubheader,
	MenuItem,
	Select,
	Typography,
	useTheme,
} from "@mui/material";
import { Controller } from "react-hook-form";

export default function DropdownBaseComponent(props) {
	const {
		options = [],
		grouping,
		name,
		control,
		rules,
		fieldKey,
		handleChangeModule,
		...other
	} = props;
	const theme = useTheme();

	const getMenuItems = (options, style = {}) => {
		return options.map((option) => (
			<MenuItem
				key={option.id}
				value={option.id}
				style={{ minHeight: "40px", ...style }}
			>
				<Typography fontSize={13}>{option.label}</Typography>
			</MenuItem>
		));
	};

	if (!control) {
		return <Typography fontSize={14}>control is required</Typography>;
	}

	const getPlaceholder = (selected) => {
		let selectedOption = null;
		if (grouping) {
			for (let index = 0; index < options.length; index++) {
				selectedOption = options[index].options?.find(
					(option) => option.id === selected
				);
				if (selectedOption) {
					break;
				}
			}
		} else {
			selectedOption = options.find((option) => option.id === selected);
		}
		return (
			selectedOption?.label || (
				<Typography
					fontSize={14}
					color={theme.palette.secondary.contrastText}
				>
					Select
				</Typography>
			)
		);
	};

	return (
		<React.Fragment>
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({
					field: { onChange, ...fieldState },
					fieldState: { error },
				}) => (
					<>
						<Select
							key={fieldKey}
							defaultValue={""}
							MenuProps={{
								style: {
									maxHeight: "380px",
								},
							}}
							{...other}
							{...fieldState}
							displayEmpty
							renderValue={getPlaceholder}
							value={fieldState.value}
							error={!!error}
							onChange={(e) => {
								onChange(e);
								if (handleChangeModule) {
									handleChangeModule(e.target.value);
								}
							}}
						>
							{grouping
								? options.reduce((option, module) => {
										option.push(
											<CustomListSubheader
												key={module.id}
											>
												{module.label}
											</CustomListSubheader>
										);
										option.push(
											...getMenuItems(module.options, {
												paddingLeft: "32px",
											})
										);
										return option;
									}, [])
								: getMenuItems(options)}
						</Select>

						{!!error && (
							<FormHelperText
								style={{ paddingLeft: "16px" }}
								error
							>
								{error.message}
							</FormHelperText>
						)}
					</>
				)}
			/>
		</React.Fragment>
	);
}

const CustomListSubheader = (props) => {
	const { style, ...other } = props;
	return (
		<ListSubheader
			style={{
				lineHeight: "normal",
				paddingTop: "4px",
				paddingBottom: "4px",
				color: "#000",
				fontSize: 13,
				fontWeight: 500,
				...style,
			}}
			{...other}
		/>
	);
};

CustomListSubheader.muiSkipListHighlight = true;

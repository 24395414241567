import { assertError } from "../apiHelper";
import { getFileStorageUrl, getFileUrl } from "../apiConfig";
import { apiClient } from "..";
import { fileUploadCategory } from "../../utils/fileUtills";

export const getFiles = async (sourceName, entityId, pageParam) => {
	let requestData = getFilesRequest(sourceName, entityId, pageParam);
	return await postFileManagementApi(requestData);
};

export const uploadFiles = async ({ sourceName, entityId, files }) => {
	let requestData = getUploadFilesRequest(sourceName, entityId, files);
	return await postFileUploadManagementApi(requestData);
};

export const deleteFile = async ({ id }) => {
	let requestData = getDeleteRequest(id);
	return await postFileManagementApi(requestData);
};

const getFilesRequest = (sourceName, entityId, pageParam) => {
	return {
		type: "getFiles",
		data: {
			source: sourceName,
			entityId: entityId,
			...pageParam,
		},
	};
};

const getUploadFilesRequest = (sourceName = "", entityId, files) => {
	return {
		entityId: entityId,
		source: sourceName,
		data: {
			attachments: files,
		},
		category: fileUploadCategory[sourceName.toLowerCase()],
	};
};

const getDeleteRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const postFileManagementApi = async (requestData) => {
	let fileManagementUrl = getFileManagementUrl();
	let response = await apiClient.post(fileManagementUrl, requestData);
	assertError(response, fileManagementUrl);
	return response.data.data;
};

export const postFileUploadManagementApi = async (requestData) => {
	let fileUploadUrl = getFileUploadUrl();
	let response = await apiClient.post(fileUploadUrl, requestData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	assertError(response);
	return response.data.data;
};

const getFileManagementUrl = () => {
	return getFileUrl() + "/mgmt";
};

const getFileUploadUrl = () => {
	return getFileStorageUrl() + "/upload";
};

import React, { useCallback, useMemo, useState } from "react";
import SelectedMenu from "./SelectedMenu";
import ProductsMenu from "./Menu";
import Table from "../Elements/Table";
import { sourceType } from "../../utils/queryKeys";
import { useNavigate } from "react-router-dom";
import { modules } from "../../utils/common/ModulesName";
import ImportModuleDialog from "../Elements/ImportModuleDialog";
import { FilterSaveViewProvider } from "../Elements/Table/Filter/Context/FilterSaveViewContext";
import { FilterDataProvider } from "../Elements/Table/Filter/Context/FilterDataContext";
import { default as EditIcon } from "../../assets/icons/edit";
import { default as DeleteIcon } from "../../assets/icons/delete";
import EditProduct from "./EditProduct";
import CustomSwipeableDrawer from "../Elements/CustomSwipeableDrawer";
import { Box } from "@mui/material";
import { twozoStyles } from "../../styles/twozo";
import { useDeleteProductMutation } from "../../hooks/services/product";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../utils/notification/notificationConfig";
import { notificationMessage } from "../../utils/notification/notificationMessages";
import DeleteDialog from "../Elements/DeleteDialog";
import { useAuth } from "../../hooks/auth";
import { PERMISSIONS } from "../../utils/Auth";
import { useModuleName } from "../../hooks/modules";
import { tableActions } from "../../utils/tableUtills";

export default function Products() {
	const navigate = useNavigate();
	const classes = twozoStyles();

	const [isProductImportDialogOpened, setIsProductImportDialogOpened] =
		useState(false);
	const [selectedRowProductId, setSelectedRowProductId] = useState(null);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [isEditFormDialogOpen, setIsEditFormDialogOpen] = useState(false);

	const { getModuleName } = useModuleName();

	const { isUserAllowedFor } = useAuth();
	const hasShareViewPermissions = isUserAllowedFor(
		PERMISSIONS.product.shareView
	);

	// delete mutation
	const deleteMutation = useDeleteProductMutation();

	const noResultsMessage = {
		title: "No Products Found",
		message: "Try resetting your filters or create new product",
	};

	const handleCloseImportDialog = () => {
		setIsProductImportDialogOpened(false);
	};

	const onImportClicked = () => {
		setIsProductImportDialogOpened(true);
	};

	const openProductSummary = useCallback(
		(row) => {
			navigate(row.id.toString());
		},
		[navigate]
	);

	const handleTableRowAction = useCallback(
		(action, actionData) => {
			switch (action) {
				case tableActions.rowClickAction:
					openProductSummary(actionData);
					break;
				default:
					break;
			}
		},
		[openProductSummary]
	);

	const toggleEditFormDialog = () => {
		setIsEditFormDialogOpen((openEditFormDialog) => !openEditFormDialog);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const handleDeleteProduct = () => {
		deleteMutation.mutate(selectedRowProductId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.productDeleted,
				});
				closeDeleteDialog();
			},
		});
	};

	const filteredTableMenuOptions = useMemo(() => {
		const onEditClicked = (row) => {
			setSelectedRowProductId(row?.id);
			toggleEditFormDialog();
		};

		const onDeleteClicked = (row) => {
			setSelectedRowProductId(row?.id);
			setIsDeleteDialogOpened(true);
		};

		const tableMenuOptions = [
			{
				id: 1,
				name: "Edit",
				icon: EditIcon,
				action: onEditClicked,
				hasPermissionToAccess: isUserAllowedFor(
					PERMISSIONS.product.edit
				),
			},
			{
				id: 2,
				name: "Delete",
				icon: DeleteIcon,
				action: onDeleteClicked,
				hasPermissionToAccess: isUserAllowedFor(
					PERMISSIONS.product.delete
				),
			},
		];

		return tableMenuOptions.filter(
			(tableMenuOption) => tableMenuOption.hasPermissionToAccess
		);
	}, [isUserAllowedFor]);

	const isTableActionsVisible = filteredTableMenuOptions.length > 0;

	let moduleName = getModuleName(modules.PRODUCT).toLowerCase();

	return (
		<React.Fragment>
			<DeleteDialog
				title={`Are you sure you want to delete the ${moduleName}?`}
				subtitle={`It will delete the ${moduleName} and the data associated with it. You can retrieve it from the Recycle Bin. It remains there for 90 days.`}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteProduct}
				disableDeleteButton={deleteMutation.isLoading}
			></DeleteDialog>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditFormDialogOpen}
				onOpen={toggleEditFormDialog}
				onClose={toggleEditFormDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditProduct
						productId={selectedRowProductId}
						onClose={toggleEditFormDialog}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<ImportModuleDialog
				open={isProductImportDialogOpened}
				onClose={handleCloseImportDialog}
				moduleName={modules.PRODUCT}
				disableBackdropClick={true}
			/>
			<FilterDataProvider>
				<FilterSaveViewProvider>
					<Table
						table={sourceType.PRODUCT}
						moduleName={modules.PRODUCT}
						menu={
							<ProductsMenu onImportClicked={onImportClicked} />
						}
						selectedMenu={<SelectedMenu />}
						handleTableRowAction={handleTableRowAction}
						isActionsVisible={isTableActionsVisible}
						noResultsMessage={noResultsMessage}
						tableMenuOptions={filteredTableMenuOptions}
						hasShareViewPermissions={hasShareViewPermissions}
					/>
				</FilterSaveViewProvider>
			</FilterDataProvider>
		</React.Fragment>
	);
}

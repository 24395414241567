import {
	Box,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import {
	useEffect,
	useState,
	forwardRef,
	useCallback,
	useImperativeHandle,
	useMemo,
} from "react";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { useCurrencies } from "../../../../hooks/settings/currency";
import MenuSearchBar from "../../MenuSearchBar";
import { MenuItemText } from "../../../../styles/twozo/twozo";

const CustomSelect = styled(Select)(() => ({
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
		borderRadius: "0px 8px 8px 0px",
		marginLeft: "-1px",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
}));

const MonetaryFieldEditForm = forwardRef(
	({ value, field, subFields, onError, onSave, onClose }, ref) => {
		const theme = useTheme();

		const {
			handleSubmit,
			formState: { errors },
			register,
			setValue,
			clearErrors,
		} = useForm({ values: value });

		const [searchValue, setSearchValue] = useState("");

		const { activeCurrencyList, getCurrencyDataById, baseCurrency } =
			useCurrencies(searchValue);

		const handleSearchValue = (event) => {
			const { value } = event.target;
			setSearchValue(value);
		};

		const getSubFieldObject = (subFieldName) => {
			let subFieldObj;
			if (!!subFields && Array.isArray(subFields)) {
				subFields.forEach((field) => {
					if (field.name === subFieldName) {
						subFieldObj = field;
					}
				});
			}

			return subFieldObj;
		};

		const currencyField = getSubFieldObject("CURRENCY");
		const monetaryValueField = getSubFieldObject("VALUE");

		const [selectedCurrency, setSelectedCurrency] = useState("");

		const handleCurrencyChange = (event) => {
			const { value } = event.target;
			setSelectedCurrency(value);
			setValue(currencyField?.id + "", value);
		};

		useEffect(() => {
			if (value?.[currencyField?.id]) {
				setSelectedCurrency(value[currencyField.id]);
			} else {
				setSelectedCurrency(baseCurrency?.id);
				setValue(currencyField?.id + "", baseCurrency?.id);
			}
		}, [value, currencyField, baseCurrency, setValue]);

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[monetaryValueField?.id]) {
						onError(errors[monetaryValueField?.id]);
					}
				}
			)();
		}, [handleSubmit, monetaryValueField, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		const renderEmptyView = () => {
			return (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="40px"
				>
					<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
						No Results Found
					</Typography>
				</Stack>
			);
		};

		const selectedCurrencyCode = useMemo(() => {
			const selectedCurrencyData = getCurrencyDataById(selectedCurrency);
			return selectedCurrencyData?.code ?? "";
		}, [selectedCurrency, getCurrencyDataById]);

		return (
			<>
				<Stack
					direction="row"
					width="100%"
					justifyContent="space-between"
					overflow="hidden"
					height="100%"
				>
					<Stack flexDirection="column" width="65%">
						<TextField
							name="value"
							placeholder={
								monetaryValueField?.placeHolder || "9876543210"
							}
							{...register(
								monetaryValueField?.id + "",
								handleFieldValidation(field)
							)}
							onChange={(event) => {
								setValue(
									monetaryValueField?.id + "",
									event.target.value.trimStart()
								);
								clearErrors(monetaryValueField?.id + "");
								onError(null);
							}}
							sx={{
								width: "100%",
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										border: "none",
									},
									"&:hover fieldset": {
										border: "none",
									},
									"&.Mui-focused fieldset": {
										border: "none",
									},
								},
							}}
						/>

						{errors[monetaryValueField?.id] ? (
							<Typography
								pl={1}
								pb={1.2}
								style={{
									fontSize: 13,
									width: "95%",
									marginLeft: "6px",
									color: theme.palette.error.main,
								}}
							>
								{errors[monetaryValueField?.id]?.message}
							</Typography>
						) : null}
					</Stack>

					<Box
						width="35%"
						borderLeft={`1px solid ${
							errors[monetaryValueField?.id]
								? theme.palette.error.main
								: theme.palette.primary.main
						}`}
						height="100%"
					>
						<CustomSelect
							{...register(currencyField?.id + "")}
							value={selectedCurrency}
							name="CURRENCY"
							sx={{
								width: "100%",
							}}
							displayEmpty
							MenuProps={{
								autoFocus: false,
								PaperProps: {
									style: {
										maxHeight: 300,
									},
								},
							}}
							renderValue={() => selectedCurrencyCode}
							onChange={handleCurrencyChange}
							onClose={() => setSearchValue("")}
						>
							<Box
								p={0.5}
								marginTop="-8px"
								sx={{
									position: "sticky",
									top: 0,
									zIndex: 1,
									backgroundColor: "#fff",
								}}
							>
								<MenuSearchBar
									value={searchValue}
									onChange={handleSearchValue}
								/>
							</Box>
							{activeCurrencyList?.length > 0
								? activeCurrencyList.map((currency) => (
										<MenuItem
											key={currency.id}
											value={currency.id}
											style={{
												height: "40px",
											}}
										>
											<Stack
												minWidth="300px"
												direction="row"
												justifyContent="space-between"
											>
												<MenuItemText>
													{currency.name}
												</MenuItemText>
												<MenuItemText>
													{currency.code}
												</MenuItemText>
											</Stack>
										</MenuItem>
									))
								: renderEmptyView()}
						</CustomSelect>
					</Box>
				</Stack>
			</>
		);
	}
);

MonetaryFieldEditForm.displayName = "MonetaryFieldEditForm";

export default MonetaryFieldEditForm;

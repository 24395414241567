import DropdownParser from "./fieldTypeParser/dropdownParser";
import TextPickersParser from "./fieldTypeParser/textPickersParser";
import { fieldType } from "../../../../utils/fieldType";

export const getFieldValue = (field) => {
	switch (field.fieldType) {
		case fieldType.DROPDOWN:
		case fieldType.DUAL_PICKER:
		case fieldType.DUAL_PICKERS:
		case fieldType.MULTI_DROPDOWN:
		case fieldType.PRODUCT:
		case fieldType.DEAL:
		case fieldType.CONTACT_ONBOARD:
		case fieldType.CONTACTS_ONBOARD:
		case fieldType.COMPANY_ONBOARD:
		case fieldType.TERRITORY:
		case fieldType.CONTACT:
		case fieldType.COMPANY:
		case fieldType.OWNER:
		case fieldType.OWNERS:
		case fieldType.MULTI_SELECT:
		case fieldType.TAGS:
		case fieldType.STEP_PICKER:
			return DropdownParser(field);
		case fieldType.TEXT_PICKERS:
		case fieldType.NUMBER_PICKERS:
		case fieldType.PHONES:
		case fieldType.EMAILS:
			return TextPickersParser(field);

		// Default case
		default:
			if (Array.isArray(field?.values)) {
				return field.values.map((value) => {
					const result = {
						value: value.value,
					};
					if (value?.id) {
						result.id = value.id;
					}
					if (value?.dependsOn) {
						result.dependsOn = value.dependsOn;
					}

					return result;
				});
			}
	}
};

import React from "react";
import { Pie } from "react-chartjs-2";
import { Box } from "@mui/material";

export const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: "right",
			display: true,
			labels: {
				usePointStyle: true,
				boxWidth: 60,
			},
		},
		title: {
			display: false,
		},
	},
};

export default function PieChart({ chartData }) {
	const data = {
		labels: chartData["xAxis"]["data"],
		datasets: chartData.yAxis.map((data) => ({
			label: data["label"],
			data: data["data"],
		})),
	};

	return (
		<React.Fragment>
			<Box height="80%">
				<Pie options={options} data={data} />
			</Box>
		</React.Fragment>
	);
}

import { Button, Stack, TextField, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	useCreateNote,
	useUpdateNote,
} from "../../../../hooks/services/summary/notes/notes";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { enqueueSnackbar } from "notistack";

export default function AddNote(props) {
	const { sourceName, entityId, isEditMode, initialNoteData, onCancelNote } =
		props;
	const theme = useTheme();
	const [noteInputValue, setNoteInputValue] = useState("");
	const [isNoteInputFocused, setIsNoteInputFocused] = useState(false);
	const [isNoteInputError, setIsNoteInputError] = useState(false);

	const displaySaveCancelButton = isNoteInputFocused || noteInputValue;

	const createNoteMutation = useCreateNote(sourceName, entityId);
	const updateNoteMutation = useUpdateNote(sourceName, entityId);

	const noteCharacterLimit = 10000;
	const isSaveButtonDisabled =
		Boolean(!noteInputValue) ||
		createNoteMutation.isLoading ||
		updateNoteMutation.isLoading ||
		initialNoteData?.note === noteInputValue;

	useEffect(() => {
		if (initialNoteData?.note) {
			setNoteInputValue(initialNoteData?.note);
			setIsNoteInputFocused(true);
		}
	}, [initialNoteData]);

	const handleNoteInputChange = (event) => {
		const { value } = event.target;
		setNoteInputValue(value?.trimStart());
		setIsNoteInputError(false);
	};

	const onFocusNoteInput = () => {
		setIsNoteInputFocused(true);
	};

	const onBlurNoteInput = () => {
		setIsNoteInputFocused(false);
	};

	const handleCancelNote = () => {
		setNoteInputValue("");
		setIsNoteInputFocused(false);
		setIsNoteInputError(false);
		onCancelNote();
	};

	const handleSaveNote = () => {
		if (noteInputValue?.length > noteCharacterLimit) {
			setIsNoteInputError(true);
		} else if (isEditMode) {
			let updateNoteRequest = {
				note: noteInputValue,
				source: sourceName,
				entityId: entityId,
				id: initialNoteData?.id,
			};
			updateNoteMutation.mutate(updateNoteRequest, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.notesUpdated,
					});
					handleCancelNote();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			});
		} else {
			let createNoteRequest = {
				note: noteInputValue,
				source: sourceName,
				entityId: entityId,
			};
			createNoteMutation.mutate(createNoteRequest, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.notesAdded,
					});
					handleCancelNote();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.somethingWentWrong,
					});
				},
			});
		}
	};

	return (
		<React.Fragment>
			<Stack
				spacing={isNoteInputError ? -1.1 : 2}
				p={1}
				pt={1}
				pb={2}
				style={{
					borderBottom: displaySaveCancelButton
						? "1px solid rgba(0, 0, 0, 0.1)"
						: "none",
				}}
			>
				<TextField
					fullWidth
					rows={displaySaveCancelButton ? 3 : 2}
					multiline
					value={noteInputValue}
					onChange={handleNoteInputChange}
					focused={isNoteInputFocused}
					onFocus={onFocusNoteInput}
					onBlur={onBlurNoteInput}
					placeholder="Add Notes Here..."
					error={isNoteInputError}
					helperText={
						isNoteInputError
							? "Max. of 10000 characters are allowed"
							: ""
					}
					FormHelperTextProps={{
						sx: {
							fontSize: 14,
							ml: "6px",
						},
					}}
					inputProps={{
						style: {
							fontSize: "15px",
						},
					}}
					sx={{
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								border: "1px solid rgba(51, 188, 126, 0.4)",
							},
							"&:hover fieldset": {
								border: "1px solid rgba(51, 188, 126, 0.4)",
							},
							"&.Mui-focused fieldset": {
								border: "1px solid #33BC7E",
							},
						},
					}}
				/>

				{displaySaveCancelButton ? (
					<Stack direction="row" spacing={2} justifyContent="end">
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={handleCancelNote}
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							disableElevation
							onClick={handleSaveNote}
							disabled={isSaveButtonDisabled}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: theme.palette.primary.main,
									color: "rgba(255, 255, 255, 0.6)",
								},
							}}
						>
							{isEditMode ? "Update" : "Save"}
						</Button>
					</Stack>
				) : null}
			</Stack>
		</React.Fragment>
	);
}

import { MenuItem, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAdditionalOptions } from "../../../../../../../../hooks/services/common/table/filter";
import { default as DropDownIcon } from "../../../../../../../../assets/icons/dropDown";
import { useFilterDataContext } from "../../../../Context/FilterDataContext";
import Menu from "../../../../../../Menu";
import { MenuItemText } from "../../../../../../../../styles/twozo";

export default function SubField(props) {
	const { filterCondition, onChange } = props;

	const theme = useTheme();

	const styles = {
		data: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			height: "38px",
			padding: "0px 10px",
			backgroundColor: "rgba(51, 188, 126, 0.12)",
		},
	};

	const { enableApplyButton } = useFilterDataContext();
	const selectedField = filterCondition?.fieldData || {};

	const [additionalOptionsMenuElement, setAdditionalOptionsMenuElement] =
		useState(null);
	const isAdditionalOptionsMenuElement = Boolean(
		additionalOptionsMenuElement
	);

	const getFieldTypeId = () => {
		if (
			!!selectedField?.additionalOptionsDropdown &&
			selectedField.additionalOptionsDropdown[0]?.requiredFields &&
			selectedField.additionalOptionsDropdown[0]?.requiredFields[0] ===
				"fieldTypeId"
		) {
			return selectedField.fieldType?.id;
		}
	};

	const fieldTypeId = getFieldTypeId();

	const { data: additionaloptions, status: isAdditionalOptionStatus } =
		useAdditionalOptions(
			fieldTypeId,
			selectedField?.additionalOptionsDropdown
		);

	useEffect(() => {
		if (
			isAdditionalOptionStatus === "success" &&
			additionaloptions.length >= 1 &&
			!filterCondition.additionalOption.displayName
		) {
			const defaultOption = additionaloptions[0];

			const updatedFilterCondition = {
				...filterCondition,
				additionalOption: {
					...filterCondition.additionalOption,
					displayName: defaultOption?.displayName,
					value: {
						name: defaultOption?.name,
						entityId: defaultOption?.id,
					},
				},
				additionalOptions: defaultOption,
			};
			onChange(updatedFilterCondition);
		}
	}, [
		additionaloptions,
		isAdditionalOptionStatus,
		filterCondition,
		onChange,
	]);

	const openAdditionalOptionsMenu = (event) => {
		setAdditionalOptionsMenuElement(event.currentTarget);
	};

	const closeAdditionalOptionsMenu = () => {
		setAdditionalOptionsMenuElement(null);
	};

	const handleSelectOption = (selectedValue) => {
		const updatedFilterCondition = {
			...filterCondition,
			valueEntityId: { ...filterCondition.valueEntityId, value: "" },
			value: { ...filterCondition.value, value: "" },
			additionalOption: {
				...filterCondition.additionalOption,
				displayName: selectedValue?.displayName,
				value: {
					name: selectedValue?.name,
					entityId: selectedValue?.id,
				},
			},
			additionalOptions: selectedValue,
		};
		onChange(updatedFilterCondition);
		enableApplyButton();
		setAdditionalOptionsMenuElement(null);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="202px"
				anchorEl={additionalOptionsMenuElement}
				open={isAdditionalOptionsMenuElement}
				onClose={closeAdditionalOptionsMenu}
				style={{ transform: "translateX(8px) translateY(-1px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				{additionaloptions?.map((field, fieldIndex) => (
					<MenuItem
						style={{ height: "40px" }}
						key={fieldIndex}
						onClick={() => handleSelectOption(field)}
					>
						<MenuItemText>{field.displayName}</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			<Stack
				direction="row"
				style={{
					...styles.data,
					cursor: "pointer",
					borderBottom: additionalOptionsMenuElement
						? `1px solid ${theme.palette.primary.main}`
						: "1px solid rgba(0,0,0,0.1)",
				}}
				onClick={(event) => openAdditionalOptionsMenu(event)}
			>
				{filterCondition?.additionalOption.displayName ? (
					<Typography fontSize={14}>
						{filterCondition.additionalOption.displayName}
					</Typography>
				) : (
					<Typography
						sx={{
							fontSize: "14px",
							color: "rgba(0,0,0,0.4)",
						}}
					>
						Select
					</Typography>
				)}
				{DropDownIcon(12, 12)}
			</Stack>
		</React.Fragment>
	);
}

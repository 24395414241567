import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import AddNewContact from "../../../../../../Deals/AddNewDeals/AddNewContact";
import { removeFieldsWithEmptyValues } from "../../../../../../../utils/common";
import ContactAutoComplete from "../../../../../ContactAutoComplete";

export function ContactsOnBoardBaseComponent(props) {
	const {
		field,
		formValues,
		value,
		onChange,
		error,
		clearErrors,
		isIconNeeded = true,
		isAddNewOption = true,
	} = props;

	const [selectedContacts, setSelectedContacts] = useState([]);
	const [selectedNewContact, setSelectedNewContact] = useState({});
	const [isAddNewContact, setIsAddNewContact] = useState(false);

	const relatedFieldId = field?.relatedField?.id;
	const primaryContactId = formValues?.[field?.config?.associatedWith]
		? [formValues?.[field?.config?.associatedWith]]
		: [];

	const contactFormData = {
		[relatedFieldId]: selectedNewContact?.name,
	};

	// This useEffect will run only initial render
	useEffect(() => {
		// This useEffect is used to handle the edit case only:
		// - It displays the label in the edit mode by setting the selected contact label.
		// - It also processes server-side data in the edit case by formatting and setting `value`.
		// If `value` is an object with a `name` property, we assume it's valid data from the server or edit case,
		// so `setSelectedContacts` is called to set the label accordingly.

		const isValidServerSideData = () => {
			return (
				Array.isArray(value) && value.some((contact) => contact?.name)
			);
		};

		if (isValidServerSideData()) {
			setSelectedContacts([...value]);
			onChange(value.map((contact) => contact?.value));
		}
	}, [value, onChange]);

	const onContactInputChange = (values) => {
		const selectedContacts = [...values];
		const lastContact = selectedContacts[selectedContacts?.length - 1];
		const isNewContact = lastContact?.isNewOption;

		if (isNewContact) {
			setSelectedNewContact(lastContact);
			setIsAddNewContact(true);
		}
		setSelectedContacts(
			selectedContacts.filter((contact) => !contact.isNewOption)
		);

		let contactIdsForRequest = selectedContacts
			.filter((contact) => contact?.value)
			.map((contact) => contact.value);
		onChange([...new Set([...value, ...contactIdsForRequest])]);
	};

	const onAddNewContact = (formSubmitData) => {
		removeFieldsWithEmptyValues(formSubmitData);

		const contactValue = {
			name: formSubmitData[relatedFieldId],
		};
		setSelectedContacts([...selectedContacts, contactValue]);
		setSelectedNewContact({});
		setIsAddNewContact(false);
		onChange([...value, formSubmitData]);
	};

	const onCloseNewContactForm = () => {
		setSelectedNewContact({});
		setIsAddNewContact(false);
	};

	const handleRemoveContact = (removedIndex) => {
		setSelectedContacts((selectedContacts) =>
			selectedContacts.filter((_, index) => index !== removedIndex)
		);
		let contactIdsForRequest = [...value].filter(
			(_, index) => index !== removedIndex
		);
		onChange(contactIdsForRequest);
	};

	return (
		<React.Fragment>
			<Stack spacing={1} width="100%">
				{isAddNewContact ? (
					<AddNewContact
						onSubmit={onAddNewContact}
						onClose={onCloseNewContactForm}
						contactFormData={contactFormData}
					/>
				) : (
					<ContactAutoComplete
						multiple={true}
						value={selectedContacts || []}
						onChange={onContactInputChange}
						onDelete={handleRemoveContact}
						clearErrors={clearErrors}
						error={error}
						isIconNeeded={isIconNeeded}
						onCancel={handleRemoveContact}
						placeHolder={field?.placeHolder}
						isAllowNewOption={isAddNewOption}
						filterCriteria={{
							excludeContactIds: primaryContactId,
						}}
					/>
				)}
			</Stack>
		</React.Fragment>
	);
}

import React, { useState } from "react";
import Dialog from "../../../Elements/Dialog";
import { Box, Button, Typography, alpha, Stack, useTheme } from "@mui/material";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { useUploadFiles } from "../../../../hooks/services/summary/files";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { useUploadFilesFromEmail } from "../../../../hooks/services/mail";
import { useModuleName } from "../../../../hooks/modules";

export default function AddFile(props) {
	const {
		isAddFileDialogOpened,
		onCloseAddFileDialog,
		sourceName,
		entityId,
		onFilesSaved,
		hasUploadedFromComposer,
	} = props;
	const theme = useTheme();

	const [addedFiles, setAddedFiles] = useState([]);

	const uploadFilesMutation = useUploadFiles(sourceName, entityId);
	const uploadFilesFromComposerMutation = useUploadFilesFromEmail();
	const { getModuleName } = useModuleName();

	const isSaveButtonDisable =
		addedFiles?.length === 0 ||
		uploadFilesMutation.isLoading ||
		uploadFilesFromComposerMutation.isLoading;

	const styles = {
		uploadFileName: {
			backgroundColor: alpha(theme.palette.primary.main, 0.12),
			padding: "4px 8px",
			borderRadius: "8px",
			alignItems: "center",
			justifyContent: "center",
			cursor: "pointer",
		},
	};

	const handleAddFile = (event) => {
		const { files } = event.target;

		if (files) {
			setAddedFiles((addedFiles) => [...addedFiles, ...files]);
		}
	};

	const handleRemoveFile = (removedFile) => {
		setAddedFiles((addedFiles) =>
			addedFiles.filter((file) => file !== removedFile)
		);
	};

	const onSaveAddedFiles = () => {
		if (hasUploadedFromComposer) {
			let uploadFilesRequest = {
				files: addedFiles,
			};
			uploadFilesFromComposerMutation.mutate(uploadFilesRequest, {
				onSuccess: (response) => {
					handleCloseFileDialog();
					onFilesSaved(response?.uploadedRecords);
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			});
		} else {
			let uploadFilesRequest = {
				entityId: entityId,
				sourceName: sourceName,
				files: addedFiles,
			};

			uploadFilesMutation.mutate(uploadFilesRequest, {
				onSuccess: (response) => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.filesAdded,
					});
					handleCloseFileDialog();
					onFilesSaved
						? onFilesSaved(response?.createdRecords)
						: null;
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			});
		}
	};

	const handleCloseFileDialog = () => {
		setAddedFiles([]);
		onCloseAddFileDialog();
	};

	const getViewName = () => {
		let viewName = getModuleName(sourceName);
		if (hasUploadedFromComposer) {
			return "Conversation";
		}
		return viewName ?? "";
	};

	return (
		<React.Fragment>
			<Dialog open={isAddFileDialogOpened} title="Add Files">
				<Box p={2}>
					<Stack spacing={1}>
						<Stack
							sx={{
								width: "100%",
								height: "144px",
								border: "1px solid rgba(0,0,0,0.1)",
								borderRadius: "8px",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Button
								variant="text"
								disableElevation
								component="label"
							>
								<Typography
									sx={{
										color: alpha("#000000", 0.6),
										fontSize: "14px",
									}}
								>
									{`Drop your files here to add to this ${getViewName()}`}
								</Typography>
								<input
									hidden
									type="file"
									onChange={handleAddFile}
									multiple
								/>
							</Button>
						</Stack>

						{addedFiles?.length > 0 ? (
							<Stack
								direction="row"
								spacing={0.5}
								flexWrap="wrap"
							>
								{addedFiles?.map((file, index) => (
									<Stack
										key={index}
										direction="row"
										alignItems="center"
										pb={0.5}
									>
										<Stack
											direction="row"
											alignItems="center"
											justifyContent="center"
											style={{ ...styles.uploadFileName }}
											spacing={0.5}
										>
											<Typography
												fontSize={15}
												style={{
													wordBreak: "break-word",
												}}
											>
												{file.name}
											</Typography>

											<Box
												display="flex"
												onClick={() =>
													handleRemoveFile(file)
												}
											>
												{CloseIcon(16, 16, "#000000")}
											</Box>
										</Stack>
									</Stack>
								))}
							</Stack>
						) : null}

						<Box p={1}>
							<Stack
								direction="row"
								justifyContent="flex-end"
								spacing={2}
							>
								<Button
									onClick={handleCloseFileDialog}
									variant="contained"
									color="secondary"
									disableElevation
								>
									Cancel
								</Button>

								<Button
									variant="contained"
									onClick={onSaveAddedFiles}
									disableElevation
									disabled={isSaveButtonDisable}
									sx={{
										"&.Mui-disabled": {
											backgroundColor:
												theme.palette.primary.main,
											color: "rgba(255, 255, 255, 0.6)",
										},
									}}
								>
									Save
								</Button>
							</Stack>
						</Box>
					</Stack>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}

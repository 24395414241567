import React from "react";
import { TableCellText, Tooltip } from "../../../../styles/twozo";
import { Checkbox, Stack, useTheme } from "@mui/material";
import { default as RoundCheckBoxOffIcon } from "../../../../assets/icons/roundCheckBoxOff";
import { default as RoundCheckBoxOnIcon } from "../../../../assets/icons/roundCheckBoxOn";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { useUpdateActivityStatus } from "../../../../hooks/services/activities";
import { getStatusProperties } from "../../../../utils/ActivitiesUtils";
import { tableActions } from "../../../../utils/tableUtills";

const Status = ({ data, id, rowState, handleTableRowAction }) => {
	const theme = useTheme();

	const handleChangeStatus = (event, status, id) => {
		event.stopPropagation();
		updateActivityStatus(status, id);
	};

	const updateActivityStatus = (status, id) => {
		updateActivityStatusMutation.mutate(
			{
				id: id,
				isDone: getStatusProperties(status).isDone,
			},
			{
				onSuccess: (activityData) => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.activityUpdated,
					});
					if (activityData?.isActivityCreationRequired) {
						handleTableRowAction(
							tableActions.activityStatusCellClickAction,
							activityData
						);
					}
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	const updateActivityStatusMutation = useUpdateActivityStatus(id);

	return (
		<React.Fragment>
			<Stack direction="row" spacing={1} alignItems="center">
				<Tooltip
					placement="top"
					title={getStatusProperties(rowState).tooltip}
				>
					<Checkbox
						icon={RoundCheckBoxOffIcon(20, 20)}
						checkedIcon={RoundCheckBoxOnIcon(
							20,
							20,
							theme.palette.primary.main
						)}
						checked={getStatusProperties(rowState).checked}
						disabled={updateActivityStatusMutation.isLoading}
						onClick={(event) =>
							handleChangeStatus(event, rowState, id)
						}
					/>
				</Tooltip>

				<TableCellText
					color={getStatusProperties(rowState, theme).textColor}
				>
					{data}
				</TableCellText>
			</Stack>
		</React.Fragment>
	);
};

export default function ActivityStatus(props) {
	const { data, id, rowState, handleTableRowAction } = props;

	return (
		<Status
			data={data}
			id={id}
			rowState={rowState}
			handleTableRowAction={handleTableRowAction}
		/>
	);
}

import {
	Box,
	Button,
	CircularProgress,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { TableCellText, TableHeaderLabel } from "../../../styles/twozo";
import ContactImage from "../../../assets/images/contact/unknownContact.png";
import { useTheme } from "@emotion/react";
import { useFollowersList } from "../../../hooks/services/summary/followers";
import { default as addIcon } from "../../../assets/icons/add";
import { getDateDifferenceWithHourAndMinute } from "../../../utils/DateUtils";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import React, { useState } from "react";
import ManageFollowers from "./ManageFollowers";
import TooltipComponent from "../../Elements/TooltipComponent";
import { sortTypes } from "../../../utils/followersUtills";

export default function Followers(props) {
	const { sourceName, entityId } = props;

	const [sortRequest, setSortRequest] = useState({});

	const { data: followerList, isLoading: followersListLoading } =
		useFollowersList(sourceName, entityId, sortRequest);

	const theme = useTheme();

	const [tableHeaders, setTableHeaders] = useState([
		{
			id: 1,
			displayName: "User",
			name: "user",
		},
		{
			id: 2,
			displayName: "Role",
			name: "role",
		},
		{
			id: 3,
			displayName: "Permissions on this source",
			name: "permissionsOnThisSource",
		},
		{
			id: 4,
			displayName: "Updated At",
			name: "addedAt",
		},
	]);

	const isFollowersListLoading =
		followersListLoading && Object.keys(sortRequest).length === 0;
	const shouldDisplayHeader =
		followerList?.length >= 1 || Object.keys(sortRequest).length > 0;
	const isSortInProgress =
		followersListLoading && Object.keys(sortRequest).length > 0;

	const [isOpenManageFollowersDrawer, setIsOpenManageFollowersDrawer] =
		useState(false);

	const openManageFollowersDrawer = () => {
		setIsOpenManageFollowersDrawer(true);
	};

	const closeManageFollowersDrawer = () => {
		setIsOpenManageFollowersDrawer(false);
	};

	const toggleSort = (sortIndex) => {
		let newSortRequest = {};

		if (tableHeaders[sortIndex].sort === sortTypes.ascending) {
			newSortRequest = {
				field: tableHeaders[sortIndex].name,
				type: sortTypes.decending,
			};
		} else {
			newSortRequest = {
				field: tableHeaders[sortIndex].name,
				type: sortTypes.ascending,
			};
		}

		setSortRequest(newSortRequest);

		setTableHeaders((headers) => {
			const updatedHeaders = [...headers];

			updatedHeaders.forEach((column, index) => {
				if (index === sortIndex) {
					column.sort =
						column.sort === sortTypes.ascending
							? sortTypes.decending
							: sortTypes.ascending;
				} else {
					column.sort = sortTypes.decending;
				}
			});

			return updatedHeaders;
		});
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isOpenManageFollowersDrawer}
				onOpen={openManageFollowersDrawer}
				onClose={closeManageFollowersDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					sx={{
						width: "50vw",
						minHeight: "100vh",
						backgroundColor: "#fff",
						borderRadius: "10px 0px 0px 10px",
					}}
				>
					<ManageFollowers
						onClose={closeManageFollowersDrawer}
						sourceName={sourceName}
						entityId={entityId}
						isAddMode
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Box>
				{isFollowersListLoading ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="50vh"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : shouldDisplayHeader ? (
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow sx={{ height: "36px" }}>
									{tableHeaders.map((header, index) => (
										<TableCell
											key={header.id}
											sx={{ width: "20%" }}
											onClick={() => toggleSort(index)}
										>
											<Stack
												direction="row"
												alignItems="center"
												spacing={0.5}
											>
												<TableHeaderLabel>
													{header.displayName}
												</TableHeaderLabel>

												<Box display="flex">
													{header.sort ===
													sortTypes.ascending ? (
														<Box
															style={{
																transform:
																	"rotate(180deg)",
															}}
															pt={0.5}
														>
															{DropDownIcon(
																12,
																12,
																"rgba(0, 0, 0, 0.6)"
															)}
														</Box>
													) : (
														DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.6)"
														)
													)}
												</Box>
											</Stack>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{isSortInProgress
									? new Array(5).fill(0).map((_, index) => (
											<TableRow key={index}>
												<TableCell padding="checkbox">
													<Skeleton
														variant="text"
														sx={{
															fontSize: "13px",
														}}
													/>
												</TableCell>

												{tableHeaders
													.slice(1)
													.map((_, index) => (
														<TableCell key={index}>
															<Skeleton
																variant="text"
																sx={{
																	fontSize:
																		"13px",
																}}
															/>
														</TableCell>
													))}
											</TableRow>
										))
									: followerList?.map((followers, index) => (
											<TableRow key={index}>
												<TableCell>
													{followers.user ? (
														<Stack
															direction="row"
															alignItems="center"
															spacing={1}
														>
															<img
																src={
																	ContactImage
																}
																alt="managerProfile"
																width={26}
																height={26}
															/>
															<TooltipComponent
																title={
																	followers
																		.user
																		.name
																}
																placement="top"
															>
																<TableCellText
																	noWrap
																	maxWidth="225px"
																	sx={{
																		wordBreak:
																			"break-all",
																	}}
																>
																	{
																		followers
																			.user
																			.name
																	}
																</TableCellText>
															</TooltipComponent>
														</Stack>
													) : (
														<TableCellText>
															-
														</TableCellText>
													)}
												</TableCell>

												<TableCell>
													<TooltipComponent
														title={
															followers.role.name
														}
														placement="top"
													>
														<TableCellText
															sx={{
																wordBreak:
																	"break-all",
															}}
															maxWidth="210px"
															fontWeight={400}
															noWrap
														>
															{
																followers.role
																	.name
															}
														</TableCellText>
													</TooltipComponent>
												</TableCell>

												<TableCell>
													<TableCellText
														style={{
															color: theme.palette
																.primary.main,
														}}
														fontWeight={500}
													>
														{
															followers.permissionsOnThisSource
														}
													</TableCellText>
												</TableCell>

												<TableCell>
													<TableCellText>
														{followers.addedAt
															? getDateDifferenceWithHourAndMinute(
																	followers.addedAt
																)
															: "-"}
													</TableCellText>
												</TableCell>
											</TableRow>
										))}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="50vh"
						spacing={2}
					>
						<Typography
							fontWeight={400}
							fontSize={14}
							sx={{ opacity: 0.6 }}
						>
							No {sourceName} Followers found.
						</Typography>

						<Button
							variant="contained"
							startIcon={addIcon(
								16,
								16,
								theme.palette.secondary.contrastText
							)}
							color="secondary"
							disableElevation
							onClick={openManageFollowersDrawer}
						>
							Add Followers
						</Button>
					</Stack>
				)}
			</Box>
		</React.Fragment>
	);
}

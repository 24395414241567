import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useReducer, useState } from "react";
import { default as DropdownRightIcon } from "../../../assets/icons/dropDownRight";
import { default as AddIcon } from "../../../assets/icons/add";
import { useNavigate } from "react-router-dom";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import RecordTags from "./RecordTags";
import EmailTemplateTags from "./EmailTemplateTags";
import { tagActionTypes } from "../../../utils/tagActionTypes";
import { TagContext } from "./TagsContext";
import { tagReducer } from "./TagReducer";

const styles = {
	text: {
		fontSize: "15px",
		fontWeight: 500,
	},
	tagsContainer: {
		border: "1px solid rgba(0, 0, 0, 0.1)",
		borderRadius: "8px",
		cursor: "pointer",
	},
};

const viewTypes = {
	RecordTag: "recordTags",
	EmailTemplateTag: "emailTemplateTags",
};

const initialTagState = {
	isCreateMode: false,
	isUpdateMode: false,
	hasUpdateError: false,
	hasCreationError: false,
};

export default function Tags() {
	const theme = useTheme();
	const navigate = useNavigate();
	const [tagState, tagStateDispatcher] = useReducer(
		tagReducer,
		initialTagState
	);

	const [view, setView] = useState(viewTypes.RecordTag);

	const handleToggleButtonGroup = (_, type) => {
		if (tagState.isCreateMode || tagState.isUpdateMode) {
			if (tagState.isCreateMode) {
				tagStateDispatcher({ type: tagActionTypes.creationError });
			}
			if (tagState.isUpdateMode) {
				tagStateDispatcher({ type: tagActionTypes.updateError });
			}
		} else {
			if (type) {
				setView(type);
			}
			tagStateDispatcher({ type: tagActionTypes.resetCreateActions });
			tagStateDispatcher({ type: tagActionTypes.resetUpdateActions });
		}
	};

	const createNewTagCard = () => {
		if (tagState.isCreateMode) {
			tagStateDispatcher({ type: tagActionTypes.creationError });
		} else {
			tagStateDispatcher({ type: tagActionTypes.createTag });
		}
	};

	return (
		<React.Fragment>
			<Box px={3} py={2}>
				<Stack direction="row" alignItems="center" spacing={1}>
					<Box
						style={{ cursor: "pointer" }}
						onClick={() => navigate("/settings")}
					>
						<Typography
							style={styles.text}
							color={theme.palette.primary.main}
						>
							Admin Settings
						</Typography>
					</Box>

					<Box display="flex">
						<IconButton size="small" style={{ padding: 0 }}>
							{DropdownRightIcon(16, 16)}
						</IconButton>
					</Box>

					<Typography style={styles.text}>Tags</Typography>
				</Stack>
			</Box>

			<Box pb={2} pl={3}>
				<Typography fontWeight={600} fontSize={17}>
					Tags
				</Typography>

				<Typography
					color="rgba(0, 0, 0, 0.6)"
					fontSize={14}
					fontWeight={400}
					pt="5px"
				>
					Tags helps your users identify records quickly and more
					accurately. As an admin, you can manage your tags from here.
					Tags added here will show up as default suggestion for all
					your users
				</Typography>
			</Box>

			<Divider />

			<Box px={2} py={2}>
				<Stack direction="row" spacing={2}>
					<ToggleButtonGroup
						value={view}
						onChange={handleToggleButtonGroup}
					>
						<ToggleButton value={viewTypes.RecordTag}>
							Record Tags
						</ToggleButton>

						<ToggleButton value={viewTypes.EmailTemplateTag}>
							Email Template Tags
						</ToggleButton>
					</ToggleButtonGroup>

					<Box>
						<Button
							variant="contained"
							color="secondary"
							startIcon={AddIcon(
								20,
								20,
								theme.palette.primary.main
							)}
							disableElevation
							onClick={createNewTagCard}
							onKeyDown={(event) => event.preventDefault()}
						>
							Add New Tag
						</Button>
					</Box>
				</Stack>
			</Box>

			<Box>
				<TagContext.Provider value={{ tagState, tagStateDispatcher }}>
					{(function () {
						switch (view) {
							case viewTypes.RecordTag:
								return <RecordTags />;
							case viewTypes.EmailTemplateTag:
								return <EmailTemplateTags />;
							default:
								break;
						}
					})()}
				</TagContext.Provider>
			</Box>
		</React.Fragment>
	);
}

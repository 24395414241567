import React from "react";
import {
	Box,
	Chip,
	InputAdornment,
	Paper,
	Popper,
	TextField,
	Typography,
} from "@mui/material";
import { default as MuiAutocomplete } from "@mui/material/Autocomplete";
import { default as CloseIcon } from "../../../assets/icons/close";
import { MenuItemText } from "../../../styles/twozo";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
			}}
		/>
	);
};

export default function Autocomplete(props) {
	const {
		textFieldSx,
		variant = "outlined",
		autoFocus,
		onBlur,
		placeholder,
		renderTags,
		renderOption,
		getOptionLabel,
		freeSolo = true,
		inputAdornmentIcon,
		inputRef,
		renderInput,
		paperComponent,
		autocompleteSx,
		error,
		noOptionsText,
		readOnly = false,
		filterSelectedOptions = true,
		...other
	} = props;

	return (
		<MuiAutocomplete
			{...other}
			size="small"
			multiple
			freeSolo={freeSolo}
			forcePopupIcon={false}
			disableClearable
			sx={{
				...autocompleteSx,
			}}
			filterSelectedOptions={filterSelectedOptions}
			PopperComponent={CustomPopper}
			PaperComponent={paperComponent || CustomPaper}
			renderTags={
				renderTags ||
				((value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							key={index}
							size="small"
							label={
								<Typography fontSize={14} color="#000">
									{option?.name ? option.name : option}
								</Typography>
							}
							color="secondary"
							deleteIcon={CloseIcon(16, 16, "#000")}
							sx={{
								borderRadius: "8px",
							}}
							{...getTagProps({ index })}
						/>
					)))
			}
			renderOption={
				renderOption ||
				((props, option) => (
					<Box
						component="li"
						key={option?.value}
						{...props}
						style={{ minHeight: "40px" }}
					>
						<MenuItemText>
							{option?.name ? option.name : option}
						</MenuItemText>
					</Box>
				))
			}
			getOptionLabel={
				getOptionLabel ||
				((option) => {
					if (typeof option === "string") {
						return option;
					}
					return option.name;
				})
			}
			renderInput={
				renderInput ||
				((params) => (
					<TextField
						autoFocus={autoFocus ? true : false}
						variant={variant}
						onBlur={onBlur ? onBlur : null}
						{...params}
						sx={{
							...textFieldSx,
						}}
						placeholder={placeholder ? placeholder : null}
						inputRef={inputRef}
						inputProps={{
							...params.inputProps,
							style: {
								...params.inputProps?.style,
								fontSize: "14px",
							},
							readOnly: readOnly,
						}}
						error={!!error}
						InputProps={{
							...params.InputProps,
							...(inputAdornmentIcon && {
								startAdornment: (
									<>
										<InputAdornment
											position="start"
											sx={{ paddingLeft: "10px" }}
										>
											{inputAdornmentIcon(
												20,
												20,
												"#666666"
											)}
										</InputAdornment>
										{params.InputProps.startAdornment}
									</>
								),
							}),
						}}
					/>
				))
			}
			noOptionsText={noOptionsText}
		/>
	);
}

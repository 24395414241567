import React, { useState } from "react";
import {
	MenuItemText,
	TableCellText,
	TableHeaderLabel,
	Tooltip,
} from "../../../../../styles/twozo";
import {
	Box,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDownCentered";
import { default as EditIcon } from "../../../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../../../assets/icons/delete";
import { default as CallIcon } from "../../../../../assets/icons/call";
import { default as MailIcon } from "../../../../../assets/icons/mail";
import { default as InfoIcon } from "../../../../../assets/icons/info";
import { getDayMonthTimeAndMinuteFormat } from "../../../../../utils/DateUtils";
import UnKnownImage from "../../../../../assets/images/contact/unknownContact.png";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import Menu from "../../../../Elements/Menu";
import DeleteDialog from "../../../../Elements/DeleteDialog";
import { enqueueSnackbar } from "notistack";
import CustomSwipeableDrawer from "../../../../Elements/CustomSwipeableDrawer";
import EditTerritory from "../EditTerritory";
import { useUserManagementContext } from "../../UserManagementContext";
import {
	useDeleteTerritory,
	useTerritoryUsersListData,
} from "../../../../../hooks/services/userManagement/territory";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

export default function TerritoryList() {
	const theme = useTheme();

	const sortTypes = {
		ascending: "asc",
		decending: "desc",
	};

	const {
		listData: territoryListData,
		isLoadingListData: isLoadingTerritoryList,
		handleSort: handleSort,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useUserManagementContext();

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const [selectedUser, setSelectedUser] = useState({});
	const [territoryName, setTerritoryName] = useState("");
	const [hoveredUserId, setHoveredUserId] = useState(null);
	const [tableRowHovered, setTableRowHovered] = useState(null);
	const [selectedTerritoryId, setSelectedTerritoryId] = useState(null);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);

	const [userMenuElement, setUserMenuElement] = useState(null);
	const isUserMenuOpened = Boolean(userMenuElement);
	const [userCardElement, setUserCardElement] = useState(null);
	const isUserCardOpened = Boolean(userCardElement);
	const [isEditTerritoryDialogOpened, setsEditTeamDialogOpened] =
		useState(false);

	const [tableHeader, setTableHeader] = useState([
		{
			id: 1,
			displayName: "Territory",
			name: "name",
			config: {
				width: "25%",
			},
		},
		{
			id: 2,
			displayName: "Users with Access",
			name: "users",
			config: {
				width: "20%",
			},
		},
		{
			id: 3,
			displayName: "Created by",
			name: "createdBy",
			config: {
				width: "25%",
			},
		},
		{
			id: 4,
			displayName: "Updated by",
			name: "updatedBy",
			config: {
				width: "20%",
			},
		},
		{
			id: 5,
			displayName: "",
			config: {
				width: "10%",
			},
		},
	]);

	const { data: userListData, isLoading: isLoadingUserListData } =
		useTerritoryUsersListData(selectedTerritoryId);
	const deleteMutation = useDeleteTerritory();

	const closeEditTerritoryDialog = () => {
		setsEditTeamDialogOpened(false);
		setSelectedTerritoryId(null);
	};

	const onMouseOverTableRow = (id) => setTableRowHovered(id);
	const onMouseOutTableRow = () => setTableRowHovered(null);

	const closeUserMenu = () => {
		setUserMenuElement(null);
		setSelectedTerritoryId(null);
	};

	const openUserCard = (event, user) => {
		setSelectedUser(user);
		setUserCardElement(event.currentTarget);
	};

	const openUserMenu = (event, territory) => {
		setSelectedTerritoryId(territory.id);
		setUserMenuElement(event.currentTarget);
	};

	const closeUserCard = () => {
		setUserCardElement(null);
	};

	const openDeleteDialog = (id, name) => {
		setSelectedTerritoryId(id);
		setTerritoryName(name);
		setIsDeleteDialogOpened(true);
	};

	const closeDeleteDialog = () => {
		setSelectedTerritoryId(null);
		setIsDeleteDialogOpened(false);
	};

	const openEditTerritoryDialog = (territory) => {
		setSelectedTerritoryId(territory.id);
		setsEditTeamDialogOpened(true);
	};

	const handleDeleteTerritory = () => {
		deleteMutation.mutate(selectedTerritoryId, {
			onSuccess: () => {
				enqueueSnackbar({
					title: "Success!",
					variant: notificationVariants.error,
					message: notificationMessage.territoryDeleted,
				});
				closeDeleteDialog();
			},
		});
	};

	const isHideEditAndDeleteIcon = (territory) => {
		return !(
			selectedTerritoryId !== territory.id &&
			tableRowHovered !== territory.id
		);
	};

	const isActionColumn = (index, tableHeader) => {
		return index === tableHeader.length - 1;
	};

	const toggleSort = (sortIndex) => {
		let sortRequest = {};

		if (tableHeader[sortIndex].sort === sortTypes.ascending) {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.decending,
			};
		} else {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.ascending,
			};
		}

		handleSort(sortRequest);

		setTableHeader((headers) => {
			const updatedHeaders = [...headers];

			updatedHeaders.forEach((column, index) => {
				if (index === sortIndex) {
					column.sort =
						column.sort === sortTypes.ascending
							? sortTypes.decending
							: sortTypes.ascending;
				} else {
					column.sort = sortTypes.decending;
				}
			});

			return updatedHeaders;
		});
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditTerritoryDialogOpened}
				onOpen={openEditTerritoryDialog}
				onClose={closeEditTerritoryDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					sx={{
						width: "50vw",
						minHeight: "100vh",
						backgroundColor: "#fff",
						borderRadius: "10px 0px 0px 10px",
					}}
				>
					<EditTerritory
						onClose={closeEditTerritoryDialog}
						territoryId={selectedTerritoryId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<DeleteDialog
				title={`Are you sure you want to delete the territory ${territoryName}?`}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteTerritory}
			/>

			{/* userMenu */}
			<Menu
				minWidth="200px"
				anchorEl={userMenuElement}
				open={isUserMenuOpened}
				onClose={closeUserMenu}
			>
				{isLoadingUserListData ? (
					<Stack
						height="100px"
						justifyContent="center"
						alignItems="center"
					>
						<CircularProgress size="20px" />
					</Stack>
				) : (
					Array.isArray(userListData) &&
					userListData.map((user) => (
						<Stack
							key={user.id}
							minWidth="300px"
							minHeight="40px"
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							px={2}
							onMouseOver={() => setHoveredUserId(user.id)}
							onMouseOut={() => setHoveredUserId(null)}
							backgroundColor={
								user.id === hoveredUserId
									? "#F4F5F5"
									: "transparent"
							}
						>
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
							>
								<img
									src={UnKnownImage}
									alt="img"
									width={20}
									height={20}
								/>
								<MenuItemText pl={1}>{user.name}</MenuItemText>
							</Stack>

							<IconButton
								onClick={(event) => openUserCard(event, user)}
							>
								{InfoIcon(16, 16, "rgba(0, 0, 0, 0.6)")}
							</IconButton>
						</Stack>
					))
				)}
			</Menu>

			{/* userCard */}
			<Menu
				minWidth="320px"
				anchorEl={userCardElement}
				open={isUserCardOpened}
				onClose={closeUserCard}
			>
				<Box px={2} py={1}>
					<Stack direction="row" spacing={2}>
						<Stack>
							<img
								src={UnKnownImage}
								alt="img"
								width={45}
								height={45}
							/>
						</Stack>

						<Stack>
							<Typography
								fontSize={17}
								fontWeight={600}
								color={theme.palette.secondary.contrastText}
							>
								{selectedUser?.name}
							</Typography>

							{!!selectedUser?.phone && (
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
									pt={0.5}
								>
									{CallIcon(
										16,
										16,
										theme.palette.primary.main
									)}

									<Typography
										fontSize={15}
										fontWeight={500}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{selectedUser?.phone}
									</Typography>
								</Stack>
							)}

							{!!selectedUser?.email && (
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
								>
									{MailIcon(
										16,
										16,
										theme.palette.primary.main
									)}

									<Typography
										fontSize={15}
										fontWeight={500}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{selectedUser?.email}
									</Typography>
								</Stack>
							)}
						</Stack>
					</Stack>
				</Box>
			</Menu>

			<TableContainer sx={{ maxHeight: "100%" }}>
				<Table stickyHeader sx={{ minWidth: 650 }} size="small">
					<TableHead>
						<TableRow sx={{ height: "36px" }}>
							{tableHeader.map((header, index) => (
								<TableCell
									key={header.id}
									width={header.config.width}
									onClick={() => toggleSort(index)}
								>
									<Stack
										direction="row"
										alignItems="center"
										spacing={1}
									>
										<TableHeaderLabel>
											{header.displayName}
										</TableHeaderLabel>
										<Box display="flex">
											{!isActionColumn(
												index,
												tableHeader
											) ? (
												header.sort ===
												sortTypes.ascending ? (
													<Box
														display="flex"
														style={{
															transform:
																"rotate(180deg)",
														}}
													>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.6)"
														)}
													</Box>
												) : (
													<Box display="flex">
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.6)"
														)}
													</Box>
												)
											) : (
												""
											)}
										</Box>
									</Stack>
								</TableCell>
							))}
						</TableRow>
					</TableHead>

					<TableBody>
						{isLoadingTerritoryList ? (
							<TableRow
								style={{
									height: "60vh",
								}}
							>
								<TableCell colSpan={5} sx={{ borderBottom: 0 }}>
									<Stack
										height="100%"
										justifyContent="center"
										alignItems="center"
										width="100%"
									>
										<CircularProgress />
									</Stack>
								</TableCell>
							</TableRow>
						) : (
							territoryListData?.pages?.map((page) =>
								page?.territories?.map((territory) => (
									<TableRow
										key={territory.id}
										onMouseOver={() =>
											onMouseOverTableRow(territory.id)
										}
										onMouseOut={onMouseOutTableRow}
									>
										<TableCell width="25%">
											<TableCellText>
												{territory.name}
											</TableCellText>
										</TableCell>

										<TableCell
											width="20%"
											onClick={(event) =>
												openUserMenu(event, territory)
											}
										>
											<TableCellText
												fontWeight={500}
												color={
													theme.palette.secondary
														.contrastText
												}
											>
												{territory.usersCount} User
											</TableCellText>
										</TableCell>

										<TableCell width="25%">
											<Tooltip
												title={getDayMonthTimeAndMinuteFormat(
													territory.createdTime
												)}
												placement="right"
											>
												<Stack
													direction="row"
													alignItems="center"
													spacing={1}
													width="fit-content"
												>
													<img
														src={UnKnownImage}
														alt="img"
														width={26}
														height={26}
													/>

													<TableCellText>
														{
															territory.createdBy
																.name
														}
													</TableCellText>
												</Stack>
											</Tooltip>
										</TableCell>

										<TableCell width="20%">
											<Tooltip
												title={getDayMonthTimeAndMinuteFormat(
													territory.updatedTime
												)}
												placement="right"
											>
												<Stack
													direction="row"
													alignItems="center"
													spacing={1}
													width="fit-content"
												>
													<img
														src={UnKnownImage}
														alt="img"
														width={26}
														height={26}
													/>

													<TableCellText>
														{
															territory.updatedBy
																.name
														}
													</TableCellText>
												</Stack>
											</Tooltip>
										</TableCell>

										<TableCell width="10%" sx={{ py: 0 }}>
											<Stack alignItems="flex-end">
												{isHideEditAndDeleteIcon(
													territory
												) && (
													<Box
														style={{
															height: "37px",
															width: "100px",
															border: `1px solid ${theme.palette.primary.main}`,
															borderRadius: "8px",
														}}
														py={1}
														px={1.5}
													>
														<Stack
															direction="row"
															justifyContent="center"
															alignItems="center"
															style={{
																height: "100%",
															}}
															spacing={1.5}
														>
															<Box
																display="flex"
																onClick={() =>
																	openEditTerritoryDialog(
																		territory
																	)
																}
															>
																{EditIcon(
																	20,
																	20,
																	theme
																		.palette
																		.primary
																		.main
																)}
															</Box>

															<Divider orientation="vertical" />

															<Box
																display="flex"
																onClick={() =>
																	openDeleteDialog(
																		territory.id,
																		territory.name
																	)
																}
															>
																{DeleteIcon(
																	20,
																	20,
																	theme
																		.palette
																		.error
																		.main
																)}
															</Box>
														</Stack>
													</Box>
												)}
											</Stack>
										</TableCell>
									</TableRow>
								))
							)
						)}
						<TableRow>
							<TableCell
								colSpan={5}
								sx={{ borderBottom: "none" }}
							>
								<Box ref={lastRowRef}>
									{!isLoadingTerritoryList && isFetching && (
										<Stack
											direction="row"
											alignItems="center"
											justifyContent="center"
											spacing={1}
											py={2}
										>
											<CircularProgress size={18} />

											<Typography fontSize={12}>
												Loading More Data
											</Typography>
										</Stack>
									)}
								</Box>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</React.Fragment>
	);
}

import { Box, Button, CircularProgress, Divider, Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import PeriodicFilter from "./PeriodicFilter";
import Filter from "./Filter";
import { useWatch } from "react-hook-form";
import { getFieldValue } from "../../../../../../../Workflow/Field/workflowParser";

export default function AddFilter({
	onClose,
	index,
	setValue,
	control,
	filterData,
	isFilterDataLoading,
}) {
	const {
		filters: filterFields,
		comparators,
		functions,
		defaultPeriodicFilterValue,
		periodicFilters: periodicFiltersFields,
	} = useMemo(() => filterData || {}, [filterData]);

	const [defaultPeriodicFilter, setDefaultPeriodicFilter] = useState({});

	useEffect(() => {
		if (defaultPeriodicFilterValue) {
			setDefaultPeriodicFilter({ ...defaultPeriodicFilterValue });
		}
	}, [defaultPeriodicFilterValue]);

	const [filters, periodicFilters] = useWatch({
		control,
		name: [
			`viewProperties.metrics.${index}.filters`,
			`viewProperties.metrics.${index}.periodicFilters`,
		],
	});

	let initialPeriodicFilter = {
		field: {},
		comparator: {},
		values: [],
		function: {},
		isCustomDropdown: false,
	};

	const [filtersList, setFiltersList] = useState([
		{
			comparator: {},
			field: {},
			values: [],
			error: {},
		},
	]);
	const [periodicFilterData, setPeriodicFilterData] = useState(
		initialPeriodicFilter
	);

	useEffect(() => {
		if (
			filters &&
			Array.isArray(filters) &&
			filters.length > 0 &&
			comparators &&
			filterFields
		) {
			let updatedFilterList = filters.map((filter) => {
				const { fieldId, comparatorId } = filter;
				let field = filterFields?.find(
					(field) => field.fieldId === fieldId || field?.field?.id
				);
				let comparator = {
					...comparators[filter?.comparator?.id || comparatorId],
					id: filter.comparator?.id || comparatorId,
				};

				return {
					...filter,
					field: {
						id: field?.fieldId,
						name: field?.name,
					},
					fieldType: field?.fieldType,
					comparator: comparator,
				};
			});

			setFiltersList(updatedFilterList);
		}
	}, [comparators, filterFields, filters]);

	useEffect(() => {
		if (!filterData) return;

		const periodicFilter =
			periodicFilters && periodicFilters?.fieldId
				? periodicFilters
				: defaultPeriodicFilter;

		const comparator = {
			...comparators[
				periodicFilter?.comparatorId || periodicFilter?.comparator?.id
			],
			id: periodicFilter?.comparatorId || periodicFilter?.comparator?.id,
		};

		const functionData = functions[periodicFilter?.functionId];

		const field = periodicFiltersFields?.find(
			(f) => f.fieldId === periodicFilter?.fieldId
		);

		setPeriodicFilterData((prev) => ({
			...prev,
			field,
			comparator,
			function: periodicFilter?.functionId || null,
			values: periodicFilter?.values,
			isCustomDropdown:
				periodicFilter?.isCustomDropdown ||
				functionData?.isCustom ||
				undefined,
		}));
	}, [
		comparators,
		defaultPeriodicFilter,
		filterData,
		functions,
		periodicFilters,
		periodicFiltersFields,
	]);

	const addFilter = () => {
		let updatedFilterSet = [
			...filtersList,
			{
				comparator: {},
				field: {},
				values: [],
				error: {},
			},
		];
		setFiltersList(updatedFilterSet);
	};

	const deleteFilter = (index) => {
		if (filtersList?.length === 1) {
			setFiltersList([
				{
					comparator: {},
					field: {},
					values: [],
					error: {},
				},
			]);
		} else {
			let updatedFilterSet = [
				...filtersList.slice(0, index),
				...filtersList.slice(index + 1),
			];
			setFiltersList(updatedFilterSet);
		}
	};

	const getFormattedFilterList = (filters) => {
		let clonedFilterList = [...filters];
		let filteredFields = clonedFilterList.filter(
			(field) => field?.field?.id || field?.fieldId
		);
		if (filteredFields?.length <= 0) {
			return [];
		}

		return clonedFilterList.map((filter, idx) => {
			const formattedFilter = {};
			formattedFilter.order = idx + 1;
			formattedFilter.fieldId = filter.field.fieldId || filter.field.id;
			formattedFilter.comparatorId = filter.comparator.id;
			if (filter.comparator.hasValue && filter.values?.length > 0) {
				formattedFilter.values = getFieldValue(filter);
			}
			return formattedFilter;
		});
	};

	const getFormattedPeriodicFilter = (periodicFilter) => {
		let clonedPeriodicFilter = { ...periodicFilter };

		const filteredData = {};

		filteredData.fieldId = clonedPeriodicFilter?.field?.fieldId;
		filteredData.comparatorId = clonedPeriodicFilter?.comparator?.id;

		if (clonedPeriodicFilter.function) {
			filteredData.functionId = clonedPeriodicFilter.function;
		}
		if (clonedPeriodicFilter.values?.length > 0) {
			filteredData.values = clonedPeriodicFilter.values;
		}
		if (clonedPeriodicFilter?.isCustomDropdown !== undefined) {
			filteredData.isCustomDropdown =
				!!clonedPeriodicFilter.isCustomDropdown;
		}
		return filteredData;
	};

	const isValidFilter = useMemo(() => {
		return filtersList.length > 1
			? filtersList.some((field) => field?.fieldId || field?.field?.id)
			: true || periodicFilterData?.field?.fieldId;
	}, [filtersList, periodicFilterData]);

	const handleAddFilter = () => {
		if (!isValidFilter) {
			return;
		}

		setValue(
			`viewProperties.metrics.${index}.filters`,
			getFormattedFilterList(filtersList)
		);
		setValue(
			`viewProperties.metrics.${index}.periodicFilters`,
			getFormattedPeriodicFilter(periodicFilterData)
		);
		onClose();
	};

	const handleClearPeriodicFilter = () => {
		setValue(
			`viewProperties.metrics.${index}.periodicFilters`,
			initialPeriodicFilter
		);
		setPeriodicFilterData(initialPeriodicFilter);
		setDefaultPeriodicFilter({});
	};

	return (
		<Box
			style={{ height: "100%", display: "flex", flexDirection: "column" }}
		>
			{isFilterDataLoading ? (
				<Stack
					justifyContent="center"
					alignItems="center"
					height="300px"
				>
					<CircularProgress size="20px" />
				</Stack>
			) : (
				<Box
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						maxHeight: "500px",
						overflow: "auto",
					}}
				>
					<Box p={2}>
						<PeriodicFilter
							periodicFilterFields={periodicFiltersFields}
							initialComparatorList={comparators}
							initialFunctionList={functions}
							periodicFilter={periodicFilterData}
							setPeriodicFilter={setPeriodicFilterData}
							handleClear={handleClearPeriodicFilter}
						/>
					</Box>

					<Divider />

					<Box p={2}>
						<Filter
							addFilter={addFilter}
							filterList={filtersList}
							deleteFilter={deleteFilter}
							filterFields={filterFields}
							comparators={comparators}
							updateFilter={setFiltersList}
							filters={filters}
						/>
					</Box>
				</Box>
			)}
			<Divider />

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="flex-end"
				spacing={2}
				px={1}
				p={2}
			>
				<Button
					variant="contained"
					color="secondary"
					onClick={onClose}
					disableElevation
				>
					Cancel
				</Button>

				<Button
					variant="contained"
					onClick={handleAddFilter}
					disableElevation
					style={{
						opacity: isValidFilter ? 1 : 0.6,
					}}
				>
					Add
				</Button>
			</Stack>
		</Box>
	);
}

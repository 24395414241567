import React, { useState } from "react";
import {
	Box,
	CircularProgress,
	Divider,
	IconButton,
	MenuItem,
	MenuList,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { default as ContactImage } from "../../../../../assets/images/contact/unknownContact.png";
import {
	useMetrics,
	useMetricsInsights,
} from "../../../../../hooks/services/mail";
import { metricTypes } from "../../../../../utils/EmailUtils/emailMetricTypes";

export default function MetricView(props) {
	const { onClose, metricData } = props;
	const theme = useTheme();

	const [selectedMetricType, setSelectedMetricType] = useState(
		metricTypes.sent
	);

	const { data: metrics } = useMetrics(metricData?.id);
	const { data: metricsInsights, isLoading: isLoadingMetricsInsights } =
		useMetricsInsights(metricData?.id, selectedMetricType);

	const metricsOverviewMenu = [
		{
			name: "Sent",
			value: metrics?.sent,
			type: metricTypes.sent,
		},
		{
			name: "Opened",
			value: metrics?.opened,
			type: metricTypes.opened,
		},
		{
			name: "Clicked",
			value: metrics?.clicked,
			type: metricTypes.clicked,
		},
		{
			name: "Replied",
			value: metrics?.replied,
			type: metricTypes.replied,
		},
		{
			name: "Opened, not clicked",
			value: metrics?.openedNotClicked,
			type: metricTypes.openedNotClicked,
		},
		{
			name: "Opened, not replied",
			value: metrics?.openedNotReplied,
			type: metricTypes.openedNotReplied,
		},
		{
			name: "Not opened",
			value: metrics?.notOpened,
			type: metricTypes.notOpened,
		},
		{
			name: "Not sent",
			value: metrics?.notSent,
			type: metricTypes.notSent,
		},
	];

	const onSelectMetricsType = (selectedViewType) => {
		setSelectedMetricType(selectedViewType);
	};

	return (
		<React.Fragment>
			<Stack height="100%">
				<Stack p={2} direction="row" justifyContent="space-between">
					<Typography fontSize={17} fontWeight={600}>
						{metricData?.subject || "(no subject)"}
					</Typography>

					<IconButton onClick={onClose} style={{ padding: "0px" }}>
						{CloseIcon(24, 24, theme.palette.primary.main)}
					</IconButton>
				</Stack>

				<Divider />

				<Stack direction="row" width="100%" height="100%">
					<Box width="30%">
						<MenuList style={{ padding: 0 }}>
							{metricsOverviewMenu.map((overviewMenu, index) => (
								<MenuItem
									style={{
										height: "40px",
										width: "100%",
										backgroundColor:
											overviewMenu.type ===
												selectedMetricType &&
											theme.palette.secondary.main,
									}}
									key={index}
									onClick={() =>
										onSelectMetricsType(overviewMenu.type)
									}
								>
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between"
										py={1.5}
										px={1.6}
										width="100%"
									>
										<Typography fontSize={14}>
											{overviewMenu.name}
										</Typography>

										<Typography fontSize={14}>
											{overviewMenu.value}
										</Typography>
									</Stack>
								</MenuItem>
							))}
						</MenuList>
					</Box>

					<Divider flexItem={true} orientation="vertical" />

					<Box width="70%" height="82vh" overflow="auto">
						{isLoadingMetricsInsights ? (
							<Stack
								alignItems="center"
								justifyContent="center"
								height="60vh"
							>
								<CircularProgress size="30px" />
							</Stack>
						) : (
							metricsInsights?.map(
								(metricsInsightData, index) => (
									<Stack
										key={index}
										direction="row"
										alignItems="center"
										style={{
											borderBottom: `1px solid ${theme.palette.divider}`,
											padding: "14px 16px",
											width: "100%",
										}}
									>
										<Box display="flex" width="50%">
											<Stack
												direction="row"
												spacing={1}
												alignItems="center"
											>
												<img
													src={ContactImage}
													alt="contact_image"
													height="24px"
													width="24px"
												/>

												<Typography fontSize={14}>
													{metricsInsightData?.value}
												</Typography>
											</Stack>
										</Box>

										<Box width="60%" display="flex">
											<Typography
												fontSize={14}
												color="rgba(0, 0, 0, 0.6)"
											>
												{metricsInsightData?.status}
											</Typography>
										</Box>
									</Stack>
								)
							)
						)}
					</Box>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}

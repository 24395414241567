import React, { useState } from "react";
import { Box, MenuItem, Stack, Divider, useTheme } from "@mui/material";
import { default as FavouriteOn } from "../../../../../../assets/icons/favouriteOn";
import { default as FavouriteOff } from "../../../../../../assets/icons/favouriteOff";
import { default as DeleteIcon } from "../../../../../../assets/icons/delete";
import { default as EditIcon } from "../../../../../../assets/icons/edit";
import DeleteDialog from "../../../../DeleteDialog";
import { MenuItemText, Tooltip } from "../../../../../../styles/twozo";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../../utils/notification/notificationMessages";
import {
	useDeleteFilterMutation,
	useSourceList,
	useUpdateFavouriteFilterMutation,
} from "../../../../../../hooks/services/common/table/filter";
import EditViewDialog from "../../EditViewDialog";
import { useFilterDataContext } from "../../Context/FilterDataContext";

export default function FilterList(props) {
	const {
		moduleName,
		closeFilterListMenu,
		filterList,
		hasShareViewPermissions,
		updateCurrentlyAppliedFilter,
		appliedFilterId,
	} = props;
	const theme = useTheme();

	const { filterState, removeAppliedFilter } = useFilterDataContext();

	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const [hoveredFilterDetails, setHoveredFilterDetails] = useState({});

	//Filter Source List
	const { data: filterSourceList } = useSourceList();
	const sourceId =
		filterSourceList?.length >= 1
			? filterSourceList?.find((module) => module.name === moduleName)?.id
			: null;

	const onMouseOverViewMenu = (id, title) => {
		setHoveredFilterDetails({
			id: id,
			title: title,
		});
	};

	const onMouseOutViewMenu = () => {
		setHoveredFilterDetails({});
	};

	const handleOpenTooltip = (event) => {
		setIsTooltipOpen(event.target.scrollWidth > event.target.clientWidth);
	};

	//Update Filter Favourites
	const updateFavourites = (filterData) => {
		updateFavouriteFilterMutation.mutate(
			{ id: filterData.id, isFavorite: !filterData.isFavorite },
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.filterUpdated,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	const updateFavouriteFilterMutation =
		useUpdateFavouriteFilterMutation(sourceId);

	//Delete Filter
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [filterForDeletion, setFilterForDeletion] = useState({});

	const handleDeleteFilter = (filterData) => {
		setFilterForDeletion(filterData);
		setIsDeleteDialogOpened(true);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const performDeleteConfirm = (filterForDeletion) => {
		deleteFilterMutation.mutate(filterForDeletion.id, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.filterDeleted,
				});
				closeDeleteDialog();
				closeFilterListMenu();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});

		if (filterForDeletion.id === filterState.appliedFilterId) {
			removeAppliedFilter();
		}
	};

	const deleteFilterMutation = useDeleteFilterMutation(sourceId);

	//Apply filter from the favourites menu
	const applyFilterFromList = (filter) => {
		updateCurrentlyAppliedFilter(filter);
	};

	//Edit View
	const [isEditViewDialogOpened, setIsEditViewDialogOpened] = useState(false);
	const [filterId, setFilterId] = useState(null);

	const openEditViewDialog = (filter) => {
		setFilterId(filter.id);
		setIsEditViewDialogOpened(true);
	};

	const closeEditViewDialog = () => {
		setIsEditViewDialogOpened(false);
	};

	return (
		<Box>
			<DeleteDialog
				title={`Are you sure you want to delete ${filterForDeletion?.name} ?`}
				subtitle="This will delete those filter criteria details, and it won't affect any data."
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={() => performDeleteConfirm(filterForDeletion)}
			/>

			<EditViewDialog
				open={isEditViewDialogOpened}
				onClose={closeEditViewDialog}
				filterId={filterId}
				sourceId={sourceId}
				hasShareViewPermissions={hasShareViewPermissions}
			/>

			{filterList?.length >= 1 &&
				filterList?.map((filterGroup, index) => (
					<Stack key={index}>
						<MenuItemText
							color="#000"
							fontWeight={500}
							style={{
								paddingLeft: "16px",
							}}
						>
							{filterGroup.title}
						</MenuItemText>

						{filterGroup?.options?.map((filter) => (
							<MenuItem
								key={filter.id}
								style={{
									height: "40px",
									display: "flex",
									justifyContent: "space-between",
									backgroundColor:
										appliedFilterId === filter.id
											? theme.palette.secondary.main
											: "transparent",
								}}
								onMouseOver={() =>
									onMouseOverViewMenu(
										filter.id,
										filterGroup.title
									)
								}
								onMouseLeave={onMouseOutViewMenu}
							>
								<Stack
									direction="row"
									spacing={1.5}
									alignItems="center"
								>
									<Stack
										onClick={() => updateFavourites(filter)}
									>
										{filter.isFavorite
											? FavouriteOn(
													20,
													20,
													theme.palette.primary.main
												)
											: FavouriteOff(
													20,
													20,
													"#000",
													"0.2"
												)}
									</Stack>

									<Stack
										width="120px"
										onClick={() =>
											applyFilterFromList(filter)
										}
									>
										<Tooltip
											title={
												hoveredFilterDetails.id ===
													filter.id && isTooltipOpen
													? filter.name
													: ""
											}
											placement="bottom"
										>
											<MenuItemText
												noWrap
												onMouseOver={(event) =>
													handleOpenTooltip(event)
												}
												onMouseLeave={() =>
													setIsTooltipOpen(false)
												}
											>
												{filter.name}
											</MenuItemText>
										</Tooltip>
									</Stack>

									<Stack direction="row" spacing={2}>
										<Stack>
											{filter?.isEditable &&
												hoveredFilterDetails.title ===
													filterGroup.title &&
												hoveredFilterDetails.id ===
													filter.id && (
													<Stack
														onClick={() =>
															openEditViewDialog(
																filter
															)
														}
													>
														{EditIcon(
															18,
															18,
															"rgba(0,0,0,0.6)"
														)}
													</Stack>
												)}
										</Stack>

										<Stack>
											{filter?.isDeletable &&
												hoveredFilterDetails.title ===
													filterGroup.title &&
												hoveredFilterDetails.id ===
													filter.id && (
													<Stack
														onClick={() =>
															handleDeleteFilter(
																filter
															)
														}
													>
														{DeleteIcon(
															18,
															18,
															"rgba(0,0,0,0.6)"
														)}
													</Stack>
												)}
										</Stack>
									</Stack>
								</Stack>
							</MenuItem>
						))}

						<Divider hidden={index === filterList.length - 1} />
					</Stack>
				))}
		</Box>
	);
}

import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";
import { useModuleName } from "../../../../../../hooks/modules";
import { modules } from "../../../../../../utils/common/ModulesName";

export default function DealTitleComponent(props) {
	const { field, inputRef, auxiliaryFormData } = props;

	const { control, clearErrors, setValue, getValues } = useFormContext();
	const { getModuleName } = useModuleName();
	const { fieldValueLabels } = auxiliaryFormData;

	useEffect(() => {
		if (field?.referrerFields?.length > 0) {
			for (let referrerFields of field.referrerFields) {
				const dealTitleInputValue = getValues(field?.id?.toString());
				if (
					!dealTitleInputValue &&
					fieldValueLabels?.[referrerFields?.id]
				) {
					setValue(
						field.id + "",
						`${fieldValueLabels[referrerFields.id]} ${getModuleName(
							modules.DEAL
						)}`
					);
				}
			}
		}
	}, [field, fieldValueLabels, setValue, getModuleName, getValues]);

	const onChangeDealTitleInput = (event, onChange) => {
		let { value } = event.target;
		onChange(value?.trimStart());
		clearErrors(field.id);
	};

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				defaultValue=""
				rules={handleFieldValidation(field)}
				render={({
					field: { onChange, ...renderField },
					fieldState: { error },
				}) => (
					<TextField
						{...renderField}
						id={field.id + ""}
						placeholder={field?.placeHolder || "Enter"}
						onChange={(event) =>
							onChangeDealTitleInput(event, onChange)
						}
						inputRef={inputRef}
						error={!!error}
					/>
				)}
			></Controller>
		</React.Fragment>
	);
}

import React from "react";
import {
	Box,
	Button,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import UnKnownImage from "../../../assets/images/contact/unknownContact.png";
import {
	useCurrentUserDetails,
	useUpdateCurrentUserMutation,
} from "../../../hooks/services/userProfile";
import { FormFieldName, MenuItemText } from "../../../styles/twozo";
import { getDropdownDataByName } from "../../../utils/dropdownData";
import { Controller, useForm } from "react-hook-form";
import { removeFieldsWithEmptyValues } from "../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";

export default function MyAccount(props) {
	const { onClose } = props;
	const theme = useTheme();

	const { data: userDetails } = useCurrentUserDetails();
	const dateFormatList = getDropdownDataByName("DATE_FORMAT_LIST");
	const timeFormatList = getDropdownDataByName("TIME_FORMAT_LIST");
	const languageList = getDropdownDataByName("LANGUAGES");
	const timeZoneList = getDropdownDataByName("ACCOUNT_TIMEZONE");
	const errorMessage = "The field is mandatory";
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		clearErrors,
	} = useForm({
		values: userDetails?.user,
		reValidateMode: false,
	});
	const updateCurrentUserMutation = useUpdateCurrentUserMutation();

	const handleSave = (formData) => {
		const data = {
			...formData,
			preferences: {
				dateFormat:
					formData.preferences.userRegionalPreference.dateFormat,
				timeFormat:
					formData.preferences.userRegionalPreference.timeFormat,
				timeZone: formData.preferences.userRegionalPreference.timeZone,
				language: formData.preferences.userRegionalPreference.language,
			},
		};

		delete data.locale;
		delete data.orgId;
		delete data.stateId;
		delete data.typeId;

		removeFieldsWithEmptyValues(data);
		updateCurrentUserMutation.mutate(data, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.profileUpdated,
				});
				onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Stack
					direction="row"
					alignItems="center"
					px={3}
					py={2}
					spacing={3}
				>
					<img src={UnKnownImage} alt="img" width={64} height={64} />

					<Stack>
						<Typography fontWeight={600} fontSize={16}>
							{userDetails?.user.firstName}
						</Typography>

						<Typography
							fontWeight={400}
							fontSize={14}
							color="rgba(0, 0, 0, 0.6)"
						>
							{userDetails?.user.email}
						</Typography>
					</Stack>
				</Stack>

				<Box
					pt={1}
					px={3}
					pb={2}
					style={{
						flex: "1 1 auto",
						minHeight: "0px",
					}}
				>
					<Box
						style={{
							display: "flex",
							flexDirection: "column",
							height: "100%",
							width: "100%",
						}}
					>
						<Box
							style={{
								flex: "1 1 auto",
								overflowY: "auto",
								minHeight: "0px",
							}}
							border="1px solid rgba(0, 0, 0, 0.1)"
							borderRadius={1}
							px={2}
						>
							<Stack py={3} spacing={2}>
								<Typography fontWeight={600} fontSize={16}>
									Work Information
								</Typography>

								<Stack
									direction="row"
									spacing={2}
									sx={{ width: "55%" }}
								>
									<Stack sx={{ width: "50%" }}>
										<Stack direction="row">
											<FormFieldName
												color={
													errors["firstName"]
														? theme.palette.error
																.main
														: "none"
												}
											>
												First Name
											</FormFieldName>
											<span
												style={{
													color: theme.palette.error
														.main,
													marginLeft: "5px",
												}}
											>
												*
											</span>
										</Stack>
										<TextField
											fullWidth
											{...register("firstName", {
												required: errorMessage,
											})}
											onChange={() => {
												clearErrors("firstName");
											}}
											FormHelperTextProps={{
												sx: {
													marginLeft: 1,
													fontSize: 13,
													fontWeight: 500,
												},
											}}
											placeholder="Eg: John"
											error={!!errors.firstName}
											helperText={
												errors.firstName?.message
											}
										/>
									</Stack>

									<Stack sx={{ width: "50%" }} spacing={0.6}>
										<FormFieldName>Last Name</FormFieldName>
										<TextField
											fullWidth
											{...register("lastName")}
											placeholder="Eg: Mathew"
										/>
									</Stack>
								</Stack>

								<Stack>
									<FormFieldName>Job Title</FormFieldName>
									<TextField
										sx={{ width: "55%" }}
										{...register("jobTitle")}
										placeholder="Eg: Sale Representative "
									/>
								</Stack>

								<Stack>
									<FormFieldName style={{ opacity: 0.6 }}>
										Email
									</FormFieldName>
									<TextField
										sx={{
											width: "55%",
											"& .MuiOutlinedInput-notchedOutline":
												{
													opacity: 0.6,
												},
										}}
										{...register("email")}
										disabled
									/>
								</Stack>

								<Stack>
									<FormFieldName>Timezone</FormFieldName>
									<Controller
										name="preferences.userRegionalPreference.timeZone"
										control={control}
										render={({
											field: { value, onChange },
										}) => (
											<Select
												sx={{ width: "55%" }}
												value={value || ""}
												onChange={onChange}
												MenuProps={{
													PaperProps: {
														style: {
															maxHeight: 300,
														},
													},
												}}
											>
												{timeZoneList.map(
													(timezone) => (
														<MenuItem
															key={timezone.value}
															value={
																timezone.value
															}
															style={{
																height: "40px",
															}}
														>
															<MenuItemText>
																{timezone.name}
															</MenuItemText>
														</MenuItem>
													)
												)}
											</Select>
										)}
									/>
									<Typography
										fontFamily={400}
										fontSize={13}
										color="rgba(0, 0, 0, 0.6)"
										style={{ paddingLeft: "6px" }}
									>
										Timezone is updated automatically to
										match your computer timezone
									</Typography>
								</Stack>

								<Stack>
									<FormFieldName>Language</FormFieldName>
									<Controller
										name="preferences.userRegionalPreference.language"
										control={control}
										render={({
											field: { value, onChange },
										}) => (
											<Select
												sx={{
													width: "55%",
												}}
												value={value || ""}
												onChange={onChange}
												MenuProps={{
													PaperProps: {
														style: {
															maxHeight: 300,
														},
													},
												}}
											>
												{languageList.map(
													(language) => (
														<MenuItem
															key={language.value}
															value={
																language.value
															}
															style={{
																height: "40px",
															}}
														>
															<MenuItemText>
																{language.name}
															</MenuItemText>
														</MenuItem>
													)
												)}
											</Select>
										)}
									/>
								</Stack>

								<Stack
									direction="row"
									spacing={2}
									sx={{ width: "55%" }}
								>
									<Stack sx={{ width: "50%" }}>
										<FormFieldName>
											Date Format
										</FormFieldName>
										<Controller
											name="preferences.userRegionalPreference.dateFormat"
											control={control}
											render={({
												field: { value, onChange },
											}) => (
												<Select
													fullWidth
													value={value || ""}
													onChange={onChange}
												>
													{dateFormatList.map(
														(date) => (
															<MenuItem
																key={date.value}
																value={
																	date.value
																}
																style={{
																	height: "40px",
																}}
															>
																<MenuItemText>
																	{date.name}
																</MenuItemText>
															</MenuItem>
														)
													)}
												</Select>
											)}
										/>
									</Stack>

									<Stack sx={{ width: "50%" }}>
										<FormFieldName>
											Number Format
										</FormFieldName>
										<Controller
											name="preferences.userRegionalPreference.timeFormat"
											control={control}
											render={({
												field: { value, onChange },
											}) => (
												<Select
													fullWidth
													value={value || ""}
													onChange={onChange}
												>
													{timeFormatList.map(
														(time) => (
															<MenuItem
																key={time.value}
																value={
																	time.value
																}
																style={{
																	height: "40px",
																}}
															>
																<MenuItemText>
																	{time.name}
																</MenuItemText>
															</MenuItem>
														)
													)}
												</Select>
											)}
										/>
									</Stack>
								</Stack>
							</Stack>

							<Stack
								direction="row"
								alignItems="flex-start"
								py={3}
								sx={{
									position: "sticky",
									bottom: 0,
									backgroundColor: "white",
									zIndex: 1,
									borderTop: "1px solid rgba(0, 0, 0, 0.1)",
								}}
							>
								<Button
									variant="contained"
									disableElevation
									onClick={handleSubmit(handleSave)}
								>
									Save
								</Button>
							</Stack>
						</Box>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}

import React from "react";
import { Typography, useTheme } from "@mui/material";

export default function EmailReceived(props) {
	const { notificationData, notificationContentStyle } = props;

	const theme = useTheme();

	if (!notificationData) {
		return null;
	}

	return (
		<React.Fragment>
			<Typography
				component="samp"
				style={{ ...notificationContentStyle }}
			>
				From{" "}
				<Typography
					component="samp"
					style={{
						fontSize: "14px",
						color: theme.palette.secondary.contrastText,
					}}
				>
					{notificationData?.data?.contact
						? notificationData.data.contact?.name
						: notificationData.data?.email}
				</Typography>
			</Typography>
		</React.Fragment>
	);
}

import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import { default as DropDownIcon } from "../../../../../../assets/icons/dropDown";
import {
	twozoStyles,
	Tooltip,
	SummaryFieldName,
} from "../../../../../../styles/twozo";
import FieldEdit from "../../../../FieldEdit";
import TagFieldEditForm from "../../../../FieldEditFormComponents/TagFieldEditForm";
import { useSummaryContext } from "../../../../../Summary/SummaryContext";

export default function TagComponent(params) {
	const { summaryField } = params;
	const [isTagHovered, setIsTagHovered] = useState(false);
	const theme = useTheme();
	const classes = twozoStyles();

	// Summary Context:-
	const { updateSummaryField, entityId } = useSummaryContext();

	const toolTipList = useMemo(() => {
		if (summaryField?.value) {
			return summaryField.value
				.slice(1)
				.map((value) => value.tag)
				.join(", ");
		}
		return [];
	}, [summaryField]);

	const getTagFieldLabel = (onFieldClicked) => {
		return (
			<Box onClick={onFieldClicked}>
				{summaryField?.value?.[0]?.tag ? (
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<Box
							py={0.3}
							px={1}
							style={{
								backgroundColor: theme.palette.secondary.main,
								borderRadius: "6px",
							}}
							flexGrow={1}
						>
							<Typography
								fontWeight={500}
								fontSize={14}
								className={classes.summaryData}
								width="fit-content"
								noWrap
								sx={{
									maxWidth: "112px",
								}}
							>
								{summaryField?.value[0].tag}
							</Typography>
						</Box>
						{summaryField?.value.length > 1 && (
							<Tooltip title={toolTipList} placement="top">
								<Typography
									width="20px"
									fontWeight={500}
									fontSize={15}
									color={theme.palette.primary.main}
									style={{
										cursor: "pointer",
									}}
								>
									+{summaryField?.value.length - 1}
								</Typography>
							</Tooltip>
						)}
					</Stack>
				) : summaryField.config.readOnly ? (
					<SummaryFieldName style={{ opacity: 0.6 }}>
						-
					</SummaryFieldName>
				) : (
					<Stack
						onMouseOver={() => setIsTagHovered(true)}
						onMouseLeave={() => setIsTagHovered(false)}
						width="100%"
						padding={0}
						sx={{
							borderBottom: isTagHovered
								? "1px solid rgba(0, 0, 0, 0.2)"
								: "none",
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							spacing={0.5}
							width="150px"
						>
							<Typography
								fontSize="15px"
								fontWeight={500}
								style={{
									color: theme.palette.secondary.contrastText,
									opacity: "0.4",
								}}
							>
								Select
							</Typography>
							{isTagHovered ? (
								<Box display="flex">
									{DropDownIcon(20, 20, "rgba(0, 0, 0, 0.2)")}
								</Box>
							) : null}
						</Stack>
					</Stack>
				)}
			</Box>
		);
	};

	const getTagRequestData = (tagFieldValue) => {
		return {
			...tagFieldValue,
			id: entityId,
		};
	};

	const arraysAreEqual = (arrayOne = [], arrayTwo = []) => {
		if (arrayOne.length !== arrayTwo.length) {
			return false;
		}

		let sortedArrayOne = [...arrayOne].sort();
		let sortedArrayTwo = [...arrayTwo].sort();

		for (let i = 0; i < sortedArrayOne.length; i++) {
			if (sortedArrayOne[i] !== sortedArrayTwo[i]) {
				return false;
			}
		}

		return true;
	};

	const hasSelectedOrTagValue = (tagFieldValue) => {
		return (
			defaultSelectedValue?.length > 0 ||
			tagFieldValue[summaryField?.fieldId]?.length > 0
		);
	};

	const hasValueOrIsChanged = (tagFieldValue) => {
		return (
			!arraysAreEqual(
				tagFieldValue[summaryField?.fieldId],
				defaultSelectedValue
			) && hasSelectedOrTagValue(tagFieldValue)
		);
	};

	const handleSave = (tagFieldValue, onClose) => {
		if (hasValueOrIsChanged(tagFieldValue)) {
			let requestData = getTagRequestData(tagFieldValue);
			updateSummaryField(requestData, onClose);
		} else {
			onClose();
		}
	};

	const defaultSelectedValue = useMemo(() => {
		if (Array.isArray(summaryField.value)) {
			return summaryField.value.map((fieldValue) => fieldValue.id);
		}
		return [];
	}, [summaryField.value]);

	return (
		<>
			<FieldEdit field={summaryField} fieldLabel={getTagFieldLabel}>
				<TagFieldEditForm
					field={summaryField}
					onSave={handleSave}
					value={summaryField?.value}
				/>
			</FieldEdit>
		</>
	);
}
